
import {
    EXPANDED_CELL_ADDED,
    EXPANDED_CELL_REMOVED,
    CLEAR_STATE_FULFILLED
} from '../actions/SubformStateActions';

// INITIALIZE STATE
const initialState = {
    expandedCells: {},
    expandedCellAdded: false,
    expandedCellRemoved: false,
    clearStateFulfilled: false
};

export const SubformStateReducer = (state = initialState, action) => {

    switch (action.type) {
        case EXPANDED_CELL_ADDED: {
            let expandedCells = Object.assign({}, state.expandedCells);
            expandedCells[action.payload.pathwayId] = action.payload.expandedCells;
            return {
                ...state,
                expandedCells: expandedCells,
                expandedCellAdded: true,
            };
        }
        case EXPANDED_CELL_REMOVED: {
            let expandedCells = Object.assign({}, state.expandedCells);
            expandedCells[action.payload.pathwayId] = action.payload.expandedCells;
            return {
                ...state,
                expandedCells: expandedCells,
                expandedCellRemoved: true,
            };
        }
        case CLEAR_STATE_FULFILLED: {
            let expandedCells = Object.assign({}, state.expandedCells);
            expandedCells[action.payload.pathwayId] = action.payload.expandedCells;
            return {
                ...state,
                expandedCells: expandedCells,
                clearStateFulfilled: true,
            };
        }
        default:
            return state;
    }
};