import { FormWithRedux } from '../../FormWithRedux';
import { IsDate, IsDateInPastError } from '../../../waferJS/Form To FormsUI/Subclasses/Validation Overrides/DateTimeValidation';
import { IsTroponinResult, IsTroponinString } from '../Validation Overrides/GPChestPain-TroponinValidation';
import { redux } from '../../../App';
import moment from 'moment';
import { ActionModel } from '../../Shared Custom Types/ActionModel';

export class GPChestPainBloodsForm extends FormWithRedux {

    constructor(formLoader, parent, actionUpdater, dependentValuesUpdater, calculationsUpdater) {
        super('initialBloods', formLoader, parent, actionUpdater, dependentValuesUpdater, calculationsUpdater);
    }

    shouldDisplayFormDetailForIdentifier(identifier) {
        super.shouldDisplayFormDetailForIdentifier(identifier);
        if(identifier == 'acuteCardiologyIndicatedBloods'){
            return this.isAcuteCardiologyAssessmentIndicated();
        } else if(identifier == 'acsManagementNotIndicatedBloods'){
            return !this.isAcuteCardiologyAssessmentIndicated() && !this.isACSManagementInicated();
        } else if(identifier == 'initialTimingNotValidBloods'){
            return !this.isAcuteCardiologyAssessmentIndicated() && this.isACSManagementInicated() && (this.initialTimingValid() == false);
        } else if(identifier == 'initialBloodsCompleteFollowTimingNotValid'){
            let initialBloodsComplete = this.getValue('initialBloodsDateAndTime') != null && this.getValue('initialBloodsTroponin') != null;
            if(!initialBloodsComplete){
                return false;
            }

            return this.followUpTimingValid() == false;
        } else if(identifier == 'initialBloodsDateAndTime' || identifier == 'initialBloodsTroponin'){
            return this.getValue('initialBloodsTroponin') != null || (this.initialTimingValid() == true  && !this.isAcuteCardiologyAssessmentIndicated()) ;
        } else if(identifier == 'followUpBloodsDateAndTime' || identifier == 'followUpBloodsTroponin'){
            let initialBloodsComplete = this.getValue('initialBloodsDateAndTime') != null && this.getValue('initialBloodsTroponin') != null;

            return  this.getValue('followUpBloodsTroponin') != null || this.getValue('followUpBloodsDateAndTime') != null  || (!this.isAcuteCardiologyAssessmentIndicated() && ((initialBloodsComplete && this.followUpTimingValid() == true)));
        }

        return true;
    }

    isAcuteCardiologyAssessmentIndicated(){
        let edacs = this.getValue('calculatedEDACS');
        let clinicalSus = this.getValue('clinicalSuspicionOfAcuteCoronorySyndrome');
        let totalRedFlags = this.getValue('totalRedFlags');

        return edacs >= 16 || clinicalSus == 'highOption' || (totalRedFlags != undefined && totalRedFlags != null && totalRedFlags > 0);
    }

    isACSManagementInicated(){
        let highRiskDifferentialIndicators = [
            this.getValue('aorticDissectionPresent'),
            this.getValue('pulmonaryEmbolism'),
            this.getValue('pancreatitis'),
            this.getValue('pericarditis'),
            this.getValue('pneumothorax')
        ];
        let totalRedFlags = this.getValue('totalRedFlags');
        let clinicalSus = this.getValue('clinicalSuspicionOfAcuteCoronorySyndrome');
        return (totalRedFlags == null || totalRedFlags == 0) || highRiskDifferentialIndicators.includes(true) || this.getValue('stemiPresent') == true || clinicalSus == 'noneOption';
    }

    initialTimingValid(){
        let worstPainTime = this.getValue('worstDateTime');
        if(worstPainTime == null || worstPainTime == undefined){
            worstPainTime = this.getValue('firstDateTime');
        }

        if(worstPainTime == null || worstPainTime == undefined){
            return null;
        }

        let worstPainDateAndTime = moment(new Date(worstPainTime));
        let worstPainPlus72Hours = moment(worstPainDateAndTime).add(72, 'hours');
        return worstPainPlus72Hours.isAfter(moment());
    }

    followUpTimingValid(){
        let worstPainTime = this.getValue('worstDateTime');
        if(worstPainTime == null || worstPainTime == undefined){
            worstPainTime = this.getValue('firstDateTime');
        }

        if(worstPainTime == null || worstPainTime == undefined){
            return null;
        }

        let worstPainDateAndTime = moment(new Date(worstPainTime));
        let initialBloodsDateAndTime = this.getValue('initialBloodsDateAndTime');
        if(initialBloodsDateAndTime == null || initialBloodsDateAndTime == undefined){
            return null;
        }
        let initialBloodsTime = moment(initialBloodsDateAndTime);
        let worstPainPlus10Hours = moment(worstPainDateAndTime).add(10, 'hours');
        return worstPainPlus10Hours.isAfter(initialBloodsTime);
    }

    isEnabled(identifier) {
        if (identifier != null) {
            return true;
        }
        return false;
    }

    isMandatory(identifier) {
         
        if(identifier == 'followUpBloodsTroponin' || identifier == 'followUpBloodsDateAndTime'){
            let pathwayActions = redux.store.getState().actionsReducer.actions[this.pathwayId];
            if(pathwayActions != null){
                let followUpAction = pathwayActions.filter((action) => { return action.identifier == 'nextTroponinAction';})[0];
                if(followUpAction != null && followUpAction.scheduledAction != null){
                    let date = moment(followUpAction.scheduledAction.date);
                    return date.isBefore() && !this.isAcuteCardiologyAssessmentIndicated();
                }
            }
        } 
        
        if (identifier == 'followUpBloodsDateAndTime') {
            let dependantValue = this.getValue('followUpBloodsTroponin');
            return dependantValue != null || this.getValue('followUpBloodsDateAndTime') != null;
        } else if(identifier == 'followUpBloodsTroponin'){
            let dependantValue = this.getValue('followUpBloodsDateAndTime');
            return dependantValue != null || this.getValue('followUpBloodsTroponin') != null;
        } else if(identifier == 'initialBloodsDateAndTime' || identifier == 'initialBloodsTroponin'){
            return this.getValue('initialBloodsTroponin') != null || (this.initialTimingValid() == true  && !this.isAcuteCardiologyAssessmentIndicated());
        }

        return false;
    }

    submissionValidation(identifier) {
        if (identifier === 'initialBloodsDateAndTime' || identifier == 'followUpBloodsDateAndTime') {
            return [IsDate, IsDateInPastError];
        } else if (identifier == 'initialBloodsTroponin' || identifier == 'followUpBloodsTroponin') {
            return [IsTroponinResult];
        }
        return [];
    }

    editingValidation(identifier) {
        if (identifier == 'initialBloodsTroponin' || identifier == 'followUpBloodsTroponin') {
            return [IsTroponinString];
        }
        return [];
    }

    getValueType(identifier) {
        if (identifier === 'initialBloodsDateAndTime') {
            return 'date';
        } else if (identifier == 'initialBloodsTroponin') {
            return 'troponin';
        } else  if (identifier === 'followUpBloodsDateAndTime') {
            return 'date';
        } else if (identifier == 'followUpBloodsTroponin') {
            return 'troponin';
        }

        return null;
    }

    detailString() {
        let formDetails = this.getFormDetails();
        var valueDescriptions = [];

        for (let formDetailIndex in formDetails) {
            let formDetail = formDetails[formDetailIndex];
            let valueDescription = formDetail.valueDescription();
            if(formDetail.identifier == 'followUpBloodsDateAndTime' && this.getValue(formDetail.identifier) != null) {
                let troponinValue = this.getValue('followUpBloodsTroponin');
                let followUpString = 'Follow-up: ' + this.abbreviatedString(formDetail.identifier, valueDescription) + (troponinValue != null ? ', ' + this.abbreviatedString('followUpBloodsTroponin', troponinValue) : '');
                valueDescriptions.push(followUpString);
            } else if(formDetail.identifier == 'initialBloodsDateAndTime' && this.getValue(formDetail.identifier) != null){
                let troponinValue = this.getValue('initialBloodsTroponin');
                let initialString = 'Initial: ' + this.abbreviatedString(formDetail.identifier, valueDescription) + (troponinValue != null ? ', ' + this.abbreviatedString('initialBloodsTroponin', troponinValue) : '');
                valueDescriptions.push(initialString);
            }
        }

        let pathwayActions = redux.store.getState().actionsReducer.actions[this.pathwayId];
        if(pathwayActions != null){
            let action = pathwayActions.filter((action) => { return action.identifier == 'nextTroponinAction';})[0];
            if(action != null){
                let actionModel = new ActionModel(action.pathwayUuid, action.versionUuid, action.identifier, action.title, action.detail, action.priority, action.observed, action.removeAfterObservation, action.lastModifiedDate, action.uuid, action.scheduledAction);
                valueDescriptions.push(actionModel.detail);
            }
        }

        let finalDetailString = valueDescriptions.join('\n');

        if (finalDetailString.length > 0) {
            return finalDetailString;
        }

        return this.detail;
    }

    abbreviatedString(identifier, value) {
        switch (identifier) {
            case 'initialBloodsTroponin':
            case 'followUpBloodsTroponin': {
                return 'Troponin ' + value + 'ng/L';
            }
            case 'initialBloodsDateAndTime':
            case 'followUpBloodsDateAndTime':
                return value;
        }
    }

    formDetailString(){
        let pathwayActions = redux.store.getState().actionsReducer.actions[this.pathwayId];
        if(pathwayActions != null){
            let action = pathwayActions.filter((action) => { return action.identifier == 'nextTroponinAction';})[0];
            if(action != null){
                let actionModel = new ActionModel(action.pathwayUuid, action.versionUuid, action.identifier, action.title, action.detail, action.priority, action.observed, action.removeAfterObservation, action.lastModifiedDate, action.uuid, action.scheduledAction);
                return actionModel.detail;
            }
        }

        return this.detail;
    }
}