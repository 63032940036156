// IMPORT PACKAGE REFERENCES
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FormButton} from '../../waferJS/FormsUI/FormsUI Components/Controls/FormButton';
import { FormLabel} from '../../waferJS/FormsUI/FormsUI Components/Controls/FormLabel';
import { FormsStyles } from '../../waferJS/FormsUI/FormsUI';
import { withRouter } from '../routers/withRouter';

// COMPONENT
class CheckModal extends React.Component {

    render() {
        return (
            <div className="modal-wrapper">
                <div className="modal-overlay" />
                <div className="modal-manager-container">
                    <div className="modal-card" ref={sessionCardNode => this.sessionCardNode = sessionCardNode}>
                        <header className={'modal-title'}>Are you sure?</header>
                        <FormLabel value={'All of the data for the current pathway will be lost if you start a new session'} fontSize={0.9} alignText={'center'} textColor={'#B0B0B0'} />
                        <div className="modal-body">
                            <FormButton  key='Cancel' onPress={this.props.toggleVisibility} enabled={true} isSelected={false} title={'Cancel'}/>
                            <div className="button-wrapper"><FormButton className="buttonWrapper" key='newSession' onPress={this.props.yesAction} enabled={true} isSelected={false} color={FormsStyles.red} title={this.props.distructiveActionTitle} /></div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

CheckModal.propTypes = {
    location: PropTypes.object.isRequired,
    navigate: PropTypes.func.isRequired,
    toggleVisibility: PropTypes.func.isRequired,
    yesAction: PropTypes.func.isRequired,
    distructiveActionTitle: PropTypes.string.isRequired
};


// CONFIGURE REACT REDUX
const mapStateToProps = state => {
    const { workflow, updatingWorkflowPending, updatingWorkflowFulfilled, updatingWorkflowFailed } = state.workflowReducer;
    return { workflow, updatingWorkflowPending, updatingWorkflowFulfilled, updatingWorkflowFailed };
};

const mapDispatchToProps = dispatch => (
    bindActionCreators({  }, dispatch)
);

const hoc = withRouter(connect(mapStateToProps, mapDispatchToProps)(CheckModal));

// EXPORT COMPONENT

export { hoc as CheckModal };