import { FormsStyles } from '../../../waferJS/FormsUI/FormsUI';

export class RiskCategoryBrackets {

    static Type = Object.freeze({
        veryLowRisk1: 0,    // Discharge to GP, no futher investigation
        lowRisk2: 1,        // Cardiology SMO review, Follow Cardiology advice
        intermediateRisk1: 2,   // Discharge to acute demand service, Bloods - Repeat, ECG
        highRisk1: 3,       // Admit to Chest Pain Unit or Cardiology Ward, Bloods - Repeat, ECG, Follow Cardiology advice
        highRisk2: 4    // Discuss with cardiology, Follow Cardiology advice
    });

    constructor(type){
        this.type = type;
    }

    get riskCategory() {
        switch(this.type) {
            case RiskCategoryBrackets.Type.veryLowRisk1:
                return new RiskCategory(RiskCategory.Type.veryLow);
            case RiskCategoryBrackets.Type.lowRisk2:
                return new RiskCategory(RiskCategory.Type.low);
            case RiskCategoryBrackets.Type.intermediateRisk1:
                return new RiskCategory(RiskCategory.Type.intermediate);
            case RiskCategoryBrackets.Type.highRisk1:
            case RiskCategoryBrackets.Type.highRisk2:
                return new RiskCategory(RiskCategory.Type.high);
        }
    }
}

export class RiskCategoryValues {

    constructor(edacsScore, age, initialTroponinResult, hasBlockingRedFlag, hoursBetweenOnsetAndInitialTroponin, followUpTroponinResult){
        this.edacsScore = edacsScore;
        this.initialTroponinResult = initialTroponinResult == null ? null : parseFloat(initialTroponinResult);
        this.hasBlockingRedFlag = hasBlockingRedFlag;
        this.hoursBetweenOnsetAndInitialTroponin = hoursBetweenOnsetAndInitialTroponin;
        this.followUpTroponinResult = followUpTroponinResult == null ? null : parseFloat(followUpTroponinResult);
        this.age = age;
    }

    get riskBracket() {
        if(this.isTroponinAboveThreshold(this.initialTroponinResult, this.followUpTroponinResult) == true ||  this.hasBlockingRedFlag  || this.edacsScore >= 21) {
            return RiskCategoryBrackets.Type.highRisk1;
        }
        else if (this.edacsScore < 12 && this.isTroponinAboveThreshold(this.initialTroponinResult, this.followUpTroponinResult) == false) {
            return RiskCategoryBrackets.Type.lowRisk2;
        }
        else {
            return RiskCategoryBrackets.Type.intermediateRisk1;
        }
        // if (this.edacsScore < 16) {
        //     //Emergency Medicine Team
        //     if (this.initialTroponinResult < 5) {
        //         if (this.isTroponinPositive(this.initialTroponinResult, this.followUpTroponinResult) == true) {
        //             //HIGH2
        //             return RiskCategoryBrackets.Type.highRisk2;
        //         } else if (this.isTroponinAboveThreshold(this.initialTroponinResult, this.followUpTroponinResult) == true) {
        //             //INTERMEDIATE
        //             return RiskCategoryBrackets.Type.intermediateRisk1;
        //         }
        //         else {
        //             //VERYLOW1
        //             return RiskCategoryBrackets.Type.veryLowRisk1;
        //         }
        //     } else if (this.initialTroponinResult >= 5 && this.initialTroponinResult < 15) {
        //         if (this.followUpTroponinResult == null) {
        //             //INTERMEDIATE1
        //             return RiskCategoryBrackets.Type.intermediateRisk1;
        //         }

        //         if (this.isTroponinPositive(this.initialTroponinResult, this.followUpTroponinResult) == true) {
        //             //HIGH2
        //             return RiskCategoryBrackets.Type.highRisk2;
        //         } else {
        //             //LOW2
        //             return RiskCategoryBrackets.Type.lowRisk2;
        //         }
        //     } else if (this.initialTroponinResult >= 15) {
        //         //HIGH1
        //         return RiskCategoryBrackets.Type.highRisk1;
        //     }
        // } else if (this.edacsScore >= 16) {
        //     //Cardiology team
        //     if (this.initialTroponinResult < 5) {
        //         if (this.followUpTroponinResult == null) {
        //             //INTERMEDIATE1
        //             return RiskCategoryBrackets.Type.intermediateRisk1;
        //         }

        //         if (this.isTroponinPositive(this.initialTroponinResult, this.followUpTroponinResult) == true) {
        //             //HIGH2
        //             return RiskCategoryBrackets.Type.highRisk2;
        //         } else {
        //             //LOW2
        //             return RiskCategoryBrackets.Type.lowRisk2;
        //         }
        //     } else if (this.initialTroponinResult >= 5 && this.initialTroponinResult < 15) {
        //         if (this.hoursBetweenOnsetAndInitialTroponin <= 3) {
        //             //HIGH1
        //             return RiskCategoryBrackets.Type.highRisk1;
        //         } else {
        //             if (this.followUpTroponinResult == null) {
        //                 //INTERMEDIATE1
        //                 return RiskCategoryBrackets.Type.intermediateRisk1;
        //             }

        //             if (this.isTroponinPositive(this.initialTroponinResult, this.followUpTroponinResult) == true) {
        //                 //HIGH2
        //                 return RiskCategoryBrackets.Type.highRisk2;
        //             } else {
        //                 //LOW2
        //                 return RiskCategoryBrackets.Type.lowRisk2;
        //             }
        //         }
        //     } else if (this.initialTroponinResult >= 15) {
        //         //HIGH1
        //         return RiskCategoryBrackets.Type.highRisk1;
        //     }
        // }
    }

    isTroponinAboveThreshold(initialTroponinResult, followUpTroponinResult) {
        if (initialTroponinResult != null) {
            if (followUpTroponinResult != null) {
                return (initialTroponinResult > 0.05 || followUpTroponinResult > 0.05) ? true : false;
            }
            else {
                return initialTroponinResult > 0.05 ? true : false;
            }
        }
        else if (followUpTroponinResult != null) {
            return followUpTroponinResult > 0.05 ? true : false;
        }
    }

    isTroponinPositive(initialTroponinResult, followUpTroponinResult) {
        if (initialTroponinResult == null || followUpTroponinResult == null) {
            return null;
        }
        
        let troponinAboveCutoffPoint = (initialTroponinResult >= 15 || followUpTroponinResult >= 15) ? true : false;
        let troponinDeltaPositive = (Math.abs(initialTroponinResult - followUpTroponinResult) >= 4) ? true : false;

        if (troponinAboveCutoffPoint || troponinDeltaPositive) {
            return true;
        } else {
            return false;
        }
    }
}

export class RiskCategory {

    static Type = Object.freeze({
        veryLow: 0.0,
        low: 1.0,
        intermediate: 2.0,
        high: 3.0
    });

    constructor(type){
        this.type = type;
    }
    
    get title(){
        switch (this.type) {
            case RiskCategory.Type.veryLow:
                return 'VERY LOW';
            case RiskCategory.Type.low:
                return 'LOW';
            case RiskCategory.Type.intermediate:
                return 'INTERMEDIATE';
            case RiskCategory.Type.high:
                return 'HIGH';
        }
    }
    
    get color(){
        switch (this.type) {
            case RiskCategory.Type.veryLow:
                return FormsStyles.green;
            case RiskCategory.Type.low:
                return FormsStyles.green;
            case RiskCategory.Type.intermediate:
                return FormsStyles.orange;
            case RiskCategory.Type.high:
                return FormsStyles.red;
        }
    }

    get rating(){
        switch (this.type) {
            case RiskCategory.Type.veryLow:
                return 'Low';
            case RiskCategory.Type.low:
                return 'Low';
            case RiskCategory.Type.intermediate:
                return 'Moderate';
            case RiskCategory.Type.high:
                return 'High';
        }
    }

    static getRiskCategory(value){
        let category = RiskCategory.Type[value];
        return category;
    }

    static getRiskCategoryFromBracketValue(value){
        let riskCategoryBracket = new RiskCategoryBrackets(value);
        return riskCategoryBracket.riskCategory;
    }
}