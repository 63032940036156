// IMPORT PACKAGE REFERENCES
import React from 'react';
import PropTypes from 'prop-types';
import { FormsStyles } from '../../FormsUI';
import '../../Styles/dropdown.scss';
import propTypes from 'prop-types';
import { CaretDown } from 'phosphor-react';

// COMPONENT
export class FormDropdown extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            selectedValue: props.selectedValue,
            menuOpen: props.menuOpen,
            onMenuOpen: props.onMenuOpen,
            onSelect: props.onSelect,
            optionsList: props.optionsList,
        };
    }

    render() {
        return (
            <div className='select-menu'>
                <div className='select' onClick={this.props.onMenuOpen}>
                    <span className={!this.props.selectedValue ? 'placeholder' : 'selected'}>{!this.props.selectedValue ? 'Required' : this.props.selectedValue}</span>
                    <CaretDown size={18} weight='bold' />
                </div>
                <ul className={`${this.props.menuOpen ? 'menu-open' : 'menu'}`}>
                    {this.props.optionsList.map((option) => (
                        <li className='option' key={option.title} onClick={() => this.props.onSelect(option.title)}>{option.title}</li>
                    ))}
                </ul>
            </div>
        );
    }
}

FormDropdown.propTypes = {
    selectedValue: propTypes.string,
    menuOpen: propTypes.bool,
    onMenuOpen: propTypes.func,
    onSelect: propTypes.func,
    optionsList: propTypes.array,
};