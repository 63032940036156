export var EDChestPainSidebar = {
    root: {
        sections: [
            {
                name: 'Notes',
                icon: 'notes',
                path: 'notesForm',
                showPie: false,
                showBadge: false
            },
            {
                name: 'Pathway',
                path: 'mainForm',
                icon: 'pathway',
                showPie: false,
                showBadge: false,
                sections: [
                    {
                        name: 'Patient details',
                        path: 'patientDetails',
                        showPie: true,
                        showBadge: true
                    },
                    {
                        name: 'Clinical question',
                        path: 'highRiskDifferentialDiagnosis',
                        showPie: true,
                        showBadge: true
                    },
                    {
                        name: 'EDACS assessment',
                        path: 'initialAssessment',
                        showPie: true,
                        showBadge: true
                    },
                    {
                        name: 'Investigations',
                        path: 'mainForm/investigations',
                        showPie: false,
                        showBadge: false,
                        sections: [
                            {
                                name: 'Vital signs',
                                path: 'vitalSigns',
                                showPie: true,
                                showBadge: true
                            },
                            {
                                name: 'ECG',
                                path: 'ecgTest',
                                showPie: true,
                                showBadge: true
                            },
                            {
                                name: 'Bloods',
                                path: 'initialBloods',
                                showPie: true,
                                showBadge: true
                            }
                        ]
                    }
                ]
            },
            {
                name: 'Insights',
                icon: 'results',
                path: 'resultsForm',
                showPie: false,
                showBadge: true
            }
        ]
    }
};