import { TotalEWS, calculateEWS } from '../Custom Types/EWS';
import moment from 'moment';
import { FormValidationError } from '../../../waferJS/Form/Validation/FormValidationError';
import {RiskCategoryBrackets, RiskCategoryValues} from '../Custom Types/Risk Category';
import { CalculationModel } from '../Custom Types/Calculation';
import { FormsStyles } from '../../../waferJS/FormsUI/FormsUI';

export class GPChestPainCalculationHelper {

    static riskTypes = {high: FormsStyles.red, considerable: FormsStyles.orange, moderate: FormsStyles.yellow, low: FormsStyles.green} 

    static calcualtionError(identifier, data, form){
        if(data == null) {return null;}

        if(identifier == 'calculatedEDACS'){
            let patientAge = data['patientAge'];
            if(patientAge != null && patientAge < 18) {
                return new FormValidationError(FormValidationError.FormErrorType.warning, 'Only for patients ≥18 years old');
            }
            else if(data['patientSex'] == 'genderOtherOption'){
                return new FormValidationError(FormValidationError.FormErrorType.warning, 'Patient is not eligible for pathway');
            }
            return null;
        } else if(identifier == 'probabilityOfMACE'){
            if(data['patientSex'] == 'genderOtherOption'){
                return new FormValidationError(FormValidationError.FormErrorType.warning, 'Patient is not eligible for pathway');
            }
            let stemiPresent = data['stemiPresent'];

            // Refer to mainForm or the parent of the form (mainForm)
            let mainForm = form.parent != null ? form.parent : form;

            let ecgCompletionProgress = mainForm.subform('ecgTest').completionProgress();
            let ecgComplete = (ecgCompletionProgress.current / ecgCompletionProgress.total) == 1;
            if(stemiPresent != null && stemiPresent && ecgComplete){
                return new FormValidationError(FormValidationError.FormErrorType.warning, 'Not calculated for patients with STEMI');
            }

            if(data['troponinAboveCutOffOverride'] != true){
                let initialBloodsTroponin = data['initialBloodsTroponin'];
                let followUpBloodsTroponin = data['followUpBloodsTroponin'];
                let gender = data['patientSexInt'];
                if(gender != null){
                    let cutOff = gender == 1 ? 34 : 16;
                    let redFlag = (initialBloodsTroponin != null && initialBloodsTroponin >= cutOff) || (followUpBloodsTroponin != null && followUpBloodsTroponin >= cutOff);
                    if(initialBloodsTroponin != null || followUpBloodsTroponin != null){
                        if (redFlag) {
                            return new FormValidationError(FormValidationError.FormErrorType.warning, 'Not calculated for patients with: Troponin above cut-off');
    
                        }
                    }
                }
            }
            
            if(data['newIschaemicChangesOverride'] != true){
                if(data['newIschaemia'] != null && data['newIschaemia']){
                    return new FormValidationError(FormValidationError.FormErrorType.warning, 'Not calculated for patients with: New ischaemic changes');
                }
            }

            if (data['redFlagsOngoingChestPainOverride'] != true){
                if(data['ongoingChestPain'] != null && data['ongoingChestPain']){
                    return new FormValidationError(FormValidationError.FormErrorType.warning, 'Not calculated for patients with: Ongoing chest pain');
                }
            }
            

            if(data['crescendoAnginaOverride'] != true){
                if(data['crescendoPain'] != null && data['crescendoPain']){
                    return new FormValidationError(FormValidationError.FormErrorType.warning, 'Not calculated for patients with: Crescendo angina');
                }
            }

            if(data['haemodynamicInstabilityOverride'] != true){
                let ewsIdentifiers = [
                    'heartRateEWS',
                    'bloodPressureEWS',
                    'bodyTemperatureEWS',
                    'oxygenSaturationEWS',
                    'respiratoryRateEWS',
                    'supplementalOxygenEWS',
                    'levelOfConsciousnessEWS'
                ];
        
                let categories = ewsIdentifiers.map((identifier) => {
                    let value = data[identifier.replace('EWS', '')];
                    return calculateEWS(identifier, value);
                }).filter(n => n);
        
                if (categories.length == 0) {
                    return null;
                }
                let totalEWS = new TotalEWS(categories);
                if(totalEWS != null && totalEWS.sum >= 2){
                    return new FormValidationError(FormValidationError.FormErrorType.warning, 'Not calculated for patients with: Haemodynamic instability');
                    
                }
            }

            if(data['calculatedEDACS'] != null && data['patientSexInt'] != null) {
                let riskBracket = this.getRiskBracket(data);
                if(riskBracket != null){
                    if(riskBracket.preventRiskCalculation) {
                        return new FormValidationError(FormValidationError.FormErrorType.warning, 'Not calculated due to Pathway Risk Category: ' + riskBracket.riskCategory.title);
                    }
                }
            }      
        }
        return null;
    }

    static getRiskBracket(data, ignoreT2Troponin){
        if(data == null) {return null;}

        let followUpTroponin;
        if(!ignoreT2Troponin){
            followUpTroponin = data['followUpBloodsTroponin'];
        }
        
        let riskCategoryValue = new RiskCategoryValues(data['calculatedEDACS'], data['patientSexInt'], data['patientDateOfBirth'] != null ? moment().diff(data['patientDateOfBirth'], 'years') : null, data['initialBloodsTroponin'], false, null, followUpTroponin);
        let bracket = riskCategoryValue.riskBracket;
        if(bracket != null){
            let riskBracket = new RiskCategoryBrackets(bracket);
            return riskBracket;
        }
        return null;
    }

    static getLatestCalculation(calculationType, calculations){
        if(calculations == null) { return null; }

        var filteredDict = Object.keys(calculations).reduce(function (filtered, key) {
            if (calculations[key].type == calculationType) filtered[key] = calculations[key];
            return filtered;
        }, {});

        let filteredSortedArray = Object.keys(filteredDict).map(function(key) {
            return filteredDict[key];
        }).sort((first, second) => {
            return new Date(second.lastModifiedDate).getTime() - new Date(first.lastModifiedDate).getTime();
        });

        if(filteredSortedArray.length == 0) {
            return null;
        }
        
        return new CalculationModel(filteredSortedArray[0].pathwayUuid, filteredSortedArray[0].versionUuid, filteredSortedArray[0].value, filteredSortedArray[0].observed, filteredSortedArray[0].type, filteredSortedArray[0].lastModifiedDate, filteredSortedArray[0].uuid);
    }

    static getPreviousCalculation(calculationType, calculations){
        if(calculations == null) { return null; }

        var filteredDict = Object.keys(calculations).reduce(function (filtered, key) {
            if (calculations[key].type == calculationType) filtered[key] = calculations[key];
            return filtered;
        }, {});

        let filteredSortedArray = Object.keys(filteredDict).map(function(key) {
            return filteredDict[key];
        }).sort((first, second) => {
            return new Date(second.lastModifiedDate).getTime() - new Date(first.lastModifiedDate).getTime();
        });

        if(filteredSortedArray.length < 2) {
            return null;
        }
        
        return new CalculationModel(filteredSortedArray[1].pathwayUuid, filteredSortedArray[1].versionUuid, filteredSortedArray[1].value, filteredSortedArray[1].observed, filteredSortedArray[1].type, filteredSortedArray[1].lastModifiedDate, filteredSortedArray[1].uuid);
    }

    static getHighestPriorityCalculationBadges(calculations){
        let highestPriorityCalculations = [];

        let highPriorityCalcualtions = GPChestPainCalculationHelper.getCalculationsWithRisk(calculations, GPChestPainCalculationHelper.riskTypes.high);
        let considerablePriorityCalcualtions = GPChestPainCalculationHelper.getCalculationsWithRisk(calculations, GPChestPainCalculationHelper.riskTypes.considerable);
        let moderatePriorityCalcualtions = GPChestPainCalculationHelper.getCalculationsWithRisk(calculations, GPChestPainCalculationHelper.riskTypes.moderate);
        let lowPriorityCalcualtions = GPChestPainCalculationHelper.getCalculationsWithRisk(calculations, GPChestPainCalculationHelper.riskTypes.low);

        if(highPriorityCalcualtions.length > 0){
            highestPriorityCalculations = highPriorityCalcualtions;
        } else if(considerablePriorityCalcualtions.length > 0){
            highestPriorityCalculations = considerablePriorityCalcualtions;
        } else if(moderatePriorityCalcualtions.length > 0){
            highestPriorityCalculations = moderatePriorityCalcualtions;
        } else if(lowPriorityCalcualtions.length > 0){
            highestPriorityCalculations = lowPriorityCalcualtions;
        }

        let badgeValues = highestPriorityCalculations.map((calculation) => {
            if(calculation.type == CalculationModel.CalculationType.riskCategory){
                let riskBracket = new RiskCategoryBrackets(calculation.value);
                return {
                    color: riskBracket.riskCategory.color,
                    value: 'Pathway Risk Category: ' + (riskBracket.riskCategory.title != null ? riskBracket.riskCategory.title : 'N/A'),
                    solid: true
                };
            }

            return {
                color: calculation.badgeColor,
                value: (calculation.type == CalculationModel.CalculationType.probabilityOfMace ? 'Probability Of MACE: '  : 'Calculated EDACS: ') +  calculation.valueString,
                solid: true
            };
        });

        return badgeValues;
    }

    static getCalculationsWithRisk(calculations, risk){
        return calculations.filter((calculation) => {
            if(calculation.type == CalculationModel.CalculationType.riskCategory){
                if(calculation.value == null) { return false; }
                let riskBracket = new RiskCategoryBrackets(calculation.value);
                return riskBracket.riskCategory.color == risk;
            }
            return calculation.badgeColor == risk;
        });
    }
}
