import { FormContentView } from '../../FormsUI/FormsUI Components/Containers/FormContentView';
import { FormButton } from '../../FormsUI/FormsUI Components/Controls/FormButton';
import { FormLabel } from '../../FormsUI/FormsUI Components/Controls/FormLabel';
import { FormsStyles } from '../../FormsUI/FormsUI';

import React from 'react';
import PropTypes from 'prop-types';

export class CompletionItemFormDetail extends React.Component {

    constructor(props) {
        super(props);

        let childIDs = this.props.formDetail.subcellIDs;
        let childIDValues = childIDs.map(childID => this.props.formDetail.form.getValue(childID));
        let childAnswered = childIDValues.includes(true) || childIDValues.includes(false);
        
        this.state = {
            showRecommendationPathway: childAnswered
        };

        this.props.formDetail.form.valueUpdated(this.props.formDetail.identifier, childAnswered);
        this.handleKeyPress = this.handleKeyPress.bind(this);
        this.toggleSubcellVisibility = this.toggleSubcellVisibility.bind(this);
    }

    componentDidMount() {
        document.addEventListener('keypress', this.handleKeyPress);
        this.props.renderErrors();
    }

    componentWillUnmount() {
        document.removeEventListener('keypress', this.handleKeyPress);
    }

    handleKeyPress(e) {
        if (document.activeElement == this.focusNode) {
            // window.gtag('event', 'used-keyboard-input-for-checkbox', {
            //     event_catagory: 'action',
            //     event_label: 'used-keyboard-input-for-checkbox'
            // });

            let keyChar = String.fromCharCode(e.which);
            
            if (e.which == 13 || keyChar == 'y') { //enter key: 13
                this.toggleSubcellVisibility();
            }
        }
    }

    toggleSubcellVisibility() {
        if (!this.state.showRecommendationPathway) {
            this.setState({
                showRecommendationPathway: true
            });
        }

        let updatedOption = this.props.formDetail.editingValue == true ? null : true;
        this.props.formDetail.form.valueUpdated(this.props.formDetail.identifier, updatedOption);
    }

    render() {
        let subcells = this.props.renderSubcells();
        let firstChildValue = this.props.formDetail.form.getValue(this.props.formDetail.subcellIDs[0]);
        let isMandatory = this.props.formDetail.form.isMandatory(this.props.formDetail.identifier);

        let recommendationTag;
        if (this.state.showRecommendationPathway) {
            if (firstChildValue == true || firstChildValue == false) {
                recommendationTag =
                <div style={{display: 'flex', flexDirection: 'row'}}>
                    <FormLabel key='firstChildComplete' value={'Completed'} fontSize={0.9} textColor={FormsStyles.green} />
                </div>;
            }
        } else {
            recommendationTag =
            <div style={{display: 'flex', flexDirection: 'row'}}>
                <FormButton key='firstChildNotVisible' title={'Once completed, click here to continue'} textSize={0.9} color={FormsStyles.primaryTint} backgroundColor={'transparent'} onPress={this.toggleSubcellVisibility} enabled={true} isSelected={false} />
            </div>;
        }
        
        return (
            <FormContentView identifier={this.props.formDetail.identifier} isSubcell={this.props.isSubcell} titleString={this.props.formDetail.title} isMandatory={isMandatory} detailString={this.props.formDetail.detail} bottomControl={recommendationTag} subcell={this.state.showRecommendationPathway ? subcells : []} subCellbackgroundColor={FormsStyles.backgroundColor} validation={this.props.errorLabels} showHover={true} allowFocus={true} focusRef={focusNode => this.focusNode = focusNode} />
        );
    }
}

CompletionItemFormDetail.propTypes = {
    formDetail: PropTypes.object.isRequired,
    isSubcell: PropTypes.bool.isRequired,
    renderErrors: PropTypes.func.isRequired,
    renderSubcells: PropTypes.func.isRequired,
    errorLabels: PropTypes.array.isRequired,
    hasError: PropTypes.bool.isRequired
};