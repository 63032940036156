import axios, { InternalAxiosRequestConfig } from "axios";
import { InteractionRequiredAuthError } from "@azure/msal-browser";
import { msalInstance } from "./AppAuthProvider";

const getBaseURL = () => {
  if (window.location.hostname === "localhost") {
    return "http://localhost:5000/api";
  } else {
    return window.location.origin + "/api";
  }
};

const nhiAxios = axios.create({
  baseURL: getBaseURL(),
  withCredentials: false,
});

nhiAxios.interceptors.request.use(
  async (config: InternalAxiosRequestConfig) => {
    if (config.headers) {
      config.headers["Content-Type"] = "application/json";
    }

    const account = msalInstance.getAllAccounts()[0];
    if (account) {
      const accessTokenRequest = {
        scopes: ["openid " + process.env.REACT_APP_CLIENT_ID],
        account: account,
      };

      try {
        // Get token from cache or API request
        const accessTokenResponse = await msalInstance.acquireTokenSilent(accessTokenRequest);
        if (accessTokenResponse) {
          const accessToken = accessTokenResponse.accessToken;

          if (config.headers && accessToken) {
            config.headers["Authorization"] = "Bearer " + accessToken;
          }
        } else {
          Promise.reject("Failed to get auth token");
        }
      } catch (error) {
        if (error instanceof InteractionRequiredAuthError) {
          msalInstance.acquireTokenRedirect(accessTokenRequest);
        }
        console.log(error);
      }
    } else {
      // This will not happen
      console.log("[ERROR] An user does not login");
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

nhiAxios.interceptors.response.use(
  (res) => res,
  (err) => {
    console.error("Got an error on API request:");
    console.error(err.response);

    return Promise.reject(err);
  }
);

export default nhiAxios;
