import React from "react";
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useMsal,
} from "@azure/msal-react";
import { AuthServerError } from "../routers/AuthHandler";
import PathwaySelectionPage from "../pages/PathwaySelectionPage";
import LoginPage from "./LoginPage";
import LoadingPage from "./LoadingPage";
interface LandingPageProps {
  authError?: AuthServerError;
}
const LandingPage: React.FC<LandingPageProps> = ({
  authError,
}) => {
  const { inProgress } = useMsal();
  if (inProgress === "login" || inProgress === "logout") {
    return <LoadingPage />;
  } else {
    return (
      <>
        <AuthenticatedTemplate>
          <PathwaySelectionPage />
        </AuthenticatedTemplate>
        <UnauthenticatedTemplate>
          <LoginPage authError={authError} />
        </UnauthenticatedTemplate>
      </>
    );
  }
};
export default LandingPage;