
import { PathwayReferenceHelper } from '../PathwayReferenceHelper';
import { GPChestPainPathwayReference } from './GPChestPainPathway-Reference';
import { FormsContentType } from '../../waferJS/FormsUI/FormsUI';
import { CalculationModel } from './Custom Types/Calculation';
import { GPChestPainCalculationHelper } from './Helpers/GPChestPainCalculationHelper';
import { redux } from '../../App';
import { calculateEWS, TotalEWS  } from './Custom Types/EWS';
import { PathwayFormatter } from '../PathwayFormatter';

const sectionLine = '=======================================\n';
const sectionBreak = '\n\n';

export class GPChestPainFormatter {

    static formatAsPlainText(formLoader, workflow) {
        let formattedString = '';

        formattedString += 'Wayfind ED Chest Pain: Suspected ACS\n';
        formattedString += 'Updated: ' + PathwayFormatter.formatDate(workflow.lastModifiedDate, 'ddd DD-MMM-YYYY HH:mm') + ' (' + 'version ' + workflow.version +  ')';
        formattedString += sectionBreak;

        formattedString += 'PATIENT\n';
        formattedString += sectionLine;
        formattedString += 'Age: ' + getReadableValue(workflow, 'patientAge', true) + '\n'; // + getReadableDate(workflow, 'patientDateOfBirth', 'DD-MMM-YYYY', false, true) + ' (' + 'yrs' + ')\n';
        formattedString += 'Sex: ' + getReadableValue(workflow, 'patientSex', true);
        formattedString += sectionBreak;

        formattedString += 'CLINICAL QUESTION\n';
        formattedString += sectionLine;
        formattedString += 'Clinical suspicion of Acute coronary syndrome: ' +  getReadableValue(workflow, 'clinicalSuspicionOfAcuteCoronorySyndrome', true) + '\n';
        formattedString += 'High-risk differential diagnoses: ';
        formattedString += getItemsInSection(workflow, ['highRiskDifferentialDiagnosisSection'], true, '', '', 'None indicated');
        formattedString += getItemsInSection(workflow, ['highRiskDifferentialDiagnosisSection'], null, '[Not specified: ', ']');
        formattedString += getItemsInSection(workflow, ['highRiskDifferentialDiagnosisSection'], false, '(Not present: ', ')');
        formattedString += '\n';

        formattedString += 'INITIAL ASSESSMENT\n';
        formattedString += sectionLine;
        formattedString += 'First episode: ' + getReadableDate(workflow, 'firstDateTime', ' ddd DD-MMM-YYYY, HH:mm', false, true) + ', lasting ' +  getReadableValue(workflow,'firstDuration', true) + '\n';
        if(workflow['worstDateTime'] != null){
            formattedString += 'Worst pain: ' + getReadableDate(workflow, 'worstDateTime', ' ddd DD-MMM-YYYY, HH:mm', false, true) + ', lasting ' +  getReadableValue(workflow,'worstDuration', true);
        } else {
            formattedString += 'Worst pain: ' + getReadableDate(workflow, 'firstDateTime', ' ddd DD-MMM-YYYY, HH:mm', false, true) + ', lasting ' +  getReadableValue(workflow,'firstDuration', true);
        }
        formattedString += sectionBreak;

        formattedString += 'KEY CLINICAL FEATURES:\n';
        formattedString += 'Symptoms and signs: ';
        formattedString += getItemsInSection(workflow, ['symptomsAndSigns'], true, '', '', 'None indicated');
        formattedString += getItemsInSection(workflow, ['symptomsAndSigns'], null, '[Not specified: ', ']');
        formattedString += getItemsInSection(workflow, ['symptomsAndSigns'], false, '(Not present: ', ')');
        formattedString += '\n';

        formattedString += 'KEY HISTORICAL INFORMATION:\n';
        
        if(indentifiersContainsValue(workflow, true, ['hypertension', 'dyslipidaemia', 'diabetes', 'currentSmoker', 'obesity', 'coronaryHeartDisease', 'coronaryArteryStenosis'])){
            formattedString += 'Problems and past medical history: ';
            formattedString += getItemsInSection(workflow, ['problemsAndPast'], true, '', '', 'None indicated');
            formattedString += getItemsInSection(workflow, ['problemsAndPast'], null, '[Not specified: ', ']');
            formattedString += getItemsInSection(workflow, ['problemsAndPast'], false, '(Not present: ', ')');
            formattedString += '\n';
        }

        if(indentifiersContainsValue(workflow, true, ['prematureCoronaryHeartDisease'])){
            formattedString += 'Family history: ';
            formattedString += getItemsInSection(workflow, ['familyHistory'], true, '', '', 'None indicated');
            formattedString += getItemsInSection(workflow, ['familyHistory'], null, '[Not specified: ', ']');
            formattedString += getItemsInSection(workflow, ['familyHistory'], false, '(Not present: ', ')');
            formattedString += '\n';
        }

        if(indentifiersContainsValue(workflow, true, ['aspirin', 'chemotherapy'])){
            formattedString += 'Medications: ';
            formattedString += getItemsInSection(workflow, ['medications'], true, '', '', 'None indicated');
            formattedString += getItemsInSection(workflow, ['medications'], null, '[Not specified: ', ']');
            formattedString += getItemsInSection(workflow, ['medications'], false, '(Not present: ', ')');
            formattedString += '\n';
        }

        formattedString += getItemsInSection(workflow, ['problemsAndPast', 'familyHistory', 'medications'], false, '(Not present: ', ')');
        formattedString += sectionBreak;

        formattedString += 'INVESTIGATIONS\n';
        formattedString += sectionLine;

        let totalEWS = getTotalEWS(workflow);
        if (totalEWS != null && totalEWS != undefined) {
            formattedString += 'VITAL SIGNS [' + totalEWS.title + ']\n';
        } else {
            formattedString += 'VITAL SIGNS' + '\n';
        }
        
        var vitalsComponents = [];

        PathwayReferenceHelper.reference('recordVitalsSection', GPChestPainPathwayReference.data)[PathwayReferenceHelper.Type.sectionChildIDs].map((vitalSign) => {
            if(vitalSign == 'supplementalOxygen' || vitalSign == 'levelOfConsciousness'){
                vitalsComponents.push('\n' + vitalsShorthandTitle(vitalSign) + getReadableValue(workflow,vitalSign, true));
            } else {
                vitalsComponents.push(vitalsShorthandTitle(vitalSign) + getReadableValue(workflow,vitalSign, true));
            }
        });

        formattedString += vitalsComponents.join(', ');

        formattedString += sectionBreak;

        formattedString += 'ECG' + getFormBadge(formLoader, workflow.pathwayUuid, 'ecgTest') + ':\n';
        formattedString += generateStemi(workflow);
        formattedString += '\n';
        formattedString += generateAbnormalities(workflow);
        formattedString += sectionBreak;
        
        formattedString += 'BLOODS' + getFormBadge(formLoader, workflow.pathwayUuid, 'initialBloods') + ':\n';
        formattedString += 'Initial troponin: ' + getReadableValue(workflow,'initialBloodsTroponin', true) + '\n';
        formattedString += 'Date and time: ' + getReadableDate(workflow, 'initialBloodsDateAndTime', 'ddd DD-MMM-YYYY, HH:mm', true, true) + '\n\n';
        formattedString += 'Follow-up troponin: ' + getReadableValue(workflow,'followUpBloodsTroponin', true) + '\n';
        formattedString += 'Date and time: ' + getReadableDate(workflow, 'followUpBloodsDateAndTime', 'ddd DD-MMM-YYYY, HH:mm', true, true) + '\n\n';
        formattedString += sectionBreak;

        formattedString += 'ALERTS ' + '\n';
        formattedString += sectionLine;
        formattedString += 'Red flags ' + getRedFlagsBadge(workflow) + ':\n';
        if(indentifiersContainsValue(workflow, true, ['aorticDissectionPresentRedFlag', 'pulmonaryEmbolismRedFlag', 'pancreatitisRedFlag', 'pericarditisRedFlag', 'pneumothoraxRedFlag', 'cardiotoxicityRedFlag'])){
            formattedString += 'High-risk differential diagnoses\n';
            formattedString += getDisparateItemsAsList(workflow, ['aorticDissectionPresentRedFlag', 'pulmonaryEmbolismRedFlag', 'pancreatitisRedFlag', 'pericarditisRedFlag', 'pneumothoraxRedFlag', 'cardiotoxicityRedFlag'], true,  'Actively considering: ', '');
            formattedString += getDisparateItemsAsList(workflow, ['aorticDissectionPresentRedFlag', 'pulmonaryEmbolismRedFlag', 'pancreatitisRedFlag', 'pericarditisRedFlag', 'pneumothoraxRedFlag', 'cardiotoxicityRedFlag'], null,  '[Not specified: ', ']');
            formattedString += getDisparateItemsAsList(workflow, ['aorticDissectionPresentRedFlag', 'pulmonaryEmbolismRedFlag', 'pancreatitisRedFlag', 'pericarditisRedFlag', 'pneumothoraxRedFlag', 'cardiotoxicityRedFlag'], false,  '(Not actively considering: ', ')');
            formattedString += '\n';
        }

        if(indentifiersContainsValue(workflow, true, ['troponinAboveCutOff', 'newIschaemicChanges'])){
            formattedString += 'High-risk investigation\n';
            formattedString += getDisparateItemsAsList(workflow, ['troponinAboveCutOff', 'newIschaemicChanges'], true,  'Actively considering: ', '');
            formattedString += getDisparateItemsAsList(workflow, ['troponinAboveCutOff', 'newIschaemicChanges'], null,  '[Not specified: [', ']');
            formattedString += getDisparateItemsAsList(workflow, ['troponinAboveCutOff', 'newIschaemicChanges'], false,  '(Not actively considering: ', ')');
            formattedString += '\n';
        }

        if(indentifiersContainsValue(workflow, true, ['redFlagsOngoingChestPain', 'crescendoAngina', 'haemodynamicInstability'])){
            formattedString += 'High-risk findings\n';
            formattedString += getDisparateItemsAsList(workflow, ['redFlagsOngoingChestPain', 'crescendoAngina', 'haemodynamicInstability'], true,  'Actively considering: ', '');
            formattedString += getDisparateItemsAsList(workflow, ['redFlagsOngoingChestPain', 'crescendoAngina', 'haemodynamicInstability'], null,  '[Not specified: ', ']');
            formattedString += getDisparateItemsAsList(workflow, ['redFlagsOngoingChestPain', 'crescendoAngina', 'haemodynamicInstability'], false,  '(Not actively considering: ', ')');
            formattedString += '\n';
        }

        formattedString += getDisparateItemsAsList(workflow, ['aorticDissectionPresentRedFlag', 'pulmonaryEmbolismRedFlag', 'pancreatitisRedFlag', 'pericarditisRedFlag', 'pneumothoraxRedFlag', 'cardiotoxicityRedFlag', 'troponinAboveCutOff', 'newIschaemicChanges', 'redFlagsOngoingChestPain', 'crescendoAngina', 'haemodynamicInstability'], false,  '(Not actively considering: ', ')');
        formattedString += getRedFlagOverrideReasons(workflow);
        formattedString += '\n';

        formattedString += 'High-risk differential diagnoses ' + getHighRiskBadge(formLoader, workflow.pathwayUuid, 'highRiskDifferentialDiagnosisResults') + ':\n';
        formattedString += getItemsInSection(workflow, ['highRiskDifferentialDiagnosisSection'], true, '', '', 'None indicated');
        formattedString += getItemsInSection(workflow, ['highRiskDifferentialDiagnosisSection'], null, '[Not specified: ', ']');
        formattedString += getItemsInSection(workflow, ['highRiskDifferentialDiagnosisSection'], false, '(Not present: ', ')');
        formattedString += '\n';

        formattedString += (formLoader.form(workflow.pathwayUuid, 'resultsForm')).getPlainTextActions(false, ['stemiAction', 'abnormalVitalSigns', 'clinicalEmergency', 'clinicalInstability', 'clinicalEmergencyDeterioration']);        

        formattedString += 'INSIGHTS\n';
        formattedString += sectionLine;
        formattedString += 'Calculated EDACS: ' + getCalculationBadge(formLoader, workflow, CalculationModel.CalculationType.edacs, 'calculatedEDACS') + '\n';
        // formattedString += 'Probability of MACE: ' + getCalculationBadge(workflow, CalculationModel.CalculationType.probabilityOfMace, 'probabilityOfMACE');
        formattedString += sectionBreak;

        //let actions = redux.store.getState().actionsReducer.actions['chestPain01'];
        formattedString += 'MANAGEMENT\n';
        formattedString += sectionLine;
        formattedString += (formLoader.form(workflow.pathwayUuid, 'resultsForm')).getPlainTextActions(false, ['acsManagementExclusionMessage', 'accuteCardiologyAssessmentRequest', 'arrangeAmbulanceTransfer', 'noFurtherManagementNeededForACS']);

        formattedString += 'INVESTIGATIONS\n';
        formattedString += sectionLine;
        formattedString += (formLoader.form(workflow.pathwayUuid, 'resultsForm')).getPlainTextActions(false, ['troponinTestRequired', 'noFurtherTroponinTestRequired', 'followUpTroponinTestRequired', 'noTroponinTestRequired', 'ecgInvestigation']);

        if(workflow.rawData['additionalRequestInformation'] != null){

            formattedString += '\n\nADDITIONAL INFORMATION\n';
            formattedString += sectionLine;
            formattedString += workflow.rawData['additionalRequestInformation'] + '';
        }

        return formattedString;
    }

}

function getReadableDate(workflow, identifier, format) {
    let date = workflow.rawData[identifier];
    if (date == null) {
        return getEmptyValue(identifier);
    }
    return PathwayFormatter.formatDate(date, format);
}

function getEmptyValue(identifier){
    let reference = PathwayReferenceHelper.reference(identifier, GPChestPainPathwayReference.data);
    return '[' + reference.title + ']';
}

function vitalsShorthandTitle(identifier){
    switch(identifier){
        case 'heartRate':
            return 'HR: ';
        case 'bloodPressure':
            return 'BP: '; 
        case 'bodyTemperature':
            return 'T: ';
        case 'oxygenSaturation':
            return 'SpO₂: ';
        case 'respiratoryRate':
            return 'RR: ';
        case 'supplementalOxygen':
            return 'Supplemental oxygen: ';
        case 'levelOfConsciousness':
            return 'Level of consciousness: ';
    }
    
}

function getReadableValue(workflow, identifier) {
    let reference = PathwayReferenceHelper.reference(identifier, GPChestPainPathwayReference.data);
    let value = workflow.rawData[identifier];
    let unit = reference[PathwayReferenceHelper.Type.textEntryUnitText];
    if (value == null) { 
        return getEmptyValue(identifier); 
    }

    switch (reference[PathwayReferenceHelper.Type.controlType]) {
        case FormsContentType.ControlType.plain:
        case FormsContentType.ControlType.badge:
        case FormsContentType.ControlType.calculation:
        case FormsContentType.ControlType.action:
        case FormsContentType.ControlType.picker:
        case FormsContentType.ControlType.weblink:
        case FormsContentType.ControlType.textEntry: {
            if(identifier == 'initialBloodsTroponin' || identifier == 'followUpBloodsTroponin'){
                if(value == 0 || value == 1){
                    return '<2' + (unit == null ? '' : unit);
                }
            }
            return value + (unit == null ? '' : unit);
        }
        case FormsContentType.ControlType.radio:
            return value == true ? 'Yes' : 'No';
        case FormsContentType.ControlType.selection:
            if (reference[PathwayReferenceHelper.Type.selectionType] == FormsContentType.SelectionType.explicit) {
                return value == true ? 'Yes' : 'No';
            } else if (reference[PathwayReferenceHelper.Type.selectionType] == FormsContentType.SelectionType.simple) {
                let optionReference = PathwayReferenceHelper.reference(value, GPChestPainPathwayReference.data);
                return optionReference.title;
            }
    }
}

function getItemsInSection(workflow, identifiers, valueState, openingString, closingString) {
    let filteredItems = [];
    for (let identifier of identifiers) {
        let listReference = PathwayReferenceHelper.reference(identifier, GPChestPainPathwayReference.data);
        filteredItems = filteredItems.concat(
            listReference[PathwayReferenceHelper.Type.sectionChildIDs].filter((itemID) => {
                return workflow.rawData[itemID] == valueState && !itemID.includes('noToAll');
            })
        );
    }

    if (filteredItems.length == 0) { return ''; }

    return formatList(filteredItems, openingString, closingString, workflow);
}

function formatList(identifiers, openingString, closingString, workflow) {
    return openingString +
    identifiers.map((itemID) => {
        if(itemID == 'alternativeFinding'){
            return (
                'Alternative finding: (' + (workflow.rawData['alternativeFindingSpecify'] == null ? '[Alternative Finding]' : workflow.rawData['alternativeFindingSpecify']) + '))'
            );
        }
        let title = (PathwayReferenceHelper.reference(itemID, GPChestPainPathwayReference.data).title);
        if (title == undefined || title == null){
            return null;
        }
        if(title.includes(',')){
            return '\'' + title + '\'' + (itemID != identifiers[identifiers.length - 1] ? ', ' : '');
        } else {
            return title + (itemID != identifiers[identifiers.length - 1] ? ', ' : '');
        }
    }).filter( n => n).join(' ') 
    + closingString + '\n';     
}

function generateStemi(workflow) {
    let totalString = 'STEMI criteria\n';
    let stemiPresent = workflow.rawData['stemiPresent'];

    if (stemiPresent == null) { totalString += 'No STEMI criteria present'; return; }
    if (stemiPresent != null && stemiPresent) {
        totalString += getItemsInSection(workflow, ['stemiCriteriaSection'], true, 'Present: ', '');
        totalString += getItemsInSection(workflow, ['stemiCriteriaSection'], null, 'Not specified: ', '');
        return totalString;
    } else {
        return 'Not STEMI';
    }
}

function generateAbnormalities(workflow) {
    let totalString = 'Other abnormalities\n';
    let abnormalitiesPresent = workflow.rawData['abnormalitiesPresent'];

    if (abnormalitiesPresent == null) { totalString += 'No Other abnormalities present'; return; }
    if (abnormalitiesPresent != null && abnormalitiesPresent) {
        totalString += getItemsInSection(workflow, ['abnormalitiesCriteriaSection'], true, 'Present: ', '');
        totalString += getItemsInSection(workflow, ['abnormalitiesCriteriaSection'], false, 'Not present: ', '');
        totalString += getItemsInSection(workflow, ['abnormalitiesCriteriaSection'], null, 'Not specified: ', '');
        return totalString;
    } else {
        return 'No Other abnormalities';
    }
}

function indentifiersContainsValue(workflow, value, identifiers){
    return identifiers.map((identifier) => {
        if (workflow.rawData[identifier + 'Override'] == true) {
            return workflow.rawData[identifier + 'OverrideValue'] == value;
        }
        return workflow.rawData[identifier] == value;
    }).includes(true);
}

function getDisparateItemsAsList(workflow, identifiers, valueState, openingString, closingString) {
    let filteredItems = identifiers.filter((itemID) => {
        if (workflow.rawData[itemID + 'Override'] == true) {
            return workflow.rawData[itemID + 'OverrideValue'] == valueState;
        } else {
            return workflow.rawData[itemID] == valueState;
        }
    });

    if (filteredItems.length == 0) { return ''; }

    return formatList(filteredItems, openingString, closingString);
}

function getRedFlagOverrideReasons(workflow) {
    let redflags = [
        'aorticDissectionPresentRedFlag',
        'pulmonaryEmbolismRedFlag',
        'pancreatitisRedFlag',
        'pericarditisRedFlag',
        'pneumothoraxRedFlag',
        'cardiotoxicityRedFlag',
        'troponinAboveCutOff',
        'newIschaemicChanges',
        'redFlagsOngoingChestPain',
        'crescendoAngina',
        'haemodynamicInstability'
    ];

    let overrideValues = redflags.filter((overrideID) => {
        if (workflow.rawData[overrideID + 'Override'] == true) {
            return true;
        }
        return null;
    }).filter(n => n);

    if(overrideValues.length == 0) { return '';}
    let totalString = 'Red Flag Override Reasons';
    totalString += overrideValues.map((redFlag) => {
        return PathwayReferenceHelper.reference(redFlag, GPChestPainPathwayReference.data).title + ': ' + (workflow.rawData[redFlag + 'OverrideReason'] != null ? workflow.rawData[redFlag + 'OverrideReason'] : getEmptyValue(redFlag + 'OverrideReason'));
    }).join('\n');
    return totalString;
}

function getCalculationBadge(formLoader, workflow, calculationType, identifier) {
    let mainForm = formLoader.form(workflow.pathwayUuid, 'mainForm');
    let error = GPChestPainCalculationHelper.calcualtionError(identifier, workflow.rawData, mainForm);
    
    if(error != null){
        return error.message;
    }

    let pathwayProgress = mainForm.completionProgress(['followUpBloodsDateAndTime', 'followUpBloodsTroponin']);
    if(pathwayProgress == null){
        return getEmptyValue(identifier);
    }

    if(((calculationType == CalculationModel.CalculationType.riskCategory) && (pathwayProgress.current / pathwayProgress.total) != 1)){
        return getEmptyValue(identifier);
    }

    let calculations = redux.store.getState().calculationReducer.calculations['gpChestPain01'];

    let calculation = GPChestPainCalculationHelper.getLatestCalculation(calculationType, calculations);
    if (calculation == null) { return getEmptyValue(identifier); }
    calculation = CalculationModel.rehydrate(calculation);
    return calculation.valueString + (calculation.rating != null ? ' (' + calculation.rating + ')' : '');
}

function getTotalEWS(workflow){
    let identifiers = [
        'heartRateEWS',
        'bloodPressureEWS',
        'bodyTemperatureEWS',
        'oxygenSaturationEWS',
        'respiratoryRateEWS',
        'supplementalOxygenEWS',
        'levelOfConsciousnessEWS'
    ];
    let categories = identifiers.map((identifier) => {
        let value = workflow.rawData[identifier.replace('EWS', '')];
        return calculateEWS(identifier, value);
    }).filter(n => n);

    // Only display an EWS if all categories are represented.
    if (categories.length < identifiers.length) {
        return null;
    }
    let declaredNormal = workflow.rawData['areSignsNormal'];
    let totalEWS = new TotalEWS(categories, declaredNormal);

    return totalEWS;
}

function getRedFlagsBadge(workflow) {
    let totalRedFlags = workflow.rawData['totalRedFlags'];
    if (totalRedFlags != null && totalRedFlags > 0) {
        return ' - ' + totalRedFlags + ' ALERT' + (totalRedFlags == 1 ? '' : 'S');
    }
    return ('');
}

function getHighRiskBadge(formLoader, pathwayId, identifier){
    let badges = (formLoader.form(pathwayId, 'resultsForm')).getBadgeValue(identifier);
    if(badges.length > 0){
        let badgeStrings = [];
        for(let badge of badges){
            badgeStrings.push('- ' + badge.value);
        }
        return badgeStrings.join('');
    }
    return '';
}

function getFormBadge(formLoader, pathwayId, identifier){
    let badges = (formLoader.form(pathwayId, 'mainForm')).getBadgeValue(identifier);
    if(badges.length > 0){
        let badgeStrings = [];
        for(let badge of badges){
            badgeStrings.push(' [' + badge.value + ']');
        }
        return badgeStrings.join('');
    }
    return '';
}