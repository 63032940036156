export var ACCBISTSidebar = {
    root: {
        sections: [
            {
                name: 'Notes',
                icon: 'notes',
                path: 'notesForm',
                showPie: false,
                showBadge: false
            },
            {
                name: 'Pathway',
                path: 'mainForm',
                icon: 'pathway',
                showPie: false,
                sections: [
                    {
                        name: 'Patient Details',
                        path: 'patientDetailsSubform',
                        showPie: true,
                        showBadge: true
                    },
                    {
                        name: 'Injury Details',
                        path: 'injuryDetailsSubform',
                        showPie: true,
                        showBadge: true
                    },
                    {
                        name: 'Clinical Assessment',
                        path: 'clinicalAssessmentSubform',
                        showPie: true,
                        showBadge: true
                    },
                    {
                        name: 'Concussion Assessment',
                        path: 'mainForm/concussionAssessmentSection',
                        showPie: false,
                        sections: [
                            {
                                name: 'A-WPTA Registration',
                                path: 'awptaCheck1Subform',
                                showPie: true,
                                showBadge: true,
                            },
                            {
                                name: 'A-WPTA Recall 1',
                                path: 'awptaCheck2Subform',
                                showPie: true,
                                showBadge: true,
                            },
                            {
                                name: 'A-WPTA Recall 2',
                                path: 'awptaCheck3Subform',
                                showPie: true,
                                showBadge: true,
                            },
                            {
                                name: 'A-WPTA Recall 3',
                                path: 'awptaCheck4Subform',
                                showPie: true,
                                showBadge: true,
                            },
                            {
                                name: 'A-WPTA Recall 4',
                                path: 'awptaCheck5Subform',
                                showPie: true,
                                showBadge: true,
                            },
                            {
                                name: 'BIST Symptom Scale',
                                path: 'bistSymptomScale',
                                showPie: true,
                                showBadge: true
                            }
                        ]
                    },
                    {
                        name: 'Investigations',
                        path: 'mainForm/investigationsSection',
                        showPie: false,
                        sections: [
                            {
                                name: 'Neuro Obs',
                                path: 'gcsSubform',
                                showPie: true,
                                showBadge: true
                            },
                            {
                                name: 'Vital Signs',
                                path: 'vitalSignsSubform',
                                showPie: true,
                                showBadge: true
                            },
                            {
                                name: 'CT Brain',
                                path: 'ctBrainSubform',
                                showPie: true,
                                showBadge: true
                            }
                        ]
                    }
                ]
            },
            {
                name: 'Insights',
                icon: 'results',
                path: 'resultsForm',
                showPie: false,
                showBadge: true
            }
            // {
            //     name: 'Outcome',
            //     icon: 'results',
            //     path: 'outcome',
            //     showPie: false
            // }
        ]
    }
};