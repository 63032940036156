import { FormWithRedux } from '../../FormWithRedux';
import { FormsUIGlossary } from '../../../waferJS/FormsUI/FormsUI';

export class GPChestPainHighRiskDifferentialDiagnosisForm extends FormWithRedux {

    constructor(formLoader, parent, actionUpdater, dependentValuesUpdater, calculationsUpdater) {
        super('highRiskDifferentialDiagnosis',formLoader, parent, actionUpdater, dependentValuesUpdater, calculationsUpdater);

        this.submissionValidation = this.submissionValidation.bind(this);
    }
    
    shouldDisplayFormDetailForIdentifier(identifier) {
        super.shouldDisplayFormDetailForIdentifier(identifier);
        if(identifier == 'pathwayNotRelevantWarning'){
            return this.getValue('clinicalSuspicionOfAcuteCoronorySyndrome') == 'noneOption';
        }
        else if(identifier == 'suspicionHighHelpMessage') {
            return this.getValue('clinicalSuspicionOfAcuteCoronorySyndrome') == 'highOption';
        }
        else if(identifier == 'suspicionNoneHelpMessage'){
            return this.getValue('clinicalSuspicionOfAcuteCoronorySyndrome') == 'noneOption';
        }
        else if(identifier == 'suspicionUnsureHelpMessage'){
            return this.getValue('clinicalSuspicionOfAcuteCoronorySyndrome') == 'lowOption';
        } else if(identifier == 'noToAllHighRiskDifferentialDiagnosis'){
            return this.unselectedExplicitFormDetailsCount(identifier) > 1;
        }

        return true;
    }

    isEnabled(identifier) {
        if (identifier != null) {
            return true;
        }
        return false;
    }

    isMandatory(identifier) {
        if(identifier == 'pathwayNotRelevantWarning' || identifier == 'suspicionHighHelpMessage' || identifier == 'suspicionUnsureHelpMessage' || identifier == 'suspicionNoneHelpMessage' || identifier == 'noToAllHighRiskDifferentialDiagnosis'){
            return false;
        }
        return true;
    }

    getValueType() {
        return 'boolean';
    }

    submissionValidation() {
        return [];
    }

    detailString() {
        var finalDetailString;

        let formDetails = this.getFormDetails();
        var valueDescriptions = [];
        var aorticDissectionDescription;

        for (let formDetailIndex in formDetails) {
            let formDetail = formDetails[formDetailIndex];

            let description = formDetail.valueDescription();

            switch (formDetail.identifier) {

                case 'pulmonaryEmbolism':
                    if (description != null && description != false) {
                        valueDescriptions.push(description.slice(0, -1));
                    }
                    break;
                case 'aorticDissectionPresent':
                    if (description != null && description != false) {
                        aorticDissectionDescription = FormsUIGlossary.titlePrefix + ' Aortic Dissection';
                    }
                    break;
                case 'aorticDissectionCriteria':{
                    let subform = this.subform(formDetail.identifier);
                    let aorticDissectionCriteria = subform.detailString(true);
                    if (aorticDissectionCriteria != null && aorticDissectionCriteria.length > 0) {
                        aorticDissectionDescription += '\n' + aorticDissectionCriteria;
                    }
                    break;
                
                }
                case 'clinicalSuspicionOfAcuteCoronorySyndrome':{
                    if(description != null){
                        let selection = this.getValue('clinicalSuspicionOfAcuteCoronorySyndrome');
                        let selectionString;
                        if(selection == 'noneOption'){
                            selectionString = 'None';
                        } else if(selection == 'lowOption'){
                            selectionString = 'Low';
                        } else if(selection == 'highOption'){
                            selectionString = 'High';
                        }

                        valueDescriptions.push('Clinical suspicion of Acute coronary syndrome (ACS): ' + selectionString);
                    }
                    break;
                }
                default:
                    if (description != null && description.length > 0) {
                        valueDescriptions.push(description);
                    }

            }
        }

        if (aorticDissectionDescription != null) {
            valueDescriptions = [aorticDissectionDescription].concat(valueDescriptions);
        }

        if(valueDescriptions.length == 0){
            return this.detail;
        }

        finalDetailString = valueDescriptions.join(' \n');
        return finalDetailString;
    }

}