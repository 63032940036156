// IMPORT PACKAGE REFERENCES
import React from 'react';
import PropTypes from 'prop-types';
import { FormsStyles } from '../../FormsUI';
import '../../Styles/info.scss';
import propTypes from 'prop-types';
import { Info } from 'phosphor-react';

//COMPONENT
export class FormInfo extends React.Component {

    constructor(props) {
        super(props);

        this.state ={
            infoOpen: props.infoOpen,
            infoString: props.infoString,
            onInfoOpen: props.onInfoOpen,
        };
    }

    shouldComponentUpdate(nextProps, nextState) {
        if(nextProps.infoOpen !== this.props.infoOpen){
            return true;
        }
        if(nextState.infoOpen !== this.state.infoOpen){
            return true;
        }
        if(nextProps.infoString !== this.props.infoString){
            return true;
        }
        if(nextState.infoString !== this.state.infoString){
            return true;
        }
        if(nextProps.onInfoOpen !== this.props.onInfoOpen){
            return true;
        }
        if(nextState.onInfoOpen !== this.state.onInfoOpen){
            return true;
        }

        return false;
    }

    render() {
        return (
            <div className='info-wrapper'>
                <div className='info-icon' onClick={this.props.onInfoOpen}>
                    <Info size={20} color="#009cff" weight="bold" />
                </div>
                <div className={`${this.props.infoOpen ? 'info-list-open' : 'info-list'}`}>
                    <span className='info-title'>{this.props.infoString}</span>
                </div>
            </div>
        );
    }
}

FormInfo.propTypes = {
    infoOpen: PropTypes.bool,
    infoString: PropTypes.string,
    onInfoOpen: PropTypes.func,
};