// IMPORT PACKAGE REFERENCES
import React from 'react';
import PropTypes from 'prop-types';
import { FormsStyles } from '../../../src/waferJS/FormsUI/FormsUI';
import { FormButton } from '../../../src/waferJS/FormsUI/FormsUI Components/Controls/FormButton';

// COMPONENT
export class ImageModal extends React.Component {

    constructor(props) {
        super(props);
        
        this.state = {
            imageURL: props.image,
            title: props.title,
            isOpen: false
        };
        
        this.portraitOrLandspace();
        this.setWrapperReference = this.setWrapperReference.bind(this);
        this.handleDialogVisibility = this.handleDialogVisibility.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);
        this.portraitOrLandspace = this.portraitOrLandspace.bind(this);
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside);
    }
    
    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    setWrapperReference(node) {
        this.wrapperRef = node;
    }

    handleClickOutside(event) {
        if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
            this.handleDialogVisibility();
        }
    }

    handleDialogVisibility() {
        this.setState({
            isOpen: !this.state.isOpen
        });
    }

    portraitOrLandspace() {
        let image = new Image();
        image.src = this.state.imageURL;
        let width = image.width;
        let height = image.height;
        
        return width > height ? 'landscape' : 'portrait';
    }

    render() {
        return (
            <span className="session-wrapper">
                <a className="modal-image-link" onClick={this.handleDialogVisibility}>{this.props.title}</a>
                {this.state.isOpen && 
                <span className="modal-wrapper">
                    <div className="modal-overlay" />
                    <div className="modal-manager-container">
                        <div className={'modal-image-wrapper ' + this.portraitOrLandspace()} ref={this.setWrapperReference}>
                            <div className="modal-image-exit-button-container">
                                <FormButton title={''} image={<img className={'modal-image-exit'} src={require('../../../src/images/cross-blue.png')}/>} backgroundColor={FormsStyles.backgroundColor} enabled={true} isSelected={false} onPress={this.handleDialogVisibility}/>
                            </div>
                            <img id='image' className={'modal-image ' + this.portraitOrLandspace()} src={this.props.image}/>
                        </div>
                    </div>
                </span>
                }
            </span>
        );
    }
}

ImageModal.propTypes = {
    image: PropTypes.string,
    title: PropTypes.string
};