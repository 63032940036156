import React, { ReactNode } from "react";
import { MsalProvider } from "@azure/msal-react";
import { PublicClientApplication } from "@azure/msal-browser";
import { AuthConfig } from "../../config/AuthConfig";

export const msalInstance = new PublicClientApplication(AuthConfig);

interface AppAuthProviderProps {
  children: ReactNode;
}

const AppAuthProvider: React.FC<AppAuthProviderProps> = (prop) => {
  return <MsalProvider instance={msalInstance}>{prop.children}</MsalProvider>;
};

export const getAuthUserInfoFromToken = async () => {
  const UNKNOWN_USER_NAME = "Unknown";
  const accounts = msalInstance.getAllAccounts();
  if (!accounts || accounts.length === 0) {
    return undefined;
  }
  const currentAccount = accounts[0];

  const name =
    currentAccount.idTokenClaims?.nickname?.toString() ?? UNKNOWN_USER_NAME;

  return name && name.length > 0 ? name : UNKNOWN_USER_NAME;
};

export default AppAuthProvider;
