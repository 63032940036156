// IMPORT PACKAGE REFERENCES
import React from 'react';
import PropTypes from 'prop-types';
import { FormLabel } from '../Controls/FormLabel';
import { FormsStyles } from '../../FormsUI';
import '../../Styles/waferStyles.scss';
import '../../Styles/contentView.scss';
import { FormInfo } from '../Controls/FormInfo';

//COMPONENT
export class FormContentView extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            subCellbackgroundColor: props.subCellbackgroundColor == null ? 'transparent' : props.subCellbackgroundColor,
            titleColor: props.titleColor == null ? FormsStyles.defaultText : props.titleColor,
            strikeThrough: props.strikeThrough == null ? false : props.strikeThrough,
            allowFocus: props.allowFocus == null ? false : props.allowFocus,
            usingMouse: true,
            infoOpen: false,
        };

        this.wrapperRef = React.createRef();

        this.onInfoOpen = this.onInfoOpen.bind(this);
        this.onInfoClose = this.onInfoClose.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.subCellbackgroundColor != this.props.subCellbackgroundColor) {
            this.setState({
                subCellbackgroundColor: this.props.subCellbackgroundColor
            });
        }
    }

    shouldComponentUpdate(nextProps, nextState) {
        if(this.props.titleString !== nextProps.titleString){
            return true;
        }

        if(this.props.detailString !== nextProps.detailString){
            return true;
        }

        if(this.props.topLeftControl !== nextProps.topLeftControl){
            return true;
        }

        if(this.props.topRightControl !== nextProps.topRightControl){
            return true;
        }

        if(this.props.leftControl !== nextProps.leftControl){
            return true;
        }

        if(this.props.subCellbackgroundColor !== nextProps.subCellbackgroundColor){
            return true;
        }

        if(this.state.subCellbackgroundColor !== nextState.subCellbackgroundColor){
            return true;
        }

        if(this.props.backgroundColor !== nextProps.backgroundColor){
            return true;
        }

        if(this.props.subcellHeight !== nextProps.subcellHeight){
            return true;
        }

        if(this.props.pointerRef !== nextProps.pointerRef){
            return true;
        }

        if(this.props.subcellRef !== nextProps.subcellRef){
            return true;
        }

        if(this.props.isMandatory !== nextProps.isMandatory){
            return true;
        }

        if(this.props.bottomControl !== nextProps.bottomControl){
            return true;
        }

        if(this.props.rightControl !== nextProps.rightControl){
            return true;
        }

        if(this.props.subcellBorder !== nextProps.subcellBorder){
            return true;
        }

        if(this.props.showHover !== nextProps.showHover){
            return true;
        }

        if(this.props.titleColor !== nextProps.titleColor){
            return true;
        }

        if(this.props.isSubcell !== nextProps.isSubcell){
            return true;
        }

        if(this.props.strikeThrough !== nextProps.strikeThrough){
            return true;
        }

        if(this.props.identifier !== nextProps.identifier){
            return true;
        }

        if(this.props.allowFocus !== nextProps.allowFocus){
            return true;
        }

        if(this.props.focusRef !== nextProps.focusRef){
            return true;
        }

        if(this.props.subcellVisible !== nextProps.subcellVisible){
            return true;
        }

        if(this.state.titleColor !== nextState.titleColor){
            return true;
        }

        if(this.state.strikeThrough !== nextState.strikeThrough){
            return true;
        }

        if(this.state.allowFocus !== nextState.allowFocus){
            return true;
        }

        if(this.state.usingMouse !== nextState.usingMouse){
            return true;
        }

        if(this.props.subcell.length !== nextProps.subcell.length){
            return true;
        }

        if(this.props.titleAccessory !== nextProps.titleAccessory){
            return true;
        }
        if(this.props.info != nextProps.info){
            return true;
        }
        if(this.state.infoOpen !== nextState.infoOpen){
            return true;
        }

        if(this.props.validation !== nextState.validation){
            return true;
        }

        return false;
    }

    onInfoOpen() {
        this.setState({
            infoOpen: !this.state.infoOpen,
        });
    }

    onInfoClose() {
        this.setState({
            infoOpen: false,
        });
    }

    handleClickOutside(event) {
        if(this.wrapperRef && this.wrapperRef.current && !this.wrapperRef.current.contains(event.target)) {
            if(this.state.infoOpen) {
                this.setState({
                    infoOpen: false,
                });
            }
        }
    }

    render() {

        var style = {
            borderRadius: this.props.backgroundColor != null ? '12px' : '0',
            backgroundColor: this.props.backgroundColor,
        };

        var subcellStyle = {
            backgroundColor: this.state.subCellbackgroundColor,
            borderRadius: this.state.subCellbackgroundColor != 'transparent' ? '15px' : '0',
            marginTop: (this.props.subcell != null && this.props.subcell.length > 0) && this.props.subcellVisible != false ? '0.5em' : '0'
        };

        var contentViewBottom = {
            // marginBottom: this.props.subcell != undefined && this.props.subcell.length > 0 && this.props.bottomControl != null ? '1em' : '0em'
        };

        var contentViewValidation = {
            marginTop: this.props.validation != null && this.props.validation.length > 0 ? '0.1em' : '0',
        };

        var contentViewTop = {
            paddingBottom: this.props.bottomControl == null && this.props.isSubcell ? '0em' : '0'
        };
        
        var descriptionStyle = {
            marginTop: this.props.detailString != null ? '0.2em' : '0',
            // marginBottom: this.props.detailString != null ? '0.5em' : '0',
        };

        return (
            <div className={'avoidPageBreak ' + (this.props.showHover != null && this.props.showHover ? 'active-indicator ' : '')} tabIndex={this.props.allowFocus ? '0' : '-1'} ref={this.props.focusRef}>
                <div className={'content-view ' + ((this.props.backgroundColor == null || this.props.backgroundColor == null && (this.props.showHover != null && this.props.showHover)) ? '' : 'padding')} style={style}>
                    <div className={'contentViewTop ' + (this.props.forceRow == true ? 'force-row': '')} ref={this.props.pointerRef} style={contentViewTop}>

                        {this.props.leftControl != null && 
                            <div className={'content-view-top-left '}>
                                {this.props.leftControl}
                            </div>
                        }

                        {(this.props.topLeftControl != null || this.props.titleString != null  || this.props.topRightControl != null || this.props.detailString != null || this.props.validation != null) &&
                            <div className={'content-view-top-main ' + (this.props.isSubcell != null && this.props.isSubcell ? ' subcell ' : '') + (this.props.rightControl != null ? 'padding ' : '')}>
                                {(this.props.topLeftControl != null || this.props.titleString != null  || this.props.topRightControl != null) && 
                                    <div className='content-view-top-main-header'>

                                        {this.props.topLeftControl != null &&
                                            <div className={'content-view-top-main-header-left '}>
                                                {this.props.topLeftControl}
                                            </div>
                                        }
                                        
                                        {this.props.titleString != null &&
                                            <div className={'content-view-top-main-header-title ' + (this.props.titleString == null ? 'empty ' : '' )}>
                                                {this.props.titleString != null &&
                                                    <FormLabel key={'content-view-title-label '} value={this.props.titleString} fontSize={1} textColor={this.state.titleColor} strikeThrough={this.state.strikeThrough} isMandatory={this.props.isMandatory == null ? false : this.props.isMandatory} />
                                                }

                                                {this.props.titleAccessory != null && this.props.titleAccessory}
                                                {this.props.info != null &&
                                                  <div className='info-container' ref={this.wrapperRef}>                                         
                                                    <FormInfo infoOpen={this.state.infoOpen} onInfoOpen={this.onInfoOpen} infoString={this.props.info} />
                                                  </div> 
                                                }

                                            </div>
                                        }

                                        {this.props.topRightControl != null &&
                                            <div className={'content-view-top-main-header-right '}>
                                                {this.props.topRightControl}
                                            </div>
                                        }    
                                
                                    </div>
                                }
                                    {(this.props.validation != null && this.props.validation.length > 0) &&
                                        <div className={'content-view-validation ' + ( this.props.isSubcell ? 'subcell' : '')} style={contentViewValidation}>
                                            {this.props.validation != undefined && this.props.validation.map((error) => {
                                                return error;
                                            })}
                                        </div>
                                    }

                                {this.props.detailString != null &&
                                    <div className={'content-view-top-main-detail'} style={descriptionStyle}>
                                        <FormLabel hidden={this.props.detailString != null} value={this.props.detailString} fontSize={0.9} textColor={FormsStyles.bodyText} strikeThrough={this.state.strikeThrough} />
                                    </div>
                                }
                            </div>
                        }

                        {this.props.rightControl != null &&
                            <div className={'content-view-top-right '}>
                                {this.props.rightControl}
                            </div>
                        }
                        
                    </div>
                    {this.props.bottomControl != null &&
                        <div className={'content-view-bottom '} style={contentViewBottom}>
                            {this.props.bottomControl}
                        </div>
                    }
                    {
                        this.props.subcell != undefined && this.props.subcell.length > 0 &&
                        <div ref={this.props.subcellRef} className={'content-view-subcell ' + (this.props.subcell.length > 0 && this.state.subCellbackgroundColor != 'transparent' && this.props.subcellHeight != '0px' ? 'subcell-padding' : '')} style={subcellStyle}>
                            {this.props.subcell != undefined && this.props.subcell.map((subcell) => {
                                return subcell;
                            })}
                        </div>
                    }
                </div>
            </div>
        );
    }
}

FormContentView.propTypes = {
    titleString: PropTypes.string,
    titleAccessory: PropTypes.element,
    detailString: PropTypes.string,
    topLeftControl: PropTypes.element,
    topRightControl: PropTypes.element,
    leftControl: PropTypes.element,
    subcell: PropTypes.array,
    subCellbackgroundColor: PropTypes.string,
    backgroundColor: PropTypes.string,
    subcellHeight: PropTypes.string,
    pointerRef: PropTypes.func,
    subcellRef: PropTypes.func,
    isMandatory: PropTypes.bool,
    bottomControl: PropTypes.element,
    rightControl: PropTypes.element,
    validation: PropTypes.array,
    subcellBorder: PropTypes.bool,
    showHover: PropTypes.bool,
    titleColor: PropTypes.string,
    isSubcell: PropTypes.bool,
    strikeThrough: PropTypes.bool,
    identifier: PropTypes.string.isRequired,
    allowFocus: PropTypes.bool,
    focusRef: PropTypes.func,
    subcellVisible: PropTypes.bool,
    forceRow: PropTypes.bool,
    info: PropTypes.string,
};