import { FormWithRedux } from '../../FormWithRedux';
import { OsteoporosisIntroForm } from '../IntroForm/Osteoporosis-IntroForm';
import { OsteoporosisResultsForm } from '../ResultsForm/Osteoporosis-ResultsForm';
import { OsteoporosisPatientDetailsForm } from '../PatientDetails/Osteoporosis-PatientDetails';
import { OsteoporosisRequestCriteriaForm } from '../RequestCriteria/Osteoporosis-RequestCriteria';
import { OsteoporosisFurtherInformationForm } from '../FurtherInformation/Osteoporosis-FurtherInformation';
import { OsteoporosisActions } from '../Actions/Osteoporosis-Actions';
import { OsteoporosisDependantValuesUpdater } from '../Calculations/Osteoporosis-DependantValuesUpdater';
import { OsteoporosisCalculations } from '../Calculations/Osteoporosis-Calculations';

export class OsteoporosisMainForm extends FormWithRedux {

    constructor(formLoader) {
        super('mainForm', formLoader, null, new OsteoporosisActions(formLoader), new OsteoporosisDependantValuesUpdater(formLoader), new OsteoporosisCalculations());
    }

    generateSubform(identifier) {
        switch (identifier) {
            case 'introForm':
                return new OsteoporosisIntroForm(this.formLoader);
            case 'resultsForm':
                return new OsteoporosisResultsForm(this.formLoader, this, this.actionUpdater, this.dependentValuesUpdater, this.calculationsUpdater);
            case 'patientDetails':
                return new OsteoporosisPatientDetailsForm(this.formLoader, this, this.actionUpdater, this.dependentValuesUpdater, this.calculationsUpdater);
            case 'requestCriteria':
                return new OsteoporosisRequestCriteriaForm(this.formLoader, this, this.actionUpdater, this.dependentValuesUpdater, this.calculationsUpdater);
            case 'furtherInformation':
                return new OsteoporosisFurtherInformationForm(this.formLoader, this, this.actionUpdater, this.dependentValuesUpdater, this.calculationsUpdater);
        }
    }

    shouldDisplayFormDetailForIdentifier(identifier) {
        super.shouldDisplayFormDetailForIdentifier(identifier);
        return true;
    }

    isEnabled(identifier) {
        if (identifier != null) {
            return true;
        }
        return false;
    }

    isMandatory() {
        return false;
    }

    getValueType() {
        return null;
    }

    submissionValidation() {
        return [];
    }

    detailString() {
        return this.detail;
    }
}