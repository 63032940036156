import healthNZAxios from "../components/Common/HealthNZAxios";
import { AxiosError } from "axios";

export interface UserInfo {
  Name: string;
  ConfidenceLevel: number | undefined;
  HPINumber: string | undefined;
  Email: string | undefined;
}

const getUserInfoFromB2CServer = (callback: (data?: UserInfo, error?: string) => void) => {
  healthNZAxios
    .get("/openid/v2.0/userinfo")
    .then((response) => {
      if (response.status === 200) {
        const userInfo: UserInfo = {
          Name: response.data.given_name + " " + response.data.family_name,
          ConfidenceLevel: parseInt(response.data["urn:login:health:nz:claims:confidence_level"]),
          HPINumber: response.data["urn:login:health:nz:claims:cpn"],
          Email: response.data.email,
        };
        return callback(userInfo, undefined);
      } else {
        return callback(undefined, "There was an error getting user info. Please contact support.");
      }
    })
    .catch((err: AxiosError) => {
      return callback(undefined, err.message);
    });
};

export default getUserInfoFromB2CServer;
