import { FormWithRedux } from '../../FormWithRedux';

export class ACCMRI_KP_OKR_CriteriaForm extends FormWithRedux {

    constructor(formIdentifier, formLoader, parent, actionUpdater, dependentValuesUpdater, calculationsUpdater) {
        super(formIdentifier, formLoader, parent, actionUpdater, dependentValuesUpdater, calculationsUpdater);
    }

    shouldDisplayFormDetailForIdentifier(identifier) {
        super.shouldDisplayFormDetailForIdentifier(identifier);
        return true;
    }

    isEnabled(identifier) {
        if (identifier != null) {
            return true;
        }
        return false;
    }

    isMandatory() {
        if (this.getValue('postTraumaticKneePain') == true) {

            let values = [
                this.getValue('KP_OKR_overAge'),
                this.getValue('KP_OKR_tendernessOfPatella'),
                this.getValue('KP_OKR_tendernessOfFibula'),
                this.getValue('KP_OKR_inabilityToFlex'),
                this.getValue('KP_OKR_inabilityToBearWeight')];

            if (values.includes(true) || values.includes(false)) {
                return true;
            }
        }

        return false;
    }

    getValueType() {
        return 'boolean';
    }

    submissionValidation() {
        return [];
    }

    detailString(isSubform) {
        let formDetails = this.getFormDetails();
        let values = formDetails.map((value) => {
            if (value.currentValue == true) {
                return value.title + ': Yes';
            }
        });
        
        if(values.filter(n => n).length == 0){
            return isSubform ? '' : this.detail;
        }
        
        if (isSubform == true) {
            return values.filter(n => n).join('\n  ');
        }
        return values.filter(n => n).join('\n');
    }

}