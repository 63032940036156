import { AxiosError } from "axios";
import nhiAxios from "../components/Common/NHIAxios";
import { getDisplayErrorMessage } from "../helpers/Utils";
import { ACC_API_ROOT } from "../config/config";

export interface Patient {
  NHINumber: string;
  Name: string;
  Address: string;
  BirthDate: string | undefined;
  Gender: string;
  Ethnicity: string;
}

const getPatientByNHINumber = (nhiNumber: string, callback: (data: Patient | undefined, error: string | undefined) => void) => {
  nhiAxios.defaults.baseURL = ACC_API_ROOT;
  nhiAxios
    .get("api/NHI/GetPatientByNHINumber?patientNHINumber=" + nhiNumber)
    .then((response) => {
      if (response.status === 200) {
        return callback(response.data, undefined);
      } else {
        return callback(undefined, response.data.detail);
      }
    })
    .catch((error: AxiosError) => {
      return callback(undefined, getDisplayErrorMessage(error));
    });
};

export default getPatientByNHINumber;
