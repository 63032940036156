import { FormValidationError } from './Validation/FormValidationError';
import { FormValidation } from './Validation/FormValidation';
import { getPathwayIdFromURL } from '../../helpers/URLHelper';

export var FormEditingState = Object.freeze({ view: 1, edit: 2, validate: 3 });

export class Form {

    constructor(identifier, formLoader) {
        this.editingState = FormEditingState.view;
        this.formIdentifier = identifier;
        this.formLoader = formLoader;

        this.subformIdentifiers = [];
        this.formDetailIdentifiers = [];
        this.generatedSectionReferences = [];

        this.sectionIds = [];
    }

    set editingState(value) {
        switch (value) {
            case FormEditingState.view, FormEditingState.edit, FormEditingState.validate:
                this._editingSate = value;
                break;
            default:
                return;
        }
    }

    subform(identifier) {
        return this.formLoader.form(getPathwayIdFromURL(), identifier);
    }

    getFormDetails() {
        let self = this;
        return this.formDetailIdentifiers.map(function (identifier) {
            return self.formDetail(identifier);
        });
    }

    formDetail(identifier) {
        return this.formLoader.formDetail(getPathwayIdFromURL(), identifier);
    }

    sectionReference(identifier) {
        let existingSectionReference = this.generatedSectionReferences[identifier];
        if (existingSectionReference != undefined) {
            return existingSectionReference;
        } else {
            return this.generateSectionReference(identifier);
        }
    }

    generateSectionReference(identifier) {
        throw new Error('Override required ' + identifier);
    }

    exisitngSectionReference(identifier) {
        throw new Error('Override required ' + identifier);
    }

    // Assume true for all, override if custom logic is required.
    isMandatory() {
        return true;
    }

    // Assume true for all, override if custom logic is required.
    isEnabled() {
        return true;
    }

    //MARK: - FormValidation
    areAllCurrentValuesValid() {
        for (let index in this.formDetailIdentifiers) {
            let identifier = this.formDetailIdentifiers[index];
            let formDetail = this.formDetail(identifier);
            if (formDetail != null && formDetail.canHaveValue) {
                let form = formDetail.form;

                let allErrors = form.validationErrors(identifier, formDetail.currentValue);
                let validityErrors = allErrors.filter(function (error) {
                    return error.errorType == FormValidationError.FormErrorType.error;
                });

                let requiredErrors = allErrors.filter(function (error) {
                    return error.errorType == FormValidationError.FormErrorType.required;
                });

                if (validityErrors.length > 0) {
                    return false;
                }

                let mandatory = form.isMandatory(identifier);

                if (mandatory && requiredErrors.length > 0) {
                    return false;
                }
            }
        }

        for (let index in this.subformIdentifiers) {
            let subformIdentifier = this.subformIdentifiers[index];
            let subform = this.subform(subformIdentifier);

            if (subform != null) {
                if (!subform.areAllCurrentValuesValid()) {
                    return false;
                }
            }
        }

        return true;
    }

    submissionValidation(identifier) {
        throw new Error('Override required ' + identifier);
    }

    editingValidation() {
        return []; // Might not need any editingValidation
    }

    requiredValidation() {
        // Even if a value is optional we check for notNil, that way we can verify if an optional value is notNil
        return [FormValidation.notNill];
    }

    validationErrors(identifier, value) {
        let requiredValidation = this.requiredValidation(identifier);
        var errors = [];

        for (let validationIndex in requiredValidation) {
            let validation = requiredValidation[validationIndex];
            let requiredErrors = validation.validationBlock(value);
            errors = errors.concat(requiredErrors);
        }

        let submissionValidation = this.submissionValidation(identifier);

        for (let validationIndex in submissionValidation) {
            let validation = submissionValidation[validationIndex];
            let submissionErrors = validation.validationBlock(value);
            errors = errors.concat(submissionErrors);
        }

        let editingValidation = this.editingValidation(identifier);

        for (let validationIndex in editingValidation) {
            let validation = editingValidation[validationIndex];
            let formDetail = this.formDetail(identifier);
            
            if (formDetail != null) {
                let editingErrors = validation.validationBlock(formDetail.editingValue);
                errors = errors.concat(editingErrors);
            }
        }

        return errors;
    }

    getValueType(identifier) {
        throw new Error('Override required for: ', identifier);
    }

    getValue(identifier) {
        throw new Error('Override required for: ', identifier);
    }

    get JSON() {
        var formJSON = {};

        for (let index in this.formDetailIdentifiers) {
            let identifier = this.formDetailIdentifiers[index];
            let formDetail = this.formLoader.formDetail(getPathwayIdFromURL(), identifier);
            if (formDetail.canHaveValue) {
                formJSON[identifier] = formDetail.currentValue;
            }
        }

        return formJSON;
    }

    valueUpdated(identifier, value) {
        throw new Error('Override required for: ', identifier, value);
    }

    shouldDisplayFormDetailForIdentifier(identifier) {
        throw new Error('Override required: ', identifier);
    }

    valueDescription() {
        let formDetails = this.getFormDetails();

        let values = formDetails.map((detail) => {
            detail.valueDescription();
        }).join('\n');

        return values;
    }

    completionProgress(ignoreFields) {
        var totalFormDetails = 0;
        var validFormDetails = 0;
        
        let formContainsMandatoryFields = this.formDetailIdentifiers.map((identifier) => {
            return this.isMandatory(identifier) ? identifier : null;
        }).filter(n => n).length > 0;
        
        //If a form has no mandatory fields then it is considered stateless 
        if(formContainsMandatoryFields){
            for (let formDetailIdentifier of this.formDetailIdentifiers) {
                let formDetail = this.formDetail(formDetailIdentifier);
                if (formDetail != null && (ignoreFields == null || !ignoreFields.includes(formDetail.identifier))) {
                    let form = formDetail.form;
                    let shouldDisplay = this.shouldDisplayFormDetailForIdentifier(formDetailIdentifier);
                    let allErrors = form.validationErrors(formDetailIdentifier, formDetail.currentValue);
                    if(formDetail.canHaveValue){
                        let mandatory = form.isMandatory(formDetailIdentifier);
                        let validityErrors = allErrors.filter((error) => {
                            return error.errorType != FormValidationError.FormErrorType.warning;
                        });
                        
                        let optionalValuePopulated = !mandatory && (formDetail.currentValue != null && formDetail.currentValue != undefined);
                        let contributesToCompletionProgress = (formDetail.canHaveValue && (mandatory || optionalValuePopulated) && shouldDisplay);
                        if (contributesToCompletionProgress) {
                            totalFormDetails += 1;
                            if (validityErrors.length == 0) {
                                validFormDetails += 1;
                            }
                        } 
                    } else {
                        let validityErrors = allErrors.filter((error) => {
                            return error.errorType != FormValidationError.FormErrorType.warning && error.errorType != FormValidationError.FormErrorType.required;
                        });

                        if(validityErrors.length > 0 && shouldDisplay){
                            totalFormDetails += 1;
                        }
                    }
                } 
            }
        }

        for (let subformIdentifier of this.subformIdentifiers) {
            let subform = this.subform(subformIdentifier);
            let subformProgress = subform.completionProgress();
            totalFormDetails += subformProgress.total;
            validFormDetails += subformProgress.current;
        }
        
        return { current: validFormDetails, total: totalFormDetails };
    }

    detailForIdentifier(prefix, value) {
        if (this.subform(value.identifier) != null && this.subform(value.identifier) != undefined) {
            let subform = this.subform(value.identifier);
            let details = subform.detailString(true);
            if (details != null && details != undefined) {
                return '▪︎ ' + subform.title + '\n' + subform.detailString(true);
            }
            else {
                return null;
            }
        }
        else if (!this.shouldDisplayFormDetailForIdentifier(value.identifier) || value.valueDescription() == null) {
            return null;
        }
        else if (value.valueDescription() == false) {
            return prefix + value.title + ': ' + 'No';
        }
        else if (value.valueDescription(false) == true) {
            return prefix + value.title + ': ' + 'Yes';
        }

        if (value.title != null && value.title != undefined) {
            return prefix + value.title + ': ' + value.valueDescription();
        }
        else {
            return prefix + value.valueDescription();
        }
    }

    detailString(isSubform) {
        let prefix = isSubform ? '- ': '';
        let formDetails = this.getFormDetails();
        let values = formDetails.map((value) => {
            return this.detailForIdentifier(prefix, value);
        });

        if(values.filter(n => n).length == 0){
            return isSubform ? null : this.detail;
        }
        
        return values.filter(n => n).join(',\n');
    }
}


