export const pictureCards = [
    {
        id: 1,
        imageName: 'Cup.png',
        alt: 'Cup card',
        label: 'Cup',
    },
    {
        id: 2,
        imageName: 'Keys.png',
        alt: 'Keys card',
        label: 'Keys',
    },
    {
        id: 3,
        imageName: 'Bird.png',
        alt: 'Bird card',
        label: 'Bird',
    },
    // {
    //     id: 1,
    //     imageName: 'Clock.png',
    //     alt: 'Clock card',
    //     selected: false,
    // },
    // {
    //     id: 6,
    //     imageName: 'Flower.png',
    //     alt: 'Flower card',
    //     selected: false,
    // },
    // {
    //     id: 2,
    //     imageName: 'Fork.png',
    //     alt: 'Fork cup',
    //     selected: false,
    // },
    // {
    //     id: 7,
    //     imageName: 'Pen.png',
    //     alt: 'Pen card',
    //     selected: false,
    // },
    // {
    //     id: 3,
    //     imageName: 'Scissors.png',
    //     alt: 'Scissors card',
    //     selected: false,
    // },
    // {
    //     id: 5,
    //     imageName: 'Toothbrush.png',
    //     alt: 'Toothbrush card',
    //     selected: false,
    // }
];

export const pictureCardsFail = [
    {
        id: 1,
        imageName: 'Clock.png',
        alt: 'Clock card',
        label: 'Clock',
    },
    {
        id: 2,
        imageName: 'Fork.png',
        alt: 'Fork cup',
        label: 'Fork',
    },
    {
        id: 3,
        imageName: 'Scissors.png',
        alt: 'Scissors card',
        label: 'Scissors',
    },
    {
        id: 4,
        imageName: 'Cup.png',
        alt: 'Cup card',
        label: 'Cup',
    },
    {
        id: 5,
        imageName: 'Toothbrush.png',
        alt: 'Toothbrush card',
        label: 'Toothbrush',
    },
    {
        id: 6,
        imageName: 'Flower.png',
        alt: 'Flower card',
        label: 'Flower',
    },
    {
        id: 7,
        imageName: 'Pen.png',
        alt: 'Pen card',
        selected: false,
        label: 'Pen',
    },
    {
        id: 8,
        imageName: 'Keys.png',
        alt: 'Keys card',
        label: 'Keys',
    },
    {
        id: 9,
        imageName: 'Bird.png',
        alt: 'Bird card',
        label: 'Bird',
    },
];
