import { FormWithRedux } from '../../FormWithRedux';

export class ACCMRI_CSI_RP_CriteriaForm extends FormWithRedux {

    constructor(formIdentifier, formLoader, parent, actionUpdater, dependentValuesUpdater, calculationsUpdater) {
        super(formIdentifier, formLoader, parent, actionUpdater, dependentValuesUpdater, calculationsUpdater);
    }

    shouldDisplayFormDetailForIdentifier(identifier) {
        super.shouldDisplayFormDetailForIdentifier(identifier);
        if(identifier == 'CSI_RP_NoToAll'){
            return this.unselectedExplicitFormDetailsCount(identifier) > 1;
        }
        return true;
    }

    isEnabled(identifier) {
        if (identifier != null) {
            return true;
        }
        return false;
    }

    isMandatory(identifier) {
        if (this.getValue('cervicalSpineInjury') == true) {
            if (identifier == 'CSI_RP_NoToAll'){
                return false;
            }

            let values = [
                this.getValue('CSI_lancinatingNervePainRadiatingDownArmWithinNarrowConfine'),
                this.getValue('CSI_uncomfortableAtNightWithPain'),
                this.getValue('CSI_positiveSpurlingTest')];

            if (values.includes(true) || values.includes(false)) {
                return true;
            }
        }
        
        return false;
    }

    getValueType() {
        return 'boolean';
    }

    submissionValidation() {
        return [];
    }

    detailString(isSubform) {
        let formDetails = this.getFormDetails();
        let values = formDetails.map((value) => {
            if (value.currentValue == true) {
                return value.title + ': Yes';
            }
        });

        if(values.filter(n => n).length == 0){
            return isSubform ? '' : this.detail;
        }

        if (isSubform == true) {
            return values.filter(n => n).join('\n  ');
        }
        return values.filter(n => n).join('\n');
    }

}