
import React from 'react';

// ED Chest Pain
import { EDChestPainSidebar } from './edChestPain01/EDChestPain-SidebarLayout';
import { EDChestPainMainForm } from './edChestPain01/Forms/EDChestPain-MainForm';
import { EDChestPainSummary } from './edChestPain01/Components/EDChestPain-Summary';
import { QuidelOrthoChestPainMainForm } from './edChestPain01/Forms/QuidelOrthoChestPain-MainForm';
import { QuidelOrthoChestPainSummary } from './edChestPain01/Components/QuidelOrthoChestPain-Summary';

// Gallbladder
import { GallbladderSidebar } from './Gallbladder01/GallbladderSidebarLayout';
import { GallbladderMainForm } from './Gallbladder01/MainForm/Gallbladder-MainForm';

// Osteoporosis
import { OsteoporosisSidebar } from './dxaScan01/Osteoporosis-SidebarLayout';
import { OsteoporosisMainForm } from './dxaScan01/MainForm/Osteoporosis-MainForm';

// GP Chest Pain
import { GPChestPainSidebar } from './gpChestPain01/GPChestPain-SidebarLayout';
import { GPChestPainMainForm } from './gpChestPain01/Forms/GPChestPain-MainForm';
import { GPChestPainSummary } from './gpChestPain01/Components/GPChestPain-Summary';

// ACC MRI
import { ACCMRISidebar } from './accmri01/ACCMRI-SidebarLayout';
import { ACCMRIMainForm } from './accmri01/Forms/ACCMRI-MainForm';

// ACC BIST
import { ACCBISTSidebar } from './concussion01/ACCBIST-SidebarLayout';
import { ACCBISTMainForm } from './concussion01/Forms/ACCBIST-MainForm';
import { ACCBISTSummary } from './concussion01/Components/ACCBIST-Summary';

import { ReferenceDataLoader } from './ReferenceDataLoader';
import { PathwayReferenceHelper } from './PathwayReferenceHelper';
import { ENABLED_PATHWAYS } from '../config/config';
import { EnvironmentInfoForm } from './sharedForms/EnvironmentInfoForm';

export class FormLoader {

    constructor() {
        this.subformCache = {};
        this.formDetailCache = {};
        this.formIDsForIdentifier = {};
    }
  
    getForm (pathwayID, formID) {
        let cacheForPathwayID = this.subformCache[pathwayID];

        if (cacheForPathwayID == undefined || Object.keys(cacheForPathwayID).length == 0) {
            this.subformCache[pathwayID] = {};
            this.formDetailCache[pathwayID] = {};
            this.formIDsForIdentifier[pathwayID] = {};

            this.recurseReferencesForFormIDs(pathwayID);
        } else {
            let cachedForm = cacheForPathwayID[formID];

            if(cachedForm != null && cachedForm != undefined){
                return cachedForm;
            }
        }

        let generatedForm;
        if (formID == 'mainForm') {
            switch (pathwayID) {
                case 'chestPain01':
                    generatedForm = new EDChestPainMainForm(this);
                    break;
                case 'gpChestPain01':
                    generatedForm = new GPChestPainMainForm(this);
                    break;
                case 'gallbladder01':
                    generatedForm = new GallbladderMainForm(this);
                    break;
                case 'dxaScan01':
                    generatedForm = new OsteoporosisMainForm(this);
                    break;
                case 'accmri01':
                    generatedForm = new ACCMRIMainForm(this);
                    break;
                case 'accbist01':
                    generatedForm = new ACCBISTMainForm(this);
                    break;
                case 'environmentInfoForm':
                    generatedForm = new EnvironmentInfoForm(this);
                    break;
                case 'QuidelOrtho':
                    generatedForm = new QuidelOrthoChestPainMainForm(this);
                    break;
            }
        } else {
            let parentFormID = this.formIDsForIdentifier[pathwayID][formID];
            if (parentFormID != null || parentFormID != undefined) {
                generatedForm = this.getForm(pathwayID, parentFormID).generateSubform(formID);
            } else {
                generatedForm = null;
            }
        }
        
        this.subformCache[pathwayID][formID] = generatedForm;

        return generatedForm;
    }

    recurseReferencesForFormIDs(pathwayID) {
        if(!pathwayID) return;

        let pathwayData = ReferenceDataLoader.referenceData(pathwayID);

        if(!pathwayData) return;

        let allPathwayReferences = pathwayData['template.content'];

        let contentTabIDs = pathwayData['template.contentTabIDs'];

        var formIDsToRecurse = [];

        if (contentTabIDs != undefined) {
            formIDsToRecurse = formIDsToRecurse.concat(Object.assign([], contentTabIDs));
        }
        
        let introID = pathwayData['template.introID'];

        if (introID != undefined) {
            formIDsToRecurse = formIDsToRecurse.concat(introID);
        }

        formIDsToRecurse.forEach(formID => {
            this.declareFormIDForSubformContents(pathwayID, formID != 'mainForm' ? 'mainForm' : null, formID, allPathwayReferences);
        });
    }

    declareFormIDForSubformContents(pathwayID, parentFormID, formID, pathwayReferences) {
        if (parentFormID != null) {
            this.formIDsForIdentifier[pathwayID][formID] = parentFormID;
        }

        let formReference = pathwayReferences[formID];

        let subformSectionIds = formReference[PathwayReferenceHelper.Type.subformSectionIds];

        subformSectionIds.forEach(sectionID => {
            this.formIDsForIdentifier[pathwayID][sectionID] = formID;

            let sectionReference = pathwayReferences[sectionID];

            var sectionChildIDs = Object.assign([], sectionReference[PathwayReferenceHelper.Type.sectionChildIDs]);
            while (sectionChildIDs.length > 0) {
                let childID = sectionChildIDs.pop();

                this.formIDsForIdentifier[pathwayID][childID] = formID;
          
                let childReference = pathwayReferences[childID];
                if (childReference.itemType == 'control') {
                    let subcellIDs = childReference[PathwayReferenceHelper.Type.subcellIDs];
                    if (subcellIDs != undefined && subcellIDs.length > 0) {
                        sectionChildIDs = sectionChildIDs.concat(subcellIDs);
                    }
                } else if (childReference.itemType == 'subform') {
                    this.declareFormIDForSubformContents(pathwayID, null, childID, pathwayReferences);
                }
            }
        });
    }

    getFormDetail(pathwayID, identifier) {
        let cacheForPathwayID = this.formDetailCache[pathwayID];

        let existingFormDetail = cacheForPathwayID[identifier];

        if(existingFormDetail != null && existingFormDetail != undefined){
            return existingFormDetail;
        }
        else {
            let formID = this.formIDsForIdentifier[pathwayID][identifier];

            let form = this.getForm(pathwayID, formID);

            if (form != null) {
                let formDetail = form.generateFormDetail(identifier);
                this.formDetailCache[pathwayID][identifier] = formDetail;

                return formDetail;
            }

            return null;
        }
    }

    // ED Chest Pain
    edChestPainNextFromDetails(formId){
        switch(formId){
            case 'patientDetails':
                return { title: 'Clinical question', link: 'highRiskDifferentialDiagnosis' };
            case 'vitalSigns':
                return { title: 'ECG', link: 'ecgTest'};
            case 'ecgTest':
                return { title: 'Bloods', link: 'initialBloods' };
            case 'initialBloods':
                return { title: 'Insights', link: 'resultsForm' };
            case 'highRiskDifferentialDiagnosis':
                return { title: 'EDACS assessment', link: 'initialAssessment' };
            case 'initialAssessment':
                return { title: 'Vital signs', link: 'vitalSigns' };
            default: 
                return null;
        }
    }

    gallbladderNextFromDetails(formId){
        switch(formId){ 
            case 'patientDetails':
                return { title: 'Request criteria', link: 'requestCriteria' };
            case 'requestCriteria':
                return { title: 'Further information', link: 'furtherInformation' };
            case 'furtherInformation':
                return { title: 'Request outcome', link: 'resultsForm' };
            default:
                return null;
        }
    }

    osteoporosisNextFromDetails(formId){
        switch(formId){ 
            case 'patientDetails':
                return { title: 'Request criteria', link: 'requestCriteria' };
            case 'requestCriteria':
                return { title: 'Further information', link: 'furtherInformation' };
            case 'furtherInformation':
                return { title: 'Outcome', link: 'resultsForm' };
            default:
                return null;
        }
    }

    // GP Chest Pain
    gpChestPainNextFromDetails(formId){
        switch(formId){
            case 'patientDetails':
                return { title: 'Clinical question', link: 'highRiskDifferentialDiagnosis' };
            case 'vitalSigns':
                return { title: 'ECG', link: 'ecgTest'};
            case 'ecgTest':
                return { title: 'Bloods', link: 'initialBloods' };
            case 'initialBloods':
                return { title: 'Outcome', link: 'resultsForm' };
            case 'highRiskDifferentialDiagnosis':
                return { title: 'Initial assessment', link: 'initialAssessment' };
            case 'initialAssessment':
                return { title: 'Vital signs', link: 'vitalSigns' };
            default: 
                return null;
        }
    }

    accmriNextFromDetails(formId){
        switch(formId){ 
            case 'requestCriteria':
                return { title: 'Assessment', link: 'assessment' };
            case 'assessment':
                return { title: 'Outcome', link: 'outcome' };
            default:
                return null;
        }
    }

    accbistNextFromDetails(formId){
        switch(formId) {
            case 'patientDetailsSubform': 
                return { title: 'Injury Details', link: 'injuryDetailsSubform' };
            case 'injuryDetailsSubform': 
                return { title: 'Clinical Assessment', link: 'clinicalAssessmentSubform' };
            case 'clinicalAssessmentSubform': 
                return { title: 'A-WPTA', link: 'awptaCheck1Subform' };
            case 'awptaCheck1Subform': 
                return { title: 'BIST Symptom Scale', link: 'bistSymptomScale' };
            case 'bistSymptomScale': 
                return { title: 'Glasgow Coma Score', link: 'gcsSubform' };
            case 'gcsSubform': 
                return { title: 'Vital Signs', link: 'vitalSignsSubform' };
            case 'vitalSignsSubform': 
                return { title: 'CT Brain', link: 'ctBrainSubform' };
            case 'ctBrainSubform':
                return { title: 'Insights', link: 'resultsForm' };
            default:
                return null;
        }
    }

    accBistPreviousFormDetails(formId) {
        switch(formId) {
            case 'injuryDetailsSubform': 
                return { title: 'Patient Details', link: 'patientDetailsSubform' };
            case 'clinicalAssessmentSubform': 
                return { title: 'Injury Details', link: 'injuryDetailsSubform' };
            case 'awptaCheck1Subform': 
                return { title: 'Clinical Assessment', link: 'clinicalAssessmentSubform' };
            case 'bistSymptomScale': 
                return { title: 'A-WPTA', link: 'awptaCheck1Subform' };
            case 'gcsSubform': 
                return { title: 'BIST Symptom Scale', link: 'bistSymptomScale' };
            case 'vitalSignsSubform': 
                return { title: 'Neurological Observations', link: 'gcsSubform' };
            case 'ctBrainSubform':
                return { title: 'Vital Signs', link: 'vitalSignsSubform' };
            default:
                return null;
        }
    }
  
    
    clearPathway(pathwayID) {
        this.subformCache[pathwayID] = {};
        this.formDetailCache[pathwayID] = {};
        this.formIDsForIdentifier[pathwayID] = {};
    }
    form (pathwayID, formID) {
        return this.getForm(pathwayID, formID);
    }
    formDetail (pathwayID, identifier) {
        return this.getFormDetail(pathwayID, identifier);
    }
    get validPathways(){
        return ENABLED_PATHWAYS;
    }
    nextFormDetails (pathwayId, formId){
        switch (pathwayId){
            case 'chestPain01':
                return this.edChestPainNextFromDetails(formId);
            case 'gpChestPain01':
                return this.gpChestPainNextFromDetails(formId);
            case 'gallbladder01':
                return this.gallbladderNextFromDetails(formId);
            case 'dxaScan01':
                return this.osteoporosisNextFromDetails(formId);
            case 'accmri01':
                return this.accmriNextFromDetails(formId);
            case 'accbist01':
                return this.accbistNextFromDetails(formId);
            case 'QuidelOrtho':
                return this.edChestPainNextFromDetails(formId);
        }
    }
    previousFormDetails (pathwayId, formId){
        switch (pathwayId){
            case 'accbist01':
                return this.accBistPreviousFormDetails(formId);
        }
    }
    shouldSummarize (pathwayId, formId){
        switch (pathwayId){
            case 'chestPain01':
                switch(formId){
                    case 'mainForm':
                        return true;
                    default:
                        return false;
                }
            case 'gallbladder01':
                switch(formId){
                    case 'mainForm':
                        return true;
                    default:
                        return false;
                }
            case 'dxaScan01':
                switch(formId){
                    case 'mainForm':
                        return true;
                    default:
                        return false;
                }
            case 'gpChestPain01':
                switch(formId){
                    case 'mainForm':
                        return true;
                    default:
                        return false;
                }
            case 'accmri01':
                switch(formId){
                    case 'mainForm':
                        return true;
                    default:
                        return false;
                }
            case 'accbist01':
                switch(formId){
                    case 'mainForm':
                        return true;
                    default:
                        return false;
                }
            case 'QuidelOrtho':
                switch(formId){
                    case 'mainForm':
                        return true;
                    default:
                        return false;
                }
            default:
                return false;
        }
    }
    showSummary (pathwayId){
        switch (pathwayId){
            case 'chestPain01':
                return true;
            case 'gallbladder01':
                return false;
            case 'dxaScan01':
                return false;
            case 'gpChestPain01':
                return false;
            case 'accmri01':
                return false;
            case 'accbist01':
                return true;
            case 'QuidelOrtho':
                return true;
        }
    }
    mainForm (pathwayId){
        return this.getForm(pathwayId, 'mainForm');
    }
    introForm (pathwayId){
        return this.getForm(pathwayId, 'introForm');
    }
    sidebarLayout (pathwayId){
        switch(pathwayId){
            case 'chestPain01':
                return EDChestPainSidebar;
            case 'gallbladder01':
                return GallbladderSidebar;
            case 'dxaScan01':
                return OsteoporosisSidebar;
            case 'gpChestPain01':
                return GPChestPainSidebar;
            case 'accmri01':
                return ACCMRISidebar;
            case 'accbist01':
                return ACCBISTSidebar;
            case 'QuidelOrtho':
                return EDChestPainSidebar;
        }
    }
    // eslint-disable-next-line react/display-name
    summaryComponent (pathwayID){
        switch(pathwayID){
            case 'chestPain01':
                return <EDChestPainSummary formLoader={this} pathwayId={pathwayID}/>;
            case 'gpChestPain01':
                return <GPChestPainSummary formLoader={this} pathwayId={pathwayID}/>;
            case 'accbist01':
                return <ACCBISTSummary formLoader={this} pathwayId={pathwayID}/>;
            case 'QuidelOrtho':
                return <QuidelOrthoChestPainSummary formLoader={this} pathwayId={pathwayID}/>;
        }
    }
}
