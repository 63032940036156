import { PathwayFormatter } from '../PathwayFormatter';
import { GallbladderActionItem } from './Actions/Gallbladder-ActionItem';

const sectionLine = '=======================================\n';
const sectionBreak = '\n\n';

export class GallbladderFormatter {
    static formatAsPlainText(formLoader, workflow) {

        let action;
        if(workflow.rawData['specialistAppointment'] != null){
            action = GallbladderActionItem.rehydrate(workflow.rawData['specialistAppointment']);
        }
        //requestCriteria
        //furtherInformation
        var forms = [formLoader.form(workflow.pathwayUuid, 'patientDetails'), formLoader.form(workflow.pathwayUuid, 'requestCriteria'), formLoader.form(workflow.pathwayUuid, 'furtherInformation')];
        var formattedString = '';
        
        formattedString += 'Wayfind Gallbladder Request\n';
        formattedString += 'Updated: ' + PathwayFormatter.formatDate(workflow.lastModifiedDate, 'ddd DD-MMM-YYYY HH:mm') + ' (' + 'version ' + workflow.version +  ')';
        formattedString += sectionBreak;

        if(action != null || workflow.rawData['acceptanceOutsideUsualCriteria'] == true){
            formattedString += 'ERMS REQUEST DETAILS (GENERAL SURGERY USE ONLY)\n';
            formattedString += sectionLine;
            if(action != null){
                if (workflow.rawData['patientPrivateInsurance'] == true) {
                    formattedString += (action.automaticAcceptance == true ? 'CLINICAL INDICATIONS' : 'MAY HAVE CLINICAL INDICATIONS') + '\n';
                } else {
                    formattedString += (action.automaticAcceptance == true ? 'AUTOMATED TRIAGE ACCEPTED' : 'REQUIRES SPECIALIST TRIAGE REVIEW') + '\n';
                    formattedString += 'Clinic: ' + action.getPrioritisedClinic() + (action.urgent ? ' - URGENT' : '- Routine') + '\n';
                    formattedString += 'Appointment timeframe: ' + (action.appointmentTimeFrame == GallbladderActionItem.TimeFrame.ten ? '100' : '28') + ' days\n';
                }
                
                formattedString += 'Reason for request: ' + action.reasons.join(', ') + '\n';
                formattedString += 'SNOMED: ' + action.snomedIds.join(', ') + '\n';
            } else if(workflow.rawData['acceptanceOutsideUsualCriteria'] == true){
                formattedString += 'NO INDICATION FOR CLINICAL INTERVENTION\n';
                formattedString += 'GP requesting special consideration: YES\n';
                formattedString += 'Reason: ' + workflow.rawData['acceptanceOutsideCriteriaReason']; 
            }
            formattedString += sectionBreak;
        }

        for(let form of forms){
            formattedString += form.title.toUpperCase() + '\n';
            formattedString += sectionLine;
            formattedString += form.getPlainText();
            formattedString += sectionBreak;
        }

        formattedString += 'REQUEST OUTCOME\n';
        formattedString += sectionLine;
        
        formattedString += 'Specialist appointment outcome: ';
        if(action != null){
            formattedString += action.getPlainTextTitle() + (action.urgent ? ' [URGENT]' : '') + '\n';
            formattedString += action.getPlainText();
        } else {
            formattedString += 'No indication for clinical intervention.';
        }
        formattedString += sectionBreak;

        if(workflow.rawData['acceptanceOutsideUsualCriteria'] == true){
            formattedString += 'Request consideration: ';
            formattedString += 'Request should be considered for acceptance outside usual criteria\n';
            formattedString += 'Reason: ' + workflow.rawData['acceptanceOutsideCriteriaReason'];
            formattedString += sectionBreak;
        }

        if(workflow.rawData['additionalInformation'] != null){
            formattedString += 'Additional information: ';
            formattedString += workflow.rawData['additionalInformation'];
            formattedString += sectionBreak;
        }

        formattedString += sectionBreak;
        
        return formattedString;
    }
}