import { FormWithRedux } from '../../FormWithRedux';
import { ACCBISTInformationForm } from './ACCBIST-IntroForm';
import { ACCBISTPatientDetailsForm } from './ACCBIST-PatientDetails';
import { ACCBISTInjuryDetailsForm } from './ACCBIST-InjuryDetailsForm';
import { ACCBISTClinicalAssessmentForm } from './ACCBIST-ClinicalAssessmentForm';
import { ACCBISTSymptonScaleForm } from './ACCBIST-SymptomScaleForm';

import { ACCBISTAWPTAForm } from './ACCBIST-AWPTAForm'; 
import { ACCBISTAWPTA2Form } from './ACCBIST-AWPTA2Form';
import { ACCBISTAWPTA3Form } from './ACCBIST-AWPTA3Form';
import { ACCBISTAWPTA4Form } from './ACCBIST-AWPTA4Form';
import { ACCBISTAWPTA5Form } from './ACCBIST-AWPTA5Form';
import { ACCBISTResultsForm } from './ACCBIST-ResultsForm'; 
import { ACCBISTNeuroObsForm } from './ACCBIST-NeuroObsForm';
import { ACCBISTVitalSignsForm } from './ACCBIST-VitalSignsForm';
import { ACCBISTCTBrainForm } from './ACCBIST-CTBrainForm';

import { ACCBISTNotesForm } from './ACCBIST-NotesForm';

import { ACCBISTActions } from '../Actions/ACCBIST-Actions';
import { ACCBISTDependantValuesUpdater } from '../Calculations/ACCBIST-DependantValuesUpdater';
import { ACCBISTCalculations } from '../Calculations/ACCBIST-Calculations';

import { FormValidation } from '../../../waferJS/Form/Validation/FormValidation';
import { FormValidationError } from '../../../waferJS/Form/Validation/FormValidationError';

export class ACCBISTMainForm extends FormWithRedux {

    constructor(formLoader) {
        super('mainForm', formLoader, null, new ACCBISTActions(), new ACCBISTDependantValuesUpdater(formLoader), new ACCBISTCalculations());
    }

    generateSubform(identifier) {
        switch (identifier) {
            case 'introForm':
                return new ACCBISTInformationForm(this.formLoader);
            case 'notesForm':
                return new ACCBISTNotesForm(this.formLoader, this, this.dependentValuesUpdater);
            case 'resultsForm':
                return new ACCBISTResultsForm(this.formLoader, this, this.actionUpdater, this.dependentValuesUpdater, this.calculationsUpdater);
            case 'patientDetailsSubform':
                return new ACCBISTPatientDetailsForm(this.formLoader, this, this.actionUpdater, this.dependentValuesUpdater, this.calculationsUpdater);
            case 'injuryDetailsSubform':
                return new ACCBISTInjuryDetailsForm(this.formLoader, this, this.actionUpdater, this.dependentValuesUpdater, this.calculationsUpdater);
            case 'clinicalAssessmentSubform':
                return new ACCBISTClinicalAssessmentForm(this.formLoader, this, this.actionUpdater, this.dependentValuesUpdater, this.calculationsUpdater);
            case 'awptaCheck1Subform':
                return new ACCBISTAWPTAForm(this.formLoader, this, this.actionUpdater, this.dependentValuesUpdater, this.calculationsUpdater);
            case 'awptaCheck2Subform':
                return new ACCBISTAWPTA2Form(this.formLoader, this, this.actionUpdater, this.dependentValuesUpdater, this.calculationsUpdater);
            case 'awptaCheck3Subform':
                return new ACCBISTAWPTA3Form(this.formLoader, this, this.actionUpdater, this.dependentValuesUpdater, this.calculationsUpdater);
            case 'awptaCheck4Subform':
                return new ACCBISTAWPTA4Form(this.formLoader, this, this.actionUpdater, this.dependentValuesUpdater, this.calculationsUpdater);
            case 'awptaCheck5Subform':
                return new ACCBISTAWPTA5Form(this.formLoader, this, this.actionUpdater, this.dependentValuesUpdater, this.calculationsUpdater);
            case 'bistSymptomScale':
                return new ACCBISTSymptonScaleForm(this.formLoader, this, this.actionUpdater, this.dependentValuesUpdater, this.calculationsUpdater);
            case 'gcsSubform':
                return new ACCBISTNeuroObsForm(this.formLoader, this, this.actionUpdater, this.dependentValuesUpdater, this.calculationsUpdater);
            case 'vitalSignsSubform':
                return new ACCBISTVitalSignsForm(this.formLoader, this, this.actionUpdater, this.dependentValuesUpdater, this.calculationsUpdater);
            case 'ctBrainSubform':
                return new ACCBISTCTBrainForm(this.formLoader, this, this.actionUpdater, this.dependentValuesUpdater, this.calculationsUpdater);
        }
    }

    shouldDisplayFormDetailForIdentifier(identifier) {
        super.shouldDisplayFormDetailForIdentifier(identifier);
        let subform = this.subform(identifier);
        if (subform != null && subform != undefined && typeof subform.shouldShowInSidebar === 'function') {
            return subform.shouldShowInSidebar();
        }

        return true;
    }

    isEnabled(identifier) {
        if (identifier != null) {
            return true;
        }
        return false;
    }

    isMandatory() {
        return false;
    }

    getValueType() {
        return null;
    }

    submissionValidation(identifier) {
        if (identifier == 'gcsSubform') {
            let self = this;
    
            let valueConflict = new FormValidation('VerbalScoreConflict', function () {
                let orienationChecks1 = [
                    self.getValue('whatIsYourNameCheck1'),
                    self.getValue('nameOfThisPlaceCheck1'),
                    self.getValue('whyAreYouHereCheck1'),
                    self.getValue('whatMonthAreWeInCheck1'),
                    self.getValue('whatYearAreWeInCheck1')];
    
                let orienationChecks2 = [
                    self.getValue('whatIsYourNameCheck2'),
                    self.getValue('nameOfThisPlaceCheck2'),
                    self.getValue('whyAreYouHereCheck2'),
                    self.getValue('whatMonthAreWeInCheck2'),
                    self.getValue('whatYearAreWeInCheck2')];

                let orienationChecks3 = [
                    self.getValue('whatIsYourNameCheck3'),
                    self.getValue('nameOfThisPlaceCheck3'),
                    self.getValue('whyAreYouHereCheck3'),
                    self.getValue('whatMonthAreWeInCheck3'),
                    self.getValue('whatYearAreWeInCheck3')];

                let orienationChecks4 = [
                    self.getValue('whatIsYourNameCheck4'),
                    self.getValue('nameOfThisPlaceCheck4'),
                    self.getValue('whyAreYouHereCheck4'),
                    self.getValue('whatMonthAreWeInCheck4'),
                    self.getValue('whatYearAreWeInCheck4')];

                let orienationChecks5 = [
                    self.getValue('whatIsYourNameCheck5'),
                    self.getValue('nameOfThisPlaceCheck5'),
                    self.getValue('whyAreYouHereCheck5'),
                    self.getValue('whatMonthAreWeInCheck5'),
                    self.getValue('whatYearAreWeInCheck5')];

                var incompatiblerResult = false;
                var showAsWarning = true;

                if (self.getValue('verbalScore') == 'Oriented') {
                    showAsWarning = false;
                }

                if(!orienationChecks5.includes(null) && !orienationChecks5.includes(undefined)) {
                    if(orienationChecks5.includes(false) && this.getValue('')) {
                        incompatiblerResult = true;
                    }
                }
                else if(!orienationChecks4.includes(null) && !orienationChecks4.includes(undefined)) {
                    if(orienationChecks4.includes(false)) {
                        incompatiblerResult = true;
                    }
                }
                else if(!orienationChecks3.includes(null) && !orienationChecks3.includes(undefined)) {
                    if(orienationChecks3.includes(false)) {
                        incompatiblerResult = true;
                    }
                }
                else if(!orienationChecks2.includes(null) && !orienationChecks2.includes(undefined)) {
                    if(orienationChecks2.includes(false)) {
                        incompatiblerResult = true;
                    }
                }
                else if(!orienationChecks1.includes(null) && !orienationChecks1.includes(undefined)) {
                    if(orienationChecks1.includes(false)) {
                        incompatiblerResult = true;
                    }
                }

                if (incompatiblerResult) {
                    return [new FormValidationError(showAsWarning ? FormValidationError.FormErrorType.warning : FormValidationError.FormErrorType.error, 'AWPTA suggests patient is confused')];
                }
                else {
                    return [];
                }
            });

            let gcsNot15 = new FormValidation('AbnormalitiesPresentConflict', function () {
                let totalGCS = self.getValue('totalGCS');
                let noIssuesCheck = self.getValue('neuroObsOkayCheck');

                if(totalGCS != null && totalGCS != undefined && totalGCS > 0 && totalGCS != 15) {
                    if (noIssuesCheck == true) {
                        return [new FormValidationError(FormValidationError.FormErrorType.error, 'GCS is not 15')];
                    }
                    else {
                        return [new FormValidationError(FormValidationError.FormErrorType.warning, 'GCS is not 15')];
                    }
                }

                return [];
            });

            return [valueConflict, gcsNot15];
        }

        return [];
    }

    detailString() {
        return this.detail;
    }
}