import { FormContentView } from '../../FormsUI/FormsUI Components/Containers/FormContentView';
import { FormRadio } from '../../FormsUI/FormsUI Components/Controls/FormRadio';
import { FormsStyles } from '../../FormsUI/FormsUI';
import React from 'react';
import PropTypes from 'prop-types';

export class RadioFormDetail extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            isChecked: this.props.formDetail.editingValue == true ? true : false
        };

        this.valueUpdated = this.valueUpdated.bind(this);
        this.handleKeyPress = this.handleKeyPress.bind(this);
    }

    componentDidMount() {
        document.addEventListener('keypress', this.handleKeyPress);
    }
    componentWillUnmount() {
        document.removeEventListener('keypress', this.handleKeyPress);
    }

    componentDidUpdate() {
        if ((this.props.formDetail.editingValue == true) != this.state.isChecked) {
            this.setState({
                isChecked: this.props.formDetail.editingValue == true ? true : false
            });
        }
        this.props.renderErrors();
    }

    editingValue(initialValue) {
        if (typeof initialValue === 'string') {
            return initialValue;
        }

        return super.editingValue(initialValue);
    }

    handleKeyPress(e){
        if(document.activeElement == this.focusNode){
            // window.gtag('event', 'used-keyboard-input-for-segment', {
            //     event_category: 'action',
            //     event_label: 'used-keyboard-input-for-segment'
            // });
            if (e.which == 13){ //enter key
                this.valueUpdated();
            } 
        }
    }

    valueUpdated() {
        
        this.props.formDetail.form.valueUpdated(this.props.formDetail.identifier, this.props.formDetail.editingValue == true ? null : true);
        this.props.renderErrors();
        this.setState({
            isChecked: !this.state.isChecked
        });
    }

    render() {
        let formRadio = <div ref={radioNode => this.radioNode = radioNode}><FormRadio key={this.props.formDetail.identifier} isChecked={this.state.isChecked} onChange={this.valueUpdated} /></div>;
        let subcells = this.props.renderSubcells();

        let isMandatory = this.props.formDetail.form.isMandatory(this.props.formDetail.identifier);

        return (
            <div ref={node => this.node = node}>
                <FormContentView identifier={this.props.formDetail.identifier} isSubcell={this.props.isSubcell} titleString={this.props.formDetail.title} isMandatory={isMandatory} detailString={this.props.formDetail.detail} rightControl={formRadio} validation={this.props.errorLabels} subcell={subcells} subCellbackgroundColor={FormsStyles.backgroundColor} showHover={true} allowFocus={true} focusRef={focusNode => this.focusNode = focusNode} forceRow={true} />
            </div>
        );
    }
}

RadioFormDetail.propTypes = {
    formDetail: PropTypes.object.isRequired,
    isSubcell: PropTypes.bool.isRequired,
    renderErrors: PropTypes.func.isRequired,
    renderSubcells: PropTypes.func.isRequired,
    errorLabels: PropTypes.array.isRequired,
    hasError: PropTypes.bool.isRequired
};