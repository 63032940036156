import { v1 as uuidv1 } from 'uuid';
import { FormsStyles } from '../../../waferJS/FormsUI/FormsUI';
import { RiskCategory, RiskCategoryBrackets } from './Risk Category';

export class CalculationModel {

    static CalculationType = Object.freeze({edacs: 0, riskCategory: 1, probabilityOfMace: 2});

    static typeRequiresPrimaryFormValidation(type) {
        switch (type) {
            case CalculationModel.CalculationType.edacs:
                return false;
            case  CalculationModel.CalculationType.riskCategory:
            case  CalculationModel.CalculationType.probabilityOfMace:
                return true;
        }
    }
    
    constructor(pathwayUuid, versionUuid, value, observed, type, lastModifiedDate, uuid) {
        
        this.pathwayUuid = pathwayUuid;
        this.versionUuid = versionUuid;
        
        this.value = value;
        
        this.observed = observed;
        this.type = type;
        
        this.lastModifiedDate = lastModifiedDate;

        if(uuid == null){
            this.uuid = uuidv1();
        } else {
            this.uuid = uuid;
        }
    }

    static rehydrate(calculation){
        return new CalculationModel(calculation.pathwayUuid, calculation.versionUuid, calculation.value, calculation.observed, calculation.type, calculation.lastModifiedDate, calculation.uuid);
    }
    
    get valueString() {
        if(this.value == null) {
            return 'N/A';
        }
        
        switch (this.type) {
            case CalculationModel.CalculationType.riskCategory:{
                let riskBracket = new RiskCategoryBrackets(this.value);
                let riskCategory = riskBracket.riskCategory; 
                if(riskCategory != null){
                    return riskCategory.title;
                }
                break;
            }
            case CalculationModel.CalculationType.probabilityOfMace:{
                
                let percentage =  (this.value * 100).toFixed(1);
                //percentage = Math.round(percentage * 100) / 100;
                if(percentage != null){
                    return percentage + '%';
                }
                break;
            }
            default:{
                let decimal = (this.value).toFixed(0);
                return decimal;
            }
                
        }
        
        return null;
    }
    
    get badgeColor() {

        if(this.value == null) {
            return FormsStyles.backgroundGray;
        }

        if(this.type == CalculationModel.CalculationType.edacs && this.value < 16){
            return FormsStyles.green;
        } else if(this.type == CalculationModel.CalculationType.edacs && this.value < 21){
            return FormsStyles.orange;
        } else if(this.type == CalculationModel.CalculationType.edacs && this.value >= 21){
            return FormsStyles.red;
        } else if(this.type == CalculationModel.CalculationType.riskCategory){
            let riskCategory = RiskCategory.getRiskCategory(this.value);
            
            if(riskCategory != null){
                return riskCategory.color;
            } else {
                let riskCategory = RiskCategory.getRiskCategoryFromBracketValue(this.value);
                if(riskCategory != null){
                    return riskCategory.color;
                }
            }
            return FormsStyles.backgroundGray;
        } else if(this.type == CalculationModel.CalculationType.probabilityOfMace && this.value < 0.01){
            return FormsStyles.green;
        } else if(this.type == CalculationModel.CalculationType.probabilityOfMace && this.value < 0.05){
            return FormsStyles.orange;
        } else if(this.type == CalculationModel.CalculationType.probabilityOfMace && this.value >= 0.05){
            return FormsStyles.red;
        }

        return FormsStyles.yellow;
    }

    get badgeStyle(){
        if(this.value == null) {
            return false;
        }

        if(this.type == CalculationModel.CalculationType.edacs && this.value < 16){
            return true;
        } else if(this.type == CalculationModel.CalculationType.edacs && this.value < 21){
            return true;
        } else if(this.type == CalculationModel.CalculationType.edacs && this.value >= 21){
            return true;
        } else if(this.type == CalculationModel.CalculationType.riskCategory){
            let riskCategory = RiskCategory.getRiskCategory(this.value);
            if(riskCategory != null){
                return true;
            } 
            return FormsStyles.backgroundGray;
        } else if(this.type == CalculationModel.CalculationType.probabilityOfMace && this.value < 0.01){
            return true;
        } else if(this.type == CalculationModel.CalculationType.probabilityOfMace && this.value < 0.05){
            return true;
        } else if(this.type == CalculationModel.CalculationType.probabilityOfMace && this.value >= 0.05){
            return true;
        }

        return true;
    }

    get rating(){

        if(this.value == null) {
            return null;
        }

        if(this.type == CalculationModel.CalculationType.edacs && this.value < 16){
            return 'Low';
        } else if(this.type == CalculationModel.CalculationType.edacs && this.value < 21){
            return 'Moderate';
        } else if(this.type == CalculationModel.CalculationType.edacs && this.value >= 21){
            return 'High';
        } else if(this.type == CalculationModel.CalculationType.riskCategory){
            return null;
        } else if(this.type == CalculationModel.CalculationType.probabilityOfMace && this.value < 0.01){
            return 'Low';
        } else if(this.type == CalculationModel.CalculationType.probabilityOfMace && this.value < 0.05){
            return 'Moderate';
        } else if(this.type == CalculationModel.CalculationType.probabilityOfMace && this.value >= 0.05){
            return 'High';
        }

        return 'Moderate';
    }

    get textColor(){
        if(this.value == null) {
            return FormsStyles.black;
        }
        return FormsStyles.white;
    }
    
}