import { FormWithRedux } from '../../FormWithRedux';
import { IsDate, IsDateInPastError } from '../../../waferJS/Form To FormsUI/Subclasses/Validation Overrides/DateTimeValidation';
import { IsTroponinResult, IsTroponinString } from '../Validation Overrides/EDChestPain-TroponinValidation';
import { redux } from '../../../App';
// import moment from 'moment';
import { ActionModel } from '../../Shared Custom Types/ActionModel';

export class EDChestPainBloodsForm extends FormWithRedux {

    constructor(formLoader, parent, actionUpdater, dependentValuesUpdater, calculationsUpdater) {
        super('initialBloods', formLoader, parent, actionUpdater, dependentValuesUpdater, calculationsUpdater);
    }

    shouldDisplayFormDetailForIdentifier(identifier) {
        super.shouldDisplayFormDetailForIdentifier(identifier);
        return true;
    }

    isEnabled(identifier) {
        if (identifier != null) {
            return true;
        }
        return false;
    }

    isMandatory(identifier) {
        // if(identifier == 'followUpBloodsTroponin' || identifier == 'followUpBloodsDateAndTime'){
        //     let pathwayActions = redux.store.getState().actionsReducer.actions[this.pathwayId];
        //     if(pathwayActions != null){
        //         let followUpAction = pathwayActions.filter((action) => { return action.identifier == 'nextTroponinAction';})[0];
        //         if(followUpAction != null && followUpAction.scheduledAction != null){
        //             let date = moment(followUpAction.scheduledAction.date);
        //             return date.isBefore();
        //         }
        //     }
        // } 
        
        if (identifier == 'followUpBloodsDateAndTime') {
            let dependantValue = this.getValue('followUpBloodsTroponin');
            return dependantValue != null || this.getValue('followUpBloodsDateAndTime') != null;
        } else if(identifier == 'followUpBloodsTroponin'){
            let dependantValue = this.getValue('followUpBloodsDateAndTime');
            return dependantValue != null || this.getValue('followUpBloodsTroponin') != null;
        }

        return true;
    }

    submissionValidation(identifier) {
        if (identifier === 'initialBloodsDateAndTime' || identifier == 'followUpBloodsDateAndTime') {
            return [IsDate, IsDateInPastError];
        } else if (identifier == 'initialBloodsTroponin' || identifier == 'followUpBloodsTroponin') {
            return [IsTroponinResult];
        }
        return [];
    }

    editingValidation(identifier) {
        if (identifier == 'initialBloodsTroponin' || identifier == 'followUpBloodsTroponin') {
            return [IsTroponinString];
        }
        return [];
    }

    getValueType(identifier) {
        if (identifier === 'initialBloodsDateAndTime') {
            return 'date';
        } else if (identifier == 'initialBloodsTroponin') {
            return 'troponin';
        } else  if (identifier === 'followUpBloodsDateAndTime') {
            return 'date';
        } else if (identifier == 'followUpBloodsTroponin') {
            return 'troponin';
        }

        return null;
    }

    detailString() {
        let formDetails = this.getFormDetails();
        var valueDescriptions = [];

        for (let formDetailIndex in formDetails) {
            let formDetail = formDetails[formDetailIndex];
            let valueDescription = formDetail.valueDescription();
            if(formDetail.identifier == 'followUpBloodsDateAndTime' && this.getValue(formDetail.identifier) != null) {
                let troponinValue = this.getValue('followUpBloodsTroponin');
                let followUpString = 'Repeat: ' + this.abbreviatedString(formDetail.identifier, valueDescription) + (troponinValue != null ? ', ' + this.abbreviatedString('followUpBloodsTroponin', troponinValue) : '');
                valueDescriptions.push(followUpString);
            } else if(formDetail.identifier == 'initialBloodsDateAndTime' && this.getValue(formDetail.identifier) != null){
                let troponinValue = this.getValue('initialBloodsTroponin');
                let initialString = 'Initial: ' + this.abbreviatedString(formDetail.identifier, valueDescription) + (troponinValue != null ? ', ' + this.abbreviatedString('initialBloodsTroponin', troponinValue) : '');
                valueDescriptions.push(initialString);
            }
        }

        let pathwayActions = redux.store.getState().actionsReducer.actions[this.pathwayId];
        if(pathwayActions != null){
            let action = pathwayActions.filter((action) => { return action.identifier == 'repeatTroponinAction';})[0];
            if(action != null){
                let actionModel = new ActionModel(action.pathwayUuid, action.versionUuid, action.identifier, action.title, action.detail, action.priority, action.observed, action.removeAfterObservation, action.lastModifiedDate, action.uuid, action.scheduledAction);
                valueDescriptions.push(actionModel.detail);
            }
        }

        let finalDetailString = valueDescriptions.join('\n');

        if (finalDetailString.length > 0) {
            return finalDetailString;
        }

        return this.detail;
    }

    abbreviatedString(identifier, value) {
        switch (identifier) {
            case 'initialBloodsTroponin':
            case 'followUpBloodsTroponin': {
                if(value < 2){
                    return 'Troponin ' + '<2' + 'ng/L';
                }
                return 'Troponin ' + value + 'ng/L';
            }
            case 'initialBloodsDateAndTime':
            case 'followUpBloodsDateAndTime':
                return value;
        }
    }

    formDetailString(){
        let pathwayActions = redux.store.getState().actionsReducer.actions[this.pathwayId];
        if(pathwayActions != null){
            let action = pathwayActions.filter((action) => { return action.identifier == 'repeatTroponinAction';})[0];
            if(action != null){
                let actionModel = new ActionModel(action.pathwayUuid, action.versionUuid, action.identifier, action.title, action.detail, action.priority, action.observed, action.removeAfterObservation, action.lastModifiedDate, action.uuid, action.scheduledAction);
                return actionModel.detail;
            }
        }

        return this.detail;
    }
}