import { ENABLED_EXPORTS, FUNCTIONS_API_ROOT } from '../../../config/config';

const exportToECPP = (data) => {

    if (ENABLED_EXPORTS.includes('ecpp') == true) {
        let token = null; //getCachedToken != null ? getCachedToken() : null;

        if (token != null && token != undefined) {
            if (FUNCTIONS_API_ROOT != null && FUNCTIONS_API_ROOT != undefined) {
                return postData(`${FUNCTIONS_API_ROOT}/export/ecpp`, data, token).then((response) => {
                    console.log(response);
                }).catch((error) => {
                    console.log(error);
                });
            }
        } else {
            console.log('Auth token has not been provided.');
            return;
        }
    } else {
        console.log('ECPP is not enabled on this environment');
        return;
    }
};

function postData(url = '', data = {}, token) {
    // Default options are marked with *

    return fetch(url, {
        method: 'POST',
        cache: 'no-cache', 
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': ('Bearer ' + token)
        },
        redirect: 'follow',
        referrer: 'no-referrer',
        body: JSON.stringify(data), 
    }).then(response => response.json());
}

export { exportToECPP };