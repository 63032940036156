// IMPORT PACKAGE REFERENCES
import React from 'react';
import PropTypes from 'prop-types';
import { FormsStyles } from '../../waferJS/FormsUI/FormsUI';

// COMPONENT
export class Chevron extends React.Component {

    constructor(props) {
        super(props);
    }

    shouldComponentUpdate(nextProps) {
        if(nextProps.angle !== this.props.angle){
            return true;
        }

        if(nextProps.color !== this.props.color){
            return true;
        }

        return false;
    }

    render() {
        let chevronStyle = {
            height: '15px',
            width: '15px',
            transform: this.props.angle,
            WebkitTransition: 'transform 0.2s ease-out',
            MozTransition: 'transform 0.2s ease-out',
            OTransition: 'transform 0.2s ease-out',
            transition: 'transform 0.2s ease-out',
            fill: this.props.color != null ? this.props.color : FormsStyles.primaryTint,
            marginBottom: '0.08em',
            overflow: 'hidden',
            verticalAlign: 'middle'
        };

        return (
            <svg width="62px" height="62px" viewBox="0 0 62 62" version="1.1" style={chevronStyle}>
                <g id="Group" transform="translate(3.000000, 15.000000)" >
                    <rect id="Rectangle" transform="translate(16.970563, 16.970563) rotate(-45.000000) translate(-16.970563, -16.970563) " x="12.4705627" y="-2.52943725" width="9" height="39" rx="4.5"></rect>
                    <rect id="Rectangle" transform="translate(38.470563, 16.970563) scale(-1, 1) rotate(-45.000000) translate(-38.470563, -16.970563) " x="33.9705627" y="-2.52943725" width="9" height="39" rx="4.5"></rect>
                </g>
            </svg>
        );
    }
}

Chevron.propTypes = {
    angle: PropTypes.string.isRequired,
    color: PropTypes.string
};