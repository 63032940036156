export const EXPANDED_CELL_ADDED = 'EXPANDED_CELL_ADDED';
export const EXPANDED_CELL_REMOVED = 'EXPANDED_CELL_REMOVED';
export const CLEAR_STATE_FULFILLED = 'EXPANDED_CELL_REMOVED';

const addExpandedCell = (pathwayId, identifier, expandedCells) => (dispatch) => {
    let cellsForPathway = [];
    if(expandedCells[pathwayId] != null){
        cellsForPathway = expandedCells[pathwayId];
    }

    cellsForPathway.push(identifier);

    dispatch({
        type: EXPANDED_CELL_ADDED,
        payload: {
            pathwayId: pathwayId,
            expandedCells: cellsForPathway
        }
    });

};

const removeExpandedCell = (pathwayId, identifiers, expandedCells) => (dispatch) => {
    let cellsForPathway = [];
    if(expandedCells[pathwayId] != null){
        cellsForPathway = expandedCells[pathwayId];
    }

    expandedCells = cellsForPathway.filter((item) => {
        return !identifiers.includes(item);
    });

    dispatch({
        type: EXPANDED_CELL_REMOVED,
        payload: {
            pathwayId: pathwayId,
            expandedCells: expandedCells
        }
    });

};

const clearState = (pathwayId) => (dispatch) => {
    dispatch({
        type: CLEAR_STATE_FULFILLED,
        payload: {
            pathwayId: pathwayId,
            expandedCells: []
        }
    });
};

export { addExpandedCell, removeExpandedCell, clearState };