// IMPORT PACKAGE REFERENCES
import React from 'react';
import PropTypes from 'prop-types';
import { FormsStyles } from '../../FormsUI';

// COMPONENT
export class FormBadge extends React.Component {

    constructor(props) {
        super(props);
    }

    shouldComponentUpdate(nextProps) {
        if(nextProps.value !== this.props.value){
            return true;
        }

        if(nextProps.color !== this.props.color){
            return true;
        }

        if(nextProps.solid !== this.props.solid){
            return true;
        }

        return false;
    }

    render() {
        let badgeStyle = {
            backgroundColor: this.props.solid ? this.props.color : FormsStyles.backgroundColor,
            color: this.props.solid ? 'white' : this.props.color,
            borderRadius: '8px',
            paddingLeft: '0.5em',
            paddingRight: '0.5em',
            paddingTop: '0.2em',
            paddingBottom: '0.2em',
            marginLeft: '0.2em',
            fontWeight: '500',
            fontSize: '0.85em',
        };

        return (
            <div className='form-badge' style={badgeStyle}>
                <div className='form-badge-label'>{this.props.value == null ? 'N/A' : this.props.value}</div>
            </div>
        );
    }
}

FormBadge.propTypes = {
    value: PropTypes.string,
    color: PropTypes.string,
    solid: PropTypes.bool.isRequired
};