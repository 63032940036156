import { Card, Stack, Box, Typography } from "@mui/material";
import { CustomContentProps, SnackbarMessage } from "notistack";

interface SnackbarBaseProps {
  backgroundColour: string;
  borderColour: string;
  iconBackgroundColour: string;
  messageColour: string;
  ctaColour: string | null;

  icon: JSX.Element;

  message: SnackbarMessage;
  cta: string | null;
}

export interface CustomSnackbarProps extends CustomContentProps {
  cta?: string;
}

export const SnackbarBase = (props: SnackbarBaseProps) => {
  return (
    <Card
      sx={{
        p: 2,
        width: "100%",
        border: "none",
        boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
        borderBottom: `4px solid ${props.borderColour}`,
        backgroundColor: props.backgroundColour,
        borderRadius: "24px",
      }}
    >
      <Stack direction="row" alignItems={"center"} gap={4} sx={{ mx: 2 }}>
        <Box
          sx={{
            backgroundColor: props.iconBackgroundColour,
            p: 1,
            borderRadius: 20,
            width: 32,
            height: 32,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {props.icon}
        </Box>

        <Stack>
          <Typography color={props.messageColour} fontWeight={500}>
            {props.message}
          </Typography>
          {props.cta && (
            <Typography color={props.ctaColour ?? "white"} fontWeight={600}>
              {props.cta}
            </Typography>
          )}
        </Stack>
      </Stack>
    </Card>
  );
};
