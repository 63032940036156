import { ActionModel, ScheduledAction } from '../../Shared Custom Types/ActionModel';
import { RiskCategoryBrackets } from '../Custom Types/Risk Category';
import { EDChestPainCalculationHelper } from '../Helpers/EDChestPainCalculationHelper';
import { TroponinResult } from '../Custom Types/Troponin';
import { checkAndUpdateForAction } from '../../../components/state/actions/ActionActions';
import { EDChestPainExportToECPP } from '../Export/EDChestPain-ExportToECPP';
import { ENABLED_EXPORTS } from '../../../config/config';

export class EDChestPainActions {

    constructor(formLoader)
    {
        this.formLoader = formLoader;
    }

    update(pathwayId, workflow, oldActions, form) {
        let data = workflow.rawData;

        // Refer to mainForm or the parent of the form (mainForm)
        let mainForm = form.parent != null ? form.parent : form;

        let requiredActions = [];

        let progress = mainForm.completionProgress(['followUpBloodsDateAndTime', 'followUpBloodsTroponin']);
        let formComplete = (progress.current / progress.total) == 1;

        //invalidPatientAction

        let patientEligable = data['patientSex'] != 'genderOtherOption';
        let action = new ActionModel(workflow.pathwayUuid, workflow.versionUuid, 'invalidPatientAction', null, null, 0, false, false, Date.now(), null);
        let reconsiledAction = checkAndUpdateForAction(pathwayId, !patientEligable, action, 'invalidPatientAction', oldActions);
        if (reconsiledAction != null) {
            requiredActions.push(reconsiledAction);
        }
        
        //TotalEWS
        let totalEWS = data['totalEWS'];
        if (totalEWS != null) {
            if (totalEWS >= 6 && totalEWS <= 7) {
                let action = new ActionModel(workflow.pathwayUuid, workflow.versionUuid, 'clinicalInstability', null, null, 0, false, false, Date.now(), null);
                let reconsiledAction = checkAndUpdateForAction(pathwayId, action != null && patientEligable, action, 'clinicalInstability', oldActions);
                if (reconsiledAction != null) {
                    requiredActions.push(reconsiledAction);
                }
            } else if (totalEWS >= 8 && totalEWS <= 9) {
                let action = new ActionModel(workflow.pathwayUuid, workflow.versionUuid, 'clinicalEmergencyDeterioration', null, null, 0, false, false, Date.now(), null);
                let reconsiledAction = checkAndUpdateForAction(pathwayId, action != null && patientEligable, action, 'clinicalEmergencyDeterioration', oldActions);
                if (reconsiledAction != null) {
                    requiredActions.push(reconsiledAction);
                }
            } else if (totalEWS >= 10) {
                let action = new ActionModel(workflow.pathwayUuid, workflow.versionUuid, 'clinicalEmergency', null, null, 0, false, false, Date.now(), null);
                let reconsiledAction = checkAndUpdateForAction(pathwayId, action != null && patientEligable, action, 'clinicalEmergency', oldActions);
                if (reconsiledAction != null) {
                    requiredActions.push(reconsiledAction);
                }
            }
        }

        //STEMI
        let stemiPresent = data['stemiPresent'];
        let ecgCompletionProgress = mainForm.subform('ecgTest').completionProgress();
        let ecgComplete = (ecgCompletionProgress.current / ecgCompletionProgress.total) == 1;
        let stemiActionRequired = stemiPresent == true;
        let stemiAction = new ActionModel(workflow.pathwayUuid, workflow.versionUuid, 'stemiAction', null, null, 0, false, false, Date.now(), null);
        let reconsileStemiAction = checkAndUpdateForAction(pathwayId, stemiActionRequired && ecgComplete && patientEligable, stemiAction, 'stemiAction', oldActions);
        if (reconsileStemiAction != null) {
            requiredActions.push(reconsileStemiAction);
        }

        //Total Red Flags
        let redFlagActionRequired = data['totalRedFlags'] > 0;
        let clinicalFormProgress = (this.formLoader.form(workflow.pathwayUuid, 'highRiskDifferentialDiagnosis')).completionProgress();
        let clincialFormComplete = (clinicalFormProgress.current / clinicalFormProgress.total) == 1;

        let redFlagsAction = new ActionModel(workflow.pathwayUuid, workflow.versionUuid, 'reviewWithSMO', null, null, 0, false, false, Date.now(), null);
        let reconsiledRedFlagAction = checkAndUpdateForAction(pathwayId, redFlagActionRequired && patientEligable && clincialFormComplete, redFlagsAction, 'reviewWithSMO', oldActions);
        if (reconsiledRedFlagAction != null) {
            requiredActions.push(reconsiledRedFlagAction);
        }

        //Assessment Team
        let pathwayActionsCanDisplay = !stemiPresent && !redFlagActionRequired;
        let edacsValue = data['calculatedEDACS'];
        if (edacsValue != null) {
            if (edacsValue < 16) {
                // emergencyMedicineAssessment
                let action = new ActionModel(workflow.pathwayUuid, workflow.versionUuid, 'emergencyMedicineAssessment', null, null, 0, false, false, Date.now(), null);
                let reconsiledAction = checkAndUpdateForAction(pathwayId, action != null && pathwayActionsCanDisplay && patientEligable, action, 'emergencyMedicineAssessment', oldActions);
                if (reconsiledAction != null) {
                    requiredActions.push(reconsiledAction);
                }
            } else if (edacsValue >= 16) {
                // cardiologyAssessment
                let action = new ActionModel(workflow.pathwayUuid, workflow.versionUuid, 'cardiologyAssessment', null, null, 0, false, false, Date.now(), null);
                let reconsiledAction = checkAndUpdateForAction(pathwayId, action != null && pathwayActionsCanDisplay && patientEligable, action, 'cardiologyAssessment', oldActions);
                if (reconsiledAction != null) {
                    requiredActions.push(reconsiledAction);
                }
            }
        }
        
        //Referral & Disposition
        if (formComplete) {
            let currentRiskBracket = EDChestPainCalculationHelper.getRiskBracket(data, false);
            if (currentRiskBracket != null) {
                if (currentRiskBracket.type == RiskCategoryBrackets.Type.veryLowRisk1) {
                    // dischargeToGP
                    let action = new ActionModel(workflow.pathwayUuid, workflow.versionUuid, 'dischargeToGP', null, null, 0, false, false, Date.now(), null);
                    let reconsiledAction = checkAndUpdateForAction(pathwayId, action != null && pathwayActionsCanDisplay && patientEligable, action, 'dischargeToGP', oldActions);
                    if (reconsiledAction != null) {
                        requiredActions.push(reconsiledAction);
                    }
                } else if (currentRiskBracket.type == RiskCategoryBrackets.Type.lowRisk2) {
                    // cardiologySMOReview
                    let action = new ActionModel(workflow.pathwayUuid, workflow.versionUuid, 'cardiologySMOReview', null, null, 0, false, false, Date.now(), null);
                    let reconsiledAction = checkAndUpdateForAction(pathwayId, action != null && pathwayActionsCanDisplay && patientEligable, action, 'cardiologySMOReview', oldActions);
                    if (reconsiledAction != null) {
                        requiredActions.push(reconsiledAction);
                    }
                } else if (currentRiskBracket.type == RiskCategoryBrackets.Type.intermediateRisk1) {
                    // dischargeToAcuteDemandService
                    let action = new ActionModel(workflow.pathwayUuid, workflow.versionUuid, 'dischargeToAcuteDemandService', null, null, 0, false, false, Date.now(), null);
                    let reconsiledAction = checkAndUpdateForAction(pathwayId, action != null && pathwayActionsCanDisplay && patientEligable, action, 'dischargeToAcuteDemandService', oldActions);
                    if (reconsiledAction != null) {
                        requiredActions.push(reconsiledAction);
                    }
                } else if (currentRiskBracket.type == RiskCategoryBrackets.Type.highRisk2) {
                    // discussWithCardiology
                    let action = new ActionModel(workflow.pathwayUuid, workflow.versionUuid, 'discussWithCardiology', null, null, 0, false, false, Date.now(), null);
                    let reconsiledAction = checkAndUpdateForAction(pathwayId, action != null && pathwayActionsCanDisplay && patientEligable, action, 'discussWithCardiology', oldActions);
                    if (reconsiledAction != null) {
                        requiredActions.push(reconsiledAction);
                    }
                } else if (currentRiskBracket.type == RiskCategoryBrackets.Type.highRisk1) {
                    // admitToChestPainUnitOrCardiologyWard
                    let action = new ActionModel(workflow.pathwayUuid, workflow.versionUuid, 'admitToChestPainUnitOrCardiologyWard', null, null, 0, false, false, Date.now(), null);
                    let reconsiledAction = checkAndUpdateForAction(pathwayId, action != null && pathwayActionsCanDisplay && patientEligable, action, 'admitToChestPainUnitOrCardiologyWard', oldActions);
                    if (reconsiledAction != null) {
                        requiredActions.push(reconsiledAction);
                    }
                }
            }

            //Investigations
            let initialRiskBracket = EDChestPainCalculationHelper.getRiskBracket(data, false);
            if (initialRiskBracket != null) {
                if (data['patientSex'] != null) {
                    if (initialRiskBracket.type == RiskCategoryBrackets.Type.intermediateRisk1 || initialRiskBracket.type == RiskCategoryBrackets.Type.highRisk1) {
                        // repeatTroponinAction
                        let initialBloodsDateAndTime = data['initialBloodsDateAndTime'];
                        let initialBloods = TroponinResult.newTroponinResult(data['initialBloodsTroponin']);
                        
                        if (initialBloods != null && initialBloodsDateAndTime != null) {
                            initialBloodsDateAndTime = new Date(initialBloodsDateAndTime);
                            let hoursUntilNextBloods;
                            let detailString = '';
                            switch (initialRiskBracket.type) {
                                case RiskCategoryBrackets.Type.intermediateRisk1:
                                    hoursUntilNextBloods = 24;
                                    detailString = 'Next day troponin, at least two hours after first troponin';
                                    break;
                                case RiskCategoryBrackets.Type.highRisk1:
                                    hoursUntilNextBloods = 2;
                                    detailString = 'Repeat troponin two hours after first troponin';
                                    break;
                            }
                            let afterFirstTroponin = initialBloodsDateAndTime.setHours(initialBloodsDateAndTime.getHours() + hoursUntilNextBloods);

                            let followUpBloodsComplete = data['followUpBloodsTroponin'] != null;
                            let scheduledAction = new ScheduledAction(afterFirstTroponin, ScheduledAction.DuePoint.dueAt, 'Bloods - Repeat', followUpBloodsComplete, !followUpBloodsComplete);

                            let reconsiledNextTroponinAction;
                            if (!followUpBloodsComplete) {
                                let nextTroponinAction = new ActionModel(workflow.pathwayUuid, workflow.versionUuid, 'repeatTroponinAction', null, detailString, 0, false, false, Date.now(), null, scheduledAction);
                                reconsiledNextTroponinAction = checkAndUpdateForAction(pathwayId, nextTroponinAction != null && pathwayActionsCanDisplay && patientEligable, nextTroponinAction, 'repeatTroponinAction', oldActions);
                            }
                            if (reconsiledNextTroponinAction != null && reconsiledNextTroponinAction != undefined) {
                                requiredActions.push(reconsiledNextTroponinAction);
                            }
                        }
                    }
                    if (initialRiskBracket.type == RiskCategoryBrackets.Type.intermediateRisk1 || initialRiskBracket.type == RiskCategoryBrackets.Type.highRisk1) {
                        // repeatECGAction
                        let action = new ActionModel(workflow.pathwayUuid, workflow.versionUuid, 'repeatECGAction', null, null, 0, false, false, Date.now(), null);
                        let reconsiledAction = checkAndUpdateForAction(pathwayId, action != null && pathwayActionsCanDisplay && patientEligable, action, 'repeatECGAction', oldActions);
                        if (reconsiledAction != null) {
                            requiredActions.push(reconsiledAction);
                        }
                    }
                    if (initialRiskBracket.type == RiskCategoryBrackets.Type.lowRisk2 || initialRiskBracket.type == RiskCategoryBrackets.Type.highRisk1 || initialRiskBracket.type == RiskCategoryBrackets.Type.highRisk2) {
                        // followCardiologyAdviceAction
                        let action = new ActionModel(workflow.pathwayUuid, workflow.versionUuid, 'followCardiologyAdviceAction', null, null, 0, false, false, Date.now(), null);
                        let reconsiledAction = checkAndUpdateForAction(pathwayId, action != null && pathwayActionsCanDisplay && patientEligable, action, 'followCardiologyAdviceAction', oldActions);
                        if (reconsiledAction != null) {
                            requiredActions.push(reconsiledAction);
                        }
                    }
                    if (initialRiskBracket.type == RiskCategoryBrackets.Type.veryLowRisk1) {
                        // noFurtherInvestigationRequiredAction
                        let action = new ActionModel(workflow.pathwayUuid, workflow.versionUuid, 'noFurtherInvestigationRequiredAction', null, null, 0, false, false, Date.now(), null);
                        let reconsiledAction = checkAndUpdateForAction(pathwayId, action != null && pathwayActionsCanDisplay && patientEligable, action, 'noFurtherInvestigationRequiredAction', oldActions);
                        if (reconsiledAction != null) {
                            requiredActions.push(reconsiledAction);
                        }
                    }
                }
            }
        }
		
        if (ENABLED_EXPORTS.includes('ecpp') && edacsValue != null && edacsValue != undefined) {
            EDChestPainExportToECPP.performExport(workflow);
        }
        
        return requiredActions;
    }
}