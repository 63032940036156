// IMPORT PACKAGE REFERENCES
import React from 'react';

// COMPONENT
export class Loading extends React.Component {
    render() {
        return (
            <div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
        );
    }
}