import { PatientSex } from '../../Shared Custom Types/Gender';

export class TroponinResult {

    static Status = Object.freeze({ aboveCutoff: 1, belowCutoff: 2, normal: 3 });

    statusTitle(status) {
        switch (status) {
            case TroponinResult.Status.aboveCutoff:
                return 'Above cut-off';
            case TroponinResult.Status.belowCutoff:
                return 'Below cut-off';
            case TroponinResult.Status.normal:
                return 'Normal';
        }
    }

    static lessThanTwo = '<2'

    constructor(resultString) {
        if (resultString == TroponinResult.lessThanTwo) {
            this.numberValue = 0;
        } else {
            let value = parseFloat(resultString);
            if (value < 2) {
                this.numberValue = 0;
            } else {
                this.numberValue = value;
            }
        }
    }

    static newTroponinResult(value) {
        if (value != null && value != undefined && value.toString().length > 0 && (value === TroponinResult.lessThanTwo || (!isNaN(parseFloat(value)) && (parseFloat(value) != null) ))) {
            return new TroponinResult(value.toString());
        }
        return null;
    }

    get string() {
        if (this.numberValue < 2) {
            return TroponinResult.lessThanTwo;
        } else if (this.numberValue != undefined) {
            return this.numberValue.toString();
        }
    }

    set numberValue(value) {
        this._numberValue = value;
    }

    get numberValue() {
        return this._numberValue;
    }

    get doubleValue() {
        if (this.numberValue < 2) {
            return 0;
        }
        return this.numberValue;
    }

    getStatus(gender) {
        switch (gender) {
            case PatientSex.gender.male:
                if (this.doubleValue >= 34) {
                    return TroponinResult.Status.aboveCutoff;
                } else if (this.doubleValue >= 2) {
                    return TroponinResult.Status.belowCutoff;
                } else {
                    return TroponinResult.Status.normal;
                }
            case PatientSex.gender.female:
                if (this.doubleValue >= 16) {
                    return TroponinResult.Status.aboveCutoff;
                } else if (this.doubleValue >= 2) {
                    return TroponinResult.Status.belowCutoff;
                } else {
                    return TroponinResult.Status.normal;
                }
            case PatientSex.gender.other:
                if (this.doubleValue >= 16) {
                    return TroponinResult.Status.aboveCutoff;
                } else if (this.doubleValue >= 2) {
                    return TroponinResult.Status.belowCutoff;
                } else {
                    return TroponinResult.Status.normal;
                }
        }
    }
}
