
import documentVersions from '../../config/documentVersions.json';
import { PathwayFormatter } from '../PathwayFormatter';
import { ACCMRIActionItem } from './Actions/ACCMRIActionItem';
import { ENVIRONMENT } from '../../config/config';

const sectionBreak = '\n\n';

export class ACCMRIFormatter {
    static formatAsPlainText(formLoader, workflow) {
        let assessmentForm = formLoader.form(workflow.pathwayUuid, 'assessment');
        let requestCriteriaForm = formLoader.form(workflow.pathwayUuid, 'requestCriteria');
        let actionOutcome = workflow.rawData['mriRequestOutcome'] != null  ? ACCMRIActionItem.rehydrate(workflow.rawData['mriRequestOutcome']) : null;
        var formattedString = '';
        
        let environmentName = getEnvironmentName();

        formattedString += 'Wayfind ACC MRI Request (' + (environmentName != null ? environmentName : '') + documentVersions[workflow.pathwayUuid] + ') - Updated: ' + PathwayFormatter.formatDate(workflow.lastModifiedDate, 'ddd DD-MMM-YYYY HH:mm') + ' (v' + workflow.version +  ')';
        formattedString += sectionBreak;

        if (actionOutcome != null && actionOutcome != undefined) {
            formattedString += 'MRI REQUEST OUTCOME\n';

            formattedString += actionOutcome.getTitle();

            let reasons = actionOutcome.getReasons();
            if (reasons != null && reasons != undefined && reasons != '') {
                formattedString += '\nOutcome determined by: ' + actionOutcome.getReasons();
            }
            formattedString += sectionBreak;
        }

        formattedString += requestCriteriaForm.detailString(false, true);
        formattedString += '\n';

        let assessment = assessmentForm.detailString(null, true, true);
        if (assessment != null && assessment != undefined && assessment != '') {
            formattedString += assessment;
            formattedString += '\n';
        }

        if (actionOutcome != null && actionOutcome != undefined) {
            formattedString += 'MRI REQUEST OUTCOME\n';

            formattedString += actionOutcome.getTitle() + '\n';

            let reasons = actionOutcome.getReasons();
            if (reasons != null && reasons != undefined && reasons != '') {
                formattedString += 'Outcome determined by: ' + actionOutcome.getReasons();
                formattedString += '\n';
            }
        }

        let additionInformation = workflow.rawData['additionalRequestInformation'];
        if (additionInformation != null && additionInformation != undefined) {
            formattedString += '\nADDITIONAL INFORMATION\n';
            formattedString += additionInformation;
        }

        return formattedString;
    }
}

function getEnvironmentName() {
    switch(ENVIRONMENT) {
        case 'PROD':
            break;
        case 'DEV':
            return 'Dev ';
        case 'TEST':
            return 'Test ';
        case 'STAGING':
            return 'Staging ';
        case 'DEMO':
            return 'Demo ';
    }
}