import { FormContentView } from '../../FormsUI/FormsUI Components/Containers/FormContentView';
import { FormTextField } from '../../FormsUI/FormsUI Components/Controls/FormTextField';
import { FormsStyles } from '../../FormsUI/FormsUI';
import '../../FormsUI/Styles/bloodPressure.scss';


import React from 'react';
import PropTypes from 'prop-types';

export class BloodPressure {

    set systolic(value) {
        this._systolic = value;
    }

    set diastolic(value) {
        this._diastolic = value;
    }

    get systolic() {
        return this._systolic;
    }

    get diastolic() {
        return this._diastolic;
    }

    constructor(systolic, diastolic) {
        this.systolic = systolic;
        this.diastolic = diastolic;
    }

    string() {
        return this.systolic.toString() + '/' + this.diastolic.toString();
    }

    static bloodPressure(systolic, diastolic) {
        if (systolic != null && !isNaN(+parseInt(systolic)) && diastolic != null && !isNaN(+parseInt(diastolic))) {
            return new BloodPressure(parseInt(systolic), parseInt(diastolic));
        }
        return null;
    }

    static bloodPressureFromString(string) {
        if (string == null || string == '') {
            return null;
        }

        let components = string.split('/');

        let integerComponenets = components.map(function (component) {
            return parseInt(component);
        });

        integerComponenets = components.filter(function (component) {
            return !isNaN(+component);
        });

        if (integerComponenets.length != 2) {
            return null;
        }

        return new BloodPressure(integerComponenets[0], integerComponenets[1]);
    }
}

export class BloodPressureTextFormDetail extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            bloodPresure: BloodPressure.bloodPressureFromString(this.props.formDetail.editingValue),
            alternateSystolic: '',
            alternateDiastolic: ''
        };
        this.systolicTextFieldUpdated = this.systolicTextFieldUpdated.bind(this);
        this.diastolicTextFieldUpdated = this.diastolicTextFieldUpdated.bind(this);
    }

    systolicTextFieldUpdated(value) {
        let newBloodPressure = BloodPressure.bloodPressure(parseInt(value), this.state.bloodPresure == null ? '' : this.state.bloodPresure.diastolic);
        this.setState({ bloodPresure: newBloodPressure });

        let bloodPressureString;
        if (newBloodPressure == null) {
            this.setState({
                alternateSystolic: value
            });

            if (value == null) {
                bloodPressureString = null;
            } else {
                bloodPressureString = value.toString() + '/' + this.state.alternateDiastolic.toString();
            }
        } else {
            bloodPressureString = newBloodPressure.string();
        }

        this.props.formDetail.form.valueUpdated(this.props.formDetail.identifier, bloodPressureString);
        this.props.renderErrors();
    }

    diastolicTextFieldUpdated(value) {
        let newBloodPressure = BloodPressure.bloodPressure(this.state.bloodPresure == null ? '' : this.state.bloodPresure.systolic, parseInt(value));
        this.setState({ bloodPresure: newBloodPressure });

        let bloodPressureString;
        if (newBloodPressure == null) {
            this.setState({
                alternateDiastolic: value
            });
            if (value == null) {
                bloodPressureString = null;
            } else {
                bloodPressureString = this.state.alternateSystolic.toString() + '/' + value.toString();
            }
        } else {
            bloodPressureString = newBloodPressure.string();
        }

        this.props.formDetail.form.valueUpdated(this.props.formDetail.identifier, bloodPressureString);
        this.props.renderErrors();
    }

    render() {
        let subcells = this.props.renderSubcells();
        let isMandatory = this.props.formDetail.form.isMandatory(this.props.formDetail.identifier);

        let topControl =
            <div className="blood-pressure-container">
                <FormTextField value={this.state.bloodPresure == null ? this.state.alternateSystolic : this.state.bloodPresure.systolic.toString()} inputType={'number'} onChange={this.systolicTextFieldUpdated} width={'6.8em'} placeholder={isMandatory ? 'Required' : 'Optional'}/> <label className={'blood-pressure-slash ' + (this.state.bloodPresure == null ? 'no-pressure' : '' )}>/</label>
                <FormTextField value={this.state.bloodPresure == null ? this.state.alternateDiastolic : this.state.bloodPresure.diastolic.toString()} inputType={'number'} onChange={this.diastolicTextFieldUpdated} unitText={'mmHg'} width={'6.8em'} placeholder={isMandatory ? 'Required' : 'Optional'} />
            </div>;

        return (<FormContentView identifier={this.props.formDetail.identifier} titleString={this.props.formDetail.title} isMandatory={isMandatory} isSubcell={this.props.isSubcell} validation={this.props.errorLabels} detailString={this.props.formDetail.detail} subCellbackgroundColor={FormsStyles.backgroundColor}  rightControl={topControl} subcell={subcells} showHover={true}/>);
    }
}

BloodPressureTextFormDetail.propTypes = {
    formDetail: PropTypes.object.isRequired,
    isSubcell: PropTypes.bool.isRequired,
    renderErrors: PropTypes.func.isRequired,
    renderSubcells: PropTypes.func.isRequired,
    errorLabels: PropTypes.array.isRequired,
    hasError: PropTypes.bool.isRequired
};