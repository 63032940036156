import { redux } from '../../../App';
import { saveCalculation } from '../../../components/state/actions/CalculationActions';
import { CalculationModel } from '../Custom Types/CalculationModel';

export class ACCBISTCalculations {


    update(pathwayId, workflow, calculations, form) {
       
        let mainForm = form.parent != null ? form.parent : form;

        let progress = mainForm.completionProgress();

        let formComplete = (progress.current / progress.total) == 1;
        formComplete = true;
        
        var isDayPostInjury = false;
        let injuryDateString = workflow.rawData['injuryOccuranceDateTime'];  

        if (injuryDateString != undefined && injuryDateString != null) {
            let injuryDate = new Date(injuryDateString);
            let now = new Date();
            let msBetweenDates = Math.abs(injuryDate.getTime() - now.getTime());
            let hoursBetweenDates = msBetweenDates / (60 * 60 * 1000);
            isDayPostInjury = hoursBetweenDates > 24;
        }

        let physicalValues = [
            workflow.rawData['headacheBistRating'],
            workflow.rawData['neckHurtsBistRating'],
            workflow.rawData['brightLightsBistRating'],
            workflow.rawData['loudNoisesBistRating'],
        ];

        let vestibValues = [
            workflow.rawData['dizzySickBistRating'],
            workflow.rawData['closedEyesAtSeaBistRating'],
            workflow.rawData['troubleWithVisionBistRating'],
            workflow.rawData['clumsyBistRating'],
        ];

        let cognitiveValues = [
            workflow.rawData['longerToThinkBistRating'],
            workflow.rawData['forgetThingsBistRating'],
            workflow.rawData['confusedEasilyBistRating'],
            workflow.rawData['troubleConcentratingBistRating']
        ];

        let post24Hours = [

        ];

        // 24 hours
        if (isDayPostInjury) {
            post24Hours.push(workflow.rawData['angryOrIrritatedBistRating']);
            post24Hours.push(workflow.rawData['dontFeelRightBistRating']);
            post24Hours.push(workflow.rawData['tiredDuringDayBistRating']);
            post24Hours.push(workflow.rawData['sleepMoreOrHardToSleepBistRating']);
        }

        let scaleValues = physicalValues.concat(vestibValues).concat(cognitiveValues).concat(post24Hours);

        if (!scaleValues.includes(null) && !scaleValues.includes.undefined) {
            let score = scaleValues.reduce((a, b) => a + b, 0);
            workflow.rawData['calculatedSymptomScore'] = score;

            let scoreCalculation = new CalculationModel(workflow.uuid, workflow.versionUuid, score, false, CalculationModel.CalculationType.bistScoreCalculation, new Date());
            redux.store.dispatch(saveCalculation(pathwayId, scoreCalculation, calculations));

            let physicalScore = physicalValues.reduce((a, b) => a + b, 0);
            let cognotiveScore = cognitiveValues.reduce((a, b) => a + b, 0);
            let vestibScore = vestibValues.reduce((a, b) => a + b, 0);

            let dominantClusters = [];
            if (vestibScore >= physicalScore && vestibScore >= cognotiveScore) {
                dominantClusters.push('Vestibularocular');
            }
            
            if (cognotiveScore >= vestibScore && cognotiveScore >= physicalScore) {
                dominantClusters.push('Cognitive');
            }
            
            if (physicalScore >= vestibScore && physicalScore >= cognotiveScore) {
                dominantClusters.push('Physical');
            }

            if (dominantClusters.length > 0) {
                let scoreCalculation = new CalculationModel(workflow.uuid, workflow.versionUuid, dominantClusters.join(', '), false, CalculationModel.CalculationType.bistDominantSymptomCluster, new Date());
                redux.store.dispatch(saveCalculation(pathwayId, scoreCalculation, calculations));
                workflow.rawData['calculatedSymptomCluster'] = dominantClusters.join(', ');
            }
            else {
                let scoreCalculation = new CalculationModel(workflow.uuid, workflow.versionUuid, 'None', false, CalculationModel.CalculationType.bistDominantSymptomCluster, new Date());
                redux.store.dispatch(saveCalculation(pathwayId, scoreCalculation, calculations));

                workflow.rawData['calculatedSymptomCluster'] = 'None';
            }
        }
        else {
            workflow.rawData['calculatedSymptomScore'] = null;
            workflow.rawData['calculatedSymptomCluster'] = null;
        }

        // GCS/AWPTA
        if (formComplete) {
            let motorScores = {
                'Obeys commands': 6,
                'Localises': 5,
                'Withdraws': 4,
                'Abnormal flexion': 3,
                'Extension': 2,
                'None': 1
            };

            let eyeScores = {
                'Spontaneously': 4,
                'To speech': 3,
                'To pain': 2,
                'None': 1
            };

            let verbalScores = {
                'Oriented': 5,
                'Confused': 4,
                'Inappropriate words': 3,
                'Incomprehensible sounds': 2,
                'None': 1
            };

            let gcsValues = [workflow.rawData['eyeOpeningScore'], workflow.rawData['motorScore'], workflow.rawData['verbalScore']];

            if (!gcsValues.includes(null) && !gcsValues.includes(undefined) || workflow.rawData['neuroObsOkayCheck'] == true) {
                var totalGCS = 0;

                let eyeScoreRawValue = workflow.rawData['eyeOpeningScore'];
                if (eyeScoreRawValue != null & eyeScoreRawValue != undefined) {
                    let eyeScore = eyeScores[eyeScoreRawValue];
                    totalGCS += eyeScore;
                }
    
                let motorScoreRawValue = workflow.rawData['motorScore'];
                if (motorScoreRawValue != null & motorScoreRawValue != undefined) {
                    let motorScore = motorScores[motorScoreRawValue];
                    totalGCS += motorScore;
                }
    
                let verbalScoreRawValue = workflow.rawData['verbalScore'];
                if (verbalScoreRawValue != null & verbalScoreRawValue != undefined) {
                    let verbalScore = verbalScores[verbalScoreRawValue];
                    totalGCS += verbalScore;
                }
    
                if (totalGCS == 0) {
                    totalGCS = null;
                }

                if (workflow.rawData['neuroObsOkayCheck'] == true && (totalGCS == 0 || totalGCS == null)) {
                    totalGCS = 15;
                }
    
                let gcsCalculation = new CalculationModel(workflow.uuid, workflow.versionUuid, totalGCS, false, CalculationModel.CalculationType.gcsCalculation, new Date());
                redux.store.dispatch(saveCalculation(pathwayId, gcsCalculation, calculations));
                workflow.rawData['totalGCS'] = totalGCS;
    
                workflow.rawData['reducedGCSRedFlag'] = totalGCS < 15;
    
                let riskCategory = 'N/A';
    
                //AWP-TA Check 1
                if (totalGCS != null && totalGCS != undefined) {
                    let result = this.getAWPTA(workflow.rawData, totalGCS);
                    let totalAWPTA = result.totalAWPTA;
                    let validForRiskCategory = result.validForRiskCategory;
        
                    //Risk category
                    if (validForRiskCategory) {

                        let awptaCalculation = new CalculationModel(workflow.uuid, workflow.versionUuid, totalAWPTA, false, CalculationModel.CalculationType.awptaScore, new Date());
                        redux.store.dispatch(saveCalculation(pathwayId, awptaCalculation, calculations));
                        workflow.rawData['totalAWPTA'] = totalAWPTA;

                        let score = workflow.rawData['calculatedSymptomScore'];
                        if (totalAWPTA < 18) {
                            riskCategory = 'High';
                        }
                        else if (score != null && score != undefined) {
                            if(isDayPostInjury) {
                                if (score > 65) {
                                    riskCategory = 'Moderate';
                                }
                                else {
                                    riskCategory = 'Low';
                                }
                            }
                            else {
                                if (score > 49) {
                                    riskCategory = 'Moderate';
                                }
                                else {
                                    riskCategory = 'Low';
                                }
                            }
                        }
                    }
                    else {
                        let awptaCalculation = new CalculationModel(workflow.uuid, workflow.versionUuid, null, false, CalculationModel.CalculationType.awptaScore, new Date());
                        redux.store.dispatch(saveCalculation(pathwayId, awptaCalculation, calculations));
                        workflow.rawData['totalAWPTA'] = null;
                    }
                }
    
                let riskCategoryCalculation = new CalculationModel(workflow.uuid, workflow.versionUuid, riskCategory, false, CalculationModel.CalculationType.concussionRiskCategory, new Date());
                redux.store.dispatch(saveCalculation(pathwayId, riskCategoryCalculation, calculations));
                workflow.rawData['riskCategory'] = riskCategory;
            }
            else {
                let gcsCalculation = new CalculationModel(workflow.uuid, workflow.versionUuid, null, false, CalculationModel.CalculationType.gcsCalculation, new Date());
                redux.store.dispatch(saveCalculation(pathwayId, gcsCalculation, calculations));

                workflow.rawData['totalGCS'] = null;
                workflow.rawData['reducedGCSRedFlag'] = null;

                let awptaCalculation = new CalculationModel(workflow.uuid, workflow.versionUuid, null, false, CalculationModel.CalculationType.awptaScore, new Date());
                redux.store.dispatch(saveCalculation(pathwayId, awptaCalculation, calculations));
                workflow.rawData['totalAWPTA'] = null;
                workflow.rawData['riskCategory'] = null;
            }

            // CT Scan
            var requiresCT;
            let ctDependentValues = [
                workflow.rawData['patientAge'],
                workflow.rawData['concussiveForce'],
                workflow.rawData['alterationInMentalState'],
                workflow.rawData['bleedingOrClottingDisorder'],
                workflow.rawData['dangerousMechanismOfOInjury'],
                workflow.rawData['totalGCS'],
                workflow.rawData['suspectedSkullFracture'],
                workflow.rawData['postTraumaticSeizure'],
                workflow.rawData['focalNeurologicalDeficit']
            ];

            if(workflow.rawData['totalGCS'] != null && workflow.rawData['totalGCS'] && workflow.rawData['totalGCS'] > 0 && workflow.rawData['totalGCS'] < 15) {
                requiresCT = true;
            }
            else if(workflow.rawData['suspectedSkullFracture'] == true) {
                requiresCT = true;
            }
            else if(workflow.rawData['postTraumaticSeizure'] == true) {
                requiresCT = true;
            }
            else if(workflow.rawData['focalNeurologicalDeficit'] == true) {
                requiresCT = true;
            }
            else if(workflow.rawData['vomiting'] == true) {
                requiresCT = true;
            }
            else if([
                workflow.rawData['clopidogrelOrTicagrelor'],
                workflow.rawData['warfarin'],
                workflow.rawData['revaroxabanOrDabigatran'],
                workflow.rawData['otherMedication']].includes(true)) {
                requiresCT = true;
            }
            else if (((workflow.rawData['concussiveForce'] == true || workflow.rawData['alterationInMentalState'] == true) && workflow.rawData['alterationInMentalState'] == true) && (workflow.rawData['patientAge'] >= 65 || workflow.rawData['bleedingOrClottingDisorder'] == true || (workflow.rawData['retrogradeAmnesiaDuration'] != null && workflow.rawData['retrogradeAmnesiaDuration'] != undefined && workflow.rawData['retrogradeAmnesiaDuration'] > 30) || workflow.rawData['dangerousMechanismOfOInjury'] == true)) {
                requiresCT = true;
            }
            else if(!ctDependentValues.includes(null) && !ctDependentValues.includes(undefined)) {
                requiresCT = false;
            }

            workflow.rawData['ctCriteraRedflag'] = requiresCT;
        }
    }

    getAWPTA(rawData, totalGCS) {
        let totalAWPTA;
        let validForRiskCategory = false;

        if (totalGCS == null || totalGCS == undefined) {
            return {totalAWPTA: totalAWPTA, 
                validForRiskCategory: validForRiskCategory};
        }

        var checks = [
            {check: 'recollectionCheck5', recall: 'recollectionFailureCheck5'},
            {check: 'recollectionCheck4', recall: 'recollectionFailureCheck4'}, 
            {check: 'recollectionCheck3', recall: 'recollectionFailureCheck3'}, 
            {check: 'recollectionCheck2', recall: 'recollectionFailureCheck2'}];

        totalAWPTA = totalGCS;

        for(let check of checks) {
            let imageScoreData = rawData[check.check];
            if (imageScoreData != null && imageScoreData != undefined) {
                if (imageScoreData.length == 3) {
                    totalAWPTA += 3;
                    validForRiskCategory = true;
                }
                else {
                    let imageScoreFollowUpData = rawData[check.recall];
                    //bird cup keys
                                    
                    if (imageScoreFollowUpData != null && imageScoreFollowUpData != undefined) {
                        let imageNames = imageScoreFollowUpData.map((image) => image.label);
                                        
                        if (imageNames.includes('Cup')) {
                            totalAWPTA += 1;
                        }
                    
                        if (imageNames.includes('Bird')) {
                            totalAWPTA += 1;
                        }
                    
                        if (imageNames.includes('Keys')) {
                            totalAWPTA += 1;
                        }
                
                        validForRiskCategory = true;
                    }
                }
                break;
            }
        }

        return {totalAWPTA: totalAWPTA, 
            validForRiskCategory: validForRiskCategory};
    }
}