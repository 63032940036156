import { FormWithRedux } from '../../FormWithRedux';
import { PathwayReferenceHelper } from '../../PathwayReferenceHelper';
import { GPChestPainPathwayReference } from '../GPChestPainPathway-Reference';
import { FormsStyles } from '../../../waferJS/FormsUI/FormsUI';
import { FormValidationError } from '../../../waferJS/Form/Validation/FormValidationError';

export class GPChestPainHighRiskDifferentialDiagnosisResultsForm extends FormWithRedux {

    constructor(formLoader, parent, actionUpdater, dependentValuesUpdater, calculationsUpdater) {
        super('highRiskDifferentialDiagnosisResults', formLoader, parent, actionUpdater, dependentValuesUpdater, calculationsUpdater);
    }

    shouldDisplayFormDetailForIdentifier(identifier) {
        super.shouldDisplayFormDetailForIdentifier(identifier);
        return true;
    }

    isEnabled(identifier) {
        if (identifier != null) {
            return true;
        }
        return false;
    }

    isMandatory() {
        return false;
    }

    getValueType() {
        return null;
    }

    submissionValidation() {
        return [];
    }

    getProgress(){
        return 1;
    }

    detailString() {
        let identifier = [
            'aorticDissectionPresent',
            'pulmonaryEmbolism',
            'pancreatitis',
            'pericarditis',
            'pneumothorax'
        ];

        let values = identifier.map((identifier) => {
            let value = this.getValue(identifier);
            if(value != null){
                return value ? PathwayReferenceHelper.reference(identifier, GPChestPainPathwayReference.data).title : null;
            }
        });

        let formProgress = this.completionProgress();
        if(values.filter(n => n).length == 0 && (formProgress.current / formProgress.total) == 1){
            return 'None';
        }else if(values.filter(n => n).length == 0){
            return this.detail;
        }

        return values.filter(n => n).join(',\n');
    }

    getBadgeValue(identifier) {
        let identifiers = {
            'aorticDissectionResults' : 'aorticDissectionPresent',
            'pulmonaryEmbolismResults': 'pulmonaryEmbolism',
            'pancreatitisResults' : 'pancreatitis',
            'pericarditisResults': 'pericarditis',
            'pneumothoraxResults': 'pneumothorax'
        };

        if(this.getValue(identifiers[identifier]) == true){
            return [{
                color: FormsStyles.red,
                value: 'YES',
                solid: false
            }];
        } else if(this.getValue(identifiers[identifier]) == false){
            return [{
                color: FormsStyles.black,
                value: 'NO',
                solid: false
            }];
        } 
        return null;
    }

    calculationError(identifier){
        let identifiers = {
            'aorticDissectionResults' : 'aorticDissectionPresent',
            'pulmonaryEmbolismResults': 'pulmonaryEmbolism',
            'pancreatitisResults' : 'pancreatitis',
            'pericarditisResults': 'pericarditis',
            'pneumothoraxResults': 'pneumothorax'
        };
        let value = this.getValue(identifiers[identifier]);
        if(value == null){
            
            return new FormValidationError(FormValidationError.FormErrorType.warning, 'From High-risk differential diagnosis');
        }
    }

    totalRedFlags(){
        let identifiers = {
            'aorticDissectionResults' : 'aorticDissectionPresent',
            'pulmonaryEmbolismResults': 'pulmonaryEmbolism',
            'pancreatitisResults' : 'pancreatitis',
            'pericarditisResults': 'pericarditis',
            'pneumothoraxResults': 'pneumothorax'
        };

        let count = 0;
        for(let item of Object.keys(identifiers)){
            if (this.getValue(identifiers[item]) == true){
                count += 1;
            }
        }
        return count;
    }

    isRedFlagActive(identifier){
        let value = this.getValue(identifier);
        return value; // override == true ? !value : value;
    }

    completionProgress() {
        var totalFormDetails = 0;
        var validFormDetails = 0;
        
        for (let formDetailIdentifier of this.formDetailIdentifiers) {
            let formDetail = this.formDetail(formDetailIdentifier);
            if (formDetail != null && formDetail.canHaveValue) {
                let form = formDetail.form;
                let mandatory = form.isMandatory(formDetailIdentifier);

                let value = this.getValue(formDetail.identifier);

                if (mandatory) {
                    totalFormDetails += 1;

                    if (value != null) {
                        validFormDetails += 1;
                    } 
                }
            }
        }

        for (let subformIdentifier of this.subformIdentifiers) {
            let subform = this.subform(subformIdentifier);
            let subformProgress = subform.completionProgress();
            totalFormDetails += subformProgress.total;
            validFormDetails += subformProgress.current;
        }

        return { current: validFormDetails, total: totalFormDetails };
    }


}