export class PathwayReferenceHelper {

    static Type = Object.freeze({
        title: 'title',
        detail: 'detail',
        info: 'info',
        templateContent: 'template.content',
        itemType: 'itemType',
        controlType: 'control.type',
        subformSectionIds: 'subform.sectionIDs',
        sectionChildIDs: 'section.childIDs',
        sectionStyle: 'section.style',
        subcellIDs: 'subcellIDs',
        selectionType: 'selection.style',
        identifier: 'identifier',
        selectionOptions: 'selection.options',
        textEntryUnitText: 'textEntry.unitText',
        pickerType: 'picker.type',
        weblink: 'weblink',
        textEntryType: 'textEntry.type',
        subformType: 'subform.type'
    });

    static reference(identifier, data) {
        if(!data) return null;
        let contentData = data['template.content'];

        if (contentData[identifier] != null) {
            return contentData[identifier];
        } else if(data[identifier] != null){
            return data[identifier];
        }
        console.log('No reference found for identifier: ' + identifier);
        return null;
    }

}
