import { FormContentView } from '../../../../waferJS/FormsUI/FormsUI Components/Containers/FormContentView';
import { FormTextField } from '../../../../waferJS/FormsUI/FormsUI Components/Controls/FormTextField';
import { FormButton } from '../../../../waferJS/FormsUI/FormsUI Components/Controls/FormButton';
import { QuidelOrthoTroponinResult } from '../../Custom Types/QuidelOrthoTroponin';
import React from 'react';
import PropTypes from 'prop-types';
import { FormsStyles } from '../../../../waferJS/FormsUI/FormsUI';
import '../Styles/troponinStyles.scss';


export class QuidelOrthoTroponinFormDetail extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            formDetail: props.formDetail,
            isActivelyEditing: false, // Introduced so that the editingValue is not replaced mid-edit
        };

        this.textFieldUpdated = this.textFieldUpdated.bind(this);
        this.adoptedSuggestion = this.adoptedSuggestion.bind(this);
        this.textFieldSelected = this.textFieldSelected.bind(this);
        this.textfieldDeselected = this.textfieldDeselected.bind(this);
    }

    textFieldUpdated(value) {
        this.props.formDetail.form.valueUpdated(this.props.formDetail.identifier, value);
        this.props.renderErrors();
    }

    adoptedSuggestion() {
        this.props.formDetail.form.valueUpdated(this.props.formDetail.identifier, 0);
    }

    textFieldSelected() {
        this.setState({isActivelyEditing: true });
    }

    textfieldDeselected() {
        this.setState({isActivelyEditing: false });
    }

    render() {
        let subcells = this.props.renderSubcells();
        let isMandatory = this.props.formDetail.form.isMandatory(this.props.formDetail.identifier);
        console.log('value before:' + this.props.formDetail.currentValue);
        let troponinResult = QuidelOrthoTroponinResult.newTroponinResult(this.props.formDetail.currentValue);
        let value;
        
        if (troponinResult != null && !this.state.isActivelyEditing) {
            value = troponinResult.string;
        } else {
            value = this.props.formDetail.editingValue != null ? this.props.formDetail.editingValue.toString() : '';
        }
        let textfield = <FormTextField value={value} onChange={this.textFieldUpdated} placeholder={isMandatory ? 'Required' : 'Optional'} unitText={this.props.formDetail.unitText} onBlur={this.textfieldDeselected} onFocus={this.textFieldSelected} color={this.props.hasError ? FormsStyles.red : null}/>;
        console.log('value after:' + value);
        let suggestionStyle = {
            marginTop: '1em',
            paddingLeft: '70%'
        };

        let helperButton;
        if (this.props.formDetail.editingValue == null) {
            helperButton =
                <div style={suggestionStyle}>
                    <FormButton title={'<0.05'} enabled={true} isSelected={false} onPress={this.adoptedSuggestion} />
                </div>;
        }

        return (<FormContentView identifier={this.props.formDetail.identifier} isSubcell={this.props.isSubcell} titleString={this.props.formDetail.title} isMandatory={isMandatory} detailString={this.props.formDetail.detail} bottomControl={helperButton} validation={this.props.errorLabels} rightControl={textfield} subcell={subcells} showHover={true}/>);
    }
}

QuidelOrthoTroponinFormDetail.propTypes = {
    formDetail: PropTypes.object.isRequired,
    isSubcell: PropTypes.bool.isRequired,
    renderErrors: PropTypes.func.isRequired,
    renderSubcells: PropTypes.func.isRequired,
    errorLabels: PropTypes.array.isRequired,
    hasError: PropTypes.bool.isRequired
};
