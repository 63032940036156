import {
    CREATE_NEW_RESPONSE_PENDING,
    CREATE_NEW_RESPONSE_FULFILLED,
    CREATE_NEW_RESPONSE_FAILED,
    UPDATING_WORKFLOW_PENDING,
    UPDATING_WORKFLOW_FULFILLED,
    UPDATING_WORKFLOW_FAILED,
    IMPORT_WORKFLOW_FULFILLED,
    CLEAR_WORKFLOW_FULFILLED
} from '../actions/WorkflowResponseActions';

// INITIALIZE STATE
const initialState = {
    workflows: {},
    creatingNewWorkflowPending: false,
    creatingNewWorkflowFulfilled: false,
    creatingNewWorkflowFailed: false,
    updatingWorkflowPending: false,
    updatingWorkflowFulfilled: false,
    updatingWorkflowFailed: false,
    importWorkflowfulfilled: false,
    clearWorkflowFulfilled: false
};

export const WorkflowResponseReducer = (state = initialState, action) => {

    switch (action.type) {
        case CREATE_NEW_RESPONSE_PENDING:
            return {
                ...state,
                creatingNewWorkflowPending: true,
                creatingNewWorkflowFulfilled: false,
                creatingNewWorkflowFailed: false,
                clearWorkflowFulfilled: false
            };
        case CREATE_NEW_RESPONSE_FULFILLED:{
            let workflows = Object.assign({}, state.workflows);
            workflows[action.payload.pathwayId] = action.payload.workflow;
            return {
                ...state,
                workflows: workflows,
                creatingNewWorkflowPending: false,
                creatingNewWorkflowFulfilled: true,
                creatingNewWorkflowFailed: false,
            };
        }
        case CREATE_NEW_RESPONSE_FAILED:
            return {
                ...state,
                workflow: action.payload,
                creatingNewWorkflowPending: false,
                creatingNewWorkflowFulfilled: false,
                creatingNewWorkflowFailed: true,
            };
        case UPDATING_WORKFLOW_PENDING:
            return {
                ...state,
                updatingWorkflowPending: true,
                updatingWorkflowFulfilled: false,
                updatingWorkflowFailed: false
            };
        case UPDATING_WORKFLOW_FULFILLED:{
            let workflows = Object.assign({}, state.workflows);
            workflows[action.payload.pathwayId] = action.payload.workflow;
            return {
                ...state,
                workflows: workflows,
                updatingWorkflowPending: false,
                updatingWorkflowFulfilled: true,
                updatingWorkflowFailed: false
            };
        }
        case UPDATING_WORKFLOW_FAILED:
            return {
                ...state,
                workflow: action.payload,
                updatingWorkflowPending: false,
                updatingWorkflowFulfilled: false,
                updatingWorkflowFailed: true
            };
        case IMPORT_WORKFLOW_FULFILLED:{
            let workflows = Object.assign({}, state.workflows);
            workflows[action.payload.pathwayId] = action.payload.workflow;
            return {
                ...state,
                workflows: workflows,
                importWorkflowfulfilled: true
            };
        } 
        case CLEAR_WORKFLOW_FULFILLED: {
            let workflows = Object.assign({}, state.workflows);
            workflows[action.payload.pathwayId] = action.payload.workflow;
            return {
                ...state,
                workflows: workflows,
                clearWorkflowFulfilled: true
            };
        }
        default:
            return state;
    }
};