import { FormWithRedux } from '../../FormWithRedux';

export class ACCBISTConfoundingFactorsForm extends FormWithRedux {

    constructor(formLoader, parent, actionUpdater, dependentValuesUpdater, calculationsUpdater) {
        super('confoundingFactorsSubform', formLoader, parent, actionUpdater, dependentValuesUpdater, calculationsUpdater);
    }

    shouldDisplayFormDetailForIdentifier(identifier) {
        super.shouldDisplayFormDetailForIdentifier(identifier);

        return true;
    }

    isEnabled() {
        return true;
    }

    isMandatory(identifier) {
        if (identifier == 'intoxicationOrDrugUse' || identifier == 'psychologicalOrEmotionalTrauma' || identifier == 'severePain') {
            return this.getValue('confoundingFactors') == true;  
        }

        return true;
    }

    getValueType(identifier) {
        if (identifier == 'intoxicationOrDrugUse' || identifier == 'psychologicalOrEmotionalTrauma' || identifier == 'severePain') {
            return 'boolean';  
        }

        return null;
    }

    submissionValidation() {
        return [];
    }

    editingValidation(identifier) {
        return [];
    }
}