import {
    SAVE_ACTION_PENDING,
    SAVE_ACTION_FULFILLED,
    SAVE_ACTION_FAILED,
    CLEAR_ACTIONS_FULFILLED,
    LAST_OBSERVED_ACTIONS_UPDATED,
    CLEAR_OBSERVED_ACTIONS
} from '../actions/ActionActions';

// INITIALIZE STATE
const initialState = {
    actions: {},
    lastObservedActions: {},
    saveActionPending: false,
    saveActionFulfilled: false,
    saveActionFailed: false,
    clearActionsFulfilled: false
};

export const ActionsReducer = (state = initialState, action) => {
    switch (action.type) {
        case SAVE_ACTION_PENDING: {
            return {
                ...state,
                saveActionPending: true,
                saveActionFulfilled: false,
                saveActionFailed: false
            };
        }
        case SAVE_ACTION_FULFILLED: {
            let actions = Object.assign({}, state.actions);
            actions[action.payload.pathwayId] = action.payload.actions;
            return {
                ...state,
                actions: actions,
                saveActionPending: false,
                saveActionFulfilled: true,
                saveActionFailed: false
            };
        }
        case SAVE_ACTION_FAILED: {
            return {
                ...state,
                saveActionPending: false,
                saveActionFulfilled: false,
                saveActionFailed: true
            };
        }
        case CLEAR_ACTIONS_FULFILLED: {
            let actions = Object.assign({}, state.actions);
            actions[action.payload.pathwayId] = action.payload.actions;
            return {
                ...state,
                clearActionsFulfilled: true,
                actions: actions
            };
        }
        case LAST_OBSERVED_ACTIONS_UPDATED: {
            let lastObservedActions = Object.assign({}, state.lastObservedActions);
            lastObservedActions[action.payload.pathwayId] = action.payload.lastObservedActions;
            return {
                ...state,
                lastObservedActions: lastObservedActions,
            };
        }
        case CLEAR_OBSERVED_ACTIONS: {
            let lastObservedActions = Object.assign({}, state.lastObservedActions);
            lastObservedActions[action.payload.pathwayId] = action.payload.actions;
            return {
                ...state,
                lastObservedActions: lastObservedActions
            };
        }
        default:
            return state;
    }
};