import { SnackbarContent } from "notistack";
import { forwardRef } from "react";
import { orange } from "@mui/material/colors";
import { PriorityHigh } from "@mui/icons-material";
import { CustomSnackbarProps, SnackbarBase } from "./SnackbarBase";

const WarningSnackbar = forwardRef<HTMLDivElement, CustomSnackbarProps>(
  (props, ref) => {
    return (
      <SnackbarContent ref={ref} role="alert">
        <SnackbarBase
          backgroundColour={orange[50]}
          iconBackgroundColour={orange[100]}
          borderColour={orange[300]}
          messageColour={orange[700]}
          ctaColour={orange[900]}
          message={props.message}
          cta={props.cta ?? null}
          icon={<PriorityHigh color="warning" />}
        />
      </SnackbarContent>
    );
  }
);

WarningSnackbar.displayName = "WarningSnackbar";

export default WarningSnackbar;
