import { FormContentView } from '../../FormsUI/FormsUI Components/Containers/FormContentView';
import { FormsStyles } from '../../FormsUI/FormsUI';
import { FormBadge } from '../../FormsUI/FormsUI Components/Controls/FormBadge';
import React from 'react';
import PropTypes from 'prop-types';
import { FormLabel } from '../../FormsUI/FormsUI Components/Controls/FormLabel';
import { FormValidationError } from '../../Form/Validation/FormValidationError';


export class BadgeFormDetail extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {

        let badgeDetails = this.props.formDetail.form.getBadgeValue(this.props.formDetail.identifier);
        let badge;

        let validation = [];
        if(typeof this.props.formDetail.form.calculationError === 'function'){
            let calculationError = this.props.formDetail.form.calculationError(this.props.formDetail.identifier);
            
            if(calculationError != null){
                validation.push(<FormLabel key={calculationError.message + this.props.formDetail.identifier} value={calculationError.message} textColor={calculationError.errorType == FormValidationError.FormErrorType.warning ? 'orange' : 'red'} />);
            }
        }
        

        let title = this.props.formDetail.title;
        if (badgeDetails != null && badgeDetails[0] != null) {
            badge = <FormBadge value={badgeDetails[0].value} color={badgeDetails[0].color} solid={badgeDetails[0].solid} />;

            if (badgeDetails.title != null) {
                title = badgeDetails[0].title;
            }
        }

        return (
            <div style={{paddingBottom: this.props.isSubcell ? '0' : '0.6em'}}>
                <FormContentView identifier={this.props.formDetail.identifier} isSubcell={this.props.isSubcell} titleString={title} detailString={this.props.formDetail.detail} topRightControl={badge} subcell={[]} validation={validation} subCellbackgroundColor={FormsStyles.backgroundColor} showHover={this.props.showHover}  />
            </div>
        );
    }
}

BadgeFormDetail.propTypes = {
    formDetail: PropTypes.object.isRequired,
    showHover: PropTypes.bool.isRequired,
    isSubcell: PropTypes.bool.isRequired,
    renderErrors: PropTypes.func.isRequired,
    renderSubcells: PropTypes.func.isRequired,
    errorLabels: PropTypes.array.isRequired,
    hasError: PropTypes.bool.isRequired
};