import { v1 as uuidv1 } from 'uuid';
import { FormsStyles } from '../../../waferJS/FormsUI/FormsUI';

export class CalculationModel {

    static CalculationType = Object.freeze({awptaScore: 0, bistScoreCalculation: 2, bistDominantSymptomCluster: 3, concussionRiskCategory: 4});

    static typeRequiresPrimaryFormValidation(type) {
        // switch (type) {
        //     case CalculationModel.CalculationType.concussionRiskCategory:
        //     case CalculationModel.CalculationType.awptaScore:
        //         return true;
        //     case CalculationModel.CalculationType.bistScoreCalculation:
        //     case CalculationModel.CalculationType.bistDominantSymptomCluster:
        //         return false;
        // }

        return false;
    }
    
    constructor(pathwayUuid, versionUuid, value, observed, type, lastModifiedDate, uuid) {
        
        this.pathwayUuid = pathwayUuid;
        this.versionUuid = versionUuid;
        
        this.value = value;
        
        this.observed = observed;
        this.type = type;
        
        this.lastModifiedDate = lastModifiedDate;

        if(uuid == null){
            this.uuid = uuidv1();
        } else {
            this.uuid = uuid;
        }
    }

    static rehydrate(calculation){
        return new CalculationModel(calculation.pathwayUuid, calculation.versionUuid, calculation.value, calculation.observed, calculation.type, calculation.lastModifiedDate, calculation.uuid);
    }
    
    get valueString() {
        if(this.value == null) {
            return 'N/A';
        }

        switch (this.type) {
            case CalculationModel.CalculationType.bistDominantSymptomCluster: {
                return this.value.toString();
            }
            case CalculationModel.CalculationType.concussionRiskCategory: {
                return this.value.toString();
            }
            default:{
                let decimal = (this.value).toFixed(0);
                return decimal.toString();
            }
                
        }
    }
    
    get badgeColor() {
        if (this.value == null) {
            return FormsStyles.backgroundGray;
        }
        else if (this.value == 'None' || this.value == 'N/A') {
            return FormsStyles.lightGray;
        }
        else if (this.value == 'High') {
            return FormsStyles.red;
        }

        return FormsStyles.orange;
    }

    get badgeStyle() {
        if(this.value == null) {
            return false;
        }

        return true;
    }

    get rating() {
        return null;
    }

    get textColor(){
        if(this.value == null) {
            return FormsStyles.black;
        }
        return FormsStyles.white;
    }
    
}