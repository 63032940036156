import { FormWithRedux } from '../../FormWithRedux';
import { FormValidation } from '../../../waferJS/Form/Validation/FormValidation';
import { IsNumber, IsPositive } from '../../../waferJS/Form To FormsUI/Subclasses/Validation Overrides/DecimalNumberValidaton';
import { CheckHeartRate, CheckBodyTemperature, CheckOxygenSaturation, CheckRespiratoryRate, IsBloodPressure } from '../Validation Overrides/GPChestPain-PathwayValidation';
import { TotalEWS, calculateEWS, EWSCategory } from '../Custom Types/EWS';

export class GPChestPainVitalSignsForm extends FormWithRedux {

    constructor(formLoader, parent, actionUpdater, dependentValuesUpdater, calculationsUpdater) {
        super('vitalSigns',formLoader, parent, actionUpdater, dependentValuesUpdater, calculationsUpdater);
    }

    shouldDisplayFormDetailForIdentifier(identifier) {
        super.shouldDisplayFormDetailForIdentifier(identifier);
        switch (identifier) {
            case 'heartRateEWS':
            case 'bloodPressureEWS':
            case 'bodyTemperatureEWS':
            case 'oxygenSaturationEWS':
            case 'respiratoryRateEWS':
            case 'supplementalOxygenEWS':
            case 'levelOfConsciousnessEWS': {
                let value = this.getValue(identifier.replace('EWS', ''));
                let ews = calculateEWS(identifier, value);
                return  ews != null && ews.type != EWSCategory.Type.white ;
            }
            default:
                return true;
        }
    }

    isEnabled(identifier) {
        if (identifier != null) {
            return true;
        }
        return false;
    }

    isMandatory(identifier) {
        let mandatoryIdentifiers = [
            'oxygenSaturation',
            'bodyTemperature',
            'respiratoryRate',
            'heartRate',
            'levelOfConsciousness',
            'supplementalOxygen',
            'bloodPressure'
        ];
        if (mandatoryIdentifiers.includes(identifier)) {
            return true;
        }
        return false;
    }

    getValueType(identifier) {
        if (identifier == 'heartRate' || identifier == 'bodyTemperature' || identifier == 'oxygenSaturation' || identifier == 'respiratoryRate') {
            return 'double';
        } else if (identifier == 'supplementalOxygen') {
            return 'boolean';
        } else if (identifier == 'levelOfConsciousness') {
            return 'string';
        } else if (identifier == 'bloodPressure') {
            return 'bloodpressure';
        }

        return null;
    }

    getBadgeValue(identifier) {
        let value = this.getValue(identifier.replace('EWS', ''));
        let ews = calculateEWS(identifier, value);
        if (ews != null) {
            return [{
                color: ews.color,
                title: ews.title,
                value: ews.score,
                solid: true
            }];
        }
        return [];
    }

    totalEWS() {
        let categories = this.formDetailIdentifiers.map((identifier) => {
            let value = this.getValue(identifier.replace('EWS', ''));
            return calculateEWS(identifier, value);
        }).filter(n => n);

        if (categories.length == 0 && !this.areAllCurrentValuesValid()) {
            return null;
        }
        return new TotalEWS(categories);
    }

    requiredValidation() {
        // Even if a value is optional we check for notNil, that way we can verify if an optional value is notNil
        return [FormValidation.notNill];
    }

    submissionValidation(identifier) {
        if (identifier == 'oxygenSaturation') {
            return [IsPositive];
        } else if (identifier == 'bodyTemperature') {
            return [IsNumber];
        } else if (identifier == 'respiratoryRate') {
            return [IsNumber, IsPositive];
        } else if (identifier == 'heartRate') {
            return [IsNumber, IsPositive];
        }
    }

    editingValidation(identifier) {
        if (identifier == 'oxygenSaturation') {
            return [IsNumber, CheckOxygenSaturation];
        } else if (identifier == 'bodyTemperature') {
            return [IsNumber, CheckBodyTemperature];
        } else if (identifier == 'respiratoryRate') {
            return [IsNumber, CheckRespiratoryRate];
        } else if (identifier == 'heartRate') {
            return [IsNumber, CheckHeartRate];
        } else if (identifier == 'bloodPressure') {
            return [IsBloodPressure];
        }
        return [];
    }

    detailString() {
        var detailString;

        let formDetails = this.getFormDetails();
        var dateAndTimeDescription;

        let values = formDetails.map((formDetail) => {
            return this.abbreviatedString(formDetail.identifier, formDetail.valueDescription());
        });

        detailString = values.filter(n => n).join(', ');

        if (dateAndTimeDescription != null && dateAndTimeDescription.length > 0) {
            detailString = [dateAndTimeDescription, detailString].join('\n');
        }

        if(detailString.length == 0){
            return this.detail;
        }

        return detailString;
    }

    abbreviatedString(identifier, value) {

        if (value == null) {
            return null;
        }

        switch (identifier) {
            case 'heartRate':
                return 'HR: ' + value;
            case 'bloodPressure':
                return 'BP: ' + value;
            case 'bodyTemperature':
                return 'T: ' + value + '℃';
            case 'oxygenSaturation':
                return 'SpO₂: ' + value + '%';
            case 'respiratoryRate':
                return 'RR: ' + value;
            case 'supplementalOxygen':
                return 'Supplemental oxygen';
            case 'levelOfConsciousness':
                return 'Level of consciousness: ' + value;
            case 'heartRateEWS':
            case 'bloodPressureEWS':
            case 'bodyTemperatureEWS':
            case 'oxygenSaturationEWS':
            case 'respiratoryRateEWS':
            case 'supplementalOxygenEWS':
            case 'levelOfConsciousnessEWS':
                return null;
        }
    }

}