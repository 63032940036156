import { FormContentView } from '../../FormsUI/FormsUI Components/Containers/FormContentView';
import { FormsStyles } from '../../FormsUI/FormsUI';
import React from 'react';
import PropTypes from 'prop-types';
import { FormCardGridCheck1 } from '../../FormsUI/FormsUI Components/Controls/FormCardGridCheck1';
import { pictureCards } from '../../../lib/pictureCards';
import { FormButton } from '../../FormsUI/FormsUI Components/Controls/FormButton';

export class PictureGridCheck1FormDetail extends React.Component {

    constructor(props) {
        super(props);

        this.confirmCheck = this.confirmCheck.bind(this);
    }

    confirmCheck() {
        this.props.formDetail.form.valueUpdated(this.props.formDetail.identifier, true);
    }

    render() {
        let enabled = this.props.formDetail.form.isEnabled(this.props.formDetail.identifier);
        let currentValue = this.props.formDetail.form.getValue(this.props.formDetail.identifier);
        currentValue = currentValue == null ? false : currentValue;

        let grid = 
            <div>        
                <FormCardGridCheck1 cardList={pictureCards} />
                <div style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-end'}}>
                    <FormButton key='confirm' onPress={this.confirmCheck} enabled={enabled} title={'Confirm'} isSelected={currentValue} />
                </div>
            </div>;


        return (
            <FormContentView identifier={this.props.formDetail.identifier} isSubcell={this.props.isSubcell} titleString={this.props.formDetail.title} detailString={this.props.formDetail.detail} subcell={[]} bottomControl={grid} subCellbackgroundColor={FormsStyles.backgroundColor} showHover={true}/>
        );
    }
}

PictureGridCheck1FormDetail.propTypes = {
    formDetail: PropTypes.object.isRequired,
    isSubcell: PropTypes.bool.isRequired,
    renderErrors: PropTypes.func.isRequired,
    renderSubcells: PropTypes.func.isRequired,
    errorLabels: PropTypes.array.isRequired,
    hasError: PropTypes.bool.isRequired
};