

import { CalculationModel } from '../Custom Types/CalculationModel';

export class ACCBISTCalculationHelper {
    
    static calcualtionError(identifier, data, form) {
        return null;
    }

    static getLatestCalculation(calculationType, calculations){
        if(calculations == null) { return null; }

        var filteredDict = Object.keys(calculations).reduce(function (filtered, key) {
            if (calculations[key].type == calculationType) filtered[key] = calculations[key];
            return filtered;
        }, {});

        let filteredSortedArray = Object.keys(filteredDict).map(function(key) {
            return filteredDict[key];
        }).sort((first, second) => {
            return new Date(second.lastModifiedDate).getTime() - new Date(first.lastModifiedDate).getTime();
        });

        if(filteredSortedArray.length == 0) {
            return null;
        }
        
        return new CalculationModel(filteredSortedArray[0].pathwayUuid, filteredSortedArray[0].versionUuid, filteredSortedArray[0].value, filteredSortedArray[0].observed, filteredSortedArray[0].type, filteredSortedArray[0].lastModifiedDate, filteredSortedArray[0].uuid);
    }

    static getPreviousCalculation(calculationType, calculations){
        if(calculations == null) { return null; }

        var filteredDict = Object.keys(calculations).reduce(function (filtered, key) {
            if (calculations[key].type == calculationType) filtered[key] = calculations[key];
            return filtered;
        }, {});

        let filteredSortedArray = Object.keys(filteredDict).map(function(key) {
            return filteredDict[key];
        }).sort((first, second) => {
            return new Date(second.lastModifiedDate).getTime() - new Date(first.lastModifiedDate).getTime();
        });

        if(filteredSortedArray.length < 2) {
            return null;
        }
        
        return new CalculationModel(filteredSortedArray[1].pathwayUuid, filteredSortedArray[1].versionUuid, filteredSortedArray[1].value, filteredSortedArray[1].observed, filteredSortedArray[1].type, filteredSortedArray[1].lastModifiedDate, filteredSortedArray[1].uuid);
    }
}