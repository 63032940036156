// IMPORT PACKAGE REFERENCES
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { PathwayReferenceHelper } from '../../forms/PathwayReferenceHelper';
import { PrintHeader } from '../shared/PrintHeader';
import { ReferenceDataLoader } from '../../forms/ReferenceDataLoader';
import { FormDetailWithReact } from '../../waferJS/Form To FormsUI/FormDetailWithReact';
import { withRouter } from '../routers/withRouter';

// COMPONENT
class SectionPage extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            mainForm: props.formLoader.mainForm(this.props.match.params.pathwayId),
            data: ReferenceDataLoader.referenceData(this.props.match.params.pathwayId)
        }; 
    }

    componentDidMount(){
        this.updateTitle();
    }

    componentDidUpdate(prevProps){
        if(JSON.stringify(this.props.match) !== JSON.stringify(prevProps.match)){
            this.updateTitle();
        }
    }

    updateTitle(){
        let sectionReference =  PathwayReferenceHelper.reference(this.props.match.params.sectionId, this.state.data);
        document.title = 'Wayfind | ' + PathwayReferenceHelper.reference('title', ReferenceDataLoader.referenceData(this.props.match.params.pathwayId)) + ' - ' +  sectionReference.title;
    }

    render() {
        let section = this.state.mainForm.sectionReference(this.props.match.params.sectionId);
        return (
            <Fragment>
                {!this.isExtension && <PrintHeader forceVisibility={false} pathwayId={this.props.match.params.pathwayId}/>}
                <header className='page-title no-descrption'>{section.title}</header>
                <div className={'form-container ' + (this.props.isExtension ? 'hidden-nav' : '')}>
                    <div key={section} className='form-section'>
                        {
                            section[PathwayReferenceHelper.Type.sectionChildIDs].map((childID) => {
                                if (this.state.mainForm.shouldDisplayFormDetailForIdentifier(childID)) {
                                    let formDetail = this.state.mainForm.formDetail(childID);
                                    return <FormDetailWithReact formLoader={this.props.formLoader} key={formDetail.identifier} formDetail={formDetail} isSummary={true} isSubcell={false} showHover={true}/>;
                                }
                            })
                        }
                    </div>
                </div>
            </Fragment>
        );
    }
}

SectionPage.propTypes = {
    location: PropTypes.object.isRequired,
    navigate: PropTypes.func.isRequired,
    isExtension: PropTypes.bool.isRequired,
    workflows: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired
};


// CONFIGURE REACT REDUX
const mapStateToProps = state => {
    const { workflows, updatingWorkflowPending, updatingWorkflowFulfilled, updatingWorkflowFailed } = state.workflowReducer;
    return { workflows, updatingWorkflowPending, updatingWorkflowFulfilled, updatingWorkflowFailed };
};

const mapDispatchToProps = dispatch => (
    
    bindActionCreators({}, dispatch)
);

const hoc = withRouter(connect(mapStateToProps, mapDispatchToProps)(SectionPage));

// EXPORT COMPONENT

export { hoc as SectionPage };