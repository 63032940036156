// IMPORT PACKAGE REFERENCES
import React from 'react';
import PropTypes from 'prop-types';
import { FormsStyles } from '../../FormsUI';
import '../../Styles/scaleKey.scss';
import propTypes from 'prop-types';
import { Smiley, SmileyMeh, SmileyNervous, SmileySad } from 'phosphor-react';

//COMPONENT
export class FormScaleKey extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            smileyType: props.smileyType,
            color: props.color,
            text: props.text
        };
    }

    render() {
        let smileyElement = null;

        if(this.props.smileyType === 'happy') {
            smileyElement = <Smiley color='#02b839' size={36} className='smiley' />;
        }
        else if(this.props.smileyType === 'mild') {
            smileyElement = <SmileyMeh color='#bb9011' size={36} className='smiley' />;
        }
        else if(this.props.smileyType === 'moderate') {
            smileyElement = <SmileyNervous color='#f47906' size={36} className='smiley' />;
        }
        else if(this.props.smileyType === 'severe') {
            smileyElement = <SmileySad color='#d4011a' size={36} className='smiley' />;
        }

        return (
            <div className='smiley-wrapper'>
                {smileyElement}
                <span className={`smiley-text-${this.props.color}`}>{this.props.text}</span>
                <div className={`smiley-line-${this.props.color}`}></div>
            </div>
        );
    }
}

FormScaleKey.propTypes = {
    smileyType: propTypes.string,
    color: propTypes.string,
    text:propTypes.string
};