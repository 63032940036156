import { FormWithRedux } from '../../FormWithRedux';
import { IsPositive } from '../../../waferJS/Form To FormsUI/Subclasses/Validation Overrides/DecimalNumberValidaton';

export class GallbladderPatientDetailsForm extends FormWithRedux {

    constructor(formLoader, parent, actionUpdater, dependentValuesUpdater) {
        super('patientDetails', formLoader, parent, actionUpdater, dependentValuesUpdater);
    }

    shouldDisplayFormDetailForIdentifier(identifier) {
        super.shouldDisplayFormDetailForIdentifier(identifier);
        if(identifier == 'privateInsuranceDetail'){
            return this.getValue('patientPrivateInsurance') == true;
        } else if(identifier == 'patientDateOfBirth'){
            return this.getValue(identifier) != null || this.getValue('patientAge') == null;
        }
        return true;
    }

    isEnabled(identifier) {
        if (identifier != null) {
            return true;
        }
        return false;
    }

    isMandatory(identifier) {
        if(identifier == 'patientAge' || identifier == 'patientPrivateInsurance'){
            return true;
        }
        return false;
    }

    getValueType(identifier) {
        if(identifier == 'patientDateOfBirth'){
            return 'date';
        } else if(identifier == 'patientPrivateInsurance'){
            return 'boolean';
        } else if(identifier == 'patientAge'){
            return 'double';
        }
        return null;
    }

    editingValidation(identifier){
        if(identifier == 'patientDOB'){
            return [IsPositive];
        }
    }

    submissionValidation() {
        return [];
    }

    detailString(isSubform, isPlainText) {
        let formDetails = this.getFormDetails();
        let values = formDetails.map((value) => {
            return this.getSummaryValue(isSubform, value);
            
        });

        if(values.filter(n => n).length == 0) {
            return isSubform ? '' : this.detail;
        }

        if(isPlainText == true){
            return values.filter(n => n).join(', ');
        }

        return values.filter(n => n).join(',\n');
    }

    getSummaryValue(isSubcell, value){
        if (value.identifier == 'patientAge' && value.valueDescription() != null){
            if (value.currentValue != null) {
                return 'Age: ' + this.getValue('patientAge') + ' year' + (this.getValue('patientAge') == 1 ? '' : 's');
            }
        } else if(value.identifier == 'patientPrivateInsurance' && this.getValue('patientPrivateInsurance') != null){
            return this.getValue('patientPrivateInsurance') == true ? 'Private funding indicated' : false;
        }
        return value.valueDescription() == null ? null : (isSubcell ? '- ' : '') + value.valueDescription();
    }

    shouldShowEmptySection(){
        return false;
    }
}