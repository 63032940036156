export class DXAActionItem {

    static TimeFrame = Object.freeze({fifteen: 15, sixMonths: 6, unclear: -1, none: -2});

    static Acceptance = Object.freeze({autoAccept: 'AUTOMATED TRIAGE ACCEPTED DXA FUNDED SCAN', manualReview: 'REQUIRES SPECIALIST TRIAGE REVIEW FOR DXA FUNDED SCAN', criteriaNotMet: 'DOES NOT MEET CRITERIA FOR DXA FUNDED SCAN'})

    constructor(acceptance, clicnicallyIndicated, dhbFunded, appointmentTimeFrame, reasons, additionalDetail){
        this.acceptance = acceptance;
        this.clicnicallyIndicated = clicnicallyIndicated;
        this.appointmentTimeFrame = appointmentTimeFrame;
        this.reasons = reasons;
        this.dhbFunded = dhbFunded;
        this.additionalDetail = additionalDetail;
    }

    getLongDetail(isPlainText, privatelyFunded){
        let detailStrings = [];

        if(!privatelyFunded){
            if(this.acceptance == DXAActionItem.Acceptance.autoAccept){
                detailStrings.push('Attach the outcome to your ERMS request using the ‘Copy as Plain Text’ button at the bottom of this page so an appointment can be scheduled.');
            }
            else if(this.acceptance == DXAActionItem.Acceptance.manualReview){
                detailStrings.push('Enter any additional information in the text box below, then attach the outcome to your ERMS request using the ‘Copy as Plain Text’ button at the bottom of this page for further action.');
            }
            else if(this.acceptance == DXAActionItem.Acceptance.criteriaNotMet){
                detailStrings.push('Consider using NOGG risk management to help determine indication for a privately funded DXA.\nIf you wish to proceed with a private request, enter any additional information in the text box below, then attach the outcome to your ERMS request using the ‘Copy as Plain Text’ button at the bottom of this page and select private funding.');
            }
        }
        else {
            if(this.acceptance == DXAActionItem.Acceptance.autoAccept){
                detailStrings.push('Attach the outcome to your ERMS request using the ‘Copy as Plain Text’ button at the bottom of this page and select private funding, so an appointment can be scheduled.');
            }
            else if(this.acceptance == DXAActionItem.Acceptance.manualReview){
                detailStrings.push('Enter any additional information in the text box below, then attach the outcome to your ERMS request using the ‘Copy as Plain Text’ button at the bottom of this page for further action.');
            }
            else if(this.acceptance == DXAActionItem.Acceptance.criteriaNotMet){
                detailStrings.push('Consider using NOGG risk management to help determine indication for a privately funded DXA.\nIf you wish to proceed with a private request, enter any additional information in the text box below, then attach the outcome to your ERMS request using the ‘Copy as Plain Text’ button at the bottom of this page and select private funding.');
            }
        }

        if (this.reasons.length > 0) {
            detailStrings.push('Outcome determined by:' + (this.reasons.length != 1 ? '\n' : ' ') + this.reasons.join(',\n'));
        }

        if(this.additionalDetail != null){
            detailStrings.push('\n' + this.additionalDetail);
        }

        return detailStrings.join('\n');
    }

    getTitle(privatelyFunded) {
        if(!privatelyFunded){
            if(this.acceptance == DXAActionItem.Acceptance.autoAccept){
                return 'This request meets criteria for a DHB-funded DXA scan';
            }
            else if(this.acceptance == DXAActionItem.Acceptance.manualReview){
                return 'This request requires specialised review to determine eligibility for a DHB-funded DXA scan';
            }
            else if(this.acceptance == DXAActionItem.Acceptance.criteriaNotMet){
                return 'This request does not meet criteria for a DHB-funded DXA scan';
            }
        }
        else {
            if(this.acceptance == DXAActionItem.Acceptance.autoAccept){
                return 'This request shows clinical indications for a DXA scan';
            }
            else if(this.acceptance == DXAActionItem.Acceptance.manualReview){
                return 'This request may show clinical indications for a DXA scan';
            }
            else if(this.acceptance == DXAActionItem.Acceptance.criteriaNotMet){
                return 'This request does not show clinical indications for a DXA scan';
            }
        }
    }

    getAcceptanceDescription(isFunded){
        if(isFunded){
            if(this.acceptance == DXAActionItem.Acceptance.autoAccept){
                return 'CLINICAL INDICATIONS';
            }
            else if(this.acceptance == DXAActionItem.Acceptance.manualReview){
                return 'MAY HAVE CLINICAL INDICATIONS';
            }
            else if(this.acceptance == DXAActionItem.Acceptance.criteriaNotMet){
                return 'NO CLINICAL INDICATIONS';
            }
        }
        else {
            return this.acceptance;
        }
    }

    static rehydrate(actionItem){
        return new DXAActionItem(actionItem.acceptance, actionItem.clicnicallyIndicated, actionItem.dhbFunded, actionItem.appointmentTimeFrame, actionItem.reasons, actionItem.additionalDetail);
    }
} 