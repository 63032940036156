import { FormContentView } from '../../FormsUI/FormsUI Components/Containers/FormContentView';
import { FormsStyles } from '../../FormsUI/FormsUI';
import { FormScale } from '../../FormsUI/FormsUI Components/Controls/FormScale';
import React from 'react';
import PropTypes from 'prop-types';
import '../../FormsUI/Styles/concussionScale.scss';

export class ScaleFormDetail extends React.Component {
    
    constructor(props) {
        super(props);

        this.radioOnChange = this.radioOnChange.bind(this);
    }

    radioOnChange(e) {
        this.props.formDetail.form.valueUpdated(this.props.formDetail.identifier, e.target.value);
        // this.props.renderErrors;
    }

    render() {
        //TODO -> Create HTML scale here?
        // add it to rightControl prop on <FormContentView /> ??
        let isMandatory = this.props.formDetail.form.isMandatory(this.props.formDetail.identifier);
        
        let currentValue = this.props.formDetail.currentValue;

        let scale = 
        <div style={{display: 'flex', alignItems: 'center'}}>
            <FormScale color='green' name={this.props.formDetail.identifier} number={0} textColor='dark-gray' value={0} event={this.radioOnChange} checked={currentValue == 0}/>
            {[...Array(3)].map((item, i) => {
                return <FormScale key={i} color='brown' name={this.props.formDetail.identifier} number={i + 1} textColor='light-gray' value={i + 1} event={this.radioOnChange} checked={currentValue == i + 1} />;
            })}
            {[...Array(4)].map((item, i) => (
                <FormScale key={i} color='orange' name={this.props.formDetail.identifier} number={i + 4} textColor='light-gray' value={i + 4} event={this.radioOnChange} checked={currentValue == i + 4} />
            ))}
            {[...Array(2)].map((item, i) => (
               
                <FormScale key={i} color='red' name={this.props.formDetail.identifier} number={i + 8} textColor='light-gray' value={i + 8} event={this.radioOnChange} checked={currentValue == i + 8} />
            ))}
            <FormScale color='red' name={this.props.formDetail.identifier} number={10} textColor='dark-gray' value={10} event={this.radioOnChange} checked={currentValue == 10} />
        </div>;
        return (
            <FormContentView identifier={this.props.formDetail.identifier} isMandatory={isMandatory} isSubcell={this.props.isSubcell} titleString={this.props.formDetail.title} detailString={this.props.formDetail.detail} subcell={[]} rightControl={scale} subCellbackgroundColor={FormsStyles.backgroundColor} showHover={true}/>
        );
    }
}

ScaleFormDetail.propTypes = {
    formDetail: PropTypes.object.isRequired,
    isSubcell: PropTypes.bool.isRequired,
    renderErrors: PropTypes.func.isRequired,
    renderSubcells: PropTypes.func.isRequired,
    errorLabels: PropTypes.array.isRequired,
    hasError: PropTypes.bool.isRequired
};