export class GallbladderActionItem {

    static TimeFrame = Object.freeze({ten: 100, three: 28});
    static Clinic = Object.freeze({HBP: 'HPB', GI: 'Upper GI', General: 'General'});

    constructor(automaticAcceptance, urgent, appointmentTimeFrame, reasons, clinics, snomedIds){
        this.automaticAcceptance = automaticAcceptance;
        this.urgent = urgent;
        this.appointmentTimeFrame = appointmentTimeFrame;
        this.reasons = reasons;
        this.clinics = clinics;
        this.snomedIds = snomedIds;
    }

    getPrioritisedClinic = function (){
        if(this.clinics.includes(GallbladderActionItem.Clinic.HBP)){
            return GallbladderActionItem.Clinic.HBP;
        }

        if(this.clinics.includes(GallbladderActionItem.Clinic.GI)){
            return GallbladderActionItem.Clinic.GI;
        }

        if(this.clinics.includes(GallbladderActionItem.Clinic.General)){
            return GallbladderActionItem.Clinic.General;
        }

        return null;
    }

    getLongDetail = function() {
        var string = '';
        if(this.reasons.length > 1){
            string += 'When there are multiple indications, patient will be triaged to the highest priority clinic.\n\n';
        }

        string += 'This specialist appointment is indicated due to:' + (this.reasons.length != 1 ? '\n' : ' ') + this.reasons.join(',\n');

        string += '\nAttach the outcome to your ERMS request using the ‘Copy as Plain Text’ button at the bottom of this page so an appointment can be scheduled.';

        return string;
    };

    getPlainTextTitle = function() {
        var string = '';

        string += this.automaticAcceptance ? 'Patient' : 'A specialist will review this request. Unless further investigation is recommended, patient';
        string += ' will be seen in an outpatients clinic within ';
        string += (this.appointmentTimeFrame == GallbladderActionItem.TimeFrame.ten ? '100' : '28');
        string += ' working days.';

        return string;
    }

    getPlainText = function() {
        var string = '';

        // if(this.reasons.length > 1){
        //     string += 'When there are multiple indications, patient will be triaged to the highest priority clinic.\n';
        // }
        string += 'This specialist appointment is indicated due to:' + (this.reasons.length != 1 ? '\n' : ' ') + this.reasons.join(', ');
        
        string += '\nAttach the outcome to your ERMS request using the ‘Copy as Plain Text’ button at the bottom of this page so an appointment can be scheduled.';

        return string;

        // var descriptionPrefix = this.automaticAcceptance ? 'An' : 'A specialist will review this request, and unless other action is recommended, an';
        // return descriptionPrefix 
        //          + ' will be seen in an outpatients clinic within ' 
        //         //  + this.getPrioritisedClinic() 
        //         //  + ' clinic will be scheduled within ' 
        //          + (this.appointmentTimeFrame == GallbladderActionItem.TimeFrame.ten ? '100' : '28') 
        //          + ' working days of the submission of this request\n\n'
        //          + 'This specialist appointment is indicated due to:' + (this.reasons.length != 1 ? '\n' : ' ') + this.reasons.join(', ');
    }

    static merge(actionItems){
        var isUrgent = false;
        var isAutomatic = true;
        var timeFrame = GallbladderActionItem.TimeFrame.ten;
        var reasons = [];
        var snomedIds = [];
        var clinics = [];

        for(let action of actionItems){
            if(action.urgent){
                isUrgent = true;
            }

            if(!action.automaticAcceptance){
                isAutomatic = false;
            }

            for(let clinic of action.clinics){
                if(!clinics.includes(action.clinic)){
                    clinics.push(clinic);
                }
            }

            if(action.appointmentTimeFrame == GallbladderActionItem.TimeFrame.three){
                timeFrame = GallbladderActionItem.TimeFrame.three;
            }
            for(let reason of action.reasons){
                if (!reasons.includes(reason)) {
                    reasons.push(reason);
                }
            } 
            snomedIds = snomedIds.concat(action.snomedIds);
        }
        
        return new GallbladderActionItem(isAutomatic, isUrgent, timeFrame, reasons, clinics, snomedIds);
    }

    static rehydrate(actionItem){
        return new GallbladderActionItem(actionItem.automaticAcceptance, actionItem.urgent, actionItem.appointmentTimeFrame, actionItem.reasons, actionItem.clinics, actionItem.snomedIds);
    }
} 