import { FormWithRedux } from '../../FormWithRedux';
import { GallbladderAbdominalExamForm } from './Gallbladder-AbdominalExamForm';
import { FormValidation } from '../../../waferJS/Form/Validation/FormValidation';
import { FormValidationError } from '../../../waferJS/Form/Validation/FormValidationError';


export class GallbladderFurtherInformationForm extends FormWithRedux {

    constructor(formLoader, parent, actionUpdater, dependentValuesUpdater, calculationsUpdater) {
        super('furtherInformation', formLoader, parent, actionUpdater, dependentValuesUpdater, calculationsUpdater);
    }

    generateSubform(identifier) {
        switch (identifier) {
            case 'abdominalExam':
                return new GallbladderAbdominalExamForm(this.formLoader);
        }
    }

    shouldDisplayFormDetailForIdentifier(identifier) {
        super.shouldDisplayFormDetailForIdentifier(identifier);

        if(identifier == 'unexpectedWeightLoss' || identifier == 'changeInBowelHabit' || identifier == 'abdominalDistension' || identifier == 'melena'){
            let age = this.getValue('patientAge');
            return age != null && age >= 40;
        } else if (identifier == 'abdominalExam'){
            let hasHadExam = this.getValue('hasHadAbdominalExam');
            return hasHadExam != false || (this.getValue('abdominalExamScars') != null || this.getValue('abdominalExamAbnormalities') != null || this.getValue('abdominalExamScarsSpecifiy') != null || this.getValue('abdominalExamAbnormalitiesSpecifiy') != null);
        } else if(identifier == 'polypsSize' || identifier == 'polypsSessileOrSolitary'){
            return this.getValue('polyps') == true || (this.getValue('polypsSize') != null || this.getValue('polypsSessileOrSolitary') != null);
        } else if(identifier == 'pastSurgicalHistoryFormSectioNoToAll' || identifier == 'noToAllUltrasound' || identifier == 'noToAllCancerSymptoms') {
            if(identifier == 'noToAllCancerSymptoms' && (this.getValue('patientAge') == null || this.getValue('patientAge') < 40)){
                return false;
            }
            return this.unselectedExplicitFormDetailsCount(identifier) > 1;
        } else if(identifier == 'pastSurgicalHistoryOtherSpecify'){
            return this.getValue('pastSurgicalHistoryOtherSpecify') != null || this.getValue('pastSurgicalHistoryOther') == true;
        }
        return true;
    }

    isEnabled(identifier) {
        if (identifier != null) {
            return true;
        } 
        return false;
    }

    isMandatory(identifier) {
        if(identifier == 'polypsSize' || identifier == 'polypsSessileOrSolitary'){
            return this.getValue('polyps');
        } else if(identifier == 'unexpectedWeightLoss' || identifier == 'changeInBowelHabit' || identifier == 'abdominalDistension' || identifier == 'melena'){
            let age = this.getValue('patientAge');
            return age != null && age >= 40;
        } else if(identifier == 'priorERCP' || identifier == 'primarySclerosingCholangitis' || identifier == 'historyOfInflammatoryBowelDisease' 
                    || identifier == 'keyClinicalFeaturesSymptomsSignsJaundice' || identifier == 'stonesInGallbladder' || identifier == 'commonBileDuctStone' || identifier == 'dilatedDuctOnUSS' 
                    || identifier == 'adenonmyomatosis' || identifier == 'gallbladderMass' || identifier == 'bloodsObstructiveLFT' || identifier == 'polyps'){
            return true;
        } else if(identifier == 'pastSurgicalHistoryOtherSpecify'){
            return this.getValue('pastSurgicalHistoryOther') == true;
        }

        
        return false;
    }

    getValueType(identifier) {
        if(identifier == 'cholecystectomyOrBiliarySurgeryNameOfSurgeonIfKnown' || identifier == 'pastSurgicalHistoryOtherSpecify'){
            return 'string';
        } else if(identifier == 'polypsSize'){
            return 'double';
        } 

        return 'boolean';
    }

    submissionValidation(identifier) {
        if(identifier == 'polyps'){
            let self = this;
            let criteriaConflict = new FormValidation('PolypsConflict', function () {
                if((self.getValue('polypsSize') != null || self.getValue('polypsSessileOrSolitary') != null) && self.getValue('polyps')  == false){
                    return [new FormValidationError(FormValidationError.FormErrorType.error, 'Modify size and solitary to match selection')];
                }
                return [];
            });
                
            return [criteriaConflict];
        }
        return [];
    }

    detailString(isSubform, isPlainText) {
        let formDetails = this.getFormDetails();
        let values = formDetails.map((value) => {
            this.getSummaryValue(isSubform, value);
        });

        if(values.filter(n => n).length == 0) {
            return isSubform || isPlainText ? '' : this.detail;
        }

        if(isPlainText == true){
            return values.filter(n => n).join(', ');
        }

        return values.filter(n => n).join(',\n');
    }

    getSummaryValue(isSubform, value){
        if(value.identifier == 'keyClinicalFeaturesSymptomsOtherSpecifiy'){
            return null;
        } else if(value.identifier == 'keyClinicalFeaturesSymptomsOther' && value.valueDescription() != null){
            let otherString = this.getValue('keyClinicalFeaturesSymptomsOtherSpecifiy');
            return value.valueDescription() + (otherString != null && otherString != undefined  ? ': ' + otherString : '');
        } else if(value.identifier == 'abdominalExam'){
            let subform = this.subform(value.identifier);
            return subform.detailString(isSubform);
        } else if (value.identifier == 'polyps' && value.valueDescription() != null){
            let size = this.getValue('polypsSize');
            let solitary = this.getValue('polypsSessileOrSolitary');
            var strings = [];

            if(size != null){
                strings.push('size: ' + size + 'mm');
            }

            if(solitary == true){
                strings.push('Solitary');
            }
            else if (solitary == false){
                strings.push('Multiple');
            }

            if(strings.length > 0){
                return value.valueDescription() + '(' + strings.join(', ') + ')';
            }

            return value.valueDescription();
        } else if (value.identifier == 'polypsSize'){
            return null;
        } else if (value.identifier == 'polypsSessileOrSolitary'){
            return null;
        } else if(value.identifier == 'hasHadAbdominalExam'){
            let entryValue = this.getValue('hasHadAbdominalExam');
            return entryValue == null ? null : 'Abdominal examination notes: ' + entryValue; 
        } else if(value.identifier == 'pastSurgicalHistoryOther' && this.getValue(value.identifier) == true){
            return 'Other Medical and surgical history (Specify: ' + this.getValue('pastSurgicalHistoryOtherSpecify') + ')';
        }

        if(value.valueDescription() == false){
            return false;
        }

        return value.valueDescription() == null ? null : (isSubform ? '- ' : '') + value.valueDescription();
    }
}