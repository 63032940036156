import { FormWithRedux } from '../../FormWithRedux';
import React from 'react';

export class ACCBISTPictureCardRecollectionTestForm extends FormWithRedux {

    constructor(formLoader, parent, actionUpdater, dependentValuesUpdater, calculationsUpdater) {
        super('pictureCardTestRecollectionSubform', formLoader, parent, actionUpdater, dependentValuesUpdater, calculationsUpdater);
    }

    shouldDisplayFormDetailForIdentifier(identifier) {
        super.shouldDisplayFormDetailForIdentifier(identifier);
        return true;
    }

    isEnabled() {
        return true;
    }

    isMandatory(identifier) {
        return true;
    }

    getValueType(identifier) {
        return 'boolean';
    }

    submissionValidation() {
        return [];
    }

    editingValidation(identifier) {
        return [];
    }

    detailForIdentifier(prefix, value) {
        if (value.identifier === 'pictureCardTestRecollection' && this.getValue('pictureCardTestRecollection') == true) {
            return prefix + 'Showed patient the image set';
        }

        return super.detailForIdentifier(prefix, value);
    }

    htmlForIdentifier(prefix, value) {
        if (value.identifier === 'pictureCardTestRecollection' && this.getValue('pictureCardTestRecollection') == true) {
            return (<div key={value.identifier} >
                <label className='list-detail'>Showed patient the image set</label>
            </div>);
        }
        else {
            return super.htmlForIdentifier(prefix, value);
        }
    }
}