import { FormContentView } from '../../FormsUI/FormsUI Components/Containers/FormContentView';
import { FormsStyles } from '../../FormsUI/FormsUI';
import React from 'react';
import PropTypes from 'prop-types';


export class CalloutFormDetail extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        let leadingBadge = <div className='callout-badge'></div>;

        return (
            <FormContentView identifier={this.props.formDetail.identifier} isSubcell={this.props.isSubcell} titleString={this.props.formDetail.title} detailString={this.props.formDetail.detail} subcell={[]} subCellbackgroundColor={FormsStyles.backgroundColor} backgroundColor={FormsStyles.backgroundColor} showHover={true} topLeftControl={leadingBadge}/>
        );
    }
}

CalloutFormDetail.propTypes = {
    formDetail: PropTypes.object.isRequired,
    isSubcell: PropTypes.bool.isRequired,
    renderErrors: PropTypes.func.isRequired,
    renderSubcells: PropTypes.func.isRequired,
    errorLabels: PropTypes.array.isRequired,
    hasError: PropTypes.bool.isRequired
};