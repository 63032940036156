import {DateHelper} from '../waferJS/Helpers/DateHelper';

export class MetadataHelper {
    
    static Keys = Object.freeze( {
        UniqueId: 'uniqueId', 
        VersionId: 'versionId', 
        Title:'title',
        PatientId: 'patientId',
        CreationTime: 'creationTime',
        MimeType: 'mimeType',
        ServiceStartTime: 'serviceStartTime',
        ServiceEndTime: 'serviceEndTime',
        
        Facility: 'facility',
        Organisation: 'organisation',
        
        Author: 'author',
        AssigningAuthority: 'assigningAuthority',
        AuthorId: 'authorId',
        Email: 'email',
        FullName: 'fullName',
        FamilyName: 'familyName',
        GivenName: 'givenName',
        
        ClassCode: 'classCode',
        PracticeSettingCode: 'practiceSettingCode',
        TypeCode: 'typeCode',
        
        CodingSystem: 'codingSystem',
        Description: 'description',
        Value:'value'
    });
    
    static Values = Object.freeze({
        
        // Title
        Title: 'ED Cardiac Chest Pain Pathway',
        
        // MimeType
        Text_Html: 'text/html',
        
        // Facility
        ChristchurchHospital: 'F04028',
        
        // Organisation
        CanterburyDHB: 'G00005',
        
        // AssigningAuthority
        //        static let HPI_CPN = "2.16.840.1.113883.2.18.3.1"
        MedicalCouncil: '2.16.840.1.113883.2.18.41',
        NursingCouncil: '2.16.840.1.113883.2.18.42',
        
        // Role
        Physician: 'PHY',
    });

    static TypeCode = Object.freeze({
        CodingSystem: '2.16.840.1.113883.2.18.69.10.2',
        Description: 'Assessments',
        Value: 'ASS'
    });

    static PracticeSettingCode = Object.freeze({
        CodingSystem: '2.16.840.1.113883.2.18.54.4',
        Description: 'Emergency Medicine',
        Value: 'M05'
    });

    static ClassCode = Object.freeze({
        CodingSystem: '2.16.840.1.113883.2.18.69.10.1',
        Description: 'Clinical Documents',
        Value: 'CD'
    });
    
    static assigningAuthority(string) {
        switch (string) {
            case 'medicalCouncil':
                return MetadataHelper.Values.MedicalCouncil;
            case 'nursingCouncil':
                return MetadataHelper.Values.NursingCouncil;
            default:
                return null;
        }
    }
    
    static constructMetadata(pathway, patient, user) {
        
        var metadataDictionary = {};
        metadataDictionary[MetadataHelper.Keys.UniqueId] = numericFlatten(pathway.uuid);
        metadataDictionary[MetadataHelper.Keys.VersionId] = pathway.version;
        
        metadataDictionary[MetadataHelper.Keys.Title] = MetadataHelper.Values.Title;
        
        if (patient != null && patient != undefined) {
            metadataDictionary[MetadataHelper.Keys.PatientId] = patient.nhiNumber; //patient.nhiNumber.description;
        }
        
        metadataDictionary[MetadataHelper.Keys.CreationTime] = DateHelper.h17(new Date());
        metadataDictionary[MetadataHelper.Keys.MimeType] = MetadataHelper.Values.Text_Html;
        
        metadataDictionary[MetadataHelper.Keys.ServiceStartTime] = DateHelper.h17(pathway.createdDate);
        metadataDictionary[MetadataHelper.Keys.ServiceEndTime] = DateHelper.h17(pathway.lastModifiedDate);
        
        metadataDictionary[MetadataHelper.Keys.Facility] = MetadataHelper.Values.ChristchurchHospital;
        metadataDictionary[MetadataHelper.Keys.Organisation] = MetadataHelper.Values.CanterburyDHB;
        
        if (user != null && user != undefined) {
            var authorDictionary = {};
            
            // authorDictionary[MetadataHelper.Keys.AssigningAuthority] = MetadataHelper.assigningAuthority(user.identityType);
            
            if (user.email != null) {
                authorDictionary[MetadataHelper.Keys.AuthorId] = user.email;
            } else if (user.sub != null) {
                authorDictionary[MetadataHelper.Keys.AuthorId] = user.sub;
            }

            if (user.email != null) {
                authorDictionary[MetadataHelper.Keys.Email] = user.email;
            }
            
            if (user.name != null) {
                authorDictionary[MetadataHelper.Keys.FullName] = user.name;
            }

            if (user.family_name != null) {
                authorDictionary[MetadataHelper.Keys.FamilyName] = user.family_name;
            }

            if (user.given_name != null) {
                authorDictionary[MetadataHelper.Keys.GivenName] = user.given_name;
            }
            
            metadataDictionary[MetadataHelper.Keys.Author] = authorDictionary;
        }
        
        var classCodeDictionary = {};
        classCodeDictionary[MetadataHelper.Keys.CodingSystem] = MetadataHelper.ClassCode.CodingSystem;
        classCodeDictionary[MetadataHelper.Keys.Description] = MetadataHelper.ClassCode.Description;
        classCodeDictionary[MetadataHelper.Keys.Value] = MetadataHelper.ClassCode.Value;
        
        metadataDictionary[MetadataHelper.Keys.ClassCode] = classCodeDictionary;
        
        var practiceSettingCodeDictionary = {};
        practiceSettingCodeDictionary[MetadataHelper.Keys.CodingSystem] = MetadataHelper.PracticeSettingCode.CodingSystem;
        practiceSettingCodeDictionary[MetadataHelper.Keys.Description] = MetadataHelper.PracticeSettingCode.Description;
        practiceSettingCodeDictionary[MetadataHelper.Keys.Value] = MetadataHelper.PracticeSettingCode.Value;
        
        metadataDictionary[MetadataHelper.Keys.PracticeSettingCode] = practiceSettingCodeDictionary;
        
        var typeCodeDictionary = {};
        typeCodeDictionary[MetadataHelper.Keys.CodingSystem] = MetadataHelper.TypeCode.CodingSystem;
        typeCodeDictionary[MetadataHelper.Keys.Description] = MetadataHelper.TypeCode.Description;
        typeCodeDictionary[MetadataHelper.Keys.Value] = MetadataHelper.TypeCode.Value;
        
        metadataDictionary[MetadataHelper.Keys.TypeCode] = typeCodeDictionary;
        
        return metadataDictionary;
    }
}

function numericFlatten(uuid) {
    let validChars = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
    let cleanedInput = uuid.replace(/\W/g, '').toUpperCase();
    let numericValues = [];

    for(let char of cleanedInput){
        let index = validChars.indexOf(char);
        numericValues.push(index);
    }
    
    let numericString = '';
    numericValues.map((value) => {
        numericString += (('0' + value).slice(-2));
    });

    return numericString;
}