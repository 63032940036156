export class OsteoporosisPathwayReference {
    static data = {
        'title': 'DXA Request',
        'detail': 'Placeholder Detail',
        'itemType': 'template',
        'template.version': 1.0,
        'template.versionUuid': 'osteoporosisInitialAssessment',
        'template.createText': 'Use This Pathway',
        'template.introID': 'introForm',
        'template.contentTabIDs': ['mainForm', 'resultsForm'],
        'template.defaultContentTabID': 'mainForm',
        'template.content': {
            'introForm': {
                'title': 'DXA Request',
                'detail': 'This pathway is designed for patients at risk of osteoporosis and where first or follow-up Bone Density Scan (DXA) is being considered.\n\nIf the patient is intolerant or unresponsive to treatment, or has complex medical disorders, instead of DXA, consider request to Bone clinic.\n\nSee Community HealthPathways > #@^Osteoporosis^https://canterbury.communityhealthpathways.org/24517.htm#@ and #@^Bone Density Scan (DXA)^https://canterbury.communityhealthpathways.org/15621.htm#@ for more information.',
                'itemType': 'subform',
                'subform.sectionIDs': [
                    
                ]
            },
            // 'additionalInformation': {
            //     'title': 'Aditional Information',
            //     'itemType': 'section',
            //     'section.childIDs': [
            //         'PathwayInformationForm'
            //     ]
            // },
            // 'PathwayInformationForm': {
            //     'title': 'Additional Information',
            //     'itemType': 'subform',
            //     'subform.sectionIDs': [
            //         'pathwayInformationSection'
            //     ]
            // },
            // 'pathwayInformationSection': {
            //     'title': '',
            //     'itemType': 'section',
            //     'section.childIDs': [
            //         'pathwayObjectivesInfo',
            //         'calculatedInsightsInfo'
            //     ]
            // },
            // 'pathwayObjectivesInfo': {
            //     'title': 'Pathway Objectives',
            //     'detail': 'This pathway is designed to simultaneously:\nImprove your clinical decision-making\nCreate an accurate clinical record\nCollect high quality research data\nSupport referrals for DXA scan and Bone clinic when clinically appropriate',
            //     'itemType': 'control',
            //     'control.type': 'plain'
            // },
            // 'calculatedInsightsInfo': {
            //     'title': 'Calculated Insights',
            //     'detail': 'Major Osteoporotic Risk Factors, Recommended medical treatment, DXA scan referral, Bone Clinic referral',
            //     'itemType': 'control',
            //     'control.type': 'plain'
            // },
            'mainForm': {
                'title': 'Referral',
                'itemType': 'subform',
                'subform.sectionIDs': [
                    'patientDetailsSection',
                    'requestCriteriaSection',
                    'furtherInformationSection'
                ]
            },
            'patientDetailsSection': {
                'title': 'Patient details',
                'itemType': 'section',
                'section.childIDs': [
                    'patientDetails',
                ]
            },
            'patientDetails': {
                'title': 'Patient details',
                'itemType': 'subform',
                'subform.sectionIDs': [
                    'detailsSection'
                ]
            },
            'detailsSection': {
                'section.childIDs': [
                    'patientAge',
                    'patientWeight',
                    'infectionRiskToStaff',
                    'privateFundingAvailable'
                ]
            },
            'patientAge': {
                'title': 'Age',
                'itemType': 'control',
                'control.type': 'textEntry',
                'textEntry.type': 'decimalNumber',
                'textEntry.unitText': 'years',
                'subcellIDs':[
                    'patientDateOfBirth'
                ]
            },
            'patientDateOfBirth':{
                'title': 'Date of birth',
                'detail': 'Date of birth can be used to calculate age.',
                'itemType': 'control',
                'control.type': 'picker',
                'picker.type': 'dateOfBirth',
            },
            'patientWeight': {
                'title': 'Weight',
                'detail': 'Patient weight determines the machines that can be used',
                'itemType': 'control',
                'control.type': 'textEntry',
                'textEntry.type': 'decimalNumber',
                'textEntry.unitText': 'kg',
                'subcellIDs': [
                    'dxaNotPossibleDueToWeight'
                ]
            },
            'dxaNotPossibleDueToWeight': {
                'title': 'DXA scan is not possible on this patient because their weight exceeds the capability of the machines',
                'itemType': 'control',
                'control.type': 'plain',
            },
            'infectionRiskToStaff':{
                'title': 'Is there any disability or infection risk to staff?',
                'itemType': 'control',
                'control.type': 'selection',
                'selection.style': 'explicit',
                'subcellIDs': [
                    'infectionRiskDetail'
                ]
            },
            'infectionRiskDetail':{
                'title': 'Specify',
                'itemType': 'control',
                'control.type': 'textEntry',
                'textEntry.type': 'wrappingPlainText'
            },
            'genderMaleOption': {
                'title': 'Male',
                'itemType': 'option'
            },
            'genderFemaleOption': {
                'title': 'Female',
                'itemType': 'option'
            },
            'genderOtherOption': {
                'title': 'Other',
                'itemType': 'option'
            },
            'privateFundingAvailable': {
                'title': 'Does the patient wish to go privately for this request?',
                'itemType': 'control',
                'control.type': 'selection',
                'selection.style': 'explicit',
                'subcellIDs': [
                    'privatelyFundedDetail'
                ]
            },
            'privatelyFundedDetail':{
                'title': 'Continue with this request to provide the information needed for reporting, and choose ERMS private or ACC options.',
                'itemType': 'control',
                'control.type': 'plain',
            },
            'requestCriteriaSection': {
                'title': 'Request criteria',
                'itemType': 'section',
                'section.childIDs': [
                    'requestCriteria',
                ]
            },
            'requestCriteria':{
                'title': 'Request criteria',
                'itemType': 'subform',
                'subform.sectionIDs': [
                    'reasonForRequestSection',
                    'followupDXAInformationSection',
                    'osteoporosisTreatmentSection',
                    'highRiskCriteriaSection',
                    'fraxRiskAssessmentSection'
                ]
            },
            'reasonForRequestSection':{
                'title': 'Reason for request',
                'itemType': 'section',
                'section.childIDs': [
                    'isThisFollowUpRequest',
                ]
            },
            'isThisFollowUpRequest':{
                'title': 'Is this request for a first or follow-up DXA?',
                'itemType': 'control',
                'control.type': 'selection',
                'selection.style': 'simple',
                'selection.options': [
                    'firstOption',
                    'followupOption'
                ]
            },
            'firstOption': {
                'title': 'First',
                'itemType': 'option'
            },
            'followupOption': {
                'title': 'Follow-up',
                'itemType': 'option'
            },
            'followupDXAInformationSection':{
                'title': 'Follow-up DXA information',
                'itemType': 'section',
                'section.childIDs': [
                    'hasPreviousDXAResults',
                ]
            },
            'hasPreviousDXAResults':{
                'title': 'Do you have previous DXA Scan results?',
                'detail': 'Attach all previous DXA scan results to your ERMS request.',
                'itemType': 'control',
                'control.type': 'selection',
                'selection.style': 'explicit',
                'subcellIDs': [
                    'previousDXADate',
                    'previousDXATScore'
                ]
            },
            'previousDXADate': {
                'title': 'Date',
                'itemType': 'control',
                'control.type': 'picker',
                'picker.type': 'date',
                'date.format': 'MM/YYYY'
            },
            'previousDXATScore': {
                'title': 'T-score',
                'itemType': 'control',
                'control.type': 'textEntry',
                'textEntry.type': 'decimalNumber',
                'textEntry.unitText': '',
                'subcellIDs': [
                    'tScoreBoneDensityBadge'
                ]
            },
            'tScoreBoneDensityBadge':{
                'title': 'Bone density state',
                'itemType': 'control',
                'control.type': 'badge'
            },
            'osteoporosisTreatmentSection':{
                'title': 'Osteoporosis treatment',
                'itemType': 'section',
                'section.childIDs': [
                    'isPatientOnOsteoporosisTreatment',
                    'osteoporosisTreatmentDetails'
                ]
            },
            'isPatientOnOsteoporosisTreatment':{
                'title': 'Is patient on osteoporosis treatment?',
                'detail': 'i.e. Bisphosponates, hormone therapy',
                'itemType': 'control',
                'control.type': 'selection',
                'selection.style': 'explicit',
                'subcellIDs':[
                    'onOralOrIVTreatment',
                    'consideringTreatmentBreak',
                    'isPatientOnTreatmentBreak'
                ]
            },
            'onOralOrIVTreatment':{
                'title': 'Is patient on oral or IV treatment?',
                'itemType': 'control',
                'control.type': 'selection',
                'selection.style': 'simple',
                'selection.options': [
                    'oralOption',
                    'ivOption'
                ]
            },
            'consideringTreatmentBreak':{
                'title': 'Is patient considering a treatment break?',
                'itemType': 'control',
                'control.type': 'selection',
                'selection.style': 'explicit',
            },
            'isPatientOnTreatmentBreak':{
                'title': 'Is patient on a treatment break?',
                'itemType': 'control',
                'control.type': 'selection',
                'selection.style': 'explicit',
            },
            'oralOption': {
                'title': 'Oral',
                'itemType': 'option'
            },
            'ivOption': {
                'title': 'IV',
                'itemType': 'option'
            },
            'osteoporosisTreatmentDetails':{
                'title': 'Osteoporosis treatment details',
                'detail': 'Name of medications, years of treatment, approximate dates started and stopped etc.',
                'itemType': 'control',
                'control.type': 'textEntry',
                'textEntry.type': 'wrappingPlainText'
            },
            'highRiskCriteriaSection':{
                'title': 'High-risk criteria',
                'itemType': 'section',
                'section.childIDs': [
                    'hasPatientHadFragilityFracture',
                    'highRiskGlucocorticoidUse',
                    'multipleFalls'
                ]
            },
            'hasPatientHadFragilityFracture':{
                'title': 'Has the patient had a fragility fracture?',
                'detail': 'In vertebrae, long bone (hip, humerus, wrist), and rib. Does not include ankles. With minimal trauma, i.e. a fall from standing height or less.',
                'itemType': 'control',
                'control.type': 'selection',
                'selection.style': 'explicit',
                'subcellIDs':[
                    // 'longBoneOrHipFracture',
                    'longBoneHipFractureDetails',
                    'fragilityFractureSincePreviousScan'
                ]
            },
            // 'longBoneOrHipFracture':{
            //     'title': 'Long bone or hip fracture?',
            //     'itemType': 'control',
            //     'control.type': 'selection',
            //     'selection.style': 'explicit'
            // },
            'longBoneHipFractureDetails': {
                'title': 'Further detail',
                'detail': 'Dates, details, etc.',
                'itemType': 'control',
                'control.type': 'textEntry',
                'textEntry.type': 'wrappingPlainText'
            },
            'fragilityFractureSincePreviousScan': {
                'title': 'Has a new fragility fracture occurred since previous DXA scan?',
                'itemType': 'control',
                'control.type': 'selection',
                'selection.style': 'explicit',
            },
            'highRiskGlucocorticoidUse':{
                'title': 'High-risk glucocorticoid use',
                'detail': 'Equivalent to prednisone 5mg per day for more than 3 months in a 12 month period or since last DXA',
                'itemType': 'control',
                'control.type': 'selection',
                'selection.style': 'explicit',
            },
            'multipleFalls':{
                'title': 'Multiple falls',
                'detail': 'A high-risk criteria when patient age >= 70 years',
                'itemType': 'control',
                'control.type': 'selection',
                'selection.style': 'explicit',
            },
            'fraxRiskAssessmentSection':{
                'title': 'FRAX assessment',
                'itemType': 'section',
                'section.childIDs': [
                    'fraxAssessmentWebsite',
                    'hipFractureRisk',
                ]
            },
            'fraxAssessmentWebsite': {
                'title': 'FRAX Assessment Website',
                'itemType': 'control',
                'control.type': 'weblink',
                'weblink': 'https://www.sheffield.ac.uk/FRAX/tool.aspx?country=23'
            },
            'hipFractureRisk': {
                'title': 'Hip fracture risk',
                'itemType': 'control',
                'control.type': 'textEntry',
                'textEntry.type': 'decimalNumber',
                'textEntry.unitText': '%',
                'subcellIDs': ['over70Hint']
            },
            'over70Hint':{
                'title': 'Where patient age >= 70 years, hip fracture risk can be assumed to be at least 3%.',
                'itemType': 'control',
                'control.type': 'plain'
            },
            'furtherInformationSection': {
                'title': 'Further information',
                'itemType': 'section',
                'section.childIDs': [
                    'furtherInformation',
                ]
            },
            'furtherInformation':{
                'title': 'Further information',
                'detail': 'These common secondary causes can contribute towards clinical indication of a DXA scan, and will be taken into account when your request is considered for funding. The information is also needed so the test report can provide clinical guidance.',
                'itemType': 'subform',
                'subform.sectionIDs': [
                    'medicalProblemsSection',
                    'socialAndFamilySection',
                    'medicationsSection',
                ]
            },
            'medicalProblemsSection': {
                'title': 'Medical problems',
                'itemType': 'section',
                'section.childIDs': [
                    'medicalHistoryMalabsorption',
                    'hyperthyroidism',
                    'hyperparathyroidism',
                    'hypogonadism',
                    'inflammatoryArthritis',
                    'amenorrhea',
                    'eatingDisorders',
                    'medicalHistoryCOPD',
                    'chronicLiverDisease',
                    'chronicKidneyDisease',
                    'lowBMI',
                    'noToAllMedicalProblems'
                ]
            },
            'noToAllMedicalProblems':{
                'title': 'medical problems',
                'itemType': 'control',
                'control.type': 'noToAll'
            },
            'medicalHistoryCOPD': {
                'title': 'COPD',
                'itemType': 'control',
                'control.type': 'selection',
                'selection.style': 'explicit'
            },
            'medicalHistoryMalabsorption': {
                'title': 'Malabsorption',
                'detail': 'eg. Coeliac disease',
                'itemType': 'control',
                'control.type': 'selection',
                'selection.style': 'explicit',
                'subcellIDs': [
                    'malabSorptionNewSinceLast'
                ]
            },
            'malabSorptionNewSinceLast':{
                'title': 'Is this indication new since the patient\'s most recent DXA scan?',
                'itemType': 'control',
                'control.type': 'selection',
                'selection.style': 'explicit'
            },
            'hyperthyroidism': {
                'title': 'Hyperthyroidism',
                'itemType': 'control',
                'control.type': 'selection',
                'selection.style': 'explicit',
                'subcellIDs': [
                    'hyperthyroidismNewSinceLast'
                ]
            },
            'hyperthyroidismNewSinceLast':{
                'title': 'Is this indication new since the patient\'s most recent DXA scan?',
                'itemType': 'control',
                'control.type': 'selection',
                'selection.style': 'explicit'
            },
            'hyperparathyroidism': {
                'title': 'Hyperparathyroidism',
                'itemType': 'control',
                'control.type': 'selection',
                'selection.style': 'explicit',
                'subcellIDs': [
                    'hyperparathyroidismNewSinceLast'
                ]
            },
            'hyperparathyroidismNewSinceLast':{
                'title': 'Is this indication new since the patient\'s most recent DXA scan?',
                'itemType': 'control',
                'control.type': 'selection',
                'selection.style': 'explicit'
            },
            'hypogonadism': {
                'title': 'Hypogonadism',
                'detail': 'eg. Premature menopause, anorexia, testosterone deficiency, prostate cancer survivors',
                'itemType': 'control',
                'control.type': 'selection',
                'selection.style': 'explicit',
                'subcellIDs': [
                    'hypogonadismNewSinceLast'
                ]
            },
            'hypogonadismNewSinceLast':{
                'title': 'Is this indication new since the patient\'s most recent DXA scan?',
                'itemType': 'control',
                'control.type': 'selection',
                'selection.style': 'explicit'
            },
            'inflammatoryArthritis': {
                'title': 'Inflammatory arthritis',
                'detail': 'eg. Rheumatoid arthritis',
                'itemType': 'control',
                'control.type': 'selection',
                'selection.style': 'explicit',
                'subcellIDs': [
                    'inflammatoryArthritisNewSinceLast'
                ]
            },
            'inflammatoryArthritisNewSinceLast':{
                'title': 'Is this indication new since the patient\'s most recent DXA scan?',
                'itemType': 'control',
                'control.type': 'selection',
                'selection.style': 'explicit'
            },
            'amenorrhea': {
                'title': 'Amenorrhea',
                'detail': '>6 months age <45yrs',
                'itemType': 'control',
                'control.type': 'selection',
                'selection.style': 'explicit',
                'subcellIDs': [
                    'amenorrheaNewSinceLast'
                ]
            },
            'amenorrheaNewSinceLast':{
                'title': 'Is this indication new since the patient\'s most recent DXA scan?',
                'itemType': 'control',
                'control.type': 'selection',
                'selection.style': 'explicit'
            },
            'eatingDisorders': {
                'title': 'Eating disorders',
                'itemType': 'control',
                'control.type': 'selection',
                'selection.style': 'explicit',
                'subcellIDs':[
                    'eatingDisordersNewSinceLast'
                ]
            },
            'eatingDisordersNewSinceLast':{
                'title': 'Is this indication new since the patient\'s most recent DXA scan?',
                'itemType': 'control',
                'control.type': 'selection',
                'selection.style': 'explicit'
            },
            'chronicLiverDisease': {
                'title': 'Chronic liver disease',
                'itemType': 'control',
                'control.type': 'selection',
                'selection.style': 'explicit'
            },
            'chronicKidneyDisease': {
                'title': 'Chronic kidney disease',
                'itemType': 'control',
                'control.type': 'selection',
                'selection.style': 'explicit'
            },
            'lowBMI': {
                'title': 'Low BMI',
                'itemType': 'control',
                'control.type': 'selection',
                'selection.style': 'explicit'
            },
            'socialAndFamilySection': {
                'title': 'Social and family',
                'itemType': 'section',
                'section.childIDs': [
                    'smoking',
                    'highAlchoholIntake',
                    'familyHistoryOfPaternalHipFracture',
                    'noToAllSocialAndFamily'
                ]
            },
            'noToAllSocialAndFamily': {
                'itemType': 'control',
                'control.type': 'noToAll'
            },
            'smoking': {
                'title': 'Smoking',
                'itemType': 'control',
                'control.type': 'selection',
                'selection.style': 'explicit'
            },
            'highAlchoholIntake': {
                'title': 'High alcohol intake',
                'detail': '>3 standard drinks per day',
                'itemType': 'control',
                'control.type': 'selection',
                'selection.style': 'explicit'
            },
            'familyHistoryOfPaternalHipFracture': {
                'title': 'Family history of parental hip fracture',
                'itemType': 'control',
                'control.type': 'selection',
                'selection.style': 'explicit'
            },
            'medicationsSection':{
                'title': 'Medications',
                'itemType': 'section',
                'section.childIDs': [
                    'inhibitors',
                    'aromataseInhibitors',
                    'glucocorticoids',
                    'ssri',
                    'lithium',
                    'suppressiveDosesOfThyroxine',
                    'methotrexate',
                    'prolongedUseOfPPI',
                    'cyclosporin',
                    'goserelin',
                    'antiAndrogens',
                    'noToAllFurtherInformationMedications'
                ]
            },
            'noToAllFurtherInformationMedications':{
                'title': 'medications',
                'itemType': 'control',
                'control.type': 'noToAll'
            },
            'inhibitors':{
                'title': 'Phenobarbital, phenytoin, carbamazepine, primidone, or valproate',
                'itemType': 'control',
                'control.type': 'selection',
                'selection.style': 'explicit',
                'subcellIDs': [
                    'inhibitorsNewSinceLast'
                ]
            },
            'inhibitorsNewSinceLast':{
                'title': 'Is this indication new since the patient\'s most recent DXA scan?',
                'itemType': 'control',
                'control.type': 'selection',
                'selection.style': 'explicit'
            },
            'aromataseInhibitors':{
                'title': 'Aromatase inhibitors',
                'itemType': 'control',
                'control.type': 'selection',
                'selection.style': 'explicit',
                'subcellIDs': [
                    'aromataseInhibitorsNewSinceLast'
                ]
            },
            'aromataseInhibitorsNewSinceLast':{
                'title': 'Is this indication new since the patient\'s most recent DXA scan?',
                'itemType': 'control',
                'control.type': 'selection',
                'selection.style': 'explicit'
            },
            'glucocorticoids': {
                'title': 'Glucocorticoids',
                'itemType': 'control',
                'control.type': 'selection',
                'selection.style': 'explicit'
            },
            'ssri': {
                'title': 'SSRI',
                'itemType': 'control',
                'control.type': 'selection',
                'selection.style': 'explicit'
            },
            'lithium':{
                'title': 'Lithium',
                'itemType': 'control',
                'control.type': 'selection',
                'selection.style': 'explicit'
            },
            'suppressiveDosesOfThyroxine': {
                'title': 'Suppressive doses of thyroxine',
                'itemType': 'control',
                'control.type': 'selection',
                'selection.style': 'explicit'
            },
            'methotrexate': {
                'title': 'Methotrexate',
                'itemType': 'control',
                'control.type': 'selection',
                'selection.style': 'explicit'
            },
            'prolongedUseOfPPI': {
                'title': 'Prolonged use of proton pump inhibitor (PPI)',
                'itemType': 'control',
                'control.type': 'selection',
                'selection.style': 'explicit'
            },
            'cyclosporin':{
                'title': 'Cyclosporin',
                'itemType': 'control',
                'control.type': 'selection',
                'selection.style': 'explicit'
            },
            'goserelin':{
                'title': 'Goserelin',
                'itemType': 'control',
                'control.type': 'selection',
                'selection.style': 'explicit'
            },
            'antiAndrogens': {
                'title': 'Anti-androgens in men',
                'detail': 'e.g. Finasteride, flutamide',
                'itemType': 'control',
                'control.type': 'selection',
                'selection.style': 'explicit'
            },
            'resultsForm': {
                'title': 'Request outcome',
                'itemType': 'subform',
                'subform.sectionIDs': [
                    'patientWeightRestrictionsSection',
                    'dxaRequestOutcomeSection',
                    'moreInformationRequiredSection',
                    'requestConsiderationSection',
                    'additionalInformationSection',
                    'exportRequestOutcomeSection'
                ]
            },
            'patientWeightRestrictionsSection':{
                'title': 'Patient weight restrictions',
                'section.childIDs': [
                    'overweightAction',
                    'weightIssuesAction'
                ]
            },
            'overweightAction':{
                'title': 'DXA scan not possible on this patient because their weight exceeds the capability of the machines (160kg)',
                'itemType': 'control',
                'control.type': 'action'
            },
            'weightIssuesAction':{
                'title': 'Pacific Radiology DXA scan is not possible on this patient because their weight exceeds the capability of the machine (136kg), however DHB machines are suitable to 160kg.',
                'itemType': 'control',
                'control.type': 'action'
            },
            'privateFundingIndicated':{
                'title': 'You have indicated that the patient wishes to use private funding for their request.',
                'itemType': 'control',
                'control.type': 'plain'
            },
            'dxaRequestOutcomeSection':{
                'title': 'DXA scan outcome',
                'section.childIDs': [
                    'incompletDXARequest',
                    'dxaRequestOutcome'
                ]
            },
            'incompletDXARequest':{
                'title': 'Please complete the Patient details and Request criteria sections for initial recommendations.',
                'itemType': 'control',
                'control.type': 'plain'
            },
            'dxaRequestOutcome':{
                'title': 'DXA REQUEST OUTCOME',
                'itemType': 'control',
                'control.type': 'action'
            },
            'moreInformationRequiredSection':{
                'title': 'More information required',
                'section.childIDs': [
                    'moreInformationRequired'
                ]
            },
            'moreInformationRequired':{
                'title': 'Please complete all sections of the form. This improves the likelihood of request approval and provides complete clinical information for test reporting.\n\nOn form completion you will be able to review the recommendation and submit this request for automatic approval, or you may request special consideration if needed.',
                'itemType': 'control',
                'control.type': 'plain'
            },
            'requestConsiderationSection':{
                'title': 'Request consideration',
                'section.childIDs': [
                    'acceptanceOutsideUsualCriteria'
                ]
            },
            'acceptanceOutsideUsualCriteria': {
                'title': 'Do you believe this request should be considered for acceptance outside usual criteria?',
                'detail': 'Requests that do not fit the criteria may be accepted if a good case is presented.',
                'itemType': 'control',
                'control.type': 'selection',
                'selection.style': 'explicit',
                'subcellIDs': [
                    'acceptanceOutsideCriteriaReason'
                ]
            },
            'acceptanceOutsideCriteriaReason':{
                'title': 'Include your reasoning below',
                'itemType': 'control',
                'control.type': 'textEntry',
                'textEntry.type': 'wrappingPlainText'
            },
            'additionalInformationSection': {
                'title': 'Additional information',
                'section.childIDs': [
                    'additionalRequestInformation'
                ]
            },
            'additionalRequestInformation':{
                'title': 'Please include any other relevant information',
                'itemType': 'control',
                'control.type': 'textEntry',
                'textEntry.type': 'wrappingPlainText'
            },
            'exportRequestOutcomeSection':{
                'title': 'Export request outcome',
                'section.childIDs': [
                    'exportRequestOutcome'
                ]
            },
            'exportRequestOutcome':{
                'title': 'To include this automated outcome within your ERMS request, please use the button below to copy the summary of this outcome into the Clinical notes section of your request.',
                'itemType': 'control',
                'control.type': 'export'
            }

        },
        'insightsDependancies': [
            
        ]
    }
}