import { PatientSex } from '../../Shared Custom Types/Gender';
import { FormsStyles } from '../../../waferJS/FormsUI/FormsUI';

export class RiskCategoryBrackets {

    //Date:    22 August 2018 at 20:09
    //Subject:    data check for app
    //Marc: Can I confirm that cutoff troponin >34 means >=35?
    //John P: must be a typo somewhere.  All troponin thresholds (hsTnI 16 for females and 34 for males) are such that a positive result is >= threshold.  ie Positive if >=16 for females, >=34 for males. (ps… if this is all in a look-up table, then shld be easy to change for jurisdictions with different assays???).

    static Type = Object.freeze({
        lowRisk1: 0, // "EDACS <=20 And T1<5 If t-o>2h"
        lowRisk2: 1, // "EDACS <=20 And T1<5 If t-o<2h"
        lowRisk3: 2, // "EDACS <=15 And T1= 5-34 (men) / 5-16 (women)"
        intermediateRisk1: 3, // "EDACS 16-20 And T1= 5-34 (men) / 5-16 (women)"
        highRisk1: 4, // "EDACS >=21"
        highRisk2:5, // "T1 ≥34 (men) / ≥16 (women)"

        // After second troponin
        veryLowRisk1: 6, // "EDACS <=5 Or Age <=40 And T2 <=34 (men) / <=16 (women)"
        lowRisk4: 7, // "EDACS <=20 And T2 <=34 (men) / <=16 (women)"
        intermediateRisk2: 8, // "EDACS >=21 And T2 <=34 (men) / <=16 (women)"
        highRisk3: 9 // "T2 ≥34 (men) / ≥16 (women)"

    });

    constructor(type){
        this.type = type;
    }
    
    get riskCategory() {
        switch(this.type){
            case RiskCategoryBrackets.Type.veryLowRisk1:
                return new RiskCategory(RiskCategory.Type.veryLow);
            case RiskCategoryBrackets.Type.lowRisk1:
            case RiskCategoryBrackets.Type.lowRisk2:
            case RiskCategoryBrackets.Type.lowRisk3:
            case RiskCategoryBrackets.Type.lowRisk4:
                return  new RiskCategory(RiskCategory.Type.low);
            case RiskCategoryBrackets.Type.intermediateRisk1:
            case RiskCategoryBrackets.Type.intermediateRisk2:
                return  new RiskCategory(RiskCategory.Type.intermediate);
            case RiskCategoryBrackets.Type.highRisk1: 
            case RiskCategoryBrackets.Type.highRisk2: 
            case RiskCategoryBrackets.Type.highRisk3:
                return  new RiskCategory(RiskCategory.Type.high);
        }
    }

    get preventRiskCalculation(){
        switch(this.type){
            case RiskCategoryBrackets.Type.highRisk2, RiskCategoryBrackets.Type.highRisk3:
                return true;
            default:
                return false;
        }
    }
}

export class RiskCategoryValues {

    // let edacsScore : Double
    // let gender: Gender
    // let age: Int
    // let initialTroponinResult: Double
    // let hasBlockingRedFlag: Bool
    // let hoursBetweenOnsetAndInitialTroponin: Int
    
    // let followUpTroponinResult: Double?){
    
    constructor(edacsScore, gender, age, initialTroponinResult, hasBlockingRedFlag, hoursBetweenOnsetAndInitialTroponin, followUpTroponinResult){
        this.edacsScore = edacsScore;
        this.gender = gender;
        this.initialTroponinResult = initialTroponinResult == null ? null : parseFloat(initialTroponinResult);
        this.hasBlockingRedFlag = hasBlockingRedFlag;
        this.hoursBetweenOnsetAndInitialTroponin = hoursBetweenOnsetAndInitialTroponin;
        this.followUpTroponinResult = followUpTroponinResult == null ? null : parseFloat(followUpTroponinResult);
        this.age = age;
    }
    
    
    get riskBracket(){
        if(this.followUpTroponinResult != null){
            if(this.hasBlockingRedFlag) {
                return RiskCategoryBrackets.Type.highRisk3;
            }

            // Male >= 34, or Female/Other >= 16
            var followUpAboveCutoff = (this.gender == PatientSex.gender.female && this.followUpTroponinResult >= 16) || (this.followUpTroponinResult >= 34);

            if (followUpAboveCutoff) {
                return RiskCategoryBrackets.Type.highRisk3;
            } 
            
            // All below resolves true for: (followUpAboveCutoff == false)
            else if (this.edacsScore >= 21) {
                return RiskCategoryBrackets.Type.intermediateRisk2;
            } 
            
            // All below resolves true for: (this.edacsScore < 21)
            else if (this.edacsScore <= 5) {
                return RiskCategoryBrackets.Type.veryLowRisk1;
            } else if (this.age <= 40) {
                return RiskCategoryBrackets.Type.veryLowRisk1;
            }

            // Do these lowRisk4 checks after veryLowRisk1 since veryLowRisk1 is a more specific subset of the lowRisk4 criteria
            else if (this.edacsScore <= 20) {
                return RiskCategoryBrackets.Type.lowRisk4;
            } else {
                return null;
            }
        } else {
            if (this.hasBlockingRedFlag) {
                return RiskCategoryBrackets.Type.highRisk2;
            }

            var initialAboveCutoff = (this.gender == PatientSex.gender.male && this.initialTroponinResult >= 34) || (this.initialTroponinResult >= 16);
            
            if (this.edacsScore >= 21) {
                return RiskCategoryBrackets.Type.highRisk1;
            } 
            
            // All below resolves true for: (this.edacsScore < 21)
            else if (initialAboveCutoff) {
                return RiskCategoryBrackets.Type.highRisk2;
            } 

            // All below resolves true for: (initialAboveCutoff == false)
            else if (this.edacsScore >= 16 && this.initialTroponinResult >= 5){
                return RiskCategoryBrackets.Type.intermediateRisk1;
            } else if(this.edacsScore < 16 && this.initialTroponinResult >= 5){
                return RiskCategoryBrackets.Type.lowRisk3;
            }
            
            //  All below resolves true for: (this.initialTroponinResult < 5)
            else { 
                if(this.hoursBetweenOnsetAndInitialTroponin > 2){
                    return RiskCategoryBrackets.Type.lowRisk1;
                } else {
                    return RiskCategoryBrackets.Type.lowRisk2;
                }
            }
        }
    }
}

export class RiskCategory {

    static Type = Object.freeze({
        veryLow: 0.0,
        low: 1.0,
        intermediate: 2.0,
        high: 3.0
    });

    constructor(type){
        this.type = type;
    }
    
    get title(){
        switch (this.type) {
            case RiskCategory.Type.veryLow:
                return 'VERY LOW';
            case RiskCategory.Type.low:
                return 'LOW';
            case RiskCategory.Type.intermediate:
                return 'INTERMEDIATE';
            case RiskCategory.Type.high:
                return 'HIGH';
        }
    }
    
    get color(){
        switch (this.type) {
            case RiskCategory.Type.veryLow:
                return FormsStyles.green;
            case RiskCategory.Type.low:
                return FormsStyles.green;
            case RiskCategory.Type.intermediate:
                return FormsStyles.orange;
            case RiskCategory.Type.high:
                return FormsStyles.red;
        }
    }

    static getRiskCategory(value){
        let category = RiskCategory.Type[value];
        return category;
    }

    static getRiskCategoryFromBracketValue(value){
        let riskCategoryBracket = new RiskCategoryBrackets(value);
        return riskCategoryBracket.riskCategory;
    }
}