// IMPORT PACKAGE REFERENCES

import { combineReducers } from "redux";

// IMPORT REDUCERS
import { UserReducer } from "./UserReducer";
import { WorkflowResponseReducer } from "./WorkflowResponseReducer";
import { CalculationReducer } from "./CalculationReducer";
import { NotificationsReducer } from "./NotificationsReducer";
import { ActionsReducer } from "./ActionsReducer";
import { SubformStateReducer } from "./SubformStateReducer";
import { ExportToHCSReducer } from "./ExportToHCSReducer";
import { ExportToEmailReducer } from "./ExportToEmailReducer";
import { ExportToACCReducer } from "./ExportToACCReducer";
import { AuthReducer } from "./AuthReducer";

// EXPORT APP REDUCER

export const AppReducer = combineReducers({
  userReducer: UserReducer,
  workflowReducer: WorkflowResponseReducer,
  calculationReducer: CalculationReducer,
  notificationsReducer: NotificationsReducer,
  actionsReducer: ActionsReducer,
  subformStateReducer: SubformStateReducer,
  exportToEmailReducer: ExportToEmailReducer,
  exportToACCReducer: ExportToACCReducer,
  authReducer: AuthReducer,
});
