// Gender enum, if useful. Used to compare custom gender numeric value
var PatientSexType = Object.freeze({'male': 1, 'female': 0});

export var edacsTotal = function(jsonData) {
    
    var parameters = {
        patientAge: jsonData.patientAge,
        ageRiskFactorsCount: 0,
        
        famHxCvd:jsonData.prematureCoronaryHeartDisease,
        hxDm: jsonData.diabetes,
        hxDyslip: jsonData.dyslipidaemia,
        hxHtn: jsonData.hypertension,
        hxSmok: jsonData.currentSmoker,
        
        coronaryHeartDisease: jsonData.coronaryHeartDisease,

        diaphoresis: jsonData.diaphoresis,
        painPleuritic: jsonData.pleuriticPain,
        painRadiateArmNeckJaw: jsonData.painRadiates,
        painPalpation:jsonData.painByPalpation,
        patientSex: jsonData.patientSexInt
    };

    if(dataIncomplete(parameters)){
        return null;
    }
    
    if (parameters.patientAge < 18) {
        return null;
    }
    
    var cumulativeTotal = 0;
    
    // Patient age
    if (parameters.patientAge < 46) {
        cumulativeTotal += 2;
    } else if (parameters.patientAge < 51) {
        cumulativeTotal += 4;
    } else if (parameters.patientAge < 56) {
        cumulativeTotal += 6;
    } else if (parameters.patientAge < 61) {
        cumulativeTotal += 8;
    } else if (parameters.patientAge < 66) {
        cumulativeTotal += 10;
    } else if (parameters.patientAge < 71) {
        cumulativeTotal += 12;
    } else if (parameters.patientAge < 76) {
        cumulativeTotal += 14;
    } else if (parameters.patientAge < 81) {
        cumulativeTotal += 16;
    } else if (parameters.patientAge < 86) {
        cumulativeTotal += 18;
    } else {
        cumulativeTotal += 20;
    }
    
    // Risk factors - age-related
    if (parameters.patientAge < 51) {
        
        if (parameters.famHxCvd === true) {
            parameters.ageRiskFactorsCount += 1;
        }
        
        if (parameters.hxDm === true) {
            parameters.ageRiskFactorsCount += 1;
        }
        
        if (parameters.hxDyslip === true) {
            parameters.ageRiskFactorsCount += 1;
        }
        
        if (parameters.hxHtn === true) {
            parameters.ageRiskFactorsCount += 1;
        }
        
        if (parameters.hxSmok === true) {
            parameters.ageRiskFactorsCount += 1;
        }
        
        if (parameters.coronaryHeartDisease === true || parameters.ageRiskFactorsCount >= 3) {
            cumulativeTotal += 4;
        }
    }
    
    // Symptoms
    
    if (parameters.diaphoresis === true) {
        cumulativeTotal += 3;
    }
    
    if (parameters.painPleuritic === true) {
        cumulativeTotal -= 4;
    }
    
    if (parameters.painRadiateArmNeckJaw === true) {
        cumulativeTotal += 5;
    }
    
    if (parameters.painPalpation === true) {
        cumulativeTotal -= 6;
    }
    
    if (parameters.patientSex != PatientSexType.female) {
        cumulativeTotal += 6;
    }
    
    return cumulativeTotal;
};

const AFTER_ONE_TROPONIN_INITIAL_PARAMETER = -8.57;
const AFTER_ONE_TROPONIN_AGE = 0.0201;
const AFTER_ONE_TROPONIN_SEX_MALE = 0.613;
const AFTER_ONE_TROPONIN_NEW_ISCHAEMIA = 0.258;
const AFTER_ONE_TROPONIN_HX_HTN = 0.683;
const AFTER_ONE_TROPONIN_HX_DYSLIP = 0.292;
const AFTER_ONE_TROPONIN_HX_DM = 0.282;
const AFTER_ONE_TROPONIN_HX_SMOK = 0.619;
const AFTER_ONE_TROPONIN_HX_CAD = 0.282;
const AFTER_ONE_TROPONIN_FAM_HX_CVD = -0.0594;
const AFTER_ONE_TROPONIN_AS_AUSE = -0.929;
const AFTER_ONE_TROPONIN_DIAPHORESIS = 0.409;
const AFTER_ONE_TROPONIN_PAIN_RADIATE_ARM_NECK_JAW = 0.473;
const AFTER_ONE_TROPONIN_PAIN_PLEURITIC = -0.092;
const AFTER_ONE_TROPONIN_PAIN_PALPATION = -1.02;
const AFTER_ONE_TROPONIN_HS_TNI_0H = 3.71;
const AFTER_ONE_TROPONIN_HS_TNI_0H_BONUS = 0.5;

const AFTER_TWO_TROPONINS_INITIAL_PARAMETER = -8.99;
const AFTER_TWO_TROPONINS_AGE = 0.017;
const AFTER_TWO_TROPONINS_SEX_MALE = 0.66;
const AFTER_TWO_TROPONINS_NEW_ISCHAEMIA = 0.208;
const AFTER_TWO_TROPONINS_HX_HTN = 0.559;
const AFTER_TWO_TROPONINS_HX_DYSLIP = 0.319;
const AFTER_TWO_TROPONINS_HX_DM = 0.579;
const AFTER_TWO_TROPONINS_HX_SMOK = 0.539;
const AFTER_TWO_TROPONINS_HX_CAD = 0.299;
const AFTER_TWO_TROPONINS_FAM_HX_CVD = -0.064;
const AFTER_TWO_TROPONINS_AS_AUSE = -0.829;
const AFTER_TWO_TROPONINS_DIAPHORESIS = 0.321;
const AFTER_TWO_TROPONINS_PAIN_RADIATE_ARM_NECK_JAW = 0.103;
const AFTER_TWO_TROPONINS_PAIN_PLEURITIC = -0.159;
const AFTER_TWO_TROPONINS_PAIN_PALPATION = -0.535;
const AFTER_TWO_TROPONINS_HS_TNI_0H = 3.38;
const AFTER_TWO_TROPONINS_HS_TNI_0H_BONUS = 0.5;
const AFTER_TWO_TROPONINS_HS_TNI_0H_2H_DELTA = 3.48;
const AFTER_TWO_TROPONINS_HS_TNI_0H_2H_DELTA_BONUS = 0.5;
const AFTER_TWO_TROPONINS_HS_TNI_0H_MULTIPLIER = -1.13;
const AFTER_TWO_TROPONINS_HS_TNI_0H_MULTIPLIER_BONUS = 0.5;
const AFTER_TWO_TROPONINS_HS_TNI_0H_2H_DELTA_MULTIPLICAND_BONUS = 0.5;

export var probabilityOfMACE = function(jsonData) {
    var hsTnI2h = jsonData.followUpBloodsTroponin;
    if (hsTnI2h != undefined && hsTnI2h != null) {
        return afterTwoTroponins(jsonData);
    } else {
        return afterOneTroponin(jsonData);
    }
};

var afterOneTroponin = function(jsonData) {
    var cumulativeTotal = AFTER_ONE_TROPONIN_INITIAL_PARAMETER;
    
    var parameters = {
        patientAge: jsonData.patientAge,
        patientSex: jsonData.patientSexInt,
        hxHtn: jsonData.hypertension,
        hxDyslip: jsonData.dyslipidaemia,
        hxDm: jsonData.diabetes,
        hxSmok: jsonData.currentSmoker,
        coronaryArteryStenosis: jsonData.coronaryArteryStenosis,
        coronaryHeartDisease: jsonData.coronaryHeartDisease,
        famHxCvd: jsonData.prematureCoronaryHeartDisease,
        asAuse: jsonData.aspirin,
        diaphoresis: jsonData.diaphoresis,
        painRadiateArmNeckJaw: jsonData.painRadiates,
        painPleuritic: jsonData.pleuriticPain,
        painPalpation: jsonData.painByPalpation,
        hsTnI0h: jsonData.initialBloodsTroponin
    };

    if(jsonData.newIschaemicChangesOverride == true){
        parameters.newIschaemia = jsonData.newIschaemicChangesOverrideValue;
    } else if(jsonData.newIschaemia != null){
        parameters.newIschaemia = jsonData.newIschaemia ;
    } else if(jsonData.abnormalitiesPresent == false){
        parameters.newIschaemia = false;
    } else {
        parameters.newIschaemia = null;
    }

    if(dataIncomplete(parameters)){
        return null;
    }
    
    // Either of these values
    parameters.hxCad = parameters.coronaryArteryStenosis === true || parameters.coronaryHeartDisease === true;
    
    //console.log('P1: ' + JSON.stringify(parameters));
    
    cumulativeTotal += (parameters.patientAge * AFTER_ONE_TROPONIN_AGE);
    
    if (parameters.patientSex != PatientSexType.female) {
        cumulativeTotal += AFTER_ONE_TROPONIN_SEX_MALE;
    }
    
    if (parameters.newIschaemia === true) {
        cumulativeTotal += AFTER_ONE_TROPONIN_NEW_ISCHAEMIA;
    }
    
    if (parameters.hxHtn === true) {
        cumulativeTotal += AFTER_ONE_TROPONIN_HX_HTN;
        // console.log("+ AFTER_ONE_TROPONIN_HX_HTN (" + AFTER_ONE_TROPONIN_HX_HTN + ") = " + cumulativeTotal);
    }
    
    if (parameters.hxDyslip === true) {
        cumulativeTotal += AFTER_ONE_TROPONIN_HX_DYSLIP;
    }
    
    if (parameters.hxDm === true) {
        cumulativeTotal += AFTER_ONE_TROPONIN_HX_DM;
    }
    
    if (parameters.hxSmok === true) {
        cumulativeTotal += AFTER_ONE_TROPONIN_HX_SMOK;
    }
    
    if (parameters.hxCad === true) {
        cumulativeTotal += AFTER_ONE_TROPONIN_HX_CAD;
    }
    
    if (parameters.famHxCvd === true) {
        cumulativeTotal += AFTER_ONE_TROPONIN_FAM_HX_CVD;
    }
    
    if (parameters.asAuse === true) {
        cumulativeTotal += AFTER_ONE_TROPONIN_AS_AUSE;
    }
    
    if (parameters.diaphoresis === true) {
        cumulativeTotal += AFTER_ONE_TROPONIN_DIAPHORESIS;
    }
    
    if (parameters.painRadiateArmNeckJaw === true) {
        cumulativeTotal += AFTER_ONE_TROPONIN_PAIN_RADIATE_ARM_NECK_JAW;
    }
    
    if (parameters.painPleuritic === true) {
        cumulativeTotal += AFTER_ONE_TROPONIN_PAIN_PLEURITIC;
    }
    
    if (parameters.painPalpation === true) {
        cumulativeTotal += AFTER_ONE_TROPONIN_PAIN_PALPATION;
    }
    
    cumulativeTotal += AFTER_ONE_TROPONIN_HS_TNI_0H * Math.log10(parameters.hsTnI0h + AFTER_ONE_TROPONIN_HS_TNI_0H_BONUS);
    
    var bottomHalf = 1 + Math.exp(-cumulativeTotal);
    
    return 1 / bottomHalf;
};

var afterTwoTroponins = function(jsonData) {
    
    var cumulativeTotal = AFTER_TWO_TROPONINS_INITIAL_PARAMETER;
    
    var parameters = {
        patientAge: jsonData.patientAge,
        patientSex: jsonData.patientSexInt,
        hxHtn: jsonData.hypertension,
        hxDyslip: jsonData.dyslipidaemia,
        hxDm: jsonData.diabetes,
        hxSmok: jsonData.currentSmoker,
        hxCad: jsonData.coronaryArteryStenosis,
        famHxCvd: jsonData.prematureCoronaryHeartDisease,
        asAuse: jsonData.aspirin,
        diaphoresis: jsonData.diaphoresis,
        painRadiateArmNeckJaw: jsonData.painRadiates,
        painPleuritic: jsonData.pleuriticPain,
        painPalpation: jsonData.painByPalpation,
        hsTnI0h: jsonData.initialBloodsTroponin,
        hsTnI2h: jsonData.followUpBloodsTroponin
    };

    if(jsonData.newIschaemia != null){
        parameters.newIschaemia = jsonData.newIschaemia ;
    }else if(jsonData.abnormalitiesPresent == false){
        parameters.newIschaemia = false;
    } else {
        parameters.newIschaemia = null;
    }

    if(dataIncomplete(parameters)){
        return null;
    }

    // Either of these values
    parameters.hxCad = parameters.coronaryArteryStenosis === true || parameters.coronaryHeartDisease === true;
    
    //console.log('P2: ' + JSON.stringify(parameters));
    
    cumulativeTotal += (parameters.patientAge * AFTER_TWO_TROPONINS_AGE);
    
    if (parameters.patientSex != PatientSexType.female) {
        cumulativeTotal += AFTER_TWO_TROPONINS_SEX_MALE;
    }
    
    if (parameters.newIschaemia === true) {
        cumulativeTotal += AFTER_TWO_TROPONINS_NEW_ISCHAEMIA;
    }
    
    if (parameters.hxHtn === true) {
        cumulativeTotal += AFTER_TWO_TROPONINS_HX_HTN;
    }
    
    if (parameters.hxDyslip === true) {
        cumulativeTotal += AFTER_TWO_TROPONINS_HX_DYSLIP;
    }
    
    if (parameters.hxDm === true) {
        cumulativeTotal += AFTER_TWO_TROPONINS_HX_DM;
    }
    
    if (parameters.hxSmok === true) {
        cumulativeTotal += AFTER_TWO_TROPONINS_HX_SMOK;
    }
    
    if (parameters.hxCad === true) {
        cumulativeTotal += AFTER_TWO_TROPONINS_HX_CAD;
    }
    
    if (parameters.famHxCvd === true) {
        cumulativeTotal += AFTER_TWO_TROPONINS_FAM_HX_CVD;
    }
    
    if (parameters.asAuse === true) {
        cumulativeTotal += AFTER_TWO_TROPONINS_AS_AUSE;
    }
    
    if (parameters.diaphoresis === true) {
        cumulativeTotal += AFTER_TWO_TROPONINS_DIAPHORESIS;
    }
    
    if (parameters.painRadiateArmNeckJaw === true) {
        cumulativeTotal += AFTER_TWO_TROPONINS_PAIN_RADIATE_ARM_NECK_JAW;
    }
    
    if (parameters.painPleuritic === true) {
        cumulativeTotal += AFTER_TWO_TROPONINS_PAIN_PLEURITIC;
    }
    
    if (parameters.painPalpation === true) {
        cumulativeTotal += AFTER_TWO_TROPONINS_PAIN_PALPATION;
    }
    cumulativeTotal += AFTER_TWO_TROPONINS_HS_TNI_0H * Math.log10(parameters.hsTnI0h + AFTER_TWO_TROPONINS_HS_TNI_0H_BONUS);
    cumulativeTotal += AFTER_TWO_TROPONINS_HS_TNI_0H_2H_DELTA * Math.log10(Math.abs(parameters.hsTnI2h - parameters.hsTnI0h) + AFTER_TWO_TROPONINS_HS_TNI_0H_2H_DELTA_BONUS);
    cumulativeTotal += AFTER_TWO_TROPONINS_HS_TNI_0H_MULTIPLIER * Math.log10(parameters.hsTnI0h + AFTER_TWO_TROPONINS_HS_TNI_0H_MULTIPLIER_BONUS) * Math.log10(Math.abs(parameters.hsTnI2h - parameters.hsTnI0h) + AFTER_TWO_TROPONINS_HS_TNI_0H_2H_DELTA_MULTIPLICAND_BONUS);
    
    var bottomHalf = 1 + Math.exp(-cumulativeTotal);
    
    return 1 / bottomHalf;
};

// var testCalculationAccuracy = function(name, subject) {
//     var p1Result = afterOneTroponin(subject);
//     var p1Difference = Math.abs(subject.p1 - p1Result);

//     var p2Result = afterTwoTroponins(subject);
//     var p2Difference = Math.abs(subject.p2 - p2Result);

//     console.log(name + ' P1: ' + p1Result + ' (diff: ' + p1Difference + ')');
//     console.log(name + ' P2: ' + p2Result + ' (diff: ' + p2Difference + ')');
//     console.log('');
// };

// var example1 = {
//     patientAge : 65.60070091,
//     patientSex : 0,
//     newIschaemia : false,
//     hypertension : true,
//     dyslipidaemia : true,
//     diabetes : true,
//     currentSmoker : false,
//     coronaryArteryStenosis : true,
//     prematureCoronaryHeartDisease : true,
//     aspirin : true,
//     diaphoresis : true,
//     painRadiates : true,
//     pleuriticPain : true,
//     painByPalpation : false,
//     initialBloodsTroponin : 4.1,
//     followUpBloodsTroponin : 5.6,
//     p1 : 0.030497534760851267,
//     p2 : 0.02459013013133179
// };

// var example2 = {
//     patientAge : 47.27576388,
//     patientSex : 0,
//     newIschaemia : true,
//     hypertension : false,
//     dyslipidaemia : false,
//     diabetes : false,
//     currentSmoker : false,
//     coronaryArteryStenosis : false,
//     prematureCoronaryHeartDisease : false,
//     aspirin : false,
//     diaphoresis : true,
//     painRadiates : false,
//     pleuriticPain : false,
//     painByPalpation : false,
//     initialBloodsTroponin : 2040.6,
//     followUpBloodsTroponin : 4383.8,
//     p1 : 0.995082708160944,
//     p2 : 0.935230606874906
// };

// var example3 = {
//     patientAge : 80.46216187,
//     patientSex : 0,
//     newIschaemia : false,
//     hypertension : true,
//     dyslipidaemia : true,
//     diabetes : true,
//     currentSmoker : false,
//     coronaryArteryStenosis : true,
//     prematureCoronaryHeartDisease : false,
//     aspirin : true,
//     diaphoresis : false,
//     painRadiates : true,
//     pleuriticPain : false,
//     painByPalpation : false,
//     initialBloodsTroponin : 44.8,
//     followUpBloodsTroponin : 36.2,
//     p1 : 0.5653777218533972,
//     p2 : 0.634390336272516
// };

// var examplesToRun = {
//     'example1': example1,
//     'example2': example2,
//     'example3': example3
// };

// var performTests = function() {
//     Object.keys(examplesToRun).forEach(key => {
//         var example = examplesToRun[key];

//         testCalculationAccuracy(key, example);
//     });
// };

//performTests();

function dataIncomplete(data) {
    for (var member in data) {
        if (data[member] == null && member != 'followUpBloodsTroponin'){
            return true;
        }
    }
    return false;
}
