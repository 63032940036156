import { FormContentView } from '../../FormsUI/FormsUI Components/Containers/FormContentView';
import { FormsStyles } from '../../FormsUI/FormsUI';
import React from 'react';
import PropTypes, { array } from 'prop-types';
import { FormCardGrid } from '../../FormsUI/FormsUI Components/Controls/FormCardGrid';
import { pictureCards, pictureCardsFail } from '../../../lib/pictureCards';
import { FormButton } from '../../FormsUI/FormsUI Components/Controls/FormButton';

export class PictureGridFormDetail extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            firstCheckSelected: {},
            secondCheckSelected: {},
            firstCheckCards: pictureCards,
            secondCheckCards: pictureCardsFail
        };

        this.imageClick = this.imageClick.bind(this);
        this.confirmClick = this.confirmClick.bind(this);
        this.hasChanged = this.hasChanged.bind(this);
        this.isSame = this.isSame.bind(this);
        this.isNotSame = this.isNotSame.bind(this);
    }
    
    componentDidMount() {
        let value = this.props.formDetail.currentValue;
        let firstCheckSelected = {};
        let secondCheckSelected = {};


        if(value !== undefined && value !== null && value.length > 0) {
            if (!this.props.formDetail.identifier.includes('Failure')) {
                value.forEach((card) => {
                    firstCheckSelected[card.id] = card;
                });
            }
            else {
                value.forEach((card) => {
                    secondCheckSelected[card.id] = card;
                });
            }
        }

        this.setState({
            firstCheckSelected: firstCheckSelected, 
            secondCheckSelected: secondCheckSelected
        });
    }

    confirmClick() {
        let values = [];
        
        
        if(!this.props.formDetail.identifier.includes('Failure')) {
            for (const [key, value] of Object.entries(this.state.firstCheckSelected)) {
                if (value != null && value != undefined) {
                    values.push(value);
                }
            }
        }
        else {
            for (const [key, value] of Object.entries(this.state.secondCheckSelected)) {
                if (value != null && value != undefined) {
                    values.push(value);
                }
            }
        }

        this.props.formDetail.form.valueUpdated(this.props.formDetail.identifier, values);
    }

    imageClick(id) {
        //3 picture grid
        if(!this.props.formDetail.identifier.includes('Failure')) {
            let cards = this.state.firstCheckSelected;
            let selectedCard = this.state.firstCheckCards.find((card) => card.id == id);
            
            if(cards[id] != null && cards[id] != undefined) {
                cards[id] = null;
            }
            else {
                cards[id] = selectedCard;
            }

            this.setState({
                firstCheckSelected: cards
            });
        }

        //9 picture grid
        else {
            let cards = this.state.secondCheckSelected;
            let selectedCard = this.state.secondCheckCards.find((card) => card.id == id);
            
            if(cards[id] != null && cards[id] != undefined) {
                cards[id] = null;
            }
            else {
                cards[id] = selectedCard;
            }

            this.setState({
                secondCheckSelected: cards
            });
        }
    }

    isSame(array1, array2) {
        return array2.every(array2Item => array1.includes(array2Item)) && array1.length === array2.length;
    }

    isNotSame(array1, array2) {
        return !this.isSame(array1, array2);
    }

    hasChanged(array) {
        let currentValue = this.props.formDetail.form.getValue(this.props.formDetail.identifier);

        let filteredArray = Object.fromEntries(Object.entries(array).filter(([key, value]) => value != null && value != undefined));
        let selectedIds = Object.keys(filteredArray);

        if(currentValue !== undefined && currentValue !== null && array !== undefined && array !== null) {
            let currentValueIds = currentValue.length > 0 ? currentValue.map((item) => item.id.toString()): [];
            

            if(currentValue.length === 0 && selectedIds.length === 0) {
                return {
                    isSelected: true,
                    title: 'None',
                };
            }
            else if(this.isSame(currentValueIds, selectedIds) && selectedIds.length > 0) {
                return {
                    isSelected: true,
                    title: 'Confirm',
                };
            }
            else if(this.isNotSame(currentValueIds, selectedIds) && selectedIds.length === 0) {
                return {
                    isSelected: false,
                    title: 'None',
                };
            }
            else if(this.isNotSame(currentValueIds, selectedIds) && selectedIds.length > 0) {
                return {
                    isSelected: false,
                    title: 'Confirm',
                };
            }
        }
        else if (selectedIds !== null && selectedIds != undefined && selectedIds.length > 0) {
            return {
                isSelected: false,
                title: 'Confirm',
            };
        }
        else {
            return {
                isSelected: false,
                title: 'None',
            };
        }
    }

    render() {

        let enabled = this.props.formDetail.form.isEnabled(this.props.formDetail.identifier);
        let currentValue = this.props.formDetail.form.getValue(this.props.formDetail.identifier);

        let value = currentValue;
        let callHasChanged;
        let numCards = 0;
        let cardList;
        let selectedValues = {};
        
        if(!this.props.formDetail.identifier.includes('Failure')) {
            value = this.state.firstCheckSelected;
            callHasChanged = this.hasChanged(this.state.firstCheckSelected);
            numCards = 3;
            cardList = this.state.firstCheckCards;
            selectedValues = this.state.firstCheckSelected;
        }
        else {
            value = this.state.secondCheckSelected;
            callHasChanged = this.hasChanged(this.state.secondCheckSelected);
            numCards = 9;
            cardList = this.state.secondCheckCards;
            selectedValues = this.state.secondCheckSelected;
        }

        let grid = 
        <div>
            <FormCardGrid identifier={this.props.formDetail.identifier} cardNumber={numCards} cardList={cardList} selectedCardlist={selectedValues} imageClick={this.imageClick}/>
            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-end'}}>
                <FormButton key={value.length > 0 ? 'confirm' + this.props.formDetail.identifier : 'none' + this.props.formDetail.identifier} onPress={this.confirmClick} enabled={enabled} title={callHasChanged.title} isSelected={callHasChanged.isSelected} /> 
            </div>
        </div>;
        return (
            <FormContentView identifier={this.props.formDetail.identifier} isSubcell={this.props.isSubcell} titleString={this.props.formDetail.title} detailString={this.props.formDetail.detail} subcell={[]} bottomControl={grid} subCellbackgroundColor={FormsStyles.backgroundColor} showHover={true}/>
        );
    }
}

PictureGridFormDetail.propTypes = {
    formDetail: PropTypes.object.isRequired,
    isSubcell: PropTypes.bool.isRequired,
    renderErrors: PropTypes.func.isRequired,
    renderSubcells: PropTypes.func.isRequired,
    errorLabels: PropTypes.array.isRequired,
    hasError: PropTypes.bool.isRequired
};
