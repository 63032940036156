import documentVersions from '../../config/documentVersions.json';
import { PathwayReferenceHelper } from '../PathwayReferenceHelper';
import { EDChestPainPathwayReference } from './/EDChestPainPathway-Reference';
import { FormsContentType } from '../../waferJS/FormsUI/FormsUI';
import { CalculationModel } from './Custom Types/Calculation';
import { EDChestPainCalculationHelper } from './Helpers/EDChestPainCalculationHelper';
import { redux } from '../../App';
import { calculateEWS, TotalEWS  } from './Custom Types/EWS';
import { PathwayFormatter } from '../PathwayFormatter';
import { EDChestPainResultsForm } from './Forms/EDChestPain-ResultsForm';
import { ENVIRONMENT } from '../../config/config';
import { ENABLED_EXPORTS } from '../../config/config';

const sectionBreak = '\n\n';

export class EDChestPainFormatter {

    static formatAsPlainText(formLoader, workflow) {
        let formattedString = '';
        
        let environmentName = getEnvironmentName();

        formattedString += 'Wayfind ED Chest Pain: Suspected ACS (' + (environmentName != null ? environmentName : '') + documentVersions[workflow.pathwayUuid] + ') - Updated: ' + PathwayFormatter.formatDate(workflow.lastModifiedDate, 'ddd DD-MMM-YYYY HH:mm') + ' (v' + workflow.version +  ')';
        formattedString += sectionBreak;

        formattedString += 'PATIENT: ';

        if (ENABLED_EXPORTS.includes('ecpp')) {
            formattedString += getReadableValue(workflow, 'patientNHINumber', true) + ' ';
        }
        
        formattedString += getReadableValue(workflow, 'patientAge', true) + ' ' + getReadableValue(workflow, 'patientSex', true);
        
        formattedString += sectionBreak;

        let notes = generateNotes(workflow);
        if (notes != null) {
            formattedString += 'NOTES\n';
            formattedString += notes;
            formattedString += sectionBreak;
        }

        formattedString += 'KEY CLINICAL FEATURES\n';
        formattedString += 'Timing of pain:' + getReadableDate(workflow, 'firstDateTime', ' ddd DD-MMM-YYYY, HH:mm', false, true) + ', lasting ' +  getReadableValue(workflow,'firstDuration', true);
        formattedString += sectionBreak;

        formattedString += 'SYMPTOMS AND SIGNS\n';
        formattedString += getItemsInSection(workflow, ['symptomsAndSigns'], true, '', '');
        formattedString += getItemsInSection(workflow, ['symptomsAndSigns'], null, '[Not specified: ', ']');
        formattedString += getItemsInSection(workflow, ['symptomsAndSigns'], false, '(Not present: ', ')');
        formattedString += '\n';

        formattedString += 'KEY HISTORICAL INFORMATION\n';
        
        formattedString += getItemsInSection(workflow, ['problemsAndPast', 'familyHistory', 'medications'], true, '', '');
        formattedString += getItemsInSection(workflow, ['problemsAndPast', 'familyHistory', 'medications'], null, '[Not specified: ', ']');
        formattedString += getItemsInSection(workflow, ['problemsAndPast', 'familyHistory', 'medications'], false, '(Not present: ', ')');
        formattedString += '\n';

        let totalEWS = getTotalEWS(workflow);
        let vitalsDeclaredNormal = workflow.rawData['areSignsNormal'] == true;
        var shouldDisplayVitalsNormal = false;
        var shouldDisplayVitalSigns = false;

        let ewsIdentifiers = [
            'heartRateEWS',
            'bloodPressureEWS',
            'bodyTemperatureEWS',
            'oxygenSaturationEWS',
            'respiratoryRateEWS',
            'supplementalOxygenEWS',
            'levelOfConsciousnessEWS'
        ];

        let categories = ewsIdentifiers.map((identifier) => {
            let value = workflow.rawData[identifier.replace('EWS', '')];
            return calculateEWS(identifier, value);
        }).filter(n => n);

        if (totalEWS != null && totalEWS != undefined) {
            formattedString += 'VITAL SIGNS [' + totalEWS.title + ']\n';

            shouldDisplayVitalsNormal = (vitalsDeclaredNormal == true && totalEWS.totalCanDeclareNormal == true && totalEWS.singleFieldCanDeclareNormal == true);
            shouldDisplayVitalSigns = categories.length > 0 || vitalsDeclaredNormal != true || (totalEWS.totalCanDeclareNormal != true && totalEWS.singleFieldCanDeclareNormal != true);
        } else {
            formattedString += 'VITAL SIGNS' + '\n';

            shouldDisplayVitalsNormal = vitalsDeclaredNormal == true;
            shouldDisplayVitalSigns = (categories.length > 0 || vitalsDeclaredNormal != true);
        }
        
        if (shouldDisplayVitalsNormal == true) {
            let areVitalsNormal = getReadableValue(workflow, 'areSignsNormal', true);
            formattedString += 'Are the vital signs normal for this patient?: ' + areVitalsNormal;
        }

        if (shouldDisplayVitalSigns == true) {
            if (shouldDisplayVitalsNormal == true) {
                formattedString += '\n';
            }

            var vitalsComponents = [];

            PathwayReferenceHelper.reference('recordVitalsSection', EDChestPainPathwayReference.data)[PathwayReferenceHelper.Type.sectionChildIDs].map((vitalSign) => {
                if(vitalSign == 'supplementalOxygen' || vitalSign == 'levelOfConsciousness'){
                    vitalsComponents.push('\n' + vitalsShorthandTitle(vitalSign) + getReadableValue(workflow,vitalSign, true));
                } else {
                    vitalsComponents.push(vitalsShorthandTitle(vitalSign) + getReadableValue(workflow,vitalSign, true));
                }
            });

            formattedString += vitalsComponents.join(', ');
        }
        formattedString += sectionBreak;

        formattedString += 'ECG' + getFormBadge(formLoader, workflow.pathwayUuid, 'ecgTest') + '\n';
        formattedString += generateStemi(workflow);
        formattedString += generateAbnormalities(workflow);
        formattedString += '\n';

        formattedString += 'BLOODS' + getFormBadge(formLoader, workflow.pathwayUuid, 'initialBloods') + '\n';
        formattedString += 'Troponin I - Initial: ' + getReadableValue(workflow,'initialBloodsTroponin', true) + ', ' + getReadableDate(workflow, 'initialBloodsDateAndTime', 'ddd DD-MMM-YYYY, HH:mm', true, true);
        if (workflow.rawData['followUpBloodsTroponin'] != null) {
            formattedString += '\nTroponin I - Repeat: ' + getReadableValue(workflow,'followUpBloodsTroponin', true) + ', ' + getReadableDate(workflow, 'followUpBloodsDateAndTime', 'ddd DD-MMM-YYYY, HH:mm', true, true);
        }
        formattedString += sectionBreak;

        formattedString += 'RED FLAGS '  + getRedFlagsBadge(workflow) + '\n';
        let redFlags = [
            'aorticDissectionPresentRedFlag', 'oesophagealRuptureRedFlag', 'pulmonaryEmbolismRedFlag', 
            'pancreatitisRedFlag', 'pneumothoraxRedFlag', 'newIschaemicChanges', 
            'redFlagsOngoingChestPain', 'crescendoAngina', 'haemodynamicInstability'
        ];

        formattedString += getDisparateItemsAsList(workflow, redFlags, true,  'High clinical suspicion of: ', '');
        formattedString += getDisparateItemsAsList(workflow, redFlags, null,  '[Not specified: ', ']');
        formattedString += getDisparateItemsAsList(workflow, redFlags, false,  '(Low clinical suspicion: ', ')');
        formattedString += '\n';

        let overrides = getRedFlagOverrideReasons(workflow);
        if (overrides != '') {
            formattedString += getRedFlagOverrideReasons(workflow);
            formattedString +='asdfasdfsa';
            formattedString += sectionBreak;
        }
        
        formattedString += 'INSIGHTS\n';
        formattedString += 'Calculated EDACS: ' + getCalculationBadge(formLoader, workflow, CalculationModel.CalculationType.edacs, 'calculatedEDACS') + '\n';
        formattedString += 'Pathway Risk Category: ' + getCalculationBadge(formLoader, workflow, CalculationModel.CalculationType.riskCategory, 'pathwayRiskCategory') + '\n';
        formattedString += 'Probability of MACE: ' + getCalculationBadge(formLoader, workflow, CalculationModel.CalculationType.probabilityOfMace, 'probabilityOfMACE');
        formattedString += sectionBreak;

        formattedString += (new EDChestPainResultsForm(formLoader)).getPlainTextActions();

        return formattedString;
    }

}

function getEnvironmentName() {
    switch(ENVIRONMENT) {
        case 'PROD':
            break;
        case 'DEV':
            return 'Dev ';
        case 'TEST':
            return 'Test ';
        case 'STAGING':
            return 'Staging ';
        case 'DEMO':
            return 'Demo ';
    }
}

function getReadableDate(workflow, identifier, format) {
    let date = workflow.rawData[identifier];
    if (date == null) {
        return getEmptyValue(identifier);
    }
    return PathwayFormatter.formatDate(date, format);
}

function getEmptyValue(identifier){
    let reference = PathwayReferenceHelper.reference(identifier, EDChestPainPathwayReference.data);
    return '[' + reference.title + ']';
}

function vitalsShorthandTitle(identifier){
    switch(identifier){
        case 'heartRate':
            return 'HR: ';
        case 'bloodPressure':
            return 'BP: '; 
        case 'bodyTemperature':
            return 'T: ';
        case 'oxygenSaturation':
            return 'SpO₂: ';
        case 'respiratoryRate':
            return 'RR: ';
        case 'supplementalOxygen':
            return 'Supplemental oxygen: ';
        case 'levelOfConsciousness':
            return 'Level of consciousness: ';
    }
    
}

function generateNotes(workflow) {
    let notesReference = PathwayReferenceHelper.reference('notesSection', EDChestPainPathwayReference.data);

    let noNotes = notesReference[PathwayReferenceHelper.Type.sectionChildIDs].map((notesSection) => {
        return workflow.rawData[notesSection];
    }).filter(n => n).length == 0;

    if (noNotes) {
        return null;
    } 

    return notesReference[PathwayReferenceHelper.Type.sectionChildIDs].map((notesSection) => { 
        if (workflow.rawData[notesSection] != null){
            return workflow.rawData[notesSection];
        }
        return null;
    }).filter(n => n).join('\n\n');
}

function getReadableValue(workflow, identifier) {
    let reference = PathwayReferenceHelper.reference(identifier, EDChestPainPathwayReference.data);
    let value = workflow.rawData[identifier];
    let unit = reference[PathwayReferenceHelper.Type.textEntryUnitText];
    if (value == null) { 
        return getEmptyValue(identifier); 
    }

    switch (reference[PathwayReferenceHelper.Type.controlType]) {
        case FormsContentType.ControlType.plain:
        case FormsContentType.ControlType.badge:
        case FormsContentType.ControlType.calculation:
        case FormsContentType.ControlType.action:
        case FormsContentType.ControlType.picker:
        case FormsContentType.ControlType.weblink:
        case FormsContentType.ControlType.textEntry: {
            if(identifier == 'initialBloodsTroponin' || identifier == 'followUpBloodsTroponin'){
                if(value == 0 || value == 1){
                    return '<2' + (unit == null ? '' : unit);
                }
            }
            return value + (unit == null ? '' : unit);
        }
        case FormsContentType.ControlType.radio:
            return value == true ? 'Yes' : 'No';
        case FormsContentType.ControlType.selection:
            if (reference[PathwayReferenceHelper.Type.selectionType] == FormsContentType.SelectionType.explicit) {
                return value == true ? 'Yes' : 'No';
            } else if (reference[PathwayReferenceHelper.Type.selectionType] == FormsContentType.SelectionType.simple) {
                let optionReference = PathwayReferenceHelper.reference(value, EDChestPainPathwayReference.data);
                return optionReference.title;
            }
    }
}

function getItemsInSection(workflow, identifiers, valueState, openingString, closingString, emptyString) {
    let filteredItems = [];
    for (let identifier of identifiers) {
        let listReference = PathwayReferenceHelper.reference(identifier, EDChestPainPathwayReference.data);
        filteredItems = filteredItems.concat(
            listReference[PathwayReferenceHelper.Type.sectionChildIDs].filter((itemID) => {
                return workflow.rawData[itemID] == valueState && itemID != 'noToAllHighRiskDifferentialDiagnosis' && itemID != 'noToAllSTEMI' && itemID != 'noToAllAbnormalities';
            })
        );
    }

    if (filteredItems.length == 0) { return (emptyString != null ? emptyString : ''); }

    return formatList(filteredItems, openingString, closingString, workflow);
}

function formatList(identifiers, openingString, closingString, workflow) {
    return openingString +
    identifiers.map((itemID) => {
        if(itemID == 'alternativeFinding'){
            return (
                'Alternative finding: (' + (workflow.rawData['alternativeFindingSpecify'] == null ? '[Alternative Finding]' : workflow.rawData['alternativeFindingSpecify']) + ')'
            );
        }
        return (PathwayReferenceHelper.reference(itemID, EDChestPainPathwayReference.data).title.includes(',') ? '\'' : '') + PathwayReferenceHelper.reference(itemID, EDChestPainPathwayReference.data).title + (PathwayReferenceHelper.reference(itemID, EDChestPainPathwayReference.data).title.includes(',') ? '\'' : '') + (itemID != identifiers[identifiers.length - 1] ? ', ' : '');
    }).join(' ') 
    + closingString + '\n';     
}

function generateStemi(workflow) {
    let totalString = '';
    let stemiPresent = workflow.rawData['stemiPresent'];

    if (stemiPresent == undefined) {
        return '[STEMI not specified]\n'; 
    } else if (stemiPresent != null && stemiPresent != undefined && stemiPresent) {
        totalString += 'STEMI: ';
        totalString += getItemsInSection(workflow, ['stemiCriteriaSection'], true, '', '');
        totalString += getItemsInSection(workflow, ['stemiCriteriaSection'], null, '[Not specified: ', ']');
        return totalString;
    } else {
        return 'Not STEMI\n';
    }
}

function generateAbnormalities(workflow) {
    let totalString = '';
    let abnormalitiesPresent = workflow.rawData['abnormalitiesPresent'];

    if (abnormalitiesPresent == undefined) { 
        return '[Other abnormalities not specified]\n';
    } else if (abnormalitiesPresent != null && abnormalitiesPresent) {
        totalString += 'Other abnormalities present: ';
        totalString += getItemsInSection(workflow, ['abnormalitiesCriteriaSection'], true, '', '');
        totalString += getItemsInSection(workflow, ['abnormalitiesCriteriaSection'], false, 'Not present: ', '');
        totalString += getItemsInSection(workflow, ['abnormalitiesCriteriaSection'], null, '[Not specified: ', ']');
        return totalString;
    } else {
        return 'No Other abnormalities\n';
    }
}

function getDisparateItemsAsList(workflow, identifiers, valueState, openingString, closingString) {
    let filteredItems = identifiers.filter((itemID) => {
        if (workflow.rawData[itemID + 'Override'] == true) {
            return workflow.rawData[itemID + 'OverrideValue'] == valueState;
        } else {
            return workflow.rawData[itemID] == valueState;
        }
    });

    if (filteredItems.length == 0) { return ''; }

    return formatList(filteredItems, openingString, closingString);
}

function getRedFlagOverrideReasons(workflow) {
    let redflags = [
        'aorticDissectionPresentRedFlag',
        'oesophagealRuptureRedFlag',
        'pulmonaryEmbolismRedFlag',
        'pancreatitisRedFlag',
        'pneumothoraxRedFlag',
        'newIschaemicChanges',
        'redFlagsOngoingChestPain',
        'crescendoAngina',
        'haemodynamicInstability'
    ];

    let overrideValues = redflags.filter((overrideID) => {
        if (workflow.rawData[overrideID + 'Override'] == true) {
            return true;
        }
        return null;
    }).filter(n => n);

    if(overrideValues.length == 0) { return '';}
    let totalString = 'Red Flag Override Reasons\n';
    totalString += overrideValues.map((redFlag) => {
        return PathwayReferenceHelper.reference(redFlag, EDChestPainPathwayReference.data).title + ': ' + (workflow.rawData[redFlag + 'OverrideReason'] != null ? workflow.rawData[redFlag + 'OverrideReason'] : getEmptyValue(redFlag + 'OverrideReason'));
    }).join('\n');
    return totalString;
}

function getCalculationBadge(formLoader, workflow, calculationType, identifier) {
    let mainForm = formLoader.form(workflow.pathwayUuid, 'mainForm');
    let error = EDChestPainCalculationHelper.calcualtionError(identifier, workflow.rawData, mainForm);
    
    if(error != null){
        return error.message;
    }

    let pathwayProgress = mainForm.completionProgress(['followUpBloodsDateAndTime', 'followUpBloodsTroponin']);
    if(pathwayProgress == null){
        return getEmptyValue(identifier);
    }

    if(((calculationType == CalculationModel.CalculationType.riskCategory) && (pathwayProgress.current / pathwayProgress.total) != 1)){
        return getEmptyValue(identifier);
    }

    let calculations = redux.store.getState().calculationReducer.calculations['chestPain01'];

    let calculation = EDChestPainCalculationHelper.getLatestCalculation(calculationType, calculations);
    if (calculation == null) { return getEmptyValue(identifier); }
    calculation = CalculationModel.rehydrate(calculation);
    return calculation.valueString + (calculation.rating != null ? ' (' + calculation.rating + ')' : '');
}

function getTotalEWS(workflow){
    let identifiers = [
        'heartRateEWS',
        'bloodPressureEWS',
        'bodyTemperatureEWS',
        'oxygenSaturationEWS',
        'respiratoryRateEWS',
        'supplementalOxygenEWS',
        'levelOfConsciousnessEWS'
    ];
    let categories = identifiers.map((identifier) => {
        let value = workflow.rawData[identifier.replace('EWS', '')];
        return calculateEWS(identifier, value);
    }).filter(n => n);

    // Only display an EWS if all categories are represented.
    if (categories.length < identifiers.length) {
        return null;
    }
    let declaredNormal = workflow.rawData['areSignsNormal'];
    let totalEWS = new TotalEWS(categories, declaredNormal);

    return totalEWS;
}

function getRedFlagsBadge(workflow) {
    let totalRedFlags = workflow.rawData['totalRedFlags'];
    if (totalRedFlags != null && totalRedFlags > 0) {
        return ' [' + totalRedFlags + ' RED FLAG' + (totalRedFlags == 1 ? '' : 'S') + ']';
    }
    return ('');
}

function getFormBadge(formLoader, pathwayId, identifier){
    let badges = formLoader.form(pathwayId, 'mainForm').getBadgeValue(identifier);
    if(badges.length > 0){
        let badgeStrings = [];
        for(let badge of badges){
            badgeStrings.push(' [' + badge.value + ']');
        }
        return badgeStrings.join('');
    }
    return '';
}