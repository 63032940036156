import { FormWithRedux } from '../../FormWithRedux';

export class GallbladderAbdominalExamForm extends FormWithRedux {

    constructor(formLoader) {
        super('abdominalExam', formLoader);
    }

    shouldDisplayFormDetailForIdentifier(identifier) {
        super.shouldDisplayFormDetailForIdentifier(identifier);

        if(identifier == 'abdominalExamAbnormalitiesSpecifiy'){
            let dependantValue = this.getValue('abdominalExamAbnormalities');
            return dependantValue != false;
        } else if(identifier == 'abdominalExamScarsSpecifiy'){
            let dependantValue = this.getValue('abdominalExamScars');
            return dependantValue != false;
        } 
        
        return true;
    }

    isEnabled(identifier) {
        if (identifier != null) {
            return true;
        }
        return false;
    }

    isMandatory() {
        return false;
    }

    getValueType(identifier) {
        if(identifier == 'abdominalExamAbnormalitiesSpecifiy' || identifier == 'abdominalExamScarsSpecifiy'){
            return 'string';
        }
        return 'boolean';
    }

    submissionValidation() {
        return [];
    }

    detailString(isSubform) {
        let formDetails = this.getFormDetails();
        let values = formDetails.map((value) => {

            if(value.identifier == 'abdominalExamNormal' && value.valueDescription() != null){
                return 'Abdominal exam normal';
            } else if(value.identifier == 'abdominalExamScarsSpecifiy'){
                return null;
            } else if(value.identifier == 'abdominalExamScars' && value.valueDescription() != null){
                let otherString = this.getValue('abdominalExamScarsSpecifiy');
                return value.valueDescription() + (otherString != null ? ': ' + otherString : '');
            } else if(value.identifier == 'abdominalExamAbnormalitiesSpecifiy'){
                return null;
            } else if(value.identifier == 'abdominalExamAbnormalities' && value.valueDescription() != null){
                let otherString = this.getValue('abdominalExamAbnormalitiesSpecifiy');
                return value.valueDescription() + (otherString != null ? ': ' + otherString : '');
            }

            return value.valueDescription() == null ? null : (isSubform ? '- ' : '') + value.valueDescription();
        });

        if(values.filter(n => n).length == 0) {
            return isSubform ? '' : this.detail;
        }

        return values.filter(n => n).join(',\n');
    }
}