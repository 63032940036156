import { FormWithRedux } from '../../FormWithRedux';


export class GPChestPainKeyClinicalFeaturesForm extends FormWithRedux {

    constructor(formIdentifier,formLoader, parent, actionUpdater, dependentValuesUpdater, calculationsUpdater) {
        super(formIdentifier, formLoader, parent, actionUpdater, dependentValuesUpdater, calculationsUpdater);
    }

    shouldDisplayFormDetailForIdentifier(identifier) {
        super.shouldDisplayFormDetailForIdentifier(identifier);
        if (identifier === 'followUpReason') {
            let isFollowUpScanDetail = this.generatedFormDetails['isThisFollowUpScan'];
            return isFollowUpScanDetail.currentValue == null ? false : isFollowUpScanDetail.currentValue;
        } else if (identifier == 'noToAllSymptomsAndSigns'){
            return this.unselectedExplicitFormDetailsCount(identifier) > 1;
        }

        return true;
    }

    isEnabled(identifier) {
        if (identifier != null) {
            return true;
        }
        return false;
    }

    isMandatory(identifier) {
        if(identifier == 'noToAllSymptomsAndSigns') {
            return false;
        } else if (identifier != null) {
            return true;
        }
        return false;
    }

    getValueType() {


        return 'boolean';
    }

    submissionValidation() {
        return [];
    }

    detailString(isSubform) {
        let formDetails = this.getFormDetails();
        let values = formDetails.map((value) => {
            return (value.valueDescription() == null || value.valueDescription() == false) ? null : (isSubform ? '- ' : '') + value.valueDescription() ;
        });

        if(values.filter(n => n).length == 0) {
            return isSubform ? '' : this.detail;
        }

        return values.filter(n => n).join(',\n');
    }

}