import { FormValidation } from '../../../Form/Validation/FormValidation';
import { FormValidationIdentifier } from '../../../Form/Validation/FormValidationIdentifier';
import { FormValidationError } from '../../../Form/Validation/FormValidationError';
import moment from 'moment';

FormValidationIdentifier.IsDate = 'IsDate';
FormValidationIdentifier.IsDateInPast = 'IsDateInPast';
FormValidationIdentifier.DateOfBirth = 'DateOfBirth';

let IsDate = new FormValidation(FormValidationIdentifier.IsDate, function (value) {
    if (!(value instanceof Date) && moment(value).toDate() == 'Invalid Date') {
        return [FormValidationError.RequiredError];
    }

    return [];
});

let IsDateInPastError = new FormValidation(FormValidationIdentifier.IsDateInPast, function (value) {
    if (!(value instanceof Date) && moment(value).toDate() == 'Invalid Date') {
        return [FormValidationError.RequiredError];
    }

    if (moment(value).isAfter()) {
        return [new FormValidationError(FormValidationError.FormErrorType.error, 'Date cannot be in the future')];
    }

    return [];
});

let IsDateInPastWarning = new FormValidation(FormValidationIdentifier.IsDateInPast, function (value) {
    if (!(value instanceof Date) && moment(value).toDate() == 'Invalid Date') {
        return [FormValidationError.RequiredError];
    }

    if (moment(value).isAfter()) {
        return [new FormValidationError(FormValidationError.FormErrorType.warning, 'Date is in the future')];
    }

    return [];
});

let DateOfBirth = new FormValidation(FormValidationIdentifier.DateOfBirth, function (value) {
    if (!(value instanceof Date) && moment(value).toDate() == 'Invalid Date') {
        return [FormValidationError.RequiredError];
    }
    
    if (moment(value).isAfter()) {
        return [new FormValidationError(FormValidationError.FormErrorType.warning, 'Date expected to be in the past')];
    }

    var years = moment().diff(moment(value), 'years');

    if (years > 200) {
        return [new FormValidationError(FormValidationError.FormErrorType.warning, 'Date is over 200 years ago')];
    }

    return [];
});

export { IsDate, IsDateInPastError, IsDateInPastWarning, DateOfBirth }; 