import { FormWithRedux } from '../../FormWithRedux';
import { GPChestPainRedFlagsForm } from './GPChestPain-RedFlagsForm';
import { CalculationModel } from '../Custom Types/Calculation';
import { RiskCategoryBrackets } from '../Custom Types/Risk Category';
import { redux } from '../../../App';
import { saveCalculation } from '../../../components/state/actions/CalculationActions';
import { FormsStyles } from '../../../waferJS/FormsUI/FormsUI';
import { GPChestPainCalculationHelper } from '../Helpers/GPChestPainCalculationHelper';
import { saveAction } from '../../../components/state/actions/ActionActions';
import { GPChestPainProbabilityOfMACEGraphForm } from './GPChestPain-ProbabilityOfMACEGraphForm';
import { PathwayReferenceHelper } from '../../PathwayReferenceHelper';
import { PathwayFormatter } from '../../PathwayFormatter';
import { GPChestPainHighRiskDifferentialDiagnosisResultsForm } from './GPChestPain-HighRiskDifferentialDiagnosisResultsForm';
import { markWorkFlowAsExported } from '../../../components/state/actions/WorkflowResponseActions';
import { getPathwayIdFromURL } from '../../../helpers/URLHelper';

export class GPChestPainResultsForm extends FormWithRedux {

    set calculations(value) {
        this._calculations= JSON.parse(JSON.stringify(value));
    }

    get calculations() {
        return this._calculations;
    }

    set actions(value) {
        this._actions = JSON.parse(JSON.stringify(value));
    }

    get actions() {
        return this._actions;
    }

    constructor(formLoader, parent, actionsUpdater, dependenValuesUpdater, calculationsUpdater) {
        super('resultsForm', formLoader, parent, actionsUpdater, dependenValuesUpdater, calculationsUpdater);

        let self = this;
        redux.store.subscribe(() => {
            let state = redux.store.getState();
            self.calculations = state.calculationReducer.calculations;
            self.actions = redux.store.getState().actionsReducer.actions;
        });

        this.calculations = redux.store.getState().calculationReducer.calculations;

        this.actions = redux.store.getState().actionsReducer.actions;
    }

    generateSubform(identifier) {
        switch (identifier) {
            case 'redFlags':
                return new GPChestPainRedFlagsForm(this.formLoader, this.parent, this.actionUpdater, this.dependentValuesUpdater, this.calculationsUpdater);
            case 'highRiskDifferentialDiagnosisResults':
                return new GPChestPainHighRiskDifferentialDiagnosisResultsForm(this.formLoader, this.parent, this.actionUpdater, this.dependentValuesUpdater, this.calculationsUpdater);
            case 'probabilityOfMACEGraphForm':
                return new GPChestPainProbabilityOfMACEGraphForm(this.formLoader);
        }
    }

    shouldDisplayFormDetailForIdentifier(identifier) {
        super.shouldDisplayFormDetailForIdentifier(identifier);

        if (identifier == 'exportRequestOutcome' || identifier == 'additionalRequestInformation') {
            return this.isComplete();
        } else if (identifier == 'additionalInformationIncompleteForm') {
            return !this.isComplete();
        }
        
        let formDetail = this.formDetail(identifier);
        if(formDetail.controlType == 'action'){
            if(this.actions[this.pathwayId] != null){
                let pathwayActions = this.actions[this.pathwayId];
                if(pathwayActions == null || JSON.stringify(pathwayActions) == JSON.stringify({})){
                    return null;
                }
                return pathwayActions.filter((action) => {return action.identifier == identifier;}).length > 0;
            }
            return false;
        } else if(identifier == 'probabilityOfMACEGraphForm'){
            return this.getValue('probabilityOfMACE') != null;
        }

        return true;
    }

    isEnabled(identifier) {
        if (identifier != null) {
            return true;
        }
        return false;
    }

    isMandatory() {
        return false;
    }

    isComplete(){
        let form = this.formLoader.form(this.pathwayId, 'mainForm');
        let progress = form.completionProgress();

        if(progress != null){
            return (progress.current / progress.total) == 1;
        }
        return false;
    }

    getValueType(identifier) {
        if(identifier == 'additionalRequestInformation'){
            return 'string';
        }
        return null;
    }

    getBadgeValue(identifier) {
        if(identifier == 'calculatedEDACS'){
            let calculation = GPChestPainCalculationHelper.getLatestCalculation(CalculationModel.CalculationType.edacs, this.calculations[this.pathwayId]);
            
            if(calculation != null){
                return [{
                    color: calculation.badgeColor,
                    value: calculation.valueString,
                    solid: calculation.badgeStyle
                }];
            }
        } else if(identifier == 'probabilityOfMACE'){
            let calculation = GPChestPainCalculationHelper.getLatestCalculation(CalculationModel.CalculationType.probabilityOfMace, this.calculations[this.pathwayId]);
            if(calculation != null){
                return [{
                    color: calculation.badgeColor,
                    value: calculation.valueString,
                    solid: calculation.badgeStyle
                }];
            }
        } else if(identifier == 'pathwayRiskCategory'){
            let calculation = GPChestPainCalculationHelper.getLatestCalculation(CalculationModel.CalculationType.riskCategory, this.calculations[this.pathwayId]);
            if(calculation != null){
                if (calculation.value != null) {
                    let riskBracket = new RiskCategoryBrackets(calculation.value);
                    if(riskBracket != null){
                        return [{
                            color: riskBracket.riskCategory.color,
                            value: riskBracket.riskCategory.title != null ? riskBracket.riskCategory.title : 'N/A',
                            solid: true
                        }];
                    } 
                }
            }
            return[{
                color: FormsStyles.black,
                value: null,
                solid: false
            }];
        } else if(identifier == 'redFlags'){
            let total = this.getValue('totalRedFlags');
            if (total > 0){
                return[{
                    color: FormsStyles.red,
                    value: total.toString() + ' ALERT' + (total == 1 ? '' : 'S'),
                    solid: true
                }];
            }
        } else if(identifier == 'resultsForm'){

            let probabilityOfMace = GPChestPainCalculationHelper.getLatestCalculation(CalculationModel.CalculationType.probabilityOfMace, this.calculations[this.pathwayId]);
            let riskCategory = GPChestPainCalculationHelper.getLatestCalculation(CalculationModel.CalculationType.riskCategory, this.calculations[this.pathwayId]);
            let edacs = GPChestPainCalculationHelper.getLatestCalculation(CalculationModel.CalculationType.edacs, this.calculations[this.pathwayId]);
            let calculations = [probabilityOfMace, riskCategory, edacs].filter(n => n);

            return GPChestPainCalculationHelper.getHighestPriorityCalculationBadges(calculations);
        } else if(identifier == 'accuteCardiologyAssessmentRequest'){
            return[{
                color: FormsStyles.red,
                value: 'HIGH RISK',
                solid: true
            }];
        } else if(identifier == 'stemiAction'){
            return[{
                color: FormsStyles.red,
                value: 'STEMI',
                solid: true
            }];
        } else if(identifier == 'highRiskDifferentialDiagnosisResults'){
            let identifiers = {
                'aorticDissectionResults' : 'aorticDissectionPresent',
                'pulmonaryEmbolismResults': 'pulmonaryEmbolism',
                'pancreatitisResults' : 'pancreatitis',
                'pericarditisResults': 'pericarditis',
                'pneumothoraxResults': 'pneumothorax'
            };
    
            let count = 0;
            for(let item of Object.keys(identifiers)){
                if (this.getValue(identifiers[item]) == true){
                    count += 1;
                }
            }
            if (count > 0){
                return[{
                    color: FormsStyles.red,
                    value: count.toString() + ' ALERT' + (count == 1 ? '' : 'S'),
                    solid: true
                }];
            }
        } else if(identifier == 'abnormalVitalSigns' || identifier == 'clinicalInstability' || identifier == 'clinicalEmergencyDeterioration' || identifier == 'clinicalEmergency') {
            let vitalsProgress = (this.formLoader.form(this.pathwayId, 'vitalSigns')).completionProgress();
            if(vitalsProgress.current / vitalsProgress.total == 1){
                let totalEWS = (this.formLoader.form(this.pathwayId, 'vitalSigns')).totalEWS();
                if (totalEWS != null) {
                    return [{
                        color: totalEWS.color,
                        value: totalEWS.title,
                        solid: totalEWS.solid
                    }];
                }
            }
            return [];
        }
        return [];
    }

    

    submissionValidation() {
        return [];
    }

    detailString(isSubcell, isPlainText) {
        if(isPlainText){
            return this.getPlainText();
        }
        let formDetails = this.getFormDetails();
        let values = formDetails.map((value) => {
            return this.getSummaryValue(isSubcell, value);
        });

        if(values.filter(n => n).length == 0){
            return isPlainText ? '' : this.detail;
        }
        
        return values.filter(n => n).join(isPlainText ? ', ' : ',\n');
    }

    getSummaryValue(isSubcell, value){
        return value.valueDescription();
    }

    calculationError(identifier){
        let data = null;
        if(this.workflow[this.pathwayId] != null){
            data = this.workflow[this.pathwayId].rawData;
        }
        return GPChestPainCalculationHelper.calcualtionError(identifier, data, this);
    }

    latestCalculation(identifier){
        return GPChestPainCalculationHelper.getLatestCalculation(this.getTypeFromIdentifier(identifier), this.calculations[this.pathwayId]);
    }

    previousCalculation(identifier){
        return GPChestPainCalculationHelper.getPreviousCalculation(this.getTypeFromIdentifier(identifier), this.calculations[this.pathwayId]);
    }

    markCalculationAsObserved(identifier){
        let calculation = this.latestCalculation(identifier);
        if(calculation != null){
            calculation.observed = true;
            redux.store.dispatch(saveCalculation(getPathwayIdFromURL(), calculation, this.calculations));
        }
    }

    markActionAsObserved(identifier){
        if(this.actions[this.pathwayId] == null ){ return; }
        let matchingActions = this.actions[this.pathwayId].filter((action) => {
            return action.identifier == identifier;
        });

        let filteredActions = this.actions[this.pathwayId].filter((action) => {
            return action.identifier != identifier;
        });

        if (matchingActions.length == 0 ) { return;}

        let action = matchingActions[0];

        if(!action.removeAfterObservation){
            action.observed = true;
            filteredActions.push(action);
        }

        redux.store.dispatch(saveAction(this.pathwayId, filteredActions));
    }

    calculationProgress(identifier){
        if(CalculationModel.typeRequiresPrimaryFormValidation(this.getTypeFromIdentifier(identifier))){
            let form = this.formLoader.form(this.pathwayId , 'mainForm');
            let progress = form.completionProgress(['followUpBloodsDateAndTime', 'followUpBloodsTroponin']);
            if(progress != null){
                return (progress.current / progress.total);
            }
            return 0;
        } else if(identifier == 'calculatedEDACS'){
            let requiredIdentifiers = [
                'coronaryHeartDisease', 'coronaryArteryStenosis',
                'prematureCoronaryHeartDisease', 'diabetes', 'hypertension', 'dyslipidaemia', 'currentSmoker',
                'diaphoresis', 'pleuriticPain', 'painRadiates', 'painByPalpation', 'patientAge', 'patientSex'
            ];

            let missingCount = requiredIdentifiers.map((identifier) => {
                return this.getValue(identifier) != null ? true : null;
            }).filter(n => n).length;
            return (missingCount / requiredIdentifiers.length);
        }
        return 1;
    }

    getTypeFromIdentifier(identifier){
        if(identifier == 'calculatedEDACS'){
            return CalculationModel.CalculationType.edacs;
        } else if(identifier == 'probabilityOfMACE'){
            return CalculationModel.CalculationType.probabilityOfMace;
        } else if(identifier == 'pathwayRiskCategory'){
            return CalculationModel.CalculationType.riskCategory;
        }
    }

    actionForIdentifier(identifier){
        if(this.actions[this.pathwayId] != null ){
            let filteredAction = this.actions[this.pathwayId].filter((action) => {return action.identifier == identifier;});
            if(filteredAction.length > 0){
                return filteredAction[0];
            }
        }
        
        return null;
    }

    getPlainTextActions(showTitle, identifiers){
        let sectionStrings = this.sectionIds.map((sectionID) => {
            let section = this.sectionReference(sectionID);
            var sectionString = '';
            
            let sectionValues = [];
            if(section[PathwayReferenceHelper.Type.sectionChildIDs] != undefined){
                section[PathwayReferenceHelper.Type.sectionChildIDs].map((childID) => {
                    if(!identifiers.includes(childID) && identifiers.length > 0 ) { return; }
                    let formDetail = this.formDetail(childID);
                    if(formDetail.controlType == 'action'){
                        let action = this.actionForIdentifier(formDetail.identifier);
                        if(action != null){
                            let actionStringComponents = [];
                            action.title != null ? actionStringComponents.push(this.stripSpecialChars(action.title)) : actionStringComponents.push(this.stripSpecialChars(formDetail.title));
                            action.detail != null ? actionStringComponents.push(this.stripSpecialChars(action.detail)) : actionStringComponents.push(this.stripSpecialChars(formDetail.detail));
                            sectionValues.push(actionStringComponents.filter(n => n).join('\n'));
                        }
                    }
                });

                if(sectionValues.length > 0 ){
                    if(section.title != null && showTitle){
                        sectionString += section.title.toUpperCase() + ': \n';
                    }
                    sectionString += sectionValues.join('\n\n');
                    sectionString += '\n\n';
                }
            }
            return sectionString == '' ? null : sectionString;
        }).filter( n => n);

        return sectionStrings.join('\n\n');
    }

    stripSpecialChars(string){
        if(string == null || string == undefined) { return string; }
        if(!string.includes('#@')){ return string; } 
        let components = string.split('#@');
        let strippedString = '';
        for(let component of components){
            if(component.includes('^')){
                let subComponents = component.split('^');
                strippedString += subComponents[1];
            } else {
                strippedString += component;
            }
        }
        return strippedString;
    }

    getCopyText(){
        return PathwayFormatter.formatAsPlainText(this.formLoader, this.pathwayId, this.workflow[this.pathwayId], this.actions[this.pathwayId]);
    }

    markAsExported(){
        redux.store.dispatch(markWorkFlowAsExported(this.pathwayId, this.workflow[this.pathwayId]));
    }
}