import { FormWithRedux } from '../../FormWithRedux';
import { GPChestPainIntroForm } from '../Forms/GPChestPain-IntroForm';
import { GPChestPainNotesForm } from '../Forms/GPChestPain-NotesForm';
import { GPChestPainResultsForm } from '../Forms/GPChestPain-ResultsForm';
import { GPChestPainVitalSignsForm } from './GPChestPain-VitalSignsForm';
import { GPChestPainECGForm } from './GPChestPain-ECGForm';
import { GPChestPainBloodsForm } from './GPChestPain-BloodsForm';
import { GPChestPainHighRiskDifferentialDiagnosisForm } from './GPChestPain-HighRiskDifferentialDiagnosisForm';
import { GPChestPainClinicalAssessmentForm } from './GPChestPain-ClinicalAssessmentForm';
import { GPChestPainPatientDetailsForm } from './GPChestPain-PatientDetailsForm';
import { FormsStyles } from '../../../waferJS/FormsUI/FormsUI';
import { TroponinResult } from '../Custom Types/Troponin';
import { FormValidation } from '../../../waferJS/Form/Validation/FormValidation';
import { FormValidationError } from '../../../waferJS/Form/Validation/FormValidationError';
import { redux } from '../../../App';
import { ActionModel } from '../../Shared Custom Types/ActionModel';
import { ENVIRONMENT } from '../../../config/config';
import { GPChestPainActions } from '../Calculations/GPChestPain-Actions';
import { GPChestPainDependantValuesUpdater } from '../Calculations/GPChestPain-DependantValuesUpdater';
import { GPChestPainCalculations } from '../Calculations/GPChestPain-Calculations';

export class GPChestPainMainForm extends FormWithRedux {

    constructor(formLoader) {
        super('mainForm', formLoader, null, new GPChestPainActions(formLoader), new GPChestPainDependantValuesUpdater(formLoader), new GPChestPainCalculations());
    }

    generateSubform(identifier) {
        switch (identifier) {
            case 'introForm':
                return new GPChestPainIntroForm(this.formLoader);
            case 'notesForm':
                return new GPChestPainNotesForm(this.formLoader);
            case 'resultsForm':
                return new GPChestPainResultsForm(this.formLoader, this, this.actionUpdater, this.dependentValuesUpdater, this.calculationsUpdater);
            case 'vitalSigns':
                return new GPChestPainVitalSignsForm(this.formLoader, this, this.actionUpdater, this.dependentValuesUpdater, this.calculationsUpdater);
            case 'ecgTest':
                return new GPChestPainECGForm(this.formLoader, this, this.actionUpdater, this.dependentValuesUpdater, this.calculationsUpdater);
            case 'initialBloods':
                return new GPChestPainBloodsForm(this.formLoader, this, this.actionUpdater, this.dependentValuesUpdater, this.calculationsUpdater);
            case 'highRiskDifferentialDiagnosis':
                return new GPChestPainHighRiskDifferentialDiagnosisForm(this.formLoader, this, this.actionUpdater, this.dependentValuesUpdater, this.calculationsUpdater);
            case 'initialAssessment':
                return new GPChestPainClinicalAssessmentForm(this.formLoader, this, this.actionUpdater, this.dependentValuesUpdater, this.calculationsUpdater);
            case 'patientDetails':
                return new GPChestPainPatientDetailsForm(this.formLoader, this, this.actionUpdater, this.dependentValuesUpdater, this.calculationsUpdater);
        }
    }

    shouldDisplayFormDetailForIdentifier(identifier) {
        super.shouldDisplayFormDetailForIdentifier(identifier);
        return true;
    }

    isEnabled() {
        return false;
    }

    isMandatory() {
        return false;
    }

    getValueType() {
        return 'boolean';
    }

    editingValidation(identifier){
        if (identifier == 'initialBloods'){
            let self = this;
            let scheduledActionPassed = new FormValidation('FollowUpBloodsAction', function () {
                let pathwayActions = redux.store.getState().actionsReducer.actions[self.pathwayId];
                if(pathwayActions != null){
                    let action = pathwayActions.filter((action) => { return action.identifier == 'nextTroponinAction';})[0];
                    if(action != null && action.scheduledAction != null){
                        let actionModel = new ActionModel(action.pathwayUuid, action.versionUuid, action.identifier, action.title, action.detail, action.priority, action.observed, action.removeAfterObservation, action.lastModifiedDate, action.uuid, action.scheduledAction);

                        let string = actionModel.scheduledActionDetailString(false);
                        if(string != null){
                            return [new FormValidationError(FormValidationError.FormErrorType.warning, string)];
                        }
                        return [];
                    }
                }
            });
                
            return [scheduledActionPassed];
        }

        return [];
    }

    submissionValidation() {
        return [];
    }

    detailString() {
        return 'summary';
    }

    getBadgeValue(identifier) {
        let subform = this.subform(identifier);
        let completionProgress = subform.completionProgress();
        let subformCompletion = (completionProgress.current / completionProgress.total) == 1;

        switch (identifier) {
            case 'vitalSigns': {
                let vitalsProgress = subform.completionProgress();
                if(vitalsProgress.current / vitalsProgress.total == 1){
                    let totalEWS = subform.totalEWS();
                    if (totalEWS != null) {
                        return [{
                            color: totalEWS.color,
                            value: totalEWS.title,
                            solid: totalEWS.solid
                        }];
                    }
                }
                
                return [];
            }
            case 'ecgTest': {
                let ecgBadges = [];
                let stemiPresentValue = subform.formDetail('stemiPresent').currentValue;
                if (stemiPresentValue != null && stemiPresentValue && subformCompletion) {
                    ecgBadges.push({
                        color: FormsStyles.red,
                        value: 'STEMI',
                        solid: true
                    });
                }

                let abnormalitiesPresentValue = subform.formDetail('abnormalitiesPresent').currentValue;
                if (abnormalitiesPresentValue != null && abnormalitiesPresentValue && subformCompletion) {
                    ecgBadges.push({
                        color: FormsStyles.orange, //FormsStyles.red,
                        value: 'ABNORMAL',
                        solid: true
                    });
                }

                if (ecgBadges.length == 0 && subform.areAllCurrentValuesValid() && subformCompletion) {
                    ecgBadges.push({
                        color: FormsStyles.grey,
                        value: 'NORMAL',
                        solid: false
                    });
                }
                return ecgBadges;
            }
            case 'initialBloods': {
                let gender = this.getValue('patientSexInt');
                if (gender != null && subformCompletion) {
                    let troponinResult = this.getValue('initialBloodsTroponin');
                    let folowUpTroponinResult = this.getValue('followUpBloodsTroponin');
                    let troponinStatus;
                    let followUpTroponinStatus;
                    if (troponinResult != null) {
                        let troponin = TroponinResult.newTroponinResult(troponinResult);
                        troponinStatus = troponin.getStatus(gender);
                    }
                    
                    if (folowUpTroponinResult != null) {
                        let troponin = TroponinResult.newTroponinResult(folowUpTroponinResult);
                        followUpTroponinStatus = troponin.getStatus(gender);
                    }

                    if(followUpTroponinStatus != null && (troponinStatus == null || troponinStatus != TroponinResult.Status.aboveCutoff)){
                        let troponin = TroponinResult.newTroponinResult(TroponinResult.Status.aboveCutoff);
                        return [{
                            color: followUpTroponinStatus == TroponinResult.Status.aboveCutoff ? FormsStyles.red : FormsStyles.grey,
                            value: troponin.statusTitle(followUpTroponinStatus).toUpperCase(),
                            solid: followUpTroponinStatus == TroponinResult.Status.aboveCutoff && ENVIRONMENT == 'DEV'
                        }];
                    } else if(troponinStatus != null && (followUpTroponinStatus == null || troponinStatus != TroponinResult.Status.aboveCutoff)){
                        let troponin = TroponinResult.newTroponinResult(troponinResult);
                        return [{
                            color: troponinStatus == TroponinResult.Status.aboveCutoff ? FormsStyles.red : FormsStyles.grey,
                            value: troponin.statusTitle(troponinStatus).toUpperCase(),
                            solid: troponinStatus == TroponinResult.Status.aboveCutoff && ENVIRONMENT == 'DEV'
                        }];
                    }
                }
                return [];
            }
        }
    }
}