import { FormContentView } from '../../FormsUI/FormsUI Components/Containers/FormContentView';
import { FormsStyles } from '../../FormsUI/FormsUI';
import React from 'react';
import PropTypes from 'prop-types';
import { FormButton } from '../../FormsUI/FormsUI Components/Controls/FormButton';


export class ExportFormDetail extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            showCopySucess: false
        };

        this.copyText = this.copyText.bind(this);
        this.removeSuccessLabel = this.removeSuccessLabel.bind(this);
    }

    copyText() {
        this.node.select();
        document.execCommand('copy');
        this.setState({showCopySucess: true});
        this.resetTimeout = setTimeout(this.removeSuccessLabel, 1900);
        
        this.props.formDetail.form.markAsExported();
    }

    removeSuccessLabel(){
        this.setState({showCopySucess: false});
    }

    render() {

        let promptButton = 
        <div className="form-detail-export-bar">
            <div className="plain-text-container">
                <FormButton title={'Copy as Plain Text'} color={this.state.showCopySucess ? FormsStyles.solidBackgroundColor : FormsStyles.primaryTint} enabled={true} isSelected={false} onPress={this.copyText}/> 
                <label className={'copy-success ' + (this.state.showCopySucess ? 'show-success' : '') }>Copied to Clipboard</label>
                <textarea className={'summary-plaintext-input'} rows={1} value={this.props.formDetail.form.getCopyText()} readOnly ref={node => this.node = node} />
            </div>
        </div>;

        return (
            <FormContentView identifier={this.props.formDetail.identifier} isSubcell={this.props.isSubcell} titleString={this.props.formDetail.title} detailString={this.props.formDetail.detail} bottomControl={promptButton} subcell={[]} subCellbackgroundColor={FormsStyles.backgroundColor} showHover={true}/>
        );
    }
}

ExportFormDetail.propTypes = {
    formDetail: PropTypes.object.isRequired,
    isSubcell: PropTypes.bool.isRequired,
    renderErrors: PropTypes.func.isRequired,
    renderSubcells: PropTypes.func.isRequired,
    errorLabels: PropTypes.array.isRequired,
    hasError: PropTypes.bool.isRequired
};