export const SAVE_CALCULATION_PENDING = 'SAVE_CALCULATION_PENDING';
export const SAVE_CALCULATION_FULFILLED = 'SAVE_CALCULATION_FULFILLED';
export const SAVE_CALCULATION_FAILED = 'SAVE_CALCULATION_FAILED';

export const CALCULATIONS_UPDATED = 'CALCULATIONS_UPDATED';

export const LAST_OBSERVED_CALCULATIONS_UPDATED = 'LAST_OBSERVED_CALCULATIONS_UPDATED';
export const CLEAR_OBSERVED_CALCULATIONS = 'CLEAR_OBSERVED_CALCULATIONS';

export const CLEAR_CALCULATIONS_FULFILLED = 'CLEAR_CALCULATIONS_FULFILLED';

const saveCalculation = (pathwayId, newCalculation, calculations) => (dispatch) => {

    dispatch({
        type: SAVE_CALCULATION_PENDING
    });

    let calculation = {};
    let pathwayCalculations = {};
    if(calculations[pathwayId] != null) {
        pathwayCalculations = calculations[pathwayId];
        let exisitingCalculation = calculations[pathwayId][newCalculation.uuid];
        if (exisitingCalculation != null) {
            calculation = exisitingCalculation;
        }
    }

    calculation['uuid'] = newCalculation.uuid;

    calculation['pathwayUuid'] = newCalculation.pathwayUuid;
    calculation['versionUuid'] = newCalculation.versionUuid;
    calculation['value'] = newCalculation.value;

    calculation['observed'] = newCalculation.observed;
    calculation['type'] = newCalculation.type;

    calculation['lastModifiedDate'] = newCalculation.lastModifiedDate;

    pathwayCalculations[newCalculation.uuid] = calculation;

    dispatch({
        type: SAVE_CALCULATION_FULFILLED,
        payload: {
            pathwayId: pathwayId,
            calculations: pathwayCalculations
        }
    });

};

const snapshotObservedCalculations = (pathwayId, calculations) => (dispatch) => {
    dispatch({
        type: LAST_OBSERVED_CALCULATIONS_UPDATED,
        payload: {
            pathwayId: pathwayId,
            lastObservedCalculations: calculations
        }
    });
};


const clearSnapshotCalculations = (pathwayId) => (dispatch) => {
    dispatch({
        type: CLEAR_OBSERVED_CALCULATIONS,
        payload: {
            pathwayId: pathwayId,
            calculations: []
        }
    });
};

const updateCalculations = (pathwayId, workflow, calculations, form) => (dispatch) => {
    // Refer to mainForm or the parent of the form (mainForm)
    let mainForm = form.parent != null ? form.parent : form;
    
    mainForm.calculationsUpdater.update(pathwayId, workflow, calculations, form);
    
    dispatch({
        type: CALCULATIONS_UPDATED
    });
};

const clearCalculations = (pathwayId) => (dispatch) => {
    dispatch({
        type: CLEAR_CALCULATIONS_FULFILLED,
        payload: {
            pathwayId: pathwayId,
            calculations: {}
        }
    });
};

export { saveCalculation, clearCalculations, updateCalculations, snapshotObservedCalculations, clearSnapshotCalculations };