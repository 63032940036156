import React, { useEffect } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { Box } from "@mui/material";
import { ENABLED_PATHWAYS } from "../../config/config";

export interface AuthServerError {
  error: string;
  description: string;
}

interface AuthHandlerProp {
  handleError: (err: AuthServerError) => void;
}

const AuthHandler: React.FC<AuthHandlerProp> = ({ handleError }) => {
  const [searchParams] = useSearchParams();
  const { hash } = useLocation();

  const navigate = useNavigate();

  useEffect(() => {
    // This will happen when the auth server returns error - the params will be provided by hash
    if (hash) {
      const urlParams = new URLSearchParams(hash.substring(1));
      const error = urlParams.get("error");
      const description = urlParams.get("error_description");
      if (error) {
        console.log(error + "\r\n" + description);
        handleError({
          error: error,
          description: description ?? "",
        });
        // Jump to un-defined page to display auth-error
        navigate("/auth-error", { replace: true });
      }
    }

    // This will happen by update login user account flow starting from Landing page
    // The state param was set the pathway before jumping to update page
    const previousPathway = searchParams.get("state");
    if (previousPathway && ENABLED_PATHWAYS?.includes(previousPathway)) {
      navigate("/" + previousPathway, { replace: true });
    }
  }, []);

  return <Box />;
};

export default AuthHandler;
