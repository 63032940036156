import { FormWithRedux } from '../../FormWithRedux';
import { DateOfBirth } from '../../../waferJS/Form To FormsUI/Subclasses/Validation Overrides/DateTimeValidation';
import { FormValidation } from '../../../waferJS/Form/Validation/FormValidation';
import { FormValidationError } from '../../../waferJS/Form/Validation/FormValidationError';
import { NHINumber } from '../../Shared Custom Types/NHI';
import { ENABLED_EXPORTS } from '../../../config/config';

export class EDChestPainPatientDetailsForm extends FormWithRedux {

    constructor(formLoader, parent, actionUpdater, dependentValuesUpdater, calculationsUpdater) {
        super('patientDetails', formLoader, parent, actionUpdater, dependentValuesUpdater, calculationsUpdater);
    }

    shouldDisplayFormDetailForIdentifier(identifier) {
        super.shouldDisplayFormDetailForIdentifier(identifier);

        if (identifier == 'patientNHINumber') {
            return ENABLED_EXPORTS.includes('ecpp');
        } else if(identifier == 'patientDateOfBirth'){
            return this.getValue(identifier) != null || this.getValue('patientAge') == null;
        } else if(identifier == 'patientSexIssue'){
            return this.getValue('patientSex') == 'genderOtherOption';
        }
        return true;
    }

    isEnabled() {
        return true;
    }

    isMandatory(identifier) {
        if (identifier == 'patientNHINumber') {
            return false;
        } else if (identifier == 'patientDOB') {
            return false;
        } else if (identifier == 'patientGender') {
            return false;
        }
        return true;
    }

    getValueType(identifier) {
        if (identifier == 'patientDateOfBirth') {
            return 'date';
        } else if (identifier == 'patientSex') {
            return 'string';
        } else if(identifier == 'patientAge'){
            return 'double';
        } else if (identifier == 'patientNHINumber' && ENABLED_EXPORTS.includes('ecpp')) {
            return 'string';
        } else if (identifier == 'patientName') {
            return 'string';
        } else if (identifier == 'patientEthnicity') {
            return 'string';
        } else if (identifier == 'patientNHI') {
            return 'string';
        } else if (identifier == 'patientGender') {
            return 'string';
        }
        return null;
    }

    submissionValidation() {
        return [];
    }

    editingValidation(identifier) {
        if (identifier == 'patientDateOfBirth') {
            return [DateOfBirth];
        } else if(identifier == 'patientNHINumber'){
            let isValidNHI = new FormValidation('NHIValid', function (value) {
                if(value == null){
                    return [FormValidationError.RequiredError];
                }

                if (NHINumber.isTest(value)) {
                    return [];
                }

                let nhi = NHINumber.construct(value);
                if(nhi == null) {
                    return [new FormValidationError(FormValidationError.FormErrorType.error, 'NHI Number invalid')];
                }
                return [];
            });

            let isTestNHI = new FormValidation('NHITestValue', function (value) {
                
                if (NHINumber.isTest(value)) {
                    return [new FormValidationError(FormValidationError.FormErrorType.warning, 'Test NHI value detected')];
                }
                return [];
            });

            return [isValidNHI, isTestNHI];
        }
        return [];
    }

    detailString() {
        let formDetails = this.getFormDetails();
        let values = formDetails.map((value) => {
            if (value.identifier == 'patientAge' && value.valueDescription() != null){
                if (value.currentValue != null) {
                    return 'Age: ' + this.getValue('patientAge') + ' year' + (this.getValue('patientAge') == 1 ? '' : 's');
                }
            }
            return value.valueDescription();
        });

        if(values.filter(n => n).length == 0){
            return this.detail;
        }
        
        return values.filter(n => n).join(',\n');
    }
}