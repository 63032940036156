import moment from 'moment';
import { redux } from '../../../App';
import { PatientSex } from '../../Shared Custom Types/Gender';
import { TotalEWS, calculateEWS } from '../Custom Types/EWS'; 
import { postResultsBadge } from '../../../components/state/actions/NotificationsActions';
import { saveWorkflow } from '../../../components/state/actions/WorkflowResponseActions';
import { removeResultsAlertValue } from '../../../components/state/actions/NotificationsActions';

export class GPChestPainDependantValuesUpdater {

    constructor(formLoader) {
        this.formLoader = formLoader;
    }

    update(pathwayId, workflow, identifier, value, iterationIndex){
        updateDependentValues(this.formLoader, pathwayId, workflow, identifier, value, iterationIndex);
    }

    logObservedValues(pathwayId, workflow, form){
        redux.store.dispatch(saveWorkflow(pathwayId, {'lastObservedTotalRedFlags': workflow.rawData['totalRedFlags']}, workflow, false, form, 0));
    }
}

function updateDependentValues(formLoader, pathwayId, workflow, identifier, value, iterationIndex) {
    var rawData = workflow.rawData;
    var changedValues = {};

    switch (identifier) {
        case 'newIschaemia':
        case 'oldIschaemia':
        case 'oldLBBB':
        case 'nonSpecificSTorTChanges':
        case 'alternativeFinding': {
            let dependentValues = [rawData['newIschaemia'], rawData['oldIschaemia'], rawData['oldLBBB'], rawData['nonSpecificSTorTChanges'], rawData['alternativeFinding']];

            if (identifier == 'alternativeFinding' && (value == null || !value)) {
                changedValues['alternativeFindingSpecify'] = null;
            }

            if (rawData['abnormalitiesPresent'] == null || rawData['abnormalitiesPresent'] == undefined) {

                let allCriteriaAnswered = !dependentValues.includes(null) && !dependentValues.includes(undefined);

                if (allCriteriaAnswered) {
                    changedValues['abnormalitiesPresent'] = dependentValues.includes(true);
                }
            }

            break;
        }
        case 'abnormalitiesPresent': {
            //TODO: Verify this logic!
            if (rawData['newIschaemia'] == null || rawData['newIschaemia'] == undefined) {
                if (value == false) {
                    changedValues['newIschaemicChanges'] = false;
                } else if (value == null) {
                    if (rawData['newIschaemicChanges'] == false) {
                        changedValues['newIschaemicChanges'] = null;
                    }
                }
            }
            
            break;
        }
        case 'stemiCriteriaV4toV6':
        case 'stemiCriteriaV1toV3':
        case 'stemiCriteriaV7toV9':
        case 'stemiCriteriaNewLBBB':
        case 'stemiCriteriaLBBBWithPositiveQRS':
        case 'stemiCriteriaLBBBWithNegativeQRS': {
            let dependentValues = [rawData['stemiCriteriaV4toV6'], rawData['stemiCriteriaV1toV3'], rawData['stemiCriteriaV7toV9'], rawData['stemiCriteriaNewLBBB'], rawData['stemiCriteriaLBBBWithPositiveQRS'], rawData['stemiCriteriaLBBBWithNegativeQRS']];

            if (rawData['stemiPresent'] == null || rawData['stemiPresent'] == undefined) {

                let allCriteriaAnswered = !dependentValues.includes(null) && !dependentValues.includes(undefined);

                if (allCriteriaAnswered) {
                    changedValues['stemiPresent'] = dependentValues.includes(true);
                }
                
            }
            break;
        }
        case 'stemiPresent': {
            break;
        }
        case 'aorticDissectionCriteriaHighRiskConditions':
        case 'aorticDissectionCriteriaHighRiskSymptoms':
        case 'aorticDissectionCriteriaHighRiskFindings': {
            let dependentValues = [rawData['aorticDissectionCriteriaHighRiskConditions'], rawData['aorticDissectionCriteriaHighRiskSymptoms'], rawData['aorticDissectionCriteriaHighRiskFindings']];

            if (dependentValues.includes(true) && !dependentValues.includes(false) && rawData['aorticDissectionPresent'] == null) {
                changedValues['aorticDissectionPresent'] = true;
            } else if (rawData['aorticDissectionPresent'] == null) {
                let aorticDissectionNotPresent = !dependentValues.includes(true) && !dependentValues.includes(undefined) && !dependentValues.includes(null);
                changedValues['aorticDissectionPresent'] = aorticDissectionNotPresent ? false : rawData['aorticDissectionPresent'];
            }
            break;
        }
        case 'aorticDissectionPresent': {
            changedValues['aorticDissectionPresentRedFlagOverride'] = null;
            changedValues['aorticDissectionPresentRedFlagOverrideValue'] = null;
            changedValues['aorticDissectionPresentRedFlagOverrideReason'] = null;
            break;
        }
        case 'pulmonaryEmbolismRedFlag': {
            changedValues['pulmonaryEmbolismRedFlagOverride'] = null;
            changedValues['pulmonaryEmbolismRedFlagOverrideValue'] = null;
            break;
        }
        case 'pancreatitis': {
            changedValues['pancreatitisRedFlagOverride'] = null;
            changedValues['pancreatitisRedFlagOverrideValue'] = null;
            break;
        }
        case 'pericarditis': {
            changedValues['pericarditisRedFlagOverride'] = null;
            changedValues['pericarditisRedFlagOverrideValue'] = null;
            break;
        }
        case 'pneumothorax': {
            changedValues['pneumothoraxRedFlagOverride'] = null;
            changedValues['pneumothoraxRedFlagOverrideValue'] = null;
            break;
        }
        case 'chemotherapy': {
            changedValues['cardiotoxicityRedFlagOverride'] = null;
            changedValues['cardiotoxicityRedFlagOverrideValue'] = null;
            break;
        }
        case 'crescendoPain': {
            changedValues['crescendoAnginaOverride'] = null;
            changedValues['crescendoAnginaOverrideValue'] = null;
            break;
        }
        case 'painWorstAt': {
            if (value == 'firstEpisodeOption' || value == null) {
                changedValues['worstDateTime'] = null;
                changedValues['worstDuration'] = null;
            }
            break;

        }
        case 'patientDateOfBirth': {
            if(value != null){
                let momentDate = moment(value, 'DD/MM/YYYY');
                let years = moment().diff(momentDate, 'years');
                if(!isNaN(+years) && years >= 0){
                    changedValues['patientAge'] = years;
                } else {
                    changedValues['patientAge'] = null;
                }
            } else {
                changedValues['patientAge'] = null;
            }
            break;
        }
        case 'patientAge':{
            if(rawData['patientDateOfBirth'] != null){
                let momentDate = moment(rawData['patientDateOfBirth']);
                let years = moment().diff(momentDate, 'years');
                if(years != null && parseInt(years) != parseInt(value)){
                    changedValues['patientDateOfBirth'] = null;
                } 
            } 
            break;
        }
        case 'patientSex': {
            if (value == null) {
                changedValues['patientSexInt'] = null;
            } else {
                changedValues['patientSexInt'] = PatientSex.genderFromOption(value);
            }
            break;
        }
        case 'ongoingChestPain': {
            changedValues['redFlagsOngoingChestPainOverride'] = null;
            changedValues['redFlagsOngoingChestPainOverrideValue'] = null;
            break;
        }
        case 'initialBloodsTroponin':
        case 'followUpBloodsTroponin': {
            changedValues['troponinAboveCutOffOverride'] = null;
            changedValues['troponinAboveCutOffOverrideValue'] = null;
            break;
        }
        case 'aorticDissectionPresentRedFlagOverride': {
            if (value == true) {
                changedValues['aorticDissectionPresentRedFlagOverrideValue'] = !rawData['aorticDissectionPresentRedFlag'];
            } else {
                changedValues['aorticDissectionPresentRedFlagOverrideValue'] = null;
            }
            break;
        }
        case 'pulmonaryEmbolismRedFlagOverride': {
            if (value == true) {
                changedValues['pulmonaryEmbolismRedFlagOverrideValue'] = !rawData['pulmonaryEmbolismRedFlag'];
            } else {
                changedValues['pulmonaryEmbolismRedFlagOverrideValue'] = null;
            }
            break;
        }
        case 'pancreatitisRedFlagOverride': {
            if (value == true) {
                changedValues['pancreatitisRedFlagOverrideValue'] = !rawData['pancreatitisRedFlag'];
            } else {
                changedValues['pancreatitisRedFlagOverrideValue'] = null;
            }
            break;
        }
        case 'pericarditisRedFlagOverride': {
            if (value == true) {
                changedValues['pericarditisRedFlagOverrideValue'] = !rawData['pericarditisRedFlag'];
            } else {
                changedValues['pericarditisRedFlagOverrideValue'] = null;
            }
            break;
        }
        case 'pneumothoraxRedFlagOverride': {
            if (value == true) {
                changedValues['pneumothoraxRedFlagOverrideValue'] = !rawData['pneumothoraxRedFlag'];
            } else {
                changedValues['pneumothoraxRedFlagOverrideValue'] = null;
            }
            break;
        }
        case 'cardiotoxicityRedFlagOverride': {
            if (value == true) {
                changedValues['cardiotoxicityRedFlagOverrideValue'] = !rawData['cardiotoxicityRedFlag'];
            } else {
                changedValues['cardiotoxicityRedFlagOverrideValue'] = null;
            }
            break;
        }
        case 'troponinAboveCutOffOverride': {
            if (value == true) {
                changedValues['troponinAboveCutOffOverrideValue'] = !rawData['troponinAboveCutOff'];
            } else {
                changedValues['troponinAboveCutOffOverrideValue'] = null;
            }
            break;
        }
        case 'newIschaemicChangesOverride': {
            if (value == true) {
                changedValues['newIschaemicChangesOverrideValue'] = !rawData['newIschaemicChanges'];
            } else {
                changedValues['newIschaemicChangesOverrideValue'] = null;
            }
            break;
        }
        case 'redFlagsOngoingChestPainOverride': {
            if (value == true) {
                changedValues['redFlagsOngoingChestPainOverrideValue'] = !rawData['redFlagsOngoingChestPain'];
            } else {
                changedValues['redFlagsOngoingChestPainOverrideValue'] = null;
            }
            break;
        }
        case 'crescendoAnginaOverride': {
            if (value == true) {
                changedValues['crescendoAnginaOverrideValue'] = !rawData['crescendoAngina'];
            } else {
                changedValues['crescendoAnginaOverrideValue'] = null;
            }
            break;
        }
        case 'haemodynamicInstabilityOverride': {
            if (value == true) {
                changedValues['haemodynamicInstabilityOverrideValue'] = !rawData['haemodynamicInstability'];
            } else {
                changedValues['haemodynamicInstabilityOverrideValue'] = null;
            }
            break;
        }
        case 'heartRate':
        case 'bloodPressure':
        case 'bodyTemperature':
        case 'oxygenSaturation':
        case 'respiratoryRate':
        case 'supplementalOxygen':
        case 'levelOfConsciousness': {
            changedValues['haemodynamicInstabilityOverride'] = null;

            let totalEWS = getTotalEWS(rawData);
            if (totalEWS != null && totalEWS != undefined) {
                changedValues['totalEWS'] = totalEWS.sum;
                changedValues['haemodynamicInstability'] = totalEWS.sum >= 2;
            } else {
                changedValues['totalEWS'] = null;
                changedValues['haemodynamicInstability'] = null;
            }

            

            break;
        }
        case 'alternativeFindingSpecify': {
            if(rawData['alternativeFinding'] == null && value != null){
                changedValues['alternativeFinding'] = true;
            }
            break;
        }
    }
    
    if (identifier != 'troponinAboveCutOff') {
        // Complex derived red flag could be triggered by many different attributes...
        let initialBloodsTroponin = rawData['initialBloodsTroponin'];
        let followUpBloodsTroponin = rawData['followUpBloodsTroponin'];
        let genderChanged = Object.keys(changedValues).includes('patientSexInt');
        let gender = genderChanged ? changedValues['patientSexInt'] : rawData['patientSexInt'];
        if (gender != null) {
            let cutOff = gender == PatientSex.gender.male ? 34 : 16;

            let redFlag = (initialBloodsTroponin != null && initialBloodsTroponin >= cutOff) || (followUpBloodsTroponin != null && followUpBloodsTroponin >= cutOff);

            if (initialBloodsTroponin != null || followUpBloodsTroponin != null) {
                changedValues['troponinAboveCutOff'] = redFlag;
            } else {
                changedValues['troponinAboveCutOff'] = null;
            }
        } else {
            changedValues['troponinAboveCutOff'] = null;
        }
    }

    let redFlagIdentifiers = {
        'aorticDissectionPresent': 'aorticDissectionPresentRedFlag',
        'pulmonaryEmbolism': 'pulmonaryEmbolismRedFlag',
        'pancreatitis': 'pancreatitisRedFlag',
        'pericarditis': 'pericarditisRedFlag',
        'pneumothorax': 'pneumothoraxRedFlag',
        'chemotherapy': 'cardiotoxicityRedFlag',
        'newIschaemia': 'newIschaemicChanges',
        'ongoingChestPain': 'redFlagsOngoingChestPain',
        'crescendoPain': 'crescendoAngina',
    };
    // haemodynamicInstability and troponinAboveCutOff red flag resolved independently.

    if (Object.keys(redFlagIdentifiers).includes(identifier)) {
        let redFlagIdentifier = redFlagIdentifiers[identifier];

        let previousRedFlagValue = rawData[redFlagIdentifier];
        if (previousRedFlagValue != value) {
            changedValues[redFlagIdentifier] = value;
            changedValues[redFlagIdentifier + 'Override'] = null;
            changedValues[redFlagIdentifier + 'OverrideValue'] = null;
            changedValues[redFlagIdentifier + 'OverrideReason'] = null;
        }
    }
    
    let totalRedFlags = getActiveRedFlagCount(rawData);

    let stringChanges = [];

    let lastObservedValue = rawData['lastObservedTotalRedFlags'] == null ? 0 : rawData['lastObservedTotalRedFlags'];
    let lastValue = rawData['totalRedFlags'] == null ? 0 : rawData['totalRedFlags'];

    if(lastValue != totalRedFlags){
        if((lastObservedValue == 0 && totalRedFlags != 0) || (lastObservedValue > 0 && totalRedFlags == 0)){
            if((lastValue == 1 && totalRedFlags == 0) || (totalRedFlags == 1 && lastValue == 0) ){
                stringChanges.push('Red Flags');
            }
        } else {
            redux.store.dispatch(removeResultsAlertValue(pathwayId, 'Red Flags'));
        }
        changedValues['totalRedFlags'] = totalRedFlags;
    }

    if (stringChanges.length > 0) {
        //redux.store.dispatch(postResultsAlert(pathwayId, stringChanges));
        redux.store.dispatch(postResultsBadge(pathwayId));
    }

    if (Object.keys(changedValues).length > 0) {
        let mainForm = formLoader.mainForm(pathwayId);
        mainForm.save(changedValues, workflow, true, iterationIndex + 1);
    }
}

function getActiveRedFlagCount(rawData) {
    let redFlagIdentifiers = [
        'aorticDissectionPresentRedFlag',
        'pulmonaryEmbolismRedFlag',
        'pancreatitisRedFlag',
        'pericarditisRedFlag',
        'pneumothoraxRedFlag',
        'cardiotoxicityRedFlag',
        'newIschaemicChanges',
        'redFlagsOngoingChestPain',
        'crescendoAngina',
        'haemodynamicInstability',
        'troponinAboveCutOff'
    ];

    let values = redFlagIdentifiers.map((identifier) => {
        if (rawData[identifier + 'Override'] == true) {
            return null;
        } else {
            return rawData[identifier] == true ? true : null;
        }
    });

    return values.filter(n => n).length;
}

function getTotalEWS(data) {
    let ewsIdentifiers = [
        'heartRateEWS',
        'bloodPressureEWS',
        'bodyTemperatureEWS',
        'oxygenSaturationEWS',
        'respiratoryRateEWS',
        'supplementalOxygenEWS',
        'levelOfConsciousnessEWS'
    ];
    
    let categories = ewsIdentifiers.map((identifier) => {
        let value = data[identifier.replace('EWS', '')];
        let ews = calculateEWS(identifier, value);
        return ews;
    }).filter(n => n);

    if (categories.length < ewsIdentifiers.length - 1) { //supplementalOxygenEWS is null even if value is present, hence -1
        return null;
    }

    return new TotalEWS(categories);
}