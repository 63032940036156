import { FormWithRedux } from '../FormWithRedux';
import { FormValidation } from '../../waferJS/Form/Validation/FormValidation';
import { FormValidationError } from '../../waferJS/Form/Validation/FormValidationError';
import { NHINumber } from '../Shared Custom Types/NHI';

export class ExportToHCSForm extends FormWithRedux {

    get pathwayId() {
        return 'hcsExport';
    }

    constructor(formLoader) {
        super('exportToHCSForm', formLoader);
    }

    shouldDisplayFormDetailForIdentifier() {
        return true;
    }

    isEnabled(identifier) {
        if (identifier != null) {
            return true;
        }
        return false;
    }

    isMandatory() {
        return true;
    }

    getValueType() {
        return 'string';
    }

    editingValidation(identifier){
        if(identifier == 'patientNHINumber'){
            let isValidNHI = new FormValidation('NHIValid', function (value) {
                if(value == null){
                    return [FormValidationError.RequiredError];
                }
                let nhi = NHINumber.construct(value);
                if(nhi == null) {
                    return [new FormValidationError(FormValidationError.FormErrorType.error, 'NHI Number invalid')];
                }
                return [];
            });

            let isTestNHI = new FormValidation('NHITestValue', function (value) {
                
                if(NHINumber.isTest(value)) {
                    return [new FormValidationError(FormValidationError.FormErrorType.warning, 'Test NHI value detected')];
                }
                return [];
            });

            return [isValidNHI, isTestNHI];
        }
        return [];
    }

    submissionValidation() {
        return [];
    }

    detailString() {
        return this.detail;
    }
}