import {
    EXPORT_TO_ACC_PENDING,
    EXPORT_TO_ACC_FULFILLED,
    EXPORT_TO_ACC_FAILED,
    RESET_ACC_EXPORT_STATE,
    PREVIEW_TO_ACC_FULFILLED
} from '../actions/ExportToACCActions';

// INITIALIZE STATE
const initialState = {
    exportToACCPending: false,
    exportToACCFulfilled: false,
    exportToACCFailed: false,
    pdfPreviewData: null
};

export const ExportToACCReducer = (state = initialState, action) => {
    switch (action.type) {
        case EXPORT_TO_ACC_PENDING: {
            return {
                ...state,
                pdfPreviewData: null,
                exportToACCPending: true,
                exportToACCFulfilled: false,
                exportToACCFailed: false
            };
        }
        case EXPORT_TO_ACC_FULFILLED: {
            return {
                ...state,
                pdfPreviewData: null,
                exportToACCPending: false,
                exportToACCFulfilled: true,
                exportToACCFailed: false
            };
        }
        case EXPORT_TO_ACC_FAILED: {
            return {
                ...state,
                pdfPreviewData: null,
                exportToACCPending: false,
                exportToACCFulfilled: false,
                exportToACCFailed: true
            };
        }
        case RESET_ACC_EXPORT_STATE: {
            return {
                ...state,
                pdfPreviewData: null,
                exportToACCPending: false,
                exportToACCFulfilled: false,
                exportToACCFailed: false
            };
        }
        case PREVIEW_TO_ACC_FULFILLED: {
            return {
                ...state,
                pdfPreviewData: action.data,
                exportToACCPending: false,
                exportToACCFulfilled: false,
                exportToACCFailed: false
            };
        }
        default:
            return state;
    }
};