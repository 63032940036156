// IMPORT PACKAGE REFERENCES
import React from 'react';
import PropTypes from 'prop-types';
import { FormsStyles } from '../../FormsUI';

// COMPONENT
export class FormTextView extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            placeholder: props.placeholder,
            value: props.value,
            rows: 3,
            isActive: false
        };

        this.handleChange = this.handleChange.bind(this);
        this.updateParent = this.updateParent.bind(this);
        this.updateHeight = this.updateHeight.bind(this);
        this.onFocus = this.onFocus.bind(this);
        this.onBlur = this.onBlur.bind(this);
    }

    componentDidMount(){
        this.updateHeight(this.node.childNodes[0]);
    }

    shouldComponentUpdate(nextProps, nextState) {
        if(nextProps.placeholder !== this.props.placeholder){
            return true;
        }

        if(nextState.placeholder !== this.state.placeholder){
            return true;
        }

        if(nextProps.value !== this.props.value){
            return true;
        }

        if(nextState.value !== this.state.value){
            return true;
        }

        if(nextProps.onChange !== this.props.onChange){
            return true;
        }

        if(nextState.onChange !== this.state.onChange){
            return true;
        }

        return false;
    }

    handleChange(e) {
        const caret = e.target.selectionStart;
        const element = e.target;
        window.requestAnimationFrame(() => {
            element.selectionStart = caret;
            element.selectionEnd = caret;
        });

        clearTimeout(this.typingTimeout);

        this.typingTimeout = setTimeout(this.updateParent, 300);

        this.setState({ value: e.target.value });

        this.updateHeight(e.target);
    }

    updateHeight(textArea){
        const oldRows = textArea.rows;
        textArea.rows = 3;
        const newRows = ~~(textArea.scrollHeight / 18);
    
        if (newRows === oldRows) {
            textArea.rows = newRows;
        }
    
        this.setState({
            rows: newRows
        });
    }

    updateParent() {
        this.props.onChange(this.state.value == '' ? null : this.state.value);
    }

    onFocus(){
        this.setState({isActive: true});
    }

    onBlur(){
        this.setState({isActive: false});
    }

    render() {
        let style = {
            resize: 'none',
            borderRadius: '15px',
            borderWidth: '2px',
            borderStyle: 'solid',
            borderColor: FormsStyles.backgroundColor,
            marginTop: this.props.isLarge ? '0' : '0.5em',
            width: '100%',
            height: this.props.isLarge ? '20em' : 'auto',
            padding: '0.5em',
            lineHeight: '18px',
            color: this.state.isActive ? FormsStyles.black : FormsStyles.primaryTint
        };

        return (
            <div ref={node => this.node = node} style={{marginBottom: '-0.3em'}}>
                <textarea className='form-text-view' style={style} rows={this.state.rows} onChange={this.handleChange} onFocus={this.onFocus} onBlur={this.onBlur} value={this.state.value} placeholder={this.props.placeholder} />
            </div>
        );
    }
}

FormTextView.propTypes = {
    placeholder: PropTypes.string,
    onChange: PropTypes.func,
    value: PropTypes.string,
    isLarge: PropTypes.bool,
};