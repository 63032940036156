import {
    LOGIN_USER_PENDING,
    LOGIN_USER_FULFILLED,
    LOGIN_USER_REJECTED,
    LOGOUT_USER_REJECTED,
    LOGOUT_USER_FULFILLED,
    LOGOUT_USER_PENDING,
    CHECK_SSO_PENDING,
    CHECK_SSO_FULFILLED,
    CHECK_SSO_REJECTED,
    RESET_LOADING,
} from '../actions/UserActions';

// INITIALIZE STATE
const initialState = {
    user: {},
    loggingIn: false,
    loggingOut: false,
    authenticationType: '',
    checkingSSOPending: false,
    checkingSSOFulfilled: false,
    checkingSSOFailed: false,
    message: '',
    failed: false,
    redirectPathway: '',
};

// REDUCER
export const UserReducer = (state = initialState, action) => {
    switch(action.type) {
        case LOGIN_USER_PENDING:
            return {
                ...state,
                redirectPathway: action.payload.redirectPathway,
                loggingIn: true,
                failed: false,
                message: '',
            };
        case LOGIN_USER_FULFILLED: {
            return {
                ...state,
                user: action.payload,
                loggingIn: false,
                failed: false,
                message: '',
            };
        }
        case LOGIN_USER_REJECTED:
            return {
                ...state,
                loggingIn: false,
                failed: true,
                message: action.payload.message,
            };
        case LOGOUT_USER_PENDING: {
            return {
                ...state,
                redirectPathway: action.payload.redirectPathway,
                loggingOut: true,
                failed: false,
                message: '',
            };
        }
        case LOGOUT_USER_FULFILLED:
            return {
                ...state,
                user: {},
                loggingIn: false,
                loggingOut: false,
                authenticationType: '',
                checkingSSOPending: false,
                checkingSSOFulfilled: false,
                checkingSSOFailed: false,
                message: '',
                failed: false,
                // redirectPathway: '',
            };
        case LOGOUT_USER_REJECTED:
            return {
                ...state,
                loggingOut: false,
                message: action.payload.message,
                failed: true,
            };
        case CHECK_SSO_PENDING:
            return {
                ...state,
                checkingSSOPending: true,
                checkingSSOFulfilled: false,
                checkingSSOFailed: false
            };
        case CHECK_SSO_FULFILLED:
            return {
                ...state,
                authenticationType: action.payload,
                checkingSSOPending: false,
                checkingSSOFulfilled: true,
                checkingSSOFailed: false
            };
        case CHECK_SSO_REJECTED:
            return {
                ...state,
                authenticationType: action.payload,
                checkingSSOPending: false,
                checkingSSOFulfilled: false,
                checkingSSOFailed: true
            };
        case RESET_LOADING:
            return {
                ...state,
                authenticationType: '',
                checkingSSOPending: false,
                loggingIn: false,
            };
        default:
            return state;
    }
};