// IMPORT PACKAGE REFERENCES
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FormLabel } from '../../../waferJS/FormsUI/FormsUI Components/Controls/FormLabel';
import { FormButton } from '../../../waferJS/FormsUI/FormsUI Components/Controls/FormButton';
import { DateHelper } from '../../../waferJS/Helpers/DateHelper';
import { createNewResponse, clearWorkflow } from '../../state/actions/WorkflowResponseActions';
import { FormsStyles } from '../../../waferJS/FormsUI/FormsUI';
import { CheckModal } from '../../Modals/CheckModal';
import { clearCalculations, clearSnapshotCalculations } from '../../state/actions/CalculationActions';
import { clearActions, clearSnapshotActions } from '../../state/actions/ActionActions';
import { PathwayReferenceHelper } from '../../../forms/PathwayReferenceHelper';
import { FormPie } from '../../../waferJS/FormsUI/FormsUI Components/Controls/FormPie';
import { clearState } from '../../state/actions/SubformStateActions';
import { clearResultsAlert, clearResultsBadge } from '../../state/actions/NotificationsActions';
import { Chevron } from '../../shared/Chevron';
import { ENVIRONMENT, PILOT_PATHWAYS, CDHB_PILOT_DISCLAIMER_PATHWAYS } from '../../../config/config';
import { FormDetailWithReact } from '../../../waferJS/Form To FormsUI/FormDetailWithReact';
import { getPathwayIdFromURL } from '../../../helpers/URLHelper';
import { withRouter } from '../../routers/withRouter';

// COMPONENT
class SessionManagementPage extends React.Component {

    constructor(props) {
        super(props);


        let environmentFormName;

        switch (ENVIRONMENT) {
            case 'DEV':
                environmentFormName = 'devInformationForm';
                break;
            case 'PROD':
                if (PILOT_PATHWAYS.includes(getPathwayIdFromURL())) {
                    environmentFormName = 'pilotInformationForm';
                } else {
                    environmentFormName = 'pilotInformationForm';
                }
                break;
            case 'DEMO':
                environmentFormName = 'demoInformationForm';
                break;
            case 'TEST':
                environmentFormName = 'testInformationForm';    
                break;
            case 'STAGING':
                environmentFormName = 'stagingInformationForm';    
                break;
            default:
                environmentFormName = 'pilotInformationForm';
                break;
        }

        this.state = {
            form: this.props.formLoader.introForm(this.props.pathwayId),
            environmentForm: this.props.formLoader.form('environmentInfoForm', environmentFormName),
            checkModalVisible: window.location.pathname == ('/' + this.props.pathwayId + '/') || window.location.pathname == ('/' + this.props.pathwayId + '') ? false : true,
            canDismissWithClick: true,
            checkingForDiscard: false,
            startMenuVisible: window.location.pathname == ('/' + this.props.pathwayId + '/') || window.location.pathname == ('/' + this.props.pathwayId + '') || window.location.pathname == null ? true : false,
            environemtnDetailVisible: false
        };

        this.newPathwayAction = this.newPathwayAction.bind(this);
        this.useExistingSession = this.useExistingSession.bind(this);
        this.createNewSession = this.createNewSession.bind(this);
        this.toggleCheckModal = this.toggleCheckModal.bind(this);
        this.cancelAction = this.cancelAction.bind(this);
        this.handleClick = this.handleClick.bind(this);
        this.discardPathwayCheck = this.discardPathwayCheck.bind(this);
        this.clearPathway = this.clearPathway.bind(this);
        this.toggleenvironemtnDetailVisibility = this.toggleenvironemtnDetailVisibility.bind(this);
    }

    componentDidMount() {
        document.addEventListener('click', this.handleClick);

        this.props.toggleUserContentVisibility(!this.state.checkModalVisible);
    }

    componentWillUnmount() {
        document.removeEventListener('click', this.handleClick);
    }

    toggleenvironemtnDetailVisibility() {
        this.setState({ environemtnDetailVisible: !this.state.environemtnDetailVisible });
    }

    newPathwayAction() {
        this.createNewSession();
        let initialForm = "/mainForm";
        if(this.props.pathwayId == "accbist01") {
            // Display patient details form at first in BIST pathway
            initialForm = "/patientDetailsSubform";
        }
        this.props.navigate('/' + this.props.pathwayId + initialForm);
        this.props.toggleVisibility();

        // window.gtag('event', 'created-new-session', {
        //     event_category: 'action',
        //     event_label: 'created-new-session'
        // });
    }

    toggleCheckModal() {
        this.props.toggleVisibility();
    }

    cancelAction() {
        if (this.state.checkingForDiscard) {
            this.setState({
                checkingForDiscard: false,
                checkModalVisible: false
            });
            this.props.toggleUserContentVisibility(true);
        } else {
            this.props.toggleVisibility();
        }
    }

    createNewSession() {
        this.props.createNewResponse(this.props.pathwayId);
    }

    clearPathway() {
        this.props.clearWorkflow(this.props.pathwayId);
        this.props.clearCalculations(this.props.pathwayId);
        this.props.clearSnapshotCalculations(this.props.pathwayId);
        this.props.clearActions(this.props.pathwayId);
        this.props.clearSnapshotActions(this.props.pathwayId);
        this.props.clearState(this.props.pathwayId);
        this.props.clearResultsAlert(this.props.pathwayId);
        this.props.clearResultsBadge(this.props.pathwayId);
        this.props.formLoader.clearPathway(this.props.pathwayId);

        this.setState({ checkModalVisible: false });
        this.props.toggleUserContentVisibility(true);

        if (!this.state.checkingForDiscard) {
            if (!this.props.isExtension) {
                if (window.location.pathname != ('/' + this.props.pathwayId + '/')) {
                    this.props.navigate('/' + this.props.pathwayId + '/');
                }
                this.setState({ startMenuVisible: true });
            } else {
                this.props.toggleVisibility();
            }
        } else {
            this.setState({ checkingForDiscard: false });
        }

        // window.gtag('event', 'discarded-current-session', {
        //     event_category: 'action',
        //     event_label: 'discarded-current-session'
        // });
    }

    useExistingSession() {
        this.props.toggleVisibility();

        if (window.location.pathname == ('/' + this.props.pathwayId + '/') || window.location.pathname == ('/' + this.props.pathwayId)) {
            let initialForm = "/mainForm";
            if(this.props.pathwayId == "accbist01") {
                // Display patient details form at first in BIST pathway
                initialForm = "/patientDetailsSubform";
            }
            this.props.navigate('/' + this.props.pathwayId + initialForm);
        }
    }

    handleClick(e) {
        if (this.existingSessionNode != null && this.existingSessionNode.contains(e.target)) {
            this.useExistingSession();
        } else if (this.environmentDetailNode != null && this.environmentDetailNode.contains(e.target)) {
            this.toggleenvironemtnDetailVisibility();
        }
    }

    discardPathwayCheck() {
        this.setState({
            checkingForDiscard: true,
            checkModalVisible: true
        });
    }

    hasActiveSession() {
        if (JSON.stringify(this.props.workflows) != JSON.stringify({}) && this.props.workflows[this.props.pathwayId] != undefined) {
            return JSON.stringify(this.props.workflows[this.props.pathwayId]) != JSON.stringify({});
        }
        return false;
    }

    render() {
        let formProgress = (this.props.formLoader.mainForm(this.props.pathwayId)).completionProgress();

        return (
            <div className="session-wrapper">
                {this.state.checkModalVisible && <div ref={checkNode => this.checkNode = checkNode}><CheckModal toggleVisibility={this.cancelAction} yesAction={this.clearPathway} distructiveActionTitle={this.props.isExtension ? 'Clear Input' : 'Discard Session'} /> </div>}
                <div className="session-overlay" />

                {this.state.startMenuVisible &&
                    <div className="session-manager-container">
                        <div className="session-manager-aligner">
                            <div className="faux-card"></div>
                            <div className="session-card open" ref={sessionCardNode => this.sessionCardNode = sessionCardNode}>
                                <header className={'session-title'}>{this.state.form.title}</header>
                                {this.state.form.detail != null && 
                                    <div className={' session-detail'}>
                                        {CDHB_PILOT_DISCLAIMER_PATHWAYS.includes(this.props.pathwayId) &&
                                            <div className={'pilot-ended-label'}>
                                                <FormLabel value={'The pilot of the Wayfind decision support tool (acute chest pain, DXA, gallbladder) has finished. The tool has been removed from Community HealthPathways and ERMS while it is being evaluated.'} fontSize={1} textColor={FormsStyles.orange}/>
                                            </div>
                                        }
                                        <FormLabel value={this.state.form.detail} fontSize={1} textColor={'#909294'}/>
                                    </div>
                                }
                                <div className="session-body">
                                    <div className={'' + (this.props.isExtension ? 'hidden-nav' : '')}> 
                                        {this.state.form.sectionIds != null && this.state.form.sectionIds.map((sectionID) => {
                                            let section = this.state.form.sectionReference(sectionID);
                                            return (
                                                <div key={sectionID} className='form-section'>
                                                    {
                                                        section[PathwayReferenceHelper.Type.sectionChildIDs] != undefined &&
                                                        section[PathwayReferenceHelper.Type.sectionChildIDs].map((childID) => {
                                                            if (this.state.form.shouldDisplayFormDetailForIdentifier(childID)) {
                                                                let formDetail = this.state.form.formDetail(childID);
                                                                return <FormDetailWithReact formLoader={this.props.formLoader} key={formDetail.identifier} formDetail={formDetail} isSummary={false} isSubcell={false} showHover={true}/>;
                                                            }
                                                        })
                                                    }
                                                </div>
                                            );
                                        })}
                                    </div>

                                    {this.hasActiveSession() &&
                                        <div className="exisiting-session-container">
                                            <FormLabel value={'Existing Session'} fontSize={1.2} fontWeight={500} textColor={FormsStyles.black} />
                                            <div className="existing-session-cell" ref={existingSessionNode => this.existingSessionNode = existingSessionNode}>
                                                <div className="existing-session-button">
                                                    <FormPie totalValue={formProgress.total} filledValue={formProgress.current} />
                                                    <FormButton textAlignment={'left'} image={<Chevron angle={'rotate(-90deg)'} />} activeBackgroundColor={'transparent'} backgroundColor={'transparent'} enabled={true} isSelected={false} title={this.props.workflows[this.props.pathwayId].name} />
                                                </div>
                                                <FormLabel value={'Last modified ' + DateHelper.relativeDate(this.props.workflows[this.props.pathwayId].lastModifiedDate)} fontSize={0.9} textColor={'#B0B0B0'} />
                                                <FormLabel value={'Selected'} fontSize={0.9} textColor={FormsStyles.primaryTint} />
                                            </div>
                                            <div className="new-pathway-button"><FormButton key='newPathway' onPress={this.discardPathwayCheck} enabled={true} isSelected={false} title={'Discard Session'} color={FormsStyles.red} /></div>
                                        </div>
                                    }
                                    <div className="new-session-container">
                                        <FormLabel value={'New Session'} fontSize={1.2} fontWeight={500} textColor={FormsStyles.black} />
                                        {this.hasActiveSession() && <FormLabel value={'Only one session can be stored; to start a new one you must discard your existing session. Ensure you have exported any important data from the session above before continuing'} fontSize={0.9} textColor={FormsStyles.orange} />}
                                        <div className="new-pathway-button"><FormButton key='newPathway' onPress={this.newPathwayAction} enabled={!this.hasActiveSession()} isSelected={false} title={'Start New Session'} color={FormsStyles.primaryTint} /></div>
                                    </div>
                                </div>
                            </div>
                            {(ENVIRONMENT == 'PROD' && PILOT_PATHWAYS.includes(this.props.pathwayId)) &&
                                <div className="environemt-session-container">
                                    <div className={'session-card ' + (this.state.environemtnDetailVisible ? 'open' : '')} ref={sessionCardNode => this.sessionCardNode = sessionCardNode}>

                                        <div className="environment-title-container" ref={environmentDetailNode => this.environmentDetailNode = environmentDetailNode}>
                                            <header className={'session-environment-title'}>{this.state.environmentForm.title}</header>
                                            <FormButton textAlignment={'left'} image={<Chevron angle={this.state.environemtnDetailVisible ? 'rotate(180deg)' : 'rotate(0deg)'} />} activeBackgroundColor={'transparent'} backgroundColor={'transparent'} enabled={true} isSelected={false} title={''} />
                                        </div>
                                        {
                                            this.state.environemtnDetailVisible &&
                                            <div>
                                                <div className={'environemnt-session-detail'}><FormLabel value={this.state.environmentForm.detail} fontSize={0.9} textColor={'#909294'} /></div>

                                                <div className="session-body">
                                                    {this.state.environmentForm.sectionIds.map((sectionID) => {
                                                        let section = this.state.environmentForm.sectionReference(sectionID);
                                                        return (
                                                            <div key={sectionID} className='form-section'>
                                                                {section[PathwayReferenceHelper.Type.sectionChildIDs].map((childID) => { return this.state.environmentForm.shouldDisplayFormDetailForIdentifier(childID) ? true : null; }).filter(n => n).length > 0 && <div className='section-title'>{section.title}</div>}
                                                                {
                                                                    section[PathwayReferenceHelper.Type.sectionChildIDs] != undefined &&
                                                                    section[PathwayReferenceHelper.Type.sectionChildIDs].map((childID) => {
                                                                        if (this.state.environmentForm.shouldDisplayFormDetailForIdentifier(childID)) {
                                                                            let formDetail = this.state.environmentForm.formDetail(childID);
                                                                            return <FormDetailWithReact formLoader={this.props.formLoader} key={formDetail.identifier} formDetail={formDetail} isSummary={false} isSubcell={false} showHover={true} />;
                                                                        }
                                                                    })
                                                                }
                                                            </div>
                                                        );
                                                    })}
                                                </div>
                                            </div>
                                        }
                                    </div>
                                </div>
                                
                            }
                            <div className="faux-card"></div>
                        </div>

                    </div>
                }
            </div>
        );
    }
}

SessionManagementPage.propTypes = {
    location: PropTypes.object.isRequired,
    navigate: PropTypes.func.isRequired,
    workflows: PropTypes.object.isRequired,
    updatingWorkflowPending: PropTypes.bool.isRequired,
    updatingWorkflowFulfilled: PropTypes.bool.isRequired,
    updatingWorkflowFailed: PropTypes.bool.isRequired,
    toggleVisibility: PropTypes.func.isRequired,
    createNewResponse: PropTypes.func.isRequired,
    clearCalculations: PropTypes.func.isRequired,
    clearActions: PropTypes.func.isRequired,
    isExtension: PropTypes.bool.isRequired,
    clearState: PropTypes.func.isRequired,
    clearResultsAlert: PropTypes.func.isRequired,
    clearResultsBadge: PropTypes.func.isRequired,
    clearWorkflow: PropTypes.func.isRequired,
    pathwayId: PropTypes.string.isRequired,
    clearSnapshotCalculations: PropTypes.func.isRequired,
    clearSnapshotActions: PropTypes.func.isRequired,
    toggleUserContentVisibility: PropTypes.func.isRequired,
};

// CONFIGURE REACT REDUX
const mapStateToProps = state => {
    const { workflows, updatingWorkflowPending, updatingWorkflowFulfilled, updatingWorkflowFailed } = state.workflowReducer;
    return { workflows, updatingWorkflowPending, updatingWorkflowFulfilled, updatingWorkflowFailed };
};

const mapDispatchToProps = dispatch => (
    bindActionCreators({ createNewResponse, clearCalculations, clearActions, clearState, clearResultsAlert, clearResultsBadge, clearWorkflow, clearSnapshotCalculations, clearSnapshotActions }, dispatch)
);

const hoc = withRouter(connect(mapStateToProps, mapDispatchToProps)(SessionManagementPage));

// EXPORT COMPONENT

export { hoc as SessionManagementPage };