import { FormContentView } from '../../FormsUI/FormsUI Components/Containers/FormContentView';
import { FormsStyles } from '../../FormsUI/FormsUI';
import React from 'react';
import PropTypes from 'prop-types';
import { FormDropdown } from '../../FormsUI/FormsUI Components/Controls/FormDropdown';

export class DropDownFormDetail extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            menuOpen: false,
        };

        this.onMenuOpen = this.onMenuOpen.bind(this);
        this.onSelect = this.onSelect.bind(this);
        this.wrapperRef = React.createRef();
        this.handleClickOutside = this.handleClickOutside.bind(this);

    }

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    handleClickOutside(event) {
        if(this.wrapperRef && !this.wrapperRef.current.contains(event.target)) {
            if(this.state.menuOpen) {
                this.setState({
                    menuOpen: false,
                });
            }
        }
    }

    onMenuOpen() {
        this.setState({
            menuOpen: !this.state.menuOpen,
        });
    }

    onSelect(value) {
        this.props.formDetail.form.valueUpdated(this.props.formDetail.identifier, value);
        
        this.setState({
            menuOpen: false,
        });

        this.props.renderErrors();
    }

    render() {
        let isMandatory = this.props.formDetail.form.isMandatory(this.props.formDetail.identifier);
        let currentValue = this.props.formDetail.currentValue;

        let dropdown =
        <div ref={this.wrapperRef}>
            <FormDropdown selectedValue={currentValue} onMenuOpen={this.onMenuOpen} menuOpen={this.state.menuOpen} optionsList={this.props.formDetail.options} onSelect={this.onSelect} />
        </div>;

        return (
            <FormContentView identifier={this.props.formDetail.identifier} isSubcell={this.props.isSubcell} info={this.props.formDetail.info} isMandatory={isMandatory} titleString={this.props.formDetail.title} detailString={this.props.formDetail.detail} subcell={[]} validation={this.props.errorLabels} rightControl={dropdown} subCellbackgroundColor={FormsStyles.backgroundColor} showHover={true}/>
        );
    }
}

DropDownFormDetail.propTypes = {
    formDetail: PropTypes.object.isRequired,
    isSubcell: PropTypes.bool.isRequired,
    renderErrors: PropTypes.func.isRequired,
    renderSubcells: PropTypes.func.isRequired,
    errorLabels: PropTypes.array.isRequired,
    hasError: PropTypes.bool.isRequired,
};