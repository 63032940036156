import { FormContentView } from '../../../../waferJS/FormsUI/FormsUI Components/Containers/FormContentView';
import React from 'react';
import PropTypes from 'prop-types';
import { FormsStyles } from '../../../../waferJS/FormsUI/FormsUI';

export class PersonGraphFormDetail extends React.Component {

    constructor(props) {
        super(props);

        let value = this.props.formDetail.form.getGraphValue();

        this.state = {
            valueAsPercentage: value * 100
        };

        this.getPersonStyle = this.getPersonStyle.bind(this);
    }

    getPersonStyle(index){
        index = index + 1; //index starts at 1
        let fillPercentage = this.state.valueAsPercentage - (index - 1);
        
        if(fillPercentage <= 0){
            fillPercentage = 0;
        } else if(fillPercentage >= 1){
            fillPercentage = 100;
        } else {
            fillPercentage = fillPercentage * 100;
        }

        let color;
        if(this.state.valueAsPercentage < 1){
            color = FormsStyles.green;
        } else if(this.state.valueAsPercentage < 5){
            color = FormsStyles.orange;
        } else if(this.state.valueAsPercentage >= 5){
            color = FormsStyles.red;
        }
        
        let cellStyle = {
            width: '100%',
            height: '100%',
            position: 'relative'
        };

        let filledStyle = {
            height: '100%',
            position: 'absolute',
            left: 0,
            top: 0,
            fill: color
        };

        let filled = {
            width:  fillPercentage.toString() + '%',
            height: '100%',
            position: 'absolute',
            left: 0,
            top: 0,
            overflow: 'hidden',
        };

        let backingStyle = {
            height: '100%',
            width: '100%',
            position: 'absolute',
            left: 0,
            top: 0,
            fill: FormsStyles.lightestGray
        };

        return (
            <div key={index + this.props.formDetail.identifier} className="person-graph-cell-container">
                <div style={cellStyle}>
                    <svg width="24px" height="25px" viewBox="0 0 24 25" version="1.1" className="person-graph-filled-cell" style={backingStyle}>
                        <path d="M23.9922284,23.940541 C24.0266901,24.206187 23.9454591,24.4749584 23.7700739,24.6780995 C23.5928425,24.8824907 23.3380724,25 23.0703792,25 L0.929383194,25 C0.662305391,25 0.40753532,24.8824907 0.230303967,24.6780995 C0.0549187734,24.4749584 -0.0263122635,24.206187 0.00753400187,23.940541 C0.400150681,20.8846736 3.18169831,19.2395432 5.41616721,17.9175633 C7.05371107,16.9493615 8.46725419,16.1130452 8.46725419,15.0623369 C8.46725419,13.2790705 7.39894298,12.3383709 7.13863443,12.1358548 C6.35770878,12.0039693 5.94970743,10.5094758 5.94970743,9.69191098 C5.94970743,9.14249241 6.10909257,8.86684555 6.28324699,8.72995969 C5.71770667,6.85668613 5.72816824,4.26085527 7.01186478,2.42258448 C8.0155604,0.985595522 9.67279663,0.222409997 11.8032652,0.214909402 C13.3423472,-0.274504411 15.018045,0.0855241409 16.1866643,1.1568591 C17.1454366,2.03630385 18.5983645,4.17709863 17.7115924,8.70995811 C17.895593,8.83934337 18.0728243,9.11374013 18.0728243,9.69191098 C18.0728243,10.5107259 17.6642076,12.0064695 16.8808204,12.1358548 C16.6131272,12.339621 15.5331236,13.2678196 15.5331236,15.0623369 C15.5331236,16.1130452 16.9466668,16.9493615 18.5835952,17.9175633 C20.8186795,19.2395432 23.5996118,20.8846736 23.9922284,23.940541" id="Path"></path>
                    </svg>
                    <div style={filled}>
                        <svg width="24px" height="25px" viewBox="0 0 24 25" version="1.1" className="person-graph-filled-cell" style={filledStyle}>
                            <path d="M23.9922284,23.940541 C24.0266901,24.206187 23.9454591,24.4749584 23.7700739,24.6780995 C23.5928425,24.8824907 23.3380724,25 23.0703792,25 L0.929383194,25 C0.662305391,25 0.40753532,24.8824907 0.230303967,24.6780995 C0.0549187734,24.4749584 -0.0263122635,24.206187 0.00753400187,23.940541 C0.400150681,20.8846736 3.18169831,19.2395432 5.41616721,17.9175633 C7.05371107,16.9493615 8.46725419,16.1130452 8.46725419,15.0623369 C8.46725419,13.2790705 7.39894298,12.3383709 7.13863443,12.1358548 C6.35770878,12.0039693 5.94970743,10.5094758 5.94970743,9.69191098 C5.94970743,9.14249241 6.10909257,8.86684555 6.28324699,8.72995969 C5.71770667,6.85668613 5.72816824,4.26085527 7.01186478,2.42258448 C8.0155604,0.985595522 9.67279663,0.222409997 11.8032652,0.214909402 C13.3423472,-0.274504411 15.018045,0.0855241409 16.1866643,1.1568591 C17.1454366,2.03630385 18.5983645,4.17709863 17.7115924,8.70995811 C17.895593,8.83934337 18.0728243,9.11374013 18.0728243,9.69191098 C18.0728243,10.5107259 17.6642076,12.0064695 16.8808204,12.1358548 C16.6131272,12.339621 15.5331236,13.2678196 15.5331236,15.0623369 C15.5331236,16.1130452 16.9466668,16.9493615 18.5835952,17.9175633 C20.8186795,19.2395432 23.5996118,20.8846736 23.9922284,23.940541" id="Path"></path>
                        </svg>
                    </div>
                </div>
            </div>
        );
    }

    render() {
        let subcells = this.props.renderSubcells();
        let isMandatory = this.props.formDetail.form.isMandatory(this.props.formDetail.identifier);

        let graphStyle = {
            display: 'flex',
            flexDirection: 'column',
            alignContent: 'center',
            alignItems: 'center',
            width: '100%',
            marginTop: '0.5em'
        };

        let personTableStyle = {
            display: 'flex',
            flexDirection: 'column',
            alignContent: 'center',
            alignItems: 'center',
            paddingLeft: '2em',
            paddingRight: '2em',
            marginTop: '0.5em'
        };

        let rowStyle = {
            display: 'flex',
            flexDirection: 'row',
            alignContent: 'flex-start',
            alignItems: 'flex-start',
        };

        let graph = 
            <div style={graphStyle}>
                <div>{Math.round(this.state.valueAsPercentage)} in 100</div>
                <div style={personTableStyle}>
                    <div style={rowStyle}>
                        {[...Array(10).keys()].map(x => x + 0).map((index) => {
                            return(this.getPersonStyle(index));
                        })}
                    </div>
                    <div style={rowStyle}>
                        {[...Array(10).keys()].map(x => x + 10).map((index) => {
                            return(this.getPersonStyle(index));
                        })}
                    </div>
                    <div style={rowStyle}>
                        {[...Array(10).keys()].map(x => x + 20).map((index) => {
                            return(this.getPersonStyle(index));
                        })}
                    </div>
                    <div style={rowStyle}>
                        {[...Array(10).keys()].map(x => x + 30).map((index) => {
                            return(this.getPersonStyle(index));
                        })}
                    </div>
                    <div style={rowStyle}>
                        {[...Array(10).keys()].map(x => x + 40).map((index) => {
                            return(this.getPersonStyle(index));
                        })}
                    </div>
                    <div style={rowStyle}>
                        {[...Array(10).keys()].map(x => x + 50).map((index) => {
                            return(this.getPersonStyle(index));
                        })}
                    </div>
                    <div style={rowStyle}>
                        {[...Array(10).keys()].map(x => x + 60).map((index) => {
                            return(this.getPersonStyle(index));
                        })}
                    </div>
                    <div style={rowStyle}>
                        {[...Array(10).keys()].map(x => x + 70).map((index) => {
                            return(this.getPersonStyle(index));
                        })}
                    </div>
                    <div style={rowStyle}>
                        {[...Array(10).keys()].map(x => x + 80).map((index) => {
                            return(this.getPersonStyle(index));
                        })}
                    </div>
                    <div style={rowStyle}>
                        {[...Array(10).keys()].map(x => x + 90).map((index) => {
                            return(this.getPersonStyle(index));
                        })}
                    </div>
                </div>
            </div>;

        return (<FormContentView identifier={this.props.formDetail.identifier} isSubcell={this.props.isSubcell} titleString={this.props.formDetail.title} isMandatory={isMandatory} bottomControl={graph} detailString={this.props.formDetail.detail} validation={this.props.errorLabels} subcell={subcells} showHover={true}/>);
    }
}

PersonGraphFormDetail.propTypes = {
    formDetail: PropTypes.object.isRequired,
    isSubcell: PropTypes.bool.isRequired,
    renderErrors: PropTypes.func.isRequired,
    renderSubcells: PropTypes.func.isRequired,
    errorLabels: PropTypes.array.isRequired,
    hasError: PropTypes.bool.isRequired
};