import { FormContentView } from '../../FormsUI/FormsUI Components/Containers/FormContentView';
import { FormsStyles } from '../../FormsUI/FormsUI';

import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { FormDate } from '../../FormsUI/FormsUI Components/Controls/FormDate';


export class DateFormDetail extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            value: props.formDetail.editingValue == null || props.formDetail.editingValue == undefined ? null : props.formDetail.editingValue.toString()
        };

        this.handleSelectedDate = this.handleSelectedDate.bind(this);
        this.adoptSuggestion = this.adoptSuggestion.bind(this);
    }

    handleSelectedDate(value) {
        this.props.formDetail.form.valueUpdated(this.props.formDetail.identifier, value);
        this.props.renderErrors();
    }

    adoptSuggestion(){
        this.props.formDetail.form.valueUpdated(this.props.formDetail.identifier, moment().toDate());
        this.props.renderErrors();
    }

    componentDidUpdate(){
        let value = this.props.formDetail.editingValue == null || this.props.formDetail.editingValue == undefined ? null : this.props.formDetail.editingValue.toString();
        if(value != this.state.value) {
            this.setState({value: value}
            );
        }
    }

    render() {
        let subcells = this.props.renderSubcells();
        let isMandatory = this.props.formDetail.form.isMandatory(this.props.formDetail.identifier);
        let dateElement = <FormDate dateSelected={this.handleSelectedDate} value={this.state.value} withTime={false} formattedDate={this.props.formDetail.dateFormat} isMandatory={isMandatory} hasError={this.props.hasError == undefined ? false : this.props.hasError}/>;
        return (<FormContentView identifier={this.props.formDetail.identifier} isSubcell={this.props.isSubcell} titleString={this.props.formDetail.title} isMandatory={isMandatory} detailString={this.props.formDetail.detail} validation={this.props.errorLabels} rightControl={dateElement} subcell={subcells} subCellbackgroundColor={FormsStyles.backgroundColor} showHover={true}/>);
    }
}

DateFormDetail.propTypes = {
    formDetail: PropTypes.object.isRequired,
    isSubcell: PropTypes.bool.isRequired,
    renderErrors: PropTypes.func.isRequired,
    renderSubcells: PropTypes.func.isRequired,
    errorLabels: PropTypes.array.isRequired,
    hasError: PropTypes.bool.isRequired
};