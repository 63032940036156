import { FormWithRedux } from '../../FormWithRedux';
import { ACCMRIIntroForm } from '../Forms/ACCMRI-IntroForm';
import { ACCMRIResultsForm } from '../ResultsForm/ACCMRI-ResultsForm';
import { ACCMRIRequestCriteriaSubform } from './ACCMRI-RequestCriteriaSubform';
import { ACCMRIAssessmentForm } from './ACCMRI-AssessmentForm';
import { ACCMRIActions } from '../Actions/ACCMRI-Actions';
import { ACCMRIDependantValuesUpdater } from '../Calculations/ACCMRI-DependantValuesUpdater';
import { ACCMRICalculations } from '../Calculations/ACCMRI-Calculations';

export class ACCMRIMainForm extends FormWithRedux {

    constructor(formLoader) {
        super('mainForm', formLoader, null, new ACCMRIActions(formLoader), new ACCMRIDependantValuesUpdater(formLoader), new ACCMRICalculations());
    }

    generateSubform(identifier) {
        switch (identifier) {
            case 'introForm':
                return new ACCMRIIntroForm(this.formLoader);
            case 'requestCriteria':
                return new ACCMRIRequestCriteriaSubform(this.formLoader, this, this.actionUpdater, this.dependentValuesUpdater, this.calculationsUpdater);
            case 'assessment':
                return new ACCMRIAssessmentForm(this.formLoader, this, this.actionUpdater, this.dependentValuesUpdater, this.calculationsUpdater);
            case 'outcome':
                return new ACCMRIResultsForm(this.formLoader, this, this.actionUpdater, this.dependentValuesUpdater, this.calculationsUpdater);
        }
    }

    shouldDisplayFormDetailForIdentifier(identifier) {
        super.shouldDisplayFormDetailForIdentifier(identifier);
        return true;
    }

    isEnabled(identifier) {
        if (identifier != null) {
            return true;
        }
        return false;
    }

    isMandatory() {
        return false;
    }

    getValueType() {
        return null;
    }

    submissionValidation() {
        return [];
    }

    detailString() {
        return this.detail;
    }
}