
import { EDChestPainPathwayReference } from './edChestPain01/EDChestPainPathway-Reference';
import { QuidelOrthoChestPainPathwayReference } from './edChestPain01/QuidelOrthoChestPainPathway-Reference';
import { SharedFormData } from './sharedForms/SharedFormData';
import { GallbladderPathwayReference } from './Gallbladder01/GallbladderPathwayReference';
import { OsteoporosisPathwayReference } from './dxaScan01/Osteoporosis-PathwayReference';
import { GPChestPainPathwayReference } from './gpChestPain01/GPChestPainPathway-Reference';
import { ACCMRIPathwayReference } from './accmri01/ACCMRI-PathwayReference';
import { ACCBISTPathwayReference } from './concussion01/ACCBIST-PathwayReference';


export class ReferenceDataLoader {
    static referenceData(pathwayId){
        switch (pathwayId){
            case 'chestPain01':
                return EDChestPainPathwayReference.data;
            case 'gallbladder01':
                return GallbladderPathwayReference.data;
            case 'dxaScan01':
                return OsteoporosisPathwayReference.data;
            case 'gpChestPain01':
                return GPChestPainPathwayReference.data;
            case 'accmri01':
                return ACCMRIPathwayReference.data;
            case 'accbist01':
                return ACCBISTPathwayReference.data;
            case 'environmentInfoForm':
                return SharedFormData.data;
            case 'hcsExport':
                return SharedFormData.data;
            case 'QuidelOrtho':
                return QuidelOrthoChestPainPathwayReference.data;
        }
    }
}