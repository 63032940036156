import {
    EXPORT_TO_EMAIL_PENDING,
    EXPORT_TO_EMAIL_FULFILLED,
    EXPORT_TO_EMAIL_FAILED,
    RESET_EMAIL_EXPORT_STATE,
} from '../actions/ExportToEmailActions';

// INITIALIZE STATE
const initialState = {
    exportToEmailPending: false,
    exportToEmailFulfilled: false,
    exportToEmailFailed: false
};

export const ExportToEmailReducer = (state = initialState, action) => {
    switch (action.type) {
        case EXPORT_TO_EMAIL_PENDING: {
            return {
                ...state,
                exportToEmailPending: true,
                exportToEmailFulfilled: false,
                exportToEmailFailed: false
            };
        }
        case EXPORT_TO_EMAIL_FULFILLED: {
            return {
                ...state,
                exportToEmailPending: false,
                exportToEmailFulfilled: true,
                exportToEmailFailed: false
            };
        }
        case EXPORT_TO_EMAIL_FAILED: {
            return {
                ...state,
                exportToEmailPending: false,
                exportToEmailFulfilled: false,
                exportToEmailFailed: true
            };
        }
        case RESET_EMAIL_EXPORT_STATE: {
            return {
                ...state,
                exportToEmailPending: false,
                exportToEmailFulfilled: false,
                exportToEmailFailed: false
            };
        }
        default:
            return state;
    }
};