import { FormWithRedux } from '../FormWithRedux';

export class GenericFrom extends FormWithRedux {

    get pathwayId() {
        return  'environmentInfoForm'; //this._pathwayId
    }

    set pathwayId(value) {
        this._pathwayId = value;
    }

    subform(identifier) {
        return this.formLoader.form(this.pathwayId, identifier);
    }

    formDetail(identifier) {
        return this.formLoader.formDetail(this.pathwayId, identifier);
    }

    constructor(identifier, formLoader, pathwayId) {
        super(identifier, formLoader);
        this.pathwayId = pathwayId;
    }

    generateSubform(identifier) {
        return new GenericFrom(identifier, this.formLoader, this.pathwayId);
    }

    shouldDisplayFormDetailForIdentifier() {
        return true;
    }

    isEnabled(identifier) {
        if (identifier != null) {
            return true;
        }
        return false;
    }

    isMandatory() {
        return true;
    }

    getValueType() {
        return 'string';
    }

    editingValidation(){
        return [];
    }

    submissionValidation() {
        return [];
    }

    detailString() {
        return this.detail;
    }
}