// IMPORT PACKAGE REFERENCES
import React from 'react';
import PropTypes from 'prop-types';
import { FormsStyles } from '../../FormsUI';
import '../../Styles/concussionScale.scss';
import propTypes from 'prop-types';

// COMPONENT
export class FormScale extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            color: props.color,
            name: props.name,
            number: props.number,
            textColor: props.textColor,
            event: props.event,
            value: props.value,
            checked: props.checked
        };
    }

    render() {
        return (
            <div className='scale-container'>
                <label className={`${this.props.color}-radio-button`}>
                    <input type='radio' name={this.props.name} value={this.props.value} onChange={this.props.event} checked={this.props.checked} />
                    <span className={`${this.props.color}-checkmark`}></span>
                </label>
                <span className={`scale-${this.props.textColor}-number`}>{this.props.number}</span>
            </div>
        );
    }
}

FormScale.propTypes = {
    color: propTypes.string,
    name: propTypes.string,
    number: propTypes.number,
    textColor: propTypes.string,
    event: propTypes.func,
    value: propTypes.number,
    checked: propTypes.bool
};