import { FormWithRedux } from '../../FormWithRedux';
import { EDChestPainAorticDissectionCriteriaForm } from './EDChestPain-AorticDissectionCriteriaForm';
import { FormsUIGlossary } from '../../../waferJS/FormsUI/FormsUI';
import { FormValidation } from '../../../waferJS/Form/Validation/FormValidation';
import { FormValidationError } from '../../../waferJS/Form/Validation/FormValidationError';

export class EDChestPainHighRiskDifferentialDiagnosisForm extends FormWithRedux {

    constructor(formLoader, parent, actionUpdater, dependentValuesUpdater, calculationsUpdater) {
        super('highRiskDifferentialDiagnosis', formLoader, parent, actionUpdater, dependentValuesUpdater, calculationsUpdater);

        this.submissionValidation = this.submissionValidation.bind(this);
    }

    generateSubform(identifier) {
        switch (identifier) {
            case 'aorticDissectionCriteria':
                return new EDChestPainAorticDissectionCriteriaForm(identifier, this.formLoader, this.parent, this.actionUpdater, this.dependentValuesUpdater, this.calculationsUpdater);
        }
    }

    shouldDisplayFormDetailForIdentifier(identifier) {
        super.shouldDisplayFormDetailForIdentifier(identifier);
        if (identifier === 'aorticDissectionCriteria') {
            let dependantValue = this.getValue('aorticDissectionPresent');
            if(dependantValue == true || dependantValue == null){
                return true;
            } else {
                let aorticDissectionCriteriaHighRiskConditions = this.getValue('aorticDissectionCriteriaHighRiskConditions');
                let aorticDissectionCriteriaHighRiskSymptoms = this.getValue('aorticDissectionCriteriaHighRiskSymptoms');
                let aorticDissectionCriteriaHighRiskFindings = this.getValue('aorticDissectionCriteriaHighRiskFindings');
                return aorticDissectionCriteriaHighRiskConditions != null || aorticDissectionCriteriaHighRiskSymptoms != null || aorticDissectionCriteriaHighRiskFindings != null;
            }
        } else if(identifier == 'pathwayNotRelevantWarning'){
            return this.getValue('clinicalSuspicionOfAcuteCoronorySyndrome') == 'noneOption';
        } else if(identifier == 'noToAllHighRiskDifferentialDiagnosis'){
            return this.unselectedExplicitFormDetailsCount(identifier) > 1;
        }

        return true;
    }

    isEnabled(identifier) {
        if (identifier != null) {
            return true;
        }
        return false;
    }

    isMandatory(identifier) {
        if(identifier == 'pathwayNotRelevantWarning' || identifier == 'noToAllHighRiskDifferentialDiagnosis'){
            return false;
        }
        return true;
    }

    getValueType() {
        return 'boolean';
    }

    submissionValidation(identifier) {
        if(identifier == 'aorticDissectionCriteria'){
            let self = this;
            let criteriaConflict = new FormValidation('AorticDissectionConflict', function () {
                let value = self.getValue('aorticDissectionPresent');
                let allBooleanValues = [self.getValue('aorticDissectionCriteriaHighRiskConditions'), self.getValue('aorticDissectionCriteriaHighRiskSymptoms'), self.getValue('aorticDissectionCriteriaHighRiskFindings')];
                if (value == false) {
                    if (allBooleanValues.includes(true)) {
                        return [new FormValidationError(FormValidationError.FormErrorType.error, 'Response conflicts with Aortic dissection criteria values')];
                    }
                } else if (value == true) {
                    if (!allBooleanValues.includes(true) && !allBooleanValues.includes(null) && !allBooleanValues.includes(undefined)) {
                        return [new FormValidationError(FormValidationError.FormErrorType.error, 'Response conflicts with Aortic dissection criteria values')];
                    }
                }
                
                return [];
            });
            
            return [criteriaConflict];
        } 
        return [];
    }

    detailString() {
        var finalDetailString;

        let formDetails = this.getFormDetails();
        var valueDescriptions = [];
        var aorticDissectionDescription;

        for (let formDetailIndex in formDetails) {
            let formDetail = formDetails[formDetailIndex];

            let description = formDetail.valueDescription();
            switch (formDetail.identifier) {

                case 'pulmonaryEmbolism':
                    if (description != null && description != '') {
                        valueDescriptions.push(description.slice(0, -1));
                    }
                    break;
                case 'aorticDissectionPresent':
                    if (description != null && description != '') {
                        aorticDissectionDescription = FormsUIGlossary.titlePrefix + ' Aortic Dissection';
                    }
                    break;
                case 'aorticDissectionCriteria':{
                    let subform = this.subform(formDetail.identifier);
                    let aorticDissectionCriteria = subform.detailString(true);
                    if (aorticDissectionCriteria != null && aorticDissectionCriteria.length > 0) {
                        aorticDissectionDescription += '\n' + aorticDissectionCriteria;
                    }
                    break;
                }
                default:
                    if (description != null && description.length > 0) {
                        valueDescriptions.push(description);
                    }

            }
        }

        if (aorticDissectionDescription != null) {
            valueDescriptions = [aorticDissectionDescription].concat(valueDescriptions);
        }

        if(valueDescriptions.length == 0){
            return this.detail;
        }

        finalDetailString = valueDescriptions.join(' \n');
        return finalDetailString;
    }

}