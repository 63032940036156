
import moment from 'moment';

export class DateHelper {

    static momentHumanize(eventDuration) {

        let seconds = Number(eventDuration);
        var w = Math.floor(seconds / 604800); //604800 seconds in a week
        var d = Math.floor((seconds % 604800) / 86400); //86400 in a day
        var h = Math.floor((seconds % 86400) / 3600);
        var m = Math.floor((seconds % 3600) / 60);
        var s = Math.floor(seconds % 3600 % 60);

        let values = [];

        if(w > 0){
            values.push(w + (w == 1 ? ' week' : ' weeks'));
        }

        if(d > 0){
            values.push(d + (d == 1 ? ' day' : ' days'));
        }

        if(h > 0){
            values.push(h + (h == 1 ? ' hour' : ' hours'));
        }

        if(m > 0){
            values.push(m + (m == 1 ? ' minute' : ' minutes'));
        }

        if(s > 0){
            values.push(s + (s == 1 ? ' second' : ' seconds'));
        }

        return values.join(', ');
    }

    static getRelativeDate( date ) {
        let momentDate = moment(date);

        let today = moment(new Date());
        let tomorrow = moment(today).add(1,'days');
        let yesterday = moment(today).add(-1, 'days');
        
        if (momentDate.isSame(today, 'd')){
            return 'Today';
        } else if (momentDate.isSame(yesterday, 'd')){
            return 'Yesterday';
        } else if (momentDate.isSame(tomorrow, 'd')){
            return 'Tomorrow';
        } else {
            return momentDate.format('ddd DD MMMM');
        }
    }

    static getDateAsToday(date){
        let momentDate = moment(date);

        let today = moment(new Date());
        if (momentDate.isSame(today, 'd')){
            return 'Today ' + momentDate.format('HH:mm');
        } else {
            return momentDate.format('ddd DD-MMM-YYYY HH:mm');
        }
    }

    static getDateAsFormat(date, format){
        let momentDate = moment(date);
        return momentDate.format(format);
    }

    static duration(string) {

        if (string == null || string == undefined) {
            return null;
        }

        if(!/[a-z]/.test(string.toString().toLowerCase())){
            if(parseFloat(string) > 60) {
                return parseFloat(string);
            }
        }

        string = string.toString();
        let cleanComponents = [];
        let numeric = /[0-9]+/;
        let nonNumeric = /[^0-9]/;
    
        let remainingString = string.toLowerCase();
        let nextNumber = string.match(numeric);

        while (nextNumber != null) {
            // eg. "for 12h 14m"

            // "12h 14m"
            let numberOnwards = remainingString.substring(nextNumber.index);

            let nextNonNumber = numberOnwards.match(nonNumeric);
            if (nextNonNumber != null) {
                // eg. "for 12h 14m"

                // "12"
                let numberString = numberOnwards.substring(0, nextNonNumber.index);

                // "h 14m"
                let nonNumberString = numberOnwards.substring(nextNonNumber.index);

                let secondNumber = nonNumberString.match(numeric);
                if (secondNumber != null) {
                    // "14m"
                    remainingString = nonNumberString.substring(secondNumber.index);

                    // "h "
                    nonNumberString = nonNumberString.substring(0, secondNumber.index);
                } else {
                    // "h 14m"
                    remainingString = '';
                }

                // "12h "
                let cleanedComponent = numberString + nonNumberString;
                cleanComponents.push(cleanedComponent);

            } else {
                remainingString = '';

                let cleanedComponent = numberOnwards;
                cleanComponents.push(cleanedComponent);
            }
            nextNumber = remainingString.match(numeric);
        }

        let components = cleanComponents;
        if (components.length == 0) {
            return null;
        }

        let duration = 0;

        for (let componentIndex in components) {
            let component = components[componentIndex];
            let nonValueString = component.replace(numeric, '');
            let valueString = component.replace(nonNumeric, '');

            let value = parseFloat(valueString);
            if (value != null) {
                let trimmedString = nonValueString.replace(' ', '').replace('/n', '');
                if (trimmedString.substring(0, 1) == 'w') {
                    duration += value * (60 * 60 * 24 * 7);
                } else if (trimmedString.substring(0, 2) == 'mo') {
                    return null;
                } else if (trimmedString.substring(0, 1) == 'y') {
                    return null;
                } else if (trimmedString.substring(0, 1) == 'd') {
                    duration += value * (60 * 60 * 24);
                } else if (trimmedString.substring(0, 1) == 'h') {
                    duration += value * (60 * 60);
                } else if (trimmedString.substring(0, 1) == 'm') {
                    duration += value * 60;
                } else if (trimmedString.substring(0, 1) == 's') {
                    duration += value;
                } else if (components.length == 1) {
                    duration += value * 60;
                } else if (component.length == 2) {
                    duration += value * (60 * 60);
                } else if (component.length == 3) {
                    duration += value * (60 * 60 * 24);
                }
            }
        }

        return duration > 0 ? duration : null;
    }

    static relativeDate(date){
        let momentDate = moment(date);
        return momentDate.fromNow();
    }

    static h17(date){
        let momentDate = moment(date);
        return momentDate.utc().format('YYYYMMDDHHmmssZ');
    }

}

