import React, { ReactNode, useEffect } from "react";
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from "@azure/msal-react";
import { Box, CircularProgress } from "@mui/material";
import redux from "../state/stores/AppStore";
import LandingPage from "../pages/LandingPage";
import { AuthServerError } from "../routers/AuthHandler";
import { setLoginUser } from "../state/actions/AuthActions";
import { getPathwayIdFromURL } from "../../helpers/URLHelper";
import { clearWorkflow } from "../state/actions/WorkflowResponseActions";
import { clearCalculations, clearSnapshotCalculations } from "../state/actions/CalculationActions";
import { clearActions, clearSnapshotActions } from "../state/actions/ActionActions";
import { clearState } from "../state/actions/SubformStateActions";
import { clearResultsAlert, clearResultsBadge } from "../state/actions/NotificationsActions";

interface AppAuthTemplateProps {
  children: ReactNode;
  authError?: AuthServerError;
}

const AppAuthTemplate: React.FC<AppAuthTemplateProps> = ({ children, authError }) => {
  const { inProgress, accounts } = useMsal();

  useEffect(() => {
    if (accounts && accounts.length > 0) {
      const account = accounts[0];
      // Check if the login account is the same for the redux states owner
      const catchOwnerId = redux.store.getState().authReducer?.loginUserId;
      if (catchOwnerId !== account.localAccountId) {
        // Remove cache and start new session
        const pathwayId = getPathwayIdFromURL();
        if (pathwayId) {
          redux.store.dispatch(clearWorkflow(pathwayId));
          redux.store.dispatch(clearCalculations(pathwayId));
          redux.store.dispatch(clearSnapshotCalculations(pathwayId));
          redux.store.dispatch(clearActions(pathwayId));
          redux.store.dispatch(clearSnapshotActions(pathwayId));
          redux.store.dispatch(clearState(pathwayId));
          redux.store.dispatch(clearResultsAlert(pathwayId));
          redux.store.dispatch(clearResultsBadge(pathwayId));
          redux.store.dispatch(clearWorkflow(pathwayId));
          // Save the new owner id
          redux.store.dispatch(setLoginUser(account.localAccountId));
        }
      }
    }
  }, [accounts]);

  const Loading = () => {
    return (
      <Box
        sx={{
          display: "flex",
          width: "100%",
          mt: 40,
          justifyContent: "center",
          alignContent: "center",
        }}
      >
        <CircularProgress size="3rem" color="info" />
      </Box>
    );
  };

  if (inProgress === "login" || inProgress === "logout") {
    return <Loading />;
  } else {
    return (
      <>
        <AuthenticatedTemplate>{children}</AuthenticatedTemplate>
        <UnauthenticatedTemplate>
          <LandingPage authError={authError} />
        </UnauthenticatedTemplate>
      </>
    );
  }
};

export default AppAuthTemplate;
