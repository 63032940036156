import { FormContentView } from '../../../../waferJS/FormsUI/FormsUI Components/Containers/FormContentView';
import { FormsStyles } from '../../../../waferJS/FormsUI/FormsUI';
import { FormBadge } from '../../../../waferJS/FormsUI/FormsUI Components/Controls/FormBadge';
import React from 'react';
import PropTypes from 'prop-types';
import { FormLabel } from '../../../../waferJS/FormsUI/FormsUI Components/Controls/FormLabel';
import { FormValidationError } from '../../../../waferJS/Form/Validation/FormValidationError';
import { FormPie } from '../../../../waferJS/FormsUI/FormsUI Components/Controls/FormPie';
import { FormButton } from '../../../../waferJS/FormsUI/FormsUI Components/Controls/FormButton';

export class RedFlagFormDetail extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            showOverrideDetail: false,
            canShowOverride: false
        };

        this.updateBadge = this.updateBadge.bind(this);
        this.toggleSubcellVisibility = this.toggleSubcellVisibility.bind(this);
    }

    componentDidMount() {
        document.addEventListener('click', this.handleClick);

        this.updateBadge();
    }

    componentWillUnmount(){
        document.removeEventListener('click', this.handleClick);
    }

    toggleSubcellVisibility(){
        if(!this.state.showOverrideDetail) {
            this.setState({
                showOverrideDetail: true
            });
        } else {
            this.setState({
                showOverrideDetail: false
            });
        }
    }

    componentDidUpdate(){
        this.updateBadge();
    }

    updateBadge(){
        let badgeDetails = this.props.formDetail.form.getBadgeValue(this.props.formDetail.identifier);
        if (badgeDetails != null && badgeDetails[0] != null) {
            if(!this.state.canShowOverride){
                this.setState({canShowOverride: true});
            }
        }
    }

    render() {
        
        let isMandatory = this.props.formDetail.form.isMandatory(this.props.formDetail.identifier);
        let validation = [];
        let subcells = this.props.renderSubcells();

        let formPie;
        let title = this.props.formDetail.title;
        let badge;
        let calculationError = this.props.formDetail.form.calculationError(this.props.formDetail.identifier);

        if(calculationError != null){
            validation.push(<FormLabel key={calculationError.message + this.props.formDetail.identifier} value={calculationError.message} textColor={calculationError.errorType == FormValidationError.FormErrorType.warning ? 'orange' : 'red'} fontSize={0.9} />);
        } else {
            let badgeDetails = this.props.formDetail.form.getBadgeValue(this.props.formDetail.identifier);

            if (badgeDetails != null && badgeDetails[0] != null) {
                badge = <FormBadge value={badgeDetails[0].value} color={badgeDetails[0].color} solid={badgeDetails[0].solid} />;
                if (badgeDetails.title != null) {
                    title = badgeDetails[0].title;
                }
            }

            let progress = this.props.formDetail.form.getProgress(this.props.formDetail.identifier);
            if(progress < 1 && progress > 0){
                formPie = <FormPie totalValue={1} filledValue={progress} />;
            }
        }

        let supportsOverride = this.props.formDetail.form.supportsOverride();
        let overrideReason = this.props.formDetail.form.getOverrideReason(this.props.formDetail.identifier);
        let overrideTag;
        if(supportsOverride) {
            if(overrideReason != null) {
                if(this.props.formDetail.form.isRedFlagActive(this.props.formDetail.identifier) ) {
                    overrideTag = 
                    <div style={{marginLeft: '0.5em', display: 'flex', 'flexDirection': 'row', 'alignItems': 'flex-start', 'justifyContent': 'flex-start'}}>
                        <FormButton key={overrideReason + this.props.formDetail.identifier}  backgroundColor={'transparent'} onPress={this.toggleSubcellVisibility} enabled={true} isSelected={false} title={overrideReason} textSize={0.9}/>
                    </div>;
                } else {
                    overrideTag = <div style={{marginLeft: '0.5em'}}><FormLabel key={overrideReason + this.props.formDetail.identifier} value={overrideReason} textColor={FormsStyles.primaryTint} /></div>;
                }
            } else if(this.props.formDetail.form.isRedFlagActive(this.props.formDetail.identifier) ) {
                overrideTag = 
                <div style={{marginLeft: '0.5em', display: 'flex', flexDirection: 'row'}}>
                    <FormLabel key={overrideReason + this.props.formDetail.identifier} value={'If you determine this red flag is not applicable,'} textColor={FormsStyles.bodyText} fontSize={0.9} />
                    <FormButton key='Cancel' backgroundColor={'transparent'} onPress={this.toggleSubcellVisibility} enabled={true} isSelected={false} title={'you can override the red flag'} textSize={0.9}/>
                </div>;
            }
        }
        else {
            overrideTag = null;
        }
        

        return (
            <div> 
                <FormContentView identifier={this.props.formDetail.identifier} isSubcell={this.props.isSubcell} titleString={title} detailString={this.props.formDetail.detail} topLeftControl={formPie} isMandatory={isMandatory} bottomControl={overrideTag} validation={validation} topRightControl={badge} subcell={this.state.showOverrideDetail ? subcells : []} subcellRef={subcellNode => this.subcellNode = subcellNode} subCellbackgroundColor={FormsStyles.backgroundColor} showHover={true}/>
            </div>
        );
    }
}

RedFlagFormDetail.propTypes = {
    formDetail: PropTypes.object.isRequired,
    isSubcell: PropTypes.bool.isRequired,
    renderErrors: PropTypes.func.isRequired,
    renderSubcells: PropTypes.func.isRequired
};