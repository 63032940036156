import { FormWithRedux } from '../../FormWithRedux';
import { GallbladderPatientDetailsForm } from '../PatientDetails/Gallbladder-PatientDetailsForm';
import { GallbladderRequestionCriteriaForm } from '../ClinicalContext/Gallbladder-RequestionCriteriaForm';
import { GallbladderFurtherInformationForm } from '../ClinicalAssessment/Gallbladder-FurtherInformationForm';
import { GallbladderIntroForm } from '../IntroForm/Gallbladder-IntroForm';
import { GallbladderResultsForm } from '../Results/Gallbladder-ResultsForm';
import { GallbladderActions } from '../Actions/Gallbladder-Actions';
import { GallbladderDependantValuesUpdater } from '../Calculations/Gallbladder-DependantValuesUpdater';
import { GallbladderCalculations } from '../Calculations/Gallbladder-Calculations';

export class GallbladderMainForm extends FormWithRedux {
    
    constructor(formLoader) {
        super('mainForm', formLoader, null, new GallbladderActions(), new GallbladderDependantValuesUpdater(formLoader), new GallbladderCalculations());
    }

    generateSubform(identifier) {
        switch (identifier) {
            case 'introForm':
                return new GallbladderIntroForm(this.formLoader);
            case 'resultsForm':
                return new GallbladderResultsForm(this.formLoader, this, this.actionUpdater, this.dependentValuesUpdater, this.calculationsUpdater);
            case 'patientDetails':
                return new GallbladderPatientDetailsForm(this.formLoader, this, this.actionUpdater, this.dependentValuesUpdater, this.calculationsUpdater);
            case 'requestCriteria':
                return new GallbladderRequestionCriteriaForm(this.formLoader, this, this.actionUpdater, this.dependentValuesUpdater, this.calculationsUpdater);
            case 'furtherInformation':
                return new GallbladderFurtherInformationForm(this.formLoader, this, this.actionUpdater, this.dependentValuesUpdater, this.calculationsUpdater);
        }
    }

    shouldDisplayFormDetailForIdentifier(identifier) {
        super.shouldDisplayFormDetailForIdentifier(identifier);

        return true;
    }

    isEnabled(identifier) {
        if (identifier != null) {
            return true;
        }
        return false;
    }

    isMandatory() {
        return false;
    }

    getValueType() {
        return null;
    }

    submissionValidation() {
        return [];
    }

    detailString() {
        return this.detail;
    }
}