// IMPORT PACKAGE REFERENCES
import React from 'react';
import PropTypes from 'prop-types';
import { FormsStyles } from '../../FormsUI';
import '../../Styles/button.scss';

// COMPONENT
export class FormButton extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            image: props.image,
            color: props.color == null ? FormsStyles.primaryTint : props.color,
            activeColor: props.activeColor == null ? '#FFFFFF' : props.activeColor,
            backgroundColor: props.backgroundColor == null ? FormsStyles.backgroundColor : props.backgroundColor,
            textAlignment: props.textAlignment == null ? 'center' : props.textAlignment,
            enabled: props.enabled == null ? false : props.enabled,
            isSelected: props.isSelected,
            textSize: props.textSize == null ? 1 : props.textSize,
            activeBackgroundColor: props.activeBackgroundColor != null ? props.activeBackgroundColor : FormsStyles.primaryTint,
            ignoreTab: props.ignoreTab == null ? false : props.ignoreTab,
            usingMouse: true
        };

        this.handleClick = this.handleClick.bind(this);
        this.setUsingMouse = this.setUsingMouse.bind(this);
        this.setUsingKeyboard = this.setUsingKeyboard.bind(this);
        this.onClick = this.onClick.bind(this);
    }

    componentDidMount() {
        document.addEventListener('click', this.handleClick);
        document.addEventListener('mousedown', this.setUsingMouse);
        document.addEventListener('keydown', this.setUsingKeyboard);

        this._ismounted = true;
    }

    componentWillUnmount() {
        document.removeEventListener('click', this.handleClick);
        document.removeEventListener('keydown', this.setUsingKeyboard);
        document.removeEventListener('mousedown', this.setUsingMouse);

        this._ismounted = false;
    }

    setUsingMouse() {
        if (this._ismounted) {
            this.setState({ usingMouse: true });
        }
    }

    setUsingKeyboard() {
        if (this._ismounted) {
            this.setState({ usingMouse: false });
        }
    }

    handleClick() {}

    componentDidUpdate(prevProps) {

        if (this.props.image != prevProps.image) {
            this.setState({ image: this.props.image });
        }

        if (this.props.color != prevProps.color) {
            this.setState({ color: this.props.color == null ? FormsStyles.primaryTint : this.props.color, });
        }

        if (this.props.activeColor != prevProps.activeColor) {
            this.setState({ activeColor: this.props.activeColor == null ? '#FFFFFF' : this.props.activeColor });
        }

        if (this.props.backgroundColor != prevProps.backgroundColor) {
            this.setState({ backgroundColor: this.props.backgroundColor == null ? FormsStyles.backgroundColor : this.props.backgroundColor });
        }

        if (this.props.textAlignment != prevProps.textAlignment) {
            this.setState({ textAlignment: this.props.textAlignment == null ? 'center' : this.props.textAlignment });
        }

        if (this.props.enabled != prevProps.enabled) {
            this.setState({ enabled: this.props.enabled == null ? false : this.props.enabled });
        }

        if (this.props.isSelected != this.state.isSelected) {
            this.setState({ isSelected: this.props.isSelected });
        }

        if (this.props.textSize != prevProps.textSize) {
            this.setState({ textSize: this.props.textSize == null ? 1 : this.props.textSize });
        }
    }

    shouldComponentUpdate(nextProps, nextState) {
        
        if(nextProps.image !== this.props.image){
            return true;
        }

        if(nextState.image !== this.state.image){
            return true;
        }

        if(nextProps.color !== this.props.color){
            return true;
        }

        if(nextState.color !== this.state.color){
            return true;
        }

        if(nextProps.title !== this.props.title){
            return true;
        }

        if(nextProps.activeColor !== this.props.activeColor){
            return true;
        }

        if(nextState.activeColor !== this.state.activeColor){
            return true;
        }
        
        if(nextProps.backgroundColor !== this.props.backgroundColor){
            return true;
        }

        if(nextState.backgroundColor !== this.state.backgroundColor){
            return true;
        }

        if(nextProps.textAlignment !== this.props.textAlignment){
            return true;
        }

        if(nextState.textAlignment !== this.state.textAlignment){
            return true;
        }

        if(nextProps.enabled !== this.props.enabled){
            return true;
        }

        if(nextState.enabled !== this.state.enabled){
            return true;
        }

        if(nextProps.isSelected !== this.props.isSelected){
            return true;
        }

        if(nextState.isSelected !== this.state.isSelected){
            return true;
        }

        if(nextProps.textSize !== this.props.textSize){
            return true;
        }

        if(nextState.textSize !== this.state.textSize){
            return true;
        }

        return false;
    }

    onClick(e){
        if(this.props.onPress != null){
            this.props.onPress(e);
        }
    }
    
    render() {
        const ButtonContainerStyle = {
            backgroundColor: !this.state.isSelected ? this.state.backgroundColor : this.state.activeBackgroundColor,
            textAlign: this.state.textAlignment,
            color: this.state.isSelected ? this.state.activeColor : this.state.color,
        };

        const ButtonStyle = {
            textAlign: this.state.textAlignment,
            color: this.state.isSelected ? this.state.activeColor : this.state.color,
            paddingLeft: ButtonContainerStyle.backgroundColor != 'transparent' && (this.props.title != null && this.props.title != '') ? '2em' : '0em',
            paddingRight: ButtonContainerStyle.backgroundColor != 'transparent' && (this.props.title != null && this.props.title != '') ? '2em' : '0em',
            paddingTop: ButtonContainerStyle.backgroundColor != 'transparent' ? '0.3em' : '0em',
            paddingBottom: ButtonContainerStyle.backgroundColor != 'transparent' ? '0.3em' : '0em',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignContent: 'center',
            alignItems: 'center',
            fontSize: this.state.textSize.toString() + 'em'
        };

        const imageStyle = {
            paddingBottom: this.props.image != null ? '0.1em' : '0em',
            userSelect: 'none',
            marginLeft: this.props.title != null && this.props.title != '' ? '0.2em' : '0' ,
        };

        const buttonContentStyle = {
            display: 'flex',
            flexDirection: 'row',
            width: '100%'
        };

        return (
            <div className={'formButton ' + (this.state.isSelected ? '' : 'unselected ') + (this.state.ignoreTab ? 'hideInPrint ' :  '')} style={{ ...ButtonContainerStyle, ...FormsStyles.enabledStyle(this.state.enabled) }} ref={buttonContainerNode => this.buttonContainerNode = buttonContainerNode}>
                <span ref={buttonNode => this.buttonNode = buttonNode} style={buttonContentStyle} >
                    <button className={this.state.usingMouse ? 'using-mouse' : ''} tabIndex={this.state.ignoreTab ? '-1' : '0'} onClick={this.onClick}>

                        {
                            this.props.leftToRight && 
                            <span style={ButtonStyle}>
                                <span style={imageStyle}>{this.props.image}</span>
                                {this.props.title}
                            </span>
                            
                        }

                        {
                            !this.props.leftToRight && 
                            <span style={ButtonStyle}>
                                {this.props.title}
                                <span style={imageStyle}>{this.props.image}</span>
                            </span>
                        }
                       
                    </button>
                </span>
            </div>
        );
    }


}

FormButton.propTypes = {
    image: PropTypes.element,
    title: PropTypes.string,
    color: PropTypes.string,
    activeColor: PropTypes.string,
    backgroundColor: PropTypes.string,
    activeBackgroundColor: PropTypes.string,
    textAlignment: PropTypes.string,
    enabled: PropTypes.bool,
    onPress: PropTypes.func,
    isSelected: PropTypes.bool.isRequired,
    textSize: PropTypes.number,
    ignoreTab: PropTypes.bool,
    leftToRight: PropTypes.bool
};