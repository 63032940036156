import React, { ReactNode, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSnackbar } from "notistack";
import { FormLoader } from "../../forms/FormLoader";
import { ACCBISTPathwayReference } from "../../forms/concussion01/ACCBIST-PathwayReference";

interface FormRouteHandlerProp {
  children: ReactNode;
  formLoader: FormLoader;
}

const FormRouteHandler: React.FC<FormRouteHandlerProp> = ({ children, formLoader }) => {
  const { pathwayId, formId } = useParams();
  const { enqueueSnackbar } = useSnackbar();

  const navigate = useNavigate();
  const [currentFormId, setCurrentFormId] = useState<string>();

  useEffect(() => {
    if (pathwayId === "accbist01") {
      // Validate if the Patient Details are completed when jumping from Patient Details to any form
      if (formId !== "patientDetailsSubform" && currentFormId === "patientDetailsSubform") {
        // Check if the mandatory fields are populated
        const existIncompleteField = ACCBISTPathwayReference.data[
          "template.content"
        ].patientDetailsSubform.mandatoryFields.some((fieldId) => {
          const fieldValue = formLoader.formDetail(pathwayId, fieldId)?.editingValue;
          return !fieldValue;
        });

        if (existIncompleteField) {
          enqueueSnackbar("Please complete the Patient Details form first", {
            variant: "warning",
          });

          navigate("/" + pathwayId + "/" + currentFormId);
          return;
        }
      }
      setCurrentFormId(formId);
    }
  }, [pathwayId, formId]);

  return children;
};

export default FormRouteHandler;
