import { FormWithRedux } from '../../FormWithRedux';
import { EDChestPainStemiCriteriaForm } from './EDChestPain-StemiCriteriaForm';
import { EDChestPainAbnormalitiesForm } from './EDChestPain-AbnormalitiesForm';
import { FormsUIGlossary } from '../../../waferJS/FormsUI/FormsUI';
import { FormValidation } from '../../../waferJS/Form/Validation/FormValidation';
import { FormValidationError } from '../../../waferJS/Form/Validation/FormValidationError';

export class EDChestPainECGForm extends FormWithRedux {
    
    constructor(formLoader, parent, actionUpdater, dependentValuesUpdater, calculationsUpdater) {
        super('ecgTest',formLoader, parent, actionUpdater, dependentValuesUpdater, calculationsUpdater);
    }

    generateSubform(identifier) {
        switch (identifier) {
            case 'stemiCriteria':
                return new EDChestPainStemiCriteriaForm(identifier, this.formLoader, this.parent, this.actionUpdater, this.dependentValuesUpdater, this.calculationsUpdater);
            case 'abnormalitiesCriteria':
                return new EDChestPainAbnormalitiesForm(identifier, this.formLoader, this.parent, this.actionUpdater, this.dependentValuesUpdater, this.calculationsUpdater);
        }
    }

    shouldDisplayFormDetailForIdentifier(identifier) {
        super.shouldDisplayFormDetailForIdentifier(identifier);
        if (identifier === 'stemiCriteria') {
            let dependantValue = this.getValue('stemiPresent');
            if(dependantValue == true || dependantValue == null){
                return true;
            } else {
                let dependantValues = [this.getValue('stemiCriteriaV4toV6'), this.getValue('stemiCriteriaV1toV3'), this.getValue('stemiCriteriaV7toV9'), 
                    this.getValue('stemiCriteriaNewLBBB'), this.getValue('stemiCriteriaLBBBWithPositiveQRS'),this.getValue('stemiCriteriaLBBBWithNegativeQRS')];
                return dependantValues.includes(false) || dependantValues.includes(true);
            }
        } else if (identifier === 'abnormalitiesCriteria') {
            let dependantValue = this.getValue('abnormalitiesPresent');
            if(dependantValue == true || dependantValue == null){
                return true;
            } else {
                let dependantValues = [this.getValue('newIschaemia'), this.getValue('oldIschaemia'), this.getValue('oldLBBB'), this.getValue('nonSpecificSTorTChanges'), this.getValue('alternativeFinding')];
                return dependantValues.includes(false) || dependantValues.includes(true);
            }
        } else {
            super.shouldDisplayFormDetailForIdentifier(identifier);
        }
        return true;
    }

    isEnabled(identifier) {
        if (identifier != null) {
            return true;
        }
        return false;
    }

    isMandatory(identifier) {
        if (identifier != null) {
            return true;
        }
        return false;
    }

    getValueType(identifier) {
        if (identifier == 'stemiPresent' || identifier == 'abnormalitiesPresent') {
            return 'boolean';
        } 

        return null;
    }

    //

    submissionValidation(identifier) {
        if(identifier == 'stemiCriteria'){
            let self = this;
            let criteriaConflict = new FormValidation('StemiPresentConflict', function () {
                let value = self.getValue('stemiPresent');
                let allBooleanValues = [self.getValue('stemiCriteriaV4toV6'), self.getValue('stemiCriteriaV1toV3'), self.getValue('stemiCriteriaV7toV9'), self.getValue('stemiCriteriaNewLBBB'), self.getValue('stemiCriteriaLBBBWithPositiveQRS'), self.getValue('stemiCriteriaLBBBWithNegativeQRS')];
                if (value == false) {
                    if (allBooleanValues.includes(true)) {
                        return [new FormValidationError(FormValidationError.FormErrorType.error, 'Modify STEMI criteria to match selection')];
                    }
                } else if (value == true){
                    if (!allBooleanValues.includes(true) && !allBooleanValues.includes(null) && !allBooleanValues.includes(undefined)) {
                        return [new FormValidationError(FormValidationError.FormErrorType.error, 'Modify STEMI criteria to match selection')];
                    }
                }
                return [];
            });
                
            return [criteriaConflict];
        } else if (identifier == 'abnormalitiesCriteria'){
            let self = this;
            let criteriaConflict = new FormValidation('AbnormalitiesPresentConflict', function () {
                let value = self.getValue('abnormalitiesPresent');
                let allBooleanValues = [self.getValue('newIschaemia'), self.getValue('oldIschaemia'), self.getValue('oldLBBB'), self.getValue('nonSpecificSTorTChanges'), self.getValue('alternativeFinding')];
                if (value == false) {
                    if (allBooleanValues.includes(true)) {
                        return [new FormValidationError(FormValidationError.FormErrorType.error, 'Modify Other abnormalities to match selection')];
                    }
                } else if (value == true){
                    if (!allBooleanValues.includes(true) && !allBooleanValues.includes(null) && !allBooleanValues.includes(undefined)) {
                        return [new FormValidationError(FormValidationError.FormErrorType.error, 'Modify Other abnormalities to match selection')];
                    }
                }
                    
                return [];
            });
                
            return [criteriaConflict];
        }
        return [];
    }

    detailString() {
        let formDetails = this.getFormDetails();
        var valueDescriptions = [];

        var stemiDescription;
        var abnormalitiesDescription;

        for (let formDetailIndex in formDetails) {
            let formDetail = formDetails[formDetailIndex];

            switch (formDetail.identifier) {
                case 'stemiCriteria': {
                    let subform = this.subform(formDetail.identifier);
                    let criteriaSummary = subform.detailString(true);
                    if (criteriaSummary != null && criteriaSummary.length > 0) {
                        stemiDescription = FormsUIGlossary.titlePrefix + ' STEMI criteria';
                        stemiDescription += '\n' + criteriaSummary;
                    }
                    break;
                }
                case 'abnormalitiesCriteria': {
                    let subform = this.subform(formDetail.identifier);
                    let criteriaSummary = subform.detailString(true);
                    if (criteriaSummary != null && criteriaSummary.length > 0) {
                        abnormalitiesDescription = FormsUIGlossary.titlePrefix + ' Other abnormalities';
                        abnormalitiesDescription += '\n' + criteriaSummary;
                    }
                }
            }
        }

        if (stemiDescription != null) {
            valueDescriptions.push(stemiDescription);
        }

        if (abnormalitiesDescription != null) {
            valueDescriptions.push(abnormalitiesDescription);
        }

        if(valueDescriptions.length == 0){
            return this.detail;
        }

        return valueDescriptions.join('\n');
    }
}