// IMPORT PACKAGE REFERENCES
import React from 'react';
import PropTypes from 'prop-types';
import { FormsStyles } from '../../FormsUI';

// COMPONENT

export class FormRadio extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        const RadioContainerStyle = {
            borderStyle: 'solid',
            borderColor: FormsStyles.secondaryTint,
            borderWidth: '2px',
            borderRadius: '200px',
            height: '26px',
            width: '26px',
            cursor: 'pointer',
            padding: '2px'
        };

        const RadioInnerStyle = {
            backgroundColor: this.props.isChecked ? '#019CFF' : 'transparent',
            width: '100%',
            height: '100%',
            borderRadius: '200px',
        };

        const RadioStyle = {
            width: '100%',
            height: '100%',
            backgroundColor: 'transparent',
            borderStyle: 'none',
            outline: 'none',
            cursor: 'pointer',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignContent: 'center',
            alignItems: 'center',
            padding: '0'
        };

        const radioTickStyle = {
            height: '100%',
            width: '100%',
        };

        return (
            <div className='form-radio' style={RadioContainerStyle} tabIndex="-1">
                <div className='form-radio-inner' style={RadioInnerStyle}>
                    <button onClick={this.props.onChange} style={RadioStyle} tabIndex="-1">
                        {this.props.isChecked && <img className="radio-tick" style={radioTickStyle} src={require('../../../../images/tick.png')} />}
                    </button>
                </div>
            </div>
        );
    }
}

FormRadio.propTypes = {
    isChecked: PropTypes.bool.isRequired,
    onChange: PropTypes.func.isRequired
};