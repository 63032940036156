// IMPORT PACKAGE REFERENCES
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import moment from 'moment';
import { PathwayReferenceHelper } from '../../forms/PathwayReferenceHelper';
import { ReferenceDataLoader } from '../../forms/ReferenceDataLoader';
import { withRouter } from '../routers/withRouter';

// COMPONENT
class PrintHeader extends React.Component {

    constructor(props) {
        super(props);
        this.formatDate = this.formatDate.bind(this);
    }

    formatDate(date, format){
        if(date == null){ return null;}
        return moment(date).format(format);
    }

    render() {
        let version = this.props.workflows[this.props.pathwayId] != null ?  this.props.workflows[this.props.pathwayId].version : '';
        let lastModifiedDate = this.props.workflows[this.props.pathwayId] != null ? this.formatDate(this.props.workflows[this.props.pathwayId].lastModifiedDate, 'ddd DD-MMM-YYYY HH:mm')  : '';
        return (
            <div id="heading" className={!this.props.forceVisibility ? 'print-only' : ''}>
                <h1>{PathwayReferenceHelper.reference('title', ReferenceDataLoader.referenceData(this.props.pathwayId))}</h1>
                <label>Document Version: {version}</label>
                <br/>
                <label>Last Modified: {lastModifiedDate}</label>
                <br/>
                <label>Date of Export: {this.formatDate(Date.now(), 'ddd DD-MMM-YYYY HH:mm')}</label>
            </div>
        );
    }
}

PrintHeader.propTypes = {
    location: PropTypes.object.isRequired,
    navigate: PropTypes.func.isRequired,
    workflows: PropTypes.object.isRequired,
    forceVisibility: PropTypes.bool.isRequired,
    pathwayId: PropTypes.string.isRequired
};


// CONFIGURE REACT REDUX
const mapStateToProps = state => {
    const { workflows, updatingWorkflowPending, updatingWorkflowFulfilled, updatingWorkflowFailed } = state.workflowReducer;
    return { workflows, updatingWorkflowPending, updatingWorkflowFulfilled, updatingWorkflowFailed };
};

const mapDispatchToProps = dispatch => (
    bindActionCreators({ }, dispatch)
);

const hoc = withRouter(connect(mapStateToProps, mapDispatchToProps)(PrintHeader));

// EXPORT COMPONENT

export { hoc as PrintHeader };