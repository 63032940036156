import { FormWithRedux } from '../../FormWithRedux';
import { IsDate, IsDateInPastError } from '../../../waferJS/Form To FormsUI/Subclasses/Validation Overrides/DateTimeValidation';
import { IsNumber, IsPositive } from '../../../waferJS/Form To FormsUI/Subclasses/Validation Overrides/DecimalNumberValidaton';
import { DateHelper } from '../../../waferJS/Helpers/DateHelper';
import { FormValidationError } from '../../../waferJS/Form/Validation/FormValidationError';
import { FormValidation } from '../../../waferJS/Form/Validation/FormValidation';
import { FormsUIGlossary } from '../../../waferJS/FormsUI/FormsUI';
import { GPChestPainKeyHistoricalInformationForm } from './GPChestPain-KeyHistoricalInformationForm';
import { GPChestPainKeyClinicalFeaturesForm } from './GPChestPain-KeyClinicalFeaturesForm';


export class GPChestPainClinicalAssessmentForm extends FormWithRedux {

    constructor(formLoader, parent, actionUpdater, dependentValuesUpdater, calculationsUpdater) {
        super('initialAssessment', formLoader, parent, actionUpdater, dependentValuesUpdater, calculationsUpdater);
    }

    generateSubform(identifier) {
        switch (identifier) {
            case 'keyHistoricalInformation':
                return new GPChestPainKeyHistoricalInformationForm(identifier, this.formLoader, this.parent, this.actionUpdater, this.dependentValuesUpdater, this.calculationsUpdater);
            case 'keyClinicalFeatures':
                return new GPChestPainKeyClinicalFeaturesForm(identifier, this.formLoader, this.parent, this.actionUpdater, this.dependentValuesUpdater, this.calculationsUpdater);
        }
    }

    shouldDisplayFormDetailForIdentifier(identifier) {
        super.shouldDisplayFormDetailForIdentifier(identifier);
        if (identifier == 'worstDateTime' || identifier == 'worstDuration') {
            let dependantValue = this.getValue('painWorstAt');
            if (dependantValue == null || dependantValue == 'firstEpisodeOption') {
                return false;
            }
            return true;
        }
        return true;
    }

    isEnabled(identifier) {
        if (identifier != null) {
            return true;
        }
        return false;
    }

    isMandatory(identifier) {
        if(identifier == 'worstDuration' || identifier == 'worstDateTime') {
            let dependantValue = this.getValue('painWorstAt');
            return dependantValue == 'otherEpisodeOption';
        } 
        return true;
    }

    getValueType(identifier) {
        if (identifier == 'firstDateTime' || identifier == 'worstDateTime') {
            return 'date';
        } else if (identifier == 'firstDuration' || identifier == 'worstDuration') {
            return 'double';
        } else if (identifier == 'painWorstAt') {
            return 'string';
        }
        return null;
    }

    submissionValidation(identifier) {
        if (identifier == 'firstDateTime') {
            return [IsDate, IsDateInPastError];
        } else if (identifier == 'firstDuration') {
            return [IsNumber, IsPositive];
        } else if (identifier == 'painWorstAt') {
            return [];
        } else if (identifier == 'worstDateTime') {
            let self = this;
            let worstAfterFirst = new FormValidation('WorstAfterFirst', function (value) {
                let firstDateTime = self.getValue('firstDateTime');
                if(firstDateTime != null && value != null && value < new Date(firstDateTime)){
                    return [new FormValidationError(FormValidationError.FormErrorType.warning, 'Worst episode must be after the first episode')];
                }
                    
                return [];
            });
            return [IsDate, IsDateInPastError, worstAfterFirst];
        } else if (identifier == 'worstDuration') {
            return [IsNumber];
        }
    }

    editingValidation(identifier) {
        if (identifier == 'firstDateTime') {
            return [];
        } else if (identifier == 'firstDuration') {
            return [IsNumber];
        } else if (identifier == 'painWorstAt') {
            return [];
        } else if (identifier == 'worstDateTime') {
            return [];
        } else if (identifier == 'worstDuration') {
            return [IsNumber];
        }
    }

    detailString() {
        let formDetails = this.getFormDetails();

        var firstEpisodeValueDescriptions = [];
        var otherEpisodeValueDescriptions = [];
        var valueDescriptions = [];

        for (let formDetailIndex in formDetails) {
            let formDetail = formDetails[formDetailIndex];

            let description = formDetail.valueDescription();

            switch (formDetail.identifier) {
                case 'firstDateTime':
                    if (description != null && description != false) {
                        firstEpisodeValueDescriptions.push(description);
                    }
                    break;
                case 'firstDuration':
                    if (description != null && description != false) {
                        firstEpisodeValueDescriptions.push(description);
                    }
                    break;
                case 'painWorstAt':
                    // Do not add worst episode to the detail string
                    break;
                case 'worstDateTime':
                    if (description != null && description != false) {
                        otherEpisodeValueDescriptions.push(description);
                    }
                    break;
                case 'worstDuration':
                    if (description != null && description != false) {
                        otherEpisodeValueDescriptions.push(DateHelper.momentHumanize(description, 'minutes'));
                    }
                    break;
                case 'keyHistoricalInformation':{
                    let subform = this.subform(formDetail.identifier);
                    let detail = subform.detailString(true);
                    if (detail != null && detail != ''){
                        valueDescriptions.push(FormsUIGlossary.titlePrefix + subform.title + '\n' + detail);
                    }
                    break;
                }
                case 'keyClinicalFeatures': {
                    let subform = this.subform(formDetail.identifier);
                    let detail = subform.detailString(true);
                    if (detail != null && detail != ''){
                        valueDescriptions.push(FormsUIGlossary.titlePrefix + subform.title + '\n' + detail);
                    }
                    break;
                }
                default:
                    if (description != null) {
                        valueDescriptions.push(description);
                    }
            }
        }

        if (!otherEpisodeValueDescriptions.length == 0) {
            let values = otherEpisodeValueDescriptions.join(', ');
            let finalOtherEpisodeDescription = ['Worst episode', values].join(': ');
            valueDescriptions.splice(0, 0, finalOtherEpisodeDescription);
        }

        if (!firstEpisodeValueDescriptions.length == 0) {
            let values = firstEpisodeValueDescriptions.join(', ');
            let finalFirstEpisodeDescription = ['First episode', values].join(': ');
            valueDescriptions.splice(0, 0, finalFirstEpisodeDescription);
        }

        valueDescriptions = valueDescriptions.filter((value) => { return value != '';});
        
        if (valueDescriptions.length == 0) {
            return this.detail;
        }

        else {
            let finalDetailString = valueDescriptions.join('\n');
            return finalDetailString;
        }
    }

}