// IMPORT PACKAGE REFERENCES
import React from 'react';
import PropTypes from 'prop-types';
import 'react-day-picker/dist/style.css';
import { FormTextField } from './FormTextField';
import { FormsStyles } from '../../FormsUI';
import moment from 'moment';
import '../../Styles/datePicker.scss';
import { FormLabel } from './FormLabel';

const formattedDate = 'DD-MMM-YYYY';
const formattedTime = 'HH:mm';

const dateFormats = [formattedDate, 'DD/MM/YYYY', 'DD-MM-YYYY'];
const timeFormats = [formattedTime, 'hh:mm a'];

export class FormDateTime extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            dateTextFieldFocused: false,
            timeTextFieldFocused: true,
            dateStringValid: true,
            timeStringValid: true,
        };

        this.dateStringChanged = this.dateStringChanged.bind(this);
        this.dateTextFieldFocus = this.dateTextFieldFocus.bind(this);
        this.onDateFieldKeyPress = this.onDateFieldKeyPress.bind(this);
        this.dateTextFieldBlur = this.dateTextFieldBlur.bind(this);
        this.timeTextFieldFocus = this.timeTextFieldFocus.bind(this);
        this.timeStringChanged = this.timeStringChanged.bind(this);
        this.formatValue = this.formatValue.bind(this);
        this.timeTextFieldBlur = this.timeTextFieldBlur.bind(this);
    }

    componentDidMount() {
        this.formatValue();
        document.addEventListener('click', this.handleClick);

        let self = this;
        this.props.adoptTodayString(function() {
            self.setState({ dateString: moment().format(formattedDate) }, self.updateDateString);
            self.props.toggleTodayButton(false);
        });
    }

    formatValue(){
        let dateString = this.props.value == null || this.props.value == undefined ? '' : moment(this.props.value).format(formattedDate);
        let timeString = this.props.value == null || this.props.value == undefined ? '' : moment(this.props.value).format(formattedTime);

        this.setState({
            date: this.props.value == null ? this.props.value : moment(this.props.value).toDate(),
            dateString: dateString,
            timeString: timeString
        });
    }

    componentDidUpdate(prevProps){
        if(this.props.value != prevProps.value){
            this.formatValue();
        }
    }

    dateStringChanged(value) {
        if(this.isStringEmtpy(value)){
            this.setState({
                dateString: null,
            });
            this.props.toggleTodayButton(true);
        } else {
            this.setState({dateString: value});
            this.props.toggleTodayButton(false);
        }
    }

    timeStringChanged(value){
        if(this.isStringEmtpy(value)){
            this.setState({
                timeString: null,
            });
            this.props.toggleNowButton(true);
        } else {
            this.setState({timeString: value});
            this.props.toggleNowButton(false);
        }
    }

    updateTimeString(){
        let momentDate;
        for(let timeFormat of timeFormats){
            let testDate = moment(this.state.timeString, timeFormat);
            if(testDate.toDate() != 'Invalid Date' && testDate.isValid){
                momentDate = moment(this.state.timeString, timeFormat);
            }
        }

        let timeStringInvalid = momentDate == null || !momentDate.isValid || momentDate.format(formattedTime) == 'Invalid date';
        let timeString = timeStringInvalid ? this.state.timeString : momentDate.format(formattedTime);

        this.setState({timeStringValid: !timeStringInvalid}, this.updateErrors);

        this.setState({
            timeString: timeString
        },this.updateDate);
    }

    updateDateString(){
        let momentDate;
        for(let dateFormat of dateFormats){
            let testDate = moment(this.state.dateString, dateFormat);
            if(testDate.toDate() != 'Invalid Date' && testDate.isValid){
                momentDate = moment(this.state.dateString, dateFormat);
            }
        }
        let dateStringInvalid = momentDate == null || !momentDate.isValid || momentDate.format(formattedDate) == 'Invalid date';
        let dateString = dateStringInvalid ? this.state.dateString : momentDate.format(formattedDate);

        this.setState({dateStringValid: !dateStringInvalid},this.updateErrors);

        this.setState({
            dateString: dateString
        },this.updateDate);
    }

    updateErrors(){
        if(!this.state.dateStringValid && !this.state.timeStringValid && this.stringNotEmpty(this.state.dateString) && this.stringNotEmpty(this.state.timeString))  {
            let date = moment().format(formattedDate + ' ' + formattedTime);
            this.props.dateValidation([<FormLabel key={'invalidDateFormat'} value={'Recommended date time format: \'' + date + '\''} textColor={FormsStyles.red} />]);
        } else if(!this.state.dateStringValid && this.stringNotEmpty(this.state.dateString)){
            let date = moment().format(formattedDate);
            this.props.dateValidation([<FormLabel key={'invalidDateFormat'} value={'Recommended date format: \'' + date + '\''} textColor={FormsStyles.red} />]);
        } else if(!this.state.timeStringValid && this.stringNotEmpty(this.state.timeString)) {
            let date = moment().format(formattedTime);
            this.props.dateValidation([<FormLabel key={'invalidDateFormat'} value={'Recommended time format: \'' + date + '\''} textColor={FormsStyles.red} />]);
        } else {
            this.props.dateValidation([]);
        }
    }

    stringNotEmpty(string){
        return string != null && string != undefined && string != '' && /\S/.test(string);
    }

    isStringEmtpy(string){
        return string == null || string == undefined || string == '';
    }

    dateTextFieldFocus() {
        this.setState({
            dateTextFieldFocused: true
        });
    }

    dateTextFieldBlur(value) {
        this.setState({
            dateTextFieldFocused: false,
            dateString: value
        });
        this.updateTimeString(); 
        this.updateDateString();
    }

    timeTextFieldFocus() {
        this.setState({
            pickerVisible: false,
            timeTextFieldFocused: true
        });
    }

    timeTextFieldBlur(value){
        this.setState({
            timeTextFieldFocused: false,
            timeString: value
        });
        this.updateTimeString(); 
        this.updateDateString();
    }

    onDateFieldKeyPress(event) {
        if (event != undefined && event.key === 'Enter') {
            event.target.blur();
        }
    }

    updateDate(){
        if(this.state.dateString != null && this.state.timeString != null && this.state.dateString != '' && this.state.timeString != '' && this.state.timeStringValid && this.state.dateStringValid){
            let combinedDate = moment(this.state.dateString + ' ' + this.state.timeString, formattedDate + ' ' + formattedTime);
            if(combinedDate.toDate() != 'Invalid Date'){
                this.props.dateSelected(combinedDate.toDate());
            }
        } else if((this.state.dateString == null || this.state.dateString == '') && (this.state.timeString == null || this.state.timeString == '')){
            this.props.dateSelected(null);
        }
    }

    render() {
        let textFieldsContainer = {
            display: 'flex',
            flexDirection: 'row',
        };

        let textField = {
            marginLeft: '0.5em'
        };

        return (
            <div className='form-date-picker'>
                <div className='form-date-textfields-container' style={textFieldsContainer}>
                    <div ref={dateTextFieldNode => this.dateTextFieldNode = dateTextFieldNode} className="form-date-textfield">
                        <FormTextField onDateFieldKeyPress={this.onDateFieldKeyPress} onChange={this.dateStringChanged} placeholder={this.props.isMandatory ? 'Date' : 'Date'} value={this.state.dateString} unitText={this.props.unitText} onFocus={this.dateTextFieldFocus} shouldDelayResponse={false} onBlur={this.dateTextFieldBlur} width={'8.3em'} color={this.props.hasError ? FormsStyles.red : null}/>
                    </div>
                    <div ref={timeTextFieldNode => this.timeTextFieldNode = timeTextFieldNode} className="form-date-textfield" style={textField}>
                        <FormTextField onChange={this.timeStringChanged} placeholder={this.props.isMandatory ? 'Time' : 'Time'} value={this.state.timeString} unitText={this.props.unitText} onFocus={this.timeTextFieldFocus} onBlur={this.timeTextFieldBlur} width={'6.2em'} shouldDelayResponse={false}  color={this.props.hasError ? FormsStyles.red : null}/>
                    </div>
                </div>
            </div>
        );
    }
}

FormDateTime.propTypes = {
    value: PropTypes.string,
    withTime: PropTypes.bool.isRequired,
    dateSelected: PropTypes.func.isRequired,
    unitText: PropTypes.string,
    isMandatory: PropTypes.bool.isRequired,
    hasError: PropTypes.bool.isRequired,
    toggleNowButton: PropTypes.func.isRequired,
    toggleTodayButton: PropTypes.func.isRequired,
    toggleSuggestions: PropTypes.func.isRequired,
    adoptTodayString: PropTypes.func.isRequired,
    dateValidation: PropTypes.func.isRequired
};