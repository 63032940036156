import { FormContentView } from '../../FormsUI/FormsUI Components/Containers/FormContentView';
import { FormsStyles } from '../../FormsUI/FormsUI';
import React from 'react';
import PropTypes from 'prop-types';
import { FormLabel } from '../../FormsUI/FormsUI Components/Controls/FormLabel';
import { ActionModel } from '../../../forms/Shared Custom Types/ActionModel';
import { FormBadge } from '../../FormsUI/FormsUI Components/Controls/FormBadge';

export class ActionFormDetail extends React.Component {

    constructor(props) {
        super(props);
    }

    componentWillUnmount(){
        if (typeof this.props.formDetail.form.markActionAsObserved === 'function'){
            this.props.formDetail.form.markActionAsObserved(this.props.formDetail.identifier);
        }
    }

    shouldComponentUpdate(nextProps) {
        if(nextProps.formDetail.editingValue !== this.props.formDetail.editingValue){
            return true;
        }

        if(nextProps.isSubcell !== this.props.isSubcell){
            return true;
        }

        return false;
    }

    render() {
        let isMandatory = this.props.formDetail.form.isMandatory(this.props.formDetail.identifier);

        let title = this.props.formDetail.title;
        let detail = this.props.formDetail.detail; 

        let action = this.props.formDetail.form.actionForIdentifier(this.props.formDetail.identifier);
        let bottomControl;
        let controls = [];

        let badge;

        let actionModel = new ActionModel(action.pathwayUuid, action.versionUuid, action.identifier, action.title, action.detail, action.priority, action.observed, action.removeAfterObservation, action.lastModifiedDate, action.uuid, action.scheduledAction);
        title = actionModel.title != null ? actionModel.title : title;
        detail = actionModel.detail != null ? actionModel.detail : detail;
        if(action != null && !action.removeAfterObservation){
            if(!actionModel.observed && !actionModel.removeAfterObservation){
                controls.push(<FormLabel key={'new' + this.props.formDetail.identifier} value={'New'} textColor={FormsStyles.primaryTint}/>);
            }

            let badgeDetails = this.props.formDetail.form.getBadgeValue(this.props.formDetail.identifier);
            if (badgeDetails != null && badgeDetails[0] != null) {
                badge = <FormBadge value={badgeDetails[0].value} color={badgeDetails[0].color} solid={badgeDetails[0].solid} />;
            }
            
            if(actionModel.scheduledAction != null){
                let string = actionModel.scheduledActionDetailStringSimple(false);
                
                if(string != null){
                    if(controls.length > 0){
                        controls.push(<FormLabel key={'scheduledAction-comma' + this.props.formDetail.identifier} value={', '} textColor={FormsStyles.grey}/>);
                    }
                    controls.push(<FormLabel key={'scheduledAction' + this.props.formDetail.identifier} value={string} textColor={FormsStyles.orange}/>);
                }

                if(actionModel.scheduledAction.completed){
                    if(controls.length > 0){
                        controls.push(<FormLabel key={'completed-comma' + this.props.formDetail.identifier} value={', '} textColor={FormsStyles.grey}/>);
                    }
                    controls.push(<FormLabel key={'Completed' + this.props.formDetail.identifier} value={'Completed'} textColor={FormsStyles.green}/>);
                }
            }

            bottomControl = <span className="detail-list-span-horizontal"> 
                {controls.map((control) => {
                    return control;
                })}
            </span>;
        }

        let strikeThrough = action != null ? action.removeAfterObservation : false;

        return (
            <FormContentView titleString={title} detailString={detail} isMandatory={isMandatory} strikeThrough={ strikeThrough } validation={[]} subcell={[]} identifier={this.props.formDetail.identifier} subCellbackgroundColor={FormsStyles.backgroundColor} topRightControl={badge} bottomControl={bottomControl} showHover={true} isSubcell={this.props.isSubcell}/>
        );
    }
}

ActionFormDetail.propTypes = {
    formDetail: PropTypes.object.isRequired,
    isSubcell: PropTypes.bool.isRequired,
    renderErrors: PropTypes.func.isRequired,
    renderSubcells: PropTypes.func.isRequired,
    errorLabels: PropTypes.array.isRequired,
    hasError: PropTypes.bool.isRequired
};