
//import axios from 'axios';
import { ENABLED_EXPORTS } from '../../../config/config';

export const EXPORT_TO_HCS_PENDING = 'EXPORT_TO_HCS_PENDING';
export const EXPORT_TO_HCS_FULFILLED = 'EXPORT_TO_HCS_FULFILLED';
export const EXPORT_TO_HCS_FAILED = 'EXPORT_TO_HCS_FAILED';

export const RESET_EXPORT_STATE = 'RESET_EXPORT_STATE';

const exportToHCS = (data) => (dispatch) => {
    dispatch({
        type: EXPORT_TO_HCS_PENDING
    });

    let HCS_API_ROOT = null;

    // let headers = {
    //     'Content-Type': 'application/json'
    // };

    // let postData = JSON.stringify(data);

    if (ENABLED_EXPORTS.includes('hcs') == false) {
        console.log('HCS_API_ROOT not enabled for environment.');
        return dispatch({
            type: EXPORT_TO_HCS_FAILED
        });
    }

    if (HCS_API_ROOT === null || HCS_API_ROOT == undefined) {
        console.log('HCS_API_ROOT not defined for environment.');
        return dispatch({
            type: EXPORT_TO_HCS_FAILED
        });
    }

    return postData(`${HCS_API_ROOT}receiveChestpainDocument`, data).then((response) => {
        console.log(response);
        return dispatch({
            type: EXPORT_TO_HCS_FULFILLED
        });
    }).catch((error) => {
        console.log(error);
        return dispatch({
            type: EXPORT_TO_HCS_FAILED
        });
    });

    // axios.post(`${HCS_API_ROOT}receiveChestpainDocument`, postData, { headers: headers }).then((response) => {
    //     console.log(response);
    //     dispatch({
    //         type: EXPORT_TO_HCS_FULFILLED
    //     });
    // }).catch((error) => {
    //     console.log(error);
    //     dispatch({
    //         type: EXPORT_TO_HCS_FAILED
    //     });
    // });
};

const resetState = () => (dispatch) => {
    dispatch({
        type: RESET_EXPORT_STATE
    });
};

function postData(url = '', data = {}) {
    // Default options are marked with *
    return fetch(url, {
        method: 'POST',
        mode: 'cors', 
        cache: 'no-cache', 
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json'
        },
        redirect: 'follow',
        referrer: 'no-referrer',
        body: JSON.stringify(data), 
    }).then(response => response.json()); 
}

export { exportToHCS, resetState };