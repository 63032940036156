// IMPORT PACKAGE REFERENCES
import React from 'react';
import PropTypes from 'prop-types';
import { FormsStyles } from '../../FormsUI';
import { PatternFormat } from 'react-number-format';

// COMPONENT
export class FormMaskedTextField extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            value: props.value == null ? undefined : props.value,
            placeholder: props.placeholder,
            unitText: props.unitText,
            shouldShowUnit: props.value == null ? false : true,
            alignment: props.alignment == null ? 'right' : props.alignment,
            text: null,
            focused: false,
            inputType: this.props.inputType == null ? 'text' : this.props.inputType,
            mask: '00/00/0000',
        };

        this.highlight = this.highlight.bind(this);
        this.unhighlight = this.unhighlight.bind(this);
        this.valueChanged = this.valueChanged.bind(this);
        this.typingTimeout = null;
        this.updateParent = this.updateParent.bind(this);
        this.onKeyPress = this.onKeyPress.bind(this);
    }

    componentDidUpdate(prevProps) {
        if (this.props.placeholder != prevProps.placeholder) {
            this.setState({ placeholder: this.props.placeholder });
        }

        if(this.props.unitText != prevProps.unitText){
            this.setState({unitText: this.props.unitText});
        }

        if (this.props.value != prevProps.value) {
            this.setState({ value: this.props.value });
        }
    }

    valueChanged(value) {
        clearTimeout(this.typingTimeout);

        if(value.formattedValue != this.state.value){
            this.typingTimeout = setTimeout(this.updateParent, 200);

            this.setState({ value: value.formattedValue });
        }
    }

    updateParent() {
        let returningValue = this.state.value == '' ? null : this.state.value;
        this.props.onChange(returningValue);
    }

    highlight() {
        this.setState({ 
            shouldShowUnit: true,
            focused: true
        });
        if (this.props.onFocus != null) {
            this.props.onFocus();
        }
    }

    unhighlight() {
        this.setState({
            focused: false
        });
        if (this.props.value == undefined) {
            this.setState({ shouldShowUnit: false });
        }
        if (this.props.onBlur != null) {
            this.props.onBlur();
        }
    }

    onKeyPress(event) {
        if (event != undefined && event.key === 'Enter') {
            event.target.blur();
            this.unhighlight();
        }

        if(this.props.onKeyPress != null){
            this.props.onKeyPress();
        }
    }

    render() {
        let textFieldContainerStyle = {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
            alignItems: 'center',
            alignContent: 'center',
            width: this.props.width != null ? this.props.width : null,
            borderRadius: '10px',
            borderStyle: 'solid',
            borderWidth: '2px',
            borderColor: FormsStyles.backgroundColor,
            backgroundColor: 'white',
            paddingRight: '0.5em',
            overflow: 'hidden'
        };

        let textFieldstyle = {
            height: '2.5em',
            width: '100%',
            paddingLeft: '0.2em',
            paddingRight: '0.2em',
            textAlign: this.state.alignment,
            borderRadius: '10px',
            borderStyle: 'none',
            fontSize: "1em",
            marginRight: this.state.unitText != null ? '0.1em' : '0',
            color: !this.state.focused ? FormsStyles.primaryTint : FormsStyles.black,
        };

        let unitContainer = {
            marginTop: '0.4em',
            textAlign: 'right',
        };

        let unitStyle = {
            color: 'rgba(76, 125, 175, 0.32)',
            width: this.state.unitText == null ? '0' : 'auto',
            textAlign: 'right',
            marginBottom: '0.4em'
        };

        return (
            <div className='form-text-field' style={textFieldContainerStyle}>
                <PatternFormat className="form-input" style={textFieldstyle} format={this.props.format} placeholder={this.state.placeholder} mask={this.props.mask} onValueChange={this.valueChanged} value={this.state.value || ''} onFocus={() => this.highlight()} onBlur={() => this.unhighlight()} onKeyPress={this.onKeyPress} />
                {this.state.unitText && (this.state.focused || (this.state.value != null && this.state.value != '')) &&
                <div style={unitContainer}>
                    <div style={unitStyle}>{this.state.unitText}</div>
                </div>}
            </div>
        );
    }
}

FormMaskedTextField.propTypes = {
    placeholder: PropTypes.string,
    onChange: PropTypes.func,
    value: PropTypes.string,
    unitText: PropTypes.string,
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,
    width: PropTypes.string,
    onKeyPress: PropTypes.func,
    alignment: PropTypes.string,
    inputType: PropTypes.string,
    format: PropTypes.string.isRequired,
    mask: PropTypes.array.isRequired
};