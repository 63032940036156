import { FormWithRedux } from '../../FormWithRedux';
import { redux } from '../../../App';
import { CalculationModel } from '../../edChestPain01/Custom Types/Calculation';
import { saveAction } from '../../../components/state/actions/ActionActions';
import { saveCalculation } from '../../../components/state/actions/CalculationActions';
import { EDChestPainCalculationHelper as CalculationHelper } from '../../edChestPain01/Helpers/EDChestPainCalculationHelper';
import { FormValidation } from '../../../waferJS/Form/Validation/FormValidation';
import { FormValidationError } from '../../../waferJS/Form/Validation/FormValidationError';
import { PathwayFormatter } from '../../PathwayFormatter';
import { DXAActionItem } from '../Actions/DXAActionItem';
import { markWorkFlowAsExported } from '../../../components/state/actions/WorkflowResponseActions';
import { getPathwayIdFromURL } from '../../../helpers/URLHelper';

export class OsteoporosisResultsForm extends FormWithRedux {

    set calculations(value) {
        this._calculations= JSON.parse(JSON.stringify(value));
    }

    get calculations() {
        return this._calculations;
    }

    set actions(value) {
        this._actions = JSON.parse(JSON.stringify(value));
    }

    get actions() {
        return this._actions;
    }

    constructor(formLoader, parent, actionsUpdater, dependenValuesUpdater, calculationsUpdater) {
        super('resultsForm', formLoader, parent,  actionsUpdater, dependenValuesUpdater, calculationsUpdater);

        let self = this;
        redux.store.subscribe(() => {
            let state = redux.store.getState();
            self.calculations = state.calculationReducer.calculations;
            self.actions = state.actionsReducer.actions;
        });

        this.calculations = redux.store.getState().calculationReducer.calculations;

        this.actions = redux.store.getState().actionsReducer.actions;
    }

    shouldDisplayFormDetailForIdentifier(identifier) {
        super.shouldDisplayFormDetailForIdentifier(identifier);
        let formDetail = this.formDetail(identifier);
        if(formDetail.controlType == 'action'){

            let action = this.actionForIdentifier(identifier);
            if(identifier == 'dxaRequestOutcome'){
                return action != null && this.areFormsForInitialOutcomeFormComplete() && !this.isPatientOverweight();
            }
            return action != null;
        } else if(identifier == 'incompletDXARequest'){
            return !this.areFormsForInitialOutcomeFormComplete() && !this.isPatientOverweight();
        }
        else if (identifier == 'moreInformationRequired'){
            return !this.isComplete() && !this.isPatientOverweight();
        } else if(identifier == 'acceptanceOutsideUsualCriteria'){
            return (this.getValue('dxaRequestOutcome') != null && this.getValue('dxaRequestOutcome').appointmentTimeFrame == DXAActionItem.TimeFrame.none) && this.isComplete() && this.getValue('privateFundingAvailable') != true;
        } else if(identifier == 'acceptanceOutsideCriteriaReason'){
            return this.getValue('acceptanceOutsideUsualCriteria') == true || this.getValue('identifier') != null;
        } else if(identifier == 'exportRequestOutcome' || identifier == 'additionalRequestInformation'){
            return this.isComplete() || this.isPatientOverweight();
        }
        
        return true;
    }

    isPatientOverweight(){
        return this.getValue('patientWeight') != null && this.getValue('patientWeight') >= 160;
    }

    isEnabled(identifier) {
        if (identifier != null) {
            return true;
        }
        return false;
    }

    isMandatory() {
        return false;
    }

    getValueType(identifier) {
        if(identifier == 'acceptanceOutsideUsualCriteria'){
            return 'boolean';
        }
        return 'string';
    }

    submissionValidation(identifier) {
        if(identifier == 'acceptanceOutsideCriteriaReason'){
            let self = this;
            let criteriaConflict = new FormValidation('RequestReasonConflict', function () {
                let value = self.getValue('acceptanceOutsideCriteriaReason');
                if(value != null && self.getValue('acceptanceOutsideUsualCriteria') == false){
                    return [new FormValidationError(FormValidationError.FormErrorType.error, 'Modify reasoning to match selection')];
                }
                return [];
            });
                
            return [criteriaConflict];
        }
        return [];
    }

    detailString(isSubcell, isPlainText) {
        if(isPlainText){
            return this.getPlainText();
        }
        let formDetails = this.getFormDetails();
        let values = formDetails.map((value) => {
            return this.getSummaryValue(isSubcell, value);
        });

        if(values.filter(n => n).length == 0){
            return isPlainText ? '' : this.detail;
        }
        
        return values.filter(n => n).join(isPlainText ? ', ' : ',\n');
    }

    getSummaryValue(isSubcell, value){
        if(value.identifier == 'exportRequestOutcome' || value.identifier == 'acceptanceOutsideCriteriaReason'){
            return null;
        } else if(value.identifier == 'dxaRequestOutcome'){
            if(this.getValue(value.identifier) != null){
                let action = this.actionForIdentifier(value.identifier);
                let dxaAction = DXAActionItem.rehydrate(this.getValue(value.identifier));
                return action.title + '\n' + dxaAction.getLongDetail(true, this.getValue('privateFundingAvailable') == true);
            } else {
                return null;
            }
        } else if(value.identifier == 'acceptanceOutsideUsualCriteria' && value.currentValue == true){
            var reason = this.getValue('acceptanceOutsideCriteriaReason');
            return 'Request should be considered for acceptance outside usual criteria' + (reason != null ? ' (Reason: ' + reason + ')' : '');
        } else if(value.controlType == 'action'){
            let action = this.actionForIdentifier(value.identifier);
            if(action != null){
                let actionStringComponents = [];
                action.title != null ? actionStringComponents.push(action.title) : actionStringComponents.push(value.title);
                action.detail != null ? actionStringComponents.push(action.detail) : actionStringComponents.push(value.detail);
                return actionStringComponents.filter(n => n).join('\n');
            } else {
                return null;
            }
        } 
        return value.valueDescription();
    }

    shouldShowEmptySection(){
        return false;
    }

    areFormsForInitialOutcomeFormComplete(){
        let patientDetailsForm = this.formLoader.form(this.pathwayId, 'patientDetails');
        let requestCriteriaForm = this.formLoader.form(this.pathwayId, 'requestCriteria');

        let patientDetailsFromCompletionProgress = patientDetailsForm.completionProgress();
        let isPatientDetailsFormComplete = false;
        if(patientDetailsFromCompletionProgress != null && patientDetailsFromCompletionProgress != undefined){
            isPatientDetailsFormComplete = (patientDetailsFromCompletionProgress.current / patientDetailsFromCompletionProgress.total) == 1;
        }
        

        let requestCriteriaFormCompletionProgress = requestCriteriaForm.completionProgress();
        let isRquestCriteriaFormComplete = false;
        if(requestCriteriaFormCompletionProgress != null && requestCriteriaFormCompletionProgress != undefined){
            isRquestCriteriaFormComplete = (requestCriteriaFormCompletionProgress.current / requestCriteriaFormCompletionProgress.total) == 1;
        }
        
        return isPatientDetailsFormComplete && isRquestCriteriaFormComplete;
    }

    isComplete(){
        let form = this.formLoader.form(this.pathwayId, 'mainForm');
        let progress = form.completionProgress();
        if(progress != null){
            return (progress.current / progress.total) == 1;
        }
        return false;
    }

    calculationError(identifier){
        let data = null;
        if(this.workflow[this.pathwayId] != null){
            data = this.workflow[this.pathwayId].rawData;
        }
        return CalculationHelper.calcualtionError(identifier, data, this);
    }

    latestCalculation(identifier){
        return CalculationHelper.getLatestCalculation(this.getTypeFromIdentifier(identifier), this.calculations[this.pathwayId]);
    }

    previousCalculation(identifier){
        return CalculationHelper.getPreviousCalculation(this.getTypeFromIdentifier(identifier), this.calculations[this.pathwayId]);
    }

    markCalculationAsObserved(identifier){
        let calculation = this.latestCalculation(identifier);
        if(calculation != null){
            calculation.observed = true;
            
            redux.store.dispatch(saveCalculation(getPathwayIdFromURL(), calculation, this.calculations));
        }
    }

    markActionAsObserved(identifier){
        if(this.actions[this.pathwayId] == null ){ return; }
        let matchingActions = this.actions[this.pathwayId].filter((action) => {
            return action.identifier == identifier;
        });

        let filteredActions = this.actions[this.pathwayId].filter((action) => {
            return action.identifier != identifier;
        });

        if (matchingActions.length == 0 ) { return;}

        let action = matchingActions[0];

        if(!action.removeAfterObservation){
            action.observed = true;
            filteredActions.push(action);
        }
        
        redux.store.dispatch(saveAction(this.pathwayId, filteredActions));
    }

    calculationProgress(identifier){
        if(CalculationModel.typeRequiresPrimaryFormValidation(this.getTypeFromIdentifier(identifier))){
            let progress = redux.store.getState().progressReducer.progress[this.pathwayId];
            if(progress != null){
                return (progress.complete / progress.total);
            }
            return 0;
        } 
        return 1;
    }

    actionForIdentifier(identifier){
        if(this.actions[this.pathwayId] != null ){
            let filteredAction = this.actions[this.pathwayId].filter((action) => {return action.identifier == identifier;});
            if(filteredAction.length > 0){
                return filteredAction[0];
            }
        }
        
        return null;
    }

    getCopyText(){
        return PathwayFormatter.formatAsPlainText(this.formLoader, this.pathwayId, this.workflow[this.pathwayId], this.actions[this.pathwayId]);
    }

    markAsExported(){
        redux.store.dispatch(markWorkFlowAsExported(this.pathwayId, this.workflow[this.pathwayId]));
    }
}