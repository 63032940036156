import { v1 as uuidv1 } from 'uuid';
import moment from 'moment';
import { DateHelper } from '../../waferJS/Helpers/DateHelper';

export class ActionModel {

    constructor(pathwayUuid, versionUuid, identifier, title, detail, priority, observed, removeAfterObservation, lastModifiedDate, uuid, scheduledAction){
        this.pathwayUuid = pathwayUuid;
        this.versionUuid = versionUuid;
        this.identifier = identifier;
        this.title = title;
        this.detail = detail;
        this.priority = priority;
        this.observed = observed;
        this.removeAfterObservation = removeAfterObservation;
        this.lastModifiedDate = lastModifiedDate;
        this.uuid = uuid == null ? this.uuid = uuidv1() : uuid;
        this.scheduledAction = scheduledAction;
    }

    scheduledActionDetailString(includeActionDetails){
        if(this.scheduledAction == null || this.scheduledAction.completed == true) { return null;}

        
        var components = [];
        
        if (includeActionDetails) {
            components.push(this.title);
        }
        
        switch (this.scheduledAction.duePoint) {
            case ScheduledAction.DuePoint.dueAt:
                components.push('Due');
                break;
            case ScheduledAction.DuePoint.dueBefore:
                components.push('Due before');
        }
        
        components.push(DateHelper.getRelativeDate(this.scheduledAction.date));
        components.push('at');
        components.push(moment(this.scheduledAction.date).format('hh:mma'));
        components.push('(' + moment(this.scheduledAction.date).fromNow() + ')');
        
        return components.join(' ');
    }

    scheduledActionDetailStringSimple(includeActionDetails){
        if(this.scheduledAction == null || this.scheduledAction.completed == true) { return null;}

        
        var components = [];
        
        if (includeActionDetails) {
            components.push(this.title);
        }
        
        switch (this.scheduledAction.duePoint) {
            case ScheduledAction.DuePoint.dueAt:
                components.push('Due');
                break;
            case ScheduledAction.DuePoint.dueBefore:
                components.push('Due before');
        }
        
        components.push(moment(this.scheduledAction.date).fromNow());
        
        return components.join(' ');
    }

    overwrite(otherModel) {
        // Don't do the UUID, this is how we enforce replacing of the existing model.
        //self.uuid = otherModel.uuid
        
        this.patientUuid = otherModel.patientUuid;
        this.pathwayUuid = otherModel.pathwayUuid;
        this.versionUuid = otherModel.versionUuid;
        
        this.category = otherModel.category;
        this.identifier = otherModel.identifier;
        
        this.title = otherModel.title;
        this.detail = otherModel.detail;
        this.priority = otherModel.priority;
        
        this.scheduledAction = otherModel.scheduledAction;
        
        this.lastModifiedDate = otherModel.lastModifiedDate;
    }

    uniqueModelUuid() {
        return this.patientUuid + this.pathwayUuid + this.versionUuid + this.category + this.identifier + this.title + this.detail + this.priority + (this.scheduledAction != null ? this.scheduledAction.uniqueModelUuid() : '');
    }

    static rehydrate(action){
        let scheduledAction;
        if(action.scheduledAction != null){
            scheduledAction = new ScheduledAction(action.scheduledAction.date, action.scheduledAction.duePoint, action.scheduledAction.title, action.scheduledAction.completed, action.scheduledAction.requiresAction);
        }
        return new ActionModel(action.pathwayUuid, action.versionUuid, action.identifier, 
            action.title, action.detail, action.priority, action.observed, action.removeAfterObservation, action.lastModifiedDate, action.uuid, scheduledAction);
    }
}

export class ScheduledAction {
    static DuePoint = Object.freeze({dueAt: 0, dueBefore: 1});
    
    // // Used to indicate when to remove badge/notifications
    // var completed: Bool
    
    // // Used to determine if we display badge/notifications
    // var requiresAction: Bool
    
    // // Show badge if RequiresAction=true and Completed=false
    
    uniqueModelUuid(){
        return this.date + this.duePoint + this.title + this.completed + this.requiresAction;
    }
    
    constructor(date, duePoint, title, completed, requiresAction) {
        this.date = date;
        this.duePoint = duePoint;
        this.title = title;
        this.completed = completed;
        this.requiresAction = requiresAction;
    }
}