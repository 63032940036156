import { FormWithRedux } from '../../FormWithRedux';

export class OsteoporosisFurtherInformationForm extends FormWithRedux {

    constructor(formLoader, parent, actionUpdater, dependentValuesUpdater) {
        super('furtherInformation', formLoader, parent, actionUpdater, dependentValuesUpdater);
    }

    shouldDisplayFormDetailForIdentifier(identifier) {
        super.shouldDisplayFormDetailForIdentifier(identifier);
        if(identifier == 'noToAllMedicalProblems' || identifier == 'noToAllSocialAndFamily' || identifier == 'noToAllFurtherInformationMedications'){
            return this.unselectedExplicitFormDetailsCount(identifier) > 1;
        } else if(identifier == 'hyperthyroidismNewSinceLast') {
            return this.getValue('hyperthyroidism') == true && this.getValue('isThisFollowUpRequest') == 'followupOption';
        } else if(identifier == 'malabSorptionNewSinceLast'){
            return this.getValue('medicalHistoryMalabsorption') == true && this.getValue('isThisFollowUpRequest') == 'followupOption';
        } else if(identifier == 'hyperthyroidismNewSinceLast'){
            return this.getValue('hyperthyroidism') == true && this.getValue('isThisFollowUpRequest') == 'followupOption';
        } else if(identifier == 'hyperparathyroidismNewSinceLast'){
            return this.getValue('hyperparathyroidism') == true && this.getValue('isThisFollowUpRequest') == 'followupOption';
        } else if(identifier == 'hypogonadismNewSinceLast'){
            return this.getValue('hypogonadism') == true && this.getValue('isThisFollowUpRequest') == 'followupOption';
        } else if(identifier == 'inflammatoryArthritisNewSinceLast'){
            return this.getValue('inflammatoryArthritis') == true && this.getValue('isThisFollowUpRequest') == 'followupOption';
        } else if(identifier == 'amenorrheaNewSinceLast'){
            return this.getValue('amenorrhea') == true && this.getValue('isThisFollowUpRequest') == 'followupOption';
        } else if (identifier == 'eatingDisordersNewSinceLast'){
            return this.getValue('eatingDisorders') == true && this.getValue('isThisFollowUpRequest') == 'followupOption';
        } else if (identifier == 'inhibitorsNewSinceLast'){
            return this.getValue('inhibitors') == true && this.getValue('isThisFollowUpRequest') == 'followupOption';
        } else if (identifier == 'aromataseInhibitorsNewSinceLast'){
            return this.getValue('aromataseInhibitors') == true && this.getValue('isThisFollowUpRequest') == 'followupOption';
        } 
        return true;
    }

    isEnabled(identifier) {
        if (identifier != null) {
            return true;
        }
        return false;
    }

    isMandatory(identifier) {
        if(identifier == 'indicateTreatOrMeasure' || identifier == 'noToAllMedicalProblems' || identifier == 'noToAllSocialAndFamily' || identifier == 'noToAllFurtherInformationMedications'){
            return false;
        } else if(identifier == 'hyperthyroidismNewSinceLast') {
            return this.getValue('hyperthyroidism') == true && this.getValue('isThisFollowUpRequest') == 'followupOption';
        } else if(identifier == 'malabSorptionNewSinceLast'){
            return this.getValue('medicalHistoryMalabsorption') == true && this.getValue('isThisFollowUpRequest') == 'followupOption';
        } else if(identifier == 'hyperthyroidismNewSinceLast'){
            return this.getValue('hyperthyroidism') == true && this.getValue('isThisFollowUpRequest') == 'followupOption';
        } else if(identifier == 'hyperparathyroidismNewSinceLast'){
            return this.getValue('hyperparathyroidism') == true && this.getValue('isThisFollowUpRequest') == 'followupOption';
        } else if(identifier == 'hypogonadismNewSinceLast'){
            return this.getValue('hypogonadism') == true && this.getValue('isThisFollowUpRequest') == 'followupOption';
        } else if(identifier == 'inflammatoryArthritisNewSinceLast'){
            return this.getValue('inflammatoryArthritis') == true && this.getValue('isThisFollowUpRequest') == 'followupOption';
        } else if(identifier == 'amenorrheaNewSinceLast'){
            return this.getValue('amenorrhea') == true && this.getValue('isThisFollowUpRequest') == 'followupOption';
        } else if (identifier == 'eatingDisordersNewSinceLast'){
            return this.getValue('eatingDisorders') == true && this.getValue('isThisFollowUpRequest') == 'followupOption';
        } else if (identifier == 'inhibitorsNewSinceLast'){
            return this.getValue('inhibitors') == true && this.getValue('isThisFollowUpRequest') == 'followupOption';
        } else if (identifier == 'aromataseInhibitorsNewSinceLast'){
            return this.getValue('aromataseInhibitors') == true && this.getValue('isThisFollowUpRequest') == 'followupOption';
        } 
        return true;
    }

    getValueType() {
        return 'boolean';
    }

    submissionValidation() {
        return [];
    }

    editingValidation(){
        return [];
    }

    detailString(isSubcell, isPlainText) {
        if(isPlainText){
            return this.getPlainText();
        }
        let formDetails = this.getFormDetails();
        let values = formDetails.map((value) => {
            return this.getSummaryValue(isSubcell, value);
        });

        if(values.filter(n => n).length == 0){
            return isPlainText ? '' : this.detail;
        }
        
        return values.filter(n => n).join(isPlainText ? ', ' : ',\n');
    }

    getSummaryValue(isSubcell, value){
        return value.valueDescription();
    }
}