import { FormWithRedux } from '../../FormWithRedux';

export class EDChestPainAbnormalitiesForm extends FormWithRedux {

    constructor(formIdentifier, formLoader, parent, actionUpdater, dependentValuesUpdater, calculationsUpdater) {
        super(formIdentifier, formLoader, parent, actionUpdater, dependentValuesUpdater, calculationsUpdater);
    }

    shouldDisplayFormDetailForIdentifier(identifier) {
        super.shouldDisplayFormDetailForIdentifier(identifier);

        if (identifier === 'alternativeFindingSpecify') {
            let alternativeFinding = this.getValue('alternativeFinding');
            return alternativeFinding == null ? true : alternativeFinding;
        } else if (identifier == 'noToAllAbnormalities') {
            return this.unselectedExplicitFormDetailsCount(identifier) > 1;
        }

        return true;
    }

    isEnabled(identifier) {
        if (identifier != null) {
            return true;
        }
        return false;
    }

    isMandatory(identifier) {
        if (identifier == 'noToAllAbnormalities') {
            return false;
        } else if(identifier == 'alternativeFindingSpecify'){
            let alternativeFinding = this.getValue('alternativeFinding');
            return alternativeFinding == null ? false : alternativeFinding;
        } else {
            let values = [this.getValue('newIschaemia'),
                this.getValue('oldIschaemia'),
                this.getValue('oldLBBB'),
                this.getValue('nonSpecificSTorTChanges'),
                this.getValue('alternativeFinding')];

            if(values.includes(true) || values.includes(false)){
                return true;
            }

            let dependantValue = this.getValue('abnormalitiesPresent');
            return dependantValue == null ? false : dependantValue;
        }
    }

    getValueType() {
        return 'boolean';
    }

    submissionValidation() {
        return [];
    }

    detailString(isSubform) {
        let formDetails = this.getFormDetails();
        let values = formDetails.map((formDetail) => {
            var valueDescription = formDetail.valueDescription();

            if (formDetail.identifier == 'alternativeFinding') {
                let specifyText = this.getValue('alternativeFindingSpecify');
                if (specifyText != null && specifyText.length > 0) {
                    valueDescription = valueDescription + ': ' + specifyText;
                }
            } else if (formDetail.identifier == 'alternativeFindingSpecify') {
                return null;
            }

            return valueDescription == null || valueDescription == false  ? null : (isSubform ? '- ' : '') + valueDescription;
        });

        if(values.filter(n => n).length == 0){
            return this.detail;
        }

        return values.filter(n => n).join(',\n');
    }

}