import { redux } from '../../../App';
import { saveWorkflow } from '../../../components/state/actions/WorkflowResponseActions';
import moment from 'moment';

export class OsteoporosisDependantValuesUpdater {

    constructor(formLoader) {
        this.formLoader = formLoader;
    }

    update(pathwayId, workflow, identifier, value, iterationIndex){
        updateDependentValues(this.formLoader, pathwayId, workflow, identifier, value, iterationIndex);
    }

    logObservedValues(pathwayId, workflow, form){
        redux.store.dispatch(saveWorkflow(pathwayId, {'lastObservedTotalRedFlags': workflow.rawData['totalRedFlags']}, workflow, false, form, 0));
    }
}

function updateDependentValues(formLoader, pathwayId, workflow, identifier, value, iterationIndex){
    var rawData = workflow.rawData;
    var changedValues = {};

    switch(identifier){
        case 'patientDateOfBirth': {
            if(value != null){
                let momentDate = moment(value, 'DD/MM/YYYY');
                let years = moment().diff(momentDate, 'years');
                if(!isNaN(+years) && years >= 0){
                    changedValues['patientAge'] = years;
                } else {
                    changedValues['patientAge'] = null;
                }
            } else {
                changedValues['patientAge'] = null;
            }
            break;
        }
        case 'patientAge':{
            if(rawData['patientDateOfBirth'] != null){
                let momentDate = moment(rawData['patientDateOfBirth']);
                let years = moment().diff(momentDate, 'years');
                if(years != null && parseInt(years) != parseInt(value)){
                    changedValues['patientDateOfBirth'] = null;
                } 
            } 
        }
        /*eslint-disable */
        case 'isThisFollowUpRequest':
        // case 'longBoneOrHipFracture':
        case 'multipleFalls':
        case 'hasPatientHadFragilityFracture':
        case 'hipFractureRisk':
        case 'highRiskGlucocorticoidUse': {
            if(rawData['isThisFollowUpRequest'] == 'firstOption'){
                if(rawData['hasPatientHadFragilityFracture'] == true || rawData['highRiskGlucocorticoidUse'] || (rawData['hipFractureRisk'] >= 3 && (rawData['patientAge'] != null && rawData['patientAge'] < 70)) || (rawData['multipleFalls'] == true && (rawData['patientAge'] != null && rawData['patientAge'] >= 70))){
                    changedValues['highRiskCriteraPresent'] = true;
                } else {
                    changedValues['highRiskCriteraPresent'] = false;
                }
            } else if(rawData['isThisFollowUpRequest'] == 'followupOption'){
                if(rawData['fragilityFractureSincePreviousScan'] || rawData['highRiskGlucocorticoidUse']  || (rawData['multipleFalls'] == true && (rawData['patientAge'] != null && rawData['patientData'] >= 70))){
                    changedValues['highRiskCriteraPresent'] = true;
                } else {
                    changedValues['highRiskCriteraPresent'] = false;
                }
            } else {
                changedValues['highRiskCriteraPresent'] = null;
            }
            break;
        }
        /*eslint-enable */
    }

    if (Object.keys(changedValues).length > 0) {
        let mainForm = formLoader.mainForm(pathwayId);
        mainForm.save(changedValues, workflow, true, iterationIndex + 1);
    }
}