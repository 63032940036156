// IMPORT PACKAGE REFERENCES
import PropTypes from 'prop-types';
import React from 'react';
import { v1 as uuidv1 } from 'uuid';
import { ImageModal } from '../../../../components/Modals/ImageModal';

// COMPONENT
export class FormLabel extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            value: props.value,
            textColor: props.textColor,
            fontSize: props.fontSize == null ? 0.8 : props.fontSize,
            fontWeight: props.fontWeight == null ? 400 : props.fontWeight,
            isMandatory: props.isMandatory,
            alignText: props.alignText == null ? 'left' : props.alignText,
            strikeThrough: props.strikeThrough == null ? false : props.strikeThrough
        };
    }



    componentDidUpdate(prevProps) {
        if (this.props.value != prevProps.value) {
            this.setState({ value: this.props.value });
        }

        if (this.props.textColor != prevProps.textColor) {
            this.setState({ textColor: this.props.textColor });
        }

        if (this.props.isMandatory != prevProps.isMandatory) {
            this.setState({ isMandatory: this.props.isMandatory });
        }
    }

    shouldComponentUpdate(nextProps, nextState) {
        if(nextProps.hidden !== this.props.hidden){
            return true;
        }

        if(nextState.hidden !== this.state.hidden){
            return true;
        }

        if(nextProps.value !== this.props.value){
            return true;
        }

        if(nextState.value !== this.state.value){
            return true;
        }

        if(nextProps.textColor !== this.props.textColor){
            return true;
        }

        if(nextState.textColor !== this.state.textColor){
            return true;
        }

        if(nextProps.fontSize !== this.props.fontSize){
            return true;
        }

        if(nextState.fontSize !== this.state.fontSize){
            return true;
        }

        if(nextProps.isMandatory !== this.props.isMandatory){
            return true;
        }

        if(nextState.isMandatory !== this.state.isMandatory){
            return true;
        }

        if(nextProps.fontWeight !== this.props.fontWeight){
            return true;
        }

        if(nextState.fontWeight !== this.state.fontWeight){
            return true;
        }

        if(nextProps.alignText !== this.props.alignText){
            return true;
        }

        if(nextState.alignText !== this.state.alignText){
            return true;
        }

        if(nextProps.alignText !== this.props.alignText){
            return true;
        }

        if(nextState.strikeThrough !== this.state.strikeThrough){
            return true;
        }

        return false;
    }

    render() {
        let isMandatorySymbol;
        if (this.state.isMandatory != null && this.state.isMandatory) {
            isMandatorySymbol = <label style={{ color: 'red', marginBottom: '0em' }}>*</label>;
        }

        let style = {
            color: this.state.textColor,
            fontSize: this.state.fontSize + 'em',
            fontWeight: this.state.fontWeight,
            textAlign: this.state.alignText,
            textDecoration: this.state.strikeThrough ? 'line-through' : '',
            textDecorationColor: this.state.strikeThrough ? 'red' : '',
            whiteSpace: 'pre-line',
            marginRight: '0.2em'
        };

        let elements = [];
        let value = this.state.value;

        // Handle imbedded hyperlinks/images
        let components = value.split('#@');
        
        let index = 0;
        for (let item of components) {
            if (index % 2) {
                var title = item;
                var link = item;
                var type;
                if (item.includes('^')) {
                    title = item.split('^')[1];
                    link = item.split('^')[2];
                    type = 0; //Hyperlink
                } else if (item.includes('~')) {
                    title = item.split('~')[1];
                    link = item.split('~')[2];
                    type = 1; //Image
                }
                
                switch (type) {
                    case 0:
                        elements.push(<a key={item + uuidv1()} href={link} target="_blank" rel="noopener noreferrer">{title}</a>);
                        break;
                    case 1:
                        elements.push(<ImageModal key={item + uuidv1()} image={link} title={title} />);
                        break;
                }
            } else {
                elements.push(item.replaceAll('\t', '\u00A0\u00A0\u00A0\u00A0'));
            }
            index += 1;
        }

        return (
            <div className={'form-label' + (this.props.hidden ? ' hidden' : '')} style={style}>
                {elements.map((item)=> {
                    return item;
                })}
                {isMandatorySymbol}
            </div>
        );
    }
}

String.prototype.replaceAll = function(search, replacement) {
    var target = this;
    return target.split(search).join(replacement);
};

FormLabel.propTypes = {
    hidden: PropTypes.bool,
    value: PropTypes.string,
    textColor: PropTypes.string,
    fontSize: PropTypes.number,
    isMandatory: PropTypes.bool,
    fontWeight: PropTypes.number,
    alignText: PropTypes.string,
    strikeThrough: PropTypes.bool
};