import { FormWithRedux } from '../../FormWithRedux';
import { ACCMRIInformationForm } from '../IntroForm/ACCMRI-InformationForm';

export class ACCMRIIntroForm extends FormWithRedux {

    constructor(formLoader) {
        super('introForm', formLoader);
    }

    generateSubform(identifier) {
        switch (identifier) {
            case 'PathwayInformationForm':
                return new ACCMRIInformationForm(this.formLoader,);
        }
    }

    shouldDisplayFormDetailForIdentifier(identifier) {
        super.shouldDisplayFormDetailForIdentifier(identifier);
        return true;
    }

    isEnabled(identifier) {
        if (identifier != null) {
            return true;
        }
        return false;
    }

    isMandatory() {
        return false;
    }

    getValueType() {
        return null;
    }

    submissionValidation() {
        return [];
    }

    detailString() {
        return this.detail;
    }
}