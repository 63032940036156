import { FormContentView } from '../../FormsUI/FormsUI Components/Containers/FormContentView';
import { FormsStyles } from '../../FormsUI/FormsUI';
import React from 'react';
import PropTypes from 'prop-types';


export class WebLinkFormDetail extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            isOpen: true
        };

        this.handleClick = this.handleClick.bind(this);
    }

    componentDidMount() {
        document.addEventListener('click', this.handleClick);
    }

    componentWillUnmount() {
        document.removeEventListener('click', this.handleClick);
    }

    handleClick(e) {
        if (this.node.contains(e.target)){
            window.open(this.props.formDetail.weblink, '_blank');
        }
    }

    render() {
        let subcells = this.props.renderSubcells();
        let image = <img  style={{width: '18px', height: '18px'}} src={require('../../../images/weblink_symbol.png')} ref={homeButtonNode => this.homeButtonNode = homeButtonNode}/>;

        return (
            <div style={{cursor: 'pointer'}} ref={node => this.node = node}>
                <FormContentView identifier={this.props.formDetail.identifier} titleString={this.props.formDetail.title} detailString={'#@' + this.props.formDetail.weblink +'#@'} titleAccessory={image} isSubcell={this.props.isSubcell} subcell={subcells} activeColor={FormsStyles.primaryTint} subCellbackgroundColor={FormsStyles.backgroundColor} showHover={true}/>
            </div>
        );
    }
}

WebLinkFormDetail.propTypes = {
    formDetail: PropTypes.object.isRequired,
    isSubcell: PropTypes.bool.isRequired,
    renderErrors: PropTypes.func.isRequired,
    renderSubcells: PropTypes.func.isRequired,
    errorLabels: PropTypes.array.isRequired,
    hasError: PropTypes.bool.isRequired
};