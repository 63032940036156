import { FormValidation } from '../../../Form/Validation/FormValidation';
import { FormValidationIdentifier } from '../../../Form/Validation/FormValidationIdentifier';
import { FormValidationError } from '../../../Form/Validation/FormValidationError';

FormValidationIdentifier.IsNumber = 'IsNumber';
FormValidationIdentifier.IsDecimalNumber = 'IsDecimalNumber';
FormValidationIdentifier.IsIntNumber = 'IsIntNumber';
FormValidationIdentifier.IsIntNumber = 'IsPositive';

let IsNumber = new FormValidation(FormValidationIdentifier.IsNumber, function (value) {
    if (value == null || value == '') {
        return [FormValidationError.RequiredError];
    }

    if (isNaN(+value) || !isFinite(value)) {
        return [new FormValidationError(FormValidationError.FormErrorType.error, 'Value is not a number')];
    }

    return [];
});

let IsDecimalNumber = new FormValidation(FormValidationIdentifier.IsDecimalNumber, function (value) {
    if (isNaN(+value) || !isFinite(value) || value == null) {
        return [new FormValidationError(FormValidationError.FormErrorType.error, 'Numeric value required')];
    }

    return [];
});

let IsIntNumber = new FormValidation(FormValidationIdentifier.IsIntNumber, function (value) {
    if (!Number.isInteger(value)) {
        return [new FormValidationError(FormValidationError.FormErrorType.error, 'Numeric value required')];
    }

    return [];
});

let IsPositive = new FormValidation(FormValidationIdentifier.IsIntNumber, function (value) {
    value = parseFloat(value);
    if (value == null || isNaN(+value) || !isFinite(value) || value == null) {
        return [FormValidationError.RequiredError];
    }

    if (value < 0) {
        return [new FormValidationError(FormValidationError.FormErrorType.error, 'Numeric value must be positive')];
    }
    return [];
});

export { IsNumber, IsDecimalNumber, IsIntNumber, IsPositive };