
import React from "react";
import PropTypes from "prop-types";
import { Outlet } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { PathwayReferenceHelper } from "../../forms/PathwayReferenceHelper";
import { ReferenceDataLoader } from "../../forms/ReferenceDataLoader";
import { getPathwayIdFromURL } from "../../helpers/URLHelper";
import { ENVIRONMENT, ENABLED_PATHWAYS } from "../../config/config";

/* 
    Manage the authorisation and routing of the user based on the provided URL parameters. 
*/
class AppRouter extends React.Component {

  constructURLForRedirect() {
    let url = "https://access.wayfind.health/";
    var parameters = [];

    if (
      ["dev", "test", "staging", "demo"].includes(ENVIRONMENT.toLowerCase())
    ) {
      parameters.push("env=" + ENVIRONMENT.toLowerCase());
    }

    let pathwayId = getPathwayIdFromURL();
    if (pathwayId.length > 0) {
      parameters.push("code=" + pathwayId);
    } else {
      return "https://wayfind.health";
    }

    if (parameters.length > 0) {
      var urlWithParameters = url + "?" + parameters.join("&");

      return urlWithParameters;
    } else {
      return url;
    }
  }

  render() {
    return <Outlet />;
  }
}

AppRouter.propTypes = {
  loggingIn: PropTypes.bool.isRequired,
  redirectPathway: PropTypes.string,
};

// CONFIGURE REACT REDUX
const mapStateToProps = (state) => {
  const { loggingIn, redirectPathway } = state.userReducer;
  return { loggingIn, redirectPathway };
};

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

const hoc = connect(mapStateToProps, mapDispatchToProps)(AppRouter);

// EXPORT COMPONENT
export { hoc as AppRouter };
