import { saveWorkflow } from '../../../components//state/actions/WorkflowResponseActions';
import { redux } from '../../../App';
import { checkAndUpdateForAction } from '../../../components/state/actions/ActionActions';
import { ActionModel } from '../../Shared Custom Types/ActionModel';
import { DXAActionItem } from './DXAActionItem';

export class OsteoporosisActions {

    constructor(formLoader) {
        this.formLoader = formLoader;
    }

    update(pathwayId, workflow, oldActions, form) {
        let data = workflow.rawData;
        let requiredActions = [];

        let action = this.getHighestPriorityAction(data);

        let patientDetailsForm = this.formLoader.form(pathwayId, 'patientDetails');
        let requestCriteriaForm = this.formLoader.form(pathwayId, 'requestCriteria');

        let patientDetailsFromCompletionProgress = patientDetailsForm.completionProgress();
        let isPatientDetailsFormComplete = false;
        if(patientDetailsFromCompletionProgress != null && patientDetailsFromCompletionProgress != undefined){
            isPatientDetailsFormComplete = (patientDetailsFromCompletionProgress.current / patientDetailsFromCompletionProgress.total) == 1;
        }
        

        let requestCriteriaFormCompletionProgress = requestCriteriaForm.completionProgress();
        let isRquestCriteriaFormComplete = false;
        if(requestCriteriaFormCompletionProgress != null && requestCriteriaFormCompletionProgress != undefined){
            isRquestCriteriaFormComplete = (requestCriteriaFormCompletionProgress.current / requestCriteriaFormCompletionProgress.total) == 1;
        }
        
        let areFormsForInitialOutcomeFormComplete = isPatientDetailsFormComplete && isRquestCriteriaFormComplete;

        var shouldShowOverweightAction = data['patientWeight'] != null && data['patientWeight'] >= 160;

        redux.store.dispatch(saveWorkflow(pathwayId, {'dxaRequestOutcome': action}, workflow, false, form, 0));
        let titleString = action != null ? action.getTitle(workflow.rawData['privateFundingAvailable']) : null;
        let detail = action != null ? action.getLongDetail(false, workflow.rawData['privateFundingAvailable'] == true) : null;
        let dxaRequestOutcomeAction = new ActionModel(workflow.pathwayUuid, workflow.versionUuid, 'dxaRequestOutcome', titleString, detail, 0, false, false, Date.now(), null);
        let reconsiledDXARequestOutcomeAction = checkAndUpdateForAction(pathwayId, action != null && areFormsForInitialOutcomeFormComplete && !shouldShowOverweightAction, dxaRequestOutcomeAction, 'dxaRequestOutcome', oldActions);
        if (reconsiledDXARequestOutcomeAction != null) {
            requiredActions.push(reconsiledDXARequestOutcomeAction);
        }

        let aboveMaxWeightAction = new ActionModel(workflow.pathwayUuid, workflow.versionUuid, 'overweightAction', null, null, 0, false, false, Date.now(), null);
        let reconsiledAboveMaxWeightAction = checkAndUpdateForAction(pathwayId, shouldShowOverweightAction, aboveMaxWeightAction, 'overweightAction', oldActions);
        if (reconsiledAboveMaxWeightAction != null) {
            requiredActions.push(reconsiledAboveMaxWeightAction);
        }

        var shouldShowWeightIssueAction = data['patientWeight'] != null && data['patientWeight'] >= 136 && data['patientWeight'] < 160 ;
        let weightIssueAction = new ActionModel(workflow.pathwayUuid, workflow.versionUuid, 'weightIssuesAction', null, null, 0, false, false, Date.now(), null);
        let reconsiledWeightIssueAction = checkAndUpdateForAction(pathwayId, shouldShowWeightIssueAction, weightIssueAction, 'weightIssuesAction', oldActions);
        if (reconsiledWeightIssueAction != null) {
            requiredActions.push(reconsiledWeightIssueAction);
        }

        return requiredActions;
    }

    getAutoAcceptedValues(data) {
        if(data['isThisFollowUpRequest'] == 'firstOption'){
            var autoAcceptadValuesForFirst = [
                data['hyperthyroidism'],
                data['malabSorption'],
                data['hyperthyroidism'],
                data['hypogonadism'],
                data['inflammatoryArthritis'],
                data['amenorrhea'],
                data['eatingDisorders'],
                data['inhibitors'],
                data['aromataseInhibitors']
            ];

            return autoAcceptadValuesForFirst;
        } else if(data['isThisFollowUpRequest'] == 'followupOption'){
            var autoAcceptadValuesForFollowUp = [
                data['hyperthyroidismNewSinceLast'],
                data['malabSorptionNewSinceLast'],
                data['hyperthyroidismNewSinceLast'],
                data['hypogonadismNewSinceLast'],
                data['inflammatoryArthritisNewSinceLast'],
                data['amenorrheaNewSinceLast'],
                data['eatingDisordersNewSinceLast'],
                data['inhibitorsNewSinceLast'],
                data['aromataseInhibitorsNewSinceLast']
            ];

            return autoAcceptadValuesForFollowUp;
        } else {
            return [];
        }
    }

    getHighestPriorityAction(data){
        if(data['highRiskCriteraPresent']){
            if(data['isThisFollowUpRequest'] == 'firstOption'){
                return new DXAActionItem(DXAActionItem.Acceptance.autoAccept, true, true, DXAActionItem.TimeFrame.fifteen, ['high risk criteria']);
            } else if(data['isThisFollowUpRequest'] == 'followupOption') {
                return new DXAActionItem(DXAActionItem.Acceptance.autoAccept, true, true, DXAActionItem.TimeFrame.sixMonths, ['high risk criteria'], 'Follow-up scan funded at a 2 year interval initially, and 3 to 5 year interval subsequently.');
            }
        }

        if(data['previousDXATScore'] != null){
            let tScore = data['previousDXATScore'];
            if(tScore <= -2.5){
                if(data['isPatientOnOsteoporosisTreatment'] == true && data['consideringTreatmentBreak'] == false){
                    if(data['onOralOrIVTreatment'] != null){
                        let treatmentType = data['onOralOrIVTreatment'];
                        let reason = 'Osteoporosis (T <= -2.5)';
                        
                        return new DXAActionItem(DXAActionItem.Acceptance.autoAccept, true, true, DXAActionItem.TimeFrame.sixMonths, [reason], 'Follow-up scan is funded at ' + treatmentType == 'ivOption' ? '3 years for intravenous (IV) treatment' : '2 to 3 years for oral treatment'); 
                    }
                } else if(data['consideringTreatmentBreak'] == true){
                    if(data['onOralOrIVTreatment'] != null){
                        let treatmentType = data['onOralOrIVTreatment'];
                        let reason = 'Osteoporosis (T <= -2.5)';
                        
                        return new DXAActionItem(DXAActionItem.Acceptance.autoAccept, true, true, DXAActionItem.TimeFrame.sixMonths, [reason], 'Follow-up scan is funded at ' + treatmentType == 'ivOption' ? '3 years' : '5 years' + ' if the patient is considering a break from treatment.'); 
                    }
                } else if(data['isPatientOnTreatmentBreak']){
                    return new DXAActionItem(DXAActionItem.Acceptance.autoAccept, true, true, DXAActionItem.TimeFrame.sixMonths, ['Osteoporosis (T < -2.5)'], 'Follow-up scan funded [2 years (after oral)/3 to 5 years (after IV)], after stopping treatment if the patient is likely to need further treatment.');
                }
            } else if(tScore <= -1.5){
                return new DXAActionItem(DXAActionItem.Acceptance.manualReview, true, true, DXAActionItem.TimeFrame.sixMonths, ['Osteopenia (T-score -2.5 to -1.5)'], 'Follow-up scan may be funded at the upper end of the recommended follow-up interval (usually 5 years if no major risks).');
            } 
        }
        
        var autoAcceptadValues = this.getAutoAcceptedValues(data);

        var allFurtherInformationValues = [
            data['medicalHistoryMalabsorption'],
            data['hyperthyroidism'],
            data['hyperparathyroidism'],
            data['hypogonadism'],
            data['inflammatoryArthritis'],
            data['amenorrhea'],
            data['eatingDisorders'],
            data['diabities'],
            data['osteogenesisImperfecta'],
            data['medicalHistoryCOPD'],
            data['chronicLiverDisease'],
            data['chronicKidneyDisease'],
            data['lowBMI'],
            data['smoking'],
            data['highAlchoholIntake'],
            data['familyHistoryOfPaternalHipFracture'],
            data['inhibitors'],
            data['glucocorticoids'],
            data['ssri'],
            data['lithium'],
            data['suppressiveDosesOfThyroxine'],
            data['methotrexate'],
            data['prolongedUseOfPPI'],
            data['cyclosporin'],
            data['goserelin'],
            data['antiAndrogens']
        ];

        if(autoAcceptadValues.includes(true)){
            let timeFrame = data['isThisFollowUpRequest'] == 'firstOption' ? DXAActionItem.TimeFrame.fifteen : DXAActionItem.TimeFrame.unclear;
            return new DXAActionItem(DXAActionItem.Acceptance.autoAccept, null, false, timeFrame, ['Secondary risk factors'], 'These requests will be triaged with access based on bone density scan (DXA) availability.');
        } else if(allFurtherInformationValues.includes(true) || (data['hipFractureRisk'] != null && data['hipFractureRisk'] >= 3)) {
            let timeFrame = data['isThisFollowUpRequest'] == 'firstOption' ? DXAActionItem.TimeFrame.fifteen : DXAActionItem.TimeFrame.unclear;
            return new DXAActionItem(DXAActionItem.Acceptance.manualReview, null, false, timeFrame, ['Secondary risk factors'], 'These requests will be triaged with access based on bone density scan (DXA) availability.');
        } else {
            if(data['previousDXATScore'] != null){
                let tScore = data['previousDXATScore'];
                if(tScore >= -1.4 && tScore < -1.0){
                    return new DXAActionItem(DXAActionItem.Acceptance.criteriaNotMet, null, false, DXAActionItem.TimeFrame.unclear, ['Mild osteopenia (T-score -1.4 to -1.0)']);
                } else if(tScore > -1.0){
                    return new DXAActionItem(DXAActionItem.Acceptance.criteriaNotMet, false, false, DXAActionItem.TimeFrame.unclear, [], 'Follow-up scans for normal bone density (T >= -1.0) are not funded unless the risk has changed. Reassess osteoporotic risk every 5 years');
                }
            }

            return new DXAActionItem(DXAActionItem.Acceptance.criteriaNotMet, false, false, DXAActionItem.TimeFrame.none, [], null);
        }
    }

}