import { FormsStyles } from '../../../waferJS/FormsUI/FormsUI';

export class EWSCategory {
    static Type = Object.freeze({ white: 0, yellow: 1, orange: 2, red: 3, blue: 10 });

    constructor(type) {
        this.type = type;
    }

    get color() {
        switch (this.type) {
            case EWSCategory.Type.white:
                return FormsStyles.white;
            case EWSCategory.Type.yellow:
                return FormsStyles.yellow;
            case EWSCategory.Type.orange:
                return FormsStyles.orange;
            case EWSCategory.Type.red:
                return FormsStyles.red;
            case EWSCategory.Type.blue:
                return FormsStyles.blue;
        }
    }

    get score() {
        switch (this.type) {
            case EWSCategory.Type.blue:
                return this.type.toString() + '+';
            default:
                return '+' + this.type.toString();
        }
    }

    get title() {
        let value = '';
        switch (this.type) {
            case EWSCategory.Type.white:
                value = 'WHITE';
                break;
            case EWSCategory.Type.yellow:
                value = 'YELLOW';
                break;
            case EWSCategory.Type.orange:
                value = 'ORANGE';
                break;
            case EWSCategory.Type.red:
                value = 'RED';
                break;
            case EWSCategory.Type.blue:
                value = 'BLUE';
                break;
        }
        return 'Early warning score: ' + value;
    }
}

export class TotalEWS {
    static Glossary = Object.freeze({
        WhiteDetail: 'Normal',
        YellowDetail: 'Potential to deteriorate',
        OrangeDetail: 'Indicates acute illness or unstable chronic disease',
        RedDetail: 'Likely to deteriorate rapidly',
        BlueDetail: 'Immediately life threatening critical illness'
    });

    constructor(categories) {
        this.categories = categories;
    }

    get sum() {
        let count = 0;
        for (let category of this.categories) {
            if(category != null){
                count += category.type;
            }  
        }
        
        return count;
    }

    get color() {
        if (this.categories.filter((category) => { return category.type == EWSCategory.Type.blue; }).length > 0) {
            return FormsStyles.blue;
        } else if (this.categories.filter((category) => { return category.type == EWSCategory.Type.red; }).length > 0) {
            return FormsStyles.red;
        }

        let sum = this.sum;

        if (sum >= 1 && sum < 5) {
            return FormsStyles.yellow;
        } else if (sum >= 5 && sum <= 7) {
            return FormsStyles.orange;
        } else if (sum >= 8 && sum < 9) {
            return FormsStyles.red;
        } else if (sum > 10) {
            return FormsStyles.blue;
        } else {
            return FormsStyles.grey;
        }
    }

    get textColor() {
        if (this.sum == 0) {
            return FormsStyles.grey;
        }
        return FormsStyles.white;
    }

    get solid() {

        return this.sum > 0;
    }

    get title() {
        if(this.sum == 0) {
            return 'NORMAL';
        } else if (this.sum >= 10) {
            return 'TOTAL EWS 10+';
        }
        return 'TOTAL EWS ' + this.sum;
    }

    get detail() {
        if (this.categories.filter((category) => category.type == EWSCategory.Type.blue).length > 0) {
            return TotalEWS.Glossary.BlueDetail;
        } else if (this.categories.filter((category) => category.type == EWSCategory.Type.red).length > 0) {
            return TotalEWS.Glossary.RedDetail;
        }

        let sum = this.sum;
        if (sum >= 1 && sum < 5) {
            return TotalEWS.Glossary.YellowDetail;
        } else if (sum >= 5 && sum < 7) {
            return TotalEWS.Glossary.OrangeDetail;
        } else if (sum >= 8 && sum < 9) {
            return TotalEWS.Glossary.RedDetail;
        } else if (sum > 10) {
            return TotalEWS.Glossary.BlueDetail;
        } else {
            return TotalEWS.Glossary.WhiteDetail;
        }
    }
}

export const calculateEWS = function(identifier, value) {
    switch (identifier) {
        case 'heartRateEWS': {
            if (value != null) {
                if (value >= 50 && value < 90) {
                    return new EWSCategory(EWSCategory.Type.white);
                } else if (value >= 90 && value < 111) {
                    return new EWSCategory(EWSCategory.Type.yellow);
                } else if ((value >= 40 && value < 50) || (value >= 111 && value < 130)) {
                    return new EWSCategory(EWSCategory.Type.orange);
                } else if (value >= 130 && value < 140) {
                    return new EWSCategory(EWSCategory.Type.red);
                } else if (value < 40 || value >= 140) {
                    return new EWSCategory(EWSCategory.Type.blue);
                }
            }
            return null;
        }

        case 'bloodPressureEWS': {
            if (value != null) {
                let components = value.split('/');
                let systolicString = components[0];
                let systolicPressure = parseFloat(systolicString);
                if (systolicPressure != null) {
                    if (systolicPressure >= 110 && systolicPressure < 220) {
                        return new EWSCategory(EWSCategory.Type.white);
                    } else if (systolicPressure >= 100 && systolicPressure < 110) {
                        return new EWSCategory(EWSCategory.Type.yellow);
                    } else if (systolicPressure >= 90 && systolicPressure < 100) {
                        return new EWSCategory(EWSCategory.Type.orange);
                    } else if ((systolicPressure >= 70 && systolicPressure < 90) || systolicPressure >= 220) {
                        return new EWSCategory(EWSCategory.Type.red);
                    } else if (systolicPressure < 70) {
                        return new EWSCategory(EWSCategory.Type.blue);
                    }
                }
            }

            return null;
        }
        case 'bodyTemperatureEWS': {
            if (value != null) {
                if (value >= 36 && value < 38) {
                    return new EWSCategory(EWSCategory.Type.white);
                } else if ((value >= 35 && value < 36) || value >= 38 && value < 39) {
                    return new EWSCategory(EWSCategory.Type.yellow);
                } else if ((value < 35) || value >= 39) {
                    return new EWSCategory(EWSCategory.Type.orange);
                }
            }

            return null;
        }
        case 'oxygenSaturationEWS': {
            if (value != null) {
                if (value >= 96) {
                    return new EWSCategory(EWSCategory.Type.white);
                } else if (value >= 94 && value < 96) {
                    return new EWSCategory(EWSCategory.Type.yellow);
                } else if (value >= 92 && value < 94) {
                    return new EWSCategory(EWSCategory.Type.orange);
                } else if (value < 92) {
                    return new EWSCategory(EWSCategory.Type.red);
                }
            }
            return null;
        }
        case 'respiratoryRateEWS': {
            if (value != null) {
                if (value >= 12 && value < 21) {
                    return new EWSCategory(EWSCategory.Type.white);
                } else if (value >= 9 && value < 12) {
                    return new EWSCategory(EWSCategory.Type.yellow);
                } else if (value >= 21 && value < 25) {
                    return new EWSCategory(EWSCategory.Type.orange);
                } else if ((value >= 5 && value < 9) || (value >= 25 && value < 26)) {
                    return new EWSCategory(EWSCategory.Type.red);
                } else if ((value < 5) || (value >= 35)) {
                    return new EWSCategory(EWSCategory.Type.blue);
                }
            }

            return null;
        }
        case 'supplementalOxygenEWS': {
            if (value != null) {
                if (value == true) {
                    return new EWSCategory(EWSCategory.Type.orange);
                } else if (value == false) {
                    return new EWSCategory(EWSCategory.Type.white);
                }
            }
            return null;
        }

        case 'levelOfConsciousnessEWS': {
            if (value != null) {
                if (value == 'responsivenessAlert') {
                    return new EWSCategory(EWSCategory.Type.white);
                } else if (value == 'responsivenessVoiceOrPain') {
                    return new EWSCategory(EWSCategory.Type.red);
                } else if (value == 'responsivenessUnresponsive') {
                    return new EWSCategory(EWSCategory.Type.blue);
                }
            }

            return null;
        }
        default:
            return null;
    }
};