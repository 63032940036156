/// National Health Index number
/// See [MoH website](https://www.health.govt.nz/our-work/health-identity/national-health-index/information-health-it-vendors-and-developers/nhi-interfaces) for details
export class NHINumber {
    
    static TestNHINumbers = ['ZZZ0008', 'PRP1660'];
    
    static totalLength = 7;
    static prefixLength = 3;
    static numberLength = 3;
    static prefixCharacters = {'A': 1, 'B': 2,'C': 3, 'D': 4, 'E': 5,'F': 6, 'G':7, 'H':8, 'J':9,'K':10, 'L': 11, 'M':12, 'N':13, 'P':14, 'Q':15, 'R':16, 'S':17, 'T':18, 'U':19,'V':20, 'W':21, 'X':22, 'Y':23, 'Z':24};
    static invalidPrefixCharacters = ['O', 'I'];
    static digitRegex = /^\d+$/;

    constructor(prefix, number, checkDigit) {
        this.prefix = prefix;
        this.number = number;
        this.checkDigit = checkDigit;
    }

    static isTest(value) {
        return NHINumber.TestNHINumbers.includes(value) || (value != undefined && value.includes('ZZZ'));
    }
    
    static construct(string) {
        let prefix;
        let checkDigit;

        if(string == null || string.length != NHINumber.totalLength){ return null; }
        
        let uppercaseString = string.toUpperCase();
        
        let numberStartIndex = NHINumber.prefixLength; //uppercaseString.index(uppercaseString.startIndex, offsetBy: NHINumber.prefixLength)
        prefix = uppercaseString.substring(0, numberStartIndex);
        let digits = uppercaseString.substring(numberStartIndex, uppercaseString.length);
        
        // Alphabetic characters must be within the Alphabet Conversion Table (see above), that is, they are not ‘I’ or ‘O’.
        // NNNC numbers must be numeric
        if(checkString(prefix, NHINumber.invalidPrefixCharacters) || !NHINumber.digitRegex.test(digits)) { return null; }

        //self.number = UInt16(digits[digits.startIndex ..< digits.index(digits.startIndex, offsetBy: NHINumber.numberLength)])!
        
        var checksum = 0;
        // Assign first letter its corresponding value from the Alphabet Conversion Table and multiply value by 7.
        // Assign second letter its corresponding value from the Alphabet Conversion Table and multiply value by 6.
        // Assign third letter its corresponding value from the Alphabet Conversion Table and multiply value by 5.
        for(let index = 0; index < prefix.length; index++){
            let character = prefix[index];
            let alphabetLookup = NHINumber.prefixCharacters[character]; 
            let resultingValue;
            if(index == 0){
                resultingValue = alphabetLookup * 7;
            } else if (index == 1){
                resultingValue = alphabetLookup * 6;
            } else if(index == 2){
                resultingValue = alphabetLookup * 5;
            }
            checksum += resultingValue;
        }

        // Multiply first number by 4, multiply second number by 3, multiply third number by 2.
        for(let index = 0; index < digits.length - 1; index++){
            let character = digits[index];
            let resultingValue;
            if(index == 0){
                resultingValue = parseInt(character) * 4;
            } else if (index == 1){
                resultingValue = parseInt(character) * 3;
            } else if(index == 2){
                resultingValue = parseInt(character) * 2;
            }
            checksum += resultingValue;
        }
        
        // Apply modulus 11 to create a checksum.
        checksum = checksum % 11;
        
        
        // If checksum is ‘0’ then the NHI number is incorrect.
        if(checksum == 0){ return null; }
        
        // Subtract checksum from 11 to create check digit. If the check digit equals ‘10’, convert to ‘0’.
        let initialCheckDigit = 11 - checksum;
        let expectedCheckDigit = initialCheckDigit == 10 ? 0 : initialCheckDigit;
        
        // Fourth number must equal the check digit.
        checkDigit = parseInt(digits[digits.length - 1]);
        
        if(checkDigit != expectedCheckDigit) { return null; }

        return new NHINumber(prefix, digits.substring(0, digits.length - 1), checkDigit);
    }

}

function checkString(string, invalidCharacters){
    var contains = false;
    for(var i = 0, ceiling = invalidCharacters.length; i < ceiling; i++) {
        if(string.indexOf(invalidCharacters[i]) != -1) {
            contains = true;
            break;
        }
    }
    return contains;
}

