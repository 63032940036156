import { FormWithRedux } from '../../FormWithRedux';

export class GPChestPainStemiCriteriaForm extends FormWithRedux {

    constructor(formIdentifier, formLoader, parent, actionUpdater, dependentValuesUpdater, calculationsUpdater) {
        super(formIdentifier, formLoader, parent, actionUpdater, dependentValuesUpdater, calculationsUpdater);
    }

    shouldDisplayFormDetailForIdentifier(identifier) {
        super.shouldDisplayFormDetailForIdentifier(identifier);
        if(identifier == 'noToAllStemiCriteria'){
            return this.unselectedExplicitFormDetailsCount(identifier) > 1;
        }
        return true;
    }

    isEnabled(identifier) {
        if (identifier != null) {
            return true;
        }
        return false;
    }

    isMandatory(identifier) {
        if (identifier == 'noToAllStemiCriteria'){
            return false;
        }
        let values = [this.getValue('stemiCriteriaV4toV6'),
            this.getValue('stemiCriteriaV1toV3'),
            this.getValue('stemiCriteriaV7toV9'),
            this.getValue('stemiCriteriaNewLBBB'),
            this.getValue('stemiCriteriaLBBBWithNegativeQRS')];

        if(values.includes(true) || values.includes(false)){
            return true;
        }

        let dependantValue = this.getValue('stemiPresent');
        return dependantValue == null ? false : dependantValue;
    }

    getValueType() {
        return 'boolean';
    }

    submissionValidation() {
        return [];
    }

    detailString(isSubform) {
        let formDetails = this.getFormDetails();
        let values = formDetails.map((value) => {
            return value == null || value.valueDescription() == null || value.valueDescription() == false  ? null : (isSubform ? '- ' : '') + value.valueDescription();
        });

        if(values.filter(n => n).length == 0){
            return isSubform ? '' : this.detail;
        }

        return values.filter(n => n).join(',\n');
    }

}