import { TotalEWS, calculateEWS } from '../Custom Types/EWS';
import moment from 'moment';
import { FormValidationError } from '../../../waferJS/Form/Validation/FormValidationError';
import { RiskCategoryBrackets, RiskCategoryValues } from '../Custom Types/Quidel Risk Category';
import { CalculationModel } from '../Custom Types/Calculation';
import { FormsStyles } from '../../../waferJS/FormsUI/FormsUI';

export class QuidelChestPainCalculationHelper {

    static riskTypes = { high: FormsStyles.red, considerable: FormsStyles.orange, moderate: FormsStyles.yellow, low: FormsStyles.green }

    static calcualtionError(identifier, data, form) {
        let highRiskErrorMessage = 'Not calculated for patients with high risk findings';

        if (data == null) {
            return null;
        }

        if (identifier == 'calculatedEDACS') {
            let patientAge = data['patientAge'];
            if (patientAge != null && patientAge < 18) {
                return new FormValidationError(FormValidationError.FormErrorType.warning, 'Only for patients ≥18 years old');
            }
            else if (data['patientSex'] == 'genderOtherOption') {
                return new FormValidationError(FormValidationError.FormErrorType.warning, 'Patient is not eligible for pathway');
            }
            return null;
        } else if (identifier == 'probabilityOfMACE' || identifier == 'pathwayRiskCategory') {

            if (data['patientSex'] == 'genderOtherOption') {
                return new FormValidationError(FormValidationError.FormErrorType.warning, 'Patient is not eligible for pathway');
            }

            let stemiPresent = data['stemiPresent'];

            // Refer to mainForm or the parent of the form (mainForm)
            let mainForm = form.parent != null ? form.parent : form;

            let ecgCompletionProgress = mainForm.subform('ecgTest').completionProgress();
            let ecgComplete = (ecgCompletionProgress.current / ecgCompletionProgress.total) == 1;
            if (stemiPresent != null && stemiPresent && ecgComplete) {
                return new FormValidationError(FormValidationError.FormErrorType.warning, 'Not calculated for patients with STEMI');
            }

            if (data['newIschaemicChangesOverride'] != true) {
                if (data['newIschaemia'] != null && data['newIschaemia']) {
                    return new FormValidationError(FormValidationError.FormErrorType.warning, highRiskErrorMessage);
                }
            }

            if (data['redFlagsOngoingChestPainOverride'] != true) {
                if (data['ongoingChestPain'] != null && data['ongoingChestPain']) {
                    return new FormValidationError(FormValidationError.FormErrorType.warning, highRiskErrorMessage);
                }
            }


            if (data['crescendoAnginaOverride'] != true) {
                if (data['crescendoPain'] != null && data['crescendoPain']) {
                    return new FormValidationError(FormValidationError.FormErrorType.warning, highRiskErrorMessage);
                }
            }

            if (data['haemodynamicInstabilityOverride'] != true) {
                let ewsIdentifiers = [
                    'heartRateEWS',
                    'bloodPressureEWS',
                    'bodyTemperatureEWS',
                    'oxygenSaturationEWS',
                    'respiratoryRateEWS',
                    'supplementalOxygenEWS',
                    'levelOfConsciousnessEWS'
                ];

                let categories = ewsIdentifiers.map((identifier) => {
                    let value = data[identifier.replace('EWS', '')];
                    return calculateEWS(identifier, value);
                }).filter(n => n);

                if (categories.length < ewsIdentifiers.length) {
                    return null;
                }
                let declaredNormal = data['areSignsNormal'];
                let totalEWS = new TotalEWS(categories, declaredNormal);

                if (totalEWS != null && totalEWS.sum >= totalEWS.haemodynamicInstabilityThreshold) {
                    return new FormValidationError(FormValidationError.FormErrorType.warning, highRiskErrorMessage);
                }
            }
        }
        return null;
    }

    static getRiskBracket(data, ignoreT2Troponin) {
        if (data == null) { return null; }

        let followUpTroponin;
        if (!ignoreT2Troponin) {
            followUpTroponin = data['followUpBloodsTroponin'];
        }

        let onsetTime = data['firstDateTime'];
        let initialTroponin = data['initialBloodsDateAndTime'];
        var date1 = new Date(onsetTime);
        var date2 = new Date(initialTroponin);

        var hoursBetweenOnsetAndInitialTroponin = this.diffHours(date2, date1);

        let riskCategoryValue = new RiskCategoryValues(data['calculatedEDACS'], data['patientDateOfBirth'] != null ? moment().diff(data['patientDateOfBirth'], 'years') : null, data['initialBloodsTroponin'], false, hoursBetweenOnsetAndInitialTroponin, followUpTroponin);
        let bracket = riskCategoryValue.riskBracket;
        if (bracket != null) {
            let riskBracket = new RiskCategoryBrackets(bracket);
            return riskBracket;
        }
        return null;
    }

    static diffHours(dt2, dt1) {
        var diff = (dt2.getTime() - dt1.getTime()) / 1000;
        diff /= (60 * 60);
        return Math.abs(Math.round(diff));
    }

    static getLatestCalculation(calculationType, calculations) {
        if (calculations == null) { return null; }

        var filteredDict = Object.keys(calculations).reduce(function (filtered, key) {
            if (calculations[key].type == calculationType) filtered[key] = calculations[key];
            return filtered;
        }, {});

        let filteredSortedArray = Object.keys(filteredDict).map(function (key) {
            return filteredDict[key];
        }).sort((first, second) => {
            return new Date(second.lastModifiedDate).getTime() - new Date(first.lastModifiedDate).getTime();
        });

        if (filteredSortedArray.length == 0) {
            return null;
        }

        return new CalculationModel(filteredSortedArray[0].pathwayUuid, filteredSortedArray[0].versionUuid, filteredSortedArray[0].value, filteredSortedArray[0].observed, filteredSortedArray[0].type, filteredSortedArray[0].lastModifiedDate, filteredSortedArray[0].uuid);
    }

    static getPreviousCalculation(calculationType, calculations) {
        if (calculations == null) { return null; }

        var filteredDict = Object.keys(calculations).reduce(function (filtered, key) {
            if (calculations[key].type == calculationType) filtered[key] = calculations[key];
            return filtered;
        }, {});

        let filteredSortedArray = Object.keys(filteredDict).map(function (key) {
            return filteredDict[key];
        }).sort((first, second) => {
            return new Date(second.lastModifiedDate).getTime() - new Date(first.lastModifiedDate).getTime();
        });

        if (filteredSortedArray.length < 2) {
            return null;
        }

        return new CalculationModel(filteredSortedArray[1].pathwayUuid, filteredSortedArray[1].versionUuid, filteredSortedArray[1].value, filteredSortedArray[1].observed, filteredSortedArray[1].type, filteredSortedArray[1].lastModifiedDate, filteredSortedArray[1].uuid);
    }

    static getHighestPriorityCalculationBadges(calculations) {
        let highestPriorityCalculations = [];

        let highPriorityCalcualtions = QuidelChestPainCalculationHelper.getCalculationsWithRisk(calculations, QuidelChestPainCalculationHelper.riskTypes.high);
        let considerablePriorityCalcualtions = QuidelChestPainCalculationHelper.getCalculationsWithRisk(calculations, QuidelChestPainCalculationHelper.riskTypes.considerable);
        let moderatePriorityCalcualtions = QuidelChestPainCalculationHelper.getCalculationsWithRisk(calculations, QuidelChestPainCalculationHelper.riskTypes.moderate);
        let lowPriorityCalcualtions = QuidelChestPainCalculationHelper.getCalculationsWithRisk(calculations, QuidelChestPainCalculationHelper.riskTypes.low);

        if (highPriorityCalcualtions.length > 0) {
            highestPriorityCalculations = highPriorityCalcualtions;
        } else if (considerablePriorityCalcualtions.length > 0) {
            highestPriorityCalculations = considerablePriorityCalcualtions;
        } else if (moderatePriorityCalcualtions.length > 0) {
            highestPriorityCalculations = moderatePriorityCalcualtions;
        } else if (lowPriorityCalcualtions.length > 0) {
            highestPriorityCalculations = lowPriorityCalcualtions;
        }

        let badgeValues = highestPriorityCalculations.map((calculation) => {
            if (calculation.type == CalculationModel.CalculationType.riskCategory) {
                let riskBracket = new RiskCategoryBrackets(calculation.value);
                return {
                    color: riskBracket.riskCategory.color,
                    value: 'Pathway Risk Category: ' + (riskBracket.riskCategory.title != null ? riskBracket.riskCategory.title : 'N/A'),
                    solid: true
                };
            }

            return {
                color: calculation.badgeColor,
                value: (calculation.type == CalculationModel.CalculationType.probabilityOfMace ? 'Probability Of MACE: ' : 'Calculated EDACS: ') + calculation.valueString,
                solid: true
            };
        });

        return badgeValues;
    }

    static getCalculationsWithRisk(calculations, risk) {
        return calculations.filter((calculation) => {
            if (calculation.type == CalculationModel.CalculationType.riskCategory) {
                if (calculation.value == null) { return false; }
                let riskBracket = new RiskCategoryBrackets(calculation.value);
                return riskBracket.riskCategory.color == risk;
            }
            return calculation.badgeColor == risk;
        });
    }
}
