import { ENABLED_EXPORTS, ACC_API_ROOT } from '../../../config/config';

export const EXPORT_TO_ACC_PENDING = 'EXPORT_TO_ACC_PENDING';
export const EXPORT_TO_ACC_FULFILLED = 'EXPORT_TO_ACC_FULFILLED';
export const EXPORT_TO_ACC_FAILED = 'EXPORT_TO_ACC_FAILED';
export const PREVIEW_TO_ACC_FULFILLED = 'PREVIEW_TO_ACC_FULFILLED';

export const RESET_ACC_EXPORT_STATE = 'RESET_ACC_EXPORT_STATE';

const exportToACC = (data) => (dispatch) => {
    dispatch({
        type: EXPORT_TO_ACC_PENDING
    });

    // let headers = {
    //     'Content-Type': 'application/json'
    // };

    let postData = JSON.stringify(data);

    if (ENABLED_EXPORTS.includes('acc') == false) {
        console.log('ACC_API_ROOT not enabled for environment.');
        return dispatch({
            type: EXPORT_TO_ACC_FAILED
        });
    }

    if (ACC_API_ROOT === null || ACC_API_ROOT == undefined) {
        console.log('ACC_API_ROOT not defined for environment.');
        
        return dispatch({
            type: EXPORT_TO_ACC_FAILED
        });
    }

    return post(`${ACC_API_ROOT}send`, data).then((response) => {
        return dispatch({
            type: EXPORT_TO_ACC_FULFILLED
        });
    }).catch((error) => {
        return dispatch({
            type: EXPORT_TO_ACC_FAILED
        });
    });

    // axios.post(`${HCS_API_ROOT}receiveChestpainDocument`, postData, { headers: headers }).then((response) => {
    //     console.log(response);
    //     dispatch({
    //         type: EXPORT_TO_HCS_FULFILLED
    //     });
    // }).catch((error) => {
    //     console.log(error);
    //     dispatch({
    //         type: EXPORT_TO_HCS_FAILED
    //     });
    // });
};

const previewToACC = (data) => (dispatch) => {
    dispatch({
        type: EXPORT_TO_ACC_PENDING
    });

    let postData = JSON.stringify(data);

    if (ENABLED_EXPORTS.includes('acc') == false) {
        console.log('ACC_API_ROOT not enabled for environment.');
        return dispatch({
            type: EXPORT_TO_ACC_FAILED
        });
    }

    if (ACC_API_ROOT === null || ACC_API_ROOT == undefined) {
        console.log('ACC_API_ROOT not defined for environment.');
        
        return dispatch({
            type: EXPORT_TO_ACC_FAILED
        });
    }

    return postPreview(`${ACC_API_ROOT}preview`, data).then((response) => {
        return dispatch({
            type: PREVIEW_TO_ACC_FULFILLED,
            data: response
        });
    }).catch((error) => {
        return dispatch({
            type: EXPORT_TO_ACC_FAILED
        });
    });
};

const resetState = () => (dispatch) => {
    dispatch({
        type: RESET_ACC_EXPORT_STATE
    });
};

function post(url = '', data = {}) {
    // Default options are marked with *
    return fetch(url, {
        method: 'POST',
        mode: 'cors', 
        cache: 'no-cache', 
        // credentials: 'include',
        headers: {
            'Content-Type': 'application/json'
        },
        redirect: 'follow',
        referrer: 'no-referrer',
        body: JSON.stringify(data), 
    }).then(response => response.json()); 
}

function postPreview(url = '', data = {}) {
    // Default options are marked with *
    return fetch(url, {
        method: 'POST',
        mode: 'cors', 
        cache: 'no-cache', 
        // credentials: 'include',
        headers: {
            'Content-Type': 'application/json'
        },
        redirect: 'follow',
        referrer: 'no-referrer',
        body: JSON.stringify(data), 
    }).then(response => response.text()); 
}

export { exportToACC, previewToACC, resetState };