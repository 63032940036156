import { AuthState } from "../../../models/AuthState";
import { AUTH_USER } from "../../../models/actions/Auth.actions";

const initialSettings: AuthState = {
  loginUserId: null,
};

export const AuthReducer = (state = initialSettings, action) => {
  switch (action.type) {
    case AUTH_USER:
      return {
        ...state,
        loginUserId: action.payload,
      };
    default:
      return state;
  }
};
