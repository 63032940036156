import { FormContentView } from '../../FormsUI/FormsUI Components/Containers/FormContentView';
import { FormsStyles } from '../../FormsUI/FormsUI';
import React from 'react';
import PropTypes from 'prop-types';
import { FormButton } from '../../FormsUI/FormsUI Components/Controls/FormButton';


export class NoToGroupFormDetail extends React.Component {

    constructor(props) {
        super(props);

        this.performAction = this.performAction.bind(this);
    }

    performAction(){
        this.props.formDetail.form.noToAll(this.props.formDetail.identifier);
    }

    render() {
        let unselectedCount = this.props.formDetail.form.unselectedExplicitDetailString(this.props.formDetail.identifier);
        let button = <FormButton key='Cancel' onPress={this.performAction} enabled={true} isSelected={false} title={'No to ' + unselectedCount + (unselectedCount == 'all' ? (this.props.formDetail.title != null ? ' ' + this.props.formDetail.title : '') : ' other' + (this.props.formDetail.title != null && this.props.formDetail.title != ''  ? ' ' + this.props.formDetail.title : 's'))}/>;
        return (
            <FormContentView identifier={this.props.formDetail.identifier} titleString={''} rawValue={this.props.rawValue} isSubcell={this.props.isSubcell} rightControl={button} detailString={this.props.formDetail.detail} subcell={[]} subCellbackgroundColor={FormsStyles.backgroundColor} showHover={true}/>
        );
    }
}

NoToGroupFormDetail.propTypes = {
    formDetail: PropTypes.object.isRequired,
    isSubcell: PropTypes.bool.isRequired,
    renderErrors: PropTypes.func.isRequired,
    renderSubcells: PropTypes.func.isRequired,
    errorLabels: PropTypes.array.isRequired,
    hasError: PropTypes.bool.isRequired,
    rawValue: PropTypes.any
};