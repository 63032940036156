import {
    SAVE_CALCULATION_PENDING,
    SAVE_CALCULATION_FULFILLED,
    SAVE_CALCULATION_FAILED,
    CLEAR_CALCULATIONS_FULFILLED,
    LAST_OBSERVED_CALCULATIONS_UPDATED,
    CLEAR_OBSERVED_CALCULATIONS
} from '../actions/CalculationActions';

// INITIALIZE STATE
const initialState = {
    calculations: {},
    lastObservedCalculations: {},
    saveCalculationPending: false,
    saveCalculationFulfilled: false,
    saveCalculationFailed: false,
    clearCalculationsFulfilled: false,
    
};

export const CalculationReducer = (state = initialState, action) => {
    switch (action.type) {
        case SAVE_CALCULATION_PENDING: {
            return {
                ...state,
                saveCalculationPending: true,
                saveCalculationFulfilled: false,
                saveCalculationFailed: false
            };
        }
        case SAVE_CALCULATION_FULFILLED: {
            let calculations = Object.assign({}, state.calculations);
            calculations[action.payload.pathwayId] = action.payload.calculations;
            return {
                ...state,
                calculations: calculations,
                saveCalculationPending: false,
                saveCalculationFulfilled: true,
                saveCalculationFailed: false
            };
        }
        case SAVE_CALCULATION_FAILED: {
            return {
                ...state,
                saveCalculationPending: false,
                saveCalculationFulfilled: false,
                saveCalculationFailed: true
            };
        }
        case CLEAR_CALCULATIONS_FULFILLED: {
            let calculations = Object.assign({}, state.calculations);
            calculations[action.payload.pathwayId] = action.payload.calculations;
            return {
                ...state,
                clearCalculationsFulfilled: true,
                calculations: calculations
            };
        }
        case LAST_OBSERVED_CALCULATIONS_UPDATED:{
            let lastObservedCalculations = Object.assign({}, state.lastObservedCalculations);
            lastObservedCalculations[action.payload.pathwayId] = action.payload.lastObservedCalculations;
            return {
                ...state,
                lastObservedCalculations: lastObservedCalculations,
            };
        }
        case CLEAR_OBSERVED_CALCULATIONS:{
            let lastObservedCalculations = Object.assign({}, state.lastObservedCalculations);
            lastObservedCalculations[action.payload.pathwayId] = action.payload.calculations;
            return {
                ...state,
                lastObservedCalculations: lastObservedCalculations
            };
        }
        default:
            return state;
    }
};