import { FormWithRedux } from '../../FormWithRedux';
import { GPChestPainPathwayInformationForm} from './GPChestPain-PathwayInformationForm';

export class GPChestPainIntroForm extends FormWithRedux {

    constructor(formLoader) {
        super('introForm', formLoader);
    }

    generateSubform(identifier) {
        switch (identifier) {
            case 'pathwayInformationForm':
                return new GPChestPainPathwayInformationForm(this.formLoader);
        }
    }

    shouldDisplayFormDetailForIdentifier() {
        return true;
    }

    isEnabled(identifier) {
        if (identifier != null) {
            return true;
        }
        return false;
    }

    isMandatory() {
        return false;
    }

    getValueType() {
        return null;
    }

    submissionValidation() {
        return [];
    }

    detailString() {
        return this.detail;
    }
}