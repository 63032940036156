import { FormWithRedux } from '../../FormWithRedux';
import { FormsStyles } from '../../../waferJS/FormsUI/FormsUI';
import { FormValidationError } from '../../../waferJS/Form/Validation/FormValidationError';
import { PathwayReferenceHelper } from '../../PathwayReferenceHelper';
import { GPChestPainPathwayReference } from '../GPChestPainPathway-Reference';

export class GPChestPainRedFlagsForm extends FormWithRedux {

    constructor(formLoader, parent, actionUpdater, dependentValuesUpdater, calculationsUpdater) {
        super('redFlags', formLoader, parent, actionUpdater, dependentValuesUpdater, calculationsUpdater);
    }

    shouldDisplayFormDetailForIdentifier(identifier) {
        super.shouldDisplayFormDetailForIdentifier(identifier);
        if( identifier == 'aorticDissectionPresentRedFlagOverrideValue'){
            let dependentValue = this.getValue('aorticDissectionPresentRedFlagOverride');
            return dependentValue == true;
        } else if(identifier == 'pulmonaryEmbolismRedFlagOverrideValue'){
            let dependentValue = this.getValue('pulmonaryEmbolismRedFlagOverride');
            return dependentValue == true;
        } else if(identifier == 'pancreatitisRedFlagOverrideValue'){
            let dependentValue = this.getValue('pancreatitisRedFlagOverride');
            return dependentValue == true;
        } else if( identifier == 'pericarditisRedFlagOverrideValue'){
            let dependentValue = this.getValue('pericarditisRedFlagOverride');
            return dependentValue == true;
        } else if( identifier == 'pneumothoraxRedFlagOverrideValue'){
            let dependentValue = this.getValue('pneumothoraxRedFlagOverride');
            return dependentValue == true;
        } else if( identifier == 'cardiotoxicityRedFlagOverrideValue'){
            let dependentValue = this.getValue('cardiotoxicityRedFlagOverride');
            return dependentValue == true;
        } else if( identifier == 'troponinAboveCutOffOverrideValue'){
            let dependentValue = this.getValue('troponinAboveCutOffOverride');
            return dependentValue == true;
        }  else if( identifier == 'newIschaemicChangesOverrideValue'){
            let dependentValue = this.getValue('newIschaemicChangesOverride');
            return dependentValue == true;
        } else if( identifier == 'redFlagsOngoingChestPainOverrideValue'){
            let dependentValue = this.getValue('redFlagsOngoingChestPainOverride');
            return dependentValue == true;
        } else if( identifier == 'crescendoAnginaOverrideValue'){
            let dependentValue = this.getValue('crescendoAnginaOverride');
            return dependentValue == true;
        } else if( identifier == 'haemodynamicInstabilityOverrideValue'){
            let dependentValue = this.getValue('haemodynamicInstabilityOverride');
            return dependentValue == true;
        }
        return true;
    }

    isEnabled(identifier) {
        if (identifier != null) {
            return true;
        }
        return false;
    }

    isMandatory(identifier) {
        if(identifier == 'aorticDissectionPresentRedFlagOverrideReason'){
            return this.getValue('aorticDissectionPresentRedFlagOverride') == true;
        } else if(identifier == 'pulmonaryEmbolismRedFlagOverrideReason'){
            return this.getValue('pulmonaryEmbolismRedFlagOverride') == true;
        } else if(identifier == 'pancreatitisRedFlagOverrideReason'){
            return this.getValue('pancreatitisRedFlagOverride') == true;
        } else if (identifier == 'pericarditisRedFlagOverrideReason'){
            return this.getValue('pericarditisRedFlagOverride') == true;
        } else if(identifier == 'pneumothoraxRedFlagOverrideReason'){
            return this.getValue('pneumothoraxRedFlagOverride') == true;
        } else if(identifier == 'cardiotoxicityRedFlagOverrideReason'){
            return this.getValue('cardiotoxicityRedFlagOverride') == true;
        } else if(identifier == 'troponinAboveCutOffOverrideReason'){
            return this.getValue('troponinAboveCutOffOverride') == true;
        } else if(identifier == 'newIschaemicChangesOverrideReason'){
            return this.getValue('newIschaemicChangesOverride') == true;
        } else if(identifier == 'redFlagsOngoingChestPainOverrideReason'){
            return this.getValue('redFlagsOngoingChestPainOverride') == true;
        } else if(identifier == 'crescendoAnginaOverrideReason'){
            return this.getValue('crescendoAnginaOverride') == true;
        } else if(identifier == 'haemodynamicInstabilityOverrideReason'){
            return this.getValue('haemodynamicInstabilityOverride') == true;
        } else {
            let redFlagIdentifiers = [
                'aorticDissectionPresentRedFlag',
                'pulmonaryEmbolismRedFlag',
                'pancreatitisRedFlag',
                'pericarditisRedFlag',
                'pneumothoraxRedFlag',
                'cardiotoxicityRedFlag',
                'troponinAboveCutOff',
                'newIschaemicChanges',
                'redFlagsOngoingChestPain',
                'crescendoAngina',
                'haemodynamicInstability'
            ];

            if(redFlagIdentifiers.includes(identifier)){
                return true;
            }
        }

        return false;
    }

    getValueType(identifier) {
        let overrideReasonIdentifiers = [
            'aorticDissectionPresentRedFlagOverrideReason',
            'pulmonaryEmbolismRedFlagOverrideReason',
            'pancreatitisRedFlagOverrideReason',
            'pericarditisRedFlagOverrideReason',
            'pneumothoraxRedFlagOverrideReason',
            'cardiotoxicityRedFlagOverrideReason',
            'troponinAboveCutOffOverrideReason',
            'newIschaemicChangesOverrideReason',
            'redFlagsOngoingChestPainOverrideReason',
            'crescendoAnginaOverrideReason',
            'haemodynamicInstabilityOverrideReason',
            
        ];

        let overrideIdentifiers = [
            'aorticDissectionPresentRedFlagOverride',
            'pulmonaryEmbolismRedFlagOverride',
            'pancreatitisRedFlagOverride',
            'pneumothoraxRedFlagOverride',
            'cardiotoxicityRedFlagOverride',
            'pericarditisRedFlagOverride',
            'troponinAboveCutOffOverride',
            'newIschaemicChangesOverride',
            'redFlagsOngoingChestPainOverride',
            'crescendoAnginaOverride',
            'haemodynamicInstabilityOverride' 
        ];

        if(overrideReasonIdentifiers.includes(identifier)){
            return 'string';
        } else if (overrideIdentifiers.includes(identifier)){
            return 'boolean';
        }

        return null;
    }

    submissionValidation() {
        return [];
    }

    getValue(identifier) {
        let overrideIdentifiers = [
            'aorticDissectionPresentRedFlagOverride',
            'pulmonaryEmbolismRedFlagOverride',
            'pancreatitisRedFlagOverride',
            'pneumothoraxRedFlagOverride',
            'cardiotoxicityRedFlagOverride',
            'pericarditisRedFlagOverride',
            'troponinAboveCutOffOverride',
            'newIschaemicChangesOverride',
            'redFlagsOngoingChestPainOverride',
            'crescendoAnginaOverride',
            'haemodynamicInstabilityOverride'
        ];

        if(overrideIdentifiers.includes(identifier)){
            let value = super.getValue(identifier);
            return value == null ? false : value;
        }
        return super.getValue(identifier);
    }

    getProgress(identifier){
        if(this.getValue(identifier + 'Override') == true){
            if(this.getValue(identifier + 'OverrideReason') != null){
                return 1;
            }
            return 0.5;
        }

        return 1;
    }

    detailString() {
        let redflags = [
            'aorticDissectionPresentRedFlag',
            'pulmonaryEmbolismRedFlag',
            'pancreatitisRedFlag',
            'pericarditisRedFlag',
            'pneumothoraxRedFlag',
            'cardiotoxicityRedFlag',
            'troponinAboveCutOff',
            'newIschaemicChanges',
            'redFlagsOngoingChestPain',
            'crescendoAngina',
            'haemodynamicInstability'
        ];

        let values = redflags.map((redFlag) => {
            let value = this.getValue(redFlag + 'OverrideValue');
            if(value == null){
                value = this.getValue(redFlag);
            }
                
            if(value != null){
                return value ? PathwayReferenceHelper.reference(redFlag, GPChestPainPathwayReference.data).title : null;
            }
        });

        let formProgress = this.completionProgress();
        if(values.filter(n => n).length == 0 && (formProgress.current / formProgress.total) == 1){
            return 'None';
        }else if(values.filter(n => n).length == 0){
            return this.detail;
        }

        return values.filter(n => n).join(',\n');
    }

    getBadgeValue(identifier) {
        let overrideValue = this.getValue(identifier + 'OverrideValue');
        let displayValue = overrideValue;
        if(overrideValue == null){
            displayValue = this.getValue(identifier);
        }
        
        if(displayValue != null){
            return [{
                color: displayValue == true ? FormsStyles.red : FormsStyles.black,
                value: displayValue == true ? 'YES' : 'NO',
                solid: overrideValue != null
            }];
        }
    }

    totalRedFlags(){
        return this.getValue('totalRedFlags') == null ? 0 : this.getValue('totalRedFlags');
    }

    getOverrideReason(identifier){
        let isOverridden = this.getValue(identifier + 'Override');
        let overrideReason = this.getValue(identifier + 'OverrideReason');
        if(isOverridden){
            return 'Overridden' + (overrideReason == null ? '' :  ': ' + overrideReason);
        }
        return null;
    }

    supportsOverride() {
        return true;
    }

    isRedFlagActive(identifier){
        let value = this.getValue(identifier);
        return value; // override == true ? !value : value;
    }

    calculationError(identifier){
        if(identifier == 'aorticDissectionPresentRedFlag'){
            let value = this.getValue('aorticDissectionPresentRedFlag');
            if(value == null){
                return new FormValidationError(FormValidationError.FormErrorType.warning, 'From High-risk differential diagnosis results');
            }
        } else if(identifier == 'pulmonaryEmbolismRedFlag'){
            let value = this.getValue('pulmonaryEmbolismRedFlag');
            if(value == null){
                return new FormValidationError(FormValidationError.FormErrorType.warning, 'From High-risk differential diagnosis results');
            }
        } else if(identifier == 'pancreatitisRedFlag'){
            let value = this.getValue('pancreatitisRedFlag');
            if(value == null){
                return new FormValidationError(FormValidationError.FormErrorType.warning, 'From High-risk differential diagnosis results');
            }
        } else if(identifier == 'pericarditisRedFlag'){
            let value = this.getValue('pericarditisRedFlag');
            if(value == null){
                return new FormValidationError(FormValidationError.FormErrorType.warning, 'From High-risk differential diagnosis results');
            }
        } else if(identifier == 'pneumothoraxRedFlag'){
            let value = this.getValue('pneumothoraxRedFlag');
            if(value == null){
                return new FormValidationError(FormValidationError.FormErrorType.warning, 'From High-risk differential diagnosis results');
            }
        } else if(identifier == 'cardiotoxicityRedFlag'){
            let value = this.getValue('cardiotoxicityRedFlag');
            if(value == null){
                return new FormValidationError(FormValidationError.FormErrorType.warning, 'From Initial assessment results');
            }
        } else if(identifier == 'troponinAboveCutOff'){
            let value = this.getValue('troponinAboveCutOff');
            if(value == null){
                return new FormValidationError(FormValidationError.FormErrorType.warning, 'From Bloods results');
            }
        } else if(identifier == 'newIschaemicChanges'){
            let value = this.getValue('newIschaemicChanges');
            if(value == null){
                return new FormValidationError(FormValidationError.FormErrorType.warning, 'From ECG results');
            }
        } else if(identifier == 'redFlagsOngoingChestPain'){
            let value = this.getValue('redFlagsOngoingChestPain');
            if(value == null){
                return new FormValidationError(FormValidationError.FormErrorType.warning, 'From Initial assessment results');
            }
        } else if(identifier == 'crescendoAngina'){
            let value = this.getValue('crescendoAngina');
            if(value == null){
                return new FormValidationError(FormValidationError.FormErrorType.warning, 'From Initial assessment results');
            }
        } else if(identifier == 'haemodynamicInstability'){
            let value = this.getValue('haemodynamicInstability');
            if(value == null){
                return new FormValidationError(FormValidationError.FormErrorType.warning, 'From Initial assessment results');
            }
        }
        return null;
    }

    completionProgress() {
        var totalFormDetails = 0;
        var validFormDetails = 0;
        
        for (let formDetailIdentifier of this.formDetailIdentifiers) {
            let formDetail = this.formDetail(formDetailIdentifier);
            if (formDetail != null && formDetail.canHaveValue) {
                let form = formDetail.form;
                let mandatory = form.isMandatory(formDetailIdentifier);

                let value = this.getValue(formDetail.identifier);

                if (mandatory) {
                    totalFormDetails += 1;

                    if (value != null) {
                        validFormDetails += 1;
                    } 
                }
            }
        }

        for (let subformIdentifier of this.subformIdentifiers) {
            let subform = this.subform(subformIdentifier);
            let subformProgress = subform.completionProgress();
            totalFormDetails += subformProgress.total;
            validFormDetails += subformProgress.current;
        }

        return { current: validFormDetails, total: totalFormDetails };
    }

}