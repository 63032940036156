// IMPORT PACKAGE REFERENCES
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { PathwayReferenceHelper } from '../../../forms/PathwayReferenceHelper';
import { ExportToHCSForm } from '../../../forms/sharedForms/ExportToHCSForm';
import { FormButton } from '../../../waferJS/FormsUI/FormsUI Components/Controls/FormButton';
import { exportToHCS, resetState } from '../../state/actions/ExportToHCSActions';
import { Loading } from '../../shared/Loading';
import { MetadataHelper } from '../../../ExportToHCS/MetadataHelper'; 
import { FormLabel } from '../../../waferJS/FormsUI/FormsUI Components/Controls/FormLabel';
import { FormsStyles } from '../../../waferJS/FormsUI/FormsUI';
import { incrementWorkFlow, markWorkFlowAsExported } from '../../state/actions/WorkflowResponseActions';
import { FormDetailWithReact } from '../../../waferJS/Form To FormsUI/FormDetailWithReact';

// COMPONENT
class ExportToHCSOverlay extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            form: new ExportToHCSForm(props.formLoader)
        };

        this.cancelAction = this.cancelAction.bind(this);
        this.performExport = this.performExport.bind(this);
        this.dismiss = this.dismiss.bind(this);
    }

    componentDidUpdate(prevProps){
        if(this.props.exportToHCSFulfilled && !prevProps.exportToHCSFulfilled){
            this.props.markWorkFlowAsExported(this.props.pathwayWorkflow.pathwayUuid, this.props.pathwayWorkflow);
        }

        if(JSON.stringify(this.props.hcsWorkflow) != JSON.stringify(prevProps.hcsWorkflow)){
            this.setState({form:  new ExportToHCSForm(this.props.formLoader)});
        }
    }

    componentWillUnmount(){
        this.props.resetState();
    }

    cancelAction() {
        this.props.toggleOverlay();
    }

    performExport(){
        let patient = {
            firstNames:  this.props.hcsWorkflow.rawData['patientFirstNames'],
            surname: this.props.hcsWorkflow.rawData['patientSurname'],
            gender: this.props.pathwayWorkflow.rawData['patientSexInt'],
            nhiNumber: this.props.hcsWorkflow.rawData['patientNHINumber'],
            optOut: false
        };

        let user = {
            firstName: this.props.hcsWorkflow.rawData['userFirstNames'],
            lastName: this.props.hcsWorkflow.rawData['userSurname'],
            identityType: this.props.hcsWorkflow.rawData['userIdentity'],
            identityString: this.props.hcsWorkflow.rawData['userIdentifier']
        };

        let metadataJSON = MetadataHelper.constructMetadata(this.props.hcsWorkflow, patient, user);
        
        let json = {
            'pathwayJSON': this.props.pathwayWorkflow.rawData,
            'patientJSON': patient,
            'userJSON': user
        };
        
        let body = {
            'metadata': metadataJSON,
            'jsonData': json,
            'htmlBody' : this.props.htmlBody
        };

        this.props.exportToHCS(body);
    }

    dismiss(){
        this.props.resetState();
        this.props.toggleOverlay();
    }

    render() {
        let progress = this.state.form.completionProgress();
        let isComplete = (progress.current / progress.total) == 1;

        return (
            <div className="session-wrapper">
                <div className="session-overlay" />

                <div className="environment-manager-container">
                    <div className="environment-card open" ref={sessionCardNode => this.sessionCardNode = sessionCardNode}>

                        <div className="card-overlay-exit-button-container">
                            {<FormButton title={''} image={<img className={'session-card-exit'} src={require('../../../images/cross-blue.png')} />} backgroundColor={FormsStyles.backgroundColor} enabled={true} isSelected={false} onPress={this.dismiss}/> }
                        </div>

                        <header className={'session-title'}>{this.state.form.title}</header>
                        {!this.props.exportToHCSPending && (this.props.exportToHCSFulfilled || this.props.exportToHCSFailed) && 
                            <div className="export-status-container">
                                <div><FormLabel value={this.props.exportToHCSFulfilled ? 'Pathway Exported' : 'Error, failed to upload. Please try again.'} textColor={this.props.exportToHCSFulfilled ? FormsStyles.primaryTint : 'red'} fontSize={this.props.exportToHCSFulfilled ? 1 : null}/></div> 
                            </div>
                        }

                        <div className="session-body">
                            {!this.props.exportToHCSPending && !this.props.exportToHCSFulfilled && 
                                <div >
                                    {this.state.form.sectionIds.map((sectionID) => {
                                        let section = this.state.form.sectionReference(sectionID);
                                        return (
                                            <div key={sectionID} className='form-section'>
                                                {section[PathwayReferenceHelper.Type.sectionChildIDs].map((childID) => { return this.state.form.shouldDisplayFormDetailForIdentifier(childID) ? true : null; }).filter(n => n).length > 0 && <div className='section-title'>{section.title}</div>}
                                                {
                                                    section[PathwayReferenceHelper.Type.sectionChildIDs] != undefined &&
                                                        section[PathwayReferenceHelper.Type.sectionChildIDs].map((childID) => {
                                                            if (this.state.form.shouldDisplayFormDetailForIdentifier(childID)) {
                                                                let formDetail = this.state.form.formDetail(childID);
                                                                return <FormDetailWithReact formLoader={this.props.formLoader} key={formDetail.identifier} formDetail={formDetail} isSummary={false} isSubcell={false} showHover={true} />;
                                                            }
                                                        })
                                                }
                                            </div>
                                        );
                                    })}
                                </div>
                            }
                            {this.props.exportToHCSPending && <Loading/>}
                            {!this.props.exportToHCSPending &&
                            <div className="export-overlay-buttons-container">
                                {!this.props.exportToHCSFulfilled && <FormButton title={'Upload'} enabled={isComplete} isSelected={false} onPress={this.performExport}/> }
                                {this.props.exportToHCSFulfilled  && <FormButton title={'Okay'} enabled={isComplete} isSelected={false} onPress={this.cancelAction}/> }
                            </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

ExportToHCSOverlay.propTypes = {
    toggleOverlay: PropTypes.func.isRequired,
    exportToHCS: PropTypes.func.isRequired,
    exportToHCSPending: PropTypes.bool.isRequired,
    exportToHCSFulfilled: PropTypes.bool.isRequired, 
    exportToHCSFailed: PropTypes.bool.isRequired,
    hcsWorkflow: PropTypes.object.isRequired,
    pathwayWorkflow: PropTypes.object.isRequired,
    htmlBody: PropTypes.string.isRequired,
    resetState: PropTypes.func.isRequired,
    incrementWorkFlow: PropTypes.func.isRequired,
    markWorkFlowAsExported: PropTypes.func.isRequired
};


// CONFIGURE REACT REDUX
const mapStateToProps = state => {
    const { exportToHCSPending, exportToHCSFulfilled, exportToHCSFailed } = state.exportToHCSReducer;
    return {  exportToHCSPending, exportToHCSFulfilled, exportToHCSFailed };
};

const mapDispatchToProps = dispatch => (
    bindActionCreators({ exportToHCS, resetState, incrementWorkFlow, markWorkFlowAsExported }, dispatch)
);

const hoc = connect(mapStateToProps, mapDispatchToProps)(ExportToHCSOverlay);

// EXPORT COMPONENT

export { hoc as ExportToHCSOverlay };