import { FormWithRedux } from '../../FormWithRedux';
import { GallbladderComplicatedBiliarDiseaseForm } from './Gallbladder-ComplicatedBiliarDiseaseForm';
import { FormValidation } from '../../../waferJS/Form/Validation/FormValidation';
import { FormValidationError } from '../../../waferJS/Form/Validation/FormValidationError';

export class GallbladderRequestionCriteriaForm extends FormWithRedux {

    constructor(formLoader, parent, actionUpdater, dependentValuesUpdater, calculationsUpdater) {
        super('requestCriteria', formLoader, parent, actionUpdater, dependentValuesUpdater, calculationsUpdater);
    }

    generateSubform(identifier) {
        switch (identifier) {
            case 'complicatedBiliaryDiseaseCriteriaForm':
                return new GallbladderComplicatedBiliarDiseaseForm(this.formLoader, this.parent, this.actionUpdater, this.dependentValuesUpdater, this.calculationsUpdater);
        }
    }

    shouldDisplayFormDetailForIdentifier(identifier) {
        super.shouldDisplayFormDetailForIdentifier(identifier);
        if(identifier == 'incidentalUSSFindings'){
            let dependentValues = [ 
                this.getValue('ruqPain'),
                this.getValue('biliaryColic'),
                this.getValue('cholecystitis'),
                this.getValue('transientJaundice'),
                this.getValue('cholangitis'),
                this.getValue('pancreatitis'),
                this.getValue('persistingSymptomsAfterSurgery')
            ];
                
            return !dependentValues.includes(true) || this.getValue('incidentalUSSFindings') == true;
        }else if(identifier == 'complicatedBiliaryDiseaseCriteriaForm'){
            return true;
        } else if (identifier == 'cholecystectomyOrBiliarySurgeryNameOfSurgeonIfKnown'){
            return this.getValue('persistingSymptomsAfterSurgery') || this.getValue('cholecystectomyOrBiliarySurgeryNameOfSurgeonIfKnown') != null;
        } else if(identifier == 'hiddenRequestReasonTracker'){
            return false;
        }
        return true;
    }

    isEnabled(identifier) {
        if (identifier != null) {
            return true;
        }
        return false;
    }

    isMandatory(identifier) {
        if(identifier == 'hiddenRequestReasonTracker'){
            return true;
        }
        return false;
    }

    getValueType() {
        return 'boolean';
    }

    submissionValidation(identifier) {
        if(identifier == 'cholecystectomyOrBiliarySurgeryNameOfSurgeonIfKnown'){
            let self = this;
            let criteriaConflict = new FormValidation('SurgeonNameConflict', function () {
                let value = self.getValue('cholecystectomyOrBiliarySurgeryNameOfSurgeonIfKnown');
                if(value != null && self.getValue('persistingSymptomsAfterSurgery') == null){
                    return [new FormValidationError(FormValidationError.FormErrorType.error, 'Modify Hospital and surgeon details to match selection')];
                }
                return [];
            });
                
            return [criteriaConflict];
        } else if(identifier == 'incidentalUSSFindings'){
            let self = this;
            let criteriaConflict = new FormValidation('PolypsConflict', function () {
                let dependentValues = [ self.getValue('ruqPain'),
                    self.getValue('biliaryColic'),
                    self.getValue('cholecystitis'),
                    self.getValue('transientJaundice'),
                    self.getValue('cholangitis'),
                    self.getValue('pancreatitis'),
                    self.getValue('persistingSymptomsAfterSurgery')];
                if(dependentValues.includes(true) && self.getValue('incidentalUSSFindings') == true){
                    return [new FormValidationError(FormValidationError.FormErrorType.error, 'Cannot select this reason alongside indication of biliary symptoms')];
                }
                return [];
            });
                
            return [criteriaConflict];
        }
        return [];
    }

    detailString(isSubform, isPlainText) {
        let formDetails = this.getFormDetails();
        let values = formDetails.map((value) => {
            if(value.identifier == 'hiddenRequestReasonTracker'){
                return null;
            } else if(value.identifier == 'cholecystectomyOrBiliarySurgeryNameOfSurgeonIfKnown'){
                return this.getValue('cholecystectomyOrBiliarySurgeryNameOfSurgeonIfKnown') != null ? 'Hospital and surgeon details: ' + this.getValue('cholecystectomyOrBiliarySurgeryNameOfSurgeonIfKnown') : null ;
            }
            return value.valueDescription() == null ? null : (isSubform ? '- ' : '') + value.valueDescription();
        });

        if(isPlainText == true){
            return values.filter(n => n).join(', ');
        }

        return values.filter(n => n).join(',\n');
    }
}