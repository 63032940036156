// IMPORT PACKAGE REFERENCES
import React from 'react';
import PropTypes from 'prop-types';
import { CircularProgressbar }  from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { FormsStyles } from '../../FormsUI';

// COMPONENT
export class FormPie extends React.Component {

    constructor(props) {
        super(props);
    }

    shouldComponentUpdate(nextProps) {
        if(nextProps.totalValue !== this.props.totalValue){
            return true;
        }

        if(nextProps.filledValue !== this.props.filledValue){
            return true;
        }

        return false;
    }

    render() {
        let percentage = (this.props.filledValue / this.props.totalValue) * 100;
        if (isNaN(percentage)) {
            percentage = 0;
        }

        let width = '16px';

        return (
            <div className={'form-pie'} style={{ width: width, height: width, marginRight: '0.2em', display: 'flex', justifyContent: 'center', alignItems:'center'}}>
                {percentage != 100 &&
                    <CircularProgressbar value={percentage} strokeWidth={50} textForPercentage={null} styles={{ path: { strokeLinecap: 'butt', stroke: FormsStyles.lightGray }, background: { stroke: FormsStyles.lightestGray } }} />
                }
                {percentage == 100 &&
                    <div><img style={{width: width, height: width, background: FormsStyles.lightGray, borderRadius: '2em' }} className='tick' src={require('../../../../images/tick.png')} /></div>
                }
            </div>
        );
    }
}

FormPie.propTypes = {
    totalValue: PropTypes.number.isRequired,
    filledValue: PropTypes.number.isRequired
};