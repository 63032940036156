// IMPORT PACKAGE REFERENCES
import React from 'react';
import '../../Styles/pictureGrid.scss';
import '../../Styles/button.scss';
import propTypes from 'prop-types';

// COMPONENT
export class FormCardGrid extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            cardNumber: this.props.cardNumber,
            cardList: this.props.cardList,
            imageClick: this.props.imageClick,
        };
    }

    render() {
        return (
            <div key={this.props.identifier}>
                <div className={`${this.props.cardNumber > 3 ? 'multi-grid-wrapper' : 'grid-wrapper'}`}>
                    {this.props.cardList.map((card) => (
                        <div className='picture-card-wrapper' key={card.id + this.props.identifier}>
                            <img src={`/assets/${card.imageName}`} alt={card.alt} className={`${this.props.selectedCardlist[card.id] != null ? 'multi-card-selected' : 'multi-picture-card'}`} onClick={() => this.props.imageClick(card.id)} />
                            <span className='card-label'>{card.label}</span>
                        </div>
                    ))}
                </div>
            </div>
        );
    }
}

FormCardGrid.propTypes = {
    cardNumber: propTypes.number,
    cardList: propTypes.array,
    imageClick: propTypes.func,
    identifier: propTypes.string,
    selectedCardlist: propTypes.object
};
