import { FormWithRedux } from '../../FormWithRedux';
import { DateOfBirth } from '../../../waferJS/Form To FormsUI/Subclasses/Validation Overrides/DateTimeValidation';
import { FormValidation } from '../../../waferJS/Form/Validation/FormValidation';
import { FormValidationError } from '../../../waferJS/Form/Validation/FormValidationError';

export class OsteoporosisPatientDetailsForm extends FormWithRedux {

    constructor(formLoader, parent, actionUpdater, dependentValuesUpdater) {
        super('patientDetails', formLoader, parent, actionUpdater, dependentValuesUpdater);
    }

    shouldDisplayFormDetailForIdentifier(identifier) {
        super.shouldDisplayFormDetailForIdentifier(identifier);
        if(identifier == 'dxaNotPossibleDueToWeight'){
            return this.getValue('patientWeight') >= 160;
        } else if(identifier == 'infectionRiskDetail'){
            return this.getValue('infectionRiskToStaff') == true || this.getValue('infectionRiskDetail') != null;
        } else if(identifier == 'privatelyFundedDetail'){
            return this.getValue('privateFundingAvailable') == true;
        } else if(identifier == 'patientDateOfBirth'){
            return this.getValue(identifier) != null || this.getValue('patientAge') == null;
        }

        return true;
    }

    isEnabled(identifier) {
        if (identifier != null) {
            return true;
        }
        return false;
    }

    isMandatory(identifier) {
        if(identifier == 'patientAge' || identifier == 'patientWeight' || identifier == 'privateFundingAvailable' || identifier == 'infectionRiskToStaff'){
            return true;
        } else if( identifier == 'infectionRiskDetail'){
            return this.getValue('infectionRiskToStaff') == true;
        }
        return false;
    }

    getValueType(identifier) {
        if(identifier == 'infectionRiskDetail'){
            return 'string';
        } else if(identifier == 'patientWeight' || identifier == 'patientAge'){
            return 'double';
        } else if(identifier == 'privateFundingAvailable' || identifier == 'infectionRiskToStaff'){
            return 'boolean';
        } else if (identifier == 'patientDateOfBirth'){
            return 'date';
        }
        return null;
    }

    submissionValidation(identifier) {
        if(identifier == 'infectionRiskDetail'){
            let self = this;
            let criteriaConflict = new FormValidation('InfectionRiskConflict', function () {
                if(self.getValue('infectionRiskDetail') != null && self.getValue('infectionRiskToStaff') == false){
                    return [new FormValidationError(FormValidationError.FormErrorType.error, 'Modify infection risk specification to match selection')];
                }
                return [];
            });
                
            return [criteriaConflict];
        }
        return [];
    }

    editingValidation(identifier){
        if (identifier == 'patientDateOfBirth') {
            return [DateOfBirth];
        }
        return [];
    }

    detailString(isSubcell, isPlainText) {
        let formDetails = this.getFormDetails();
        let values = formDetails.map((value) => {
            return this.getSummaryValue(isSubcell, value);
        });

        if(values.filter(n => n).length == 0){
            return isPlainText ? '' : this.detail;
        }
        
        return values.filter(n => n).join(isPlainText == true ? ', ' : ',\n');
    }

    getSummaryValue(isSubcell, value){
        if (value.identifier == 'patientAge' && value.valueDescription() != null){
            if (value.currentValue != null) {
                return 'Age: ' + this.getValue('patientAge') + ' year' + (this.getValue('patientAge') == 1 ? '' : 's');
            }
        } else if (value.identifier == 'patientWeight'){
            if(value.currentValue != null){
                return value.title + ': ' + value.valueDescription() + 'kg';
            }
            return null;
        } else if(value.identifier == 'privateFundingAvailable'){
            if(value.currentValue == true){
                return 'Private funding indicated';
            }
            return null;
        } else if (value.identifier == 'infectionRiskToStaff'){
            if(value.currentValue == true){
                return 'Disability or infection risk to staff' + (this.getValue('infectionRiskDetail') != null ? ' (Reason: ' + this.getValue('infectionRiskDetail') + ')' : '');
            }
        } else if(value.identifier == 'infectionRiskDetail'){
            return null;
        }
        return value.valueDescription();
    }
}