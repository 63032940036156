export class ACCMRIActionItem {
    
    static Acceptance = Object.freeze({mriEligible: 'MEETS CRITERIA FOR ACC-FUNDED MRI ON GP REFERRAL', specialistAssessment: 'SPECIALIST RECOMMENDS MRI', awaitingRecommendation: 'AWAITING FURTHER INFORMATION', notAvailable: 'ACC FUNDED MRI NOT AVAILABLE ON GP REFERRAL'})

    constructor(acceptance, reasons, additionalDetail){
        this.acceptance = acceptance;
        this.reasons = reasons;
        this.additionalDetail = additionalDetail;
    }

    getLongDetail() {
        let detailStrings = [];

        if (this.reasons.length > 0) {
            detailStrings.push('Outcome determined by:' + (this.reasons.length != 1 ? '\n' : ' ') + this.reasons.join(',\n'));
        }

        if(this.acceptance == ACCMRIActionItem.Acceptance.mriEligible || this.acceptance == ACCMRIActionItem.Acceptance.specialistAssessment) {
            detailStrings.push('Attach the outcome to your MRI request form so that further action can be scheduled using the ‘Copy as Plain Text’ button at the bottom of this page, then paste (Ctrl + V) in into your request.');
        } 
        else if (this.acceptance == ACCMRIActionItem.Acceptance.awaitingRecommendation) {
            detailStrings.push('Based on the information you have provided, imaging or management recommendations must be completed before a MRI request outcome can be provided. If you feel an MRI is indicated, refer privately or request a specialist assessment for consideration of ACC funding.');
        } 
        else if (this.acceptance == ACCMRIActionItem.Acceptance.notAvailable) {
            detailStrings.push('Based on the information you have provided, your patient does not meet the criteria for funding. If you feel an MRI is indicated, refer privately or request a specialist assessment for consideration of ACC funding.');
        }

        if (this.additionalDetail != null) {
            detailStrings.push('\n' + this.additionalDetail);
        }
        
        return detailStrings.join('\n');
    }

    getReasons() {
        if (this.reasons.length > 1) {
            return '\n' + this.reasons.join(', ');
        } else {
            return this.reasons;
        }
    }

    getTitle() {
        if(this.acceptance == ACCMRIActionItem.Acceptance.mriEligible) {
            return 'This request meets criteria for ACC-funded MRI on GP referral';
        } else if(this.acceptance == ACCMRIActionItem.Acceptance.specialistAssessment) {
            return 'Specialist has recommended MRI';
        } else if (this.acceptance == ACCMRIActionItem.Acceptance.awaitingRecommendation) {
            return 'Awaiting further information';
        } else if(this.acceptance == ACCMRIActionItem.Acceptance.notAvailable) {
            return 'ACC funded MRI not available on GP referral';
        }
    }

    static rehydrate(actionItem){
        return new ACCMRIActionItem(actionItem.acceptance, actionItem.reasons, actionItem.additionalDetail);
    }
} 