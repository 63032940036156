import { FormContentView } from '../../FormsUI/FormsUI Components/Containers/FormContentView';
import { FormsStyles } from '../../FormsUI/FormsUI';
import { FormButton } from '../../FormsUI/FormsUI Components/Controls/FormButton';
import { HorizontalButtonControl } from '../../FormsUI/FormsUI Components/Controls/HorizontalButtonControl';
import React from 'react';
import PropTypes from 'prop-types';

export class SegmentedControlFormDetail extends React.Component {

    constructor(props) {
        super(props);

        this.renderButtons = this.renderButtons.bind(this);
        this.optionSelected = this.optionSelected.bind(this);
        this.handleKeyPress = this.handleKeyPress.bind(this);
    }

    componentDidMount(){
        document.addEventListener('keypress', this.handleKeyPress);
    }

    componentWillUnmount(){
        document.removeEventListener('keypress', this.handleKeyPress);
    }

    optionSelected(option) {
        let updatedOption = this.props.formDetail.editingValue == option ? null : option;
        this.props.formDetail.form.valueUpdated(this.props.formDetail.identifier, updatedOption);
        this.props.renderErrors();

        // window.gtag('event', 'selected-multiple-choice-option', {
        //     event_category: 'action',
        //     event_label: 'selected-multiple-choice-option'
        // });
    }

    renderButtons(enabled) {
        let buttons = [];
        for (let index in this.props.formDetail.options) {
            let option = this.props.formDetail.options[index];
            buttons.push(<FormButton key={option.identifier} enabled={enabled} onPress={() => this.optionSelected(option.identifier)} isSelected={this.props.formDetail.editingValue === option.identifier} title={option.title} ignoreTab={true} />);
        }
        return (<HorizontalButtonControl buttons={buttons} forceColumn={true} />);
    }

    handleKeyPress(e){
        if(document.activeElement == this.focusNode){
            // window.gtag('event', 'used-keyboard-input-for-segment', {
            //     event_category: 'action',
            //     event_label: 'used-keyboard-input-for-segment'
            // });
            if (e.which == 13){ //enter key
                if(this.props.formDetail.editingValue == null){
                    this.optionSelected(this.props.formDetail.options[0].identifier);
                } else {
                    for (let index in this.props.formDetail.options) {
                        let option = this.props.formDetail.options[index];
                        if(this.props.formDetail.editingValue == option.identifier){
                            if(index == this.props.formDetail.options.length - 1){
                                this.optionSelected(option.identifier);
                            } else {
                                this.optionSelected(this.props.formDetail.options[parseFloat(index) + 1].identifier);
                            }
                            break;
                        }
                    }
                }
            } else {
                let keyChar = String.fromCharCode(e.which);
                for (let index in this.props.formDetail.options) {
                    let option = this.props.formDetail.options[index];
                    if(keyChar == (parseInt(index) + 1).toString()){
                        this.optionSelected(option.identifier);
                        break;
                    }
                }
            }
        }

    }

    render() {
        let subcells = this.props.renderSubcells();
        let isEnabled = this.props.formDetail.form.isEnabled(this.props.formDetail.identifier);
        let isMandatory = this.props.formDetail.form.isMandatory(this.props.formDetail.identifier);

        return (<FormContentView identifier={this.props.formDetail.identifier} isSubcell={this.props.isSubcell} style={FormsStyles.enabledStyle(isEnabled)} isMandatory={isMandatory} titleString={this.props.formDetail.title} detailString={this.props.formDetail.detail} subCellbackgroundColor={FormsStyles.backgroundColor} validation={this.props.errorLabels} rightControl={this.renderButtons(isEnabled)} subcell={subcells} showHover={true} allowFocus={true} focusRef={focusNode => this.focusNode = focusNode} />);
    }
}

SegmentedControlFormDetail.propTypes = {
    formDetail: PropTypes.object.isRequired,
    options: PropTypes.array.isRequired,
    isSubcell: PropTypes.bool.isRequired,
    renderErrors: PropTypes.func.isRequired,
    renderSubcells: PropTypes.func.isRequired,
    errorLabels: PropTypes.array.isRequired,
    hasError: PropTypes.bool.isRequired
};