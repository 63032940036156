import { EDChestPainBloodsForm } from './EDChestPain-BloodsForm';
import { IsDate, IsDateInPastError } from '../../../waferJS/Form To FormsUI/Subclasses/Validation Overrides/DateTimeValidation';
import { IsQuidelOrthoTroponinResult, IsQuidelOrthoTroponinString } from '../Validation Overrides/QuidelOrthoChestPain-TroponinValidation';

export class QuidelOrthoChestPainBloodsForm extends EDChestPainBloodsForm {

    constructor(formLoader, parent, actionUpdater, dependentValuesUpdater, calculationsUpdater) {
        super(formLoader, parent, actionUpdater, dependentValuesUpdater, calculationsUpdater);
    }

    submissionValidation(identifier) {
        if (identifier === 'initialBloodsDateAndTime' || identifier == 'followUpBloodsDateAndTime') {
            return [IsDate, IsDateInPastError];
        } else if (identifier == 'initialBloodsTroponin' || identifier == 'followUpBloodsTroponin') {
            return [IsQuidelOrthoTroponinResult];
        }
        return [];
    }

    editingValidation(identifier) {
        if (identifier == 'initialBloodsTroponin' || identifier == 'followUpBloodsTroponin') {
            return [IsQuidelOrthoTroponinString];
        }
        return [];
    }

    abbreviatedString(identifier, value) {
        switch (identifier) {
            case 'initialBloodsTroponin':
            case 'followUpBloodsTroponin': {
                if(value < 0.05){
                    return 'Troponin ' + '<0.05' + 'ng/mL';
                }
                return 'Troponin ' + value + 'ng/mL';
            }
            case 'initialBloodsDateAndTime':
            case 'followUpBloodsDateAndTime':
                return value;
        }
    }

    getValueType(identifier) {
        if (identifier === 'initialBloodsDateAndTime') {
            return 'date';
        } else if (identifier == 'initialBloodsTroponin') {
            return 'qoTroponin';
        } else  if (identifier === 'followUpBloodsDateAndTime') {
            return 'date';
        } else if (identifier == 'followUpBloodsTroponin') {
            return 'qoTroponin';
        }

        return null;
    }
}