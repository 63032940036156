import React from "react";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { ErrorBoundary } from "react-error-boundary";
import { PersistGate } from "redux-persist/integration/react";
import { ThemeProvider } from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import GenericErrorFallback from "./components/Common/GenericErrorFallback";
import { theme } from "./themes/theme";
import { SnackbarProvider } from "notistack";
import SuccessSnackbar from "./components/Common/snackbars/SuccessSnackbar";
import ErrorSnackbar from "./components/Common/snackbars/ErrorSnackbar";
import WarningSnackbar from "./components/Common/snackbars/WarningSnackbar";
import AppAuthProvider from "./components/Common/AppAuthProvider";
import AppRoutes from "./components/routers/AppRoutes";
import store from "./components/state/stores/AppStore";
import "./App.css";
import "./styles/app.scss";

declare module "notistack" {
  interface VariantOverrides {
    success: {
      cta?: string;
    };
    error: {
      cta?: string;
    };
    warning: {
      cta?: string;
    };
  }
}

const redux = store;
export { redux };

function App() {
  return (
    <ThemeProvider theme={theme}>
      <AppAuthProvider>
        <SnackbarProvider
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          Components={{
            success: SuccessSnackbar,
            error: ErrorSnackbar,
            warning: WarningSnackbar,
          }}
        >
          <ErrorBoundary
            fallbackRender={({ error }) => (
              <GenericErrorFallback error={error} />
            )}
          >
            <Provider store={redux.store}>
              <PersistGate loading={null} persistor={redux.persistor}>
                <BrowserRouter basename="/">
                  <CssBaseline />
                  <AppRoutes />
                </BrowserRouter>
              </PersistGate>
            </Provider>
          </ErrorBoundary>
        </SnackbarProvider>
      </AppAuthProvider>
    </ThemeProvider>
  );
}

export default App;
