import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { login, loginWithToken, beginLoginWithSSO, resetLoadingState, authMethod } from '../../state/actions/UserActions';
import { FormButton } from '../../../waferJS/FormsUI/FormsUI Components/Controls/FormButton';
import { FormLabel } from '../../../waferJS/FormsUI/FormsUI Components/Controls/FormLabel';
import { FormTextField } from '../../../waferJS/FormsUI/FormsUI Components/Controls/FormTextField';
import { Loading } from '../../shared/Loading';
import { withRouter } from '../../routers/withRouter';

class LoginOverlay extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            password: '',
            username: '',
            passwordRequired: false,
            hasTriedLogin: false,
            isRedirecting: false
        };

        this.emailChange = this.emailChange.bind(this);
        this.passwordChange = this.passwordChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.onKeyPress = this.onKeyPress.bind(this);
        this.tokenReceived = this.tokenReceived.bind(this);
        this.pushToSSO = this.pushToSSO.bind(this);
    }

    componentDidUpdate(prevProps) {
        if(this.props.authenticationType != prevProps.authenticationType){
            this.setState({passwordRequired: this.props.authenticationType == 'manual'});
            if(this.state.hasTriedLogin) {
                if(this.props.authenticationType == 'AzureAD') {
                    this.pushToSSO();
                } else {
                    this.setState({hasTriedLogin: false});
                }
            }
        }
        
        if(this.props.checkingSSOFulfilled != prevProps.checkingSSOFulfilled) {
            this.setState({passwordRequired: this.props.authenticationType == 'manual'});
            if(this.state.hasTriedLogin && this.props.authenticationType == 'AzureAD') {
                this.pushToSSO();
            }
        }

        if(this.props.checkingSSOFailed != prevProps.checkingSSOFailed) {
            this.setState({passwordRequired: this.props.authenticationType == 'manual'});
            if(this.state.hasTriedLogin && this.props.authenticationType == 'AzureAD') {
                this.pushToSSO();
            }
        }
    }

    componentDidMount() {
        this.props.toggleUserContentVisibility(true);
        this.tokenReceived();

        this.setState({
            isRedirecting: false,
            hasTriedLogin: false
        });
        this.props.resetLoadingState();
    }

    pushToSSO() {
        if(this.state.username != undefined && this.state.username != '') {
            this.setState({isRedirecting: true});
            this.props.beginLoginWithSSO();
        }
    }

    passwordChange(value) {
        this.setState({password: value});
    }

    emailChange(value) {
        this.setState({
            username: value,
            hasTriedLogin: false
        });
    }

    handleSubmit() {
        if (this.state.username != '' && this.state.username != null && this.state.username != undefined) {
            this.setState({hasTriedLogin: true});

            if(this.state.passwordRequired) {
                this.setState({hasTriedLogin: false});
                this.props.login(this.state.username, this.state.password);
            } else {
                this.props.authMethod(this.state.username);
            }
        }
    }

    onKeyPress(event) {
        if(event.key === 'Enter') {
            this.handleSubmit();
        }
    }

    tokenReceived() {
        // getTokenAsync != null ? getTokenAsync((error, token) => {
        //     if (error && !token) {
        //         console.log('Adal token error', error);
        //     }
            
        //     if (token) {
        //         let decodedToken = decode(token);
        //         if (decodedToken) {
        //             this.setState({isRedirecting: true});
        //             this.props.loginWithToken(decodedToken);
        //         }
        //     }
        // }) : null;
    }

    render() {
        return (
            <div className="session-wrapper">
                <div className="session-overlay" />
                <div className="environment-manager-container">
                    <div className="login-manager-aligner">
                        <div className="faux-card"></div>
                        <div className="login-card open" ref={sessionCardNode => this.sessionCardNode = sessionCardNode}>
                            <header className={'login-title'}>{'Welcome'}</header>

                            <div className={'login-detail'}>
                                <FormLabel value={'Sign in to access Wayfind'} alignText={'center'} fontSize={1} />
                            </div>

                            <div className="login-form-section">
                                {!this.props.loggingIn && !this.state.isRedirecting && !this.props.checkingSSOPending &&
                                    <div className="login-form-group">
                                        <div className="login-text-input-group">
                                            <FormTextField className="login-input" value={this.state.username} spellCheck={false} onKeyPress={this.onKeyPress} inputType={'email'} alignment={'center'} onChange={this.emailChange} shouldDelayResponse={false} placeholder={'Email'} width={'100%'} />
                                            {this.state.passwordRequired && 
                                            <FormTextField className="login-input" value={this.state.password} onKeyPress={this.onKeyPress} inputType={'password'} alignment={'center'} onChange={this.passwordChange} shouldDelayResponse={false} placeholder={'Password'} width={'100%'} />
                                            }
                                        </div>
                                        <FormButton title={'Login'} enabled={true} isSelected={false} onPress={this.handleSubmit}/>
                                    </div>
                                }
                                {(this.props.loggingIn || this.state.isRedirecting || this.props.checkingSSOPending) && 
                                    <div className="login-form-group">
                                        <div className="login-text-input-group">
                                            <Loading />
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

LoginOverlay.propTypes = {
    location: PropTypes.object.isRequired,
    navigate: PropTypes.func.isRequired,
    loggingIn: PropTypes.bool.isRequired,
    loggingOut: PropTypes.bool.isRequired,
    failed: PropTypes.bool.isRequired,
    message: PropTypes.string.isRequired,
    login: PropTypes.func.isRequired,
    authenticationType: PropTypes.string.isRequired,
    loginWithToken: PropTypes.func.isRequired,
    authMethod: PropTypes.func.isRequired,
    checkingSSOPending: PropTypes.bool.isRequired,
    checkingSSOFulfilled: PropTypes.bool.isRequired,
    checkingSSOFailed: PropTypes.bool.isRequired,
    toggleUserContentVisibility: PropTypes.func.isRequired,
    beginLoginWithSSO: PropTypes.func.isRequired,
    resetLoadingState: PropTypes.func.isRequired,
};

// CONFIGURE REACT REDUX
const mapStateToProps = state => {
    const { loggingIn, loggingOut, failed, message, authenticationType, checkingSSOPending, checkingSSOFulfilled, checkingSSOFailed} = state.userReducer;
    return { loggingIn, loggingOut, failed, message, authenticationType, checkingSSOPending, checkingSSOFulfilled, checkingSSOFailed };
};

const mapDispatchToProps = dispatch => (
    bindActionCreators({ login, loginWithToken, beginLoginWithSSO, resetLoadingState, authMethod}, dispatch)
);

const hoc = withRouter(connect(mapStateToProps, mapDispatchToProps)(LoginOverlay));

// EXPORT COMPONENT
export { hoc as LoginOverlay };