// IMPORT PACKAGE REFERENCES
import React from 'react';
import '../../Styles/pictureGrid.scss';
import propTypes from 'prop-types';

// COMPONENT
export class FormCardGridCheck1 extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      cardList: this.props.cardList,
    };
  }

  render() {

    return (
      <div>
        <div className='grid-wrapper'>
          {this.props.cardList.map((card) => (
            <div className='picture-card-wrapper' key={card.id}>
              <img 
                style={{
                  objectFit: 'contain',
                }} 
                height="100%" 
                src={`/assets/${card.imageName}`} 
                alt={card.alt} 
                className='picture-card-check-one' 
              />

              <span className='card-label'>{card.label}</span>
            </div>
          ))}
        </div>
      </div>
    );
  }

}

FormCardGridCheck1.propTypes = {
  cardList: propTypes.array,
};