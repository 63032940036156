export class SharedFormData {
    static data = {
        'title': 'Environment Information',
        'template.contentTabIDs': ['mainForm'],
        'template.defaultContentTabID': 'mainForm',
        'template.content': {
            'mainForm': {
                'title': 'Pathway',
                'itemType': 'subform',
                'subform.sectionIDs': [
                    'subformsSection',
                ]
            },
            'subformsSection': {
                'title': 'Subforms',
                'itemType': 'section',
                'section.childIDs': [
                    'exportToHCSForm',
                    'pilotInformationForm',
                    'demoInformationForm',
                    'devInformationForm',
                    'testInformationForm',
                    'stagingInformationForm'
                ]
            },
            'exportToHCSForm': {
                'title': 'Upload to HCS',
                'itemType': 'subform',
                'subform.sectionIDs': [
                    'userInformation',
                    'patientInformation'
                ]
            },
            'userInformation': {
                'title': 'User Details',
                'itemType': 'section',
                'section.childIDs': [
                    'userSurname',
                    'userFirstNames',
                    'userIdentity',
                    'userIdentifier'
                ]
            },
            'userFirstNames': {
                'title': 'First Name(s)',
                'itemType': 'control',
                'control.type': 'textEntry',
                'textEntry.type': 'singleLinePlainText',
            },
            'userSurname': {
                'title': 'Surname',
                'itemType': 'control',
                'control.type': 'textEntry',
                'textEntry.type': 'singleLinePlainText',
            },
            'userIdentity': {
                'title': 'Identification type',
                'itemType': 'control',
                'control.type': 'selection',
                'selection.style': 'simple',
                'selection.options': [
                    'medicalCouncil',
                    'nursingCouncil'
                ]
            },
            'medicalCouncil': {
                'title': 'Medical Council',
                'itemType': 'option'
            },
            'nursingCouncil': {
                'title': 'Nursing Council',
                'itemType': 'option'
            },
            'userIdentifier':{ 
                'title': 'Identifier',
                'itemType': 'control',
                'control.type': 'textEntry',
                'textEntry.type': 'singleLinePlainText',
            },
            'patientInformation': {
                'title': 'Patient Details',
                'itemType': 'section',
                'section.childIDs': [
                    'patientSurname',
                    'patientFirstNames',
                    'patientNHINumber'
                ]
            },
            'patientFirstNames': {
                'title': 'First Name(s)',
                'itemType': 'control',
                'control.type': 'textEntry',
                'textEntry.type': 'singleLinePlainText',
            },
            'patientSurname': {
                'title': 'Surname',
                'itemType': 'control',
                'control.type': 'textEntry',
                'textEntry.type': 'singleLinePlainText',
            },
            'patientNHINumber': {
                'title': 'NHI Number',
                'itemType': 'control',
                'control.type': 'textEntry',
                'textEntry.type': 'singleLinePlainText',
            },
            'pilotInformationForm': {
                'title': 'Pilot Information',
                'itemType': 'subform',
                'detail': 'CDHB is conducting a pilot of a digital decision making tool on selected clinical pathways. Wayfind takes the existing clinical guidance on HealthPathways and presents it in an electronic decision support tool.\n\nTo provide feedback on this pilot, please fill out our #@^pilot evaluation form^https://otago.au1.qualtrics.com/jfe/form/SV_3Is0AcorgSMDzIp#@.\n\nIf you have questions, or would like someone from the Wayfind team to get in touch, contact us at #@^wayfind.health/contact.html^https://wayfind.health/contact.html#@ and we will get back to you.',
                'subform.sectionIDs': [
                    // 'questionsSection',
                    // 'additionalDetailsSection'
                ]
            },
            'questionsSection': {
                'title': '',
                'itemType': 'section',
                'section.childIDs': [
                    'whatIsWayfind',
                    'whyThePilot',
                    'howProvideFeedback',
                    'canIExport',
                    'howDataTreated',
                    'isWayfindSafe',
                    'canIContribute',
                ]
            },
            'whatIsWayfind': {
                'title': 'Q: What is Wayfind?',
                'itemType': 'subform',
                'subform.sectionIDs': [
                    'whatIsWayfindSection',
                ]
            },
            'whatIsWayfindSection': {
                'itemType': 'section',
                'section.childIDs': [
                    'whatIsWayfindAnswer'
                ]
            },
            'whatIsWayfindAnswer': {
                'title': 'A: Wayfind is a next-generation digital decision support tool designed with leading New Zealand clinicians. Visit #@wayfind.health#@ for more information.',
                'detail': '',
                'itemType': 'control',
                'control.type': 'plain'
            },
            'whyThePilot': {
                'title': 'Q: Why is this pilot being conducted?',
                'itemType': 'subform',
                'subform.sectionIDs': [
                    'whyThePilotSection',
                ]
            },
            'whyThePilotSection': {
                'itemType': 'section',
                'section.childIDs': [
                    'whyThePilotAnswer'
                ]
            },
            'whyThePilotAnswer': {
                'title': 'A: We would like your help evaluating this product. Your feedback will be vital in improving overall usability, as well as ensuring compatibility with GP processes.',
                'detail': '',
                'itemType': 'control',
                'control.type': 'plain'
            },
            'howProvideFeedback': {
                'title': 'Q: How do I provide feedback on Wayfind?',
                'itemType': 'subform',
                'subform.sectionIDs': [
                    'howProvideFeedbackSection',
                ]
            },
            'howProvideFeedbackSection': {
                'itemType': 'section',
                'section.childIDs': [
                    'howProvideFeedbackAnswer'
                ]
            },
            'howProvideFeedbackAnswer': {
                'title': 'A: We welcome all feedback on Wayfind. To provide feedback on Wayfind, please visit #@^wayfind.health/contact.html^https://wayfind.health/contact.html#@ and complete the feedback form.',
                'detail': '',
                'itemType': 'control',
                'control.type': 'plain'
            },
            'canIExport': {
                'title': 'Q: Can I export Wayfind data to HCS or ERMS?',
                'itemType': 'subform',
                'subform.sectionIDs': [
                    'canIExportSection',
                ]
            },
            'canIExportSection': {
                'itemType': 'section',
                'section.childIDs': [
                    'canIExportAnswer'
                ]
            },
            'canIExportAnswer': {
                'title': 'A: If your device is on the CDHB network you can use the Export to HCS option on the summary page to upload the document directly into your patient’s document tree. You can use the other export options to get your Wayfind data into ERMS as plain text or PDF.',
                'detail': '',
                'itemType': 'control',
                'control.type': 'plain'
            },
            'howDataTreated': {
                'title': 'Q:  How does the pilot treat patient data?',
                'itemType': 'subform',
                'subform.sectionIDs': [
                    'howDataTreatedSection',
                ]
            },
            'howDataTreatedSection': {
                'itemType': 'section',
                'section.childIDs': [
                    'howDataTreatedAnswer'
                ]
            },
            'howDataTreatedAnswer': {
                'title': 'A: You are in control of how your patient information is shared; export options are available within Wayfind. During the pilot period, patient data you have entered into Wayfind is stored exclusively within your web browser. Wayfind does not store patient data externally.',
                'detail': '',
                'itemType': 'control',
                'control.type': 'plain'
            },
            'isWayfindSafe': {
                'title': 'Q: Is Wayfind safe to use on real patients?',
                'itemType': 'subform',
                'subform.sectionIDs': [
                    'isWayfindSafeSection',
                ]
            },
            'isWayfindSafeSection': {
                'itemType': 'section',
                'section.childIDs': [
                    'isWayfindSafeAnswer'
                ]
            },
            'isWayfindSafeAnswer': {
                'title': 'A: Yes. Wayfind has been designed in collaboration with leading New Zealand clinicians. The pilot has been approved for clinical use by CDHB clinicians and management.',
                'detail': '',
                'itemType': 'control',
                'control.type': 'plain'
            },
            'canIContribute': {
                'title': 'Q: Can I contribute my own pathway or clinical process to Wayfind? ',
                'itemType': 'subform',
                'subform.sectionIDs': [
                    'canIContributeSection',
                ]
            },
            'canIContributeSection': {
                'itemType': 'section',
                'section.childIDs': [
                    'canIContributeAnswer'
                ]
            },
            'canIContributeAnswer': {
                'title': 'A: Absolutely. We welcome collaboration with clinicians of all disciplines. Contact us if you are interested in adding a clinical pathway or workflow to Wayfind.',
                'detail': '',
                'itemType': 'control',
                'control.type': 'plain'
            },
            'additionalDetailsSection':{
                'itemType': 'section',
                'section.childIDs': [
                    'additionalDetails'
                ]
            },
            'additionalDetails': {
                'title': 'Still have questions? Contact us at #@^wayfind.health/contact.html^https://wayfind.health/contact.html#@ for more information.',
                'detail': '',
                'itemType': 'control',
                'control.type': 'plain'
            },
            'demoInformationForm': {
                'title': 'Demo Information',
                'itemType': 'subform',
                'detail': 'Wayfind is a next-generation digital decision support tool designed with leading New Zealand clinicians. Flexible, smart and beautifully-elegant, Wayfind goes beyond existing solutions by transforming static clinical documents into responsive digital pathways and generating contextually-relevant insights in real-time. Wayfind empowers clinicians of all disciplines, saving valuable time and improving clinical accuracy. Visit #@wayfind.health#@ for more information.',
                'subform.sectionIDs': [
                    'demoQuestionsSection',
                    'additionalDemoInfoSection'
                ]
            },
            'demoQuestionsSection': {
                'title': '',
                'itemType': 'section',
                'section.childIDs': [
                    'whatIsWayfindDemo',
                    'canDemoBeUsedInClinics',
                    'canIProvideDemofeedback',
                    'howDoesDemoTreatData',
                    'canIContributeToDemo',
                ]
            },
            'whatIsWayfindDemo': {
                'title': 'Q: What is the Wayfind demo? ',
                'itemType': 'subform',
                'subform.sectionIDs': [
                    'whatIsWayfindDemoSection',
                ]
            },
            'whatIsWayfindDemoSection': {
                'itemType': 'section',
                'section.childIDs': [
                    'whatIsWayfindDemoAnswer'
                ]
            },
            'whatIsWayfindDemoAnswer': {
                'title': 'A: This demo is a secure environment where clinicians can test drive the clinical decision support functionality within Wayfind. This instance of Wayfind is for demonstration purposes only.',
                'detail': '',
                'itemType': 'control',
                'control.type': 'plain'
            },
            'canDemoBeUsedInClinics': {
                'title': 'Q: Can Wayfind be used in a clinical setting?  ',
                'itemType': 'subform',
                'subform.sectionIDs': [
                    'canDemoBeUsedInClinicsSection',
                ]
            },
            'canDemoBeUsedInClinicsSection': {
                'itemType': 'section',
                'section.childIDs': [
                    'canDemoBeUsedInClinicsAnswer'
                ]
            },
            'canDemoBeUsedInClinicsAnswer': {
                'title': 'A: Wayfind will be deployed to the clinical community later in 2019. While this demo version has been designed with leading New Zealand clinicians, it is not explicitly designed for clinical use.',
                'detail': '',
                'itemType': 'control',
                'control.type': 'plain'
            },
            'canIProvideDemofeedback': {
                'title': 'Q: Can I provide feedback on Wayfind?',
                'itemType': 'subform',
                'subform.sectionIDs': [
                    'canIProvideDemofeedbackSection',
                ]
            },
            'canIProvideDemofeedbackSection': {
                'itemType': 'section',
                'section.childIDs': [
                    'canIProvideDemofeedbackAnswer'
                ]
            },
            'canIProvideDemofeedbackAnswer': {
                'title': 'A: Yes, we welcome your feedback on Wayfind. To provide feedback, please visit #@^wayfind.health/contact.html^https://wayfind.health/contact.html#@ and complete the feedback form.',
                'detail': '',
                'itemType': 'control',
                'control.type': 'plain'
            },
            'howDoesDemoTreatData': {
                'title': 'Q:  How does the Wayfind demo treat patient data?',
                'itemType': 'subform',
                'subform.sectionIDs': [
                    'howDoesDemoTreatDataSection',
                ]
            },
            'howDoesDemoTreatDataSection': {
                'itemType': 'section',
                'section.childIDs': [
                    'howDoesDemoTreatDataAnswer'
                ]
            },
            'howDoesDemoTreatDataAnswer': {
                'title': 'A: When using this demo version of Wayfind, patient data is stored exclusively within your web browser. This demo version of Wayfind does not store patient data externally.',
                'detail': '',
                'itemType': 'control',
                'control.type': 'plain'
            },
            'canIContributeToDemo': {
                'title': 'Q: Can I contribute my own pathway or clinical process to Wayfind? ',
                'itemType': 'subform',
                'subform.sectionIDs': [
                    'canIContributeToDemoSection',
                ]
            },
            'canIContributeToDemoSection': {
                'itemType': 'section',
                'section.childIDs': [
                    'canIContributeToDemoAnswer'
                ]
            },
            'canIContributeToDemoAnswer': {
                'title': 'A: Absolutely. We welcome collaboration with clinicians of all disciplines. Contact us if you are interested in adding a clinical pathway or workflow to Wayfind.',
                'detail': '',
                'itemType': 'control',
                'control.type': 'plain'
            },
            'additionalDemoInfoSection':{
                'itemType': 'section',
                'section.childIDs': [
                    'additionalDemoDetails'
                ]
            },
            'additionalDemoDetails': {
                'title': 'Still have questions? Contact us at #@^wayfind.health/contact.html^https://wayfind.health/contact.html#@ for more information.',
                'detail': '',
                'itemType': 'control',
                'control.type': 'plain'
            },
            'devInformationForm': {
                'title': 'Development Environment',
                'itemType': 'subform',
                'detail': 'Used exclusively for development and internal review; this environment is able to be unstable and regularly re-deployed.',
                'subform.sectionIDs': [

                ]
            },
            'testInformationForm': {
                'title': 'Test Environment',
                'itemType': 'subform',
                'detail': 'Should maintain the latest stable development build, which Automated Testing would be performed against. This environment will typically contain a more static data set that can accomodate for identical tests to be performed against every change to ensure consistency.',
                'subform.sectionIDs': [

                ]
            },
            'stagingInformationForm': {
                'title': 'Staging Environment',
                'itemType': 'subform',
                'detail': 'A second testing environment which contains data that more closely reflects the Production environment.',
                'subform.sectionIDs': [

                ]
            }
        },
        'insightsDependancies': [
        ]
    }
}