
import { exportToECPP } from './EDChestPain-ExportToECPPActions';
import { MetadataHelper } from '../../../ExportToHCS/MetadataHelper';

export class EDChestPainExportToECPP {

    static performExport(workflow, htmlBody){
        let formData = {...workflow.rawData};

        if (formData.patientNHINumber != null && formData.patientNHINumber != undefined) {
            formData.sessionId = workflow.uuid;
            
            formData.nhi = formData.patientNHINumber;
            delete formData.patientNHINumber;

            // let token = null; // getCachedToken != null ? getCachedToken() : null;

            var user = null;

            // if (token) {
            //     let decodedToken = decode(token);
            //     if (decodedToken) {
            //         user = decodedToken;
            //     }
            // }

            let metadata = MetadataHelper.constructMetadata(workflow, null, user);
            
            var jsonBody = {
                'ecpp_data': formData,
                'metadata': metadata
            };
            
            if (htmlBody != null && htmlBody != undefined) {
                jsonBody.htmlBody = htmlBody;
            }

            exportToECPP(jsonBody);
        }
    }
}