import { FormWithRedux } from '../../FormWithRedux';
import { EDChestPainAorticDissectionCriteriaForm } from './EDChestPain-AorticDissectionCriteriaForm';
import { FormsStyles } from '../../../waferJS/FormsUI/FormsUI';
import { FormValidationError } from '../../../waferJS/Form/Validation/FormValidationError';
import { PathwayReferenceHelper } from '../../PathwayReferenceHelper';
import { EDChestPainPathwayReference } from '../EDChestPainPathway-Reference';

export class EDChestPainRedFlagsDifferentialDiagnosesForm extends FormWithRedux {

    constructor(formLoader, parent, actionUpdater, dependentValuesUpdater, calculationsUpdater) {
        super('highRiskDifferentialDiagnosisRedFlagsForm', formLoader, parent, actionUpdater, dependentValuesUpdater, calculationsUpdater);
    }

    generateSubform(identifier) {
        switch (identifier) {
            case 'aorticDissectionCriteria':
                return new EDChestPainAorticDissectionCriteriaForm(identifier, this.formLoader, this.parent, this.actionUpdater, this.dependentValuesUpdater, this.calculationsUpdater);
        }
    }

    shouldDisplayFormDetailForIdentifier(identifier) {
        super.shouldDisplayFormDetailForIdentifier(identifier);
        if(identifier == 'aorticDissectionPresentRedFlagOverrideValue'){
            let dependentValue = this.getValue('aorticDissectionPresentRedFlagOverride');
            return dependentValue == true;
        } else if( identifier == 'pulmonaryEmbolismRedFlagOverrideValue'){
            let dependentValue = this.getValue('pulmonaryEmbolismRedFlagOverride');
            return dependentValue == true;
        } else if(identifier == 'pancreatitisRedFlagOverrideValue'){
            let dependentValue = this.getValue('pancreatitisRedFlagOverride');
            return dependentValue == true;
        } else if( identifier == 'oesophagealRuptureRedFlagOverrideValue'){
            let dependentValue = this.getValue('oesophagealRuptureRedFlagOverride');
            return dependentValue == true;
        } else if( identifier == 'pneumothoraxRedFlagOverrideValue'){
            let dependentValue = this.getValue('pneumothoraxRedFlagOverride');
            return dependentValue == true;
        }
        return true;
    }

    isEnabled(identifier) {
        if (identifier != null) {
            return true;
        }
        return false;
    }

    isMandatory(identifier) {
        if(identifier == 'aorticDissectionPresentRedFlagOverrideReason'){
            return this.getValue('aorticDissectionPresentRedFlagOverride') == true;
        } else if(identifier == 'pulmonaryEmbolismRedFlagOverrideReason'){
            return this.getValue('pulmonaryEmbolismRedFlagOverride') == true;
        } else if(identifier == 'pancreatitisRedFlagOverrideReason'){
            return this.getValue('pancreatitisRedFlagOverride') == true;
        } else if (identifier == 'oesophagealRuptureRedFlagOverrideReason'){
            return this.getValue('oesophagealRuptureRedFlagOverride') == true;
        } else if(identifier == 'pneumothoraxRedFlagOverrideReason'){
            return this.getValue('pneumothoraxRedFlagOverride') == true;
        } else {
            let redFlagIdentifiers = [
                'aorticDissectionPresentRedFlag',
                'oesophagealRuptureRedFlag',
                'pulmonaryEmbolismRedFlag',
                'pancreatitisRedFlag',
                'pneumothoraxRedFlag',
            ];

            if(redFlagIdentifiers.includes(identifier)){
                return true;
            }
        }

        return false;
    }

    getValueType(identifier) {
        let overrideReasonIdentifiers = [
            'aorticDissectionPresentRedFlagOverrideReason',
            'pulmonaryEmbolismRedFlagOverrideReason',
            'pancreatitisRedFlagOverrideReason',
            'oesophagealRuptureRedFlagOverrideReason',
            'pneumothoraxRedFlagOverrideReason',
        ];

        let overrideIdentifiers = [
            'aorticDissectionPresentRedFlagOverride',
            'pulmonaryEmbolismRedFlagOverride',
            'pancreatitisRedFlagOverride',
            'pneumothoraxRedFlagOverride',
            'oesophagealRuptureRedFlagOverride'
        ];

        if(overrideReasonIdentifiers.includes(identifier)){
            return 'string';
        } else if (overrideIdentifiers.includes(identifier)){
            return 'boolean';
        }
        return null;
    }

    submissionValidation() {
        return [];
    }

    getValue(identifier) {

        let overrideIdentifiers = [
            'aorticDissectionPresentRedFlagOverride',
            'pulmonaryEmbolismRedFlagOverride',
            'pancreatitisRedFlagOverride',
            'pneumothoraxRedFlagOverride',
            'oesophagealRuptureRedFlagOverride'
        ];

        if(overrideIdentifiers.includes(identifier)){
            let value = super.getValue(identifier);
            return value == null ? false : value;
        }
        return super.getValue(identifier);
    }

    getProgress(identifier){
        if(this.getValue(identifier + 'Override') == true){
            if(this.getValue(identifier + 'OverrideReason') != null){
                return 1;
            }
            return 0.5;
        }

        return 1;
    }

    detailString() {
        let redflags = [
            'aorticDissectionPresentRedFlag',
            'pulmonaryEmbolismRedFlag',
            'pancreatitisRedFlag',
            'oesophagealRuptureRedFlag',
            'pneumothoraxRedFlag',
        ];

        let values = redflags.map((redFlag) => {
            let value = this.getValue(redFlag + 'OverrideValue');
            if(value == null){
                value = this.getValue(redFlag);
            }
                
            if(value != null){
                return value ? PathwayReferenceHelper.reference(redFlag, EDChestPainPathwayReference.data).title : null;
            }
        });

        let formProgress = this.completionProgress();
        if(values.filter(n => n).length == 0 && (formProgress.current / formProgress.total) == 1){
            return 'None';
        }else if(values.filter(n => n).length == 0){
            return this.detail;
        }

        return values.filter(n => n).join(',\n');
    }

    getBadgeValue(identifier) {
        let overrideValue = this.getValue(identifier + 'OverrideValue');
        let displayValue = overrideValue;
        if(overrideValue == null){
            displayValue = this.getValue(identifier);
        }
        
        if(displayValue != null){
            return [{
                color: displayValue == true ? FormsStyles.red : FormsStyles.black,
                value: displayValue == true ? 'YES' : 'NO',
                solid: overrideValue != null
            }];
        }
    }

    getOverrideReason(identifier){
        let isOverridden = this.getValue(identifier + 'Override');
        let overrideReason = this.getValue(identifier + 'OverrideReason');
        if(isOverridden){
            return 'Overridden' + (overrideReason == null ? '' :  ': ' + overrideReason);
        }
        return null;
    }

    supportsOverride() {
        return true;
    }

    isRedFlagActive(identifier){
        let value = this.getValue(identifier);
        return value; // override == true ? !value : value;
    }

    calculationError(identifier){
        if(identifier == 'aorticDissectionPresentRedFlag'){
            let value = this.getValue('aorticDissectionPresentRedFlag');
            if(value == null){
                return new FormValidationError(FormValidationError.FormErrorType.warning, 'From High-risk differential diagnosis results');
            }
        } else if(identifier == 'pulmonaryEmbolismRedFlag'){
            let value = this.getValue('pulmonaryEmbolismRedFlag');
            if(value == null){
                return new FormValidationError(FormValidationError.FormErrorType.warning, 'From High-risk differential diagnosis results');
            }
        } else if(identifier == 'pancreatitisRedFlag'){
            let value = this.getValue('pancreatitisRedFlag');
            if(value == null){
                return new FormValidationError(FormValidationError.FormErrorType.warning, 'From High-risk differential diagnosis results');
            }
        } else if(identifier == 'oesophagealRuptureRedFlag'){
            let value = this.getValue('oesophagealRuptureRedFlag');
            if(value == null){
                return new FormValidationError(FormValidationError.FormErrorType.warning, 'From High-risk differential diagnosis results');
            }
        } else if(identifier == 'pneumothoraxRedFlag'){
            let value = this.getValue('pneumothoraxRedFlag');
            if(value == null){
                return new FormValidationError(FormValidationError.FormErrorType.warning, 'From High-risk differential diagnosis results');
            }
        }
        return null;
    }

    completionProgress() {
        var totalFormDetails = 0;
        var validFormDetails = 0;
        
        for (let formDetailIdentifier of this.formDetailIdentifiers) {
            let formDetail = this.formDetail(formDetailIdentifier);
            if (formDetail != null && formDetail.canHaveValue) {
                let form = formDetail.form;
                let mandatory = form.isMandatory(formDetailIdentifier);

                let value = this.getValue(formDetail.identifier);

                if (mandatory) {
                    totalFormDetails += 1;

                    if (value != null) {
                        validFormDetails += 1;
                    } 
                }
            }
        }

        for (let subformIdentifier of this.subformIdentifiers) {
            let subform = this.subform(subformIdentifier);
            let subformProgress = subform.completionProgress();
            totalFormDetails += subformProgress.total;
            validFormDetails += subformProgress.current;
        }

        return { current: validFormDetails, total: totalFormDetails };
    }

}