import React from "react";
import { Box, Button, Typography } from "@mui/material";

interface GenericErrorFallbackProp {
  error?: Error;
}

const GenericErrorFallback: React.FC<GenericErrorFallbackProp> = ({
  error,
}) => {
  return (
    <Box
      sx={{
        width: "100%",
        py: { xl: 8 },
        flex: 1,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
      }}
    >
      <Box
        sx={{
          margin: 8,
          width: "100%",
          fontSize: 34,
          fontWeight: 900,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        Wayfind
      </Box>
      <Box
        sx={{
          mb: { xs: 4, xl: 5 },
          width: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box
          component="h3"
          sx={{
            mb: { xs: 3, xl: 4 },
            fontSize: { xs: 20, md: 24 },
            fontWeight: 600,
          }}
        >
          Sorry, something went wrong
        </Box>
        <Box
          sx={{
            mb: { xs: 4, xl: 5 },
            fontSize: 16,
          }}
        >
          <Typography>
            Please try refreshing the page. If the issue persists, consider
            logging out and then logging back in to resolve it.
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default GenericErrorFallback;
