import { SnackbarContent } from "notistack";
import { forwardRef } from "react";
import { red } from "@mui/material/colors";
import { Clear } from "@mui/icons-material";
import { CustomSnackbarProps, SnackbarBase } from "./SnackbarBase";

const ErrorSnackbar = forwardRef<HTMLDivElement, CustomSnackbarProps>(
  (props, ref) => {
    return (
      <SnackbarContent ref={ref} role="alert">
        <SnackbarBase
          backgroundColour={red[50]}
          iconBackgroundColour={red[100]}
          borderColour={red[300]}
          messageColour={red[700]}
          ctaColour={red[900]}
          message={props.message}
          cta={props.cta ?? null}
          icon={<Clear color="error" />}
        />
      </SnackbarContent>
    );
  }
);

ErrorSnackbar.displayName = "ErrorSnackbar";

export default ErrorSnackbar;
