import { ActionModel } from '../../Shared Custom Types/ActionModel';
import { checkAndUpdateForAction } from '../../../components/state/actions/ActionActions';
import { redux } from '../../../App';
import { saveWorkflow } from '../../../components//state/actions/WorkflowResponseActions';
import { GallbladderActionItem } from './Gallbladder-ActionItem';

export class GallbladderActions {

    update(pathwayId, workflow, oldActions, form) {
        let data = workflow.rawData;
        let requiredActions = [];

        var clinicalIndications = [];

        // Refer to mainForm or the parent of the form (mainForm)
        let mainForm = form.parent != null ? form.parent : form;

        let progress = mainForm.completionProgress(); 
        var isComplete = false;
        if(progress != null){
            isComplete = (progress.current / progress.total) == 1;
        }

        if(data['polyps'] == true &&  data['polypsSize'] >= 8 && data['polypsSessileOrSolitary'] == true && data['patientAge'] >= 45) {
            clinicalIndications.push(new GallbladderActionItem(true, true, GallbladderActionItem.TimeFrame.three, ['Solitary polyp greater than 8mm, and age over 45 years'], [GallbladderActionItem.Clinic.HBP], ['Polyp of gallbladder (197433003)']));
        }

        if(data['gallbladderMass'] == true){
            clinicalIndications.push(new GallbladderActionItem(false, true, GallbladderActionItem.TimeFrame.three, ['Possible gallbladder mass'], [GallbladderActionItem.Clinic.HBP], ['Gallbladder mass (138561000119104)']));
        }

        if(data['stonesInGallbladder'] == false && data['patientAge'] >= 45 && data['dilatedDuctOnUSS'] && data['bloodsObstructiveLFT']) {
            clinicalIndications.push(new GallbladderActionItem(false, true, GallbladderActionItem.TimeFrame.three, ['Suspicion of distal duct obstruction'], [GallbladderActionItem.Clinic.HBP],['Obstruction of bile duct (30144000)']));
        }

        if(data['polyps'] && data['primarySclerosingCholangitis'] == true){
            clinicalIndications.push(new GallbladderActionItem(false, true, GallbladderActionItem.TimeFrame.three, ['Combination of Polyps and Primary sclerosing cholangitis'], [GallbladderActionItem.Clinic.HBP], ['Polyp of gallbladder (197433003)']));
        } else if (data['primarySclerosingCholangitis'] == true) {
            clinicalIndications.push(new GallbladderActionItem(true, false, GallbladderActionItem.TimeFrame.ten, ['Primary sclerosing cholangitis'], [GallbladderActionItem.Clinic.HBP], ['Primary sclerosing cholangitis (197441003)']));
        }

        if(data['transientJaundice'] == true){
            clinicalIndications.push(new GallbladderActionItem(true, true, GallbladderActionItem.TimeFrame.three, ['Complicated biliary disease'], [GallbladderActionItem.Clinic.GI], ['Obstructive jaundice (59848001)']));
        }

        if(data['cholangitis'] == true){
            clinicalIndications.push(new GallbladderActionItem(true, true, GallbladderActionItem.TimeFrame.three, ['Complicated biliary disease'], [GallbladderActionItem.Clinic.GI], ['Ascending cholangitis (26918003)']));
        }

        if(data['pancreatitis'] == true){
            clinicalIndications.push(new GallbladderActionItem(true, true, GallbladderActionItem.TimeFrame.three, ['Complicated biliary disease'], [GallbladderActionItem.Clinic.GI], ['Gallstone pancreatitis (95563007)']));
        }

        if ((data['bloodsObstructiveLFT'] || data['commonBileDuctStone']) && data['persistingSymptomsAfterSurgery']) {
            clinicalIndications.push(new GallbladderActionItem(true, true, GallbladderActionItem.TimeFrame.three, ['Suspicion or presence of common bile duct stone'], [GallbladderActionItem.Clinic.GI], ['Common bile duct stone (307132003)']));
        }

        if (data['incidentalUSSFindings'] && data['commonBileDuctStone']) {
            clinicalIndications.push(new GallbladderActionItem(true, false, GallbladderActionItem.TimeFrame.ten, ['Presence of common bile duct stone'], [GallbladderActionItem.Clinic.GI], ['Common bile duct stone (307132003)']));
        }

        if(data['unexpectedWeightLoss'] == true || data['changeInBowelHabit'] == true || data['abdominalDistension'] == true || data['melena'] == true){
            clinicalIndications.push(new GallbladderActionItem(true, true, GallbladderActionItem.TimeFrame.three, ['High-risk cancer symptoms'], [GallbladderActionItem.Clinic.General], []));
        }

        if(data['polyps'] == true && data['polypsSize'] >= 8 && data['polypsSessileOrSolitary'] == false && data['incidentalUSSFindings'] == false) {
            clinicalIndications.push(new GallbladderActionItem(true, false, GallbladderActionItem.TimeFrame.ten, ['Symptomatic Polyps'], [GallbladderActionItem.Clinic.General], ['Polyp of gallbladder  (197433003)']));
        }

        if(data['polyps'] == true &&  data['polypsSize'] >= 8 && data['polypsSessileOrSolitary'] == true && data['patientAge'] < 45 && data['incidentalUSSFindings'] == false) {
            clinicalIndications.push(new GallbladderActionItem(true, false, GallbladderActionItem.TimeFrame.ten, ['Symptomatic Polyps'], [GallbladderActionItem.Clinic.General], ['Polyp of gallbladder (197433003)']));
        } 

        if(data['ruqPain']){
            clinicalIndications.push(new GallbladderActionItem(true, false, GallbladderActionItem.TimeFrame.ten, ['Symptomatic gallstones'], [GallbladderActionItem.Clinic.General], ['Right upper quadrant pain (301717006)']));
        }

        if(data['biliaryColic']){
            clinicalIndications.push(new GallbladderActionItem(true, false, GallbladderActionItem.TimeFrame.ten, ['Symptomatic gallstones'], [GallbladderActionItem.Clinic.General], ['Biliary colic (37389005)']));
        }

        if(data['cholecystitis']){
            clinicalIndications.push(new GallbladderActionItem(true, false, GallbladderActionItem.TimeFrame.ten, ['Symptomatic gallstones'], [GallbladderActionItem.Clinic.General], ['Cholecystitis (76581006)']));
        }

        //Incidental USS findings and Polyps and Polyps.Size < 8mm and Primary sclerosing cholangitis = No 
        //Stones in gallbladder and Only reason is Incidental USS findings
        let denyReason1 = (data['polyps'] == true &&  data['polypsSize'] < 8 && data['incidentalUSSFindings'] == true && data['primarySclerosingCholangitis'] == false);
        let denyReason2 = data['incidentalUSSFindings'] && data['stonesInGallbladder'] == true;
        var deniedAction = clinicalIndications.length == 0 && isComplete && (denyReason1 || denyReason2);
        let nonUrgentAction = new ActionModel(workflow.pathwayUuid, workflow.versionUuid, 'generalNonUrgentSurgicalClinic', null, null, 0, false, false, Date.now(), null);
        let reconsiledNonUrgentAction = checkAndUpdateForAction(pathwayId, deniedAction && isComplete, nonUrgentAction, 'generalNonUrgentSurgicalClinic', oldActions);
        if (reconsiledNonUrgentAction != null) {
            requiredActions.push(reconsiledNonUrgentAction);
        }

        if(!deniedAction && clinicalIndications.length == 0) {
            clinicalIndications.push(new GallbladderActionItem(true, false, GallbladderActionItem.TimeFrame.ten, ['Routine biliary disease'], [GallbladderActionItem.Clinic.General], ['Gallbladder disease (39621005)']));
        }

        var shouldShow = false;
        var title = 'Specialist appointment';
        var detail = null;
        if(clinicalIndications.length > 0){
            shouldShow = true;
            var actionItem = GallbladderActionItem.merge(clinicalIndications);

            if (data['patientPrivateInsurance'] == true) {
                if(actionItem.automaticAcceptance == true){
                    title = 'This request shows clinical indications for a specialist appointment.';
                } else  {
                    title = 'This request may show clinical indications for a specialist appointment.';
                }
            } else {
                if(actionItem.automaticAcceptance == true){
                    title = 'Patient will be seen in an outpatients clinic within ' + actionItem.appointmentTimeFrame + ' working days.';
                } else  {
                    title = 'A specialist will review this request. Unless further investigation is recommended, patient will be seen in an outpatients clinic within ' +  actionItem.appointmentTimeFrame + ' working days.';
                }
            }
            

            detail = actionItem.getLongDetail();
            redux.store.dispatch(saveWorkflow(this.pathwayId, {'specialistAppointment': actionItem}, workflow, false, form, 0));
        } else {
            if(oldActions != null && Array.isArray(oldActions)){
                let filteredActions = oldActions.filter((action) => { return action.identifier == 'gallbladderRequestOutcomeAction';});
                if(filteredActions.length != 0){
                    let previousAction = ActionModel.rehydrate(filteredActions[0]);
                    detail = previousAction.detail;
                }
            }
            redux.store.dispatch(saveWorkflow(this.pathwayId, {'specialistAppointment': null}, workflow, false, form, 0));
        }

        let gallbladderRequestOutcomeAction = new ActionModel(workflow.pathwayUuid, workflow.versionUuid, 'gallbladderRequestOutcomeAction', title, detail, 0, false, false, Date.now(), null);
        let reconsiledGallbladderRequestOutcomeAction = checkAndUpdateForAction(pathwayId, shouldShow && isComplete, gallbladderRequestOutcomeAction, 'gallbladderRequestOutcomeAction', oldActions);
        if (reconsiledGallbladderRequestOutcomeAction != null) {
            requiredActions.push(reconsiledGallbladderRequestOutcomeAction);
        }


        var shouldShowPriorSurgeon = false;
        var surgeonTitle = 'Consider booking to clinic of prior surgeon: ';
        if(data['persistingSymptomsAfterSurgery'] && data['cholecystectomyOrBiliarySurgeryNameOfSurgeonIfKnown'] != null){
            surgeonTitle += data['cholecystectomyOrBiliarySurgeryNameOfSurgeonIfKnown'];
            shouldShowPriorSurgeon = true;
        }

        let priorSurgeonAction = new ActionModel(workflow.pathwayUuid, workflow.versionUuid, 'considerPreviousSurgeon', surgeonTitle, null, 0, false, false, Date.now(), null);
        let reconsiledPriorSurgeonAction = checkAndUpdateForAction(pathwayId, shouldShowPriorSurgeon && isComplete, priorSurgeonAction, 'considerPreviousSurgeon', oldActions);
        if (reconsiledPriorSurgeonAction != null) {
            requiredActions.push(reconsiledPriorSurgeonAction);
        }

        return requiredActions;
    }

}