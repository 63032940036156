import { FormWithRedux } from '../../FormWithRedux';

import { ACCBISTPost24HourScaleForm } from './ACCBIST-Post24HourScaleForm'; 
import { ACCBISTCognitiveScaleForm } from './ACCBIST-CognitiveScaleForm'; 
import { ACCBISTPhysicalScaleForm } from './ACCBIST-PhysicalScaleForm'; 
import { ACCBISTVestibularocularScaleForm } from './ACCBIST-VestibularocularScaleForm'; 

export class ACCBISTSymptonScaleForm extends FormWithRedux {

    constructor(formLoader, parent, actionUpdater, dependentValuesUpdater, calculationsUpdater) {
        super('bistSymptomScale', formLoader, parent, actionUpdater, dependentValuesUpdater, calculationsUpdater);
    }

    generateSubform(identifier) {
        switch (identifier) {
            case 'physicalBistSubform': 
                return new ACCBISTPhysicalScaleForm(this.formLoader, this, this.actionUpdater, this.dependentValuesUpdater, this.calculationsUpdater);
            case 'vestibularocularBistSubform':
                return new ACCBISTVestibularocularScaleForm(this.formLoader, this, this.actionUpdater, this.dependentValuesUpdater, this.calculationsUpdater);
            case 'cognitiveBistSubform': 
                return new ACCBISTCognitiveScaleForm(this.formLoader, this, this.actionUpdater, this.dependentValuesUpdater, this.calculationsUpdater);
            case 'post24HoursBistSubform': 
                return new ACCBISTPost24HourScaleForm(this.formLoader, this, this.actionUpdater, this.dependentValuesUpdater, this.calculationsUpdater);
        }
    }

    shouldDisplayFormDetailForIdentifier(identifier) {
        super.shouldDisplayFormDetailForIdentifier(identifier);

        if (identifier == 'post24HoursBistSubform') {
            let injuryDateString = this.getValue('injuryOccuranceDateTime');  
            if (injuryDateString != undefined && injuryDateString != null) {
                let injuryDate = new Date(injuryDateString);
                let now = new Date();
                let msBetweenDates = Math.abs(injuryDate.getTime() - now.getTime());
                let hoursBetweenDates = msBetweenDates / (60 * 60 * 1000);
                return hoursBetweenDates > 24;
            }
            else {
                return false;
            }
        }
        
        return true;
    }

    isEnabled() {
        return true;
    }

    isMandatory(identifier) {
        if (identifier == 'bistDateTime') {
            return true;
        }

        return false;
    }

    getValueType(identifier) {
        if (identifier == 'bistDateTime') {
            return 'date';
        }

        return null;
    }

    submissionValidation() {
        return [];
    }

    editingValidation(identifier) {
        return [];
    }

    
}