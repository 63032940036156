import { FormWithRedux } from '../../FormWithRedux';

export class EDChestPainPathwayInformationForm extends FormWithRedux {

    constructor(formLoader) {
        super('pathwayInformationForm', formLoader);
    }

    shouldDisplayFormDetailForIdentifier() {
        return true;
    }

    isEnabled(identifier) {
        if (identifier != null) {
            return true;
        }
        return false;
    }

    isMandatory() {
        return false;
    }

    getValueType() {
        return null;
    }

    submissionValidation() {
        return [];
    }

    detailString() {
        return this.detail;
    }
}