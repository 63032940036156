import { redux } from '../../../App';
import { saveWorkflow } from '../../../components/state/actions/WorkflowResponseActions';

export class ACCMRIDependantValuesUpdater {
    
    constructor(formLoader) {
        this.formLoader = formLoader;
    }

    update(pathwayId, workflow, identifier, value, iterationIndex){
        updateDependentValues(this.formLoader, pathwayId, workflow, identifier, value, iterationIndex);
    }

    logObservedValues(pathwayId, workflow, form){
        redux.store.dispatch(saveWorkflow(pathwayId, {'lastObservedTotalRedFlags': workflow.rawData['totalRedFlags']}, workflow, false, form, 0));
    }
}

function updateDependentValues(formLoader, pathwayId, workflow, identifier, value, iterationIndex){
    var rawData = workflow.rawData;
    var changedValues = {};

    let subformDependentValues;
    let subformParentValueIdentifier;
    
    //Keeps track of pathway completeness to allow blocking of forms if other form is not complete
    if (identifier != 'hiddenShouldDisplayAssessmentFormTracker' && identifier != 'hiddenShouldDisplayOutcomeFormTracker') {
        let isRequestCriteriaRecommendationAwaiting;

        let requestCriteriaForm = formLoader.form(pathwayId, 'requestCriteria');
        let requestCriteriaProgress = requestCriteriaForm.completionProgress();
    
        if (requestCriteriaProgress != null && requestCriteriaProgress != undefined) {
            //Is a completionItem recommendation visible but child is not answered
            isRequestCriteriaRecommendationAwaiting = requestCriteriaForm.isRecommendationVisible && rawData['contactRadiologyProvider'] != true;
            let isRequestCriteriaComplete = !isRequestCriteriaRecommendationAwaiting && ((requestCriteriaProgress.current / requestCriteriaProgress.total) == 1);

            if (isRequestCriteriaComplete != rawData['hiddenShouldDisplayAssessmentFormTracker']) {
                changedValues['hiddenShouldDisplayAssessmentFormTracker'] = isRequestCriteriaComplete;
            }
        }

        let assessmentForm = formLoader.form(pathwayId, 'assessment');
        let assessmentFormProgress = assessmentForm.completionProgress();

        if (assessmentFormProgress != null && assessmentFormProgress != undefined) {
            let isAssessmentComplete = (assessmentFormProgress.current / assessmentFormProgress.total) == 1;
            //Special cases - we should still display outcome although Assessment is incomplete, if awaiting all clear from radiology, or radiology has not cleared patient
            let shouldDisplayOutcomeForm; 
            if (isRequestCriteriaRecommendationAwaiting == true || (rawData['radiologyClearedPatient'] == false && requestCriteriaForm.isRecommendationVisible == true)) {
                shouldDisplayOutcomeForm = true;
            } else {
                shouldDisplayOutcomeForm = isAssessmentComplete;
            }

            if (shouldDisplayOutcomeForm != rawData['hiddenShouldDisplayOutcomeFormTracker']) {
                changedValues['hiddenShouldDisplayOutcomeFormTracker'] = shouldDisplayOutcomeForm;
            }
        }
    }

    switch(identifier){
        /*eslint-disable */
        case 'cervicalSpineInjury':
        case 'ghjOrInstability':
        case 'lumbarSpineInjury':
        case 'postTraumaticKneePain': {
            var reasonsForRequest = [
                'cervicalSpineInjury',
                'ghjOrInstability',
                'lumbarSpineInjury',
                'postTraumaticKneePain',
            ];

            //Can only select one reason at a time

            let values = reasonsForRequest.map(reason => {
                let reasonValue = rawData[reason]
                if (value == true && reason != identifier && reasonValue == true) {
                    changedValues[reason] = undefined;
                    return undefined;
                } else {
                    return reasonValue;
                }
            });

            if (values.includes(true) && rawData['hiddenRequestReasonTracker'] != true) {
                changedValues['hiddenRequestReasonTracker'] = true;
            } else if (rawData['hiddenRequestReasonTracker'] != null) {
                changedValues['hiddenRequestReasonTracker'] = null;
            }

            break;
        }

        //Mark parent as Yes/No if subform fields entered
        case 'LSI_lancinatingNervePainRadiatingDownArmWithinNarrowConfine':
        case 'LSI_uncomfortableAtNightWithPain':
        case 'LSI_positiveStraightLegRaiseTest':
        case 'LSI_positiveSlumpTest':
        case 'LSI_positiveFemoralNerveStretchTest': {
            subformDependentValues = [rawData['LSI_lancinatingNervePainRadiatingDownArmWithinNarrowConfine'], rawData['LSI_uncomfortableAtNightWithPain'], rawData['LSI_positiveStraightLegRaiseTest'], rawData['LSI_positiveSlumpTest'], rawData['LSI_positiveFemoralNerveStretchTest']];
            subformParentValueIdentifier = 'LSI_RadicularPain';
            break;
        }
        case 'CSI_lancinatingNervePainRadiatingDownArmWithinNarrowConfine':
        case 'CSI_uncomfortableAtNightWithPain':
        case 'CSI_positiveSpurlingTest': {
            subformDependentValues = [rawData['CSI_lancinatingNervePainRadiatingDownArmWithinNarrowConfine'], rawData['CSI_uncomfortableAtNightWithPain'], rawData['CSI_positiveSpurlingTest']];
            subformParentValueIdentifier = 'CSI_FA2_RadicularPain';
            break;
        }
        case 'KP_OKR_overAge':
        case 'KP_OKR_tendernessOfPatella':
        case 'KP_OKR_tendernessOfFibula':
        case 'KP_OKR_inabilityToFlex':
        case 'KP_OKR_inabilityToBearWeight': {
            subformDependentValues = [rawData['KP_OKR_overAge'], rawData['KP_OKR_tendernessOfPatella'], rawData['KP_OKR_tendernessOfFibula'], rawData['KP_OKR_inabilityToFlex'], rawData['KP_OKR_inabilityToBearWeight']];
            subformParentValueIdentifier = 'KP_ottawaKneeRulesRecommendImaging';
            break;
        }
        case 'KP_ACL_injuryMechanism':
        case 'KP_ACL_symptoms':
        case 'KP_ACL_signs':
        case 'KP_ACL_lachmanTestPositive':
        case 'KP_ACL_anteriorDrawTestPositive':
        case 'KP_LCL_laxityOnVarusStress': {
            subformDependentValues = [rawData['KP_ACL_injuryMechanism'], rawData['KP_ACL_symptoms'], rawData['KP_ACL_signs'], rawData['KP_ACL_lachmanTestPositive'], rawData['KP_ACL_anteriorDrawTestPositive'], rawData['KP_LCL_laxityOnVarusStress']];
            subformParentValueIdentifier = 'KP_suspectAclLclRupture';
            break;
        }
        case 'KP_ML_injuryMechanism':
        case 'KP_ML_symptoms':
        case 'KP_ML_signs':
        case 'KP_ML_thessalyTestMcMurrayTest': {
            subformDependentValues = [rawData['KP_ML_injuryMechanism'], rawData['KP_ML_symptoms'], rawData['KP_ML_signs'], rawData['KP_ML_thessalyTestMcMurrayTest']];
            subformParentValueIdentifier = 'KP_suspectMeniscalLesion';
            break;
        }
        case 'KP_MCL_PCL_mclInjury':
        case 'KP_MCL_PCL_pclInjury': {
            subformDependentValues = [rawData['KP_MCL_PCL_mclInjury'], rawData['KP_MCL_PCL_pclInjury']];
            subformParentValueIdentifier = 'KP_suspectMCLPCLRupture';
            break;
        }
        /*eslint-enable */
    }

    if (subformDependentValues != null && subformDependentValues != undefined 
        && subformParentValueIdentifier != undefined && subformParentValueIdentifier != undefined ) {

        let allValuesAnswered = !subformDependentValues.includes(null) && !subformDependentValues.includes(undefined);
        let parentUnanswered = rawData[subformParentValueIdentifier] == null || rawData[subformParentValueIdentifier] == undefined;

        if (allValuesAnswered && parentUnanswered) {
            changedValues[subformParentValueIdentifier] = subformDependentValues.includes(true);
        }
    }
    
    if (Object.keys(changedValues).length > 0) {
        let mainForm = formLoader.mainForm(pathwayId);
        mainForm.save(changedValues, workflow, true, iterationIndex + 1);
    }
}