import { FormContentView } from '../../FormsUI/FormsUI Components/Containers/FormContentView';
import { FormTextField } from '../../FormsUI/FormsUI Components/Controls/FormTextField';
import { FormsStyles } from '../../FormsUI/FormsUI';

import React from 'react';
import PropTypes from 'prop-types';

export class SingleLineTextFormDetail extends React.Component {

    constructor(props) {
        super(props);

        this.textFieldUpdated = this.textFieldUpdated.bind(this);
    }

    textFieldUpdated(value) {
        this.props.formDetail.form.valueUpdated(this.props.formDetail.identifier, value);
        this.props.renderErrors();
    }

    render() {
        let subcells = this.props.renderSubcells();
        let isMandatory = this.props.formDetail.form.isMandatory(this.props.formDetail.identifier);

        let value = this.props.formDetail.editingValue == null || this.props.formDetail.editingValue == undefined ? undefined : this.props.formDetail.editingValue.toString();
        let textField = <FormTextField value={value} onChange={this.textFieldUpdated} unitText={this.props.formDetail.unitText} placeholder={isMandatory ? 'Required' : 'Optional'} color={this.props.hasError ? FormsStyles.red : null}/>;

        return (<FormContentView identifier={this.props.formDetail.identifier} isSubcell={this.props.isSubcell} titleString={this.props.formDetail.title} validation={this.props.errorLabels} isMandatory={isMandatory} detailString={this.props.formDetail.detail} rightControl={textField} subcell={subcells} subCellbackgroundColor={FormsStyles.backgroundColor} showHover={true}/>);
    }
}

SingleLineTextFormDetail.propTypes = {
    formDetail: PropTypes.object.isRequired,
    isSubcell: PropTypes.bool.isRequired,
    renderErrors: PropTypes.func.isRequired,
    renderSubcells: PropTypes.func.isRequired,
    errorLabels: PropTypes.array.isRequired,
    hasError: PropTypes.bool.isRequired
};