import { FormValidation, EditingValidation } from '../../../waferJS/Form/Validation/FormValidation';
import { FormValidationIdentifier } from '../../../waferJS/Form/Validation/FormValidationIdentifier';
import { FormValidationError } from '../../../waferJS/Form/Validation/FormValidationError';
import { QuidelOrthoTroponinResult } from '../Custom Types/QuidelOrthoTroponin';

FormValidationIdentifier.IsQuidelOrthoTroponinResult = 'IsQuidelOrthoTroponinResult';
FormValidationIdentifier.IsQuidelOrthoTroponinString = 'IsQuidelOrthoTroponinString';

let IsQuidelOrthoTroponinResult = new FormValidation(FormValidationIdentifier.IsQuidelOrthoTroponinResult, function (value) {
    if (isNaN(+parseFloat(value))) {
        return [FormValidationError.RequiredError];
    }

    let troponin = QuidelOrthoTroponinResult.newTroponinResult(value.toString());
    if (troponin == null) {
        return [new FormValidationError(FormValidationError.FormErrorType.error, 'Invalid troponin value')];
    }

    return [];
});

let IsQuidelOrthoTroponinString = new EditingValidation(FormValidationIdentifier.IsQuidelOrthoTroponinString, function (value) {
    if (value == null || value == undefined || value.toString() == null) {
        return [FormValidationError.RequiredError];
    }

    let troponin = QuidelOrthoTroponinResult.newTroponinResult(value.toString());
    if (troponin == null) {
        return [new FormValidationError(FormValidationError.FormErrorType.error, 'Invalid troponin value')];
    }

    return [];
});

export { IsQuidelOrthoTroponinResult, IsQuidelOrthoTroponinString }; 