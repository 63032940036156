import { FormContentView } from '../../FormsUI/FormsUI Components/Containers/FormContentView';
import { FormBadge } from '../../FormsUI/FormsUI Components/Controls/FormBadge';
import { FormPie } from '../../FormsUI/FormsUI Components/Controls/FormPie';
import React from 'react';
import PropTypes from 'prop-types';
import { Navigate } from 'react-router-dom';
import { FormsStyles } from '../../FormsUI/FormsUI';
import { FormButton } from '../../FormsUI/FormsUI Components/Controls/FormButton';
import { Chevron } from '../../../components/shared/Chevron';

export class FormSummaryFormDetail extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            shouldNavigate: false,
        };

        this.pushToForm = this.pushToForm.bind(this);
        this.handleClick = this.handleClick.bind(this);
    }

    componentDidMount() {
        document.addEventListener('click', this.handleClick);
    }
    componentWillUnmount() {
        document.removeEventListener('click', this.handleClick);
    }

    shouldComponentUpdate(nextProps, nextState) {
        if(nextProps.formDetail.editingValue !== this.props.formDetail.editingValue){
            return true;
        }

        if(nextProps.isSubcell !== this.props.isSubcell){
            return true;
        }

        if(nextState.shouldNavigate !== this.state.shouldNavigate){
            return true;
        }

        return false;
    }

    pushToForm() {
        this.setState({ shouldNavigate: true });
        // window.gtag('event', 'navigated-to-subform', {
        //     event_category: 'action',
        //     event_label: 'navigated-to-subform'
        // });
    }

    handleClick(e) {
        if (this.node != null && this.node != undefined && this.node.contains(e.target)) {
            this.pushToForm();
        }
    }

    render() {
        this.props.renderErrors();
        let badgeStyle = {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
            alignItems: 'center',
            alignContent: 'stretch',
        };

        let subform = this.props.formDetail.form.subform(this.props.formDetail.identifier);
        let detailValues = subform.detailString();
        if (this.state.shouldNavigate) {
            return (<Navigate to={'/' + this.props.formDetail.form.pathwayId + '/' + this.props.formDetail.identifier} />);
        }

        let badges;
        let badgeDetails = this.props.formDetail.form.getBadgeValue(this.props.formDetail.identifier);
        if (badgeDetails != null) {
            badges =
                <div style={badgeStyle}>
                    {
                        badgeDetails.map((badge) => {
                            return <FormBadge key={badge.value + badge.color} value={badge.value} color={badge.color} solid={badge.solid} />;
                        })
                    }
                </div>;
        }

        let progress = subform.completionProgress();

        let subformHeaderStyle = {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            alignContent: 'stretch'
        };

        let subformHeaderButton = 
        <div style={subformHeaderStyle}>
            {progress.current > 0 && !this.state.isOpen && <FormPie totalValue={progress.total} filledValue={progress.current} />}
            <FormButton textAlignment={'left'} image={<Chevron angle={'rotate(-90deg)'} />} activeBackgroundColor={'transparent'} backgroundColor={'transparent'} enabled={true} isSelected={false} title={this.props.formDetail.title} />
        </div>;

        let formSummaryStyle = {
            paddingLeft: '0.6em',
            paddingRight: '0.6em',
            paddingTop: '0.6em',
            paddingBottom: '0.6em',
            cursor: 'pointer'
        };

        return (
            <div style={formSummaryStyle}>
                <FormContentView identifier={this.props.formDetail.identifier} isSubcell={this.props.isSubcell} titleString={null} titleColor={FormsStyles.primaryTint} detailString={detailValues} subcell={[]} topLeftControl={subformHeaderButton} validation={this.props.errorLabels} topRightControl={badges} backgroundColor={FormsStyles.backgroundColor} pointerRef={node => this.node = node} showHover={false} />
            </div>
        );
    }
}

FormSummaryFormDetail.propTypes = {
    formDetail: PropTypes.object.isRequired,
    isSubcell: PropTypes.bool.isRequired,
    renderErrors: PropTypes.func.isRequired,
    renderSubcells: PropTypes.func.isRequired,
    errorLabels: PropTypes.array.isRequired,
    hasError: PropTypes.bool.isRequired
};

