import { FormWithRedux } from '../../FormWithRedux';
import { DateOfBirth } from '../../../waferJS/Form To FormsUI/Subclasses/Validation Overrides/DateTimeValidation';

export class GPChestPainPatientDetailsForm extends FormWithRedux {

    constructor(formLoader, parent, actionUpdater, dependentValuesUpdater, calculationsUpdater) {
        super('patientDetails', formLoader, parent, actionUpdater, dependentValuesUpdater, calculationsUpdater);
    }

    shouldDisplayFormDetailForIdentifier(identifier) {
        super.shouldDisplayFormDetailForIdentifier(identifier);
        if(identifier == 'patientDateOfBirth'){
            return this.getValue(identifier) != null || this.getValue('patientAge') == null;
        } else if(identifier == 'patientSexIssue') {
            return this.getValue('patientSex') == 'genderOtherOption';
        }
        return true;
    }

    isEnabled() {
        return true;
    }

    isMandatory(identifier) {
        if (identifier == 'patientDateOfBirth' || identifier == 'patientSexIssue') {
            return false;
        }
        return true;
    }

    getValueType(identifier) {
        if (identifier == 'patientDateOfBirth') {
            return 'date';
        } else if (identifier == 'patientSex') {
            return 'string';
        } else if(identifier == 'patientAge'){
            return 'double';
        }
        return null;
    }

    submissionValidation() {
        return [];
    }

    editingValidation(identifier) {
        if (identifier == 'patientDateOfBirth') {
            return [DateOfBirth];
        }
        return [];
    }

    detailString() {
        let formDetails = this.getFormDetails();
        let values = formDetails.map((value) => {
            if (value.identifier == 'patientAge' && value.valueDescription() != null){
                if (value.currentValue != null) {
                    return 'Age: ' + this.getValue('patientAge') + ' year' + (this.getValue('patientAge') == 1 ? '' : 's');
                }
            }
            return value.valueDescription();
        });

        if(values.filter(n => n).length == 0){
            return this.detail;
        }
        
        return values.filter(n => n).join(',\n');
    }
}