import { FormValidationIdentifier } from './FormValidationIdentifier';
import { FormValidationError } from './FormValidationError';

export class FormValidation {

    constructor(identifier, validationBlock) {
        this.validationBlock = validationBlock;
        this.identifier = identifier;
    }

    static notNill = new FormValidation(FormValidationIdentifier.NotNull, function (value) {
        if (value == null) {
            return [FormValidationError.RequiredError];
        }

        if (typeof value === 'string' && value == '') {
            return [FormValidationError.RequiredError];
        }

        return [];
    });
}

export class EditingValidation extends FormValidation {
    constructor(identifier, validationBlock) {
        super(identifier, validationBlock);
    }
}