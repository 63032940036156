import { FormContentView } from '../../FormsUI/FormsUI Components/Containers/FormContentView';
import { FormsStyles } from '../../FormsUI/FormsUI';

import moment from 'moment';
import React from 'react';
import PropTypes from 'prop-types';
import { FormMaskedTextField } from '../../FormsUI/FormsUI Components/Controls/FormMaskedTextField';

export class DateOfBirthFormDetail extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            inputValue: null,
            age: ''
        };

        this.handleSelectedDate = this.handleSelectedDate.bind(this);
        this.textFieldBlur = this.textFieldBlur.bind(this);
        this.updateAge = this.updateAge.bind(this);
    }

    componentDidMount(){
        let value = this.props.formDetail.editingValue == null || this.props.formDetail.editingValue == undefined ? null : this.props.formDetail.editingValue.toString();
        let formattedString = value == null ? null : moment(value).format('DD/MM/YYYY');
        this.setState({inputValue: formattedString}, this.updateAge);
    }

    handleSelectedDate(value) {
        this.setState({inputValue: value});

        if(value != null && value.indexOf('M') == -1 && value.indexOf('D') == -1 && value.indexOf('Y') == -1){
            let dateValue = moment(value, 'DD/MM/YYYY');
            this.updateAge();
            this.props.formDetail.form.valueUpdated(this.props.formDetail.identifier, dateValue.toDate() != 'Invalid Date' ? dateValue.toDate() : null);
        } else {
            this.props.formDetail.form.valueUpdated(this.props.formDetail.identifier,  null);
        }

        this.props.renderErrors();
    }

    textFieldBlur(){
        this.updateAge();
        this.props.renderErrors();
    }

    updateAge(){
        if(this.state.inputValue) {
            let momentDate = moment(this.state.inputValue, 'DD/MM/YYYY');
            let years = moment().diff(momentDate, 'years');
            if(!isNaN(+years) && years >= 0){
                let yearsString = '(' + years + 'yr)';
                if(yearsString != this.state.age){
                    this.setState({age: yearsString});
                }
            } else if (this.state.age != ''){
                this.setState({age: ''});
            }
        } else {
            this.setState({age: ''});
        }
    }

    render() {
        let subcells = this.props.renderSubcells();
        let isMandatory = this.props.formDetail.form.isMandatory(this.props.formDetail.identifier);
        let value = this.props.formDetail.editingValue ? moment(this.props.formDetail.editingValue).format('DD/MM/YYYY') : null;
        
        let dateElement = <FormMaskedTextField onKeyPress={this.onKeyPress} onChange={this.handleSelectedDate} value={value} onFocus={this.textFieldFocus} onBlur={this.textFieldBlur} placeholder={'DD/MM/YYYY'} format={'##/##/####'} mask={['D', 'D', 'M', 'M', 'Y', 'Y', 'Y', 'Y']} color={this.props.hasError ? FormsStyles.red : null}/>;

        return (<FormContentView identifier={this.props.formDetail.identifier} isSubcell={this.props.isSubcell} titleString={this.props.formDetail.title} isMandatory={isMandatory} detailString={this.props.formDetail.detail} validation={this.props.errorLabels} rightControl={dateElement} subcell={subcells} subCellbackgroundColor={FormsStyles.backgroundColor} showHover={true}/>);
    }
}

DateOfBirthFormDetail.propTypes = {
    formDetail: PropTypes.object.isRequired,
    isSubcell: PropTypes.bool.isRequired,
    renderErrors: PropTypes.func.isRequired,
    renderSubcells: PropTypes.func.isRequired,
    errorLabels: PropTypes.array.isRequired,
    hasError: PropTypes.bool.isRequired
};