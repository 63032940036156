import { FUNCTIONS_API_ROOT } from '../../../config/config';

export const LOGIN_USER_PENDING = 'LOGIN_USER_PENDING';
export const LOGIN_USER_FULFILLED = 'LOGIN_USER_FULFILLED';
export const LOGIN_USER_REJECTED = 'LOGIN_USER_REJECTED';

export const LOGOUT_USER_PENDING = 'LOGOUT_USER_PENDING';
export const LOGOUT_USER_FULFILLED = 'LOGOUT_USER_FULFILLED';
export const LOGOUT_USER_REJECTED = 'LOGOUT_USER_REJECTED';

export const CHECK_SSO_PENDING = 'CHECK_SSO_PENDING';
export const CHECK_SSO_FULFILLED = 'CHECK_SSO_FULFILLED';
export const CHECK_SSO_REJECTED = 'CHECK_SSO_REJECTED';

export const RESET_LOADING = 'RESET_LOADING';

const loginAction = (email, password) => (dispatch) => {
    if (email || password || dispatch) {
        //This is only here so that I don't have to remove token and dispatch due to linter. Does nothing at all.
    }
    
    //Temporary to simulate successful login
    dispatch({
        type: LOGIN_USER_PENDING
    });
    
    setTimeout(() => {
        dispatch({
            type: LOGIN_USER_FULFILLED,
            payload: {}
        });
    }, 3000);
};

const loginWithToken = (decodedToken) => (dispatch) => {
    dispatch({
        type: LOGIN_USER_FULFILLED,
        payload: {
            firstName: decodedToken.name ? decodedToken.name : '',
            givenName: decodedToken.given_name ? decodedToken.given_name : '',
            familyName: decodedToken.family_name ? decodedToken.family_name : '',
        }
    });
};

const logoutAction = () => (dispatch) => {
    dispatch({
        type: LOGOUT_USER_PENDING,
        payload: {
            redirectPathway: window.location.pathname,
        },
    });

    // if (authContext) {
    //     authContext.logOut();
    // }

    dispatch({
        type: LOGOUT_USER_FULFILLED,
    });
};

const beginLoginWithSSO = () => (dispatch) => {
    dispatch({
        type: LOGIN_USER_PENDING,
        payload: {
            redirectPathway: window.location.pathname,
        },
    });
    
    // if (authContext) {
    //     authContext.login();
    // }
};

const authMethod = (email) => (dispatch) => { 
    dispatch({
        type: CHECK_SSO_PENDING
    });

    let body = {
        'email': email
    };

    if (FUNCTIONS_API_ROOT != null && FUNCTIONS_API_ROOT != undefined) {
        postData(`${FUNCTIONS_API_ROOT}/auth/verify`, body).then((response) => {
            let authenticationType = response['authMethod'] ? response['authMethod'] : null;

            if (authenticationType != null) {
                if (['AzureAD'].includes(authenticationType)) {
                    dispatch({
                        type: CHECK_SSO_FULFILLED,
                        payload: authenticationType
                    });
                } else {
                    console.log('authMethod error: Unknown authentication type');
                    dispatch({
                        type: CHECK_SSO_REJECTED,
                        payload: 'Unknown authentication type'
                    });
                }
            } else {
                let error = response['error'] ? response['error'] : 'Server could not handle request';
                console.log('authMethod error: ', error);
                dispatch({
                    type: CHECK_SSO_REJECTED,
                    payload: error
                });
            }
        }).catch((error) => {
            console.log('authMethod error:', error);
            dispatch({
                type: CHECK_SSO_REJECTED,
                payload: error
            });
        });
    }
};

const resetLoadingState = () => (dispatch) => {
    dispatch({
        type: RESET_LOADING,
    });
};

function postData(url = '', data = {}) {
    return fetch(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(data),
    }).then(response => response.json());
}

export {loginAction as login, logoutAction as logout, beginLoginWithSSO, loginWithToken, resetLoadingState, authMethod};