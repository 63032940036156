import { FormWithRedux } from '../../FormWithRedux';
import moment from 'moment';
import { FormValidation } from '../../../waferJS/Form/Validation/FormValidation';
import { FormValidationError } from '../../../waferJS/Form/Validation/FormValidationError';
import { FormsStyles } from '../../../waferJS/FormsUI/FormsUI';

export class OsteoporosisRequestCriteriaForm extends FormWithRedux {

    constructor(formLoader, parent, actionUpdater, dependentValuesUpdater) {
        super('requestCriteria', formLoader, parent, actionUpdater, dependentValuesUpdater);
    }

    shouldDisplayFormDetailForIdentifier(identifier) {
        super.shouldDisplayFormDetailForIdentifier(identifier);
        if(identifier == 'hasPreviousDXAResults'){
            return this.getValue('isThisFollowUpRequest') == 'followupOption' || this.getValue('hasPreviousDXAResults') != null || this.getValue('previousDXADate') != null || this.getValue('previousDXATScore') != null;
        } else if(identifier == 'previousDXADate' || identifier == 'previousDXATScore'){
            return this.getValue('hasPreviousDXAResults') == true || this.getValue('previousDXADate') != null || this.getValue('previousDXATScore') != null;
        } else if(identifier == 'tScoreBoneDensityBadge'){
            return this.getValue('previousDXATScore') != null;
        } else if(identifier == 'isPatientOnOsteoporosisTreatment' || identifier == 'osteoporosisTreatmentDetails'){
            return (this.getValue('previousDXATScore') != null && this.getValue('previousDXATScore') <= -2.5) || this.getValue(identifier) != null || this.getValue('consideringTreatmentBreak') != null || this.getValue('isPatientOnTreatmentBreak') != null || this.getValue('onOralOrIVTreatment') != null;
        } else if(identifier == 'onOralOrIVTreatment' || identifier == 'consideringTreatmentBreak'){
            return this.getValue('isPatientOnOsteoporosisTreatment') == true || this.getValue('consideringTreatmentBreak') != null || this.getValue('onOralOrIVTreatment') != null;
        } else if(identifier == 'isPatientOnTreatmentBreak'){
            return this.getValue('isPatientOnOsteoporosisTreatment') == false || this.getValue(identifier) != null;
        } else if(identifier == 'fraxAssessmentWebsite' || identifier == 'hipFractureRisk'){
            var highRiskFragility = (this.getValue('isThisFollowUpRequest') == 'followupOption' && this.getValue('fragilityFractureSincePreviousScan') == true) || (this.getValue('isThisFollowUpRequest') == 'firstOption' && this.getValue('hasPatientHadFragilityFracture') == true);
            var highRiskPresent = highRiskFragility || this.getValue('highRiskGlucocorticoidUse') || (this.getValue('multipleFalls') && this.getValue('patientAge') >= 70);
            var conditions = this.getValue('isThisFollowUpRequest') != 'followupOption' || this.getValue('hasPreviousDXAResults') != true || (this.getValue('previousDXATScore') == null || this.getValue('previousDXATScore') >= -1.5);
            return (conditions || this.getValue('hipFractureRisk') != null) && !highRiskPresent;
        } else if (identifier == 'over70Hint'){
            return this.getValue('patientAge') >= 70;
        } else if(identifier == 'longBoneHipFractureDetails'){
            return this.getValue('hasPatientHadFragilityFracture') == true || this.getValue(identifier) != null || this.getValue('longBoneHipFractureDetails') != null || this.getValue('fragilityFractureSincePreviousScan') != null;
        } else if(identifier == 'fragilityFractureSincePreviousScan'){
            return (this.getValue('hasPatientHadFragilityFracture') == true && this.getValue('isThisFollowUpRequest') == 'followupOption') || this.getValue(identifier) != null;
        }
        return true;
    }

    isEnabled(identifier) {
        if (identifier != null) {
            return true;
        }
        return false;
    }

    isMandatory(identifier) {
        if(identifier == 'isThisFollowUpRequest' || identifier == 'hasPatientHadFragilityFracture' || identifier == 'highRiskGlucocorticoidUse' || identifier == 'multipleFalls'){
            return true;
        } else if(identifier == 'hasPreviousDXAResults'){
            return this.getValue('isThisFollowUpRequest') == 'followupOption';
        } else if(identifier == 'previousDXADate' || identifier == 'previousDXATScore'){
            return this.getValue('hasPreviousDXAResults') == true;
        } else if(identifier == 'isPatientOnOsteoporosisTreatment'){
            return (this.getValue('previousDXATScore') != null && this.getValue('previousDXATScore') <= -2.5);
        } else if(identifier == 'onOralOrIVTreatment' || identifier == 'consideringTreatmentBreak'){
            return this.getValue('isPatientOnOsteoporosisTreatment') == true;
        } else if(identifier == 'isPatientOnTreatmentBreak'){
            return this.getValue('isPatientOnOsteoporosisTreatment') == false;
        } else if(identifier == 'hipFractureRisk'){
            var highRiskFragility = (this.getValue('isThisFollowUpRequest') == 'followupOption' && this.getValue('fragilityFractureSincePreviousScan') == true) || (this.getValue('isThisFollowUpRequest') == 'firstOption' && this.getValue('hasPatientHadFragilityFracture') == true);
            var highRiskPresent = highRiskFragility || this.getValue('highRiskGlucocorticoidUse') || (this.getValue('multipleFalls') && this.getValue('patientAge') >= 70);
            var conditions = this.getValue('isThisFollowUpRequest') != 'followupOption' || this.getValue('hasPreviousDXAResults') != true || (this.getValue('previousDXATScore') == null || this.getValue('previousDXATScore') >= -1.5);
            return (conditions && !highRiskPresent);
        } else if(identifier == 'longBoneHipFractureDetails'){
            return this.getValue('hasPatientHadFragilityFracture') == true;
        } else if(identifier == 'fragilityFractureSincePreviousScan'){
            return (this.getValue('hasPatientHadFragilityFracture') == true && this.getValue('isThisFollowUpRequest') == 'followupOption');
        } 
        return false;
    }

    getValueType(identifier) {
        if(identifier == 'hasPreviousDXAResults' || identifier == 'isPatientOnOsteoporosisTreatment' || identifier == 'consideringTreatmentBreak' || identifier == 'isPatientOnTreatmentBreak' || identifier == 'hasPatientHadFragilityFracture' 
        || identifier == 'fragilityFractureSincePreviousScan' || identifier == 'highRiskGlucocorticoidUse' || identifier == 'multipleFalls'){
            return 'boolean';
        } else if(identifier == 'previousDXADate'){
            return 'date';
        } else if(identifier == 'previousDXATScore' || identifier == 'hipFractureRisk'){
            return 'double';
        } else if(identifier == 'isThisFollowUpRequest' || identifier == 'onOralOrIVTreatment' || identifier == 'longBoneHipFractureDetails' || identifier == 'osteoporosisTreatmentDetails'){
            return 'string';
        }
        return null;
    }

    submissionValidation(identifier) {
        if(identifier == 'hasPreviousDXAResults'){
            let self = this;
            let criteriaConflict = new FormValidation('PreviousDXAScanConflict', function () {
                let value = self.getValue('hasPreviousDXAResults');
                if(value != null && self.getValue('isThisFollowUpRequest') != 'followupOption'){
                    return [new FormValidationError(FormValidationError.FormErrorType.error, 'Response conflicts with reason for request')];
                }
                return [];
            });
        
            return [criteriaConflict];
        } else if(identifier == 'previousDXADate' || identifier == 'previousDXATScore'){
            let self = this;
            let criteriaConflict = new FormValidation('DXADetailsConflict', function () {
                let value = self.getValue(identifier);
                if(value != null && self.getValue('hasPreviousDXAResults') == false){
                    return [new FormValidationError(FormValidationError.FormErrorType.error, 'Response conflicts with selection')];
                }
                return [];
            });
        
            return [criteriaConflict];
        } else if(identifier == 'onOralOrIVTreatment' || identifier == 'consideringTreatmentBreak'){
            let self = this;
            let criteriaConflict = new FormValidation('OsteoTreatmentConflict', function () {
                let value = self.getValue(identifier);
                if(value != null && self.getValue('isPatientOnOsteoporosisTreatment') == false){
                    return [new FormValidationError(FormValidationError.FormErrorType.error, 'Response conflicts with selection')];
                }
                return [];
            });
        
            return [criteriaConflict];
        } else if(identifier == 'isPatientOnTreatmentBreak'){
            let self = this;
            let criteriaConflict = new FormValidation('PatientTreatmentBreakConflict', function () {
                let value = self.getValue(identifier);
                if(value != null && self.getValue('isPatientOnOsteoporosisTreatment') == true){
                    return [new FormValidationError(FormValidationError.FormErrorType.error, 'Response conflicts with selection')];
                }
                return [];
            });
        
            return [criteriaConflict];
        } else if(identifier == 'hipFractureRisk'){
            let self = this;
            let criteriaConflict = new FormValidation('FRAXConflict', function () {
                let value = self.getValue(identifier);
                
                if(value != null && (self.getValue('isThisFollowUpRequest') == 'followupOption' && self.getValue('hasPreviousDXAResults') == true && (self.getValue('previousDXATScore') != null && self.getValue('previousDXATScore') < -1.5 ))){
                    return [new FormValidationError(FormValidationError.FormErrorType.error, 'Response conflicts with selection')];
                }
                return [];
            });
        
            return [criteriaConflict];
        } else if(identifier == 'longBoneHipFractureDetails'){
            let self = this;
            let criteriaConflict = new FormValidation('HighRiskCriteriaConflict', function () {
                let value = self.getValue(identifier);
                if(value != null && self.getValue('hasPatientHadFragilityFracture') == false){
                    return [new FormValidationError(FormValidationError.FormErrorType.error, 'Response conflicts with selection')];
                }
                return [];
            });
        
            return [criteriaConflict];
        } else if(identifier == 'fragilityFractureSincePreviousScan'){
            let self = this;
            let criteriaConflict = new FormValidation('FollowUpFragilityConflict', function () {
                let value = self.getValue(identifier);
                if(value != null && self.getValue('hasPatientHadFragilityFracture') == false || (self.getValue('isThisFollowUpRequest') != 'followupOption')){
                    return [new FormValidationError(FormValidationError.FormErrorType.error, 'Response conflicts with selection')];
                }
                return [];
            });
        
            return [criteriaConflict];
        }
    }

    editingValidation(){
        return [];
    }

    getBadgeValue(identifier){
        if(identifier == 'tScoreBoneDensityBadge'){
            if(this.getValue('previousDXATScore') != null){
                let tScore = this.getValue('previousDXATScore');
                if (tScore <= -2.5) {
                    return [{
                        color: FormsStyles.red,
                        value: 'OSTEOPOROSIS',
                        solid: false
                    }];
                } else if (tScore > -2.5 && tScore <= -1.0){
                    return [{
                        color: FormsStyles.orange,
                        value: 'OSTEOPENIA',
                        solid: false
                    }];
                } else if (tScore > -1.0) {
                    return [{
                        color: FormsStyles.black,
                        value: 'NORMAL',
                        solid: false
                    }];
                }
            }
        }
        return [];
            
    }

    detailString(isSubcell, isPlainText) {
        if(isPlainText){
            return this.getPlainText();
        }
        let formDetails = this.getFormDetails();
        let values = formDetails.map((value) => {
            return this.getSummaryValue(isSubcell, value);
        });

        if(values.filter(n => n).length == 0){
            return isPlainText ? '' : this.detail;
        }
        
        return values.filter(n => n).join(isPlainText ? ', ' : ',\n');
    }

    getSummaryValue(isSubcell, value){
        if(value.identifier == 'previousDXATScore' || value.identifier == 'previousDXADate' 
        || value.identifier =='fragilityFractureSincePreviousScan' || value.identifier == 'isPatientOnTreatmentBreak' || value.identifier == 'onOralOrIVTreatment' 
        || value.identifier == 'consideringTreatmentBreak' || value.identifier =='isPatientOnTreatmentBreak'){
            return null;
        } else if(value.identifier == 'isThisFollowUpRequest' && value.currentValue != null){
            return value.valueDescription() + ' DXA';
        } else if((value.identifier == 'hipFractureRisk') && value.currentValue != null){
            return value.title + ': ' + value.valueDescription() + '%';
        } else if(value.identifier == 'hasPatientHadFragilityFracture' && value.currentValue == true){
            var additionalDetails = [];
            if(this.getValue('longBoneHipFractureDetails') != null){
                additionalDetails.push('Detail: ' + this.getValue('longBoneHipFractureDetails'));
            }
            if(this.getValue('fragilityFractureSincePreviousScan') == true){
                additionalDetails.push('New fragility fracture occurred since previous DXA scan');
            }
            return 'Patient had a fragility fracture ' + (additionalDetails.length > 0 ? '(' + additionalDetails.join(', ') + ')'  : '');
        } else if(value.identifier == 'longBoneHipFractureDetails'){
            return null;
        } else if(value.identifier == 'osteoporosisTreatmentDetails' && value.currentValue != null){
            return value.title + ': ' + value.valueDescription();
        } else if(value.identifier == 'hasPreviousDXAResults' && value.currentValue != null){
            let dxaDetails = [];

            if(this.getValue('previousDXADate') != null){
                dxaDetails.push('Date: ' + moment(this.getValue('previousDXADate')).format('MM/YYYY'));
            }

            if(this.getValue('previousDXATScore') != null){
                dxaDetails.push('T-score: ' + this.getValue('previousDXATScore'));
            }

            return 'Has previous DXA results' + (dxaDetails.length > 0 ? ' (' + dxaDetails.join(', ') + ')' : '');
        } else if(value.identifier == 'isPatientOnOsteoporosisTreatment' && value.currentValue != null){
            if(value.currentValue == true){
                let treatmentDetails = [];

                if(this.getValue('onOralOrIVTreatment') != null){
                    let formDetail = this.formDetail('onOralOrIVTreatment');
                    var result = formDetail.options.filter(obj => {
                        return obj.identifier === formDetail.editingValue;
                    })[0];
                    if(result != null){
                        treatmentDetails.push(result.title);
                    }
                }

                if(this.getValue('consideringTreatmentBreak') == true){
                    treatmentDetails.push('Considering treatment break');
                }

                return 'Patient is on osteoporosis treatment' + (treatmentDetails.length > 0 ? ' (' + treatmentDetails.join(', ') + ')' : '');
            } else {
                let additonalDetail = this.getValue('isPatientOnTreatmentBreak') == true ? ' (Patient is on treatment break)': '';
                return 'Patient is not on osteoporosis treatment' + additonalDetail;
            }
        }

        return value.valueDescription();
    }
}