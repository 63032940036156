import { FormWithRedux } from '../../FormWithRedux';
import { redux } from '../../../App';
import { saveAction } from '../../../components/state/actions/ActionActions';
import { FormsStyles } from '../../../waferJS/FormsUI/FormsUI';
import { PathwayFormatter } from '../../PathwayFormatter';
import { FormValidation } from '../../../waferJS/Form/Validation/FormValidation';
import { FormValidationError } from '../../../waferJS/Form/Validation/FormValidationError';
import { markWorkFlowAsExported } from '../../../components/state/actions/WorkflowResponseActions';

export class GallbladderResultsForm extends FormWithRedux {

    set actions(value) {
        this._actions = JSON.parse(JSON.stringify(value));
    }

    get actions() {
        return this._actions;
    }

    constructor(formLoader, parent, actionsUpdater, dependenValuesUpdater, calculationsUpdater) {
        super('resultsForm', formLoader, parent, actionsUpdater, dependenValuesUpdater, calculationsUpdater);

        let self = this;
        redux.store.subscribe(() => {
            self.actions = redux.store.getState().actionsReducer.actions;
        });

        this.actions = redux.store.getState().actionsReducer.actions;
    }

    shouldDisplayFormDetailForIdentifier(identifier) {
        super.shouldDisplayFormDetailForIdentifier(identifier);
        let formDetail = this.formDetail(identifier);

        if(formDetail.controlType == 'action'){
            if(this.actions[this.pathwayId] != null){
                let pathwayActions = this.actions[this.pathwayId];
                if(pathwayActions == null || JSON.stringify(pathwayActions) == JSON.stringify({})){
                    return null;
                }

                return pathwayActions.filter((action) => {return action.identifier == identifier;}).length > 0;
            }
            return false;
        } else if (identifier == 'acceptanceOutsideCriteriaReason'){
            return this.getValue('acceptanceOutsideUsualCriteria') || this.getValue('acceptanceOutsideCriteriaReason') != null;
        } else if(identifier == 'seeComunityHealthPathways'){
            return this.getValue('specialistAppointment') == null && this.getValue('incidentalUSSFindings') == true;
        } else if(identifier == 'privateFundingIndicatedDetail'){
            return this.getValue('patientPrivateInsurance');
        } else if(identifier == 'acceptanceOutsideUsualCriteria'){
            return this.getValue('specialistAppointment') == null && !this.getValue('patientPrivateInsurance') && this.isComplete();
        } else if(identifier == 'exportRequestOutcome' || identifier == 'additionalInformation'){
            return this.isComplete();
        } else if(identifier == 'moreInfoRequiredDetail'){
            return !this.isComplete();
        }
        return true;
    }

    isEnabled(identifier) {
        if (identifier != null) {
            return true;
        }
        return false;
    }

    isMandatory() {
        return false;
    }

    getValueType(identifier) {
        if(identifier == 'patientNeedsSpecialConsideration' || identifier == 'acceptanceOutsideUsualCriteria'){
            return 'boolean';
        } else if(identifier == 'acceptanceOutsideCriteriaReason' || identifier == 'additionalInformation'){
            return 'string';
        }
        return null;
    }

    isComplete(){
        let form = this.formLoader.form(this.pathwayId, 'mainForm');
        let progress = form.completionProgress();

        if(progress != null){
            return (progress.current / progress.total) == 1;
        }
        return false;
    }

    submissionValidation(identifier) {
        if(identifier == 'acceptanceOutsideCriteriaReason'){
            let self = this;
            let criteriaConflict = new FormValidation('RequestReasonConflict', function () {
                let value = self.getValue('acceptanceOutsideCriteriaReason');
                if(value != null && self.getValue('acceptanceOutsideUsualCriteria') == false){
                    return [new FormValidationError(FormValidationError.FormErrorType.error, 'Modify reasoning to match selection')];
                }
                return [];
            });
                
            return [criteriaConflict];
        }
        return [];
    }

    detailString() {
        return this.detail;
    }

    getCopyText(){
        return PathwayFormatter.formatAsPlainText(this.formLoader, this.pathwayId, this.workflow[this.pathwayId]);
    }

    markActionAsObserved(identifier){
        if(this.actions[this.pathwayId] == null ){ return; }
        let matchingActions = this.actions[this.pathwayId].filter((action) => {
            return action.identifier == identifier;
        });

        let filteredActions = this.actions[this.pathwayId].filter((action) => {
            return action.identifier != identifier;
        });

        if (matchingActions.length == 0 ) { return;}

        let action = matchingActions[0];

        if(!action.removeAfterObservation){
            action.observed = true;
            filteredActions.push(action);
        }
        
        redux.store.dispatch(saveAction(this.pathwayId, filteredActions));
    }

    actionForIdentifier(identifier){
        if(this.actions[this.pathwayId] != null ){
            let filteredAction = this.actions[this.pathwayId].filter((action) => {return action.identifier == identifier;});
            if(filteredAction.length > 0){
                return filteredAction[0];
            }
        }
        
        return null;
    }

    getBadgeValue(identifier){
        if(identifier == 'gallbladderRequestOutcomeAction'){
            var actionItem = this.getValue('specialistAppointment');
            if(actionItem != null && actionItem.urgent) {
                return[{
                    color: FormsStyles.red,
                    value: 'URGENT',
                    solid: true
                }];
            }
        }
        return [];
    }

    markAsExported(){
        redux.store.dispatch(markWorkFlowAsExported(this.pathwayId, this.workflow[this.pathwayId]));
    }

}