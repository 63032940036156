import { FormWithRedux } from '../../FormWithRedux';

import { ACCBISTSuspectedFractureForm } from './ACCBIST-SuspectedFractureForm';
import { ACCBISTAnticoagulantAntiplateletForm } from './ACCBIST-AnticoagulantAntiplateletForm';

import { FormValidationError } from '../../../waferJS/Form/Validation/FormValidationError';
import { FormValidation } from '../../../waferJS/Form/Validation/FormValidation';

export class ACCBISTClinicalAssessmentForm extends FormWithRedux {

    constructor(formLoader, parent, actionUpdater, dependentValuesUpdater, calculationsUpdater) {
        super('clinicalAssessmentSubform', formLoader, parent, actionUpdater, dependentValuesUpdater, calculationsUpdater);
    }

    generateSubform(identifier) {
        switch (identifier) {
            case 'suspectedFractureSubform':
                return new ACCBISTSuspectedFractureForm(this.formLoader, this, this.actionUpdater, this.dependentValuesUpdater, this.calculationsUpdater);
            case 'anticoagulantAntiplateletSubform':
                return new ACCBISTAnticoagulantAntiplateletForm(this.formLoader, this, this.actionUpdater, this.dependentValuesUpdater, this.calculationsUpdater);
        }
    }

    shouldDisplayFormDetailForIdentifier(identifier) {
        super.shouldDisplayFormDetailForIdentifier(identifier);

        if (identifier == 'numberOfVomittingEpisodes') {
            return this.getValue('vomiting') == true;
        }
        else if (identifier == 'numberOfPreviousConcussionsOrBrainInjuries' || identifier == 'dateOfLastInjury' || identifier == 'wasRecoveryProtractedOrProblematic') {
            return this.getValue('previousConcussionOrBrainInjury') == true; 
        }
        else if (identifier == 'focalNeurologicalDeficitDetails') {
            return this.getValue('focalNeurologicalDeficit') == true; 
        }
        else if (identifier == 'suspectedFractureSubform') {
            let dependentValues = [this.getValue('depressedSkullFracture'), this.getValue('openSkullFracture'), this.getValue('basalSkullFracture')];
            let hasAnswers = dependentValues.includes(true) || dependentValues.includes(false);
            
            return this.getValue('suspectedSkullFracture') != false || hasAnswers;
        }
        else if (identifier == 'anticoagulantAntiplateletSubform') {
            
            let dependentValues = [this.getValue('aspirin'), this.getValue('clopidogrelOrTicagrelor'), this.getValue('warfarin'), this.getValue('revaroxabanOrDabigatran'), this.getValue('otherMedication')];
            let hasAnswers = dependentValues.includes(true) || dependentValues.includes(false);
            
            return this.getValue('anticoagulantOrAntiplateletAgent') != false || hasAnswers;
        }

        return true;
    }

    isEnabled() {
        return true;
    }

    isMandatory(identifier) {
        if (identifier == 'numberOfVomittingEpisodes') {
            return this.getValue('vomiting') == true;
        }
        else if (identifier == 'additionalSymptomsAndSigns' || identifier == 'additionalHistoryDetails' || identifier == 'additionalMedications') {
            return false;
        }
        else if (identifier == 'numberOfPreviousConcussionsOrBrainInjuries' || identifier == 'dateOfLastInjury' || identifier == 'wasRecoveryProtractedOrProblematic') {
            return this.getValue('previousConcussionOrBrainInjury') == true; 
        }
        else if (identifier == 'focalNeurologicalDeficitDetails') {
            return this.getValue('focalNeurologicalDeficit') == true; 
        }
        else if (identifier == 'socialDetails') {
            return false;
        }

        return true;
    }

    getValueType(identifier) {
        if (identifier == 'dateOfLastInjury') {
            return 'double';
        }
        else if (identifier == 'feelingBetterSameWorseSinceInjury'
        || identifier == 'additionalSymptomsAndSigns'
        || identifier == 'additionalHistoryDetails'
        || identifier == 'additionalMedications'
        || identifier == 'employment'
        || identifier == 'focalNeurologicalDeficitDetails'
        || identifier == 'socialDetails'
        ) {
            return 'string';
        }
        else if (identifier == 'numberOfVomittingEpisodes'
        || identifier == 'numberOfPreviousConcussionsOrBrainInjuries') {
            return 'double';
        }
        else if (identifier == 'headache' 
        || identifier == 'vomiting'
        || identifier == 'suspectedSkullFracture'
        || identifier == 'focalNeurologicalDeficit'
        || identifier == 'depressedSkullFracture'
        || identifier == 'openSkullFracture'
        || identifier == 'basalSkullFracture'
        || identifier == 'previousConcussionOrBrainInjury'
        || identifier == 'bleedingOrClottingDisorder'
        || identifier == 'historyOfMigraine'
        || identifier == 'mentalHealthDifficulties'
        || identifier == 'previousAlchoholOrSubstanceAbuse'
        || identifier == 'cognitiveImpairment'
        || identifier == 'anticoagulantOrAntiplateletAgent'
        || identifier == 'aspirin'
        || identifier == 'clopidogrelOrTicagrelor'
        || identifier == 'warfarin'
        || identifier == 'revaroxabanOrDabigatran'
        || identifier == 'otherMedication'
        || identifier == 'headache'
        || identifier == 'headache'
        || identifier == 'wasRecoveryProtractedOrProblematic'
        || identifier == 'highRiskOccupation'
        ) {
            return 'boolean';
        }

        return null;
    }

    submissionValidation(identifier) {
        if(identifier == 'suspectedSkullFracture'){
            let self = this;
            let criteriaConflict = new FormValidation('AlterationInMentalStateConflict', function () {
                let value = self.getValue('suspectedSkullFracture');

                let allBooleanValues = [self.getValue('depressedSkullFracture'), self.getValue('openSkullFracture'), self.getValue('basalSkullFracture')];

                if (value == false) {
                    if (allBooleanValues.includes(true)) {
                        return [new FormValidationError(FormValidationError.FormErrorType.error, 'Modify to match selection')];
                    }
                } else if (value == true){
                    if (!allBooleanValues.includes(true) && !allBooleanValues.includes(null) && !allBooleanValues.includes(undefined)) {
                        return [new FormValidationError(FormValidationError.FormErrorType.error, 'Modify to match selection')];
                    }
                }
                return [];
            });
                
            return [criteriaConflict];
        }
        else if (identifier == 'anticoagulantOrAntiplateletAgent'){
            let self = this;
            let criteriaConflict = new FormValidation('AlterationInMentalStateConflict', function () {
                let value = self.getValue('anticoagulantOrAntiplateletAgent');

                let allBooleanValues = [self.getValue('aspirin'), self.getValue('clopidogrelOrTicagrelor'), self.getValue('warfarin'), self.getValue('revaroxabanOrDabigatran'), self.getValue('otherMedication')];
                
                if (value == false) {
                    if (allBooleanValues.includes(true)) {
                        return [new FormValidationError(FormValidationError.FormErrorType.error, 'Modify to match selection')];
                    }
                } else if (value == true){
                    if (!allBooleanValues.includes(true) && !allBooleanValues.includes(null) && !allBooleanValues.includes(undefined)) {
                        return [new FormValidationError(FormValidationError.FormErrorType.error, 'Modify to match selection')];
                    }
                }
                return [];
            });
                
            return [criteriaConflict];
        }
        else if (identifier == 'dateOfLastInjury') {
            let self = this;
            let criteriaConflict = new FormValidation('InvalidYearCheck', function () {
                let value = self.getValue('dateOfLastInjury');

                if (value != null && value != undefined) {
                    let currentYear = new Date().getFullYear();
            
                    if (value > currentYear || value < 1900) {
                        return [new FormValidationError(FormValidationError.FormErrorType.error, 'Please enter a valid year')];
                    }
                } 
                return [];
            });
                
            return [criteriaConflict];
        }
    }

    editingValidation(identifier) {
        return [];
    }
}