
import moment from 'moment';

export class GallbladderDependantValuesUpdater {

    constructor(formLoader) {
        this.formLoader = formLoader;
    }

    update(pathwayId, workflow, identifier, value, iterationIndex) {
        updateDependentValues(this.formLoader, pathwayId, workflow, identifier, value, iterationIndex);
    }

    logObservedValues(){
        
    }
}

function updateDependentValues(formLoader, pathwayId, workflow, identifier, value, iterationIndex) {
    var rawData = workflow.rawData;
    var changedValues = {};
    
    switch (identifier) {
        case 'transientJaundice':
        case 'cholangitis':
        case 'pancreatitis':{
            if (rawData['transientJaundice'] == true || rawData['cholangitis'] == true || rawData['pancreatitis'] == true) {
                changedValues['complicatedBiliaryDisease'] = true;
            } else if(rawData['transientJaundice'] == false && rawData['cholangitis'] == false && rawData['pancreatitis'] == false){
                changedValues['complicatedBiliaryDisease'] = false;
            } else {
                changedValues['complicatedBiliaryDisease'] = null;
            }
        }
        /*eslint-disable */
        case 'ruqPain':
        case 'biliaryColic':
        case 'cholecystitis':
        case 'persistingSymptomsAfterSurgery':
        case 'incidentalUSSFindings': {
            var reasonsForRequest = [
                'transientJaundice',
                'cholangitis',
                'pancreatitis',
                'ruqPain',
                'biliaryColic',
                'cholecystitis',
                'persistingSymptomsAfterSurgery',
                'incidentalUSSFindings'
            ];

            let values = reasonsForRequest.map(reasonID => {
                return rawData[reasonID];
            });

            if(values.includes(true) && rawData['hiddenRequestReasonTracker'] != true){
                changedValues['hiddenRequestReasonTracker'] = true;
            } else if (rawData['hiddenRequestReasonTracker'] != null) {
                changedValues['hiddenRequestReasonTracker'] = null;
            }
            break;
        }
        /*eslint-enable */
        case 'patientDateOfBirth': {
            if(value != null){
                let momentDate = moment(value, 'DD/MM/YYYY');
                let years = moment().diff(momentDate, 'years');
                if(!isNaN(+years) && years >= 0){
                    changedValues['patientAge'] = years;
                } else {
                    changedValues['patientAge'] = null;
                }
            } else {
                changedValues['patientAge'] = null;
            }
            break;
        }
        case 'patientAge':{
            if(rawData['patientDateOfBirth'] != null){
                let momentDate = moment(rawData['patientDateOfBirth']);
                let years = moment().diff(momentDate, 'years');
                if(years != null && parseInt(years) != parseInt(value)){
                    changedValues['patientDateOfBirth'] = null;
                } 
            } 
            break;
        }
    }

    if (Object.keys(changedValues).length > 0) {
        let mainForm = formLoader.mainForm(pathwayId);
        mainForm.save(changedValues, workflow, true, iterationIndex + 1);
    }
}