import { ENABLED_EXPORTS, ACC_API_ROOT } from '../../../config/config';

export const EXPORT_TO_EMAIL_PENDING = 'EXPORT_TO_EMAIL_PENDING';
export const EXPORT_TO_EMAIL_FULFILLED = 'EXPORT_TO_EMAIL_FULFILLED';
export const EXPORT_TO_EMAIL_FAILED = 'EXPORT_TO_EMAIL_FAILED';
export const PREVIEW_TO_EMAIL_FULFILLED = 'PREVIEW_TO_EMAIL_FULFILLED';

export const RESET_EMAIL_EXPORT_STATE = 'RESET_EMAIL_EXPORT_STATE';

const exportToEmail = (data) => (dispatch) => {
    dispatch({
        type: EXPORT_TO_EMAIL_PENDING
    });


    let postData = JSON.stringify(data);

    if (ENABLED_EXPORTS.includes('acc') == false) {
        console.log('ACC_API_ROOT not enabled for environment.');
        return dispatch({
            type: EXPORT_TO_EMAIL_FAILED
        });
    }

    if (ACC_API_ROOT === null || ACC_API_ROOT == undefined) {
        console.log('EMAIL_API_ROOT not defined for environment.');
        
        return dispatch({
            type: EXPORT_TO_EMAIL_FAILED
        });
    }

    return post(`${ACC_API_ROOT}email`, data).then((response) => {
        return dispatch({
            type: EXPORT_TO_EMAIL_FULFILLED
        });
    }).catch((error) => {
        return dispatch({
            type: EXPORT_TO_EMAIL_FAILED
        });
    });
};

const resetState = () => (dispatch) => {
    dispatch({
        type: RESET_EMAIL_EXPORT_STATE
    });
};

function post(url = '', data = {}) {
    // Default options are marked with *
    return fetch(url, {
        method: 'POST',
        mode: 'cors', 
        cache: 'no-cache', 
        // credentials: 'include',
        headers: {
            'Content-Type': 'application/json'
        },
        redirect: 'follow',
        referrer: 'no-referrer',
        body: JSON.stringify(data), 
    }).then(response => response.json()); 
}

export { exportToEmail, resetState };