export class PatientSex {

    static gender = Object.freeze({ female: 0,  male: 1, other: 2});

    get value() {
        switch (this.value) {
            case PatientSex.gender.male:
                return 1;
            case PatientSex.gender.female:
                return 0;
            case PatientSex.gender.other:
                return 2;
        }
    }

    get title() {
        switch (this.value) {
            case PatientSex.gender.male:
                return 'Male';
            case PatientSex.gender.female:
                return 'Female';
            case PatientSex.gender.other:
                return 'Other';
        }
    }

    static genderFromInt(integer) {
        switch (integer) {
            case PatientSex.gender.male:
                return PatientSex.gender.male;
            case PatientSex.gender.female:
                return PatientSex.gender.female;
            case PatientSex.gender.other:
                return PatientSex.gender.other;
            default:
                throw new Error('Unhandled case');
        }
    }

    static genderFromOption(option) {
        if (option == 'genderMaleOption') {
            return PatientSex.gender.male;
        } else if (option == 'genderFemaleOption') {
            return PatientSex.gender.female;
        } else if(option == 'genderOtherOption'){
            return PatientSex.gender.other;
        }
        return null;
    }

    static genderFromString(string) {
        switch (string) {
            case 'Male':
                return PatientSex.gender.male;
            case 'Female':
                return PatientSex.gender.female;
            case 'Other':
                return PatientSex.gender.other;
            default:
                throw new Error('Unhandled case');
        }
    }
}
