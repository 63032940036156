
import { PathwayFormatter } from '../PathwayFormatter';
import { DXAActionItem } from './Actions/DXAActionItem';

const sectionLine = '=======================================\n';
const sectionBreak = '\n\n';

export class OsteoporosisFormatter {
    static formatAsPlainText(formLoader, workflow) {
        
        var forms = [formLoader.form(workflow.pathwayUuid, 'patientDetails'),  formLoader.form(workflow.pathwayUuid, 'requestCriteria'), formLoader.form(workflow.pathwayUuid, 'furtherInformation'), formLoader.form(workflow.pathwayUuid, 'resultsForm')];
        let actionOutcome = workflow.rawData['dxaRequestOutcome'] != null  ? DXAActionItem.rehydrate(workflow.rawData['dxaRequestOutcome']) : null;
        var formattedString = '';
    
        formattedString += 'Wayfind DXA Request\n';
        formattedString += 'Updated: ' + PathwayFormatter.formatDate(workflow.lastModifiedDate, 'ddd DD-MMM-YYYY HH:mm') + ' (' + 'version ' + workflow.version +  ')';
        formattedString += sectionBreak;

        if (isPatientOverweight(workflow)) {
            formattedString +=  'PATIENT NOT ELIGIBLE\n';
            formattedString += sectionLine;
        } else if(actionOutcome != null){
            formattedString +=  'ERMS REQUEST DETAILS\n';
            formattedString += sectionLine;
            formattedString += actionOutcome.getAcceptanceDescription(workflow.rawData['privateFundingAvailable'] == true) + '\n';
            if(actionOutcome.reasons.length > 0){
                formattedString += 'Reason for request: ' + (actionOutcome.reasons.length != 1 ? '\n' : ' ') + actionOutcome.reasons.join(',\n');
            }
            
            if(workflow.rawData['acceptanceOutsideUsualCriteria'] == true){
                formattedString += 'GP requesting special consideration: YES\n';
                formattedString += 'Reason: ' + workflow.rawData['acceptanceOutsideCriteriaReason'];
            }
            formattedString += sectionBreak;
        }

        for(let form of forms){
            formattedString += form.title.toUpperCase() + '\n';
            formattedString += sectionLine;
            formattedString += form.detailString(false, true);
            formattedString += sectionBreak;
        }
        
        return formattedString;
    }

    
}

function isPatientOverweight(workflow){
    return workflow.rawData['patientWeight'] != null && workflow.rawData['patientWeight'] >= 160;
}
