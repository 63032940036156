// IMPORT PACKAGE REFERENCESPathwayReferenceHelper
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FormLabel } from '../../../waferJS/FormsUI/FormsUI Components/Controls/FormLabel';
import { FormButton } from '../../../waferJS/FormsUI/FormsUI Components/Controls/FormButton';
import { DateHelper } from '../../../waferJS/Helpers/DateHelper';
import { FormsStyles } from '../../../waferJS/FormsUI/FormsUI';
import { CheckModal } from '../../Modals/CheckModal';
import { clearCalculations } from '../../state/actions/CalculationActions';
import { clearActions } from '../../state/actions/ActionActions';
import { Base64 } from '../../../helpers/Base64Encoder';
import { FormPie } from '../../../waferJS/FormsUI/FormsUI Components/Controls/FormPie';
import { clearResultsAlert, clearResultsBadge } from '../../state/actions/NotificationsActions';
import { clearState } from '../../state/actions/SubformStateActions';
import { createNewResponse, clearWorkflow, importWorkflow } from '../../state/actions/WorkflowResponseActions';
import { PathwayReferenceHelper } from '../../../forms/PathwayReferenceHelper';
import { EDChestPainIntroForm } from '../../../forms/edChestPain01/Forms/EDChestPain-IntroForm';
import { Chevron } from '../../shared/Chevron';
import { FormDetailWithReact } from '../../../waferJS/Form To FormsUI/FormDetailWithReact';
import { withRouter } from '../../routers/withRouter';

// COMPONENT
class ExtensionHandoverPage extends React.Component {

    constructor(props) {
        super(props);

        let params = new URLSearchParams(this.props.location.search);
        let data = params.get('data');
        let dataString = Base64.decode(data);
        let jsonData = JSON.parse(dataString);

        this.state = {
            form: new EDChestPainIntroForm(),
            jsonData: jsonData,
            checkingForDiscard: false,
            checkModalVisible: false,
            workflow: props.workflows[this.props.match.params.pathwayId] == undefined ? {} : props.workflows[this.props.match.params.pathwayId]
        };

        this.importPathwayAction = this.importPathwayAction.bind(this);
        this.useExistingSession = this.useExistingSession.bind(this);
        this.cancelAction = this.cancelAction.bind(this);
        this.discardPathwayCheck = this.discardPathwayCheck.bind(this);
        this.endCurrentSession = this.endCurrentSession.bind(this);
        this.handleClick = this.handleClick.bind(this);
    }

    componentDidMount() {
        document.addEventListener('click', this.handleClick);
        if (JSON.stringify(this.state.workflow) == JSON.stringify({}) && this.state.jsonData != null) {
            this.props.importWorkflow(this.props.match.params.pathwayId, this.state.data);
            this.props.navigate('/' + this.props.match.params.pathwayId + '/mainForm');
        }
    }

    importPathwayAction() {
        if (this.state.jsonData != null) {
            this.props.importWorkflow(this.props.match.params.pathwayId, this.state.jsonData);
        } else {
            this.props.createNewResponse(this.props.match.params.pathwayId);
        }

        this.props.navigate('/' + this.props.match.pathwayId + '/mainForm');
    }

    handleClick(e) {
        if (this.existingSessionNode != null && this.existingSessionNode.contains(e.target)) {
            this.useExistingSession();
        }
    }

    useExistingSession() {
        this.props.navigate('/' + this.props.match.params.pathwayId + '/mainForm');
    }

    discardPathwayCheck() {
        this.setState({
            checkingForDiscard: true,
            checkModalVisible: true
        });
    }

    cancelAction() {
        this.setState({
            checkingForDiscard: false,
            checkModalVisible: false
        });
    }

    componentDidUpdate(prevProps){
        if(JSON.stringify(this.props.workflows) !== JSON.stringify(prevProps.workflows)){
            this.setState({workflow: this.props.workflows[this.props.match.params.pathwayId]});
        }
    }

    endCurrentSession() {
        this.props.clearWorkflow(this.props.match.params.pathwayId);
        this.props.clearCalculations(this.props.match.params.pathwayIds);
        this.props.clearActions(this.props.match.params.pathwayIds);
        this.props.clearState(this.props.match.params.pathwayIds);
        this.props.clearResultsAlert(this.props.match.params.pathwayIds);
        this.props.clearResultsBadge(this.props.match.params.pathwayIds);
        this.props.createNewResponse(this.props.match.params.pathwayId);

        this.setState({ checkModalVisible: false });
        this.setState({ checkingForDiscard: false });

        this.setState({workflow: {}});
    }

    render() {
        let formProgress = (this.props.formLoader.mainForm(this.props.match.params.pathwayId)).completionProgress();

        return (
            <div className="session-wrapper">
                {this.state.checkModalVisible && <div ref={checkNode => this.checkNode = checkNode}><CheckModal toggleVisibility={this.cancelAction} yesAction={this.endCurrentSession} distructiveActionTitle={'Discard Session'} /> </div>}
                <div className="session-overlay" />

                <div className="session-manager-container">
                    <div className="session-card open" ref={sessionCardNode => this.sessionCardNode = sessionCardNode}>
                        <header className={'session-title'}>Import Pathway</header>
                        <div className="session-body">
                            <div className={'' + (this.props.isExtension ? 'hidden-nav' : '')}>
                                {this.state.form.sectionIds.map((sectionID) => {
                                    let section = this.state.form.sectionReference(sectionID);
                                    return (
                                        <div key={sectionID} className='form-section'>
                                            {section[PathwayReferenceHelper.Type.sectionChildIDs].map((childID) => { return this.state.form.shouldDisplayFormDetailForIdentifier(childID) ? true : null; }).filter(n => n).length > 0 && <div className='section-title'>{section.title}</div>}
                                            {
                                                section[PathwayReferenceHelper.Type.sectionChildIDs] != undefined &&
                                                    section[PathwayReferenceHelper.Type.sectionChildIDs].map((childID) => {
                                                        if (this.state.form.shouldDisplayFormDetailForIdentifier(childID)) {
                                                            let formDetail = this.state.form.formDetail(childID);
                                                            return <FormDetailWithReact formLoader={this.props.formLoader} key={formDetail.identifier} formDetail={formDetail} isSummary={false} isSubcell={false} showHover={true} />;
                                                        }
                                                    })
                                            }
                                        </div>
                                    );
                                })}
                            </div>

                            {JSON.stringify(this.state.workflow.rawData) != JSON.stringify({}) &&
                                <div className="exisiting-session-container">
                                    <FormLabel value={'Existing Session'} fontSize={1.2} fontWeight={500} textColor={FormsStyles.black} />
                                    <div className="existing-session-cell" ref={existingSessionNode => this.existingSessionNode = existingSessionNode}>
                                        <div className="existing-session-button">
                                            <FormPie totalValue={formProgress.total} filledValue={formProgress.current} />
                                            <FormButton textAlignment={'left'} image={<Chevron angle={'rotate(-90deg)'} />} activeBackgroundColor={'transparent'} backgroundColor={'transparent'} enabled={true} isSelected={false} title={'Cardiac Chest Pain'} />
                                        </div>
                                        <FormLabel value={'Last modified ' + DateHelper.relativeDate(this.state.workflow.lastModifiedDate)} fontSize={0.9} textColor={'#B0B0B0'} />
                                        <FormLabel value={'Selected'} fontSize={0.9} textColor={FormsStyles.primaryTint} />
                                    </div>
                                    <div className="new-pathway-button"><FormButton key='newPathway' onPress={this.discardPathwayCheck} enabled={true} isSelected={false} title={'Discard Session'} color={FormsStyles.red} /></div>
                                </div>
                            }

                            <div className="new-session-container">
                                <FormLabel value={'Import Session'} fontSize={1.2} fontWeight={500} textColor={FormsStyles.black} />
                                {JSON.stringify(this.state.workflow.rawData) != JSON.stringify({}) && <FormLabel value={'Only one session can be stored; to import a new one you must discard your existing session. Ensure you have exported any important data from the session above before continuing'} fontSize={0.9} textColor={FormsStyles.orange} />}
                                <div className="new-pathway-button"><FormButton key='newPathway' onPress={this.importPathwayAction} enabled={JSON.stringify(this.state.workflow.rawData) == JSON.stringify({})} isSelected={false} title={this.state.jsonData != null ? 'Import Pathway' : 'Start New Session'} /></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

ExtensionHandoverPage.propTypes = {
    location: PropTypes.object.isRequired,
    navigate: PropTypes.func.isRequired,
    WorkflowResponseActions: PropTypes.object,
    updatingWorkflowPending: PropTypes.bool.isRequired,
    updatingWorkflowFulfilled: PropTypes.bool.isRequired,
    updatingWorkflowFailed: PropTypes.bool.isRequired,
    createNewResponse: PropTypes.func.isRequired,
    clearCalculations: PropTypes.func.isRequired,
    clearActions: PropTypes.func.isRequired,
    isExtension: PropTypes.bool.isRequired,
    importWorkflow: PropTypes.func.isRequired,
    clearState: PropTypes.func.isRequired,
    clearResultsAlert: PropTypes.func.isRequired,
    clearResultsBadge: PropTypes.func.isRequired,
    clearWorkflow: PropTypes.func.isRequired,
    workflows: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired
};


// CONFIGURE REACT REDUX
const mapStateToProps = state => {
    const { workflows, updatingWorkflowPending, updatingWorkflowFulfilled, updatingWorkflowFailed } = state.workflowReducer;
    return { workflows, updatingWorkflowPending, updatingWorkflowFulfilled, updatingWorkflowFailed };
};

const mapDispatchToProps = dispatch => (
    bindActionCreators({ createNewResponse, clearCalculations, clearActions, importWorkflow, clearState, clearResultsAlert, clearResultsBadge, clearWorkflow }, dispatch)
);

const hoc = withRouter(connect(mapStateToProps, mapDispatchToProps)(ExtensionHandoverPage));

// EXPORT COMPONENT

export { hoc as ExtensionHandoverPage };