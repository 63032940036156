import { FormWithRedux } from '../../FormWithRedux';
import { PathwayReferenceHelper } from '../../PathwayReferenceHelper';
import { ACCMRIPathwayReference } from '../ACCMRI-PathwayReference';
import { FormsContentType } from '../../../waferJS/FormsUI/FormsUI';

export class ACCMRIRequestCriteriaSubform extends FormWithRedux {

    constructor(formLoader, parent, actionUpdater, dependentValuesUpdater, calculationsUpdater) {
        super('requestCriteria', formLoader, parent, actionUpdater, dependentValuesUpdater, calculationsUpdater);

        this.isRecommendationVisible = false;
    }

    getResultFromLogicTree(identifier) {
        const reasonForRequest_childIDs = ['cervicalSpineInjury', 'ghjOrInstability', 'lumbarSpineInjury', 'postTraumaticKneePain'];

        const mriContraindications_childIDs = ['pacemaker'];
        const cautions_childIDs = ['metalware', 'cochlearImplants', 'claustrophobia', 'worksWithMetalOrbitCheck'];
        
        if (reasonForRequest_childIDs.includes(identifier)) {
            return true;
        }
        let reasonForRequest = reasonForRequest_childIDs.map( childID => this.getValue(childID));
        if (reasonForRequest.includes(true)) {
            if (mriContraindications_childIDs.includes(identifier) || cautions_childIDs.includes(identifier)) {
                return true;
            }
            let dependencyChildIDs = mriContraindications_childIDs.concat(cautions_childIDs);
            let dependencyChildIDValues = dependencyChildIDs.map( childID => this.getValue(childID)); 
            if (dependencyChildIDValues.includes(true) && (!dependencyChildIDValues.includes(null) && !dependencyChildIDValues.includes(undefined))) {
                if (identifier == 'contactRadiologyProvider') {
                    return true;
                } else if (identifier == 'radiologyClearedPatient') {
                    return true;
                } else if (this.getValue('radiologyClearedPatient') == true) {
                    if (identifier == 'radiologyClearedPatientFurtherInformation') {
                        return true;
                    }
                }
            }
        }
        return false;
    }

    shouldDisplayFormDetailForIdentifier(identifier) {
        super.shouldDisplayFormDetailForIdentifier(identifier);

        if (identifier == 'contactRadiologyProvider') {
            let isVisible = this.getResultFromLogicTree(identifier);
            if (isVisible == true) {
                this.isRecommendationVisible = true;
            } else {
                this.isRecommendationVisible = false;
            }
            return isVisible;
        }

        return this.getResultFromLogicTree(identifier);
    }

    isEnabled(identifier) {
        if (identifier != null) {
            return true;
        }
        return false;
    }

    isMandatory(identifier) {
        const notMandatory = [
            'cervicalSpineInjury', 
            'ghjOrInstability', 
            'lumbarSpineInjury', 
            'postTraumaticKneePain'
        ];
        const completionItemRelationships = {
            'radiologyClearedPatient': 'contactRadiologyProvider'
        };

        for (let subcellID in completionItemRelationships) {
            let completionItem = completionItemRelationships[subcellID];
            if (identifier == subcellID) {
                if (this.getValue(completionItem) == false) {
                    return false;
                }
            }
        }

        if (notMandatory.includes(identifier)) {
            return false;
        } else if (this.getResultFromLogicTree(identifier) == false) {
            return false;
        }
        let formDetail = this.formDetail(identifier);

        return formDetail.canHaveValue && !(formDetail.controlType == 'completionItem');
    }

    getValueType(identifier) {
        const stringFields = [
            'radiologyClearedPatientFurtherInformation'];

        if (stringFields.includes(identifier)) {
            return 'string';
        }
        return 'boolean';
    }

    submissionValidation() {
        return [];
    }

    editingValidation() {
        return [];
    }

    detailString(isPlainText, forTextExport) {
        if (forTextExport == true) {
            let sectionStrings = this.sectionIds.map((sectionID) => {
                let section = this.sectionReference(sectionID);
                var sectionString = '';
                
                let sectionValues = [];
                let notPresentWithinSectionValues = [];
                let sectionsToDisplay = [];
                if(section[PathwayReferenceHelper.Type.sectionChildIDs] != undefined){
                    section[PathwayReferenceHelper.Type.sectionChildIDs].map((childID) => { //Loop through each field in section

                        if (this.shouldDisplayFormDetailForIdentifier(childID)) { //If field is visible in assessment
                            let formDetail = this.formDetail(childID);

                            if (section[PathwayReferenceHelper.Type.sectionStyle] == FormsContentType.SectionStyle.inline) { //If section is a recommendation
                                
                                let recommendation = []; //Construct a recommendation for export (so we can split by '\n', not ',')
                                if (formDetail.title != null && formDetail.title != undefined) {
                                    recommendation.push(formDetail.title); //Add recommendation text recommendation construction
                                }
                                
                                if (formDetail.controlType == FormsContentType.ControlType.completionItem) { //If recommendation is completion style, loop through subcells
                                    formDetail[PathwayReferenceHelper.Type.subcellIDs].map((subcellID) => {

                                        if (this.shouldDisplayFormDetailForIdentifier(subcellID)) {

                                            let subcellDetail = this.formDetail(subcellID);
                                            let summaryValue = this.getSummaryValue(false, subcellDetail);
                                            if (summaryValue != null) {
                                                recommendation.push(summaryValue); //If subcell is answered, add field to recommendation construction
                                            }
                                        }
                                    });
                                }

                                if (recommendation.length > 0) {
                                    sectionValues.push(recommendation.join('\n')); //Add the formatted recommendation export to the export values
                                }
                            } else if (formDetail.canHaveValue == true) { //If field is not recommendation and can have a value
                                let summaryValue = this.getSummaryValue(false, formDetail);
                            
                                if (summaryValue == false) {
                                    notPresentWithinSectionValues.push(formDetail.title); //If field is answered no, add field to 'Not present:' list
                                } else if (summaryValue != null) {
                                    sectionValues.push(summaryValue); //If field answered yes, add field to export
                                }
                            }
                            sectionsToDisplay.push(sectionID); //Add section to list of sections to display
                        } 
                    });
                    
                    if (sectionValues.length > 0) { 
                        if (section.title != null) {
                            sectionString += section.title.toUpperCase() + '\n';
                        }
                        sectionString += sectionValues.join('\n') + '\n';
                    }

                    if (notPresentWithinSectionValues.length > 0) {
                        if (!(sectionValues.length > 0) && section.title != null) {
                            sectionString += section.title.toUpperCase() + '\n';
                        }
                        if (section.title != 'Reason for request') {
                            sectionString += '(Not present: ' + notPresentWithinSectionValues.join(', ') + ')\n';
                        }
                    }
                }

                return sectionString == '' ? null : sectionString;
            }).filter( n => n);
            
            let combinedSections = sectionStrings.join('\n');

            return combinedSections;
        } else {
            let formDetails = this.getFormDetails();
            let values = [];
            for (let index in formDetails) {
                let value = formDetails[index];
                if (this.getResultFromLogicTree(value.identifier) == true) {
                    if (value.canHaveValue == true) {
                        values.push(this.getSummaryValue(null, value));
                    }
                }
            }
            return values.filter(n => n).join(isPlainText ? ', ' : '\n');
        }
    }

    getSummaryValue(isSubcell, value){
        let formDetail = this.formDetail(value.identifier);
        if (formDetail.canHaveValue == false) {
            return value.title;
        } else if (value.currentValue != null && value.currentValue != undefined) {
            if (value.title.endsWith('?') == true) {
                return value.title + (value.currentValue == true ? ': Yes' : ': No');
            } else if (value.controlType == 'textEntry') {
                return value.title + ': ' + value.currentValue;
            } else if (value.controlSubtype == 'simple') {
                let childID = PathwayReferenceHelper.reference(value.currentValue, ACCMRIPathwayReference.data);
                if (childID != null && childID != undefined) {
                    return value.title + ': ' + childID.title;
                }
            } else {
                return value.valueDescription();
            }
        }
    }
}