export class ACCBISTPathwayReference {
    static data = {
        'title': 'ACC Brain Injury Screening Tool',
        'detail': 'Placeholder Detail',
        'itemType': 'template',
        'template.version': 1.0,
        'template.versionUuid': 'bistAssessment',
        'template.createText': 'Use This Pathway',
        'template.introID': 'introForm',
        'template.contentTabIDs': ['notesForm', 'mainForm', 'resultsForm'],
        'template.defaultContentTabID': 'mainForm',
        'template.content': {
            'introForm': {
                'title': 'ACC Brain Injury Screening Tool',
                'detail': 'Wayfind TBI is for the screening and assessment of mild traumatic brain injury in adult patients. This tool will provide guided decision support based on ACC approved clinical pathways, and will automatically create a clinical summary, referral forms and patient advice.\n\nUse the concussion assessment section to score AWPTA (Abbreviated Westmead Post-Traumatic Amnesia) or BIST (Brain Injury Screening Tool) at the bedside, or complete the pathway to create a comprehensive clinical record and receive decision support for CT scan and Concussion Service referrals.',
                'itemType': 'subform',
                'subform.sectionIDs': [

                ]
            },
            'mainForm': {
                'title': 'Pathway',
                'itemType': 'subform',
                'subform.sectionIDs': [
                    'patientDetailsSection',
                    'injuryDetailsSection',
                    'clinicalAssessmentSection',
                    'concussionAssessmentSection',
                    'investigationsSection'
                ]
            },
            'notesForm': {
                'title': 'Notes',
                'itemType': 'subform',
                'subform.sectionIDs': [
                    'notesSection'
                ]
            },
            'notesSection': {
                'itemType': 'section',
                'section.childIDs': [
                    'freeformNotes'
                ]
            },
            'freeformNotes': {
                'itemType': 'control',
                'control.type': 'textEntry',
                'mandatory': false,
                'textEntry.type': 'wrappingPlainTextLarge',
            },
            /// PATIENT DETAILS
            'patientDetailsSection': {
                'title': 'Patient Details',
                'itemType': 'section',
                'section.childIDs': [
                    'patientDetailsSubform',
                ]
            },
            'patientDetailsSubform': {
                'title': 'Patient Details',
                'itemType': 'subform',
                'subform.sectionIDs': [
                    'basicDetailsSection',
                    'accDetailsSection',
                    'contactDetailsSection'
                ],
                'mandatoryFields': [
                    'patientNHI',
                    'patientName',
                    'patientDOB',
                    'patientSex',
                    'patientPhoneNumber',
                    'patientACCClaimNumber'
                ]
            },
            'basicDetailsSection': {
                'title': 'Basic',
                'itemType': 'section',
                'section.childIDs': [
                    'patientNHI',
                    'patientName',
                    'patientDOB',
                    'patientSex',
                    'patientGender',
                    'patientEthnicity',
                ]
            },
            'contactDetailsSection': {
                'title': 'Contact Details',
                'itemType': 'section',
                'section.childIDs': [
                    'patientPhoneNumber',
                    'patientAddress',
                    'patientNOKName',
                    'patientNOKPhoneNumber',
                ]
            },
            'accDetailsSection': {
                'title': 'ACC Details',
                'itemType': 'section',
                'section.childIDs': [
                    'patientACCClaimNumber',
                    'patientGPName',
                    'patientGPPractice'
                ]
            },
            'patientName': {
                'title': 'Name',
                'itemType': 'control',
                'control.type': 'textEntry',
                'textEntry.type': 'singleLinePlainText'
            },
            'patientNHI': {
                'title': 'NHI Number',
                'itemType': 'control',
                'control.type': 'textEntry',
                'textEntry.type': 'plainTextWithNHILookup'
            },
            'patientDOB': {
                'title': 'Date of birth',
                'itemType': 'control',
                'control.type': 'picker',
                'picker.type': 'dateOfBirth',
                'subcellIDs': [
                    'patientAgeWarning'
                ]
            },
            'patientAge': {
                'title': 'Age',
                'itemType': 'control',
                'control.type': 'textEntry',
                'textEntry.type': 'decimalNumber',
                'textEntry.unitText': 'years',
            },
            'patientAgeWarning': {
                'title': 'Patient must be 16 years or older to continue with this pathway.',
                'itemType': 'control',
                'control.type': 'plain'
            },
            'patientGender': {
                'title': 'Gender',
                'itemType': 'control',
                'control.type': 'textEntry',
                'textEntry.type': 'singleLinePlainText'
            },
            'patientSex': {
                'title': 'Sex',
                'itemType': 'control',
                'control.type': 'selection',
                'selection.style': 'simple',
                'selection.options': [
                    'genderMaleOption',
                    'genderFemaleOption'
                ]
            },
            'genderMaleOption': {
                'title': 'Male',
                'itemType': 'option'
            },
            'genderFemaleOption': {
                'title': 'Female',
                'itemType': 'option'
            },
            'patientEthnicity': {
                'title': 'Ethnicity',
                'itemType': 'control',
                'control.type': 'textEntry',
                'textEntry.type': 'singleLinePlainText'
            },
            'patientPhoneNumber': {
                'title': 'Phone Number',
                'itemType': 'control',
                'control.type': 'textEntry',
                'textEntry.type': 'decimalNumber'
            },
            'patientAddress': {
                'title': 'Address',
                'itemType': 'control',
                'control.type': 'textEntry',
                'textEntry.type': 'singleLinePlainText'
            },
            'patientNOKName': {
                'title': 'Next of kin name and relationship',
                'itemType': 'control',
                'control.type': 'textEntry',
                'textEntry.type': 'singleLinePlainText'
            },
            'patientNOKPhoneNumber': {
                'title': 'Next of kin phone number',
                'itemType': 'control',
                'control.type': 'textEntry',
                'textEntry.type': 'decimalNumber'
            },
            'patientGPName': {
                'title': 'GP Name',
                'itemType': 'control',
                'control.type': 'textEntry',
                'textEntry.type': 'singleLinePlainText'
            },
            'patientGPPractice': {
                'title': 'GP Practice',
                'itemType': 'control',
                'control.type': 'textEntry',
                'textEntry.type': 'singleLinePlainText'
            },
            'patientACCClaimNumber': {
                'title': 'ACC Number (ACC45) or claim number',
                'itemType': 'control',
                'control.type': 'textEntry',
                'textEntry.type': 'singleLinePlainText'
            },

            // INJURY DETAILS

            'injuryDetailsSection': {
                'title': 'Injury Details',
                'itemType': 'section',
                'section.childIDs': [
                    'injuryDetailsSubform',
                ]
            },
            'injuryDetailsSubform': {
                'title': 'Injury Details',
                'itemType': 'subform',
                'subform.sectionIDs': [
                    'inuryTimingSection',
                    'injurDetailsQuestionsSection'
                ]
            },
            'inuryTimingSection': {
                'title': 'Timing of Injury',
                'itemType': 'section',
                'section.childIDs': [
                    'injuryOccuranceDateTime',
                    'worstGCS',
                    'arrivalGCS',
                    'invalidArrivalGCSWarning'
                ]
            },
            'injurDetailsQuestionsSection': {
                'title': 'Injury Details',
                'itemType': 'section',
                'section.childIDs': [
                    'injuryEventDescription',
                    'dangerousMechanismOfOInjury',
                    'concussiveForce',
                    'directHeadInjury',
                    'alterationInMentalState',
                    'postTraumaticSeizure',
                    'confoundingFactors',
                    'additionalInjuryDetails'
                ]
            },
            'directHeadInjury': {
                'title': 'Isolated head injury?',
                'detail': 'Is a direct head injury the only significant injury?',
                'itemType': 'control',
                'control.type': 'selection',
                'selection.style': 'explicit',
                'subcellIDs': [
                    'otherInjuries'
                ]
            },
            'otherInjuries': {
                'title': 'Other injuries',
                'detail': 'Record other significant injuries or multitrauma',
                'itemType': 'control',
                'control.type': 'textEntry',
                'textEntry.type': 'wrappingPlainText'
            },
            'alterationInMentalState': {
                'title': 'Alteration in mental state or reduced level of consciousness?',
                'detail': 'Was the mental status altered immediately after the impact?',
                'itemType': 'control',
                'control.type': 'selection',
                'selection.style': 'explicit',
                'subcellIDs': [
                    'alterationInMentalStateSubform'
                ]
            },
            'alterationInMentalStateSubform': {
                'title': 'Alteration in Mental State',
                'itemType': 'subform',
                'subform.sectionIDs': [
                    'alterationInMentalStateSection'
                ]
            },
            'alterationInMentalStateSection': {
                'itemType': 'section',
                'section.childIDs': [
                    'lossOfConsciousness',
                    'retrogradeAmnesia',
                    'alteredMentalState'
                ]
            },
            'confoundingFactors': {
                'title': 'Confounding factors?',
                'detail': 'Could factors other than brain injury account for the acute alteration in mental status?',
                'itemType': 'control',
                'control.type': 'selection',
                'selection.style': 'explicit',
                'subcellIDs': [
                    'confoundingFactorsSubform'
                ]
            },
            'confoundingFactorsSubform': {
                'title': 'Confounding Factors',
                'itemType': 'subform',
                'subform.sectionIDs': [
                    'confoundingFactorsSection'
                ]
            },
            'confoundingFactorsSection': {
                'itemType': 'section',
                'section.childIDs': [
                    'intoxicationOrDrugUse',
                    'psychologicalOrEmotionalTrauma',
                    'severePain'
                ]
            },
            'injuryOccuranceDateTime': {
                'title': 'When did the injury occur?',
                'itemType': 'control',
                'control.type': 'picker',
                'picker.type': 'dateTime'
            },
            'worstGCS': {
                'title': 'Lowest Recorded GCS',
                'detail': 'Recorded by a health professional',
                'itemType': 'control',
                'control.type': 'textEntry',
                'textEntry.type': 'decimalNumber',
                'subcellIDs': [
                    'worstGCSDateTime'
                ]
            },
            'worstGCSDateTime': {
                'title': 'When was this collected?',
                'itemType': 'control',
                'control.type': 'picker',
                'picker.type': 'dateTime'
            },
            'arrivalGCS': {
                'title': 'Recorded Arrival GCS',
                'detail': 'Recorded by a health professional',
                'itemType': 'control',
                'control.type': 'textEntry',
                'textEntry.type': 'decimalNumber',
                'subcellIDs': [
                    'arrivalGCSDateTime'
                ]
            },
            'invalidArrivalGCSWarning': {
                'title': 'TBI is not mild if GCS less than 13, at least 30min after injury. The results of this pathway may not be relevant.',
                'itemType': 'control',
                'control.type': 'callout'
            },
            'arrivalGCSDateTime': {
                'title': 'When was this collected?',
                'itemType': 'control',
                'control.type': 'picker',
                'picker.type': 'dateTime'
            },
            'injuryEventDescription': {
                'title': 'What happened?',
                'detail': 'Describe the sequence of events surrounding the injury.',
                'itemType': 'control',
                'control.type': 'textEntry',
                'textEntry.type': 'wrappingPlainText'
            },
            'dangerousMechanismOfOInjury': {
                'title': 'Dangerous mechanism of injury?',
                'detail': 'Eg. pedestrian versus car, ejected from a vehicle or fall > 0.9 metres or > 5 stairs',
                'itemType': 'control',
                'control.type': 'selection',
                'selection.style': 'explicit'
            },
            'concussiveForce': {
                'title': 'Concussive force?',
                'detail': 'Concussion can occur with or without direct head injury. Include head strike, motor vehicle accident or fall from height. Also consider deceleration or blast forces.',
                'itemType': 'control',
                'control.type': 'selection',
                'selection.style': 'explicit'
            },
            'postTraumaticSeizure': {
                'title': 'Post-Traumatic Seizure?',
                'detail': 'Include going stiff or shaking violently after the injury',
                'itemType': 'control',
                'control.type': 'selection',
                'selection.style': 'explicit'
            },
            'additionalInjuryDetails': {
                'title': 'Additional Injury Details',
                'detail': 'Record further detail of the injury mechanism, alterations in mental state, or confounding factors. Did anyone with you at the time of the injury say anything else about what happened?',
                'itemType': 'control',
                'control.type': 'textEntry',
                'textEntry.type': 'wrappingPlainText'
            },
            'lossOfConsciousness': {
                'title': 'Loss of consciousness?',
                'detail': 'Knocked out or unresponsive',
                'itemType': 'control',
                'control.type': 'selection',
                'selection.style': 'explicit',
                'subcellIDs': [
                    'lossOfConsciousnessDuration'
                ]
            },
            'lossOfConsciousnessDuration': {
                'title': 'Duration?',
                'itemType': 'control',
                'control.type': 'textEntry',
                'textEntry.type': 'decimalNumber',
                'textEntry.unitText': 'mins'
            },
            'retrogradeAmnesia': {
                'title': 'Retrograde Amnesia?',
                'detail': 'Amnesia for events before the injury',
                'itemType': 'control',
                'control.type': 'selection',
                'selection.style': 'explicit',
                'subcellIDs': [
                    'retrogradeAmnesiaDuration'
                ]
            },
            'retrogradeAmnesiaDuration': {
                'title': 'Duration',
                'itemType': 'control',
                'control.type': 'textEntry',
                'textEntry.type': 'decimalNumber',
                'textEntry.unitText': 'mins'
            },
            'alteredMentalState': {
                'title': 'Altered mental state?',
                'detail': 'Confusion, disorientation or slowed thinking after the injury',
                'itemType': 'control',
                'control.type': 'selection',
                'selection.style': 'explicit'
            },
            'intoxicationOrDrugUse': {
                'title': 'Intoxication or recreational drug use?',
                'detail': 'Around the time of injury',
                'itemType': 'control',
                'control.type': 'selection',
                'selection.style': 'explicit'
            },
            'psychologicalOrEmotionalTrauma': {
                'title': 'Psychological or emotional trauma?',
                'detail': 'Significant fear or distress at the time of injury. Include assault, violence or fatalities at the scene',
                'itemType': 'control',
                'control.type': 'selection',
                'selection.style': 'explicit'
            },
            'severePain': {
                'title': 'Severe Pain?',
                'detail': 'From any associated injuries',
                'itemType': 'control',
                'control.type': 'selection',
                'selection.style': 'explicit'
            },
            'clinicalAssessmentSection': {
                'title': 'Clinical Assessment',
                'itemType': 'section',
                'section.childIDs': [
                    'clinicalAssessmentSubform',
                ]
            },
            'clinicalAssessmentSubform': {
                'title': 'Clinical Assessment',
                'itemType': 'subform',
                'subform.sectionIDs': [
                    'signsAndSymptomsSection',
                    'problemsAndPastMedicalHistorySection',
                    'medicationsSection',
                    'socialHistorySection'
                ]
            },
            'signsAndSymptomsSection': {
                'title': 'Symptoms and Signs',
                'itemType': 'section',
                'section.childIDs': [
                    'feelingBetterSameWorseSinceInjury',
                    'headache',
                    'vomiting',
                    'suspectedSkullFracture',
                    'focalNeurologicalDeficit',
                    'additionalSymptomsAndSigns'
                ]
            },
            'feelingBetterSameWorseSinceInjury': {
                'title': 'Are you feeling better, about the same, or worse since the injury?',
                'itemType': 'control',
                'control.type': 'selection',
                'selection.style': 'simple',
                'selection.options': [
                    'betterOption',
                    'sameOption',
                    'worseOption'
                ]
            },
            'betterOption': {
                'title': 'Better',
                'itemType': 'option'
            },
            'sameOption': {
                'title': 'Same',
                'itemType': 'option'
            },
            'worseOption': {
                'title': 'Worse',
                'itemType': 'option'
            },
            'headache': {
                'title': 'Headache?',
                'itemType': 'control',
                'control.type': 'selection',
                'selection.style': 'explicit'
            },
            'vomiting': {
                'title': 'Vomiting?',
                'itemType': 'control',
                'control.type': 'selection',
                'selection.style': 'explicit',
                'subcellIDs': [
                    'numberOfVomittingEpisodes'
                ]
            },
            'numberOfVomittingEpisodes': {
                'title': 'How many episodes?',
                'itemType': 'control',
                'control.type': 'textEntry',
                'textEntry.type': 'decimalNumber',
                'textEntry.unitText': 'episodes'
            },
            'suspectedSkullFracture': {
                'title': 'Suspected skull fracture',
                'itemType': 'control',
                'control.type': 'selection',
                'selection.style': 'explicit',
                'subcellIDs': [
                    'suspectedFractureSubform'
                    
                ]
            },
            'suspectedFractureSubform': {
                'title': 'Suspected skull fracture',
                'itemType': 'subform',
                'subform.sectionIDs': [
                    'suspectedFractureSection'
                ]
            },
            'suspectedFractureSection': {
                'itemType': 'section',
                'section.childIDs': [
                    'depressedSkullFracture',
                    'openSkullFracture',
                    'basalSkullFracture'
                ]
            },
            'depressedSkullFracture': {
                'title': 'Depressed',
                'detail': 'Boggy haematoma, Obvious defect in skull',
                'itemType': 'control',
                'control.type': 'selection',
                'selection.style': 'explicit'
            },
            'openSkullFracture': {
                'title': 'Open',
                'detail': 'Otorrhoea/rhinorrhoea indicating cerebrospinal fluid (CSF) leak (double halo sign) or Visualised fracture',
                'itemType': 'control',
                'control.type': 'selection',
                'selection.style': 'explicit'
            },
            'basalSkullFracture': {
                'title': 'Basal',
                'detail': 'Hemotympanum, Bilateral periorbital bruising or Battle’s sign (postauricular bruising)',
                'itemType': 'control',
                'control.type': 'selection',
                'selection.style': 'explicit'
            },
            'focalNeurologicalDeficit': {
                'title': 'Focal neurological deficit?',
                'detail': 'Abnormal neurological exam',
                'itemType': 'control',
                'control.type': 'selection',
                'selection.style': 'explicit',
                'subcellIDs': [
                    'focalNeurologicalDeficitDetails'
                ]
            },
            'focalNeurologicalDeficitDetails': {
                'title': 'Details',
                'itemType': 'control',
                'control.type': 'textEntry',
                'textEntry.type': 'wrappingPlainText'
            },
            'additionalSymptomsAndSigns': {
                'title': 'Additional Symptoms and Signs',
                'detail': 'Record any wounds or contusions to the scalp, face or neck',
                'itemType': 'control',
                'control.type': 'textEntry',
                'textEntry.type': 'wrappingPlainText'
            },
            'problemsAndPastMedicalHistorySection': {
                'title': 'Problems and Past Medical History ',
                'itemType': 'section',
                'section.childIDs': [
                    'previousConcussionOrBrainInjury',
                    'bleedingOrClottingDisorder',
                    'historyOfMigraine',
                    'mentalHealthDifficulties',
                    'previousAlchoholOrSubstanceAbuse',
                    'cognitiveImpairment',
                    'additionalHistoryDetails'
                ]
            },
            'previousConcussionOrBrainInjury': {
                'title': 'Previous Concussion or brain injury?',
                'itemType': 'control',
                'control.type': 'selection',
                'selection.style': 'explicit',
                'subcellIDs': [
                    'numberOfPreviousConcussionsOrBrainInjuries',
                    'dateOfLastInjury',
                    'wasRecoveryProtractedOrProblematic'
                ]
            },
            'numberOfPreviousConcussionsOrBrainInjuries': {
                'title': 'How many times?',
                'itemType': 'control',
                'control.type': 'textEntry',
                'textEntry.type': 'decimalNumber',
            },
            'dateOfLastInjury': {
                'title': 'When was the last injury?',
                'itemType': 'control',
                'control.type': 'textEntry',
                'textEntry.type': 'decimalNumber',
                'textEntry.unitText': 'year',
            },
            'wasRecoveryProtractedOrProblematic': {
                'title': 'Have any previous concussions led to a prolonged or problematic recovery?',
                'itemType': 'control',
                'control.type': 'selection',
                'selection.style': 'explicit'
            },
            'protractedOption': {
                'title': 'Protracted',
                'itemType': 'option'
            },
            'problematicOption': {
                'title': 'Problematic',
                'itemType': 'option'
            },
            'bleedingOrClottingDisorder': {
                'title': 'Bleeding or clotting disorder?',
                'detail': 'Eg. Haemophilia',
                'itemType': 'control',
                'control.type': 'selection',
                'selection.style': 'explicit'
            },
            'historyOfMigraine': {
                'title': 'History of Migraine?',
                'detail': 'Severe headache with vomiting or extreme sensitivity to light and sound',
                'itemType': 'control',
                'control.type': 'selection',
                'selection.style': 'explicit'
            },
            'mentalHealthDifficulties': {
                'title': 'Mental health difficulties?',
                'detail': 'Including anxiety, depression, PTSD',
                'itemType': 'control',
                'control.type': 'selection',
                'selection.style': 'explicit'
            },
            'previousAlchoholOrSubstanceAbuse': {
                'title': 'Previous alcohol or substance abuse?',
                'itemType': 'control',
                'control.type': 'selection',
                'selection.style': 'explicit'
            },
            'cognitiveImpairment': {
                'title': 'Cognitive impairment?',
                'detail': 'Include dementia, fetal alcohol syndrome, and other conditions that may affect cognitive testing.',
                'itemType': 'control',
                'control.type': 'selection',
                'selection.style': 'explicit'
            },
            'additionalHistoryDetails': {
                'title': 'Additional History Details',
                'itemType': 'control',
                'control.type': 'textEntry',
                'textEntry.type': 'wrappingPlainText'
            },
            'medicationsSection': {
                'title': 'Medications',
                'itemType': 'section',
                'section.childIDs': [
                    'anticoagulantOrAntiplateletAgent',
                    'additionalMedications'
                ]
            },
            'anticoagulantOrAntiplateletAgent': {
                'title': 'Anticoagulant or Antiplatelet agent?',
                'itemType': 'control',
                'control.type': 'selection',
                'selection.style': 'explicit',
                'subcellIDs': [
                    'anticoagulantAntiplateletSubform'
                ]
            },
            'anticoagulantAntiplateletSubform': {
                'title': 'Anticoagulant or Antiplatelet agent',
                'itemType': 'subform',
                'subform.sectionIDs': [
                    'anticoagulantAntiplateletSection'
                ]
            },
            'anticoagulantAntiplateletSection': {
                'title': 'Medications',
                'itemType': 'section',
                'section.childIDs': [
                    'aspirin',
                    'clopidogrelOrTicagrelor',
                    'warfarin',
                    'revaroxabanOrDabigatran',
                    'otherMedication'
                ]
            },
            'aspirin': {
                'title': 'Aspirin',
                'itemType': 'control',
                'control.type': 'selection',
                'selection.style': 'explicit'
            },
            'clopidogrelOrTicagrelor': {
                'title': 'Clopidogrel or Ticagrelor',
                'itemType': 'control',
                'control.type': 'selection',
                'selection.style': 'explicit'
            },
            'warfarin': {
                'title': 'Warfarin',
                'itemType': 'control',
                'control.type': 'selection',
                'selection.style': 'explicit'
            },
            'revaroxabanOrDabigatran': {
                'title': 'Rivaroxaban or Dabigatran',
                'itemType': 'control',
                'control.type': 'selection',
                'selection.style': 'explicit'
            },
            'otherMedication': {
                'title': 'Other',
                'itemType': 'control',
                'control.type': 'selection',
                'selection.style': 'explicit'
            },
            'additionalMedications': {
                'title': 'Additional Medications Details',
                'itemType': 'control',
                'control.type': 'textEntry',
                'textEntry.type': 'wrappingPlainText'
            },
            'socialHistorySection': {
                'title': 'Social History',
                'itemType': 'section',
                'section.childIDs': [
                    'highRiskOccupation',
                    'employment',
                    'socialDetails'
                ]
            },
            'socialDetails': {
                'title': 'Social details',
                'detail': 'For example lives alone or in shared accommodation, lives rurally, poor supports',
                'itemType': 'control',
                'control.type': 'textEntry',
                'textEntry.type': 'wrappingPlainText'
            },
            'highRiskOccupation': {
                'title': 'High risk Occupation',
                'detail': 'Safety-critical jobs, tasks requiring sustained cognitive effort, or where re-injury is possible. For example pilot, physician, machinery operator, professional sports, driver, student, high screen use',
                'itemType': 'control',
                'control.type': 'selection',
                'selection.style': 'explicit'
            },
            'employment': {
                'title': 'Employment',
                'itemType': 'control',
                'control.type': 'textEntry',
                'textEntry.type': 'wrappingPlainText'
            },
            'concussionAssessmentSection': {
                'title': 'Concussion Assessment',
                'itemType': 'section',
                'section.childIDs': [
                    'awptaCheck1Subform',
                    'awptaCheck2Subform',
                    'awptaCheck3Subform',
                    'awptaCheck4Subform',
                    'awptaCheck5Subform',
                    'bistSymptomScale'
                ]
            },
            // AWPTA Initial Check
            'awptaCheck1Subform': {
                'title': 'A-WPTA',
                'itemType': 'subform',
                'subform.sectionIDs': [
                    'awptaCheck1DateTimeSection',
                    'orientationCheck1Section',
                    'pictureCardTestRecollectionSection',
                ]
            },
            'pictureCardTestRecollectionSection': {
                'itemType': 'section',
                'section.childIDs': [
                    'pictureCardTestRecollectionSubform',
                ]
            },
            'pictureCardTestRecollectionSubform': {
                'title': 'Picture Card Test',
                'itemType': 'subform',
                'subform.sectionIDs': [
                    'pictureCardTestRecollection1Section',
                ]
            },
            'pictureCardTestRecollection1Section': {
                'itemType': 'section',
                'section.childIDs': [
                    'pictureCardTestRecollection',
                ]
            },
            'awptaCheck1DateTimeSection': {
                'title': 'Time',
                'itemType': 'section',
                'section.childIDs': [
                    'awptaWarning',
                    'awptaCheck1DateTime',
                ]
            },
            'awptaWarning': {
                'title': '',
                'detail': 'Caution applying AWPTA when baseline cognitive impairment is present',
                'itemType': 'control',
                'control.type': 'plain',
            },
            'awptaCheck1DateTime': {
                'title': 'Date and Time',
                'itemType': 'control',
                'control.type': 'picker',
                'picker.type': 'dateTime'
            },
            'orientationCheck1Section': {
                'itemType': 'section',
                'section.childIDs': [
                    'orientationCheck1Subform',
                    'gcsSubform'
                ]
            },
            'pictureCardTestRecollection': {
                'detail': 'Show patients the target set of picture cards for about 5 seconds and ensure that they can repeat the names of each card.\n\nTell the patient to remember the pictures for the next testing in about one hour.',
                'itemType': 'control',
                'control.type': 'pictureGridCheck1',
            },
            'orientationCheck1Subform': {
                'title': 'Orientation',
                'detail': 'Ask the patient the following orientation questions and indicate whether their answer is correct',
                'itemType': 'subform',
                'subform.sectionIDs': [
                    'orientationCheck1QuestionsSection'
                ]
            },
            'orientationCheck1QuestionsSection': {
                'itemType': 'section',
                'section.childIDs': [
                    'whatIsYourNameCheck1',
                    'nameOfThisPlaceCheck1',
                    'whyAreYouHereCheck1',
                    'whatMonthAreWeInCheck1',
                    'whatYearAreWeInCheck1'
                ]
            },
            'whatIsYourNameCheck1': {
                'title': 'What is your name?',
                'info': 'The patient must provide their full name.',
                'itemType': 'control',
                'control.type': 'selection',
                'selection.style': 'explicit'
            },
            'nameOfThisPlaceCheck1': {
                'title': 'What is the name of this place?',
                'info': 'The patient has to be able to give the name of the hospital. For example: Westmead Hospital. (NB: The patient does not get any points for just saying ‘hospital’.) If the patient can not name the hospital, give them a choice of 3 options. To do this, pick 2 other similar sized hospitals in your local area or neighbouring region. In Westmead Hospital’s case the 3 choices are ‘Nepean Hospital, Westmead Hospital or Liverpool Hospital’.',
                'itemType': 'control',
                'control.type': 'selection',
                'selection.style': 'explicit'
            },
            'whyAreYouHereCheck1': {
                'title': 'Why are you here?',
                'info': 'The patient must know why they were brought into hospital. e.g. they were injured in a car accident, fell, assaulted or injured playing sport. If the patient does not know, give them three options, including the correct reason.',
                'itemType': 'control',
                'control.type': 'selection',
                'selection.style': 'explicit'
            },
            'whatMonthAreWeInCheck1': {
                'title': 'What month are we in?',
                'info': 'For emphasis the examiner can ask what month are we in now? The patient must name the month. For example, if the patient answers ‘the 6th month’, the examiner must ask the further question ‘What is the 6th month called?’.',
                'itemType': 'control',
                'control.type': 'selection',
                'selection.style': 'explicit'
            },
            'whatYearAreWeInCheck1': {
                'title': 'What year are we in?',
                'info': 'It is considered correct for patients to answer in the short form ‘08’, instead of ‘2008’. Also, an acceptable alternative prompt (for the rest of the 2000’s) is ‘The year is 2000 and what?’',
                'itemType': 'control',
                'control.type': 'selection',
                'selection.style': 'explicit'
            },
            // AWPTA Check 2
            'awptaCheck2Subform': {
                'title': 'A-WPTA Check 1',
                'itemType': 'subform',
                'subform.sectionIDs': [
                    'awptaCheck2DateTimeSection',
                    'orientationCheck2Section',
                    'recollectionCheck2Section',
                    'recollectionFailureCheck2Section',
                ]
            },
            'awptaCheck2DateTimeSection': {
                'title': 'Time',
                'itemType': 'section',
                'section.childIDs': [
                    'awptaCheck2DateTime',
                ]
            },
            'awptaCheck2DateTime': {
                'title': 'Date and Time',
                'itemType': 'control',
                'control.type': 'picker',
                'picker.type': 'dateTime'
            },
            'orientationCheck2Section': {
                'itemType': 'section',
                'section.childIDs': [
                    'orientationCheck2Subform',
                    'gcsSubform'
                ]
            },
            'orientationCheck2Subform': {
                'title': 'Orientation',
                'detail': 'Ask the patient the following orientation questions and indicate whether their answer is correct',
                'itemType': 'subform',
                'subform.sectionIDs': [
                    'orientationCheck2QuestionsSection',
                ]
            },
            'recollectionCheck2Section': {
                'itemType': 'section',
                'section.childIDs': [
                    'recollectionCheck2Subform',
                ]
            },
            'recollectionCheck2Subform': {
                'title': 'Picture Card Test',
                'itemType': 'subform',
                'subform.sectionIDs': [
                    'recollectionCheck2Section2',
                ]
            },
            'recollectionCheck2Section2': {
                'itemType': 'section',
                'section.childIDs': [
                    'recollectionCheck2',
                ]
            },
            'recollectionCheck2': {
                'detail': 'Ask the patient to recall the images shown in the previous test. Select images correctly identified',
                'itemType': 'control',
                'control.type': 'pictureGrid'
            },
            'recollectionFailureCheck2Section': {
                'itemType': 'section',
                'section.childIDs': [
                    'recollectionFailureCheck2Subform',
                ]
            },
            'recollectionFailureCheck2Subform': {
                'title': 'Picture Card Test Follow Up',
                'itemType': 'subform',
                'subform.sectionIDs': [
                    'recollectionFailureCheck2Section2',
                ]
            },
            'recollectionFailureCheck2Section2': {
                'itemType': 'section',
                'section.childIDs': [
                    'recollectionFailureCheck2',
                ]
            },
            'recollectionFailureCheck2': {
                'detail': 'Ask the patient to recall the images shown in the previous test. Select images correctly identified',
                'itemType': 'control',
                'control.type': 'pictureGrid'
            },
            'orientationCheck2QuestionsSection': {
                'itemType': 'section',
                'section.childIDs': [
                    'whatIsYourNameCheck2',
                    'nameOfThisPlaceCheck2',
                    'whyAreYouHereCheck2',
                    'whatMonthAreWeInCheck2',
                    'whatYearAreWeInCheck2'
                ]
            },
            'whatIsYourNameCheck2': {
                'title': 'What is your name?',
                'info': 'The patient must provide their full name.',
                'itemType': 'control',
                'control.type': 'selection',
                'selection.style': 'explicit'
            },
            'nameOfThisPlaceCheck2': {
                'title': 'What is the name of this place?',
                'info': 'The patient has to be able to give the name of the hospital. For example: Westmead Hospital. (NB: The patient does not get any points for just saying ‘hospital’.) If the patient can not name the hospital, give them a choice of 3 options. To do this, pick 2 other similar sized hospitals in your local area or neighbouring region. In Westmead Hospital’s case the 3 choices are ‘Nepean Hospital, Westmead Hospital or Liverpool Hospital’.',
                'itemType': 'control',
                'control.type': 'selection',
                'selection.style': 'explicit'
            },
            'whyAreYouHereCheck2': {
                'title': 'Why are you here?',
                'info': 'The patient must know why they were brought into hospital. e.g. they were injured in a car accident, fell, assaulted or injured playing sport. If the patient does not know, give them three options, including the correct reason.',
                'itemType': 'control',
                'control.type': 'selection',
                'selection.style': 'explicit'
            },
            'whatMonthAreWeInCheck2': {
                'title': 'What month are we in?',
                'info': 'For emphasis the examiner can ask what month are we in now? The patient must name the month. For example, if the patient answers ‘the 6th month’, the examiner must ask the further question ‘What is the 6th month called?’.',
                'itemType': 'control',
                'control.type': 'selection',
                'selection.style': 'explicit'
            },
            'whatYearAreWeInCheck2': {
                'title': 'What year are we in?',
                'info': 'It is considered correct for patients to answer in the short form ‘08’, instead of ‘2008’. Also, an acceptable alternative prompt (for the rest of the 2000’s) is ‘The year is 2000 and what?’',
                'itemType': 'control',
                'control.type': 'selection',
                'selection.style': 'explicit'
            },
            // AWPTA Check 3
            'awptaCheck3Subform': {
                'title': 'AWPTA Check 2',
                'itemType': 'subform',
                'subform.sectionIDs': [
                    'awptaCheck3DateTimeSection',
                    'orientationCheck3Section',
                    'recollectionCheck3Section',
                    'recollectionFailureCheck3Section',
                ]
            },
            'awptaCheck3DateTimeSection': {
                'title': 'Time',
                'itemType': 'section',
                'section.childIDs': [
                    'awptaCheck3DateTime',
                ]
            },
            'awptaCheck3DateTime': {
                'title': 'Date and Time',
                'itemType': 'control',
                'control.type': 'picker',
                'picker.type': 'dateTime'
            },
            'orientationCheck3Section': {
                'itemType': 'section',
                'section.childIDs': [
                    'orientationCheck3Subform',
                    'gcsSubform'
                    
                ]
            },
            'orientationCheck3Subform': {
                'title': 'Orientation',
                'detail': 'Ask the patient the following orientation questions and indicate whether their answer is correct',
                'itemType': 'subform',
                'subform.sectionIDs': [
                    'orientationCheck3QuestionsSection',
                ]
            },
            'recollectionCheck3Section': {
                'itemType': 'section',
                'section.childIDs': [
                    'recollectionCheck3Subform',
                ]
            },
            'recollectionCheck3Subform': {
                'title': 'Picture Card Test',
                'itemType': 'subform',
                'subform.sectionIDs': [
                    'recollectionCheck3Section3',
                ]
            },
            'recollectionCheck3Section3': {
                'itemType': 'section',
                'section.childIDs': [
                    'recollectionCheck3',
                ]
            },
            'recollectionCheck3': {
                'detail': 'Ask the patient to recall the images shown in the previous test. Select images correctly identified',
                'itemType': 'control',
                'control.type': 'pictureGrid',
            },
            'recollectionFailureCheck3Section': {
                'itemType': 'section',
                'section.childIDs': [
                    'recollectionFailureCheck3Subform',
                ]
            },
            'recollectionFailureCheck3Subform': {
                'title': 'Picture Card Test Follow Up',
                'itemType': 'subform',
                'subform.sectionIDs': [
                    'recollectionFailureCheck3Section3',
                ]
            },
            'recollectionFailureCheck3Section3': {
                'itemType': 'section',
                'section.childIDs': [
                    'recollectionFailureCheck3',
                ]
            },
            'recollectionFailureCheck3': {
                'detail': 'Ask the patient to recall the images shown in the previous test. Select images correctly identified',
                'itemType': 'control',
                'control.type': 'pictureGrid',
            },
            'orientationCheck3QuestionsSection': {
                'itemType': 'section',
                'section.childIDs': [
                    'whatIsYourNameCheck3',
                    'nameOfThisPlaceCheck3',
                    'whyAreYouHereCheck3',
                    'whatMonthAreWeInCheck3',
                    'whatYearAreWeInCheck3',
                ]
            },
            'whatIsYourNameCheck3': {
                'title': 'What is your name?',
                'info': 'The patient must provide their full name.',
                'itemType': 'control',
                'control.type': 'selection',
                'selection.style': 'explicit'
            },
            'nameOfThisPlaceCheck3': {
                'title': 'What is the name of this place?',
                'info': 'The patient has to be able to give the name of the hospital. For example: Westmead Hospital. (NB: The patient does not get any points for just saying ‘hospital’.) If the patient can not name the hospital, give them a choice of 3 options. To do this, pick 2 other similar sized hospitals in your local area or neighbouring region. In Westmead Hospital’s case the 3 choices are ‘Nepean Hospital, Westmead Hospital or Liverpool Hospital’.',
                'itemType': 'control',
                'control.type': 'selection',
                'selection.style': 'explicit'
            },
            'whyAreYouHereCheck3': {
                'title': 'Why are you here?',
                'info': 'The patient must know why they were brought into hospital. e.g. they were injured in a car accident, fell, assaulted or injured playing sport. If the patient does not know, give them three options, including the correct reason.',
                'itemType': 'control',
                'control.type': 'selection',
                'selection.style': 'explicit'
            },
            'whatMonthAreWeInCheck3': {
                'title': 'What month are we in?',
                'info': 'For emphasis the examiner can ask what month are we in now? The patient must name the month. For example, if the patient answers ‘the 6th month’, the examiner must ask the further question ‘What is the 6th month called?’.',
                'itemType': 'control',
                'control.type': 'selection',
                'selection.style': 'explicit'
            },
            'whatYearAreWeInCheck3': {
                'title': 'What year are we in?',
                'info': 'It is considered correct for patients to answer in the short form ‘08’, instead of ‘2008’. Also, an acceptable alternative prompt (for the rest of the 2000’s) is ‘The year is 2000 and what?’',
                'itemType': 'control',
                'control.type': 'selection',
                'selection.style': 'explicit'
            },
            // AWPTA Check 4
            'awptaCheck4Subform': {
                'title': 'AWPTA Check 3',
                'itemType': 'subform',
                'subform.sectionIDs': [
                    'awptaCheck4DateTimeSection',
                    'orientationCheck4Section',
                    'recollectionCheck4Section',
                    'recollectionFailureCheck4Section',
                ]
            },
            'awptaCheck4DateTimeSection': {
                'title': 'Time',
                'itemType': 'section',
                'section.childIDs': [
                    'awptaCheck4DateTime'
                ]
            },
            'awptaCheck4DateTime': {
                'title': 'Date and Time',
                'itemType': 'control',
                'control.type': 'picker',
                'picker.type': 'dateTime'
            },
            'orientationCheck4Section': {
                'itemType': 'section',
                'section.childIDs': [
                    'orientationCheck4Subform',
                    'gcsSubform'
                ]
            },
            'orientationCheck4Subform': {
                'title': 'Orientation',
                'detail': 'Ask the patient the following orientation questions and indicate whether their answer is correct',
                'itemType': 'subform',
                'subform.sectionIDs': [
                    'orientationCheck4QuestionsSection',
                ]
            },
            'recollectionCheck4Section': {
                'itemType': 'section',
                'section.childIDs': [
                    'recollectionCheck4Subform',
                ]
            },
            'recollectionCheck4Subform': {
                'title': 'Picture Card Test',
                'itemType': 'subform',
                'subform.sectionIDs': [
                    'recollectionCheck4Section4',
                ]
            },
            'recollectionCheck4Section4': {
                'itemType': 'section',
                'section.childIDs': [
                    'recollectionCheck4',
                ]
            },
            'recollectionCheck4': {
                'detail': 'Ask the patient to recall the images shown in the previous test. Select images correctly identified',
                'itemType': 'control',
                'control.type': 'pictureGrid',
            },
            'recollectionFailureCheck4Section': {
                'itemType': 'section',
                'section.childIDs': [
                    'recollectionFailureCheck4Subform',
                ]
            },
            'recollectionFailureCheck4Subform': {
                'title': 'Picture Card Test Follow Up',
                'itemType': 'subform',
                'subform.sectionIDs': [
                    'recollectionFailureCheck4Section4',
                ]
            },
            'recollectionFailureCheck4Section4': {
                'itemType': 'section',
                'section.childIDs': [
                    'recollectionFailureCheck4',
                ]
            },
            'recollectionFailureCheck4': {
                'detail': 'Ask the patient to recall the images shown in the previous test. Select images correctly identified',
                'itemType': 'control',
                'control.type': 'pictureGrid',
            },
            'orientationCheck4QuestionsSection': {
                'itemType': 'section',
                'section.childIDs': [
                    'whatIsYourNameCheck4',
                    'nameOfThisPlaceCheck4',
                    'whyAreYouHereCheck4',
                    'whatMonthAreWeInCheck4',
                    'whatYearAreWeInCheck4',
                ]
            },
            'whatIsYourNameCheck4': {
                'title': 'What is your name?',
                'info': 'The patient must provide their full name.',
                'itemType': 'control',
                'control.type': 'selection',
                'selection.style': 'explicit'
            },
            'nameOfThisPlaceCheck4': {
                'title': 'What is the name of this place?',
                'info': 'The patient has to be able to give the name of the hospital. For example: Westmead Hospital. (NB: The patient does not get any points for just saying ‘hospital’.) If the patient can not name the hospital, give them a choice of 3 options. To do this, pick 2 other similar sized hospitals in your local area or neighbouring region. In Westmead Hospital’s case the 3 choices are ‘Nepean Hospital, Westmead Hospital or Liverpool Hospital’.',
                'itemType': 'control',
                'control.type': 'selection',
                'selection.style': 'explicit'
            },
            'whyAreYouHereCheck4': {
                'title': 'Why are you here?',
                'info': 'The patient must know why they were brought into hospital. e.g. they were injured in a car accident, fell, assaulted or injured playing sport. If the patient does not know, give them three options, including the correct reason.',
                'itemType': 'control',
                'control.type': 'selection',
                'selection.style': 'explicit'
            },
            'whatMonthAreWeInCheck4': {
                'title': 'What month are we in?',
                'info': 'For emphasis the examiner can ask what month are we in now? The patient must name the month. For example, if the patient answers ‘the 6th month’, the examiner must ask the further question ‘What is the 6th month called?’.',
                'itemType': 'control',
                'control.type': 'selection',
                'selection.style': 'explicit'
            },
            'whatYearAreWeInCheck4': {
                'title': 'What year are we in?',
                'info': 'It is considered correct for patients to answer in the short form ‘08’, instead of ‘2008’. Also, an acceptable alternative prompt (for the rest of the 2000’s) is ‘The year is 2000 and what?’',
                'itemType': 'control',
                'control.type': 'selection',
                'selection.style': 'explicit'
            },
            // AWPTA Check 5
            'awptaCheck5Subform': {
                'title': 'AWPTA Check 4',
                'itemType': 'subform',
                'subform.sectionIDs': [
                    'awptaCheck5DateTimeSection',
                    'orientationCheck5Section',
                    'recollectionCheck5Section',
                    'recollectionFailureCheck5Section',
                ]
            },
            'awptaCheck5DateTimeSection': {
                'title': 'Time',
                'itemType': 'section',
                'section.childIDs': [
                    'awptaCheck5DateTime',
                ]
            },
            'awptaCheck5DateTime': {
                'title': 'Date and Time',
                'itemType': 'control',
                'control.type': 'picker',
                'picker.type': 'dateTime'
            },
            'orientationCheck5Section': {
                'itemType': 'section',
                'section.childIDs': [
                    'orientationCheck5Subform',
                    'gcsSubform'
                ]
            },
            'orientationCheck5Subform': {
                'title': 'Orientation',
                'detail': 'Ask the patient the following orientation questions and indicate whether their answer is correct',
                'itemType': 'subform',
                'subform.sectionIDs': [
                    'orientationCheck5QuestionsSection',
                ]
            },
            'recollectionCheck5Section': {
                'itemType': 'section',
                'section.childIDs': [
                    'recollectionCheck5Subform',
                ]
            },
            'recollectionCheck5Subform': {
                'title': 'Picture Card Test',
                'itemType': 'subform',
                'subform.sectionIDs': [
                    'recollectionCheck5Section5',
                ]
            },
            'recollectionCheck5Section5': {
                'itemType': 'section',
                'section.childIDs': [
                    'recollectionCheck5',
                ]
            },
            'recollectionCheck5': {
                'detail': 'Ask the patient to recall the images shown in the previous test. Select images correctly identified',
                'itemType': 'control',
                'control.type': 'pictureGrid',
            },
            'recollectionFailureCheck5Section': {
                'itemType': 'section',
                'section.childIDs': [
                    'recollectionFailureCheck5Subform',
                ]
            },
            'recollectionFailureCheck5Subform': {
                'title': 'Picture Card Test Follow Up',
                'itemType': 'subform',
                'subform.sectionIDs': [
                    'recollectionFailureCheck5Section5',
                ]
            },
            'recollectionFailureCheck5Section5': {
                'itemType': 'section',
                'section.childIDs': [
                    'recollectionFailureCheck5',
                ]
            },
            'recollectionFailureCheck5': {
                'detail': 'Ask the patient to recall the images shown in the previous test. Select images correctly identified',
                'itemType': 'control',
                'control.type': 'pictureGrid',
            },
            'orientationCheck5QuestionsSection': {
                'itemType': 'section',
                'section.childIDs': [
                    'whatIsYourNameCheck5',
                    'nameOfThisPlaceCheck5',
                    'whyAreYouHereCheck5',
                    'whatMonthAreWeInCheck5',
                    'whatYearAreWeInCheck5',
                ]
            },
            'whatIsYourNameCheck5': {
                'title': 'What is your name?',
                'info': 'The patient must provide their full name.',
                'itemType': 'control',
                'control.type': 'selection',
                'selection.style': 'explicit'
            },
            'nameOfThisPlaceCheck5': {
                'title': 'What is the name of this place?',
                'info': 'The patient has to be able to give the name of the hospital. For example: Westmead Hospital. (NB: The patient does not get any points for just saying ‘hospital’.) If the patient can not name the hospital, give them a choice of 3 options. To do this, pick 2 other similar sized hospitals in your local area or neighbouring region. In Westmead Hospital’s case the 3 choices are ‘Nepean Hospital, Westmead Hospital or Liverpool Hospital’.',
                'itemType': 'control',
                'control.type': 'selection',
                'selection.style': 'explicit'
            },
            'whyAreYouHereCheck5': {
                'title': 'Why are you here?',
                'info': 'The patient must know why they were brought into hospital. e.g. they were injured in a car accident, fell, assaulted or injured playing sport. If the patient does not know, give them three options, including the correct reason.',
                'itemType': 'control',
                'control.type': 'selection',
                'selection.style': 'explicit'
            },
            'whatMonthAreWeInCheck5': {
                'title': 'What month are we in?',
                'info': 'For emphasis the examiner can ask what month are we in now? The patient must name the month. For example, if the patient answers ‘the 6th month’, the examiner must ask the further question ‘What is the 6th month called?’.',
                'itemType': 'control',
                'control.type': 'selection',
                'selection.style': 'explicit'
            },
            'whatYearAreWeInCheck5': {
                'title': 'What year are we in?',
                'info': 'It is considered correct for patients to answer in the short form ‘08’, instead of ‘2008’. Also, an acceptable alternative prompt (for the rest of the 2000’s) is ‘The year is 2000 and what?’',
                'itemType': 'control',
                'control.type': 'selection',
                'selection.style': 'explicit'
            },
            // BIST Scale
            'bistSymptomScale': {
                'title': 'BIST Symptom Scale',
                'itemType': 'subform',
                'subform.sectionIDs': [
                    'bistQuestionSection'
                ]
            },
            'bistQuestionSection': {
                'itemType': 'section',
                'section.childIDs': [
                    'bistDateTime',
                    'bistQuestionPrompt',
                    'bistScaleKey',
                    'physicalBistSubform',
                    'vestibularocularBistSubform',
                    'cognitiveBistSubform',
                    'post24HoursBistSubform'
                ]
            },
            'bistDateTime': {
                'title': 'Date and time',
                'itemType': 'control',
                'control.type': 'picker',
                'picker.type': 'dateTime'
            },
            'bistQuestionPrompt': {
                'title': 'Please ask the patient the following question',
                'detail': '“Compared with before the accident, please rate how much you experience the following symptoms”',
                'itemType': 'control',
                'control.type': 'plain'
            },
            'bistScaleKey': {
                'itemType': 'control',
                'control.type': 'scaleKey'
            },
            'physicalBistSubform': {
                'title': 'Physical',
                'itemType': 'subform',
                'subform.sectionIDs': [
                    'physicalBistSection'
                ]
            },
            'physicalBistSection': {
                'itemType': 'section',
                'section.childIDs': [
                    'headacheBistRating',
                    'neckHurtsBistRating',
                    'brightLightsBistRating',
                    'loudNoisesBistRating',
                ]
            },
            'headacheBistRating': {
                'title': 'Headache (my head hurts)',
                'itemType': 'control',
                'control.type': 'scale'
            },
            'neckHurtsBistRating': {
                'title': 'My neck hurts',
                'itemType': 'control',
                'control.type': 'scale'
            },
            'brightLightsBistRating': {
                'title': 'I don’t like bright lights',
                'itemType': 'control',
                'control.type': 'scale'
            },
            'loudNoisesBistRating': {
                'title': 'I don’t like loud noises',
                'itemType': 'control',
                'control.type': 'scale'
            },
            'vestibularocularBistSubform': {
                'title': 'Vestibularocular',
                'itemType': 'subform',
                'subform.sectionIDs': [
                    'vestibularocularBistSection'
                ]
            },
            'vestibularocularBistSection': {
                'itemType': 'section',
                'section.childIDs': [
                    'dizzySickBistRating',
                    'closedEyesAtSeaBistRating',
                    'troubleWithVisionBistRating',
                    'clumsyBistRating'
                ]
            },
            'dizzySickBistRating': {
                'title': 'I feel dizzy or like I could be sick',
                'itemType': 'control',
                'control.type': 'scale'
            },
            'closedEyesAtSeaBistRating': {
                'title': 'If I close my eyes, I feel like I am at sea',
                'itemType': 'control',
                'control.type': 'scale'
            },
            'troubleWithVisionBistRating': {
                'title': 'I have trouble with my eyesight (vision)',
                'itemType': 'control',
                'control.type': 'scale'
            },
            'clumsyBistRating': {
                'title': 'I feel clumsy (bumping into things or dropping things more than usual)',
                'itemType': 'control',
                'control.type': 'scale'
            },
            'cognitiveBistSubform': {
                'title': 'Cognitive',
                'itemType': 'subform',
                'subform.sectionIDs': [
                    'cognitiveBistSection'
                ]
            },
            'cognitiveBistSection': {
                'itemType': 'section',
                'section.childIDs': [
                    'longerToThinkBistRating',
                    'forgetThingsBistRating',
                    'confusedEasilyBistRating',
                    'troubleConcentratingBistRating'
                ]
            },
            'longerToThinkBistRating': {
                'title': 'It takes me longer to think',
                'itemType': 'control',
                'control.type': 'scale'
            },
            'forgetThingsBistRating':{
                'title': 'I forget things',
                'itemType': 'control',
                'control.type': 'scale'
            },
            'confusedEasilyBistRating':{
                'title': 'I get confused easily',
                'itemType': 'control',
                'control.type': 'scale'
            },
            'troubleConcentratingBistRating':{
                'title': 'I have trouble concentrating',
                'itemType': 'control',
                'control.type': 'scale'
            },
            'post24HoursBistSubform': {
                'title': 'Post 24 Hours',
                'itemType': 'subform',
                'subform.sectionIDs': [
                    'post24HoursBistSection'
                ]
            },
            'post24HoursBistSection': {
                'itemType': 'section',
                'section.childIDs': [
                    'angryOrIrritatedBistRating',
                    'dontFeelRightBistRating',
                    'tiredDuringDayBistRating',
                    'sleepMoreOrHardToSleepBistRating'
                ]
            },
            'angryOrIrritatedBistRating':{
                'title': 'I get angry or irritated easily',
                'itemType': 'control',
                'control.type': 'scale'
            },
            'dontFeelRightBistRating':{
                'title': 'I just don\'t feel right',
                'itemType': 'control',
                'control.type': 'scale'
            },
            'tiredDuringDayBistRating':{
                'title': 'I feel tired during the day',
                'itemType': 'control',
                'control.type': 'scale'
            },
            'sleepMoreOrHardToSleepBistRating':{
                'title': 'I need to sleep a lot more or find it hard to sleep at night',
                'itemType': 'control',
                'control.type': 'scale'
            },
            'investigationsSection': {
                'title': 'Investigations',
                'itemType': 'section',
                'section.childIDs': [
                    'gcsSubform',
                    'vitalSignsSubform',
                    'ctBrainSubform'
                ]
            },
            'gcsSubform': {
                'title': 'Neurological Observations',
                'itemType': 'subform',
                'subform.sectionIDs': [
                    'neuroObsOkayCheckSection',
                    'gcsSection',
                    'pupilAssessmentSection'
                ]
            },
            'neuroObsOkayCheckSection': {
                'itemType': 'section',
                'section.childIDs': [
                    'neuroObsDateTime',
                    'neuroObsOkayCheck'
                ]
            },
            'neuroObsDateTime': {
                'title': 'Date and time',
                'itemType': 'control',
                'control.type': 'picker',
                'picker.type': 'dateTime'
            },
            'neuroObsOkayCheck': {
                'title': 'Are the neurological observations normal?',
                'detail': 'The patient must have GCS15 with symmetrical and briskly reactive pupils',
                'itemType': 'control',
                'control.type': 'selection',
                'selection.style': 'explicit'
            },
            'gcsSection': {
                'title': 'Glasgow Coma Score',
                'itemType': 'section',
                'section.childIDs': [
                    'gcsSectionSubform'
                    
                ]
            },
            'gcsSectionSubform': {
                'title': 'Glasgow Coma Score',
                'itemType': 'subform',
                'subform.sectionIDs': [
                    'gcsSubformSection'
                ]
                
            },
            'gcsSubformSection': {
                'itemType': 'section',
                'section.childIDs': [
                    'eyeOpeningScore',
                    'verbalScore',
                    'motorScore',
                    'totalGCSScore'
                ]
            },
            'motorScore': {
                'title': 'Motor',
                'info': '6: Obeys commands \n 5: Localises \n 4: Withdraws \n 3: Abnormal flexion \n 2: Extension \n 1: None',
                'itemType': 'control',
                'control.type': 'selection',
                'selection.style': 'dropdown',
                'selection.options': [
                    'obeysCommands',
                    'localises',
                    'withdraws',
                    'abnormalFlexion',
                    'extension',
                    'none'
                ]
            },
            'totalGCSScore': {
                'title': 'Total GCS',
                'itemType': 'control',
                'control.type': 'badge'
            },
            'obeysCommands': {
                'title': 'Obeys commands',
                'itemType': 'control',
                'control.type': 'selection',
                'selection.style': 'explicit'
            },
            'localises': {
                'title': 'Localises',
                'itemType': 'control',
                'control.type': 'selection',
                'selection.style': 'explicit'
            },
            'withdraws': {
                'title': 'Withdraws',
                'itemType': 'control',
                'control.type': 'selection',
                'selection.style': 'explicit'
            },
            'abnormalFlexion': {
                'title': 'Abnormal flexion',
                'itemType': 'control',
                'control.type': 'selection',
                'selection.style': 'explicit'
            },
            'extension': {
                'title': 'Extension',
                'itemType': 'control',
                'control.type': 'selection',
                'selection.style': 'explicit'
            },
            'eyeOpeningScore': {
                'title': 'Eye Opening',
                'info': '4: Spontaneously \n 3: To speech \n 2: To pain \n 1: None',
                'itemType': 'control',
                'control.type': 'selection',
                'selection.style': 'dropdown',
                'selection.options': [
                    'spontaneously',
                    'toSpeech',
                    'toPain',
                    'none'
                ]
            },
            'none': {
                'title': 'None',
                'itemType': 'control',
                'control.type': 'selection',
                'selection.style': 'explicit'
            },
            'toPain': {
                'title': 'To pain',
                'itemType': 'control',
                'control.type': 'selection',
                'selection.style': 'explicit'
            },
            'toSpeech': {
                'title': 'To speech',
                'itemType': 'control',
                'control.type': 'selection',
                'selection.style': 'explicit'
            },
            'spontaneously': {
                'title': 'Spontaneously',
                'itemType': 'control',
                'control.type': 'selection',
                'selection.style': 'explicit'
            },
            'verbalScore': {
                'title': 'Verbal',
                'info': '5: Orientated\n4: Confused\n3: Inappropriate words\n2: Incomprehensible sounds\n1: None',
                'itemType': 'control',
                'control.type': 'selection',
                'selection.style': 'dropdown',
                'selection.options': [
                    'oriented',
                    'confused',
                    'inappropriateWords',
                    'incomprehensibleSounds',
                    'none'
                ]
            },
            'confused': {
                'title': 'Confused',
                'itemType': 'control',
                'control.type': 'selection',
                'selection.style': 'explicit'
            },
            'inappropriateWords': {
                'title': 'Inappropriate words',
                'itemType': 'control',
                'control.type': 'selection',
                'selection.style': 'explicit'
            },
            'incomprehensibleSounds': {
                'title': 'Incomprehensible sounds',
                'itemType': 'control',
                'control.type': 'selection',
                'selection.style': 'explicit'
            },
            'oriented': {
                'title': 'Oriented',
                'itemType': 'control',
                'control.type': 'selection',
                'selection.style': 'explicit'
            },
            'pupilAssessmentSection': {
                'title': 'Pupil Assessment',
                'itemType': 'section',
                'section.childIDs': [
                    'pupilAssessmentSubform'
                ]
            },
            'pupilAssessmentSubform': {
                'title': 'Pupil Assessment',
                'itemType': 'subform',
                'subform.sectionIDs': [
                    'leftPupilAssessment',
                    'rightPupilAssessment'
                ]
            },
            'leftPupilAssessment': {
                'title': 'Left',
                'itemType': 'section',
                'section.childIDs': [
                    'leftPupilSize',
                    'leftPupilReaction'
                ]
            },
            'rightPupilAssessment': {
                'title': 'Right',
                'itemType': 'section',
                'section.childIDs': [
                    'rightPupilSize',
                    'rightPupilReaction'
                ]
            },
            'leftPupilSize': {
                'title': 'Size',
                'itemType': 'control',
                'control.type': 'textEntry',
                'textEntry.type': 'decimalNumber',
                'textEntry.unitText': 'mm',
            },
            'leftPupilReaction': {
                'title': 'Reaction',
                'itemType': 'control',
                'control.type': 'selection',
                'selection.style': 'dropdown',
                'selection.options': [
                    'reactsBriskly',
                    'sluggish',
                    'closed',
                    'nil'
                ]
            },
            'rightPupilSize':{
                'title': 'Size',
                'itemType': 'control',
                'control.type': 'textEntry',
                'textEntry.type': 'decimalNumber',
                'textEntry.unitText': 'mm',
            },
            'rightPupilReaction': {
                'title': 'Reaction',
                'itemType': 'control',
                'control.type': 'selection',
                'selection.style': 'dropdown',
                'selection.options': [
                    'reactsBriskly',
                    'sluggish',
                    'closed',
                    'nil'
                ]
            },
            'reactsBriskly': {
                'title': 'Reacts briskly',
                'itemType': 'control',
                'control.type': 'selection',
                'selection.style': 'explicit'
            },
            'sluggish': {
                'title': 'Sluggish',
                'itemType': 'control',
                'control.type': 'selection',
                'selection.style': 'explicit'
            },
            'closed': {
                'title': 'Closed',
                'itemType': 'control',
                'control.type': 'selection',
                'selection.style': 'explicit'
            },
            'nil': {
                'title': 'Nil',
                'itemType': 'control',
                'control.type': 'selection',
                'selection.style': 'explicit'
            },
            'vitalSignsSubform': {
                'title': 'Vital Signs',
                'itemType': 'subform',
                'subform.sectionIDs': [
                    'normalVitalSignsCheckSection',
                    'recordVitalsSection'
                ]
            },
            'normalVitalSignsCheckSection': {
                'itemType': 'section',
                'section.childIDs': [
                    'vitalSignsDateTime',
                    'normalVitalSignsCheck'
                ]
            },
            'vitalSignsDateTime': {
                'title': 'Date and time',
                'itemType': 'control',
                'control.type': 'picker',
                'picker.type': 'dateTime'
            },
            'normalVitalSignsCheck': {
                'title': 'Are the vital signs normal? ',
                'detail': 'The patient must have normal vital signs with EWS score 0',
                'itemType': 'control',
                'control.type': 'selection',
                'selection.style': 'explicit'
            },
            'recordVitalsSection': {
                'title': 'Record vital signs',
                'itemType': 'section',
                'section.childIDs': [
                    'heartRate',
                    'bloodPressure',
                    'bodyTemperature',
                    'oxygenSaturation',
                    'respiratoryRate',
                    'supplementalOxygen',
                    'levelOfConsciousness'
                ]
            },
            'heartRate': {
                'title': 'Heart rate',
                'itemType': 'control',
                'control.type': 'textEntry',
                'textEntry.type': 'decimalNumber',
                'textEntry.unitText': 'bpm',
                'subcellIDs': [
                    'heartRateEWS'
                ]
            },
            'heartRateEWS': {
                'title': 'Early Warning Score',
                'itemType': 'control',
                'control.type': 'badge'
            },
            'bloodPressure': {
                'title': 'Blood pressure',
                'itemType': 'control',
                'control.type': 'picker',
                'picker.type': 'bloodPressure',
                'textEntry.unitText': 'mmHg',
                'subcellIDs': [
                    'bloodPressureEWS'
                ]
            },
            'bloodPressureEWS': {
                'title': 'Early Warning Score',
                'itemType': 'control',
                'control.type': 'badge'
            },
            'bodyTemperature': {
                'title': 'Body temperature',
                'itemType': 'control',
                'control.type': 'textEntry',
                'textEntry.type': 'decimalNumber',
                'textEntry.unitText': '℃',
                'subcellIDs': [
                    'bodyTemperatureEWS'
                ]
            },
            'bodyTemperatureEWS': {
                'title': 'Early Warning Score',
                'itemType': 'control',
                'control.type': 'badge'
            },
            'oxygenSaturation': {
                'title': 'Oxygen saturation (SpO₂)',
                'itemType': 'control',
                'control.type': 'textEntry',
                'textEntry.type': 'decimalNumber',
                'textEntry.unitText': '%',
                'subcellIDs': [
                    'oxygenSaturationEWS'
                ]
            },
            'oxygenSaturationEWS': {
                'title': 'Early Warning Score',
                'itemType': 'control',
                'control.type': 'badge'
            },
            'respiratoryRate': {
                'title': 'Respiratory rate',
                'itemType': 'control',
                'control.type': 'textEntry',
                'textEntry.type': 'decimalNumber',
                'textEntry.unitText': 'bpm',
                'subcellIDs': [
                    'respiratoryRateEWS'
                ]
            },
            'respiratoryRateEWS': {
                'title': 'Early Warning Score',
                'itemType': 'control',
                'control.type': 'badge'
            },
            'supplementalOxygen': {
                'title': 'Supplemental oxygen',
                'itemType': 'control',
                'control.type': 'selection',
                'selection.style': 'explicit',
                'subcellIDs': [
                    'supplementalOxygenEWS'
                ]
            },
            'supplementalOxygenEWS': {
                'title': 'Early Warning Score',
                'itemType': 'control',
                'control.type': 'badge'
            },
            'levelOfConsciousness': {
                'title': 'Level of consciousness',
                'itemType': 'control',
                'control.type': 'selection',
                'selection.style': 'simple',
                'selection.options': [
                    'responsivenessAlert',
                    'responsivenessVoiceOrPain',
                    'responsivenessUnresponsive'
                ],
                'subcellIDs': [
                    'levelOfConsciousnessEWS'
                ]
            },
            'levelOfConsciousnessEWS': {
                'title': 'Early Warning Score',
                'itemType': 'control',
                'control.type': 'badge'
            },
            'responsivenessAlert': {
                'title': 'Alert',
                'itemType': 'option'
            },
            'responsivenessVoiceOrPain': {
                'title': 'Voice or pain',
                'itemType': 'option'
            },
            'responsivenessUnresponsive': {
                'title': 'Unresponsive',
                'itemType': 'option'
            },
            'ctBrainSubform': {
                'title': 'CT Brain',
                'itemType': 'subform',
                'subform.sectionIDs': [
                    'ctBrainSection'
                ]
            },
            'ctBrainSection': {
                'title': 'CT Brain',
                'itemType': 'section',
                'section.childIDs': [
                    'hasCTBeenPerformed',
                    'isCTScanNormal'
                ]
            },
            'hasCTBeenPerformed': {
                'title': 'Has a CT scan been performed?',
                'itemType': 'control',
                'control.type': 'selection',
                'selection.style': 'explicit'
            },
            'isCTScanNormal': {
                'title': 'Is the CT scan normal?',
                'itemType': 'control',
                'control.type': 'selection',
                'selection.style': 'explicit',
                'subcellIDs': [
                    'ctScanFindings'
                ]
            },
            'ctScanFindings': {
                'title': 'CT Findings',
                'itemType': 'control',
                'control.type': 'textEntry',
                'textEntry.type': 'wrappingPlainText'
            },
            'resultsForm': {
                'title': 'Insights',
                'itemType': 'subform',
                'subform.sectionIDs': [
                    'redFlagsSection',
                    'calculatedInsightsSection',
                    'diagnosisSection',
                    'investigationSection',
                    'managementSection',
                    'referralSection',
                    'patientInfoSection'
                ]
            },
            'diagnosisSection': {
                'title': 'Diagnosis',
                'itemType': 'section',
                'section.childIDs': [
                    'diagnosisConcussionAction',
                    'diagnosisConcussionConfoudningFactorsAction'
                ]
            },
            'diagnosisConcussionAction': {
                'title': 'Concussion',
                'detail': 'Mild Traumatic Brain Injury',
                'itemType': 'control',
                'control.type': 'action'
            },
            'diagnosisConcussionConfoudningFactorsAction': {
                'title': 'Concussion',
                'detail': 'Mild traumatic brain injury possible. Confounding factors are present',
                'itemType': 'control',
                'control.type': 'action'
            },
            'redFlagsSection': {
                'title': 'Red Flags',
                'detail': 'Findings requiring urgent assessment and management',
                'itemType': 'section',
                'section.childIDs': [
                    'highRiskFindingsSubform',
                    'invalidArrivalGCSWarning'
                ]
            },
            'highRiskFindingsSubform': {
                'title': 'Red Flags',
                'itemType': 'subform',
                'subform.sectionIDs': [
                    'highRiskFindingsSection',
                ]
            },
            'highRiskFindingsSection': {
                'title': '',
                'itemType': 'section',
                'section.childIDs': [
                    'reducedGCSRedFlag',
                    'ewsCriteriaRedflag',
                    'symptomsWorseSinceInjuryRedflag',
                    'ctCriteraRedflag'
                ]
            },
            'ewsCriteriaRedflag': {
                'title': 'Early Warning Score Alert',
                'itemType': 'control',
                'control.type': 'redflag'
            },
            'ctCriteraRedflag': {
                'title': 'CT Head Criteria',
                'itemType': 'control',
                'control.type': 'redflag'
            },
            'symptomsWorseSinceInjuryRedflag': {
                'title': 'Symptoms worse since injury',
                'itemType': 'control',
                'control.type': 'redflag'
            },
            'reducedGCSRedFlag': {
                'title': 'Reduced GCS',
                'itemType': 'control',
                'control.type': 'redflag'
            },
            'calculatedInsightsSection': {
                'title': 'Calculated Insights',
                'itemType': 'section',
                'section.childIDs': [
                    'awptaScoreCalculation', 
                    'bistScoreCalculation',
                    'bistDominiantSymptomCluster',
                    'concussionRiskCategory'
                ]
            },
            'awptaScoreCalculation': {
                'title': 'A-WPTA Score',
                'itemType': 'control',
                'control.type': 'calculation',
            }, 
            'bistScoreCalculation': {
                'title': 'BIST Score',
                'itemType': 'control',
                'control.type': 'calculation',
            },
            'bistDominiantSymptomCluster': {
                'title': 'BIST Dominant Symptom Cluster',
                'itemType': 'control',
                'control.type': 'calculation',
            },
            'concussionRiskCategory': {
                'title': 'Concussion Risk Category',
                'itemType': 'control',
                'control.type': 'calculation',
            },
            'investigationSection': {
                'title': 'Investigation',
                'itemType': 'section',
                'section.childIDs': [
                    'ctHead'
                ]
            },
            'ctHead': {
                'title': 'Consider CT Head',
                'detail': 'Discuss with senior doctor. Add CT of cervical spine or face if clinical concern',
                'itemType': 'control',
                'control.type': 'action'
            },
            'managementSection': {
                'title': 'Management',
                'itemType': 'section',
                'section.childIDs': [
                    'seniorMedicalReview',
                    'seekNeuroscienceReview',
                    'neuroObsManagement'
                ]
            },
            'seniorMedicalReview': {
                'title': 'Senior Medical Review',
                'detail': 'Specialist or SMO assessment and management is recommended. Ensure Red flags are resolved before continuing this pathway or planning discharge.',
                'itemType': 'control',
                'control.type': 'action'
            },
            'seekNeuroscienceReview': {
                'title': 'Request Neuroscience & Occupational Therapy review',
                'detail': 'Admission should be considered for all abnormal CT imaging, persistent PTA >4 hours or significant persistent symptoms',
                'itemType': 'control',
                'control.type': 'action'
            },
            'neuroObsManagement': {
                'title': 'Neuro obs',
                'detail': 'Interval',
                'itemType': 'control',
                'control.type': 'action'
            },
            'referralSection': {
                'title': 'Discharge Plan',
                'itemType': 'section',
                'section.childIDs': [
                    'accReferralLowRiskAction',
                    'accReferralModerateRiskAction', 
                    // 'physioTherapistReferralAction',
                    'referralAction'
                ]
            },
            'accReferralModerateRiskAction': {
                'title': 'Request ACC Concussion Service follow-up',
                'detail': 'This person is at moderate risk of poor recovery. Request ACC concussion service follow-up.',
                'itemType': 'control',
                'control.type': 'action'
            },
            'accReferralLowRiskAction': {
                'title': 'Request GP Follow-up',
                'detail': 'This person is at low risk of poor recovery. Consider referral to GP for review in 7-10 days.',
                'itemType': 'control',
                'control.type': 'action'
            },
            // 'physioTherapistReferralAction': {
            //     'title': 'Request Physiotherapy or Concussion Service follow-up',
            //     'detail': 'Consider referral to Concussion Service or a Physiotherapist. This person is likely to be at moderate risk of poor recovery.',
            //     'itemType': 'control',
            //     'control.type': 'action'
            // },
            'patientInfoSection': {
                'title': 'Patient Information',
                'itemType': 'section',
                'section.childIDs': [
                    'patientInfoLink',
                ]
            },
            'referralAction': {
                'title': 'Submit Referral to ACC',
                'detail': 'Request ACC follow-up when this is recommended, or if there is a particular clinical concern such as no primary care provider available. Please ensure patient details including ACC claim number, injury details and clinical assessment are complete.',
                'itemType': 'control',
                'control.type': 'accReferralSubmission'
            },
            'patientInfoLink': {
                'title': 'Concussion Advice',
                'detail': 'Provide written concussion advice',
                'itemType': 'control',
                'control.type': 'weblink',
                'weblink': 'https://www.acc.co.nz/assets/im-injured/acc8319-concussion-education-sheet.pdf'
            },
        },
        'insightsDependancies': [

        ]
    }
}