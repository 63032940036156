export class ACCMRIPathwayReference {
    static data = {
        'title': 'ACC MRI Request',
        'detail': 'Placeholder Detail',
        'itemType': 'template',
        'template.version': 1.0,
        'template.versionUuid': 'osteoporosisInitialAssessment',
        'template.createText': 'Use This Pathway',
        'template.introID': 'introForm',
        'template.contentTabIDs': ['mainForm', 'outcome'],
        'template.defaultContentTabID': 'mainForm',
        'template.content': {
            'introForm': {
                'title': 'ACC MRI Request',
                'detail': 'This request form is designed to validate the workflow for requesting an MRI via ACC for:\n- Cervical spine injury\n- Lumbar spine injury\n- Post traumatic knee pain',
                'itemType': 'subform',
                'subform.sectionIDs': [

                ]
            },
            'mainForm': {
                'title': 'Request Pathway',
                'itemType': 'subform',
                'subform.sectionIDs': [
                    'requestCriteriaSection',
                    'assessmentSection'
                ]
            },
            
            /// REQUEST CRITERIA
            'requestCriteriaSection': {
                'title': 'Request criteria',
                'itemType': 'section',
                'section.childIDs': [
                    'requestCriteria',
                ]
            },
            'requestCriteria':{
                'title': 'Request criteria',
                'itemType': 'subform',
                'subform.sectionIDs': [
                    'reasonForRequest',
                    'mriContraindications',
                    'cautions',
                    'cautionsManagementRecommendation'
                ]
            },
            // REQUEST CRITERIA > REASON FOR REQUEST
            'reasonForRequest':{
                'title': 'Reason for request',
                'detail': 'Select one:',
                'section.childIDs': [
                    'cervicalSpineInjury',
                    'ghjOrInstability',
                    'lumbarSpineInjury',
                    'postTraumaticKneePain',
                    'hiddenRequestReasonTracker'
                ]
            },
            'hiddenRequestReasonTracker':{
                'title': 'Request reason selected',
                'itemType': 'control',
                'control.type': 'radio',
            },
            'cervicalSpineInjury':{
                'title': 'Cervical spine injury',
                'itemType': 'control',
                'control.type': 'radio',
            },
            'ghjOrInstability':{
                'title': 'Gleno-humeral joint pain or instability',
                'itemType': 'control',
                'control.type': 'radio',
            },
            'lumbarSpineInjury':{
                'title': 'Lumbar spine injury',
                'itemType': 'control',
                'control.type': 'radio',
            },
            'postTraumaticKneePain':{
                'title': 'Post traumatic knee pain',
                'itemType': 'control',
                'control.type': 'radio',
            },

            // REQUEST CRITERIA > MRI CONTRAINDICATIONS
            'mriContraindications':{
                'title': 'MRI contra-indications',
                'section.childIDs': [
                    'pacemaker',
                ]
            },
            'pacemaker': {
                'title': 'Pacemaker',
                'itemType': 'control',
                'control.type': 'selection',
                'selection.style': 'explicit'
            },

            // REQUEST CRITERIA > CAUTIONS
            'cautions':{
                'title': 'Cautions',
                'section.childIDs': [
                    'metalware',
                    'cochlearImplants',
                    'claustrophobia',
                    'worksWithMetalOrbitCheck',
                ]
            },
            'metalware': {
                'title': 'Metalware',
                'itemType': 'control',
                'control.type': 'selection',
                'selection.style': 'explicit'
            },
            'cochlearImplants': {
                'title': 'Cochlear implants',
                'itemType': 'control',
                'control.type': 'selection',
                'selection.style': 'explicit'
            },
            'claustrophobia': {
                'title': 'Claustrophobia',
                'itemType': 'control',
                'control.type': 'selection',
                'selection.style': 'explicit'
            },
            'worksWithMetalOrbitCheck': {
                'title': 'Works with metal - Orbit check',
                'itemType': 'control',
                'control.type': 'selection',
                'selection.style': 'explicit'
            },

            // REQUEST CRITERIA > CAUTIONS - Recommendations
            'cautionsManagementRecommendation':{
                'title': 'MANAGEMENT RECOMMENDATION',
                'itemType': 'section', // 'inline-recommendation'
                'section.style': 'inline',
                'section.childIDs': [
                    'contactRadiologyProvider',
                ]
            },
            'contactRadiologyProvider': {
                'title': 'Contact radiology provider due to cautions or contra-indications',
                'itemType': 'control',
                'control.type': 'completionItem',
                'subcellIDs': [
                    'radiologyClearedPatient',
                    'radiologyClearedPatientFurtherInformation'
                ]
            },
            'radiologyClearedPatient': {
                'title': 'Has the radiology provider cleared the patient for MRI?',
                'itemType': 'control',
                'control.type': 'selection',
                'selection.style': 'explicit'
            },
            'radiologyClearedPatientFurtherInformation': {
                'title': 'Further information',
                'detail': 'Name, phone/email, date & time contacted',
                'itemType': 'control',
                'control.type': 'textEntry',
                'textEntry.type': 'wrappingPlainText'
            },
            
            /// ASSESSMENT
            'assessmentSection': {
                'title': 'Assessment',
                'itemType': 'section',
                'section.childIDs': [
                    'assessment',
                ]
            },
            'assessment':{
                'title': 'Assessment',
                'itemType': 'subform',
                'subform.sectionIDs': [
                    'assessmentMoreInformationRequiredSection',
                    'assessmentPatientNotClearedSection',

                    // LSI = LUMBAR SPINE INJURY
                    // CSI = CERVICAL SPINE INJURY
                    // GH  = GLENO-HUMERAL JOINT PAIN OR INSTABILITY
                    // KP  = POST TRAUMATIC KNEE PAIN

                    /// RED FLAGS
                    'LSI_RedFlags',
                    'LSI_RF_ManagementRecommendation',
                    
                    'CSI_RedFlags',
                    'CSI_RF_ManagementRecommendation',

                    'GH_RedFlags',
                    'GH_RF_ManagementRecommendation',
                    
                    // KP has no red flags

                    /// FURTHER ASSESSMENT
                    'LSI_FurtherAssessmentSection', 
                    'LSI_BackPainSection',
                    'LSI_BP_ManagementRecommendation',
                    'LSI_BP_ImagingRecommendation',
                    'LSI_LegPainSection',
                    'LSI_LP_ImagingRecommendation',
                    'LSI_LP_ManagementRecommendation',

                    'CSI_FurtherAssessmentSection1',
                    'CSI_FA1_ImagingRecommendation',
                    'CSI_FA1_ManagementRecommendation',
                    'CSI_FurtherAssessmentSection2',
                    'CSI_FA2_ImagingRecommendation',
                    'CSI_FA2_ManagementRecommendation',
                    
                    'GH_FA1_provisionalClinicalDiagnosis',
                    'GH_FA1_ImagingRecommendation',
                    'GH_FA1_ManagementRecommendation',
                    'GH_FA2_surgicalCandidateSection',
                    'GH_FA2_ManagementRecommendationSection1',
                    'GH_FA2_ManagementRecommendationSection2',
                    'GH_FA2_ImagingRecommendation',
                    'GH_FA2_ManagementRecommendationSection3',

                    'KP_FurtherAssessmentSection1',
                    'KP_FA1_ImagingRecommendation',
                    'KP_FA1_ManagementRecommendation',
                    'KP_FA2_AssessmentFindingsSection1',
                    'KP_LK_PCL_ManagementRecommendation',
                    'KP_LK_ACL_LCL_ImagingRecommendation',
                    'KP_ML_ImagingRecommendation',
                    'KP_ML_ManagementRecommendation',
                    'KP_ML2_ImagingRecommendation',
                    'KP_ML2_ManagementRecommendation',
                    'KP_MCL_PCL_ImagingRecommendation',
                    'KP_MCL_PCL_ManagementRecommendation'
                ]
            },

            'assessmentMoreInformationRequiredSection': {
                'title': 'More information required',
                'section.childIDs': [
                    'hiddenShouldDisplayAssessmentFormTracker',
                    'assessmentMoreInformationRequired',
                ]
            },
            'hiddenShouldDisplayAssessmentFormTracker': {
                'itemType': 'control',
                'control.type': 'selection',
                'selection.style': 'explicit',
            },
            'assessmentMoreInformationRequired': {
                'title': 'Please complete the Request criteria section of this form. This improves the likelihood of request approval and provides complete clinical information for test reporting.',
                'itemType': 'control',
                'control.type': 'plain'
            },

            'assessmentPatientNotClearedSection': {
                'title': 'Patient not cleared for MRI',
                'section.childIDs': [
                    'radiologyHasNotClearedPatientForMRI',
                ]
            },
            'radiologyHasNotClearedPatientForMRI': {
                'title': 'Radiology has not cleared the patient for MRI.',
                'itemType': 'control',
                'control.type': 'plain'
            },

            // ASSESSMENT > LUMBAR SPINE INJURY

            // ASSESSMENT > LSI > URGENT RED FLAGS
            'LSI_RedFlags': {
                'title': 'Red flags',
                'itemType': 'section',
                'section.childIDs': [
                    'LSI_featuresOfCaudaEquinaSyndrome',
                    'LSI_abdominalAorticAneurysm',
                    'LSI_infection',
                    'LSI_cancer',
                    'LSI_fracture',
                    'LSI_spondyloarthropathy',
                    'LSI_RF_NoToAll'
                ]
            },
            'LSI_featuresOfCaudaEquinaSyndrome': {
                'title': 'Features of cauda equina syndrome (CES)',
                'detail': 'Urinary retention, faecal incontinence, widespread neurological symptoms and signs in the lower limb, including gait abnormality, saddle area numbness and a lax anal sphincter',
                'itemType': 'control',
                'control.type': 'selection',
                'selection.style': 'explicit',
            },
            'LSI_abdominalAorticAneurysm': {
                'title': 'Abdominal aortic aneurysm',
                'itemType': 'control',
                'control.type': 'selection',
                'selection.style': 'explicit',
            },
            'LSI_infection': {
                'title': 'Infection',
                'detail': 'Fever plus source of infection, recent surgery, steroids, IV drug use, immunocompromised',
                'itemType': 'control',
                'control.type': 'selection',
                'selection.style': 'explicit',
            },
            'LSI_cancer': {
                'title': 'Cancer',
                'detail': 'Relevant history of cancer or strong clinical suspicion, significant weight loss',
                'itemType': 'control',
                'control.type': 'selection',
                'selection.style': 'explicit',
            },
            'LSI_fracture': {
                'title': 'Fracture',
                'detail': 'Risk factors include female, over 70 years, steroid use, significant trauma for age, and skin abrasion',
                'itemType': 'control',
                'control.type': 'selection',
                'selection.style': 'explicit',
            },
            'LSI_spondyloarthropathy': {
                'title': 'Spondyloarthropathy',
                'detail': 'Managed according to #@^HealthPathways > Low Back Pain^https://canterbury.communityhealthpathways.org/67189.htm#@ or #@^American College of Rheumatology guidelines^https://www.rheumatology.org/Practice-Quality/Clinical-Support/Clinical-Practice-Guidelines/Axial-Spondyloarthritis#@',
                'itemType': 'control',
                'control.type': 'selection',
                'selection.style': 'explicit',
            },
            'LSI_RF_NoToAll':{
                'title': 'red flags',
                'itemType': 'control',
                'control.type': 'noToAll'
            },
            
            // ASSESSMENT > LSI > RED FLAGS - Recommendations
            'LSI_RF_ManagementRecommendation': {
                'title': 'MANAGEMENT RECOMMENDATION',
                'itemType': 'section', // 'inline-recommendation'
                'section.style': 'inline',
                'section.childIDs': [
                    'LSI_RF_arrangeAcuteAssessment',
                    'LSI_RF_requestNonAcuteSpecialistAssessment',
                    'LSI_RF_managePerAcuteClinicalManagementPathways'
                ]
            },
            'LSI_RF_arrangeAcuteAssessment': {
                'title': 'Arrange acute assessment',
                'detail': 'You have indicated an urgent red flag which is a medical emergency and requires urgent hospital referral. No further MRI recommendation will be made',
                'itemType': 'control',
                'control.type': 'badge'
            },
            'LSI_RF_requestNonAcuteSpecialistAssessment': {
                'title': 'Request non-acute specialist assessment (if fails to improve)',
                'detail': 'You have indicated a red flag. No further MRI recommendation will be made',
                'itemType': 'control',
                'control.type': 'plain'
            },
            'LSI_RF_managePerAcuteClinicalManagementPathways': {
                'title': 'Manage according to acute clinical management pathways for 6 weeks',
                'itemType': 'control',
                'control.type': 'completionItem',
                'subcellIDs': [
                    'LSI_persistentPain'
                ]
            },

            // ASSESSMENT > LSI > FURTHER ASSESSMENT
            'LSI_FurtherAssessmentSection': {
                'title': 'Further assessment',
                'itemType': 'section',
                'section.childIDs': [
                    'LSI_areaOfDominantPain'
                ]
            },
            'LSI_persistentPain': {
                'title': 'Does this patient have persistent pain 6 weeks post-injury that has shown no improvement since initial presentation?',
                'itemType': 'control',
                'control.type': 'selection',
                'selection.style': 'explicit',
            },
            'LSI_areaOfDominantPain': {
                'title': 'Specify the area of dominant pain',
                'itemType': 'control',
                'control.type': 'selection',
                'selection.style': 'simple',
                'selection.options': [
                    'LSI_areaOfDominantPainBack',
                    'LSI_areaOfDominantPainLeg',
                ]
            },
            'LSI_areaOfDominantPainBack': {
                'title': 'Back',
                'itemType': 'option'
            },
            'LSI_areaOfDominantPainLeg': {
                'title': 'Leg',
                'itemType': 'option'
            },
            
            // ASSESSMENT > LSI > FURTHER ASSESSMENT > BACK PAIN
            'LSI_BackPainSection': {
                'title': 'Provisional clinical diagnosis',
                'detail': 'Are the patients symptoms and signs suggestive of:',
                'itemType': 'section',
                'section.childIDs': [
                    'LSI_lowerBackPain',
                    'LSI_somaticReferredBackPain',
                ]
            },
            'LSI_lowerBackPain': {
                'title': 'Lower back pain',
                'detail': 'Pain located around the spine only',
                'itemType': 'control',
                'control.type': 'selection',
                'selection.style': 'explicit',
            },
            'LSI_somaticReferredBackPain': {
                'title': 'Somatic referred back pain',
                'detail': 'Pain a dull ache, gnawing. Refers generally to buttock and posterior upper thigh, rarely below the knee. Pain location does not alter once established',
                'itemType': 'control',
                'control.type': 'selection',
                'selection.style': 'explicit',
            },
            
            // ASSESSMENT > LSI > FURTHER ASSESSMENT > LEG PAIN
            'LSI_LegPainSection': {
                'title': 'Provisional clinical diagnosis',
                'detail': 'Are the patients symptoms and signs suggestive of:',
                'itemType': 'section',
                'section.childIDs': [
                    'LSI_radiculopathy',
                    'LSI_RadicularPain'
                ]
            },
            'LSI_radiculopathy': {
                'title': 'Radiculopathy',
                'detail': 'Neurological abnormalities consistent with dermatomal or #@~myotomal nerve root distribution~images/LSI_radiculopathy.png#@.\nWith or without reflex abnormalities',
                'itemType': 'control',
                'control.type': 'selection',
                'selection.style': 'explicit',
                'subcellIDs': [
                    'LSI_detailNeurologicalAbnormalities'
                ]
            },
            'LSI_detailNeurologicalAbnormalities': {
                'title': 'Please detail neurological abnormalities',
                'detail': 'Including indication of affected side(s)',
                'itemType': 'control',
                'control.type': 'textEntry',
                'textEntry.type': 'wrappingPlainText'
            },
            'LSI_RadicularPain': {
                'title': 'Radicular pain',
                'itemType': 'control',
                'control.type': 'selection',
                'selection.style': 'explicit',
                'subcellIDs': [
                    'LSI_RP_Criteria',
                    'LSI_RP_detailFindings'
                ]
            },

            // ASSESSMENT > LSI > FURTHER ASSESSMENT > LEG PAIN > RADICULAR PAIN CRITERIA
            'LSI_RP_Criteria': {
                'title': 'Radicular pain criteria ',
                'itemType': 'subform',
                'subform.type': 'suggestive',
                'subform.sectionIDs': [
                    'LSI_RP_CriteriaSection'
                ],
            },
            'LSI_RP_CriteriaSection': {
                'itemType': 'section',
                'section.childIDs': [
                    'LSI_lancinatingNervePainRadiatingDownArmWithinNarrowConfine',
                    'LSI_uncomfortableAtNightWithPain',
                    'LSI_positiveStraightLegRaiseTest',
                    'LSI_positiveSlumpTest',
                    'LSI_positiveFemoralNerveStretchTest',
                    'LSI_RP_NoToAll'
                ]
            },
            'LSI_lancinatingNervePainRadiatingDownArmWithinNarrowConfine': {
                'title': 'Lancinating nerve pain radiating down the limb with a narrow confine',
                'itemType': 'control',
                'control.type': 'selection',
                'selection.style': 'explicit'
            },
            'LSI_uncomfortableAtNightWithPain': {
                'title': 'Uncomfortable at night with this pain',
                'itemType': 'control',
                'control.type': 'selection',
                'selection.style': 'explicit'
            },
            'LSI_positiveStraightLegRaiseTest': {
                'title': 'Positive straight leg raise test',
                'itemType': 'control',
                'control.type': 'selection',
                'selection.style': 'explicit'
            },
            'LSI_positiveSlumpTest': {
                'title': 'Positive slump test',
                'itemType': 'control',
                'control.type': 'selection',
                'selection.style': 'explicit'
            },
            'LSI_positiveFemoralNerveStretchTest': {
                'title': 'Positive femoral nerve stretch test (L2/3)',
                'itemType': 'control',
                'control.type': 'selection',
                'selection.style': 'explicit'
            },
            'LSI_RP_NoToAll':{
                'title': 'criteria',
                'itemType': 'control',
                'control.type': 'noToAll'
            },

            'LSI_RP_detailFindings': {
                'title': 'Please detail findings',
                'detail': 'Including indication of affected side(s)',
                'itemType': 'control',
                'control.type': 'textEntry',
                'textEntry.type': 'wrappingPlainText'
            },

            // ASSESSMENT > LSI > FURTHER ASSESSMENT > BACK PAIN - Recommendations
            'LSI_BP_ManagementRecommendation': {
                'title': 'MANAGEMENT RECOMMENDATION',
                'itemType': 'section', // 'inline-recommendation'
                'section.style': 'inline',
                'section.childIDs': [
                    'LSI_BP_painManagementAndRehabilitation',
                ]
            },
            'LSI_BP_painManagementAndRehabilitation': {
                'title': 'Pain management and rehabilitation',
                'detail': 'Refer to local pathways for management. If pathways are not available, request specialist advice',
                'itemType': 'control',
                'control.type': 'plain'
            },
            'LSI_BP_ImagingRecommendation': {
                'title': 'IMAGING RECOMMENDATION',
                'itemType': 'section', // 'inline-recommendation'
                'section.style': 'inline',
                'section.childIDs': [
                    'LSI_BP_xRayIfClinicalConcern',
                ]
            },
            'LSI_BP_xRayIfClinicalConcern': {
                'title': 'X-ray',
                'detail': 'Only if clinical concern regarding pathology',
                'itemType': 'control',
                'control.type': 'plain'
            },

            // ASSESSMENT > LSI > FURTHER ASSESSMENT > LEG PAIN - Recommendations
            'LSI_LP_ImagingRecommendation': {
                'title': 'IMAGING RECOMMENDATION',
                'itemType': 'section', // 'inline-recommendation'
                'section.style': 'inline',
                'section.childIDs': [
                    'LSI_LP_MRI',
                ]
            },
            'LSI_LP_MRI': {
                'title': 'MRI',
                'itemType': 'control',
                'control.type': 'plain'
            },

            'LSI_LP_ManagementRecommendation': {
                'title': 'MANAGEMENT RECOMMENDATION',
                'itemType': 'section', // 'inline-recommendation'
                'section.style': 'inline',
                'section.childIDs': [
                    'LSI_LP_referToSpecialist',
                    'LSI_LP_painManagementAndRehabilitation'
                ]
            },
            'LSI_LP_referToSpecialist': {
                'title': 'Consider referral to specialist if MRI confirms nerve root involvement',
                'detail': 'Otherwise, pain management and rehabilitation, and refer to local pathways for management. If pathways are not available, request specialist advice',
                'itemType': 'control',
                'control.type': 'plain'
            },
            'LSI_LP_painManagementAndRehabilitation': {
                'title': 'Pain management and rehabilitation',
                'detail': 'Refer to local pathways for management. If pathways are not available, request specialist advice',
                'itemType': 'control',
                'control.type': 'plain'
            },

            // ASSESSMENT > CERVICAL SPINE INJURY

            // ASSESSMENT > CSI > URGENT RED FLAGS
            'CSI_RedFlags': {
                'title': 'Red flags',
                'itemType': 'section',
                'section.childIDs': [
                    'CSI_severeWorseningUnrelentingPainEspeciallyAtNightOrWhenLyingDown',
                    'CSI_bilateralSignsAndSymptoms',
                    'CSI_severeNeurologicalDysfunction',
                    'CSI_infection',
                    'CSI_cancer',
                    'CSI_fracture',
                    'CSI_RF_NoToAll'
                ]
            },
            'CSI_severeWorseningUnrelentingPainEspeciallyAtNightOrWhenLyingDown': {
                'title': 'Severe worsening/unrelenting pain, especially at night or when lying down',
                'itemType': 'control',
                'control.type': 'selection',
                'selection.style': 'explicit',
            },
            'CSI_bilateralSignsAndSymptoms': {
                'title': 'Bilateral signs and symptoms',
                'itemType': 'control',
                'control.type': 'selection',
                'selection.style': 'explicit',
            },
            'CSI_severeNeurologicalDysfunction': {
                'title': 'Severe neurological dysfunction',
                'detail': 'Significant weakness not due to pain, significant or unexplained sensory deficit',
                'itemType': 'control',
                'control.type': 'selection',
                'selection.style': 'explicit',
            },
            'CSI_infection': {
                'title': 'Infection',
                'detail': 'Fever plus source of infection, recent surgery, steroids, IV drug use, immunocompromised',
                'itemType': 'control',
                'control.type': 'selection',
                'selection.style': 'explicit',
            },
            'CSI_cancer': {
                'title': 'Cancer',
                'detail': 'Relevant history of cancer or strong clinical suspicion, significant weight loss',
                'itemType': 'control',
                'control.type': 'selection',
                'selection.style': 'explicit',
            },
            'CSI_fracture': {
                'title': 'Fracture',
                'detail': 'Risk factors include steroid use, significant trauma for age',
                'itemType': 'control',
                'control.type': 'selection',
                'selection.style': 'explicit',
            },
            'CSI_RF_NoToAll':{
                'title': 'red flags',
                'itemType': 'control',
                'control.type': 'noToAll'
            },
            
            // ASSESSMENT > CSI > URGENT RED FLAGS - Recommendations
            'CSI_RF_ManagementRecommendation': {
                'title': 'MANAGEMENT RECOMMENDATION',
                'itemType': 'section', // 'inline-recommendation'
                'section.style': 'inline',
                'section.childIDs': [
                    'CSI_RF_arrangeAcuteAssessment',
                ]
            },
            'CSI_RF_arrangeAcuteAssessment': {
                'title': 'Arrange acute assessment',
                'detail': 'You have indicated an urgent red flag which is a medical emergency and requires urgent hospital referral. No further MRI recommendation will be made',
                'itemType': 'control',
                'control.type': 'badge'
            },

            // ASSESSMENT > CSI > FURTHER ASSESSMENT > STEP 1
            'CSI_FurtherAssessmentSection1': {
                'title': 'Further assessment',
                'itemType': 'section',
                'section.childIDs': [
                    'CSI_canadianCSpineRuleRecommendsImaging',
                ]
            },
            'CSI_canadianCSpineRuleRecommendsImaging': {
                'title': 'Does the Canadian C-Spine Rule recommend X-ray?',
                'detail': '#@~Calculate this~images/CSI_canadian_c-spine_rule.png#@',
                'itemType': 'control',
                'control.type': 'selection',
                'selection.style': 'explicit'
            },

            // ASSESSMENT > CSI > FURTHER ASSESSMENT > STEP 1 - Recommendations
            'CSI_FA1_ImagingRecommendation': {
                'title': 'IMAGING RECOMMENDATION',
                'itemType': 'section', // 'inline-recommendation'
                'section.style': 'inline',
                'section.childIDs': [
                    'CSI_FA1_xRay',
                ]
            },
            'CSI_FA1_xRay': {
                'title': 'X-ray',
                'itemType': 'control',
                'control.type': 'completionItem',
                'subcellIDs': [
                    'CSI_xRayConfirmsFracture',
                    'CSI_xRayConfirmsFractureProvideDetails'
                ]
            },
            'CSI_xRayConfirmsFracture': {
                'title': 'Does the X-ray confirm a fracture?',
                'itemType': 'control',
                'control.type': 'selection',
                'selection.style': 'explicit',
            },
            'CSI_xRayConfirmsFractureProvideDetails': {
                'title': 'Provide details',
                'detail': 'Including indication of affected side(s)',
                'itemType': 'control',
                'control.type': 'textEntry',
                'textEntry.type': 'wrappingPlainText'
            },

            'CSI_FA1_ManagementRecommendation': {
                'title': 'MANAGEMENT RECOMMENDATION',
                'itemType': 'section', // 'inline-recommendation'
                'section.style': 'inline',
                'section.childIDs': [
                    'CSI_FA1_arrangeAcuteAssessment',
                    'CSI_FA1_painManagementAndActiveRehabilitation',
                ]
            },
            'CSI_FA1_arrangeAcuteAssessment': {
                'title': 'Arrange acute assessment',
                'itemType': 'control',
                'control.type': 'plain'
            },
            'CSI_FA1_painManagementAndActiveRehabilitation': {
                'title': 'Pain management and rehabilitation for 6 weeks',
                'itemType': 'control',
                'control.type': 'completionItem',
                'subcellIDs': [
                    'CSI_FA2_persistentPain'
                ]
            },
            'CSI_FA2_persistentPain': {
                'title': 'Does this patient have persistent pain 6 weeks post-injury that has shown no improvement since initial presentation?',
                'itemType': 'control',
                'control.type': 'selection',
                'selection.style': 'explicit',
            },
            
            // ASSESSMENT > CSI > FURTHER ASSESSMENT > STEP 2
            'CSI_FurtherAssessmentSection2': {
                'title': 'Provisional clinical diagnosis',
                'detail': 'Are the patients symptoms and signs suggestive of:',
                'itemType': 'section',
                'section.childIDs': [
                    'CSI_FA2_radiculopathy',
                    'CSI_FA2_RadicularPain'
                ]
            },
            'CSI_FA2_radiculopathy': {
                'title': 'Radiculopathy',
                'detail': 'Neurological abnormalities consistent with #@~dermatomal or myotomal nerve root distribution~images/CSI_radiculopathy.png#@.\nWith or without reflex abnormalities',
                'itemType': 'control',
                'control.type': 'selection',
                'selection.style': 'explicit',
                'subcellIDs': [
                    'CSI_FA2_detailNeurologicalAbnormalities'
                ]
            },
            'CSI_FA2_detailNeurologicalAbnormalities': {
                'title': 'Please detail neurological abnormalities',
                'detail': 'Including indication of affected side(s)',
                'itemType': 'control',
                'control.type': 'textEntry',
                'textEntry.type': 'wrappingPlainText'
            },
            'CSI_FA2_RadicularPain': {
                'title': 'Radicular pain',
                'itemType': 'control',
                'control.type': 'selection',
                'selection.style': 'explicit',
                'subcellIDs': [
                    'CSI_RP_Criteria',
                    'CSI_RP_detailFindings'
                ]
            },

            // ASSESSMENT > CSI > FURTHER ASSESSMENT > STEP 2 > RADICULAR PAIN CRITERIA
            'CSI_RP_Criteria': {
                'title': 'Radicular pain criteria',
                'itemType': 'subform',
                'subform.type': 'suggestive',
                'subform.sectionIDs': [
                    'CSI_RP_CriteriaSection'
                ],
            },
            'CSI_RP_CriteriaSection': {
                'itemType': 'section',
                'section.childIDs': [
                    'CSI_lancinatingNervePainRadiatingDownArmWithinNarrowConfine',
                    'CSI_uncomfortableAtNightWithPain',
                    'CSI_positiveSpurlingTest',
                    'CSI_RP_NoToAll'
                ]
            },
            'CSI_lancinatingNervePainRadiatingDownArmWithinNarrowConfine': {
                'title': 'Lancinating nerve pain radiating down the limb with a narrow confine',
                'itemType': 'control',
                'control.type': 'selection',
                'selection.style': 'explicit'
            },
            'CSI_uncomfortableAtNightWithPain': {
                'title': 'Uncomfortable at night with this pain',
                'itemType': 'control',
                'control.type': 'selection',
                'selection.style': 'explicit'
            },
            'CSI_positiveSpurlingTest': {
                'title': 'Positive Spurling test',
                'detail': 'Spurling test has good reliability when performed in the sitting position. High specificity but low sensitivity',
                'itemType': 'control',
                'control.type': 'selection',
                'selection.style': 'explicit'
            },
            'CSI_RP_NoToAll':{
                'title': 'criteria',
                'itemType': 'control',
                'control.type': 'noToAll'
            },
            
            'CSI_RP_detailFindings': {
                'title': 'Please detail findings',
                'detail': 'Including indication of affected side(s)',
                'itemType': 'control',
                'control.type': 'textEntry',
                'textEntry.type': 'wrappingPlainText'
            },

            // ASSESSMENT > CSI > FURTHER ASSESSMENT > STEP 2 - Recommendations
            'CSI_FA2_ImagingRecommendation': {
                'title': 'IMAGING RECOMMENDATION',
                'itemType': 'section', // 'inline-recommendation'
                'section.style': 'inline',
                'section.childIDs': [
                    'CSI_FA2_mri',
                ]
            },
            'CSI_FA2_mri': {
                'title': 'MRI',
                'itemType': 'control',
                'control.type': 'plain'
            },
            'CSI_FA2_ManagementRecommendation': {
                'title': 'MANAGEMENT RECOMMENDATION',
                'itemType': 'section', // 'inline-recommendation'
                'section.style': 'inline',
                'section.childIDs': [
                    'CSI_FA2_painManagementAndRehabilitation',
                    'CSI_FA2_referToSpecialist'
                ]
            },
            'CSI_FA2_painManagementAndRehabilitation': {
                'title': 'Pain management and rehabilitation',
                'detail': 'Refer to local pathways for management. If pathways are not available, request specialist advice.\nIf no improvement at 3 months discuss with specialist',
                'itemType': 'control',
                'control.type': 'plain'
            },
            'CSI_FA2_referToSpecialist': {
                'title': 'Consider referral to specialist if MRI confirms nerve root involvement',
                'detail': 'Otherwise, pain management and rehabilitation, and refer to local pathways for management. If pathways are not available, request specialist advice',
                'itemType': 'control',
                'control.type': 'plain'
            },

            // GLENO-HUMERAL JOINT PAIN OR INSTABILITY > URGENT RED FLAGS
            'GH_RedFlags': {
                'title': 'Red flags',
                'itemType': 'section',
                'section.childIDs': [
                    'GH_unexplainedDeformityOrSwelling',
                    'GH_pulmonaryOrVascularCompromise',
                    'GH_bilateralNeurologicalSymptoms',
                    'GH_severeNeurologicalDysfunction',
                    'GH_infection',
                    'GH_cancer',
                    'GH_fracture',
                    'GH_RF_NoToAll'
                ]
            },
            'GH_unexplainedDeformityOrSwelling': {
                'title': 'Unexplained deformity or swelling',
                'itemType': 'control',
                'control.type': 'selection',
                'selection.style': 'explicit',
            },
            'GH_pulmonaryOrVascularCompromise': {
                'title': 'Pulmonary or vascular compromise',
                'itemType': 'control',
                'control.type': 'selection',
                'selection.style': 'explicit',
            },
            'GH_bilateralNeurologicalSymptoms': {
                'title': 'Bilateral neurological signs and symptoms',
                'itemType': 'control',
                'control.type': 'selection',
                'selection.style': 'explicit',
            },
            'GH_severeNeurologicalDysfunction': {
                'title': 'Severe neurological dysfunction',
                'detail': 'Significant weakness not due to pain, significant or unexplained sensory deficit',
                'itemType': 'control',
                'control.type': 'selection',
                'selection.style': 'explicit',
            },
            'GH_infection': {
                'title': 'Infection',
                'detail': 'Fever plus source of infection, recent surgery, steroids, IV drug use, immunocompromised',
                'itemType': 'control',
                'control.type': 'selection',
                'selection.style': 'explicit',
            },
            'GH_cancer': {
                'title': 'Cancer',
                'detail': 'Relevant history of cancer or strong clinical suspicion, significant weight loss',
                'itemType': 'control',
                'control.type': 'selection',
                'selection.style': 'explicit',
            },
            'GH_fracture': {
                'title': 'Fracture',
                'detail': 'Risk factors include steroid use, significant trauma for age',
                'itemType': 'control',
                'control.type': 'selection',
                'selection.style': 'explicit',
            },
            'GH_RF_NoToAll':{
                'title': 'red flags',
                'itemType': 'control',
                'control.type': 'noToAll'
            },

            // ASSESSMENT > GH > URGENT RED FLAGS - Recommendations
            'GH_RF_ManagementRecommendation': {
                'title': 'MANAGEMENT RECOMMENDATION',
                'itemType': 'section', // 'inline-recommendation'
                'section.style': 'inline',
                'section.childIDs': [
                    'GH_RF_arrangeAcuteAssessment',
                ]
            },
            'GH_RF_arrangeAcuteAssessment': {
                'title': 'Arrange acute assessment',
                'detail': 'You have indicated an urgent red flag which is a medical emergency and requires urgent hospital referral. No further MRI recommendation will be made',
                'itemType': 'control',
                'control.type': 'badge'
            },

            // ASSESSMENT > GH > FUTHER ASSESSMENT > Stage 1 / PROVISIONAL CLINICAL DIAGNOSIS
            'GH_FA1_provisionalClinicalDiagnosis': {
                'title': 'Provisional clinical diagnosis',
                'detail': 'Are the patients symptoms and signs suggestive of:',
                'itemType': 'section',
                'section.childIDs': [
                    'GH_suspicionOfFractureOrGHJI',
                    'GH_rotatorCuffTear',
                    'GH_subacromialBursitisImpingementSyndrome',
                    'GH_osteoarthritis',
                    'GH_frozenShoulder',
                    'GH_clinicalDiagnosisNoToAll'
                ]
            },
            'GH_suspicionOfFractureOrGHJI': {
                'title': 'Fracture or gleno-humeral joint instability',
                'detail': 'Including direct trauma, dislocation, or subluxation episodes',
                'itemType': 'control',
                'control.type': 'selection',
                'selection.style': 'explicit',
            },
            'GH_rotatorCuffTear': {
                'title': 'Rotator cuff tear',
                'detail': 'Symptoms: Age greater than 35 years, upper arm pain, night pain\nSigns: painful arc, limited active ROM, full passive ROM, possible weakness, positive impingement signs, limited active ROM (shoulder abduction and external rotation predominantly)',
                'itemType': 'control',
                'control.type': 'selection',
                'selection.style': 'explicit',
            },
            'GH_subacromialBursitisImpingementSyndrome': {
                'title': 'Subacromial bursitis / Impingement syndrome',
                'detail': 'Search for an underlying cause such as ACJ pathology, rotator cuff pathology, scapulothoracic dyskinesis or altered biomechanics to explain symptoms',
                'itemType': 'control',
                'control.type': 'selection',
                'selection.style': 'explicit',
            },
            'GH_osteoarthritis': {
                'title': 'Osteoarthritis',
                'detail': 'Signs: Decreased external rotation',
                'itemType': 'control',
                'control.type': 'selection',
                'selection.style': 'explicit',
            },
            'GH_frozenShoulder': {
                'title': 'Frozen shoulder',
                'detail': 'Symptoms: Gradual onset, increasing severity of pain, possible diabetes.\nSigns: global limitation active ROM, limited passive ROM',
                'itemType': 'control',
                'control.type': 'selection',
                'selection.style': 'explicit',
            },
            'GH_clinicalDiagnosisNoToAll':{
                'title': 'criteria',
                'itemType': 'control',
                'control.type': 'noToAll'
            },

            // GH PROVISIONAL CLINICAL DIAGNOSIS - Recommendations
            'GH_FA1_ImagingRecommendation': {
                'title': 'IMAGING RECOMMENDATION',
                'itemType': 'section', // 'inline-recommendation'
                'section.style': 'inline',
                'section.childIDs': [
                    'GH_FA1_xRays',
                    'GH_FA1_xRaysUltrasound',
                ]
            },
            'GH_FA1_xRays': {
                'title': 'X-ray',
                'detail': 'Include instability views where appropriate',
                'itemType': 'control',
                'control.type': 'completionItem',
                'subcellIDs': [
                    'GH_FA1_xRayConfirmsFracture',
                    'GH_FA1_xRayConfirmsFractureProvideDetails'
                ]
            },
            'GH_FA1_xRayConfirmsFracture': {
                'title': 'Does the X-ray confirm a fracture?',
                'itemType': 'control',
                'control.type': 'selection',
                'selection.style': 'explicit',
            },
            'GH_FA1_xRayConfirmsFractureProvideDetails': {
                'title': 'Provide details',
                'detail': 'Including indication of affected side(s)',
                'itemType': 'control',
                'control.type': 'textEntry',
                'textEntry.type': 'wrappingPlainText'
            },
            'GH_FA1_xRaysUltrasound': {
                'title': 'X-ray & Ultrasound',
                'detail': 'USS has higher sensitivity & specificity in the detection and staging of rotator cuff tears',
                'itemType': 'control',
                'control.type': 'completionItem',
                'subcellIDs': [
                    'GH_massiveRotatorCuffTear',
                    'GH_massiveRotatorCuffTearProvideDetails'
                ]
            },
            'GH_massiveRotatorCuffTear': {
                'title': 'Does the Ultrasound confirm a Massive rotator cuff tear >3cm, or tears involving 2 or more tendons?',
                'detail': 'Usually supraspinatus and infraspinatus, but also supraspinatus and subscapularis',
                'itemType': 'control',
                'control.type': 'selection',
                'selection.style': 'explicit',
            },
            'GH_massiveRotatorCuffTearProvideDetails': {
                'title': 'Provide details',
                'detail': 'Including indication of affected side(s)',
                'itemType': 'control',
                'control.type': 'textEntry',
                'textEntry.type': 'wrappingPlainText'
            },

            'GH_FA1_ManagementRecommendation': {
                'title': 'MANAGEMENT RECOMMENDATION',
                'itemType': 'section', // 'inline-recommendation'
                'section.style': 'inline',
                'section.childIDs': [
                    'GH_FA1_arrangeAcuteAssessment',
                    'GH_FA1_painManagementAndActiveRehabilitation'
                ]
            },
            'GH_FA1_arrangeAcuteAssessment': {
                'title': 'Arrange acute assessment',
                'itemType': 'control',
                'control.type': 'plain'
            },
            'GH_FA1_painManagementAndActiveRehabilitation': {
                'title': 'Pain management and active rehabilitation',
                'detail': 'Refer to local pathways for management. If pathways are not available, request specialist advice',
                'itemType': 'control',
                'control.type': 'plain'
            },

            // ASSESSMENT > GH > FUTHER ASSESSMENT > Stage 2 / MASSIVE ROTATOR CUFF
            'GH_FA2_surgicalCandidateSection': {
                'itemType': 'section',
                'section.childIDs': [
                    'GH_physiologicallyHealthyActiveAndSurgicalCandidate'
                ]
            },
            'GH_physiologicallyHealthyActiveAndSurgicalCandidate': {
                'title': 'Is the patient physiologically healthy and active and would be considered a surgical candidate?',
                'itemType': 'control',
                'control.type': 'selection',
                'selection.style': 'explicit',
            },

            // ASSESSMENT > GH > FUTHER ASSESSMENT > Stage 2 / MASSIVE ROTATOR CUFF - Recommendations
            'GH_FA2_ManagementRecommendationSection1': {
                'title': 'MANAGEMENT RECOMMENDATION',
                'itemType': 'section', // 'inline-recommendation'
                'section.style': 'inline',
                'section.childIDs': [
                    'GH_FA2_requestSpecialistAssessment'
                ]
            },
            'GH_FA2_requestSpecialistAssessment': {
                'title': 'Request specialist assessment',
                'detail': 'Contact a specialist to verify if an MRI is recommended',
                'itemType': 'control',
                'control.type': 'completionItem',
                'subcellIDs': [
                    'GH_hasSpecialistRecommendedMRI',
                    'GH_hasSpecialistRecommendedMRIProvideDetails'
                ]
            },
            'GH_hasSpecialistRecommendedMRI': {
                'title': 'Has the specialist recommended the patient for MRI?',
                'itemType': 'control',
                'control.type': 'selection',
                'selection.style': 'explicit',
            },
            'GH_hasSpecialistRecommendedMRIProvideDetails': {
                'title': 'Provide details',
                'detail': 'Specialist name, contact information (email / phone) and date recommended for MRI',
                'itemType': 'control',
                'control.type': 'textEntry',
                'textEntry.type': 'wrappingPlainText'
            },

            'GH_FA2_ManagementRecommendationSection2': {
                'title': 'MANAGEMENT RECOMMENDATION',
                'itemType': 'section', // 'inline-recommendation'
                'section.style': 'inline',
                'section.childIDs': [
                    'GH_FA2_painManagementAndActiveRehabilitationSection2',
                    'GH_FA2_considerReferalToSpecialistRotatorCuff'
                ]
            },
            'GH_FA2_painManagementAndActiveRehabilitationSection2': {
                'title': 'Pain management and active rehabilitation',
                'detail': 'Refer to local pathways for management. If pathways are not available, request specialist advice',
                'itemType': 'control',
                'control.type': 'plain'
            },
            'GH_FA2_considerReferalToSpecialistRotatorCuff': {
                'title': 'Consider referral to specialist',
                'detail': 'If there is persistent pain after completion of an appropriate rehabilitation programme and ongoing functional or physical impairment.\nIf full thickness tear, refer at 6 weeks if no improvement.\nIf tendinosis / partial thickness tear, refer at 6 months',
                'itemType': 'control',
                'control.type': 'plain',
                'subcellIDs': [
                    'GH_FA2_persistentPain'
                ]
            },
            'GH_FA2_persistentPain': {
                'title': 'Persistent pain',
                'detail': 'Rotator cuff: If full thickness tear, refer at 6 weeks if no improvement BUT for tendinosis / partial thickness tear refer at 6 months\nFrozen shoulder: Specialist referral may be indicated for additional clinical management if required\nOsteoarthritis: Specialist referral may be indicated for uncontrolled pain and functional limitation\nImpingement syndrome: Treat underlying cause first, prior to referral to specialist if indicated',
                'itemType': 'control',
                'control.type': 'plain'
            },

            'GH_FA2_ImagingRecommendation': {
                'title': 'IMAGING RECOMMENDATION',
                'itemType': 'section', // 'inline-recommendation'
                'section.style': 'inline',
                'section.childIDs': [
                    'GH_FA2_xRaysUltrasound',
                ]
            },
            'GH_FA2_xRaysUltrasound': {
                'title': 'X-ray & Ultrasound',
                'detail': 'If clinical concern regarding pathology',
                'itemType': 'control',
                'control.type': 'plain'
            },

            'GH_FA2_ManagementRecommendationSection3': {
                'title': 'MANAGEMENT RECOMMENDATION',
                'itemType': 'section', // 'inline-recommendation'
                'section.style': 'inline',
                'section.childIDs': [
                    'GH_FA2_painManagementAndActiveRehabilitationSection3',
                    'GH_FA2_considerReferalToSpecialistVariableDiagnosis'

                ]
            },
            'GH_FA2_painManagementAndActiveRehabilitationSection3': {
                'title': 'Pain management and active rehabilitation',
                'detail': 'Refer to local pathways for management. If pathways are not available, request specialist advice',
                'itemType': 'control',
                'control.type': 'plain'
            },
            'GH_FA2_considerReferalToSpecialistVariableDiagnosis': {
                'title': 'Consider referral to specialist',
                'itemType': 'control',
                'control.type': 'plain'
            },

            // ASSESSMENT > POST TRAUMATIC KNEE PAIN
            'KP_FurtherAssessmentSection1': {
                'title': 'Initial assessment',
                'itemType': 'section',
                'section.childIDs': [
                    'KP_ottawaKneeRulesRecommendImaging'
                ]
            },

            // ASSESSMENT > KP > FUTHER ASSESSMENT - STAGE 1
            'KP_ottawaKneeRulesRecommendImaging': {
                'title': 'Do the Ottawa Knee Rules recommend X-ray?',
                'itemType': 'control',
                'control.type': 'selection',
                'selection.style': 'explicit',
                'subcellIDs': [
                    'KP_OKR_Criteria',
                    'KP_OKR_xrayRecommendedRecommendation',
                    'KP_OKR_xrayNotRecommendedRecommendation'
                ]
            },
            'KP_OKR_Criteria': {
                'title': 'Ottawa Knee Rules criteria',
                'itemType': 'subform',
                'subform.type': 'suggestive',
                'subform.sectionIDs': [
                    'KP_OKR_CriteriaSection',
                ],
            },
            'KP_OKR_CriteriaSection': {
                'itemType': 'section',
                'section.childIDs': [
                    'KP_OKR_overAge',
                    'KP_OKR_tendernessOfPatella',
                    'KP_OKR_tendernessOfFibula',
                    'KP_OKR_inabilityToFlex',
                    'KP_OKR_inabilityToBearWeight'
                ]
            },
            'KP_OKR_overAge': {
                'title': 'Age ≥ 55',
                'itemType': 'control',
                'control.type': 'selection',
                'selection.style': 'explicit',
            },
            'KP_OKR_tendernessOfPatella': {
                'title': 'Isolated tenderness of patella',
                'detail': 'No bone tenderness of knee other than patella',
                'itemType': 'control',
                'control.type': 'selection',
                'selection.style': 'explicit',
            },
            'KP_OKR_tendernessOfFibula': {
                'title': 'Tenderness of head of fibula',
                'itemType': 'control',
                'control.type': 'selection',
                'selection.style': 'explicit',
            },
            'KP_OKR_inabilityToFlex': {
                'title': 'Inability to flex to 90°',
                'itemType': 'control',
                'control.type': 'selection',
                'selection.style': 'explicit',
            },
            'KP_OKR_inabilityToBearWeight': {
                'title': 'Inability to bear weight both immediately after injury and in the ED for 4 steps',
                'detail': 'Unable to transfer weight twice onto each lower limb regardless of limping',
                'itemType': 'control',
                'control.type': 'selection',
                'selection.style': 'explicit',
            },
            'KP_OKR_xrayRecommendedRecommendation': {
                'title': 'X-ray is recommended',
                'itemType': 'control',
                'control.type': 'plain'
            },
            'KP_OKR_xrayNotRecommendedRecommendation': {
                'title': 'X-ray is not recommended',
                'itemType': 'control',
                'control.type': 'plain'
            },

            // ASSESSMENT > KP > FUTHER ASSESSMENT - STAGE 1 - Recommendations
            'KP_FA1_ImagingRecommendation': {
                'title': 'IMAGING RECOMMENDATION',
                'itemType': 'section', // 'inline-recommendation'
                'section.style': 'inline',
                'section.childIDs': [
                    'KP_FA1_xRays',
                ]
            },
            'KP_FA1_xRays': {
                'title': 'X-ray',
                'itemType': 'control',
                'control.type': 'completionItem',
                'subcellIDs': [
                    'KP_xRayConfirmsFracture',
                    'KP_suspectOccultFracture',
                    'KP_xRayProvideDetails'
                ]
            },
            'KP_xRayConfirmsFracture': {
                'title': 'Does the X-ray confirm a fracture?',
                'itemType': 'control',
                'control.type': 'selection',
                'selection.style': 'explicit',
            },
            'KP_suspectOccultFracture': {
                'title': 'Do you suspect occult fracture?',
                'itemType': 'control',
                'control.type': 'selection',
                'selection.style': 'explicit',
            },
            'KP_xRayProvideDetails': {
                'title': 'Provide details',
                'detail': 'Including indication of affected side(s)',
                'itemType': 'control',
                'control.type': 'textEntry',
                'textEntry.type': 'wrappingPlainText'
            },

            'KP_FA1_ManagementRecommendation': {
                'title': 'MANAGEMENT RECOMMENDATION',
                'itemType': 'section', // 'inline-recommendation'
                'section.style': 'inline',
                'section.childIDs': [
                    'KP_FA1_acuteAssessment',
                ]
            },
            'KP_FA1_acuteAssessment': {
                'title': 'Arrange acute assessment',
                'itemType': 'control',
                'control.type': 'plain'
            },

            // ASSESSMENT > KP > FA2 > ASSESSMENT FINDINGS - Stage 1
            'KP_FA2_AssessmentFindingsSection1': {
                'title': 'Provisional clinical diagnosis',
                'detail': 'Assess for ligamentous or meniscal pathology. Are the patients symptoms and signs suggestive of:',
                'itemType': 'section',
                'section.childIDs': [
                    'KP_acutelyLockedKnee',
                    'KP_suspectAclLclRupture',
                    'KP_suspectMeniscalLesion',
                    'KP_suspectMCLPCLRupture',
                    'KP_suspectPLCInjury',
                    'KP_additionalInformation',
                ]
            },
            'KP_acutelyLockedKnee': {
                'title': 'Acutely locked knee',
                'itemType': 'control',
                'control.type': 'selection',
                'selection.style': 'explicit'
            },

            // ASSESSMENT > KP > FA2 > AF2 > ANTERIOR CRUCIATE LIGAMENT
            'KP_suspectAclLclRupture': {
                'title': 'ACL / LCL injury',
                'itemType': 'control',
                'control.type': 'selection',
                'selection.style': 'explicit',
                'subcellIDs': [
                    'KP_ACL_LCL_Criteria'
                ]
            },
            'KP_ACL_LCL_Criteria': {
                'title': 'ACL / LCL criteria',
                'itemType': 'subform',
                'subform.type': 'suggestive',
                'subform.sectionIDs': [
                    'KP_ACL_CriteriaSection',
                    'KP_LCL_CriteriaSection'
                ],
            },
            'KP_ACL_CriteriaSection': {
                'title': 'Anterior cruciate ligament (ACL) injury criteria',
                'itemType': 'section',
                'section.childIDs': [
                    'KP_ACL_injuryMechanism',
                    'KP_ACL_symptoms',
                    'KP_ACL_signs',
                    'KP_ACL_lachmanTestPositive',
                    'KP_ACL_anteriorDrawTestPositive',
                    'KP_ACL_NoToAll'
                ]
            },
            'KP_ACL_injuryMechanism': {
                'title': 'Injury mechanism',
                'detail': 'Deceleration, change of direction on a fixed foot, rotational, twisting',
                'itemType': 'control',
                'control.type': 'selection',
                'selection.style': 'explicit'
            },
            'KP_ACL_symptoms': {
                'title': 'Symptoms',
                'detail': 'Rapid onset of swelling within hours, audible ‘pop’ or noise within the knee at the time of injury, feeling of instability',
                'itemType': 'control',
                'control.type': 'selection',
                'selection.style': 'explicit'
            },
            'KP_ACL_signs': {
                'title': 'Signs',
                'detail': 'Effusion often large within 2-3 hours, loss of end range extension',
                'itemType': 'control',
                'control.type': 'selection',
                'selection.style': 'explicit'
            },
            'KP_ACL_lachmanTestPositive': {
                'title': 'Lachman test positive',
                'detail': 'High sensitivity and high specificity',
                'itemType': 'control',
                'control.type': 'selection',
                'selection.style': 'explicit'
            },
            'KP_ACL_anteriorDrawTestPositive': {
                'title': 'Anterior draw test positive',
                'detail': 'Low sensitivity and high specificity',
                'itemType': 'control',
                'control.type': 'selection',
                'selection.style': 'explicit'
            },
            'KP_ACL_NoToAll':{
                'title': 'ACL criteria',
                'itemType': 'control',
                'control.type': 'noToAll'
            },
            'KP_LCL_CriteriaSection': {
                'title': 'Lateral collateral ligament (LCL) injury criteria',
                'itemType': 'section',
                'section.childIDs': [
                    'KP_LCL_laxityOnVarusStress'
                ]
            },
            'KP_LCL_laxityOnVarusStress': {
                'title': 'Laxity on varus stress in extension and in 30° knee flexion',
                'detail': 'LCL injury is rare in isolation',
                'itemType': 'control',
                'control.type': 'selection',
                'selection.style': 'explicit'
            },

            'KP_suspectMeniscalLesion': {
                'title': 'Meniscal lesion',
                'itemType': 'control',
                'control.type': 'selection',
                'selection.style': 'explicit',
                'subcellIDs': [
                    'KP_ML_Criteria'
                ]
            },
            // ASSESSMENT > KP > FA2 > AF1 > MENISCAL LESION
            'KP_ML_Criteria': {
                'title': 'Meniscal lesion criteria',
                'itemType': 'subform',
                'subform.type': 'suggestive',
                'subform.sectionIDs': [
                    'KP_ML_CriteriaSection'
                ],
            },
            'KP_ML_CriteriaSection': {
                'itemType': 'section',
                'section.childIDs': [
                    'KP_ML_injuryMechanism',
                    'KP_ML_symptoms',
                    'KP_ML_signs',
                    'KP_ML_thessalyTestMcMurrayTest',
                    'KP_ML_NoToAll'
                ]
            },
            'KP_ML_injuryMechanism': {
                'title': 'Injury mechanism',
                'detail': 'Rotational element, squatting, cutting or twisting in younger population',
                'itemType': 'control',
                'control.type': 'selection',
                'selection.style': 'explicit'
            },
            'KP_ML_symptoms': {
                'title': 'Symptoms',
                'detail': 'Pain, swelling and mechanical symptoms (catching, locking)',
                'itemType': 'control',
                'control.type': 'selection',
                'selection.style': 'explicit'
            },
            'KP_ML_signs': {
                'title': 'Signs',
                'detail': 'Effusion, joint line tenderness – posterior more clinically relevant (variable sensitivity 55 – 85% depending on site of meniscal pathology), Loss of end range extension or flexion',
                'itemType': 'control',
                'control.type': 'selection',
                'selection.style': 'explicit'
            },
            'KP_ML_thessalyTestMcMurrayTest': {
                'title': 'Thessaly Test / McMurray Test',
                'itemType': 'control',
                'control.type': 'selection',
                'selection.style': 'explicit'
            },
            'KP_ML_NoToAll':{
                'title': 'criteria',
                'itemType': 'control',
                'control.type': 'noToAll'
            },

            'KP_suspectMCLPCLRupture': {
                'title': 'MCL / PCL injury',
                'itemType': 'control',
                'control.type': 'selection',
                'selection.style': 'explicit',
                'subcellIDs': [
                    'KP_MCL_PCL_Criteria'
                ]
            },
            'KP_MCL_PCL_Criteria': {
                'title': 'MCL / PCL criteria',
                'itemType': 'subform',
                'subform.type': 'suggestive',
                'subform.sectionIDs': [
                    'KP_MCL_PCL_CriteriaSection'
                ],
            },
            'KP_MCL_PCL_CriteriaSection': {
                'itemType': 'section',
                'section.childIDs': [
                    'KP_MCL_PCL_mclInjury',
                    'KP_MCL_PCL_pclInjury',
                    'KP_MCL_PCL_NoToAll'
                ]
            },
            'KP_MCL_PCL_mclInjury': {
                'title': 'Medial collateral ligament (MCL) injury',
                'detail': 'Injury mechanism – valgus stress, often from a lateral force to the knee\nSymptoms – Pain, swelling and feeling of instability\nSigns - Effusion, laxity on valgus stress test in 30° knee flexion, laxity on valgus stress test in extension indicates higher degree of injury',
                'itemType': 'control',
                'control.type': 'selection',
                'selection.style': 'explicit'
            },
            'KP_MCL_PCL_pclInjury': {
                'title': 'Posterior cruciate ligament (PCL) injury',
                'detail': 'Injury mechanism – posteriorly-directed force to the proximal tibia (e.g. dashboard injury or fall onto flexed knee or tackle from the front)\nSymptoms – Pain, swelling and feeling of instability\nSigns – Effusion, posterior draw test positive (high sensitivity), PCL sag sign (late sign)',
                'itemType': 'control',
                'control.type': 'selection',
                'selection.style': 'explicit'
            },
            'KP_MCL_PCL_NoToAll':{
                'title': 'criteria',
                'itemType': 'control',
                'control.type': 'noToAll'
            },

            'KP_suspectPLCInjury': {
                'title': 'Postero-Lateral Complex (PLC) injury',
                'detail': 'Rare but associated with other ligamentous injuries in particular LCL rupture',
                'itemType': 'control',
                'control.type': 'selection',
                'selection.style': 'explicit'
            },

            'KP_additionalInformation': {
                'title': 'Provide additional information',
                'detail': 'Including indication of affected side(s)',
                'itemType': 'control',
                'control.type': 'textEntry',
                'textEntry.type': 'wrappingPlainText'
            },

            // ASSESSMENT > KP > Recommendations
            'KP_LK_PCL_ManagementRecommendation': {
                'title': 'MANAGEMENT RECOMMENDATION',
                'itemType': 'section', // 'inline-recommendation'
                'section.style': 'inline',
                'section.childIDs': [
                    'KP_LK_PCL_AcuteAssessment',
                ]
            },
            'KP_LK_PCL_AcuteAssessment': {
                'title': 'Consider acute assessment',
                'itemType': 'control',
                'control.type': 'plain'
            },

            'KP_LK_ACL_LCL_ImagingRecommendation': {
                'title': 'IMAGING RECOMMENDATION',
                'itemType': 'section', // 'inline-recommendation'
                'section.style': 'inline',
                'section.childIDs': [
                    'KP_LK_ACL_LCL_MRI',
                ]
            },
            'KP_LK_ACL_LCL_MRI': {
                'title': 'MRI',
                'itemType': 'control',
                'control.type': 'plain'
            },

            'KP_ML_ImagingRecommendation': {
                'title': 'IMAGING RECOMMENDATION',
                'itemType': 'section', // 'inline-recommendation'
                'section.style': 'inline',
                'section.childIDs': [
                    'KP_ML_StandingXray',
                ]
            },
            'KP_ML_StandingXray': {
                'title': 'Standing X-ray',
                'detail': 'To determine level of OA if not already done since injury.\nKnee ultrasound scan should not be used in the assessment of intra-articular pathology (ligamentous and meniscal damage)',
                'itemType': 'control',
                'control.type': 'completionItem',
                'subcellIDs': [
                    'KP_moderateToSevereOA'
                ]
            },
            'KP_moderateToSevereOA': {
                'title': 'Does an X-ray confirm moderate to severe OA?',
                'itemType': 'control',
                'control.type': 'selection',
                'selection.style': 'explicit'
            },

            'KP_ML_ManagementRecommendation': {
                'title': 'MANAGEMENT RECOMMENDATION',
                'itemType': 'section', // 'inline-recommendation'
                'section.style': 'inline',
                'section.childIDs': [
                    'KP_ML_ActiveRehabilitation',
                ]
            },
            'KP_ML_ActiveRehabilitation': {
                'title': 'Active rehabilitation for 6 weeks',
                'itemType': 'control',
                'control.type': 'completionItem',
                'subcellIDs': [
                    'KP_improvementAfterActiveRehabilitation'
                ]
            },
            'KP_improvementAfterActiveRehabilitation': {
                'title': 'Has there been any improvement after 6 weeks of active rehabilitation?',
                'itemType': 'control',
                'control.type': 'selection',
                'selection.style': 'explicit'
            },

            'KP_ML2_ImagingRecommendation': {
                'title': 'IMAGING RECOMMENDATION',
                'itemType': 'section', // 'inline-recommendation'
                'section.style': 'inline',
                'section.childIDs': [
                    'KP_ML2_NoOAAndNoImprovement'
                ]
            },
            'KP_ML2_NoOAAndNoImprovement': {
                'title': 'MRI',
                'itemType': 'control',
                'control.type': 'plain'
            },

            'KP_ML2_ManagementRecommendation': {
                'title': 'MANAGEMENT RECOMMENDATION',
                'itemType': 'section', // 'inline-recommendation'
                'section.style': 'inline',
                'section.childIDs': [
                    'KP_ML2_ContinueActiveRehabilitation',
                ]
            },
            'KP_ML2_ContinueActiveRehabilitation': {
                'title': 'Continue active rehabilitation',
                'detail': 'GP reassess if no improvement. OA should be considered the primary diagnosis',
                'itemType': 'control',
                'control.type': 'plain'
            },

            'KP_MCL_PCL_ImagingRecommendation': {
                'title': 'IMAGING RECOMMENDATION',
                'itemType': 'section', // 'inline-recommendation'
                'section.style': 'inline',
                'section.childIDs': [
                    'KP_MCL_PCL_StandingXray'
                ]
            },
            'KP_MCL_PCL_StandingXray': {
                'title': 'Standing X-ray',
                'detail': 'If clinically required to determine level of OA if not already done since injury.\nKnee ultrasound scan should not be used in the assessment of intra-articular pathology (ligamentous and meniscal damage)',
                'itemType': 'control',
                'control.type': 'plain'
            },

            'KP_MCL_PCL_ManagementRecommendation': {
                'title': 'MANAGEMENT RECOMMENDATION',
                'itemType': 'section', // 'inline-recommendation'
                'section.style': 'inline',
                'section.childIDs': [
                    'KP_MCL_PCL_ActiveRehabilitation'
                ]
            },
            'KP_MCL_PCL_ActiveRehabilitation': {
                'title': 'Active rehabilitation',
                'detail': 'GP reassess if no improvement.',
                'itemType': 'control',
                'control.type': 'plain'
            },

            /// OUTCOME
            'outcome': {
                'title': 'MRI request outcome',
                'itemType': 'subform',
                'subform.sectionIDs': [
                    'outcomeMoreInformationRequiredSection',
                    'mriRequestOutcomeSection',
                    'additionalInformationSection',
                    'exportRequestOutcomeSection'
                ]
            },
            
            'outcomeMoreInformationRequiredSection':{
                'title': 'More information required',
                'section.childIDs': [
                    'hiddenShouldDisplayOutcomeFormTracker',
                    'outcomeMoreInformationRequired'
                ]
            },
            'hiddenShouldDisplayOutcomeFormTracker': {
                'itemType': 'control',
                'control.type': 'selection',
                'selection.style': 'explicit',
            },
            'outcomeMoreInformationRequired':{
                'title': 'Please complete all sections of the form. This improves the likelihood of request approval and provides complete clinical information for test reporting.',
                'itemType': 'control',
                'control.type': 'plain'
            },
            'mriRequestOutcomeSection':{
                'title': 'MRI request outcome',
                'section.childIDs': [
                    'mriRequestOutcome'
                ]
            },
            'mriRequestOutcome':{
                'title': 'MRI REQUEST OUTCOME',
                'itemType': 'control',
                'control.type': 'action'
            },

            'additionalInformationSection': {
                'title': 'Additional information',
                'section.childIDs': [
                    'additionalRequestInformation'
                ]
            },
            'additionalRequestInformation':{
                'title': 'Please include any other relevant information',
                'itemType': 'control',
                'control.type': 'textEntry',
                'textEntry.type': 'wrappingPlainText'
            },
            'exportRequestOutcomeSection':{
                'title': 'Export request outcome',
                'section.childIDs': [
                    'exportRequestOutcome'
                ]
            },
            'exportRequestOutcome':{
                'title': 'To include this automated outcome within your MRI request form, please copy and paste (Ctrl + V) the summary of this outcome into the clinical notes section of your request',
                'itemType': 'control',
                'control.type': 'export'
            }

        },
        'insightsDependancies': [
            
        ]
    }
}