import {
    RESULTS_ALERT_POST_FULFILLED,
    RESULTS_ALERT_CLEAR_FULFILLED,
    RESULTS_BADGE_POST_FULFILLED,
    RESULTS_BADGE_CLEAR_FULFILLED,
    REMOVE_ALERT_VALUE_FULFILLED
} from '../actions/NotificationsActions';

// INITIALIZE STATE
const initialState = {
    changes: {},
    newResultsAvailable: false,
    showResultsBadge: {}
};

export const NotificationsReducer = (state = initialState, action) => {
    switch (action.type) {
        case RESULTS_ALERT_POST_FULFILLED: {
            let changes = Object.assign({}, state.changes);
            let currentChanges = [];
            if(changes[action.payload.pathwayId] != undefined){
                currentChanges = changes[action.payload.pathwayId].slice(0);
            }

            for(let newChange of action.payload.changes){
                if(!currentChanges.includes(newChange)){
                    currentChanges.push(newChange);
                }
            }

            changes[action.payload.pathwayId] = currentChanges;

            return {
                ...state,
                changes: changes,
                newResultsAvailable: true
            };
        }
        case RESULTS_ALERT_CLEAR_FULFILLED: {
            let changes = Object.assign({}, state.calculations);
            changes[action.payload.pathwayId] = action.payload.changes;
            return {
                ...state,
                newResultsAvailable: false,
                changes: changes
            };
        }
        case RESULTS_BADGE_POST_FULFILLED:{
            let showResultsBadge = Object.assign({}, state.showResultsBadge);
            showResultsBadge[action.payload] = true;
            return {
                ...state,
                showResultsBadge: showResultsBadge
            };
        }
        case RESULTS_BADGE_CLEAR_FULFILLED:{
            let showResultsBadge = Object.assign({}, state.showResultsBadge);
            showResultsBadge[action.payload] = false;
            return {
                ...state,
                showResultsBadge: showResultsBadge
            };
        }
        case REMOVE_ALERT_VALUE_FULFILLED: {
            let changes = Object.assign({}, state.changes);
            let currentChanges = [];
            if(changes[action.payload.pathwayId] != undefined){
                currentChanges = changes[action.payload.pathwayId].slice(0);
            }

            var index = currentChanges.indexOf(action.payload.value);
            if (index !== -1) {
                currentChanges.splice(index, 1);
            }

            changes[action.payload.pathwayId] = currentChanges;

            let showResultsBadge = Object.assign({}, state.showResultsBadge);
            showResultsBadge[action.payload.pathwayId] = currentChanges.length > 0;

            return {
                ...state,
                changes: changes,
                newResultsAvailable: currentChanges.length > 0, 
                showResultsBadge: showResultsBadge
            };
        }
        default:
            return state;
    }
};