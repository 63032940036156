import React from "react";
import { Box, Button } from "@mui/material";
import LaunchIcon from "@mui/icons-material/Launch";
import Logo from "../../images/HNZ_Logo_RGB_Full Colour.png";

interface MyHealthButtonProp {
  updateOption?: boolean;
  onClick: () => void;
}

const MyHealthButton: React.FC<MyHealthButtonProp> = (prop) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          height: "80px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Button
          onClick={prop.onClick}
          sx={{
            textTransform: "none",
            color: "#002E6E",
            fontSize: "16px",
            backgroundColor: "white",
            borderColor: "#002E6E",
            "&:hover": {
              borderColor: "#002E6E",
              backgroundColor: "#EEF4FA",
            },
            "&:focus": {
              borderWidth: "3px",
              borderColor: "#7EB6DC",
            },
            "&:active": {
              borderWidth: "2px",
            },
          }}
          variant="outlined"
          endIcon={<LaunchIcon />}
        >
          <Box className="fira-sans-regular">
            {prop.updateOption ? "Update with" : "Log in with a"}
          </Box>
          <Box className="fira-sans-bold" sx={{ ml: 0.5 }}>
            Workforce account
          </Box>
        </Button>
      </Box>
      <Box
        component="img"
        src={Logo}
        alt="Workforce account logo"
        sx={{ width: "210px" }}
      />
    </Box>
  );
};

export default MyHealthButton;
