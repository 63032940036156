export class GallbladderPathwayReference {
    static data = {
        'title': 'Gallbladder Request',
        'detail': 'Use for all elective and acute gallbladder requests including polyps, masses and stones. For acute, add to Acute General Surgery ERMS Request. Direct booking to out patient clinic will result from a completed request sent through ERMS on Non-acute Surgery Request form.',
        'itemType': 'template',
        'template.version': 1.0,
        'template.versionUuid': 'gallbladderGallstones',
        'template.createText': 'Use This Pathway',
        'template.introID': 'introForm',
        'template.contentTabIDs': ['mainForm', 'resultsForm'],
        'template.defaultContentTabID': 'mainForm',
        'template.content': {
            'introForm': {
                'title': 'Gallbladder Request',
                'detail': 'Use for all elective gallbladder surgical requests including polyps, masses and stones. \nDo not use to request ultrasounds. \nFor acute, do not use Wayfind, and instead use Acute General Surgery ERMS Request.',
                'itemType': 'subform',
                'subform.sectionIDs': [
                    
                ]
            },
            'PathwayInformationForm': {
                'title': 'Additional Information',
                'itemType': 'subform',
                'subform.sectionIDs': [
                    'pathwayInformationSection'
                ]
            },
            'eligibilityForm': {
                'title': 'Eligibility criteria',
                'itemType': 'subform',
                'subform.sectionIDs': [
                    'eligibilitySection'
                ]
            },
            'eligibilitySection': {
                'title': 'Eligibility section',
                'itemType': 'section',
                'section.childIDs': [
                    'eligibilityReason'
                ]
            },
            'eligibilityReason': {
                'title': 'Eligibility reason',
                'itemType': 'control',
                'control.type': 'textEntry',
                'textEntry.type': 'wrappingPlainText'
            },
            'mainForm': {
                'title': 'Request',
                'itemType': 'subform',
                'subform.sectionIDs': [
                    'patientDetailsSection',
                    'ClinicalContextSection',
                    'ClinicalAssessmentSection'
                ]
            },
            'patientDetailsSection': {
                'title': 'Patient details',
                'itemType': 'section',
                'section.childIDs': [
                    'patientDetails',
                ]
            },
            'patientDetails': {
                'title': 'Patient details',
                'itemType': 'subform',
                'subform.sectionIDs': [
                    'detailsSection'
                ]
            },
            'detailsSection': {
                'section.childIDs': [
                    'patientAge',
                    'patientPrivateInsurance'
                ]
            },
            'patientAge': {
                'title': 'Age',
                'itemType': 'control',
                'control.type': 'textEntry',
                'textEntry.type': 'decimalNumber',
                'textEntry.unitText': 'years',
                'subcellIDs':[
                    'patientDateOfBirth'
                ]
            },
            'patientDateOfBirth':{
                'title': 'Date of birth',
                'detail': 'Date of birth can be used to calculate age.',
                'itemType': 'control',
                'control.type': 'picker',
                'picker.type': 'dateOfBirth',
            },
            'patientPrivateInsurance': {
                'title': 'Does the patient wish to go privately for this request?',
                'itemType': 'control',
                'control.type': 'selection',
                'selection.style': 'explicit',
                'subcellIDs': [
                    'privateInsuranceDetail'
                ]
            },
            'privateInsuranceDetail': {
                'title': 'Continue with this request to provide the information needed for reporting, and choose ERMS private option.',
                'itemType': 'control',
                'control.type': 'plain',
                
            },
            'ClinicalContextSection': {
                'title': 'Clinical context',
                'itemType': 'section',
                'section.childIDs': [
                    'requestCriteria',
                ]
            },
            'requestCriteria': {
                'title': 'Request criteria',
                'itemType': 'subform',
                'subform.sectionIDs': [
                    'referralIndicationFormSection'
                ]
            },
            'referralIndicationFormSection': {
                'title': 'Reason for request',
                'detail': 'Select one:',
                'section.childIDs': [
                    'ruqPain',
                    'biliaryColic',
                    'cholecystitis',
                    'transientJaundice',
                    'cholangitis',
                    'pancreatitis',
                    'persistingSymptomsAfterSurgery',
                    'incidentalUSSFindings',
                    'hiddenRequestReasonTracker'
                ]
            },
            'hiddenRequestReasonTracker':{
                'title': 'Request reason selected',
                'itemType': 'control',
                'control.type': 'radio',
            },
            'ruqPain':{
                'title': 'Right upper quadrant (RUQ) pain',
                'itemType': 'control',
                'control.type': 'radio',
            },
            'biliaryColic':{
                'title': 'Biliary colic',
                'itemType': 'control',
                'control.type': 'radio',
            },
            'cholecystitis':{
                'title': 'Cholecystitis',
                'itemType': 'control',
                'control.type': 'radio',
            },
            'complicatedBiliaryDisease':{
                'title': 'Complicated biliary disease',
                'itemType': 'control',
                'control.type': 'radio',
                'subcellIDs': [
                    'complicatedBiliaryDiseaseCriteriaForm'
                ]
            },
            'transientJaundice':{
                'title': 'Transient jaundice',
                'itemType': 'control',
                'control.type': 'radio',
            },
            'cholangitis':{
                'title': 'Cholangitis',
                'detail':'Most patients have fever, jaundice, and right upper quadrant pain',
                'itemType': 'control',
                'control.type': 'radio',
            },
            'pancreatitis':{
                'title': 'Pancreatitis',
                'itemType': 'control',
                'control.type': 'radio',
            },
            'persistingSymptomsAfterSurgery':{
                'title': 'Persisting symptoms after cholecystectomy or biliary surgery',
                'itemType': 'control',
                'control.type': 'radio',
                'subcellIDs': [
                    'cholecystectomyOrBiliarySurgeryNameOfSurgeonIfKnown'
                ]
            },
            'cholecystectomyOrBiliarySurgeryNameOfSurgeonIfKnown': {
                'title': 'Hospital and surgeon details (if known)',
                'itemType': 'control',
                'control.type': 'textEntry',
                'textEntry.type': 'wrappingPlainText',
            },
            'incidentalUSSFindings':{
                'title': 'Incidental ultrasound scan findings',
                'detail': 'Unexpected findings, not associated with biliary symptoms',
                'itemType': 'control',
                'control.type': 'radio',
            },
            'ClinicalAssessmentSection': {
                'title': 'Clinical assessment',
                'itemType': 'section',
                'section.childIDs': [
                    'furtherInformation'
                ]
            },
            'furtherInformation': {
                'title': 'Further information',
                'itemType': 'subform',
                'subform.sectionIDs': [
                    'keyClinicalFeaturesSymptomsSection',
                    'pastSurgicalHistoryFormSection',
                    'keyClinicalFeaturesSymptomsSigns',
                    'ultrasoundSection',
                    'bloodsSection'
                ]
            },
            'keyClinicalFeaturesFormSection':{
                'title': '',
                'itemType': 'section',
                'section.childIDs': [
                    'keyClinicalFeaturesForm'
                ]
            },
            'pastSurgicalHistorySection':{
                'title': '',
                'itemType': 'section',
                'section.childIDs': [
                    'pastSurgicalHistoryForm'
                ]
            },
            'keyClinicalFeaturesSymptomsSection': {
                'title': 'High-risk cancer-related symptoms',
                'section.childIDs': [ 
                    'unexpectedWeightLoss',
                    'changeInBowelHabit',
                    'abdominalDistension',
                    'melena',
                    'noToAllCancerSymptoms'
                ]
            },
            'noToAllCancerSymptoms':{
                'title': '',
                'itemType': 'control',
                'control.type': 'noToAll'
            },
            'unexpectedWeightLoss':{
                'title': 'Unexplained weight loss',
                'itemType': 'control',
                'control.type': 'selection',
                'selection.style': 'explicit'
            },
            'changeInBowelHabit':{
                'title': 'Change in bowel habit',
                'itemType': 'control',
                'control.type': 'selection',
                'selection.style': 'explicit'
            },
            'abdominalDistension':{
                'title': 'Abdominal distension',
                'itemType': 'control',
                'control.type': 'selection',
                'selection.style': 'explicit'
            },
            'melena':{
                'title': 'Melaena',
                'itemType': 'control',
                'control.type': 'selection',
                'selection.style': 'explicit'
            },
            'keyClinicalFeaturesSymptomsSigns': {
                'title': 'Signs',
                'section.childIDs': [ 
                    'keyClinicalFeaturesSymptomsSignsJaundice',
                    'hasHadAbdominalExam',
                ]
            },
            'keyClinicalFeaturesSymptomsSignsJaundice':{
                'title': 'Currently jaundiced',
                'itemType': 'control',
                'control.type': 'selection',
                'selection.style': 'explicit'
            },
            'hasHadAbdominalExam':{
                'title': 'Abdominal examination notes',
                'detail': 'Details about previous abdominal surgical scars, abnormalities, etc',
                'itemType': 'control',
                'control.type': 'textEntry',
                'textEntry.type': 'wrappingPlainText'
            },
            'abdominalExam':{
                'title': 'Abdominal Exam',
                'itemType': 'subform',
                'subform.sectionIDs': [
                    'abdominalExamSection'
                ]
            },
            'abdominalExamSection':{
                'section.childIDs': [ 
                    'abdominalExamScars',
                    'abdominalExamAbnormalities'
                ]
            },
            'abdominalExamScars':{
                'title': 'Are there any previous surgical scars?',
                'itemType': 'control',
                'control.type': 'selection',
                'selection.style': 'explicit',
                'subcellIDs': [
                    'abdominalExamScarsSpecifiy'
                ]
            },
            'abdominalExamScarsSpecifiy':{
                'title': 'Specify',
                'itemType': 'control',
                'control.type': 'textEntry',
                'textEntry.type': 'wrappingPlainText',
            },
            'abdominalExamAbnormalities':{
                'title': 'Are there any abnormalities?',
                'itemType': 'control',
                'control.type': 'selection',
                'selection.style': 'explicit',
                'subcellIDs': [
                    'abdominalExamAbnormalitiesSpecifiy'
                ]
            },
            'abdominalExamAbnormalitiesSpecifiy':{
                'title': 'Specify',
                'itemType': 'control',
                'control.type': 'textEntry',
                'textEntry.type': 'wrappingPlainText',
            },
            'pastSurgicalHistoryFormSection': {
                'title': 'Medical and surgical history',
                'section.childIDs': [ 
                    'priorERCP',
                    'primarySclerosingCholangitis',
                    'historyOfInflammatoryBowelDisease',
                    'pastSurgicalHistoryOther',
                    'pastSurgicalHistoryFormSectioNoToAll'
                ]
            },
            'priorERCP':{
                'title': 'Prior ERCP',
                'itemType': 'control',
                'control.type': 'selection',
                'selection.style': 'explicit'
            },
            'primarySclerosingCholangitis':{
                'title': 'Primary sclerosing cholangitis (PSC)',
                'itemType': 'control',
                'control.type': 'selection',
                'selection.style': 'explicit'
            },
            'historyOfInflammatoryBowelDisease':{
                'title': 'History of inflammatory bowel disease',
                'itemType': 'control',
                'control.type': 'selection',
                'selection.style': 'explicit'
            },
            'pastSurgicalHistoryOther':{
                'title': 'Other',
                'itemType': 'control',
                'control.type': 'selection',
                'selection.style': 'explicit',
                'subcellIDs':[
                    'pastSurgicalHistoryOtherSpecify'
                ]
            },
            'pastSurgicalHistoryOtherSpecify':{
                'title': 'Specify',
                'itemType': 'control',
                'control.type': 'textEntry',
                'textEntry.type': 'wrappingPlainText',
            },
            'pastSurgicalHistoryFormSectioNoToAll':{
                'title': '',
                'itemType': 'control',
                'control.type': 'noToAll'
            },
            'ultrasoundSection': {
                'title': 'Ultrasound',
                'section.childIDs': [
                    'stonesInGallbladder',
                    'commonBileDuctStone',
                    'dilatedDuctOnUSS',
                    'polyps',
                    'adenonmyomatosis',
                    'gallbladderMass',
                    'noToAllUltrasound'
                ]
            },
            'noToAllUltrasound':{
                'itemType': 'control',
                'control.type': 'noToAll',
            },
            'stonesInGallbladder':{
                'title': 'Stones in gallbladder',
                'itemType': 'control',
                'control.type': 'selection',
                'selection.style': 'explicit'
            },
            'commonBileDuctStone':{
                'title': 'Common bile duct stone',
                'itemType': 'control',
                'control.type': 'selection',
                'selection.style': 'explicit'
            },
            'dilatedDuctOnUSS':{
                'title': 'Dilated duct on USS',
                'itemType': 'control',
                'control.type': 'selection',
                'selection.style': 'explicit'
            },
            'polyps':{
                'title': 'Polyps',
                'itemType': 'control',
                'control.type': 'selection',
                'selection.style': 'explicit',
                'subcellIDs': [
                    'polypsSize',
                    'polypsSessileOrSolitary'
                ]
            },
            'polypsSize':{
                'title': 'Size',
                'itemType': 'control',
                'control.type': 'textEntry',
                'textEntry.type': 'decimalNumber',
                'textEntry.unitText': 'mm',
            },
            'polypsSessileOrSolitary':{
                'title': 'Solitary',
                'itemType': 'control',
                'control.type': 'selection',
                'selection.style': 'explicit'
            },
            'adenonmyomatosis':{
                'title': 'Adenomyomatosis',
                'itemType': 'control',
                'control.type': 'selection',
                'selection.style': 'explicit'
            },
            'gallbladderMass':{
                'title': 'Possible gallbladder mass',
                'itemType': 'control',
                'control.type': 'selection',
                'selection.style': 'explicit'
            },
            'bloodsSection': {
                'title': 'Bloods',
                'section.childIDs': [
                    'bloodsObstructiveLFT'
                ]
            },
            'bloodsObstructiveLFT':{
                'title': 'Obstructive LFTs',
                'detail': 'Raised ALP, GGT, or Bilirubin',
                'itemType': 'control',
                'control.type': 'selection',
                'selection.style': 'explicit'
            },
            'resultsForm': {
                'title': 'Request outcome',
                'itemType': 'subform',
                'subform.sectionIDs': [
                    'privateFundingSection',
                    'gallbladderRequestOutcome',
                    'moreInfoRequired',
                    'requestConsideration',
                    'additionalInformationSection',
                    'exportRequestionOutcomeSection'
                ]
            },
            'privateFundingSection':{
                'title': 'Private funding indicated',
                'section.childIDs': [
                    'privateFundingIndicatedDetail'
                ]
            },
            'privateFundingIndicatedDetail':{
                'title': 'You have indicated that the patient wishes to use private funding for their request.',
                'itemType': 'control',
                'control.type': 'plain'
            },
            'gallbladderRequestOutcome': {
                'title': 'Specialist appointment outcome',
                'section.childIDs': [
                    'gallbladderRequestOutcomeAction',
                    'generalNonUrgentSurgicalClinic',
                    'seeComunityHealthPathways',
                    'considerPreviousSurgeon'
                ]
            },
            'generalNonUrgentSurgicalClinic':{
                'title': 'No indication for clinical intervention.',
                'itemType': 'control',
                'control.type': 'action'
            },
            'seeComunityHealthPathways': {
                'title': 'See Community HealthPathways > #@^Incidental Findings on Imaging^https://canterbury.communityhealthpathways.org/195518.htm#@',
                'itemType': 'control',
                'control.type': 'plain'
            },
            'considerPreviousSurgeon':{
                'title': 'Consider booking to clinic of prior surgeon:',
                'detail': 'If the surgeon is no longer available or outside of that surgeon’s expertise, then triage based on urgency.',
                'itemType': 'control',
                'control.type': 'action'
            },
            'gallbladderRequestOutcomeAction':{
                'title': 'Specialist appointment',
                'detail': 'No repeat ECG is needed unless the patient status changes',
                'itemType': 'control',
                'control.type': 'action'
            },
            'moreInfoRequired':{
                'title': 'More information required',
                'section.childIDs': [
                    'moreInfoRequiredDetail'
                ]
            },
            'moreInfoRequiredDetail': {
                'title': 'Please complete all sections of the form. This improves the likelihood of request approval and provides complete clinical information for test reporting.\n\nOn form completion you will be able to review the recommendation and submit this request for automatic approval, or you may request special consideration if needed.',
                'itemType': 'control',
                'control.type': 'plain'
            },
            'requestConsideration':{
                'title': 'Specialist appointment outcome',
                'section.childIDs': [
                    'acceptanceOutsideUsualCriteria'
                ]
            },
            'acceptanceOutsideUsualCriteria': {
                'title': 'Do you believe this request should be considered for acceptance outside usual criteria?',
                'detail': 'Requests that do not fit the criteria may be accepted if a good case is presented.',
                'itemType': 'control',
                'control.type': 'selection',
                'selection.style': 'explicit',
                'subcellIDs': [
                    'acceptanceOutsideCriteriaReason'
                ]
            },
            'acceptanceOutsideCriteriaReason':{
                'title': 'Include your reasoning below',
                'itemType': 'control',
                'control.type': 'textEntry',
                'textEntry.type': 'wrappingPlainText'
            },
            'additionalInformationSection':{
                'title': 'Additional information',
                'section.childIDs': [
                    'additionalInformation'
                ]
            },
            'additionalInformation':{
                'title': 'Please include any other relevant information',
                'itemType': 'control',
                'control.type': 'textEntry',
                'textEntry.type': 'wrappingPlainText'
            },
            'exportRequestionOutcomeSection':{
                'title': 'Export request outcome',
                'section.childIDs': [
                    'exportRequestOutcome'
                ]
            },
            'exportRequestOutcome':{
                'title': 'To include this automated outcome within your ERMS request, please use the button below to copy the summary of this outcome into the Clinical notes section of your request.',
                'itemType': 'control',
                'control.type': 'export'
            },
            'furtherActionToBeDeterminedByHPBTeam':{
                'title': 'Further action to be determined by HPB team',
                'detail': '',
                'itemType': 'control',
                'control.type': 'action'
            }
        },
        'insightsDependancies': [
            'gallbladderMass',
            'stonesInGallbladder',
            'patientAge',
            'dilatedDuctOnUSS',
            'bloodsObstructiveLFT',
            'primarySclerosingCholangitis',
            'polyps',
            'polypsSize',
            'complicatedBiliaryDisease',
            'commonBileDuctStone',
            'persistingSymptomsAfterSurgery',
            'incidentalUSSFindings',
            'polypsSessileOrSolitary',
            'ruqPainBiliaryColicCholecystitis',
            'cholecystectomyOrBiliarySurgeryNameOfSurgeonIfKnown'
        ]
    }
}