import React, { useEffect, useState } from "react";
import { Route, Routes, useParams } from "react-router-dom";
import { Box } from "@mui/material";
import { ExtensionHandoverPage } from "../pages/Overlays/ExtensionHandoverOverlay";
import { FormSummaryPage } from "../pages/FormSummaryPage";
import { FormPage } from "../pages/FormPage";
import { SectionPage } from "../pages/SectionPage";
import { createNewResponse } from "../state/actions/WorkflowResponseActions";
import { FormLoader } from "../../forms/FormLoader";
import { AppRouter } from "./AppRouter";
import AppAuthTemplate from "../Common/AppAuthTemplate";
import DSRouterWrapper from "./DSRouterWrapper";
import AuthHandler, { AuthServerError } from "./AuthHandler";
import LandingPage from "../pages/LandingPage";
import FormRouteHandler from "../pages/FormRouteHandler";

const AppRoutes: React.FC = () => {
  // Create an instance of FormLoader, which is used everywhere in Forms
  const formLoader = new FormLoader();

  const [extensionNavigation, setExtensionNavigation] =
    useState<boolean>(false);
  const [authError, setAuthError] = useState<AuthServerError>();
  const { hideNavigation, isExtension } = useParams();

  useEffect(() => {
    const shouldUseExtensionNavigation =
      hideNavigation !== undefined
        ? hideNavigation.toLowerCase() === "true"
        : isExtension !== undefined && isExtension.toLowerCase() === "true";
    setExtensionNavigation(shouldUseExtensionNavigation);
  }, [hideNavigation, isExtension]);

  const handleAuthError = (err: AuthServerError) => {
    setAuthError(err);
  };

  return (
    <Box
      sx={{
        display: "flex",
        width: "100vw",
        height: "100vh",
        flex: 1,
        flexDirection: "column",
        padding: 0,
      }}
    >
      <Routes>
        <Route element={<AppRouter />}>
          <Route path="/" element={<LandingPage />} />

          <Route
            path="/auth"
            element={<AuthHandler handleError={handleAuthError} />}
          />
        </Route>

        <Route
          element={
            <AppAuthTemplate authError={authError}>
              <FormRouteHandler formLoader={formLoader}>
                <DSRouterWrapper formLoader={formLoader} />
              </FormRouteHandler>
            </AppAuthTemplate>
          }
        >
          <Route
            path="/:pathwayId/handover"
            element={
              <ExtensionHandoverPage
                formLoader={formLoader}
                WorkflowResponseActions={createNewResponse}
                isExtension={extensionNavigation}
              />
            }
          />
          <Route
            path="/:pathwayId/summary"
            element={<FormSummaryPage formLoader={formLoader} />}
          />
          <Route
            path="/:pathwayId/:formId"
            element={
              <FormPage
                formLoader={formLoader}
                collapseHeader={true}
                isExtension={extensionNavigation}
              />
            }
          />
          <Route
            path="/:pathwayId/:formId/:sectionId"
            element={
              <SectionPage
                formLoader={formLoader}
                isExtension={extensionNavigation}
              />
            }
          />

          {/* Empty element for DSRouter rendering */}
          <Route path="/:pathwayId" element={<Box />} />
        </Route>
      </Routes>
    </Box>
  );
};

export default AppRoutes;
