import { FormWithRedux } from '../../FormWithRedux';
import { FormsStyles } from '../../../waferJS/FormsUI/FormsUI';
import { FormValidationError } from '../../../waferJS/Form/Validation/FormValidationError';
import { PathwayReferenceHelper } from '../../PathwayReferenceHelper';
import { EDChestPainPathwayReference } from '../EDChestPainPathway-Reference';

export class EDChestPainRedFlagsFindingsForm extends FormWithRedux {
    
    constructor(formLoader, parent, actionUpdater, dependentValuesUpdater, calculationsUpdater) {
        super('highRiskFindingsRedFlagsForm', formLoader, parent, actionUpdater, dependentValuesUpdater, calculationsUpdater);
    }

    shouldDisplayFormDetailForIdentifier(identifier) {
        super.shouldDisplayFormDetailForIdentifier(identifier);
        if(identifier == 'redFlagsOngoingChestPainOverrideValue'){
            let dependentValue = this.getValue('redFlagsOngoingChestPainOverride');
            return dependentValue == true;
        } else if(identifier == 'crescendoAnginaOverrideValue'){
            let dependentValue = this.getValue('crescendoAnginaOverride');
            return dependentValue == true;
        } else if(identifier == 'haemodynamicInstabilityOverrideValue'){
            let dependentValue = this.getValue('haemodynamicInstabilityOverride');
            return dependentValue == true;
        } else if(identifier == 'newIschaemicChangesOverrideValue'){
            let dependentValue = this.getValue('newIschaemicChangesOverride');
            return dependentValue == true;
        }
        return true;
    }

    isEnabled(identifier) {
        if (identifier != null) {
            return true;
        }
        return false;
    }

    isMandatory(identifier) {
        if(identifier == 'redFlagsOngoingChestPainOverrideReason'){
            return this.getValue('redFlagsOngoingChestPainOverride') == true;
        } else if(identifier == 'crescendoAnginaOverrideReason'){
            return this.getValue('crescendoAnginaOverride') == true;
        } else if(identifier == 'haemodynamicInstabilityOverrideReason'){
            return this.getValue('haemodynamicInstabilityOverride') == true;
        } else if(identifier == 'newIschaemicChangesOverrideReason'){
            return this.getValue('newIschaemicChangesOverride') == true;
        } else {
            let redFlagIdentifiers = [
                'redFlagsOngoingChestPain',
                'crescendoAngina',
                'haemodynamicInstability',
                'newIschaemicChanges'
            ];

            if(redFlagIdentifiers.includes(identifier)){
                return true;
            }
        }

        return false;
    }

    getValueType(identifier) {
        let overrideReasonIdentifiers = [
            'redFlagsOngoingChestPainOverrideReason',
            'crescendoAnginaOverrideReason',
            'haemodynamicInstabilityOverrideReason',
            'newIschaemicChangesOverrideReason'
            
        ];

        let overrideIdentifiers = [
            'redFlagsOngoingChestPainOverride',
            'crescendoAnginaOverride',
            'haemodynamicInstabilityOverride',
            'newIschaemicChangesOverride'
        ];

        if(overrideReasonIdentifiers.includes(identifier)){
            return 'string';
        } else if (overrideIdentifiers.includes(identifier)){
            return 'boolean';
        }
        return null;
    }

    submissionValidation() {
        return [];
    }

    getValue(identifier) {

        let overrideIdentifiers = [
            'redFlagsOngoingChestPainOverride',
            'crescendoAnginaOverride',
            'haemodynamicInstabilityOverride',
            'newIschaemicChangesOverride'
        ];

        if(overrideIdentifiers.includes(identifier)){
            let value = super.getValue(identifier);
            return value == null ? false : value;
        }
        return super.getValue(identifier);
    }

    getProgress(identifier){
        if(this.getValue(identifier + 'Override') == true){
            let overrideReason = this.getValue(identifier + 'OverrideReason');
            if(overrideReason != null && overrideReason != undefined && overrideReason.length > 0){
                return 1;
            } else {
                return 0.5;
            }
        }

        return 1;
    }

    detailString() {
        let redflags = [
            'redFlagsOngoingChestPain',
            'crescendoAngina',
            'haemodynamicInstability',
            'newIschaemicChanges'
        ];

        let values = redflags.map((redFlag) => {
            let value = this.getValue(redFlag + 'OverrideValue');
            if(value == null){
                value = this.getValue(redFlag);
            }
                
            if(value != null){
                return value ? PathwayReferenceHelper.reference(redFlag, EDChestPainPathwayReference.data).title : null;
            }
        });

        let formProgress = this.completionProgress();
        if(values.filter(n => n).length == 0 && (formProgress.current / formProgress.total) == 1){
            return 'None';
        }else if(values.filter(n => n).length == 0){
            return this.detail;
        }

        return values.filter(n => n).join(',\n');
    }

    getBadgeValue(identifier) {
        let overrideValue = this.getValue(identifier + 'OverrideValue');
        let displayValue = overrideValue;
        if(overrideValue == null){
            displayValue = this.getValue(identifier);
        }
        
        if(displayValue != null){
            return [{
                color: displayValue == true ? FormsStyles.red : FormsStyles.black,
                value: displayValue == true ? 'YES' : 'NO',
                solid: overrideValue != null
            }];
        }
    }

    getOverrideReason(identifier){
        let isOverridden = this.getValue(identifier + 'Override');
        let overrideReason = this.getValue(identifier + 'OverrideReason');
        if(isOverridden){
            return 'Overridden' + (overrideReason == null ? '' :  ': ' + overrideReason);
        }
        return null;
    }

    supportsOverride() {
        return true;
    }

    isRedFlagActive(identifier){
        let value = this.getValue(identifier);
        return value; // override == true ? !value : value;
    }

    calculationError(identifier){
        if(identifier == 'redFlagsOngoingChestPain'){
            let value = this.getValue('redFlagsOngoingChestPain');
            if(value == null){
                return new FormValidationError(FormValidationError.FormErrorType.warning, 'From EDACS assessment results');
            }
        } else if(identifier == 'crescendoAngina'){
            let value = this.getValue('crescendoAngina');
            if(value == null){
                return new FormValidationError(FormValidationError.FormErrorType.warning, 'From EDACS assessment results');
            }
        } else if(identifier == 'haemodynamicInstability'){
            let value = this.getValue('haemodynamicInstability');
            if(value == null){
                return new FormValidationError(FormValidationError.FormErrorType.warning, 'From Vital signs results');
            }
        } else if(identifier == 'newIschaemicChanges'){
            let value = this.getValue('newIschaemicChanges');
            if(value == null){
                return new FormValidationError(FormValidationError.FormErrorType.warning, 'From ECG results');
            }
        }
        return null;
    }

    completionProgress() {
        var totalFormDetails = 0;
        var validFormDetails = 0;
        
        for (let formDetailIdentifier of this.formDetailIdentifiers) {
            let formDetail = this.formDetail(formDetailIdentifier);
            if (formDetail != null && formDetail.canHaveValue) {
                let form = formDetail.form;
                let mandatory = form.isMandatory(formDetailIdentifier);

                let value = this.getValue(formDetail.identifier);

                if (mandatory) {
                    totalFormDetails += 1;

                    if (value != null) {
                        validFormDetails += 1;
                    } 
                }
            }
        }

        for (let subformIdentifier of this.subformIdentifiers) {
            let subform = this.subform(subformIdentifier);
            let subformProgress = subform.completionProgress();
            totalFormDetails += subformProgress.total;
            validFormDetails += subformProgress.current;
        }

        return { current: validFormDetails, total: totalFormDetails };
    }

}