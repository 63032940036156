import { FormContentView } from '../../FormsUI/FormsUI Components/Containers/FormContentView';
import { FormDateTime } from '../../FormsUI/FormsUI Components/Controls/FormDateTime';
import { FormsStyles } from '../../FormsUI/FormsUI';

import React from 'react';
import PropTypes from 'prop-types';
import { FormButton } from '../../FormsUI/FormsUI Components/Controls/FormButton';
import moment from 'moment';


export class DateAndTimeFormDetail extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            value: props.formDetail.editingValue == null || props.formDetail.editingValue == undefined ? null : props.formDetail.editingValue.toString(),
            showNowButton: true,
            showTodayButton: true,
            adoptTodayString: null
        };

        this.handleSelectedDate = this.handleSelectedDate.bind(this);
        this.adoptSuggestion = this.adoptSuggestion.bind(this);
        this.toggleNowButton = this.toggleNowButton.bind(this);
        this.toggleTodayButton = this.toggleTodayButton.bind(this);
        this.dateValidation = this.dateValidation.bind(this);
        this.adoptTodaySuggestion = this.adoptTodaySuggestion.bind(this);
        this.adoptTodayString = this.adoptTodayString.bind(this);
    }

    handleSelectedDate(value) {
        this.props.formDetail.form.valueUpdated(this.props.formDetail.identifier, value);
        if(value == null){
            this.setState({showNowButton: true});
        }
        this.setState({adoptedTodayString: false});
        this.props.renderErrors();
    }

    adoptSuggestion(){
        this.props.formDetail.form.valueUpdated(this.props.formDetail.identifier, moment().toDate());
        this.props.renderErrors();
    }

    adoptTodaySuggestion(){
        if(this.state.showTodayButton){
            this.state.adoptTodayString();
        }
    }

    componentDidUpdate(){
        let value = this.props.formDetail.editingValue == null || this.props.formDetail.editingValue == undefined ? null : this.props.formDetail.editingValue.toString();
        if(value != this.state.value) {
            this.setState({value: value});
        }
    }

    toggleTodayButton(show){
        this.setState({showTodayButton: show});
    }

    toggleNowButton(show){
        this.setState({showNowButton: show});
    }

    toggleSuggestions(show){
        this.setState({showNowButton: show, showTodayButton: show});
    }

    dateValidation(formatErrors){
        this.props.renderErrors(formatErrors);
    }
    
    adoptTodayString(adoptTodayString){
        this.setState({adoptTodayString: adoptTodayString});
    }

    render() {
        let subcells = this.props.renderSubcells();
        let isMandatory = this.props.formDetail.form.isMandatory(this.props.formDetail.identifier);
        let dateElement = <FormDateTime dateSelected={this.handleSelectedDate} value={this.state.value} withTime={true} isMandatory={isMandatory} hasError={this.props.hasError == undefined ? false : this.props.hasError} toggleNowButton={this.toggleNowButton} toggleTodayButton={this.toggleTodayButton} toggleSuggestions={this.toggleSuggestions} adoptTodayString={this.adoptTodayString} dateValidation={this.dateValidation}/>;

        let suggestionStyle = {
            display: 'flex',
            justifyContent: 'flex-end',
        };

        let buttonContianerStyle = {
            marginTop: '1em',
            marginLeft: '0.5em',
        };

        let helperButton;
        
        if(this.state.value == null && ((this.state.showNowButton) || (this.state.showTodayButton))) {
            helperButton =
            <div style={suggestionStyle}>
                {this.state.showTodayButton &&
                 <div style={buttonContianerStyle}> 
                     <FormButton title={'Today'} enabled={true} isSelected={false} onPress={this.adoptTodaySuggestion} />
                 </div>
                }
                {this.state.showNowButton && 
                    <div style={buttonContianerStyle}> 
                        <FormButton title={'Now'} enabled={true} isSelected={false} onPress={this.adoptSuggestion} />
                    </div>
                }
            </div>;
        }

        return (<FormContentView identifier={this.props.formDetail.identifier} isSubcell={this.props.isSubcell} titleString={this.props.formDetail.title} isMandatory={isMandatory} formattedDate={this.props.formDetail.dateFormat}  detailString={this.props.formDetail.detail} validation={this.props.errorLabels} rightControl={dateElement} subcell={subcells} bottomControl={helperButton} subCellbackgroundColor={FormsStyles.backgroundColor} showHover={true}/>);
    }
}

DateAndTimeFormDetail.propTypes = {
    formDetail: PropTypes.object.isRequired,
    isSubcell: PropTypes.bool.isRequired,
    renderErrors: PropTypes.func.isRequired,
    renderSubcells: PropTypes.func.isRequired,
    errorLabels: PropTypes.array.isRequired,
    hasError: PropTypes.bool.isRequired
};