import { CalculationModel } from '../Custom Types/Calculation';
import { QuidelChestPainCalculationHelper } from '../Helpers/QuidelChestPainCalculationHelper';
import { probabilityOfMACE, edacsTotal } from './EDChestPain-CalculationData';
import { redux } from '../../../App';
import { saveCalculation } from '../../../components/state/actions/CalculationActions';
import { removeResultsAlertValue, postResultsAlert, postResultsBadge } from '../../../components/state/actions/NotificationsActions';

export class QDChestPainCalculations {
    update(pathwayId, workflow, calculations, form) {
        // Refer to mainForm or the parent of the form (mainForm)
        let mainForm = form.parent != null ? form.parent : form;

        let progress = mainForm.completionProgress(['followUpBloodsDateAndTime', 'followUpBloodsTroponin']);
        
        let formComplete = (progress.current / progress.total) == 1;

        let patientEligable = workflow.rawData['patientSex'] != 'genderOtherOption';

        let edacsScore = null;
        let edacsRequiresUpdate = false;

        let riskBracket = null;
        let riskBracketRequiresUpdate = false;

        let edacsCompletionProgress = mainForm.subform('initialAssessment').completionProgress();
        let edacsCompleted = (edacsCompletionProgress.current / edacsCompletionProgress.total) == 1;
        if (edacsCompleted && patientEligable) {
            let calculatedEDACSErrors = QuidelChestPainCalculationHelper.calcualtionError('calculatedEDACS', workflow.rawData, form);
            edacsScore = calculatedEDACSErrors == null ? edacsTotal(workflow.rawData) : null;
        }

        if (workflow.rawData['calculatedEDACS'] != edacsScore) {
            edacsRequiresUpdate = true;
        }

        if (edacsRequiresUpdate) {
            let edacsCalculation = new CalculationModel(workflow.uuid, workflow.versionUuid, edacsScore, false, CalculationModel.CalculationType.edacs, new Date());
            redux.store.dispatch(saveCalculation(pathwayId, edacsCalculation, calculations));
            workflow.rawData['calculatedEDACS'] = edacsScore;
        }

        if (formComplete && patientEligable) {
            let riskBracketCalculationErrors = QuidelChestPainCalculationHelper.calcualtionError('pathwayRiskCategory', workflow.rawData, form);
            riskBracket = riskBracketCalculationErrors == null ? QuidelChestPainCalculationHelper.getRiskBracket(workflow.rawData) : null;
        }

        if (riskBracket != null) {
            if ((riskBracket.type != workflow.rawData['pathwayRiskCategory'])) {
                riskBracketRequiresUpdate = true;
            }
        } else if (workflow.rawData['pathwayRiskCategory'] != null) {
            riskBracketRequiresUpdate = true;
        }

        if (riskBracketRequiresUpdate) {
            let riskBracketCalculation = new CalculationModel(workflow.uuid, workflow.versionUuid, riskBracket == null ? null : riskBracket.type, false, CalculationModel.CalculationType.riskCategory, new Date());
            redux.store.dispatch(saveCalculation(pathwayId, riskBracketCalculation, calculations));
            workflow.rawData['pathwayRiskCategory'] = riskBracket == null ? null : riskBracket.type;
        }

        let lastViewedCalculations = {};
        if (redux.store.getState().calculationReducer.lastObservedCalculations[pathwayId] != null) {
            lastViewedCalculations = JSON.parse(JSON.stringify(redux.store.getState().calculationReducer.lastObservedCalculations[pathwayId]));
        }

        let changesStrings = [];
        if (edacsRequiresUpdate || riskBracketRequiresUpdate) {
            if (edacsRequiresUpdate) {
                let lastEdacsValueKey = Object.keys(lastViewedCalculations).filter((calculation) => {
                    return lastViewedCalculations[calculation].type == CalculationModel.CalculationType.edacs;
                });

                if ((lastEdacsValueKey.length == 0 && workflow.rawData['calculatedEDACS'] != null)) {
                    changesStrings.push('EDACS Score');
                } else if (lastEdacsValueKey.length > 0 && lastViewedCalculations[lastEdacsValueKey[0]].value != workflow.rawData['calculatedEDACS']) {
                    changesStrings.push('EDACS Score');
                } else {
                    redux.store.dispatch(removeResultsAlertValue(pathwayId, 'EDACS Score'));
                }
            }

            if (riskBracketRequiresUpdate) {
                let lastRiskCategoryKey = Object.keys(lastViewedCalculations).filter((calculation) => {
                    return lastViewedCalculations[calculation].type == CalculationModel.CalculationType.riskCategory;
                });

                if (lastRiskCategoryKey.length == 0 && workflow.rawData['pathwayRiskCategory'] != null) {
                    changesStrings.push('Pathway Risk Category');
                } else if(lastRiskCategoryKey.length > 0 &&  lastViewedCalculations[lastRiskCategoryKey[0]].value != workflow.rawData['pathwayRiskCategory']) {
                    changesStrings.push('Pathway Risk Category');
                } else {
                    redux.store.dispatch(removeResultsAlertValue(pathwayId, 'Pathway Risk Category'));
                }
            }
        }

        if (changesStrings.length > 0) {
            redux.store.dispatch(postResultsAlert(pathwayId, changesStrings));
            redux.store.dispatch(postResultsBadge(pathwayId));
        }
    }
}