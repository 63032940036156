import { saveWorkflow } from '../../../components//state/actions/WorkflowResponseActions';
import { redux } from '../../../App';
import { checkAndUpdateForAction } from '../../../components/state/actions/ActionActions';
import { ActionModel } from '../../Shared Custom Types/ActionModel';
import { ACCMRIActionItem } from './ACCMRIActionItem';

export class ACCMRIActions {

    constructor(formLoader) {
        this.formLoader = formLoader;
    }

    update(pathwayId, workflow, oldActions, form) {
        let data = workflow.rawData;
        let requiredActions = [];

        let action = this.getAcceptanceCriteria(data);

        let requestCriteriaForm = this.formLoader.form(pathwayId, 'requestCriteria');
        
        let requestCriteriaFormCompletionProgress = requestCriteriaForm.completionProgress();
        let isRequestCriteriaFormComplete = false;
        if(requestCriteriaFormCompletionProgress != null && requestCriteriaFormCompletionProgress != undefined){
            isRequestCriteriaFormComplete = (requestCriteriaFormCompletionProgress.current / requestCriteriaFormCompletionProgress.total) == 1;
        }
        
        let areFormsForInitialOutcomeFormComplete = isRequestCriteriaFormComplete;

        redux.store.dispatch(saveWorkflow(pathwayId, {'mriRequestOutcome': action}, workflow, false, form, 0));
        let titleString = action != null ? action.getTitle() : null;
        let detail = action != null ? action.getLongDetail() : null;
        let mriRequestOutcomeAction = new ActionModel(workflow.pathwayUuid, workflow.versionUuid, 'mriRequestOutcome', titleString, detail, 0, false, false, Date.now(), null);
        let reconsiledMRIRequestOutcomeAction = checkAndUpdateForAction(pathwayId, action != null && areFormsForInitialOutcomeFormComplete , mriRequestOutcomeAction, 'mriRequestOutcome', oldActions);
        if (reconsiledMRIRequestOutcomeAction != null) {
            requiredActions.push(reconsiledMRIRequestOutcomeAction);
        }
        
        return requiredActions;
    }

    getAutoAcceptedValues() {
        return [];
    }

    getAcceptanceCriteria(data){
        let acceptanceCriteria = [];
        let rejectionCriteria = [];

        if (data['pacemaker'] == true || data['metalware'] == true || data['cochlearImplants'] == true || data['claustrophobia'] == true || data['worksWithMetalOrbitCheck'] == true) {
            if (data['contactRadiologyProvider'] == false && (data['radiologyClearedPatient'] != true && data['radiologyClearedPatient'] != false)) {
                return new ACCMRIActionItem(ACCMRIActionItem.Acceptance.awaitingRecommendation, [], null); 
            }
            if (data['radiologyClearedPatient'] == false) {
                return new ACCMRIActionItem(ACCMRIActionItem.Acceptance.notAvailable, ['Patient not cleared for MRI'], null);
            }
        }
        if (data['lumbarSpineInjury'] == true) {
            if (data['LSI_featuresOfCaudaEquinaSyndrome'] == true || data['LSI_abdominalAorticAneurysm'] == true || data['LSI_infection'] == true) {
                return new ACCMRIActionItem(ACCMRIActionItem.Acceptance.notAvailable, ['Indication of urgent red flag(s)']);
            } else if (data['LSI_cancer'] == true || data['LSI_fracture'] == true || data['LSI_spondyloarthropathy'] == true) {
                return new ACCMRIActionItem(ACCMRIActionItem.Acceptance.notAvailable, ['Indication of non-urgent red flag(s)'], null);
            } else if (data['LSI_RF_managePerAcuteClinicalManagementPathways'] == false && (data['LSI_persistentPain'] != true && data['LSI_persistentPain'] != false)) {
                return new ACCMRIActionItem(ACCMRIActionItem.Acceptance.awaitingRecommendation, [], null);
            } else if (data['LSI_persistentPain'] == false) {
                return new ACCMRIActionItem(ACCMRIActionItem.Acceptance.notAvailable, ['No indication of persistent pain 6 weeks post-injury'], null);
            } else if (data['LSI_areaOfDominantPain'] == 'LSI_areaOfDominantPainBack') {
                return new ACCMRIActionItem(ACCMRIActionItem.Acceptance.notAvailable, ['Persistent back pain not indicative of MRI'], null);
            } else if (data['LSI_areaOfDominantPain'] == 'LSI_areaOfDominantPainLeg') {
                if (data['LSI_radiculopathy'] == true) {
                    acceptanceCriteria.push('Persistent leg pain 6 weeks post-injury and presenting with radiculopathy');
                }
                if (data['LSI_RadicularPain'] == true) {
                    acceptanceCriteria.push('Persistent leg pain 6 weeks post-injury and presenting with radicular pain');
                } else if (data['LSI_radiculopathy'] == false && data['LSI_RadicularPain'] == false) {
                    return new ACCMRIActionItem(ACCMRIActionItem.Acceptance.notAvailable, ['Persistent leg pain which is neither radiculopathy nor radicular pain is not indicative of MRI'], null);
                }
            }
        } else if (data['cervicalSpineInjury'] == true) {
            if (data['CSI_severeWorseningUnrelentingPainEspeciallyAtNightOrWhenLyingDown'] == true || data['CSI_bilateralSignsAndSymptoms'] == true || data['CSI_severeNeurologicalDysfunction'] == true 
            || data['CSI_infection'] == true || data['CSI_cancer'] == true || data['CSI_fracture'] == true) {
                return new ACCMRIActionItem(ACCMRIActionItem.Acceptance.notAvailable, ['Indication of urgent red flag(s)'], null);
            } else if (data['CSI_canadianCSpineRuleRecommendsImaging'] == true) {
                if (data['CSI_FA1_xRay'] == false && (data['CSI_xRayConfirmsFracture'] != true && data['CSI_xRayConfirmsFracture'] != false)) {
                    return new ACCMRIActionItem(ACCMRIActionItem.Acceptance.awaitingRecommendation, [], null);
                } else if (data['CSI_xRayConfirmsFracture'] == true) {
                    return new ACCMRIActionItem(ACCMRIActionItem.Acceptance.notAvailable, ['Recommendations from X-ray findings do not include MRI'], null);
                } else if (data['CSI_FA1_painManagementAndActiveRehabilitation'] == false && (data['CSI_FA2_persistentPain'] != true && data['CSI_FA2_persistentPain'] != false)) {
                    return new ACCMRIActionItem(ACCMRIActionItem.Acceptance.awaitingRecommendation, [], null);
                } else if (data['CSI_FA2_persistentPain'] == false) {
                    return new ACCMRIActionItem(ACCMRIActionItem.Acceptance.notAvailable, ['No indication of persistent pain 6 weeks post-injury'], null);
                }
            } else if (data['CSI_canadianCSpineRuleRecommendsImaging'] == false) {
                if (data['CSI_FA1_painManagementAndActiveRehabilitation'] == false && (data['CSI_FA2_persistentPain'] != true && data['CSI_FA2_persistentPain'] != false)) {
                    return new ACCMRIActionItem(ACCMRIActionItem.Acceptance.awaitingRecommendation, [], null);
                } else if (data['CSI_FA2_persistentPain'] == false) {
                    return new ACCMRIActionItem(ACCMRIActionItem.Acceptance.notAvailable, ['No indication of persistent pain 6 weeks post-injury'], null);
                }
            }
            if (data['CSI_FA2_radiculopathy'] == true) {
                acceptanceCriteria.push('Persistent pain 6 weeks post-injury and presenting with radiculopathy');
            }
            if (data['CSI_FA2_RadicularPain'] == true) {
                acceptanceCriteria.push('Persistent pain 6 weeks post-injury and presenting with radicular pain');
            } else if (data['CSI_FA2_radiculopathy'] == false && data['CSI_FA2_RadicularPain'] == false) {
                return new ACCMRIActionItem(ACCMRIActionItem.Acceptance.notAvailable, ['Persistent pain which is neither radiculopathy nor radicular pain is not indicative of MRI'], null);
            }
        } else if (data['ghjOrInstability'] == true) {
            if (data['GH_unexplainedDeformityOrSwelling'] == true || data['GH_pulmonaryOrVascularCompromise'] == true || data['GH_bilateralNeurologicalSymptoms'] == true || data['GH_severeNeurologicalDysfunction'] == true 
            || data['GH_infection'] == true || data['GH_cancer'] == true || data['GH_fracture'] == true) {
                return new ACCMRIActionItem(ACCMRIActionItem.Acceptance.notAvailable, ['Indication of urgent red flag(s)'], null);
            } else if (data['GH_suspicionOfFractureOrGHJI'] == true) {
                if (data['GH_FA1_xRays'] == false && (data['GH_FA1_xRayConfirmsFracture'] != true && data['GH_FA1_xRayConfirmsFracture'] != false)) {
                    return new ACCMRIActionItem(ACCMRIActionItem.Acceptance.awaitingRecommendation, [], null);
                } else if (data['GH_FA1_xRayConfirmsFracture'] == true) {
                    return new ACCMRIActionItem(ACCMRIActionItem.Acceptance.notAvailable, ['Recommendations from X-ray findings do not include MRI'], null);
                } else if (data['GH_FA1_xRayConfirmsFracture'] == false) {
                    return new ACCMRIActionItem(ACCMRIActionItem.Acceptance.notAvailable, ['Recommendations from X-ray findings do not include MRI'], null);
                }
            } else if (data['GH_rotatorCuffTear'] == true) {
                if (data['GH_FA1_xRaysUltrasound'] == false && (data['GH_massiveRotatorCuffTear'] != true && data['GH_massiveRotatorCuffTear'] != false)) {
                    return new ACCMRIActionItem(ACCMRIActionItem.Acceptance.awaitingRecommendation, [], null);
                } else if (data['GH_massiveRotatorCuffTear'] == true) {
                    if (data['GH_physiologicallyHealthyActiveAndSurgicalCandidate'] == true) {
                        if (data['GH_FA2_requestSpecialistAssessment'] == false && (data['GH_hasSpecialistRecommendedMRI'] != true && data['GH_hasSpecialistRecommendedMRI'] != false)) {
                            return new ACCMRIActionItem(ACCMRIActionItem.Acceptance.awaitingRecommendation, [], null);
                        } else if (data['GH_hasSpecialistRecommendedMRI'] == true) {
                            return new ACCMRIActionItem(ACCMRIActionItem.Acceptance.specialistAssessment, ['Presenting with potentially eligible rotator cuff findings'], null);
                        } else if (data['GH_hasSpecialistRecommendedMRI'] == false) {
                            return new ACCMRIActionItem(ACCMRIActionItem.Acceptance.notAvailable, [], null);
                        }
                    } else if (data['GH_physiologicallyHealthyActiveAndSurgicalCandidate'] == false) {
                        return new ACCMRIActionItem(ACCMRIActionItem.Acceptance.notAvailable, [], null);
                    }
                } else if (data['GH_massiveRotatorCuffTear'] == false) {
                    return new ACCMRIActionItem(ACCMRIActionItem.Acceptance.notAvailable, [], null);
                }
            }  else if (data['GH_subacromialBursitisImpingementSyndrome'] == true || data['GH_osteoarthritis'] == true || data['GH_frozenShoulder'] == true) {
                return new ACCMRIActionItem(ACCMRIActionItem.Acceptance.notAvailable, ['Further recommendations are dependent on X-ray & Ultrasound findings'], null);
            } else if (data['GH_suspicionOfFractureOrGHJI'] == false && data['GH_rotatorCuffTear'] == false && data['GH_subacromialBursitisImpingementSyndrome'] == false && data['GH_osteoarthritis'] == false && data['GH_frozenShoulder'] == false) {
                return new ACCMRIActionItem(ACCMRIActionItem.Acceptance.notAvailable, [], null);
            }
        } else if (data['postTraumaticKneePain'] == true) {
            if (data['KP_ottawaKneeRulesRecommendImaging'] == true) {
                if (data['KP_FA1_xRays'] == false && (data['KP_xRayConfirmsFracture'] != true && data['KP_xRayConfirmsFracture'] != false) && (data['KP_suspectOccultFracture'] != true && data['KP_suspectOccultFracture'] != false)) {
                    return new ACCMRIActionItem(ACCMRIActionItem.Acceptance.awaitingRecommendation, [], null);
                } else if (data['KP_xRayConfirmsFracture'] == true || data['KP_suspectOccultFracture'] == true) {
                    return new ACCMRIActionItem(ACCMRIActionItem.Acceptance.notAvailable, ['Recommendations from X-ray findings do not include MRI'], null);
                }
            }
            if (data['KP_acutelyLockedKnee'] == true) {
                acceptanceCriteria.push('Suspicion of acutely locked knee');
            }
            if (data['KP_suspectAclLclRupture'] == true) {
                acceptanceCriteria.push('Suspicion of ACL / LCL rupture');
            } else if (data['KP_suspectMeniscalLesion'] == true) {
                if (data['KP_ML_StandingXray'] == false && (data['KP_moderateToSevereOA'] != true && data['KP_moderateToSevereOA'] != false)) {
                    return new ACCMRIActionItem(ACCMRIActionItem.Acceptance.awaitingRecommendation, [], null);
                } else if (data['KP_moderateToSevereOA'] == true) {
                    return new ACCMRIActionItem(ACCMRIActionItem.Acceptance.notAvailable, ['Recommendations for OA primary diagnosis do not include MRI'], null);
                } else if (data['KP_ML_ActiveRehabilitation'] == false && (data['KP_improvementAfterActiveRehabilitation'] != true && data['KP_improvementAfterActiveRehabilitation'] != false)) {
                    return new ACCMRIActionItem(ACCMRIActionItem.Acceptance.awaitingRecommendation, [], null);
                } else if (data['KP_improvementAfterActiveRehabilitation'] == false && data['KP_moderateToSevereOA'] == false) {
                    acceptanceCriteria.push('Suspicion of meniscal lesion and no diagnosis of OA');
                } else if (data['KP_improvementAfterActiveRehabilitation'] == true) {
                    return new ACCMRIActionItem(ACCMRIActionItem.Acceptance.notAvailable, [], null);
                }
            } else if (data['KP_suspectMCLPCLRupture'] == true) {
                return new ACCMRIActionItem(ACCMRIActionItem.Acceptance.notAvailable, ['Recommendations for suspected MCL/PCL rupture do not include MRI'], null);
            } else if (data['KP_suspectPLCInjury'] == true) {
                return new ACCMRIActionItem(ACCMRIActionItem.Acceptance.notAvailable, ['Recommendations for suspected PLC rupture do not include MRI'], null);
            } else if (data['KP_acutelyLockedKnee'] == false && data['KP_suspectAclLclRupture'] == false && data['KP_suspectMeniscalLesion'] == false && data['KP_suspectMCLPCLRupture'] == false && data['KP_suspectPLCInjury'] == false) {
                return new ACCMRIActionItem(ACCMRIActionItem.Acceptance.notAvailable, ['Assessment for ligamentous and meniscal pathology does not indicate MRI'], null);
            }
        }
        
        if (acceptanceCriteria.length > 0) {
            return new ACCMRIActionItem(ACCMRIActionItem.Acceptance.mriEligible, acceptanceCriteria, null);
        } else if (rejectionCriteria.length > 0) {
            return new ACCMRIActionItem(ACCMRIActionItem.Acceptance.notAvailable, rejectionCriteria, null);
        }
    }
}