import axios, { AxiosError } from "axios";
import { ErrorResponseDataModel } from "../models/ErrorResponseDataModel";

export const getDisplayErrorMessage = (err: Error | AxiosError) => {
  // Return the title, detail, and status if the error response contains them.
  if (axios.isAxiosError(err)) {
    try {
      if (err.response) {
        const errorBody = err.response.data as ErrorResponseDataModel;
        if (errorBody && (errorBody.title || errorBody.detail) && errorBody.status) {
          return `${errorBody.title ?? ""} ${errorBody.detail ?? ""} (Status: ${errorBody.status})`;
        }
      }
    } catch (e) {
      console.log("(Warning) Failed to convert error response");
      console.log(e);
    }
  }
  return err.message;
};
