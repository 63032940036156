import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { FormContentView } from '../../FormsUI/FormsUI Components/Containers/FormContentView';
import { FormsStyles } from '../../FormsUI/FormsUI';
import { FormDetailWithReact } from '../FormDetailWithReact';
import { FormPie } from '../../FormsUI/FormsUI Components/Controls/FormPie';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FormButton } from '../../FormsUI/FormsUI Components/Controls/FormButton';
import { FormBadge } from '../../FormsUI/FormsUI Components/Controls/FormBadge';
import { addExpandedCell, removeExpandedCell } from '../../../components/state/actions/SubformStateActions';
import { Chevron } from '../../../components/shared/Chevron';

class SubformFormDetail extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            isOpen: false,
            value: true,
            workflow: props.workflows[props.formDetail.form.pathwayId]
        };

        this.toggleSubcellVisibility = this.toggleSubcellVisibility.bind(this);
        this.handleClick = this.handleClick.bind(this);
        this.checkForState = this.checkForState.bind(this);
    }

    componentDidMount() {
        this.checkForState();
        document.addEventListener('click', this.handleClick);
    }

    componentWillUnmount() {
        document.removeEventListener('click', this.handleClick);
    }
    
    componentDidUpdate(prevProps){
        this.checkForState();

        if(JSON.stringify(this.props.workflows[this.props.formDetail.form.pathwayId]) !== JSON.stringify(prevProps.workflows[this.props.formDetail.form.pathwayId])){
            this.setState({ workflow: this.props.workflows[this.props.formDetail.form.pathwayId]} );
        }
    }

    checkForState(){
        if(this.props.expandedCells[this.props.formDetail.form.pathwayId] != null){
            if(this.props.expandedCells[this.props.formDetail.form.pathwayId].includes(this.props.formDetail.identifier) && !this.state.isOpen){
                this.setState({isOpen: true});
            } else if(!this.props.expandedCells[this.props.formDetail.form.pathwayId].includes(this.props.formDetail.identifier) && this.state.isOpen){
                this.setState({isOpen: false});
            }
        } else if (this.state.isOpen){
            this.setState({isOpen: false});
        }
    }

    toggleSubcellVisibility() {
        if(this.state.isOpen){
            this.props.removeExpandedCell(this.props.formDetail.form.pathwayId, [this.props.formDetail.identifier], this.props.expandedCells);
        } else {
            this.props.addExpandedCell(this.props.formDetail.form.pathwayId, this.props.formDetail.identifier, this.props.expandedCells);
        }

        this.setState((prevState) => ({
            isOpen: !prevState.isOpen
        }));
    }

    renderSubcells() {
        let form = this.props.formDetail.form.subform(this.props.formDetail.identifier);

        let style = {
            marginTop: '1em',
            marginBottom: '0.5em',
            fontWeight: '600',
            fontSize: '1.2em',
            color: 'black',
        };

        return form.sectionIds.map((sectionID) => {
            return (
                <div key={sectionID} style={{cursor: 'default'}}>
                    {this.state.isOpen && form.sectionReference(sectionID).title != null && <div style={style}>{form.sectionReference(sectionID).title}</div>}
                    {   
                        form.sectionReference(sectionID)['section.childIDs'].map((childID) => {
                            if(form.shouldDisplayFormDetailForIdentifier(childID) && this.state.isOpen){
                                let formDetail = this.props.formLoader.formDetail(this.props.formDetail.form.pathwayId, childID);

                                let isLastItem = form.sectionReference(sectionID)['section.childIDs'][form.sectionReference(sectionID)['section.childIDs'].length - 1] === childID;

                                let rawData;
                                if ((this.state.workflow != null && this.state.workflow != undefined) && this.state.workflow.rawData != null && this.state.workflow.rawData != undefined) {
                                    rawData = this.state.workflow.rawData[childID] != null ? this.state.workflow.rawData[childID] : moment().valueOf(); // Do not remove! Subforms will not trigger render if the value has not changed (eg. null to null). We set the rawData value to a changing value ie. current time, to trigger the render
                                } else {
                                    rawData = null;
                                }
                                    
                                return <FormDetailWithReact formLoader={this.props.formLoader} key={childID} formDetail={formDetail} isSummary={false} rawValue={rawData} isSubcell={true} isLast={isLastItem} showHover={form.sectionReference(sectionID)['section.childIDs'].length != 1}/>;
                            }
                        })
                    }
                </div>
            );
        });
    }

    handleClick(e) {
        let containsTarget = false;

        if (this.node != null && this.node != undefined) {
            if (this.node.contains(e.target)) {
                containsTarget = true;
            }
        }

        if (this.buttonNode != null && this.buttonNode != undefined) {
            if (this.buttonNode.contains(e.target)) {
                containsTarget = true;
            }
        }

        if (this.subcellNode != null && this.subcellNode != undefined) {
            if (this.subcellNode.contains(e.target)) {
                containsTarget = false;
            }
        }

        if (containsTarget) {
            this.toggleSubcellVisibility();

            // window.gtag('event', 'toggled-subform-visibility', {
            //     event_category: 'action',
            //     event_label: 'toggled-subform-visibility'
            // });
        }
    }

    render() {
        this.props.renderErrors();

        let progress = this.props.formDetail.form.subform(this.props.formDetail.identifier).completionProgress();

        let subformHeaderStyle = {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            alignContent: 'stretch'
        };

        let subformHeaderButton = 
        <div style={subformHeaderStyle} ref={buttonNode => this.buttonNode = buttonNode} >
            {progress.current > 0 && <FormPie totalValue={progress.total} filledValue={progress.current} />}
            <FormButton textAlignment={'left'} image={<Chevron angle={this.state.isOpen ? 'rotate(180deg)' : 'rotate(0deg)'}/>} activeBackgroundColor={'transparent'} backgroundColor={'transparent'} enabled={true} isSelected={false} title={this.props.formDetail.title} />
        </div>;

        let badge;
        let badgeDetails = this.props.formDetail.form.getBadgeValue(this.props.formDetail.identifier);
        if(badgeDetails != null && badgeDetails.length > 0){
            badge = <FormBadge value={badgeDetails[0].value} color={badgeDetails[0].color} solid={badgeDetails[0].solid} />;
        }

        let detailString;
        if(!this.state.isOpen){
            detailString = this.props.formDetail.form.subform(this.props.formDetail.identifier).detailString();
        } else {
            detailString = this.props.formDetail.form.subform(this.props.formDetail.identifier).detail;
        } 

        return (
            <div style={{cursor: !this.state.isOpen ? 'pointer' : 'pointer', marginTop: this.props.isSubcell ? '0' : '1em'}} ref={node => this.node = node}>
                <FormContentView identifier={this.props.formDetail.identifier} topLeftControl={subformHeaderButton} rawValue={this.state.workflow} subcellVisible={this.state.isOpen} topRightControl={badge} validation={this.props.errorLabels} subcell={this.renderSubcells()} detailString={detailString} activeColor={FormsStyles.primaryTint} isSubcell={this.props.isSubcell} subcellRef={subcellNode => this.subcellNode = subcellNode} backgroundColor={!this.props.isSubcell ? FormsStyles.backgroundColor : null} subcellHeight={this.state.isOpen ? '10000px' : '0px'} subcellBorder={true}/>
            </div>
        );
    }
}

SubformFormDetail.propTypes = {
    formDetail: PropTypes.object.isRequired,
    isSubcell: PropTypes.bool.isRequired,
    addExpandedCell: PropTypes.func.isRequired, 
    removeExpandedCell: PropTypes.func.isRequired,
    expandedCells: PropTypes.object.isRequired,
    workflows: PropTypes.object.isRequired,
    renderErrors: PropTypes.func.isRequired,
    renderSubcells: PropTypes.func.isRequired,
    errorLabels: PropTypes.array.isRequired,
    hasError: PropTypes.bool.isRequired
};

// CONFIGURE REACT REDUX
const mapStateToProps = state => {
    const { workflows } = state.workflowReducer;
    const { expandedCells } = state.subformStateReducer;
    return { expandedCells, workflows};
};

const mapDispatchToProps = dispatch => (
    bindActionCreators({ addExpandedCell, removeExpandedCell }, dispatch)
);

const hoc =connect(mapStateToProps, mapDispatchToProps)(SubformFormDetail);

// EXPORT COMPONENT
export { hoc as SubformFormDetail };