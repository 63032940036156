import { FormWithRedux } from '../../FormWithRedux';
import { FormValidation } from '../../../waferJS/Form/Validation/FormValidation';
import { IsNumber, IsPositive } from '../../../waferJS/Form To FormsUI/Subclasses/Validation Overrides/DecimalNumberValidaton';
import { CheckHeartRate, CheckBodyTemperature, CheckOxygenSaturation, CheckRespiratoryRate, IsBloodPressure } from '../Validation Overrides/EDChestPain-PathwayValidation';
import { TotalEWS, calculateEWS, EWSCategory } from '../Custom Types/EWS';

export class EDChestPainVitalSignsForm extends FormWithRedux {

    constructor(formLoader, parent, actionUpdater, dependentValuesUpdater, calculationsUpdater) {
        super('vitalSigns',formLoader, parent, actionUpdater, dependentValuesUpdater, calculationsUpdater);
    }

    shouldDisplayFormDetailForIdentifier(identifier) {
        super.shouldDisplayFormDetailForIdentifier(identifier);

        if (this.getValue('areSignsNormal') == true) {
            let totalEWS = this.totalEWS();

            if (totalEWS != null && totalEWS != undefined) {
                if (totalEWS.totalCanDeclareNormal == false) {
                    if (identifier == 'patientCannotBeNormalTotal') {
                        return true;
                    }
                } else if (totalEWS.singleFieldCanDeclareNormal == false) {
                    if (identifier == 'patientCannotBeNormalSingleField') {
                        return true;
                    }
                }
            }
        }
        
        switch (identifier) {
            case 'heartRateEWS':
            case 'bloodPressureEWS':
            case 'bodyTemperatureEWS':
            case 'oxygenSaturationEWS':
            case 'respiratoryRateEWS':
            case 'supplementalOxygenEWS':
            case 'levelOfConsciousnessEWS': {
                let value = this.getValue(identifier.replace('EWS', ''));
                let ews = calculateEWS(identifier, value);
                return  ews != null && ews.type != EWSCategory.Type.white ;
            }
            case 'patientCannotBeNormalTotal':
            case 'patientCannotBeNormalSingleField':
                return false;
            default:
                return true;
        }
    }

    isEnabled(identifier) {
        if (identifier != null) {
            return true;
        }
        return false;
    }

    isMandatory(identifier) {
        if (identifier == 'areSignsNormal') {
            return this.getValue('areSignsNormal') == true;
        }

        let vitalSignIdentifiers = [
            'oxygenSaturation',
            'bodyTemperature',
            'respiratoryRate',
            'heartRate',
            'levelOfConsciousness',
            'supplementalOxygen',
            'bloodPressure'
        ];

        if (vitalSignIdentifiers.includes(identifier)) {
            let ewsIdentifiers = [
                'heartRateEWS',
                'bloodPressureEWS',
                'bodyTemperatureEWS',
                'oxygenSaturationEWS',
                'respiratoryRateEWS',
                'supplementalOxygenEWS',
                'levelOfConsciousnessEWS'
            ];
    
            let categories = ewsIdentifiers.map((identifier) => {
                let value = this.getValue(identifier.replace('EWS', ''));
                return calculateEWS(identifier, value);
            }).filter(n => n);

            if (categories.length > 0 || this.getValue('areSignsNormal') != true) {
                return true;
            }
        }

        return false;
    }

    getValueType(identifier) {
        if (identifier == 'heartRate' || identifier == 'bodyTemperature' || identifier == 'oxygenSaturation' || identifier == 'respiratoryRate') {
            return 'double';
        } else if (identifier == 'supplementalOxygen' || identifier == 'areSignsNormal') {
            return 'boolean';
        } else if (identifier == 'levelOfConsciousness') {
            return 'string';
        } else if (identifier == 'bloodPressure') {
            return 'bloodpressure';
        }

        return null;
    }

    getBadgeValue(identifier) {
        let value = this.getValue(identifier.replace('EWS', ''));
        let ews = calculateEWS(identifier, value);
        if (ews != null) {
            return [{
                color: ews.color,
                title: ews.title,
                value: ews.score,
                solid: true
            }];
        }
        return [];
    }

    totalEWS() {

        let identifiers = [
            'heartRateEWS',
            'bloodPressureEWS',
            'bodyTemperatureEWS',
            'oxygenSaturationEWS',
            'respiratoryRateEWS',
            'supplementalOxygenEWS',
            'levelOfConsciousnessEWS'
        ];

        let categories = identifiers.map((identifier) => {
            let value = this.getValue(identifier.replace('EWS', ''));
            return calculateEWS(identifier, value);
        }).filter(n => n);

        if (categories.length < identifiers.length) {
            return null;
        }

        let declaredNormal = this.getValue('areSignsNormal');
        return new TotalEWS(categories, declaredNormal);
    }

    requiredValidation() {
        // Even if a value is optional we check for notNil, that way we can verify if an optional value is notNil
        return [FormValidation.notNill];
    }

    submissionValidation(identifier) {
        if (identifier == 'oxygenSaturation') {
            return [IsPositive];
        } else if (identifier == 'bodyTemperature') {
            return [IsNumber];
        } else if (identifier == 'respiratoryRate') {
            return [IsNumber, IsPositive];
        } else if (identifier == 'heartRate') {
            return [IsNumber, IsPositive];
        }
    }

    editingValidation(identifier) {
        if (identifier == 'oxygenSaturation') {
            return [IsNumber, CheckOxygenSaturation];
        } else if (identifier == 'bodyTemperature') {
            return [IsNumber, CheckBodyTemperature];
        } else if (identifier == 'respiratoryRate') {
            return [IsNumber, CheckRespiratoryRate];
        } else if (identifier == 'heartRate') {
            return [IsNumber, CheckHeartRate];
        } else if (identifier == 'bloodPressure') {
            return [IsBloodPressure];
        }
        return [];
    }

    detailString() {
        var detailString;

        let formDetails = this.getFormDetails();

        let values = formDetails.map((formDetail) => {
            return this.abbreviatedString(formDetail.identifier, formDetail.valueDescription());
        });

        detailString = values.filter(n => n).join(', ');

        let declaredNormal = this.getValue('areSignsNormal');

        if (declaredNormal != null && declaredNormal == true) {
            detailString = ['Normal for this patient', detailString].join('\n');
        }

        if(detailString.length == 0){
            return this.detail;
        }

        return detailString;
    }

    abbreviatedString(identifier, value) {

        if (value == null) {
            return null;
        }

        switch (identifier) {
            case 'heartRate':
                return 'HR: ' + value;
            case 'bloodPressure':
                return 'BP: ' + value;
            case 'bodyTemperature':
                return 'T: ' + value + '℃';
            case 'oxygenSaturation':
                return 'SpO₂: ' + value + '%';
            case 'respiratoryRate':
                return 'RR: ' + value;
            case 'supplementalOxygen':
                return 'Supplemental oxygen';
            case 'levelOfConsciousness':
                return 'Level of consciousness: ' + value;
            case 'heartRateEWS':
            case 'bloodPressureEWS':
            case 'bodyTemperatureEWS':
            case 'oxygenSaturationEWS':
            case 'respiratoryRateEWS':
            case 'supplementalOxygenEWS':
            case 'levelOfConsciousnessEWS':
                return null;
        }
    }

}