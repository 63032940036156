export var GallbladderSidebar = {
    root: {
        sections: [
            {
                name: 'Request',
                path: 'mainForm',
                icon: 'pathway',
                showPie: false,
                sections: [
                    {
                        name: 'Patient details',
                        path: 'patientDetails',
                        showPie: true,
                    },
                    {
                        name: 'Request criteria',
                        path: 'requestCriteria',
                        showPie: true,
                        
                    },
                    {
                        name: 'Further information',
                        path: 'furtherInformation',
                        showPie: true,
                    }
                ]
            },
            {
                name: 'Request outcome',
                icon: 'results',
                path: 'resultsForm',
                showPie: false
            }
        ]
    }
};