// IMPORT PACKAGE REFERENCES
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { PathwayReferenceHelper } from '../../../forms/PathwayReferenceHelper';
import { FormButton } from '../../../waferJS/FormsUI/FormsUI Components/Controls/FormButton';
import { FormLabel } from '../../../waferJS/FormsUI/FormsUI Components/Controls/FormLabel';
import { FormsStyles } from '../../../waferJS/FormsUI/FormsUI';
import { FormDetailWithReact } from '../../../waferJS/Form To FormsUI/FormDetailWithReact';
import { getPathwayIdFromURL } from '../../../helpers/URLHelper';
import { PILOT_PATHWAYS, ENVIRONMENT } from '../../../config/config';

// COMPONENT
class EnvironmentInfoOverlay extends React.Component {

    constructor(props) {
        super(props);

        let environmentFormName;

        switch (ENVIRONMENT) {
            case 'DEV':
                environmentFormName = 'devInformationForm';
                break;
            case 'PROD':
                if (PILOT_PATHWAYS.includes(getPathwayIdFromURL())) {
                    environmentFormName = 'pilotInformationForm';
                } else {
                    environmentFormName = 'pilotInformationForm';
                }
                break;
            case 'DEMO':
                environmentFormName = 'demoInformationForm';
                break;
            case 'TEST':
                environmentFormName = 'testInformationForm';    
                break;
            case 'STAGING':
                environmentFormName = 'stagingInformationForm';    
                break;
            default:
                environmentFormName = 'pilotInformationForm';
                break;
        }

        this.state = {
            form: props.formLoader.form('environmentInfoForm', environmentFormName)
        };

        this.dismiss = this.dismiss.bind(this);
    }

    dismiss(){
        this.props.toggleOverlay();
    }

    render() {
        return (
            <div className="session-wrapper">
                <div className="session-overlay" />
                <div className="environment-manager-container">
                    <div className="environment-card open" ref={sessionCardNode => this.sessionCardNode = sessionCardNode}>
                    
                        <div className="card-overlay-exit-button-container">
                            {<FormButton title={''} image={<img className={'session-card-exit'} src={require('../../../images/cross-blue.png')} />} backgroundColor={FormsStyles.backgroundColor} enabled={true} isSelected={false} onPress={this.dismiss}/>  }
                        </div>

                        <header className={'environment-title'}>{this.state.form.title}</header>
                        
                        <div className={'session-detail'}><FormLabel value={this.state.form.detail} fontSize={1} textColor={'#909294'}/></div>

                        <div className="session-body">
                            {this.state.form.sectionIds.map((sectionID) => {
                                let section = this.state.form.sectionReference(sectionID);
                                return (
                                    <div key={sectionID} className='form-section'>
                                        {section[PathwayReferenceHelper.Type.sectionChildIDs].map((childID) => { return this.state.form.shouldDisplayFormDetailForIdentifier(childID) ? true : null; }).filter(n => n).length > 0 && <div className='section-title'>{section.title}</div>}
                                        {section[PathwayReferenceHelper.Type.sectionChildIDs] != undefined &&
                                                    section[PathwayReferenceHelper.Type.sectionChildIDs].map((childID) => {
                                                        if (this.state.form.shouldDisplayFormDetailForIdentifier(childID)) {
                                                            let formDetail = this.state.form.formDetail(childID);
                                                            return <FormDetailWithReact formLoader={this.props.formLoader} key={formDetail.identifier} formDetail={formDetail} isSummary={false} isSubcell={false} showHover={true} />;
                                                        }
                                                    })
                                        }
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

EnvironmentInfoOverlay.propTypes = {
    toggleOverlay: PropTypes.func.isRequired,
};


// CONFIGURE REACT REDUX
const mapStateToProps = state => {
    return { state };
};

const mapDispatchToProps = dispatch => (
    bindActionCreators({  }, dispatch)
);

const hoc = connect(mapStateToProps, mapDispatchToProps)(EnvironmentInfoOverlay);

// EXPORT COMPONENT

export { hoc as EnvironmentInfoOverlay };