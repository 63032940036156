// IMPORT PACKAGE REFERENCES
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ENVIRONMENT, PILOT_PATHWAYS } from '../../config/config';
import { getPathwayIdFromURL } from '../../helpers/URLHelper';
import { withRouter } from '../routers/withRouter';

// COMPONENT
class Footer extends React.Component {

    getEnvironmentTitle(){
        if(ENVIRONMENT == 'PROD') {
            if (PILOT_PATHWAYS.includes(getPathwayIdFromURL())) {
                return 'Pilot Information';
            } else {
                return null;
            }
        } else if (ENVIRONMENT == 'DEMO'){
            return 'Demo Information';
        } else if(ENVIRONMENT == 'TEST'){
            return 'TEST Environment';
        } else if(ENVIRONMENT == 'Staging'){
            return 'Staging Environment';
        } else if(ENVIRONMENT == 'DEV'){
            return 'DEV Environment';
        }

        return null;
    }

    render() {
        return (
            <div className="footer-wrapper">
                <div className='footer-content'>
                    <div className="footer-links">
                        {this.getEnvironmentTitle() != null &&
                            <button onClick={this.props.showEnvironmentOverlay} tabIndex={this.props.isOverlayVisible ? '-1' : '0'}>{this.getEnvironmentTitle()}</button>
                        }
                        <a href={'https://wayfind.health/contact.html'} target="_blank" rel="noopener noreferrer" tabIndex={this.props.isOverlayVisible ? '-1' : '0'}>Provide Feedback</a>
                        <a href={'https://wayfind.health/contact.html'} target="_blank" rel="noopener noreferrer" tabIndex={this.props.isOverlayVisible ? '-1' : '0'}>Contact Us</a>
                        <a href={'https://smudge.com/privacy/'} target="_blank" rel="noopener noreferrer" tabIndex={this.props.isOverlayVisible ? '-1' : '0'}>Privacy Policy</a>
                    </div>
                    <div className="footer-copyright">Copyright © 2024 Smudge</div>
                </div>
            </div>  
        );
    }
}

Footer.propTypes = {
    location: PropTypes.object.isRequired,
    navigate: PropTypes.func.isRequired,
    showEnvironmentOverlay: PropTypes.func.isRequired,
    isOverlayVisible: PropTypes.bool.isRequired
};

// CONFIGURE REACT REDUX
const mapStateToProps = state => {
    return { state };
};

const mapDispatchToProps = dispatch => (
    bindActionCreators({  }, dispatch)
);

const hoc = withRouter(connect(mapStateToProps, mapDispatchToProps)(Footer));

// EXPORT COMPONENT

export { hoc as Footer };