import { Box, CircularProgress } from "@mui/material";
const LoadingPage: React.FC = () => {
  return (
    <Box
      sx={{
        display: "flex",
        width: "100%",
        mt: 40,
        justifyContent: "center",
        alignContent: "center",
      }}
    >
      <CircularProgress size="3rem" color="info" />
    </Box>
  );
};
export default LoadingPage;