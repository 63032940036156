import { FormWithRedux } from '../../FormWithRedux';

export class ACCMRI_KP_ACL_LCL_CriteriaForm extends FormWithRedux {

    constructor(formIdentifier, formLoader, parent, actionUpdater, dependentValuesUpdater, calculationsUpdater) {
        super(formIdentifier, formLoader, parent, actionUpdater, dependentValuesUpdater, calculationsUpdater);
    }

    shouldDisplayFormDetailForIdentifier(identifier) {
        super.shouldDisplayFormDetailForIdentifier(identifier);
        if(identifier == 'KP_ACL_NoToAll'){
            return this.unselectedExplicitFormDetailsCount(identifier) > 1;
        }
        return true;
    }

    isEnabled(identifier) {
        if (identifier != null) {
            return true;
        }
        return false;
    }

    isMandatory(identifier) {
        if (this.getValue('postTraumaticKneePain') == true) {
            if (identifier == 'KP_ACL_NoToAll'){
                return false;
            }

            let values = [
                this.getValue('KP_ACL_injuryMechanism'),
                this.getValue('KP_ACL_symptoms'),
                this.getValue('KP_ACL_signs'),
                this.getValue('KP_ACL_lachmanTestPositive'),
                this.getValue('KP_ACL_anteriorDrawTestPositive'),
                this.getValue('KP_LCL_laxityOnVarusStress')];

            if (values.includes(true) || values.includes(false)) {
                return true;
            }
        }

        return false;
    }

    getValueType() {
        return 'boolean';
    }

    submissionValidation() {
        return [];
    }

    detailString(isSubform) {
        let formDetails = this.getFormDetails();
        let values = formDetails.map((value) => {
            if (value.currentValue == true) {
                return value.title + ': Yes';
            }
        });

        if(values.filter(n => n).length == 0){
            return isSubform ? '' : this.detail;
        }

        if (isSubform == true) {
            return values.filter(n => n).join('\n  ');
        }
        return values.filter(n => n).join('\n');
    }

}