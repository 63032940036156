import { FormWithRedux } from '../../FormWithRedux';

export class ACCBISTOrientationCheck5Form extends FormWithRedux {

    constructor(formLoader, parent, actionUpdater, dependentValuesUpdater, calculationsUpdater) {
        super('orientationCheck5Subform', formLoader, parent, actionUpdater, dependentValuesUpdater, calculationsUpdater);
    }

    shouldDisplayFormDetailForIdentifier(identifier) {
        super.shouldDisplayFormDetailForIdentifier(identifier);
        return true;
    }

    isEnabled() {
        return true;
    }

    isMandatory(identifier) {
        return true;
    }

    getValueType(identifier) {
        return 'boolean';
    }

    submissionValidation() {
        return [];
    }

    editingValidation(identifier) {
        return [];
    }
}