import moment from 'moment';
import { checkAndUpdateForAction } from '../../../components/state/actions/ActionActions';
import { ActionModel, ScheduledAction } from '../../Shared Custom Types/ActionModel';
import { PatientSex } from '../../Shared Custom Types/Gender';
import { RiskCategoryBrackets } from '../Custom Types/Risk Category';
import { TroponinResult } from '../Custom Types/Troponin';
import { GPChestPainCalculationHelper } from '../Helpers/GPChestPainCalculationHelper';

export class GPChestPainActions {

    constructor(formLoader)
    {
        this.formLoader = formLoader;
    }

    update(pathwayId, workflow, oldActions, form) {
        let data = workflow.rawData;
        let requiredActions = [];

        // Refer to mainForm or the parent of the form (mainForm)
        let mainForm = form.parent != null ? form.parent : form;

        let progress = mainForm.completionProgress(['followUpBloodsDateAndTime', 'followUpBloodsTroponin']);
        let formComplete = (progress.current / progress.total) == 1;
        
        let initialAssessentProgress = (this.formLoader.form(pathwayId, 'initialAssessment')).completionProgress();
        let initialAssessmentComplete = (initialAssessentProgress.current / initialAssessentProgress.total) == 1;

        let patientProgress = (this.formLoader.form(pathwayId, 'patientDetails')).completionProgress();
        let patientComplete = (patientProgress.current / patientProgress.total) == 1;

        let clinicalQuestionProgress = mainForm.subform('highRiskDifferentialDiagnosis').completionProgress();
        let clinicalQuestionComplete = (clinicalQuestionProgress.current / clinicalQuestionProgress.total) == 1;

        let patientClinicalInitialComplete = clinicalQuestionComplete && patientComplete && initialAssessmentComplete;

        let initialTroponin = TroponinResult.newTroponinResult(data['initialBloodsTroponin']);
        let followUpTroponin = TroponinResult.newTroponinResult(data['followUpBloodsTroponin']);

        let acsManagementExclusion = data['patientSex'] == 'genderOtherOption' || data['clinicalSuspicionOfAcuteCoronorySyndrome'] == 'noneOption' || data['stemiPresent'] == true;
        
        //TotalEWS
        let totalEWS = data['totalEWS'];
        if (totalEWS != null) {
            if(totalEWS > 0 && totalEWS <=5){
                let action = new ActionModel(workflow.pathwayUuid, workflow.versionUuid, 'abnormalVitalSigns', null, null, 0, false, false, Date.now(), null);
                let reconsiledAction = checkAndUpdateForAction(pathwayId, action != null, action, 'abnormalVitalSigns', oldActions);
                if (reconsiledAction != null) {
                    requiredActions.push(reconsiledAction);
                }
            } else if (totalEWS >= 6 && totalEWS <= 7) {
                let action = new ActionModel(workflow.pathwayUuid, workflow.versionUuid, 'clinicalInstability', null, null, 0, false, false, Date.now(), null);
                let reconsiledAction = checkAndUpdateForAction(pathwayId, action != null, action, 'clinicalInstability', oldActions);
                if (reconsiledAction != null) {
                    requiredActions.push(reconsiledAction);
                }
            } else if (totalEWS >= 8 && totalEWS <= 9) {
                let action = new ActionModel(workflow.pathwayUuid, workflow.versionUuid, 'clinicalEmergencyDeterioration', null, null, 0, false, false, Date.now(), null);
                let reconsiledAction = checkAndUpdateForAction(pathwayId, action != null, action, 'clinicalEmergencyDeterioration', oldActions);
                if (reconsiledAction != null) {
                    requiredActions.push(reconsiledAction);
                }
            } else if (totalEWS >= 10) {
                let action = new ActionModel(workflow.pathwayUuid, workflow.versionUuid, 'clinicalEmergency', null, null, 0, false, false, Date.now(), null);
                let reconsiledAction = checkAndUpdateForAction(pathwayId, action != null, action, 'clinicalEmergency', oldActions);
                if (reconsiledAction != null) {
                    requiredActions.push(reconsiledAction);
                }
            }
        }

        //HIGH-RISK DIFFERENTIAL DIAGNOSIS
        let highRiskDifferentialIndicators = [
            data['aorticDissectionPresent'],
            data['pulmonaryEmbolism'],
            data['pancreatitis'],
            data['pericarditis'],
            data['pneumothorax'],
        ];
        
        let highRiskDifferentialAction = new ActionModel(workflow.pathwayUuid, workflow.versionUuid, 'highRiskDifferentialDiagnosisAction', null, null, 0, false, false, Date.now(), null);
        let reconsiledHighRiskDifferentialAction = checkAndUpdateForAction(pathwayId, highRiskDifferentialIndicators.includes(true) && (clinicalQuestionProgress.current / clinicalQuestionProgress.total) == 1, highRiskDifferentialAction, 'highRiskDifferentialDiagnosisAction', oldActions);
        if (reconsiledHighRiskDifferentialAction != null) {
            requiredActions.push(reconsiledHighRiskDifferentialAction);
        }

        //CLINICAL SUSPICION
        let clinicalSuspicionAction = new ActionModel(workflow.pathwayUuid, workflow.versionUuid, 'clinicalSuspicion', null, null, 0, false, false, Date.now(), null);
        let reconsiledClinicalSuspicionAction = checkAndUpdateForAction(pathwayId, data['clinicalSuspicionOfAcuteCoronorySyndrome'] == 'noneOption', clinicalSuspicionAction, 'clinicalSuspicion', oldActions);
        if (reconsiledClinicalSuspicionAction != null) {
            requiredActions.push(reconsiledClinicalSuspicionAction);
        }

        //STEMI
        let stemiPresent = data['stemiPresent'];
        let ecgCompletionProgress = mainForm.subform('ecgTest').completionProgress();
        let ecgComplete = (ecgCompletionProgress.current / ecgCompletionProgress.total) == 1;

        let stemiActionRequired = stemiPresent == true;
        let stemiAction = new ActionModel(workflow.pathwayUuid, workflow.versionUuid, 'stemiAction', null, null, 0, false, false, Date.now(), null);
        let reconsileStemiAction = checkAndUpdateForAction(pathwayId, stemiActionRequired && ecgComplete, stemiAction, 'stemiAction', oldActions);
        if (reconsileStemiAction != null) {
            requiredActions.push(reconsileStemiAction);
        }

        //Total Red Flags
        let redFlagActionRequired = data['totalRedFlags'] > 0;
        let clinicalFormProgress = (this.formLoader.form(pathwayId, 'highRiskDifferentialDiagnosis')).completionProgress();
        let clincialFormComplete = (clinicalFormProgress.current / clinicalFormProgress.total) == 1;
        let redFlagsAction = new ActionModel(workflow.pathwayUuid, workflow.versionUuid, 'reviewWithSMO', null, null, 0, false, false, Date.now(), null);
        let reconsiledRedFlagAction = checkAndUpdateForAction(pathwayId, redFlagActionRequired && clincialFormComplete && patientComplete, redFlagsAction, 'reviewWithSMO', oldActions);
        if (reconsiledRedFlagAction != null) {
            requiredActions.push(reconsiledRedFlagAction);
        }

        let displayingAnyACSManagementActions = false;

        let acsExclusionAction = new ActionModel(workflow.pathwayUuid, workflow.versionUuid, 'acsManagementExclusionMessage', null, null, 0, false, false, Date.now(), null);
        let reconciledACSExclusionAction = checkAndUpdateForAction(pathwayId, acsManagementExclusion, acsExclusionAction, 'acsManagementExclusionMessage', oldActions);
        if (reconciledACSExclusionAction != null) {
            requiredActions.push(reconciledACSExclusionAction);
        }
        
        let suspicionChoice = data['clinicalSuspicionOfAcuteCoronorySyndrome'];
        let shouldShowManagement = false;
        //let initialAssesmentProgress = form.parent.subform('initialAssessment').completionProgress();
        shouldShowManagement = ((clinicalQuestionProgress.current / clinicalQuestionProgress.total) == 1) && !acsManagementExclusion;
        let edacsOrSuspicionOrRedFlags = data['calculatedEDACS'] >= 16 || suspicionChoice == 'highOption' || redFlagActionRequired || highRiskDifferentialIndicators.includes(true);
        let shouldRequestAccuteCardiology = edacsOrSuspicionOrRedFlags && shouldShowManagement;
        if (shouldRequestAccuteCardiology) {
            displayingAnyACSManagementActions = true;
        }

        let requestAccuteCardiologyAssessmentAction = new ActionModel(workflow.pathwayUuid, workflow.versionUuid, 'accuteCardiologyAssessmentRequest', null, null, 0, false, false, Date.now(), null);
        let reconciledRequestAccuteCardiologyAssessmentAction = checkAndUpdateForAction(pathwayId, shouldRequestAccuteCardiology, requestAccuteCardiologyAssessmentAction, 'accuteCardiologyAssessmentRequest', oldActions);
        if (reconciledRequestAccuteCardiologyAssessmentAction != null) {
            requiredActions.push(reconciledRequestAccuteCardiologyAssessmentAction);
        }

        //MEDICATION
        let acsTreatmentAsprinAction = new ActionModel(workflow.pathwayUuid, workflow.versionUuid, 'acsTreatmentAsprin', null, null, 0, false, false, Date.now(), null);
        let reconciledACSTreatmentAsprinAction = checkAndUpdateForAction(pathwayId, shouldRequestAccuteCardiology, acsTreatmentAsprinAction, 'acsTreatmentAsprin', oldActions);
        if (reconciledACSTreatmentAsprinAction != null) {
            requiredActions.push(reconciledACSTreatmentAsprinAction);
        } 

        let arrangeAmbulanceTransferAction = new ActionModel(workflow.pathwayUuid, workflow.versionUuid, 'arrangeAmbulanceTransfer', null, null, 0, false, false, Date.now(), null);
        let reconciledArrangeAmbulanceTransferAction = checkAndUpdateForAction(pathwayId, shouldRequestAccuteCardiology, arrangeAmbulanceTransferAction, 'arrangeAmbulanceTransfer', oldActions);
        if (reconciledArrangeAmbulanceTransferAction != null) {
            requiredActions.push(reconciledArrangeAmbulanceTransferAction);
        }

        let provideInitialTreatmentForACSAction = new ActionModel(workflow.pathwayUuid, workflow.versionUuid, 'provideInitialTreatmentForACS', null, null, 0, false, false, Date.now(), null);
        let reconciledProvideInitialTreatmentForACSAction = checkAndUpdateForAction(pathwayId, shouldRequestAccuteCardiology, provideInitialTreatmentForACSAction, 'provideInitialTreatmentForACS', oldActions);
        if (reconciledProvideInitialTreatmentForACSAction != null) {
            requiredActions.push(reconciledProvideInitialTreatmentForACSAction);
        }

        let worstPainDateAndTime = data['worstDateTime'];
       

        if (worstPainDateAndTime == null || worstPainDateAndTime == undefined) {
            worstPainDateAndTime = data['firstDateTime'];
        }
        
        if(worstPainDateAndTime != null && worstPainDateAndTime != undefined){
            worstPainDateAndTime = moment(new Date(worstPainDateAndTime));
        }

        let isUnder72HoursSinceWorsePain = false;
        if(worstPainDateAndTime != null && worstPainDateAndTime != undefined){
            let worstPainPlus72Hours = moment(worstPainDateAndTime).add(72, 'hours');

            isUnder72HoursSinceWorsePain = worstPainPlus72Hours.isAfter(moment());
        }
        
        let observeInGeneralPractiseSurgeryAction = new ActionModel(workflow.pathwayUuid, workflow.versionUuid, 'observeInGeneralPractiseSurgery', null, null, 0, false, false, Date.now(), null);
        let shouldObserveInGP = initialTroponin == null && !edacsOrSuspicionOrRedFlags && isUnder72HoursSinceWorsePain && shouldShowManagement && initialAssessmentComplete;
        if (shouldObserveInGP) {
            displayingAnyACSManagementActions = true;
        }
        let reconciledObserveInGeneralPractiseSurgeryAction = checkAndUpdateForAction(pathwayId, shouldObserveInGP, observeInGeneralPractiseSurgeryAction, 'observeInGeneralPractiseSurgery', oldActions);
        if (reconciledObserveInGeneralPractiseSurgeryAction != null) {
            requiredActions.push(reconciledObserveInGeneralPractiseSurgeryAction);
        }

        let troponinDate = data['initialBloodsDateAndTime'];
        var isWithin10HoursFromWorstPain = false;
        if(troponinDate != null && troponinDate != undefined && worstPainDateAndTime != null && worstPainDateAndTime != undefined){
            troponinDate = moment(new Date(troponinDate));
            
            var worstPainPlus10Hours = moment(worstPainDateAndTime).add(10, 'hours');

            isWithin10HoursFromWorstPain = worstPainPlus10Hours.isAfter(troponinDate);
        }
        let waitInGeneralPractiseSurgeryAction = new ActionModel(workflow.pathwayUuid, workflow.versionUuid, 'waitInGeneralPractiseSurgery', null, null, 0, false, false, Date.now(), null);
        let shouldWaitInGP = initialTroponin != null && !edacsOrSuspicionOrRedFlags && isWithin10HoursFromWorstPain && shouldShowManagement && (followUpTroponin == null);
        if (shouldWaitInGP) {
            displayingAnyACSManagementActions = true;
        }
        let reconciledWaitInGeneralPractiseSurgeryAction = checkAndUpdateForAction(pathwayId, shouldWaitInGP, waitInGeneralPractiseSurgeryAction, 'waitInGeneralPractiseSurgery', oldActions);
        if (reconciledWaitInGeneralPractiseSurgeryAction != null) {
            requiredActions.push(reconciledWaitInGeneralPractiseSurgeryAction);
        }
       
        let noFurtherManagementNeededForACSAction = new ActionModel(workflow.pathwayUuid, workflow.versionUuid, 'noFurtherManagementNeededForACS', null, null, 0, false, false, Date.now(), null);
        let noFurtherACSActionNeeded = initialTroponin != null && !edacsOrSuspicionOrRedFlags && shouldShowManagement && followUpTroponin != null;
        if (noFurtherACSActionNeeded) {
            displayingAnyACSManagementActions = true;
        }
        let reconciledNoFurtherManagementNeededForACSAction = checkAndUpdateForAction(pathwayId, noFurtherACSActionNeeded, noFurtherManagementNeededForACSAction, 'noFurtherManagementNeededForACS', oldActions);
        if (reconciledNoFurtherManagementNeededForACSAction != null) {
            requiredActions.push(reconciledNoFurtherManagementNeededForACSAction);
        }

        let recomendationBasisActionTitle = 'Recommendations are based on your indicated clinical suspicion of ACS: ';
        if(suspicionChoice == 'highOption'){
            recomendationBasisActionTitle += 'High';
        }  else if(suspicionChoice == 'lowOption'){
            recomendationBasisActionTitle += 'Low';
        }
        let recomendationBasisAction = new ActionModel(workflow.pathwayUuid, workflow.versionUuid, 'recomendationBasis', recomendationBasisActionTitle, null, 0, false, false, Date.now(), null);
        let shouldIndicateACSRecommendations = displayingAnyACSManagementActions && (suspicionChoice != 'noneOption' && (edacsOrSuspicionOrRedFlags || suspicionChoice == 'lowOption') && shouldShowManagement);
        let reconciledRecomendationBasisAction = checkAndUpdateForAction(pathwayId, shouldIndicateACSRecommendations, recomendationBasisAction, 'recomendationBasis', oldActions);
        if (reconciledRecomendationBasisAction != null) {
            requiredActions.push(reconciledRecomendationBasisAction);
        }

        let pathwayActionsCanDisplay = !stemiPresent && !redFlagActionRequired;
        if (formComplete) {
            let initialRiskBracket = GPChestPainCalculationHelper.getRiskBracket(data, true);
            if (initialRiskBracket != null) {
                let nextTroponinAction;
                if (data['patientSex'] != null) {
                    if (initialRiskBracket.type == RiskCategoryBrackets.Type.lowRisk1 || initialRiskBracket.type == RiskCategoryBrackets.Type.lowRisk2 || initialRiskBracket.type == RiskCategoryBrackets.Type.lowRisk3) {
                        let initialBloodsDateAndTime = data['initialBloodsDateAndTime'];
                        let gender = data['patientSex'];
                        let initialBloods = TroponinResult.newTroponinResult(data['initialBloodsTroponin']);
                        // Only schedule action if the inital bloods is not above cut off (Optional)
                        if (initialBloods != null && initialBloods.getStatus(gender) != TroponinResult.Status.aboveCutoff && initialBloodsDateAndTime != null) {
                            initialBloodsDateAndTime = new Date(initialBloodsDateAndTime);
                            let afterFirstTroponin = initialBloodsDateAndTime.setHours(initialBloodsDateAndTime.getHours() + 2);

                            let followUpBloodsComplete = data['followUpBloodsTroponin'] != null;
                            let scheduledAction = new ScheduledAction(afterFirstTroponin, ScheduledAction.DuePoint.dueAt, 'Troponin I - Follow-up', followUpBloodsComplete, !followUpBloodsComplete);

                            let detailString = 'Follow-up troponin due 2 hours after initial troponin';
                            nextTroponinAction = new ActionModel(workflow.pathwayUuid, workflow.versionUuid, 'nextTroponinAction', null, detailString, 0, false, false, Date.now(), null, scheduledAction);
                        }
                    } else if (initialRiskBracket.type == RiskCategoryBrackets.Type.intermediateRisk1 || initialRiskBracket.type == RiskCategoryBrackets.Type.highRisk1) {
                        let initialBloodsDateAndTime = data['initialBloodsDateAndTime'];

                        let worstPainDateAndTime = data['worstDateTime'];
                        if (worstPainDateAndTime == null) {
                            worstPainDateAndTime = data['firstDateTime'];
                        }

                        if (initialBloodsDateAndTime != null && worstPainDateAndTime != null) {
                            initialBloodsDateAndTime = new Date(initialBloodsDateAndTime);
                            worstPainDateAndTime = new Date(worstPainDateAndTime);
                            let afterFirstTroponin = initialBloodsDateAndTime.setHours(initialBloodsDateAndTime.getHours() + 2);
                            let afterWorstPain = worstPainDateAndTime.setHours(worstPainDateAndTime.getHours() + 6);

                            let latestTimeInterval = Math.max(...[afterFirstTroponin, afterWorstPain]);
                            let nextTime = latestTimeInterval;

                            let followUpBloodsComplete = data['followUpBloodsTroponin'] != null;
                            let scheduledAction = new ScheduledAction(nextTime, ScheduledAction.DuePoint.dueAt, 'Troponin I - Follow-up', followUpBloodsComplete, !followUpBloodsComplete);

                            let detailString = 'Follow-up troponin due 2 hours after initial troponin and 6 hours after worst pain';
                            nextTroponinAction = new ActionModel(workflow.pathwayUuid, workflow.versionUuid, 'nextTroponinAction', null, detailString, 0, false, false, Date.now(), null, scheduledAction);
                        }
                    }
                }

                let reconsiledNextTroponinAction = checkAndUpdateForAction(pathwayId, nextTroponinAction != null && pathwayActionsCanDisplay && !shouldRequestAccuteCardiology, nextTroponinAction, 'nextTroponinAction', oldActions);
                if (reconsiledNextTroponinAction != null) {
                    requiredActions.push(reconsiledNextTroponinAction);
                }

                let ecgAction = new ActionModel(workflow.pathwayUuid, workflow.versionUuid, 'followUpECGAction', null, null, 0, false, false, Date.now(), null);
                let followUpECGAction = checkAndUpdateForAction(pathwayId, reconsiledNextTroponinAction != null && pathwayActionsCanDisplay, ecgAction, 'followUpECGAction', oldActions);
                if (followUpECGAction != null) {
                    requiredActions.push(followUpECGAction);
                }
            }
            
        }

        let troponinTestRequiredAction = new ActionModel(workflow.pathwayUuid, workflow.versionUuid, 'troponinTestRequired', null, null, 0, false, false, Date.now(), null);
        let troponinTestRequried = initialTroponin == null && isUnder72HoursSinceWorsePain && patientClinicalInitialComplete && !shouldRequestAccuteCardiology && shouldShowManagement;
        let reconciledTroponinTestRequiredAction = checkAndUpdateForAction(pathwayId, troponinTestRequried, troponinTestRequiredAction, 'troponinTestRequired', oldActions);
        if (reconciledTroponinTestRequiredAction != null) {
            requiredActions.push(reconciledTroponinTestRequiredAction);
        }

       
        
        let isNegative = false;
        if(data['patientSex'] != null && initialTroponin != null){
            let patientSex = PatientSex.genderFromOption(data['patientSex']);
            isNegative = initialTroponin.getStatus(patientSex) == TroponinResult.Status.belowCutoff || initialTroponin.getStatus(patientSex) == TroponinResult.Status.normal;

        }
       
        let noFurtherTroponinTestRequiredAction = new ActionModel(workflow.pathwayUuid, workflow.versionUuid, 'noFurtherTroponinTestRequired', null, null, 0, false, false, Date.now(), null);
        let isFollowUpTropoininRequired = initialTroponin != null && isNegative && followUpTroponin != null && !shouldRequestAccuteCardiology && shouldShowManagement;
        let reconciledNoFurtherTroponinTestRequiredAction = checkAndUpdateForAction(pathwayId, isFollowUpTropoininRequired, noFurtherTroponinTestRequiredAction, 'noFurtherTroponinTestRequired', oldActions);
        if (reconciledNoFurtherTroponinTestRequiredAction != null) {
            requiredActions.push(reconciledNoFurtherTroponinTestRequiredAction);
        }
        
        let followUpTroponinTestRequiredAction = new ActionModel(workflow.pathwayUuid, workflow.versionUuid, 'followUpTroponinTestRequired', null, null, 0, false, false, Date.now(), null);
        let reconciledFollowUpTroponinTestRequiredAction = checkAndUpdateForAction(pathwayId, initialTroponin != null && isWithin10HoursFromWorstPain && isNegative && followUpTroponin == null && !isFollowUpTropoininRequired && !shouldRequestAccuteCardiology && shouldShowManagement, followUpTroponinTestRequiredAction, 'followUpTroponinTestRequired', oldActions);
        if (reconciledFollowUpTroponinTestRequiredAction != null) {
            requiredActions.push(reconciledFollowUpTroponinTestRequiredAction);
        }

        //noTroponinTestRequired
        let noTroponinTestRequiredAction = new ActionModel(workflow.pathwayUuid, workflow.versionUuid, 'noTroponinTestRequired', null, null, 0, false, false, Date.now(), null);
        let reconciledNoTroponinTestRequiredAction = checkAndUpdateForAction(pathwayId, (shouldRequestAccuteCardiology || (!isUnder72HoursSinceWorsePain && initialAssessmentComplete)) && shouldShowManagement, noTroponinTestRequiredAction, 'noTroponinTestRequired', oldActions);
        if (reconciledNoTroponinTestRequiredAction != null) {
            requiredActions.push(reconciledNoTroponinTestRequiredAction);
        }

        //ecgInvestigation
        let ecgInvestigationAction = new ActionModel(workflow.pathwayUuid, workflow.versionUuid, 'ecgInvestigation', null, null, 0, false, false, Date.now(), null);
        let reconciledECGInvestigationAction = checkAndUpdateForAction(pathwayId, shouldShowManagement, ecgInvestigationAction, 'ecgInvestigation', oldActions);
        if (reconciledECGInvestigationAction != null) {
            requiredActions.push(reconciledECGInvestigationAction);
        }

        return requiredActions;
    }
}