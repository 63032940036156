// IMPORT PACKAGE REFERENCES
import React from 'react';
import PropTypes from 'prop-types';
import { FormButton } from '../../waferJS/FormsUI/FormsUI Components/Controls/FormButton';
import { FormLabel } from '../../waferJS/FormsUI/FormsUI Components/Controls/FormLabel';
import { Chevron } from '../shared/Chevron';

// COMPONENT
export class Toast extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            mouseDown: false,
            touchYStart: 0,
            prevTouchY: 0,
            velocity: 0,
            bottomOffset: 50,
            initialOffset: 50,
            timeOfLastDragEvent: 0,
            message: ''
        };

        this.handleMouseDown = this.handleMouseDown.bind(this);
        this.handleMouseMove = this.handleMouseMove.bind(this);
        this.handleMouseUp = this.handleMouseUp.bind(this);
        this.handleRemove = this.handleRemove.bind(this);
        this.handleTouchStart = this.handleTouchStart.bind(this);
        this.handleTouchMove = this.handleTouchMove.bind(this);
        this.handleTouchEnd = this.handleTouchEnd.bind(this);
        this.handleStart = this.handleStart.bind(this);
        this.handleEnd = this.handleEnd.bind(this);
        this.handleMove = this.handleMove.bind(this);
        this.resetState = this.resetState.bind(this);
        this.updateMessage = this.updateMessage.bind(this);
    }

    shouldComponentUpdate(nextProps, nextState) {
        if(nextProps.changes !== this.props.changes){
            return true;
        }

        if(nextProps.showToast !== this.props.showToast){
            return true;
        }

        if(nextProps.docked !== this.props.docked){
            return true;
        }

        if(nextProps.toastClicked !== this.props.toastClicked){
            return true;
        }

        if(nextProps.hide !== this.props.hide){
            return true;
        }

        if(nextProps.pathwayId !== this.props.pathwayId){
            return true;
        }

        if(nextState.mouseDown !== this.state.mouseDown){
            return true;
        }

        if(nextState.touchYStart !== this.state.touchYStart){
            return true;
        }

        if(nextState.prevTouchY !== this.state.prevTouchY){
            return true;
        }

        if(nextState.velocity !== this.state.velocity){
            return true;
        }

        if(nextState.bottomOffset !== this.state.bottomOffset){
            return true;
        }

        if(nextState.initialOffset !== this.state.initialOffset){
            return true;
        }

        if(nextState.timeOfLastDragEvent !== this.state.timeOfLastDragEvent){
            return true;
        }

        if(nextState.message !== this.state.message){
            return true;
        }

        return false;
    }

    componentDidMount() {
        this._ismounted = true;
        document.addEventListener('mousedown', this.handleMouseDown);
        document.addEventListener('mouseup', this.handleMouseUp);
        document.addEventListener('mousemove', this.handleMouseMove);

        document.addEventListener('touchstart', this.handleTouchStart, { passive: false });
        document.addEventListener('touchmove', this.handleTouchMove, { passive: false });
        document.addEventListener('touchend', this.handleTouchEnd, { passive: false });

        this.updateMessage();
    }

    componentWillUnmount() {
        this._ismounted = false;
        document.removeEventListener('mousedown', this.handleMouseDown);
        document.removeEventListener('mouseup', this.handleMouseUp);
        document.removeEventListener('mousemove', this.handleMouseMove);

        document.removeEventListener('touchstart', this.handleTouchStart);
        document.removeEventListener('touchmove', this.handleTouchMove);
        document.removeEventListener('touchend', this.handleTouchEnd);
    }

    componentDidUpdate() {
        this.updateMessage();
    }

    updateMessage() {
        if(this.props.changes[this.props.pathwayId] != null){
            let newMessageString = '';
            let changes = this.props.changes[this.props.pathwayId].slice(0).sort();

            for (let item of changes) {
                if (item == changes[0]) {
                    newMessageString = 'Changes to ' + item;
                } else if (item == changes[changes.length - 1]) {
                    newMessageString = newMessageString + ' & ' + item;
                } else {
                    newMessageString = newMessageString + ', ' + item;
                }
            }
    
            if (this.state.message != newMessageString) {
                this.setState({ message: newMessageString });
            }
        }
    }

    handleMouseDown(e) {
        if (this.node.contains(e.target) && this.props.showToast && !this.dismissButtonNode.contains(e.target)) {
            this.handleStart(e.clientY);
        }
    }

    handleMouseMove(e) {
        this.handleMove(e.clientY);
    }

    handleMouseUp(e) {
        if (this.state.bottomOffset == this.state.initialOffset && this.node != null && this.node.contains(e.target) && !this.dismissButtonNode.contains(e.target)) {
            this.props.toastClicked();
        }
        this.handleEnd();
    }

    handleTouchStart(e) {
        if (this.node.contains(e.targetTouches[0].target) && this.props.showToast && !this.dismissButtonNode.contains(e.targetTouches[0].target)) {
            this.handleStart(e.targetTouches[0].clientY);
        }
    }

    handleTouchMove(e) {
        if (this.state.mouseDown && this.props.showToast) {
            e.preventDefault();
        }
        this.handleMove(e.targetTouches[0].clientY);
    }

    handleTouchEnd(e) {
        if (this.state.bottomOffset == this.state.initialOffset && this.node != null && e.targetTouches.length > 0 && this.node.contains(e.targetTouches[0].target) && !this.dismissButtonNode.contains(e.targetTouches[0].target)) {
            this.props.toastClicked();
        }
        this.handleEnd();
    }

    handleStart(clientY) {
        this.setState({
            mouseDown: true,
            touchYStart: clientY,
            timeOfLastDragEvent: Date.now()
        });
    }

    handleMove(clientY) {
        if (this.state.mouseDown) {
            let shiftedValue = this.state.initialOffset + (this.state.touchYStart - clientY);
            shiftedValue = shiftedValue > this.state.initialOffset + 20 ? this.state.initialOffset + 20 : shiftedValue;
            let velocity = 20 * (clientY - this.state.prevTouchY) / (Date.now() - this.state.timeOfLastDragEvent);
            this.setState({
                bottomOffset: shiftedValue,
                velocity: velocity,
                prevTouchY: clientY
            });
        }
    }

    handleEnd() {
        if(this._ismounted){
            this.setState({
                mouseDown: false,
                touchYStart: 0,
                prevTouchY: 0,
            }, this.handleRemove);
        }
    }

    handleRemove() {
        let bottomOffset = this.state.bottomOffset;
        let velocity = this.state.velocity;
        if (!this.state.mouseDown && bottomOffset > -40) {
            velocity -= 10 * 0.033;
            bottomOffset -= velocity;
            if (velocity > 0) {
                this.props.hide();
                clearTimeout(this.resetTimeout);
                this.resetTimeout = setTimeout(this.resetState, 800);
            } else {
                this.setState({
                    mouseDown: false,
                    bottomOffset: this.state.initialOffset,
                    velocity: 0
                });
            }
        } else if (bottomOffset <= -40) {
            this.props.hide();
            clearTimeout(this.resetTimeout);
            this.resetTimeout = setTimeout(this.resetState, 800);
        }

        this.setState({ mouseDown: false });
    }

    resetState() {
        this.setState({
            bottomOffset: this.state.initialOffset,
            velocity: 0,
            mouseDown: false
        });
    }

    render() {
        return (
            <div ref={node => this.node = node} className={'notification-toast-container ' + (this.props.showToast ? 'show-toast ' : 'hide-toast ') + (this.props.docked ? 'docked' : '')} style={{ bottom: this.state.bottomOffset + 'px' }}>
                <div className="notification-toast" >
                    <div className="toast-image-container">
                        <img className="toast-results-icon" src={require('../../images/results.png')} />
                        <div className="toast-notification-badge" />
                    </div>
                    <div>
                        <div className="existing-session-button">
                            <FormButton textAlignment={'left'} image={<Chevron color={'white'} angle={'rotate(-90deg)'} />} activeBackgroundColor={'transparent'} backgroundColor={'transparent'} enabled={true} isSelected={false} color={'white'} title={'Insights'} ignoreTab={true} />
                        </div>
                        <FormLabel value={this.state.message} fontSize={0.9} textColor={'rgba(255, 255, 255, 0.6)'} />
                    </div>
                    <div ref={dismissButtonNode => this.dismissButtonNode = dismissButtonNode}><button className='notification-toast-close-button' onClick={this.props.hide} tabIndex="-1" >Dismiss</button></div>
                </div>
            </div>
        );
    }
}

Toast.propTypes = {
    changes: PropTypes.object.isRequired,
    showToast: PropTypes.bool.isRequired,
    docked: PropTypes.bool.isRequired,
    toastClicked: PropTypes.func.isRequired,
    hide: PropTypes.func.isRequired,
    pathwayId: PropTypes.string.isRequired
};
