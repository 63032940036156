import { FormWithRedux } from '../../FormWithRedux';
import { redux } from '../../../App';
import { CalculationModel } from '../../edChestPain01/Custom Types/Calculation';
import { saveAction } from '../../../components/state/actions/ActionActions';
import { saveCalculation } from '../../../components/state/actions/CalculationActions';
import { EDChestPainCalculationHelper as CalculationHelper } from '../../edChestPain01/Helpers/EDChestPainCalculationHelper';
// import { FormsStyles } from '../../../waferJS/FormsUI/FormsUI';
// import { FormValidation } from '../../../waferJS/Form/Validation/FormValidation';
// import { FormValidationError } from '../../../waferJS/Form/Validation/FormValidationError';
import { PathwayFormatter } from '../../PathwayFormatter';
import { ACCMRIActionItem } from '../Actions/ACCMRIActionItem';
import { markWorkFlowAsExported } from '../../../components/state/actions/WorkflowResponseActions';

export class ACCMRIResultsForm extends FormWithRedux {

    set calculations(value) {
        this._calculations= JSON.parse(JSON.stringify(value));
    }

    get calculations() {
        return this._calculations;
    }

    set actions(value) {
        this._actions = JSON.parse(JSON.stringify(value));
    }

    get actions() {
        return this._actions;
    }

    constructor(formLoader, parent, actionsUpdater, dependenValuesUpdater, calculationsUpdater) {
        super('outcome', formLoader, parent,  actionsUpdater, dependenValuesUpdater, calculationsUpdater);

        let self = this;
        redux.store.subscribe(() => {
            let state = redux.store.getState();
            self.calculations = state.calculationReducer.calculations;
            self.actions = state.actionsReducer.actions;
        });

        this.calculations = redux.store.getState().calculationReducer.calculations;
        this.actions = redux.store.getState().actionsReducer.actions;
    }

    shouldDisplayFormDetailForIdentifier(identifier) {
        super.shouldDisplayFormDetailForIdentifier(identifier);
        
        if (this.getValue('hiddenShouldDisplayOutcomeFormTracker') != true) {
            if (identifier == 'outcomeMoreInformationRequired') {
                return true;
            } else {
                return false;
            }
        }
        
        let formDetail = this.formDetail(identifier);
        if(formDetail.controlType == 'action'){
            let action = this.actionForIdentifier(identifier);
            if(identifier == 'mriRequestOutcome'){
                return action != null;
            }
            return action != null;
        } else {
            if (identifier == 'additionalRequestInformation') {
                return true;
            } 
            else if (this.getValue('postTraumaticKneePain') == true) {
                let formDetail = this.formDetail('additionalRequestInformation');
                formDetail.detail = 'For post-traumatic knee pain MRI requests, if you have an X-ray report remember to attach it to your MRI request form.';
            } else {
                let formDetail = this.formDetail('additionalRequestInformation');
                formDetail.detail = '';
            }
            if (identifier == 'exportRequestOutcome') {
                return true;
            }
        }

        return false;
    }

    isEnabled(identifier) {
        if (identifier != null) {
            return true;
        }
        return false;
    }

    isMandatory() {
        return false;
    }

    getValueType() {
        return 'string';
    }

    submissionValidation() {
        return [];
    }

    detailString(isSubcell, isPlainText) {
        if(isPlainText){
            return this.getPlainText();
        }
        let formDetails = this.getFormDetails();
        let values = formDetails.map((value) => {
            return this.getSummaryValue(isSubcell, value);
        });

        if(values.filter(n => n).length == 0){
            return isPlainText ? '' : this.detail;
        }
        
        return values.filter(n => n).join(isPlainText ? ', ' : ',\n');
    }

    getSummaryValue(isSubcell, value){
        if (value.identifier == 'exportRequestOutcome') {
            return null;
        } else if(value.identifier == 'mriRequestOutcome') {
            if (this.getValue(value.identifier) != null) {
                let action = this.actionForIdentifier(value.identifier);
                let mriAction = ACCMRIActionItem.rehydrate(this.getValue(value.identifier));
                return action.title + '\n' + mriAction.getLongDetail();
            } else {
                return null;
            }
        } else if (value.controlType == 'action'){
            let action = this.actionForIdentifier(value.identifier);
            if(action != null){
                let actionStringComponents = [];
                action.title != null ? actionStringComponents.push(action.title) : actionStringComponents.push(value.title);
                action.detail != null ? actionStringComponents.push(action.detail) : actionStringComponents.push(value.detail);
                return actionStringComponents.filter(n => n).join('\n');
            } else {
                return null;
            }
        }
        return value.valueDescription();
        // return value.valueDescription() == null ? null : (isSubcell ? '- ' : '') + value.valueDescription();
    }

    shouldShowEmptySection(){
        return false;
    }

    areFormsForInitialOutcomeFormComplete(){
        let requestCriteriaForm = this.formLoader.form(this.pathwayId, 'requestCriteria');
        let requestCriteriaFormCompletionProgress = requestCriteriaForm.completionProgress();
        let isRequestCriteriaFormComplete = false;
        if(requestCriteriaFormCompletionProgress != null && requestCriteriaFormCompletionProgress != undefined){
            isRequestCriteriaFormComplete = (requestCriteriaFormCompletionProgress.current / requestCriteriaFormCompletionProgress.total) == 1;
        }
        
        return isRequestCriteriaFormComplete;
    }

    getBadgeValue(){
        return [];
    }

    calculationError(identifier){
        let data = null;
        if(this.workflow[this.pathwayId] != null){
            data = this.workflow[this.pathwayId].rawData;
        }
        return CalculationHelper.calcualtionError(identifier, data, this);
    }

    latestCalculation(identifier){
        return CalculationHelper.getLatestCalculation(this.getTypeFromIdentifier(identifier), this.calculations[this.pathwayId]);
    }

    previousCalculation(identifier){
        return CalculationHelper.getPreviousCalculation(this.getTypeFromIdentifier(identifier), this.calculations[this.pathwayId]);
    }

    markCalculationAsObserved(identifier){
        let calculation = this.latestCalculation(identifier);
        if(calculation != null){
            calculation.observed = true;
            
            redux.store.dispatch(saveCalculation(window.location.href.split('/')[3], calculation, this.calculations));
        }
    }

    markActionAsObserved(identifier){
        if(this.actions[this.pathwayId] == null ){ return; }
        let matchingActions = this.actions[this.pathwayId].filter((action) => {
            return action.identifier == identifier;
        });

        let filteredActions = this.actions[this.pathwayId].filter((action) => {
            return action.identifier != identifier;
        });

        if (matchingActions.length == 0 ) { return;}

        let action = matchingActions[0];

        if(!action.removeAfterObservation){
            action.observed = true;
            filteredActions.push(action);
        }
        
        redux.store.dispatch(saveAction(this.pathwayId, filteredActions));
    }

    calculationProgress(identifier){
        if(CalculationModel.typeRequiresPrimaryFormValidation(this.getTypeFromIdentifier(identifier))){
            let progress = redux.store.getState().progressReducer.progress[this.pathwayId];
            if(progress != null){
                return (progress.complete / progress.total);
            }
            return 0;
        } 
        return 1;
    }

    actionForIdentifier(identifier){
        if(this.actions[this.pathwayId] != null ){
            let filteredAction = this.actions[this.pathwayId].filter((action) => {return action.identifier == identifier;});
            if(filteredAction.length > 0){
                return filteredAction[0];
            }
        }
        
        return null;
    }

    getCopyText(){
        return PathwayFormatter.formatAsPlainText(this.formLoader, this.pathwayId, this.workflow[this.pathwayId], this.actions[this.pathwayId]);
    }

    markAsExported(){
        redux.store.dispatch(markWorkFlowAsExported(this.pathwayId, this.workflow[this.pathwayId]));
    }
}