// IMPORT PACKAGE REFERENCES
import React from 'react';
import PropTypes from 'prop-types';

// COMPONENT
export class HorizontalButtonControl extends React.PureComponent {

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className={'horizontal-button-control ' + ((this.props.buttons != null && this.props.buttons.length > 2 || this.props.forceColumn == true) ? 'column' : '')}>
                {this.props.buttons.map((button) => (
                    <div key={Math.random().toString(36).replace(/[^a-z]+/g, '').substr(2, 10)} className={'horizontal-button-pouch ' + (this.props.buttons[0] != button ? 'margin ' : '') + (this.props.buttons.length <= 2 ? 'button-fill ' : '')}>{button}</div>
                ))}
            </div>
        );
    }
}

HorizontalButtonControl.propTypes = {
    buttons: PropTypes.array,
    forceColumn: PropTypes.bool
};