import { FormWithRedux } from '../FormWithRedux';
import { GenericFrom } from './GenericForm';
 
export class EnvironmentInfoForm extends FormWithRedux {

    get pathwayId() {
        return 'environmentInfoForm';
    }

    constructor(formLoader) {
        super('mainForm', formLoader, null);
    }

    subform(identifier) {
        return this.formLoader.form(this.pathwayId, identifier);
    }

    formDetail(identifier) {
        return this.formLoader.formDetail(this.pathwayId, identifier);
    }

    generateSubform(identifier) {
        return new GenericFrom(identifier, this.formLoader, this.pathwayId);
    }

    shouldDisplayFormDetailForIdentifier() {
        return true;
    }

    isEnabled(identifier) {
        if (identifier != null) {
            return true;
        }
        return false;
    }

    isMandatory() {
        return true;
    }

    getValueType() {
        return 'string';
    }

    editingValidation(){
        return [];
    }

    submissionValidation() {
        return [];
    }

    detailString() {
        return this.detail;
    }
}