import { v1 as uuidv1 } from 'uuid';

import { PathwayReferenceHelper } from '../../../forms/PathwayReferenceHelper';
import { ReferenceDataLoader } from '../../../forms/ReferenceDataLoader';


export const CREATE_NEW_RESPONSE_PENDING = 'CREATE_NEW_RESPONSE_PENDING';
export const CREATE_NEW_RESPONSE_FULFILLED = 'GET_PATHWAY_FULFILLED';
export const CREATE_NEW_RESPONSE_FAILED = 'CREATE_NEW_RESPONSE_FAILED';

export const UPDATING_WORKFLOW_PENDING = 'UPDATING_WORKFLOW_PENDING';
export const UPDATING_WORKFLOW_FULFILLED = 'UPDATING_WORKFLOW_FULFILLED';
export const UPDATING_WORKFLOW_FAILED = 'UPDATING_WORKFLOW_FAILED';

export const IMPORT_WORKFLOW_FULFILLED = 'IMPORT_WORKFLOW_FULFILLED';

export const CLEAR_WORKFLOW_FULFILLED = 'CLEAR_WORKFLOW_FULFILLED';

const createNewResponse = (pathwayId) => (dispatch) => {

    dispatch({
        type: CREATE_NEW_RESPONSE_PENDING
    });

    let newResponse = {
        uuid: uuidv1(),
        createdDate: Date.now(),
        pathwayUuid: pathwayId,
        name: PathwayReferenceHelper.reference('title', ReferenceDataLoader.referenceData(pathwayId)),
        rawData: {},
        lastModifiedDate: Date.now(),
        version: 1,
        versionUuid: uuidv1(),
        versionCreatedDate: Date.now(),
        preventChanges: false,
        isAbandoned: false,
        hasExported: false,

        workflowVersionUuid: uuidv1(),
    };

    dispatch({
        type: CREATE_NEW_RESPONSE_FULFILLED,
        payload: {
            workflow: newResponse,
            pathwayId: pathwayId
        }
    });
};

const clearWorkflow = (pathwayId) => (dispatch) => {
    dispatch({
        type: CLEAR_WORKFLOW_FULFILLED,
        payload: {
            pathwayId: pathwayId,
            workflow: {}
        }
    });
};

const importWorkflow = (pathwayId, workflow) => (dispatch) => {
    dispatch({
        type: IMPORT_WORKFLOW_FULFILLED,
        payload: {
            pathwayId: pathwayId,
            workflow: workflow
        }
    });
};

const incrementWorkFlow = (pathwayId, workflow) => (dispatch) => {
    workflow.version = workflow.version + 1;
    workflow.hasExported = false;
    dispatch({
        type: UPDATING_WORKFLOW_FULFILLED,
        payload: {
            pathwayId: pathwayId,
            workflow: workflow
        }
    });
};

const markWorkFlowAsExported = (pathwayId, workflow) => (dispatch) => {
    workflow.hasExported = true;
    dispatch({
        type: UPDATING_WORKFLOW_FULFILLED,
        payload: {
            pathwayId: pathwayId,
            workflow: workflow
        }
    });
};

const saveWorkflow = (pathwayId, valuesToSave, workflow, updateDependantValues, form, iterationIndex) => (dispatch) => {
    // Refer to mainForm or the parent of the form (mainForm)
    let mainForm = form.parent != null ? form.parent : form;

    Object.keys(valuesToSave).forEach(identifier => {
        let value = valuesToSave[identifier];
        
        workflow.rawData[identifier] = value;

        mainForm.dependentValuesUpdater.update(pathwayId, workflow, identifier, value, (iterationIndex != undefined ? iterationIndex : 0));
    });

    if (iterationIndex == 0) {
        if(workflow.hasExported == true){
            workflow.version = workflow.version + 1;
            workflow.hasExported = false;
        }

        workflow.lastModifiedDate = Date.now();

        dispatch({
            type: UPDATING_WORKFLOW_FULFILLED,
            payload: {
                pathwayId: pathwayId,
                workflow: workflow
            }
        });
    }
};

export { createNewResponse, saveWorkflow, importWorkflow, clearWorkflow, incrementWorkFlow, markWorkFlowAsExported };