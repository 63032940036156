import { FormWithRedux } from '../../FormWithRedux';
import { ACCMRI_LSI_RP_CriteriaForm } from './ACCMRI-LSI-RP-CriteriaForm';
import { ACCMRI_CSI_RP_CriteriaForm } from './ACCMRI-CSI-RP-CriteriaForm';
import { ACCMRI_KP_OKR_CriteriaForm } from './ACCMRI-KP-OKR-CriteriaForm';
import { ACCMRI_KP_ACL_LCL_CriteriaForm } from './ACCMRI-KP-ACL-LCL-CriteriaForm';
import { ACCMRI_KP_ML_CriteriaForm } from './ACCMRI-KP-ML-CriteriaForm';
import { ACCMRI_KP_MCL_PCL_CriteriaForm } from './ACCMRI-KP-MCL_PCL-CriteriaForm';

import { FormValidation } from '../../../waferJS/Form/Validation/FormValidation';
import { FormValidationError } from '../../../waferJS/Form/Validation/FormValidationError';
import { PathwayReferenceHelper } from '../../PathwayReferenceHelper';
import { ACCMRIPathwayReference } from '../ACCMRI-PathwayReference';
import { FormsStyles, FormsContentType } from '../../../waferJS/FormsUI/FormsUI';

export class ACCMRIAssessmentForm extends FormWithRedux {

    constructor(formLoader, parent, actionUpdater, dependentValuesUpdater) {
        super('assessment', formLoader, parent, actionUpdater, dependentValuesUpdater);
    }

    generateSubform(identifier) {
        switch (identifier) {
            case 'LSI_RP_Criteria':
                return new ACCMRI_LSI_RP_CriteriaForm(identifier, this.formLoader, this.parent, this.actionUpdater, this.dependentValuesUpdater, this.calculationsUpdater);
            case 'CSI_RP_Criteria':
                return new ACCMRI_CSI_RP_CriteriaForm(identifier, this.formLoader, this.parent, this.actionUpdater, this.dependentValuesUpdater, this.calculationsUpdater);
            case 'KP_OKR_Criteria':
                return new ACCMRI_KP_OKR_CriteriaForm(identifier, this.formLoader, this.parent, this.actionUpdater, this.dependentValuesUpdater, this.calculationsUpdater);
            case 'KP_ACL_LCL_Criteria':
                return new ACCMRI_KP_ACL_LCL_CriteriaForm(identifier, this.formLoader, this.parent, this.actionUpdater, this.dependentValuesUpdater, this.calculationsUpdater);
            case 'KP_ML_Criteria':
                return new ACCMRI_KP_ML_CriteriaForm(identifier, this.formLoader, this.parent, this.actionUpdater, this.dependentValuesUpdater, this.calculationsUpdater);
            case 'KP_MCL_PCL_Criteria':
                return new ACCMRI_KP_MCL_PCL_CriteriaForm(identifier, this.formLoader, this.parent, this.actionUpdater, this.dependentValuesUpdater, this.calculationsUpdater);
        }
    }

    getResultFromLogicTree(identifier) {
        const LSI_urgentRedFlags = ['LSI_featuresOfCaudaEquinaSyndrome', 'LSI_infection', 'LSI_abdominalAorticAneurysm'];
        const LSI_nonUrgentRedFlags = ['LSI_cancer', 'LSI_fracture', 'LSI_spondyloarthropathy'];
        const LSI_backPain = ['LSI_lowerBackPain', 'LSI_somaticReferredBackPain'];
        const LSI_backManagement = ['LSI_BP_painManagementAndRehabilitation', 'LSI_BP_xRayIfClinicalConcern'];
        const LSI_legPain = ['LSI_radiculopathy', 'LSI_RadicularPain'];

        const CSI_redFlags = [
            'CSI_severeWorseningUnrelentingPainEspeciallyAtNightOrWhenLyingDown', 'CSI_bilateralSignsAndSymptoms', 'CSI_severeNeurologicalDysfunction', 
            'CSI_infection', 'CSI_cancer', 'CSI_fracture'];
        const CSI_postActiveRehabilitationPain = ['CSI_FA2_radiculopathy', 'CSI_FA2_RadicularPain'];

        const GH_redFlags = [
            'GH_unexplainedDeformityOrSwelling', 'GH_pulmonaryOrVascularCompromise', 'GH_bilateralNeurologicalSymptoms', 'GH_severeNeurologicalDysfunction', 
            'GH_infection', 'GH_cancer', 'GH_fracture'];
        const GH_provisionalClinicalDiagnosis = ['GH_suspicionOfFractureOrGHJI', 'GH_rotatorCuffTear', 'GH_subacromialBursitisImpingementSyndrome', 'GH_osteoarthritis', 'GH_frozenShoulder'];

        const KP_clinicalDiagnosisFields = ['KP_acutelyLockedKnee', 'KP_suspectAclLclRupture', 'KP_suspectMeniscalLesion', 'KP_suspectMCLPCLRupture', 'KP_suspectPLCInjury'];
        const KP_clinicalDiagnosisSubforms = ['KP_ACL_LCL_Criteria', 'KP_ML_Criteria', 'KP_MCL_PCL_Criteria'];

        if (this.getValue('hiddenShouldDisplayAssessmentFormTracker') != true) {
            //Keeps track of reason for request form progress and blocks assessment form if incomplete
            //See ACCMRI-DependantValuesUpdater.js
            if (identifier == 'assessmentMoreInformationRequired') {
                return true;
            } else {
                return false;
            }
        } else if (this.getValue('radiologyClearedPatient') == false) {
            //If form is complete, but radiology has not cleared patient for MRI, block Assessment 
            let requestCriteriaForm = this.formLoader.form('accmri01', 'requestCriteria');
            if (requestCriteriaForm.isRecommendationVisible == true) {
                //This check is to make sure that the field is still visible, ie. the user hasn't backtracked
                if (identifier == 'radiologyHasNotClearedPatientForMRI') {
                    return true;
                } else {
                    return false;
                }
            }
        }
        
        if (this.getValue('lumbarSpineInjury') == true) {
            //Do this branch if lumbar spine injury chosen
            if (LSI_urgentRedFlags.includes(identifier) || LSI_nonUrgentRedFlags.includes(identifier)) {
                return true;
            } else if (identifier == 'LSI_RF_NoToAll') {
                //Remove 'no to all' button if nothing left to select for non-urgent red flags
                return this.unselectedExplicitFormDetailsCount(identifier) > 1;
            }
            let urgentFlags = LSI_urgentRedFlags.map( childID => this.getValue(childID));
            let nonUrgentFlags = LSI_nonUrgentRedFlags.map( childID => this.getValue(childID));
            
            if ((!urgentFlags.includes(null) && !urgentFlags.includes(undefined)) && (!nonUrgentFlags.includes(null) && !nonUrgentFlags.includes(undefined))) {
                //If no red flags remain unchecked
                if (urgentFlags.includes(true)) {
                    //If urgent red flag marked as yes, show acute recommendation
                    if (identifier == 'LSI_RF_arrangeAcuteAssessment') {
                        return true;
                    }
                } else if (nonUrgentFlags.includes(true)) {
                    //If non urgent red flag marked yes, show specialist recommendation  
                    if (identifier == 'LSI_RF_requestNonAcuteSpecialistAssessment') {
                        return true;
                    }
                } else if (identifier == 'LSI_RF_managePerAcuteClinicalManagementPathways') {
                    //If no red flag, show recommendation
                    return true;
                } else if (identifier == 'LSI_persistentPain') {
                    //If no red flag, show next section
                    return true;
                } else if (this.getValue('LSI_persistentPain') == true) {
                    //If persistant pain marked true, follow persistant pain path
                    if (identifier == 'LSI_areaOfDominantPain') {
                        return true;
                    } else if (this.getValue('LSI_areaOfDominantPain') == 'LSI_areaOfDominantPainBack') {
                        //If area of pain is back, follow back path
                        if (LSI_backPain.includes(identifier)) {
                            return true;
                        }
                        let backPain = LSI_backPain.map( childID => this.getValue(childID));
                        if (backPain.includes(true)) {
                            //If lower back pain, or referred back pain, show recommendations
                            if (LSI_backManagement.includes(identifier)) {
                                return true;
                            }
                        }
                    } else if (this.getValue('LSI_areaOfDominantPain') == 'LSI_areaOfDominantPainLeg') {
                        //If area of pain is leg, follow leg path
                        if (LSI_legPain.includes(identifier)) {
                            return true;
                        } else if (this.getValue('LSI_radiculopathy') == true) {
                            //If radiculopathy, show detail entry
                            if (identifier == 'LSI_detailNeurologicalAbnormalities') {
                                return true;
                            }
                        }
                        if (identifier == 'LSI_RP_Criteria') {
                            return true;
                        } else if (this.getValue('LSI_RadicularPain') == true) {
                            //If radicular pain true, show detail entry
                            if (identifier == 'LSI_RP_detailFindings') {
                                return true;
                            }
                        }
                        if (this.getValue('LSI_radiculopathy') == true || this.getValue('LSI_RadicularPain') == true) {
                            //If either radiculopathy or radicular pain, show recommendation
                            if (identifier == 'LSI_LP_MRI' || identifier == 'LSI_LP_referToSpecialist') {
                                return true;
                            }
                        } else if (this.getValue('LSI_radiculopathy') == false && this.getValue('LSI_RadicularPain') == false) {
                            //If neither radiculopathy or radicular pain, show recommendation
                            if (identifier == 'LSI_LP_painManagementAndRehabilitation') {
                                return true;
                            }
                        }
                    } 
                }
            }
        } else if (this.getValue('cervicalSpineInjury') == true) {
            //Do this branch if cervical spine injury chosen
            if (CSI_redFlags.includes(identifier)) {
                return true;
            } else if (identifier == 'CSI_RF_NoToAll') {
                //Remove 'no to all' button if nothing left to select for non-urgent red flags
                return this.unselectedExplicitFormDetailsCount(identifier) > 1;
            }
            let redFlagValues = CSI_redFlags.map( childID => this.getValue(childID));
            if (!redFlagValues.includes(null) && !redFlagValues.includes(undefined)) {
                //If no red flags remain unchecked
                if (redFlagValues.includes(true)) {
                    //If any red flag marked yes, show recommendation
                    if (identifier == 'CSI_RF_arrangeAcuteAssessment') {
                        return true;
                    }
                } else {
                    //If no red flag, show next section
                    if (identifier == 'CSI_canadianCSpineRuleRecommendsImaging') {
                        return true;
                    } else if (this.getValue('CSI_canadianCSpineRuleRecommendsImaging') == true) {
                        //If imaging recommended, use this branch(1)
                        if (identifier == 'CSI_FA1_xRay' || identifier == 'CSI_xRayConfirmsFracture') {
                            return true;
                        } else if (this.getValue('CSI_xRayConfirmsFracture') == true) {
                            //If x-ray taken and confirms fracture, show details entry and recommendation
                            if (identifier == 'CSI_xRayConfirmsFractureProvideDetails' || identifier == 'CSI_FA1_arrangeAcuteAssessment') {
                                return true;
                            }
                        } else if (this.getValue('CSI_xRayConfirmsFracture') == false) {
                            //If x-ray taken and no fracture, show recommendation and persistant pain section
                            if (identifier == 'CSI_FA1_painManagementAndActiveRehabilitation' || identifier == 'CSI_FA2_persistentPain') {
                                return true;
                            }
                        }
                    } else if (this.getValue('CSI_canadianCSpineRuleRecommendsImaging') == false) {
                        //If imaging is not recommended, show recommendation and persistant pain question
                        if (identifier == 'CSI_FA1_painManagementAndActiveRehabilitation' || identifier == 'CSI_FA2_persistentPain') {
                            return true;
                        }
                    }
                    if ((this.getValue('CSI_canadianCSpineRuleRecommendsImaging') == false && this.getValue('CSI_FA2_persistentPain') == true)
                    || (this.getValue('CSI_canadianCSpineRuleRecommendsImaging') == true && this.getValue('CSI_xRayConfirmsFracture') == false && this.getValue('CSI_FA2_persistentPain') == true)) {
                        //If either imaging not recommended and persistant pain true or x-ray taken, but doesn't confirm fracture but persistant pain is still true from branch(1), go down this branch
                        if (CSI_postActiveRehabilitationPain.includes(identifier)) {
                            return true;
                        } else if (this.getValue('CSI_FA2_radiculopathy') == true) {
                            //If radiculopathy, show details entry
                            if (identifier == 'CSI_FA2_detailNeurologicalAbnormalities') {
                                return true;
                            }
                        }
                        if (identifier == 'CSI_RP_Criteria') {
                            return true;
                        } else if (this.getValue('CSI_FA2_RadicularPain') == true) {
                            //If radicular pain true, show detail entry
                            if (identifier == 'CSI_RP_detailFindings') {
                                return true;
                            }
                        }
                        if (this.getValue('CSI_FA2_radiculopathy') == true || this.getValue('CSI_FA2_RadicularPain') == true) {
                            //If radiculopathy true or radicular pain true, then show recommendation
                            if (identifier == 'CSI_FA2_mri' || identifier == 'CSI_FA2_referToSpecialist') {
                                return true;
                            }
                        } else if (this.getValue('CSI_FA2_radiculopathy') == false && this.getValue('CSI_FA2_RadicularPain') == false) {
                            //If no persistent pain or no radiculopathy and radicular pain, show recommendation
                            if (identifier == 'CSI_FA2_painManagementAndRehabilitation') {
                                return true;
                            }
                        }
                    }
                }
            }
        } else if (this.getValue('ghjOrInstability') == true) {
            //Do this branch if Gleno-humeral joint pain or instability selected
            if (GH_redFlags.includes(identifier)) {
                return true;
            } else if (identifier == 'GH_RF_NoToAll') {
                return this.unselectedExplicitFormDetailsCount(identifier) > 1;
            }
            let redFlagValues = GH_redFlags.map( childID => this.getValue(childID));
            if (!redFlagValues.includes(null) && !redFlagValues.includes(undefined)) {
                //If no red flags remain unchecked
                if (redFlagValues.includes(true)) {
                    //If any red flag marked yes, show recommendation
                    if (identifier == 'GH_RF_arrangeAcuteAssessment') {
                        return true;
                    }
                } else {
                    //If no red flags, follow this pathway
                    if(GH_provisionalClinicalDiagnosis.includes(identifier)) {
                        return true;
                    } else if (identifier == 'GH_clinicalDiagnosisNoToAll') {
                        //Remove 'no to all' button if nothing left to select for non-urgent red flags
                        return this.unselectedExplicitFormDetailsCount(identifier) > 1;
                    }
                    let diagnosis = GH_provisionalClinicalDiagnosis.map( childID => this.getValue(childID));
                    if (!diagnosis.includes(null) && !diagnosis.includes(undefined)) {
                        //If nothing remains unchecked in section
                        if (this.getValue('GH_suspicionOfFractureOrGHJI')) {
                            //If suspicion of fracture marked yes, show recommendation
                            if (identifier == 'GH_FA1_xRays' || identifier == 'GH_FA1_xRayConfirmsFracture') {
                                return true;
                            } else if (this.getValue('GH_FA1_xRayConfirmsFracture') ==  true) {
                                //If fracture confirmed, show additional details text field and recommendation
                                if (identifier == 'GH_FA1_xRayConfirmsFractureProvideDetails' || identifier == 'GH_FA1_arrangeAcuteAssessment') {
                                    return true;
                                } 
                            } else if (this.getValue('GH_FA1_xRayConfirmsFracture') == false) {
                                //If fracture not confirmed, then show recommendation
                                if (identifier == 'GH_FA1_painManagementAndActiveRehabilitation') {
                                    return true;
                                }
                            }
                        } else if (this.getValue('GH_rotatorCuffTear') == true) {
                            //If rotator cuff tear marked yes, follow this branch
                            if (identifier == 'GH_FA1_xRaysUltrasound' || identifier == 'GH_massiveRotatorCuffTear') {
                                return true;
                            } else if (this.getValue('GH_massiveRotatorCuffTear') == true) {
                                //If rotator cuff tear is massive, follow this path
                                if (identifier == 'GH_massiveRotatorCuffTearProvideDetails') {
                                    return true;
                                } else if (identifier == 'GH_physiologicallyHealthyActiveAndSurgicalCandidate') {
                                    return true;
                                } else if (this.getValue('GH_physiologicallyHealthyActiveAndSurgicalCandidate') == true) {
                                    //If patient is candidate for surgery, show recommendation
                                    if (identifier == 'GH_FA2_requestSpecialistAssessment' || identifier == 'GH_hasSpecialistRecommendedMRI') {
                                        return true;
                                    } else if (this.getValue('GH_hasSpecialistRecommendedMRI') == true) {
                                        //If specialist has recommended MRI, show detail entry field
                                        if (identifier == 'GH_hasSpecialistRecommendedMRIProvideDetails') {
                                            return true;
                                        }
                                    }
                                } 
                                if (this.getValue('GH_physiologicallyHealthyActiveAndSurgicalCandidate') == false 
                                || (this.getValue('GH_physiologicallyHealthyActiveAndSurgicalCandidate') == true && this.getValue('GH_hasSpecialistRecommendedMRI') == false)) {
                                    //If patient not candidate for surgery, or is a candidate for surgery but specialist does not recommend it, show recommendation
                                    if (identifier == 'GH_FA2_painManagementAndActiveRehabilitationSection2' || identifier == 'GH_FA2_considerReferalToSpecialistRotatorCuff') {
                                        return true;
                                    }
                                }
                            }
                        } else if (this.getValue('GH_subacromialBursitisImpingementSyndrome') == true || this.getValue('GH_osteoarthritis') == true || this.getValue('GH_frozenShoulder') == true) {
                            //If any other criteria marked yes, show recommendation
                            if (identifier == 'GH_FA2_xRaysUltrasound' || identifier == 'GH_FA2_painManagementAndActiveRehabilitationSection3') {
                                return true;
                            } else if (identifier == 'GH_FA2_considerReferalToSpecialistVariableDiagnosis' && (this.getValue('GH_frozenShoulder') == true || this.getValue('GH_osteoarthritis') == true || this.getValue('GH_subacromialBursitisImpingementSyndrome') == true)) {
                                //If frozen shoulder, osteoarthritis or impingement syndrome is true, prepare to show recommendation
                                let recommendation = this.formDetail('GH_FA2_considerReferalToSpecialistVariableDiagnosis');
                                let detailText = 'If there is persistent pain after completion of an appropriate rehabilitation programme (eg. 6 months) and ongoing functional or physical impairment.';
                                if (this.getValue('GH_frozenShoulder') == true) {
                                    //If frozen shoulder, add specific detail text
                                    detailText += '\nSpecialist referral may be required for additional clinical management of frozen shoulder.';
                                }
                                if (this.getValue('GH_osteoarthritis') == true) {
                                    //If osteoarthritis, add specific detail text
                                    detailText += '\nSpecialist referral may be indicated for osteoarthritis with uncontrolled pain and functional limitation.';
                                }
                                if (this.getValue('GH_subacromialBursitisImpingementSyndrome') == true) {
                                    //If impingement syndrome, add specific detail text
                                    detailText += '\nTreat underlying causes of impingement syndrome first, prior to referral.';
                                }
                                //Show recommendation with specific detail text added
                                recommendation.detail = detailText;
                                return true;
                            }
                        }
                    }
                }
            }
        } else if (this.getValue('postTraumaticKneePain') == true) {
            //Do this branch if post traumatic knee pain selected
            const ottawaKneeFields = ['KP_OKR_overAge', 'KP_OKR_tendernessOfPatella', 'KP_OKR_tendernessOfFibula', 'KP_OKR_inabilityToFlex', 'KP_OKR_inabilityToBearWeight'];
            let ottawaKneeValues = ottawaKneeFields.map( childID => this.getValue(childID));
            if (identifier == 'KP_ottawaKneeRulesRecommendImaging' || identifier == 'KP_OKR_Criteria') {
                //This applies to KP_OKR_CriteriaForm, but because recommendations are displayed outside of subform,
                //we have to include hide/show logic here
                return true;
            } else if (ottawaKneeValues.includes(true)) {
                //If any value in Ottawa Knee Rule subform is true and all fields are answered, show subform recommendation
                if (identifier == 'KP_OKR_xrayRecommendedRecommendation') {
                    return true;
                }
            } else if (ottawaKneeValues.every( value => value == false)) {
                //If all values in Ottawa Knee Rule subform are false, show subform recommendation
                if (identifier == 'KP_OKR_xrayNotRecommendedRecommendation') {
                    return true;
                }
            }
            if (this.getValue('KP_ottawaKneeRulesRecommendImaging') == true) {
                //If imaging recommended, follow this path
                if (identifier == 'KP_FA1_xRays' || identifier == 'KP_xRayConfirmsFracture' || identifier == 'KP_suspectOccultFracture') {
                    return true;
                } else if (this.getValue('KP_xRayConfirmsFracture') == true || this.getValue('KP_suspectOccultFracture') == true) {
                    //If fracture confirmed or suspect occult fracture, show detail entry and recommendation
                    if (identifier == 'KP_xRayProvideDetails' || identifier == 'KP_FA1_acuteAssessment') {
                        return true;
                    }
                }
            } 
            if (this.getValue('KP_ottawaKneeRulesRecommendImaging') == false 
            || (this.getValue('KP_ottawaKneeRulesRecommendImaging') == true && this.getValue('KP_xRayConfirmsFracture') == false && this.getValue('KP_suspectOccultFracture') == false)) {
                //If imaging not recommended or x-ray taken, but doesn't confirm fracture and no suspicion of occult fracture, follow this path
                if (KP_clinicalDiagnosisFields.includes(identifier) || KP_clinicalDiagnosisSubforms.includes(identifier)) {
                    return true;
                }
                let formCompletionFields = ['KP_acutelyLockedKnee', 'KP_suspectAclLclRupture', 'KP_suspectMeniscalLesion', 'KP_suspectMCLPCLRupture', 'KP_suspectPLCInjury'];
                let formCompletionValues = formCompletionFields.map( childID => this.getValue(childID));
                if (!formCompletionValues.includes(null) && !formCompletionValues.includes(undefined)) {
                    //If all fields are answered, show recommendations
                    if (formCompletionValues.includes(true)) {
                        //If any fields are answered 'yes', show detail entry field
                        if (identifier == 'KP_additionalInformation') {
                            return true;
                        } 
                    }
                    if (this.getValue('KP_acutelyLockedKnee') == true || this.getValue('KP_suspectPLCInjury') == true) {
                        //If acutely locked knee or PLC, show recommendation
                        if (identifier == 'KP_LK_PCL_AcuteAssessment') {
                            return true;
                        }
                    }
                    if (this.getValue('KP_acutelyLockedKnee') == true || this.getValue('KP_suspectAclLclRupture') == true ) {
                        //If acutely locked knee or ACL/LCL, show recommendation
                        if (identifier == 'KP_LK_ACL_LCL_MRI') {
                            return true;
                        }
                    } else if (this.getValue('KP_suspectMeniscalLesion') == true) {
                        //If meniscal lesion, start recommendation path, show initial recommendations
                        if (identifier == 'KP_ML_StandingXray' || identifier == 'KP_moderateToSevereOA') {
                            return true;
                        } else if (identifier == 'KP_ML_ActiveRehabilitation' || identifier == 'KP_improvementAfterActiveRehabilitation') {
                            return true;
                        } else if (this.getValue('KP_moderateToSevereOA') == false && this.getValue('KP_improvementAfterActiveRehabilitation') == false) {
                            //If not OA and no improvement, show further recommendations
                            if (identifier == 'KP_ML2_NoOAAndNoImprovement') {
                                return true;
                            }
                        } else if (this.getValue('KP_moderateToSevereOA') == true) {
                            //If AO, show further recommendations
                            if (identifier == 'KP_ML2_ContinueActiveRehabilitation') {
                                return true;
                            }
                        }
                    } else if (this.getValue('KP_suspectMCLPCLRupture') == true) {
                        //If MCL/PCL, show recommendations
                        if (identifier == 'KP_MCL_PCL_StandingXray' || identifier == 'KP_MCL_PCL_ActiveRehabilitation') {
                            return true;
                        }
                    }
                }
            }
        }
        
        return false;
    }

    shouldDisplayFormDetailForIdentifier(identifier) {
        super.shouldDisplayFormDetailForIdentifier(identifier);

        return this.getResultFromLogicTree(identifier);
    }

    isEnabled(identifier) {
        if (identifier != null) {
            return true;
        }
        return false;
    }

    isMandatory(identifier) {
        const completionItemRelationships = {
            'LSI_persistentPain': 'LSI_RF_managePerAcuteClinicalManagementPathways',
            'CSI_xRayConfirmsFracture': 'CSI_FA1_xRay',
            'CSI_FA2_persistentPain': 'CSI_FA1_painManagementAndActiveRehabilitation',
            'GH_FA1_xRayConfirmsFracture': 'GH_FA1_xRays',
            'GH_massiveRotatorCuffTear': 'GH_FA1_xRaysUltrasound',
            'GH_hasSpecialistRecommendedMRI': 'GH_FA2_requestSpecialistAssessment',
            'KP_xRayConfirmsFracture': 'KP_FA1_xRays',
            'KP_suspectOccultFracture': 'KP_FA1_xRays',
            'KP_moderateToSevereOA': 'KP_ML_StandingXray',
            'KP_improvementAfterActiveRehabilitation': 'KP_ML_ActiveRehabilitation'
        };

        for (let subcellID in completionItemRelationships) {
            let completionItem = completionItemRelationships[subcellID];
            if (identifier == subcellID) {
                if (this.getValue(completionItem) == false) {
                    return false;
                }
            }
        } 

        if (this.getResultFromLogicTree(identifier) == false) {
            return false;
        }
        let formDetail = this.formDetail(identifier);
        
        return formDetail.canHaveValue && !(formDetail.controlType == 'completionItem');
    }

    getBadgeValue(identifier) {
        let section = this.sectionReference(identifier);
        if (section[PathwayReferenceHelper.Type.controlType] == 'badge') {
            return [{
                color: FormsStyles.red,
                value: 'URGENT',
                solid: true
            }];
        }
        return [];
    }

    getValueType(identifier) {
        const stringFields = ['LSI_RP_detailFindings', 'CSI_RP_detailFindings', 'KP_ML_detailFindings', 'KP_AF2_detailFindings'];
        if (stringFields.includes(identifier)) {
            return 'string';
        }
        return 'boolean';
    }

    submissionValidation(identifier) {
        var validations = [];

        // Identify any criteria conflict scenarios
        
        let formValidationConflictIdentifier;
        let parentValueIdentifier;
        let dependentValueIdentifiers;
        
        if (identifier == 'LSI_RP_Criteria') {
            formValidationConflictIdentifier = 'LSI_RadicularPainConflict';
            parentValueIdentifier = 'LSI_RadicularPain';
            dependentValueIdentifiers = ['LSI_lancinatingNervePainRadiatingDownArmWithinNarrowConfine', 'LSI_uncomfortableAtNightWithPain', 'LSI_positiveStraightLegRaiseTest', 'LSI_positiveSlumpTest', 'LSI_positiveFemoralNerveStretchTest'];
        } else if (identifier == 'CSI_RP_Criteria') {
            formValidationConflictIdentifier = 'CSI_RadicularPainConflict';
            parentValueIdentifier = 'CSI_FA2_RadicularPain';
            dependentValueIdentifiers = ['CSI_lancinatingNervePainRadiatingDownArmWithinNarrowConfine', 'CSI_uncomfortableAtNightWithPain', 'CSI_positiveSpurlingTest'];
        } else if (identifier == 'KP_OKR_Criteria') {
            formValidationConflictIdentifier = 'KP_OKR_CriteriaConflict';
            parentValueIdentifier = 'KP_ottawaKneeRulesRecommendImaging';
            dependentValueIdentifiers = ['KP_OKR_overAge', 'KP_OKR_tendernessOfPatella', 'KP_OKR_tendernessOfFibula', 'KP_OKR_inabilityToFlex', 'KP_OKR_inabilityToBearWeight'];
        } else if (identifier == 'KP_ACL_LCL_Criteria') {
            formValidationConflictIdentifier = 'KP_ACL_LCL_CriteriaConflict';
            parentValueIdentifier = 'KP_suspectAclLclRupture';
            dependentValueIdentifiers = ['KP_ACL_injuryMechanism', 'KP_ACL_symptoms', 'KP_ACL_signs', 'KP_ACL_lachmanTestPositive', 'KP_ACL_anteriorDrawTestPositive', 'KP_LCL_laxityOnVarusStress'];
        } else if (identifier == 'KP_ML_Criteria') {
            formValidationConflictIdentifier = 'KP_ML_CriteriaConflict';
            parentValueIdentifier = 'KP_suspectMeniscalLesion';
            dependentValueIdentifiers = ['KP_ML_injuryMechanism', 'KP_ML_symptoms', 'KP_ML_signs', 'KP_ML_thessalyTestMcMurrayTest'];
        } else if (identifier == 'KP_MCL_PCL_Criteria') {
            formValidationConflictIdentifier = 'KP_MCL_PCL_CriteriaConflict';
            parentValueIdentifier = 'KP_suspectMCLPCLRupture';
            dependentValueIdentifiers = ['KP_MCL_PCL_mclInjury', 'KP_MCL_PCL_pclInjury'];
        }

        if (dependentValueIdentifiers != null && dependentValueIdentifiers != undefined) {
            let self = this;

            let criteriaConflict = new FormValidation(formValidationConflictIdentifier, function () {
                let value = self.getValue(parentValueIdentifier);
                let dependentValues = dependentValueIdentifiers.map (dependentValueIdentifier => self.getValue(dependentValueIdentifier));
                let title = self.formDetail(identifier).title;
                if (value == false) {
                    if (dependentValues.includes(true)) {
                        
                        return [new FormValidationError(FormValidationError.FormErrorType.error, `Modify ${title} to match selection`)];
                    }
                } else if (value == true) {
                    if (!dependentValues.includes(true) && !dependentValues.includes(null) && !dependentValues.includes(undefined)) {
                        return [new FormValidationError(FormValidationError.FormErrorType.error, `Modify ${title} to match selection`)];
                    }
                }

                return [];
            });

            validations.push(criteriaConflict);
        }
        
        return validations;
    }

    editingValidation(){
        return [];
    }

    detailString(isSubcell, isPlainText, forTextExport) {
        if (forTextExport == true) {
            let sectionStrings = this.sectionIds.map((sectionID) => {
                let section = this.sectionReference(sectionID);
                var sectionString = '';
                
                let sectionValues = [];
                let notPresentWithinSectionValues = [];
                let sectionsToDisplay = [];
                if(section[PathwayReferenceHelper.Type.sectionChildIDs] != undefined){
                    section[PathwayReferenceHelper.Type.sectionChildIDs].map((childID) => { //Loop through each field in section
                        
                        if (this.shouldDisplayFormDetailForIdentifier(childID)) { //If field is visible in assessment
                            let formDetail = this.formDetail(childID);

                            if (section[PathwayReferenceHelper.Type.sectionStyle] == FormsContentType.SectionStyle.inline) { //If section is a recommendation
                                
                                let recommendation = []; //Construct a recommendation for export (so we can split by '\n', not ',')
                                if (formDetail.title != null && formDetail.title != undefined) {
                                    recommendation.push(formDetail.title); //Add recommendation text recommendation construction
                                }
                                
                                if (formDetail.controlType == FormsContentType.ControlType.completionItem) { //If recommendation is completion style, loop through subcells
                                    formDetail[PathwayReferenceHelper.Type.subcellIDs].map((subcellID) => {

                                        if (this.shouldDisplayFormDetailForIdentifier(subcellID)) {

                                            let subcellDetail = this.formDetail(subcellID);
                                            let summaryValue = this.getSummaryValue(false, subcellDetail);
                                            if (summaryValue != null) {
                                                recommendation.push(summaryValue); //If subcell is answered, add field to recommendation construction
                                            }
                                        }
                                    });
                                }

                                if (recommendation.length > 0) {
                                    sectionValues.push(recommendation.join('\n')); //Add the formatted recommendation export to the export values
                                }
                            } else if (formDetail.canHaveValue == true) { //If field is not recommendation and can have a value
                                if (formDetail[PathwayReferenceHelper.Type.subcellIDs].length > 0) { //If the field has subcells, check them out
                                    let summaryValue = this.getSummaryValue(false, formDetail);
                                    
                                    if (summaryValue == false) {
                                        notPresentWithinSectionValues.push(formDetail.title); //If field is answered no, add field to 'Not present:' list
                                    } else if (summaryValue != null) {
                                        let subcellValues = []; //Construct a subcell group for export (so we can format differently from regular fields)
                                        subcellValues.push(summaryValue); 

                                        formDetail[PathwayReferenceHelper.Type.subcellIDs].map((subcellID) => { //Loop through subcells
                                            if (this.shouldDisplayFormDetailForIdentifier(subcellID)) {
                                                let subcellDetail = this.formDetail(subcellID);

                                                if (subcellDetail.type == FormsContentType.ItemType.subform) { //If subcell is a subform, get subform details
                                                    let subform = this.formLoader.form('accmri01', subcellID);

                                                    if (subform != null && subform != undefined) {
                                                        let detailString = subform.detailString(true); //Get subform detail string

                                                        if (detailString != '') {
                                                            subcellValues.push('  ' + detailString); //Add subform detail to subcell group export
                                                        }
                                                    }
                                                } else { //If subcell is not a subform, get details from the subcell
                                                    let summaryValue = this.getSummaryValue(false, subcellDetail);

                                                    if (summaryValue != null) {
                                                        subcellValues.push('  ' + summaryValue); //Add subcell detail to subcell group export
                                                    }
                                                }
                                                
                                            }
                                        });
                                        if (subcellValues.length > 0) {
                                            sectionValues.push(subcellValues.join('\n')); //Add the formatted subcell export to the export values
                                        }
                                    }
                                } else { //If field is normal field
                                    let summaryValue = this.getSummaryValue(false, formDetail);
                               
                                    if (summaryValue == false) {
                                        notPresentWithinSectionValues.push(formDetail.title); //If field is answered no, add field to 'Not present:' list
                                    } else if (summaryValue != null) {
                                        sectionValues.push(summaryValue); //If field answered yes, add field to export
                                    }
                                }
                            }
                            sectionsToDisplay.push(sectionID); //Add section to list of sections to display
                        } 
                    });
                    
                    if (sectionValues.length > 0) { 
                        if (section.title != null) {
                            sectionString += section.title.toUpperCase() + '\n';
                        }
                        if (section.title == 'Red flags') {
                            sectionString += sectionValues.join(', ') + '\n';
                        } else {
                            sectionString += sectionValues.join('\n') + '\n';
                        }
                    }

                    if (notPresentWithinSectionValues.length > 0) {
                        if (!(sectionValues.length > 0) && section.title != null) {
                            sectionString += section.title.toUpperCase() + '\n';
                        }
                        sectionString += '(Not present: ' + notPresentWithinSectionValues.join(', ') + ')\n';
                    }
                }

                return sectionString == '' ? null : sectionString;
            }).filter( n => n);
            
            let combinedSections = sectionStrings.join('\n');

            return combinedSections;
        } else {
            let formDetails = this.getFormDetails();
            let values = [];
            for (let index in formDetails) {
                let value = formDetails[index];
                if (this.getResultFromLogicTree(value.identifier) == true) {
                    if (value.canHaveValue == true) {
                        values.push(this.getSummaryValue(null, value));
                    }
                }
            }
            return values.filter(n => n).join(isPlainText ? ', ' : '\n');
        }
    }

    getSummaryValue(isSubcell, value){
        let formDetail = this.formDetail(value.identifier);
        if (formDetail.canHaveValue == false) {
            return value.title;
        } else if (value.currentValue != null && value.currentValue != undefined) {
            if (value.title.endsWith('?') == true) {
                return value.title + (value.currentValue == true ? ': Yes' : ': No');
            } else if (value.controlType == 'textEntry') {
                return value.title + ': ' + value.currentValue;
            } else if (value.controlSubtype == 'simple') {
                let childID = PathwayReferenceHelper.reference(value.currentValue, ACCMRIPathwayReference.data);
                if (childID != null && childID != undefined) {
                    return value.title + ': ' + childID.title;
                }
            } else {
                return value.valueDescription();
            }
        }
    }
}