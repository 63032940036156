export const RESULTS_ALERT_POST_FULFILLED = 'RESULTS_ALERT_POST_FULFILLED';
export const RESULTS_ALERT_CLEAR_FULFILLED = 'RESULTS_ALERT_CLEAR_FULFILLED';

export const RESULTS_BADGE_POST_FULFILLED = 'RESULTS_BADGE_POST_FULFILLED';
export const RESULTS_BADGE_CLEAR_FULFILLED = 'RESULTS_BADGE_CLEAR_FULFILLED';

export const REMOVE_ALERT_VALUE_FULFILLED = 'REMOVE_ALERT_VALUE_FULFILLED';

const postResultsAlert = (pathwayId, changes) => (dispatch) => {

    dispatch({
        type: RESULTS_ALERT_POST_FULFILLED,
        payload: {
            pathwayId: pathwayId,
            changes: changes
        }
    });
};

const removeResultsAlertValue = (pathwayId, value) => (dispatch) => {
    dispatch({
        type: REMOVE_ALERT_VALUE_FULFILLED,
        payload: {
            pathwayId: pathwayId,
            value: value
        }
    });
};

const clearResultsAlert = (pathwayId) => (dispatch) => {
    dispatch({
        type: RESULTS_ALERT_CLEAR_FULFILLED,
        payload: {
            pathwayId: pathwayId,
            changes: []
        }
    });
};

const postResultsBadge = (pathwayId) => (dispatch) => {
    dispatch({
        type: RESULTS_BADGE_POST_FULFILLED,
        payload: pathwayId
    });
};

const clearResultsBadge = (pathwayId) => (dispatch) => {
    dispatch({
        type: RESULTS_BADGE_CLEAR_FULFILLED,
        payload: pathwayId
    });
};

export { postResultsAlert, clearResultsAlert, postResultsBadge, clearResultsBadge, removeResultsAlertValue};