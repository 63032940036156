import { FormWithRedux } from '../../FormWithRedux';


export class EDChestPainAorticDissectionCriteriaForm extends FormWithRedux {

    constructor(formIdentifier, formLoader, parent, actionUpdater, dependentValuesUpdater, calculationsUpdater) {
        super(formIdentifier,formLoader, parent, actionUpdater, dependentValuesUpdater, calculationsUpdater);
    }

    shouldDisplayFormDetailForIdentifier(identifier) {
        super.shouldDisplayFormDetailForIdentifier(identifier);
    
        let dependantValue = this.getValue('aorticDissectionPresent');
        if(dependantValue == null || dependantValue == true){
            return true;
        } else {
            let aorticDissectionCriteriaHighRiskConditions = this.getValue('aorticDissectionCriteriaHighRiskConditions');
            let aorticDissectionCriteriaHighRiskSymptoms = this.getValue('aorticDissectionCriteriaHighRiskSymptoms');
            let aorticDissectionCriteriaHighRiskFindings = this.getValue('aorticDissectionCriteriaHighRiskFindings');
            return aorticDissectionCriteriaHighRiskConditions != null || aorticDissectionCriteriaHighRiskSymptoms != null || aorticDissectionCriteriaHighRiskFindings != null;
        } 
    }

    isEnabled(identifier) {
        if (identifier != null) {
            return true;
        }
        return false;
    }

    isMandatory() {
        return false;
    }

    getValueType() {
        return 'boolean';
    }

    submissionValidation() {
        
        return [];
    }

    detailString(isSubform) {
        let formDetails = this.getFormDetails();
        var valueDescriptions = [];

        for (let formDetailIndex in formDetails) {
            let formDetail = formDetails[formDetailIndex];
            let valueDescription = formDetail.valueDescription();
            let abreiviatedValue = this.abbreviatedString(formDetail.identifier, valueDescription);
            valueDescriptions.push(abreiviatedValue == null ? null : ((isSubform ? '- ' : '') + abreiviatedValue ));
        }

        if(valueDescriptions.filter(n => n).length == 0){
            return isSubform ? '' : this.detail;
        }

        return valueDescriptions.filter(n => n).join(',\n');
    }

    abbreviatedString(identifier, value) {

        if (value == null || value == false) {
            return null;
        }

        switch (identifier) {
            case 'aorticDissectionCriteriaHighRiskConditions':
                return 'High-risk conditions';
            case 'aorticDissectionCriteriaHighRiskSymptoms':
                return 'High-risk symptoms';
            case 'aorticDissectionCriteriaHighRiskFindings':
                return 'High-risk findings';
        }
    }

}