import { FormsUIGlossary } from '../../FormsUI/FormsUI';

export class FormValidationError {
    static FormErrorType = Object.freeze({ warning: 1, error: 2, required: 3 });

    static RequiredError = new FormValidationError(FormValidationError.FormErrorType.required, FormsUIGlossary.required);

    constructor(errorType, message) {
        this.errorType = errorType;
        this.message = message;
    }

    get color(){
        if(this.errorType == FormValidationError.FormErrorType.warning){
            return FormValidationError.FormErrorTypeColor.warning;
        } else if(this.errorType == FormValidationError.FormErrorType.error){
            return FormValidationError.FormErrorTypeColor.error;
        } else if(this.errorType == FormValidationError.FormErrorType.required){
            return FormValidationError.FormErrorTypeColor.required;
        } else {
            return FormValidationError.FormErrorTypeColor.error; 
        }
    }

    static FormErrorTypeColor = { warning: 'orange', error: 'red', required: 'red' };
}