import { FormWithRedux } from '../../FormWithRedux';

export class GallbladderComplicatedBiliarDiseaseForm extends FormWithRedux {

    constructor(formLoader, parent, actionUpdater, dependentValuesUpdater) {
        super('complicatedBiliaryDiseaseCriteriaForm', formLoader, parent, actionUpdater, dependentValuesUpdater);
    }

    shouldDisplayFormDetailForIdentifier(identifier) {
        super.shouldDisplayFormDetailForIdentifier(identifier);
        return true;
    }

    isEnabled(identifier) {
        if (identifier != null) {
            return true;
        }
        return false;
    }

    isMandatory() {
        return false;
    }

    getValueType() {
        return 'boolean';
    }

    submissionValidation() {
        return [];
    }

    detailString(isSubform) {
        let formDetails = this.getFormDetails();
        let values = formDetails.map((value) => {
            return value.valueDescription() == null ? null : (isSubform ? '- ' : '') + value.valueDescription();
        });

        if(values.filter(n => n).length == 0) {
            return isSubform ? '' : this.detail;
        }

        return values.filter(n => n).join(',\n');
    }
}
