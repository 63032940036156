// IMPORT PACKAGE REFERENCES

import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { Navigate, Outlet } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

// IMPORT PROJECT REFERENCES
import { Sidebar } from "../Navigation/Sidebar";
import { Header } from "../Navigation/Header";
import { EnvironmentInfoOverlay } from "../pages/Overlays/EnvironmentInfoOverlay";

import { createNewResponse } from "../state/actions/WorkflowResponseActions";
import { SessionManagementPage } from "../pages/Overlays/SessionManagementOverlay";
import { LoginOverlay } from "../pages/Overlays/LoginOverlay";

import { Footer } from "../Navigation/Footer";
import { getPathwayIdFromURL, getFormIdFromURL } from "../../helpers/URLHelper";

import {
  PATHWAYS_WITH_AUTH,
  ENVIRONMENT,
  ENABLED_PATHWAYS,
} from "../../config/config";

import { QuidelOrthoPathWay } from "../../helpers/URLHelper";
import { FormsStyles } from "../../waferJS/FormsUI/FormsUI";

/* 
    Manage the authorisation and routing of the user based on the provided URL parameters. 
*/
class DSRouter extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      collapsed: true,
      sessionManagerVisible: false,
      extensionNavigation: false,
      collapseHeader: true,
      environmentOverlayVisible: false,
      sidebarOffset: false,
      hideUserContent: false,
      loggedIn: false,
    };

    this.showSideBar = this.showSideBar.bind(this);
    this.hideSideBar = this.hideSideBar.bind(this);
    this.toggleSessionOverlay = this.toggleSessionOverlay.bind(this);
    this.checkQueryParams = this.checkQueryParams.bind(this);
    this.hideSessionOverlay = this.hideSessionOverlay.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.toggleEnvironmentOverlay = this.toggleEnvironmentOverlay.bind(this);
    this.toggleUserContentVisibility =
      this.toggleUserContentVisibility.bind(this);
    this.checkQueryParams();

    // Change primary color for QuidelOrtho
    let pathwayId = getPathwayIdFromURL();
    const quidelPathway = pathwayId === QuidelOrthoPathWay;
    if (quidelPathway) {
      FormsStyles.quidelStyle = true;
    }
    const root = document.documentElement;
    if (root) {
      root.style.setProperty(
        "--accent-color",
        quidelPathway ? "#774EEF" : "#00A1F1"
      );
      root.style.setProperty(
        "--primary-tint",
        quidelPathway ? "#774EEF" : "#00A1F1"
      );
    }
  }

  componentDidMount() {
    this.checkQueryParams();
    document.addEventListener("click", this.handleClick);
    document.addEventListener("scroll", this.handleScroll);
    if (
      window.location.pathname === "/" + getPathwayIdFromURL() + "/" ||
      window.location.pathname === "/" + getPathwayIdFromURL()
    ) {
      this.setState({ sessionManagerVisible: true });
    } else {
      this.setState({ sessionManagerVisible: false });
    }

    window.addEventListener(
      "popstate",
      function () {
        this.checkQueryParams();
      }.bind(this)
    );

    if (PATHWAYS_WITH_AUTH.includes(getPathwayIdFromURL())) {
      this.updateLoginState();
    }
  }

  componentWillUnmount() {
    document.removeEventListener("click", this.handleClick);
    document.removeEventListener("scroll", this.handleScroll);
  }

  componentDidUpdate() {
    this.checkQueryParams();
  }

  checkQueryParams() {
    let params = new URLSearchParams(window.location.search);
    let shouldUseExtensionNavigation =
      params.get("hideNavigation") != null
        ? params.get("hideNavigation")
        : params.get("isExtension");

    shouldUseExtensionNavigation = shouldUseExtensionNavigation == "true";
    if (this.state.extensionNavigation != shouldUseExtensionNavigation) {
      this.setState({ extensionNavigation: shouldUseExtensionNavigation });
    }

    if (
      shouldUseExtensionNavigation &&
      (this.props.workflows[getPathwayIdFromURL()] == null ||
        JSON.stringify(this.props.workflows[getPathwayIdFromURL()]) ==
          JSON.stringify({}))
    ) {
      this.props.createNewResponse(getPathwayIdFromURL());
    }
  }

  showSideBar() {
    this.setState({ collapsed: false });
  }

  hideSideBar() {
    this.setState({ collapsed: true });
  }

  toggleSessionOverlay() {
    if (!this.state.sessionManagerVisible) {
      document.body.classList.add("disable-scroll");
    } else {
      document.body.classList.remove("disable-scroll");
    }

    this.setState({
      sessionManagerVisible: !this.state.sessionManagerVisible,
      hideUserContent: !this.state.sessionManagerVisible == true,
    });
  }

  toggleEnvironmentOverlay() {
    if (!this.state.environmentOverlayVisible) {
      document.body.classList.add("disable-scroll");
    } else {
      document.body.classList.remove("disable-scroll");
    }
    this.setState({
      environmentOverlayVisible: !this.state.environmentOverlayVisible,
    });
  }

  hideSessionOverlay() {
    document.body.classList.remove("disable-scroll");
    this.setState({ sessionManagerVisible: false });
    this.toggleUserContentVisibility(false);
  }

  handleClick(e) {
    if (
      this.sidebarNode != null &&
      !this.sidebarNode.contains(e.target) &&
      !this.state.collapsed &&
      !this.hamburgerNode.contains(e.target)
    ) {
      this.hideSideBar();
    }
  }

  toggleUserContentVisibility(hideContent) {
    this.setState({ hideUserContent: hideContent });
  }

  constructURLForRedirect() {
    let url = "https://access.wayfind.health/";
    var parameters = [];

    if (
      ["dev", "test", "staging", "demo"].includes(ENVIRONMENT.toLowerCase())
    ) {
      parameters.push("env=" + ENVIRONMENT.toLowerCase());
    }

    let pathwayId = getPathwayIdFromURL();
    if (pathwayId.length > 0) {
      parameters.push("code=" + pathwayId);
    } else {
      return "https://wayfind.health";
    }

    if (parameters.length > 0) {
      var urlWithParameters = url + "?" + parameters.join("&");

      return urlWithParameters;
    } else {
      return url;
    }
  }

  shouldRedirect() {
    let pathwayId = getPathwayIdFromURL();
    if (
      !ENABLED_PATHWAYS.includes(pathwayId) &&
      !pathwayId.startsWith("auth")
    ) {
      return { shouldRedirect: true, path: this.constructURLForRedirect() };
    }

    let params = new URLSearchParams(window.location.search);
    let shouldUseExtensionNavigation =
      params.get("hideNavigation") != null
        ? params.get("hideNavigation")
        : params.get("isExtension");
    shouldUseExtensionNavigation = shouldUseExtensionNavigation == "true";
    let shouldRedirect =
      window.location.pathname != "/" + pathwayId + "/handover" &&
      (this.props.workflows[pathwayId] == undefined ||
        JSON.stringify(this.props.workflows[pathwayId]) ===
          JSON.stringify({})) &&
      !shouldUseExtensionNavigation &&
      window.location.pathname != "/" + pathwayId + "/" &&
      window.location.pathname != "/" + pathwayId;

    return { shouldRedirect: shouldRedirect, path: "/" + pathwayId };
  }

  updateLoginState() {
    // Login does not support yet
    // let authToken = getCachedToken != null ? getCachedToken() : null;
    // if (authToken) {
    //     let decodedToken = decode(authToken);
    //     if (decodedToken) {
    //         this.setState({ loggedIn: true });
    //         this.toggleUserContentVisibility(false);
    //     } else {
    //         this.setState({ loggedIn: false });
    //         this.toggleUserContentVisibility(true);
    //     }
    // }
  }

  render() {
    let pathwayId = getPathwayIdFromURL();
    let redirect = this.shouldRedirect();

    if (redirect.shouldRedirect) {
      window.open(redirect.path, "_self");
    } else {
      return (
        <Fragment>
          {PATHWAYS_WITH_AUTH.includes(pathwayId) && !this.state.loggedIn && (
            <LoginOverlay
              toggleUserContentVisibility={this.toggleUserContentVisibility}
            />
          )}
          {this.state.environmentOverlayVisible && (
            <EnvironmentInfoOverlay
              formLoader={this.props.formLoader}
              toggleOverlay={this.toggleEnvironmentOverlay}
            />
          )}
          {this.state.sessionManagerVisible &&
            ((PATHWAYS_WITH_AUTH.includes(pathwayId) && this.state.loggedIn) ||
              !PATHWAYS_WITH_AUTH.includes(pathwayId)) && (
              <SessionManagementPage
                pathwayNeedsAuth={PATHWAYS_WITH_AUTH.includes(pathwayId)}
                toggleVisibility={this.hideSessionOverlay}
                isExtension={this.state.extensionNavigation}
                pathwayId={pathwayId}
                toggleUserContentVisibility={this.toggleUserContentVisibility}
                formLoader={this.props.formLoader}
              />
            )}

          <div className="wrapper">
            <Header
              pathwayNeedsAuth={PATHWAYS_WITH_AUTH.includes(pathwayId)}
              collapsed={this.state.collapsed}
              isOverlayVisible={this.state.hideUserContent}
              collapseHeader={this.state.collapseHeader}
              showSideBar={this.showSideBar}
              toggleSessionManager={this.toggleSessionOverlay}
              toggleEnvironmentOverlay={this.toggleEnvironmentOverlay}
              isExtension={this.state.extensionNavigation}
              hideSideBar={this.hideSideBar}
              hamburgerNode={(hamburgerNode) =>
                (this.hamburgerNode = hamburgerNode)
              }
              pathwayId={pathwayId}
              formId={getFormIdFromURL()}
              formLoader={this.props.formLoader}
              userName={this.props.userName}
              userHPINumber={this.props.userHPINumber}
              userEmail={this.props.userEmail}
              logout={() => {
                this.props.logout();
              }}
            />

            <div className="content-container">
              <div
                className={
                  "sidebar " +
                  (this.state.collapsed ? "collapsed " : "") +
                  (this.state.sidebarOffset ? "offset" : "")
                }
              >
                {!this.state.hideUserContent && (
                  <Sidebar
                    pathwayId={pathwayId}
                    formId={getFormIdFromURL()}
                    collapseHeader={this.state.collapseHeader}
                    collapsed={this.state.collapsed}
                    hideSideBar={this.hideSideBar}
                    formLoader={this.props.formLoader}
                  />
                )}
              </div>

              <div className="form">
                {redirect.shouldRedirect && <Navigate to={redirect.path} />}
                {!redirect.shouldRedirect && !this.state.hideUserContent && (
                  <Outlet />
                )}
              </div>
            </div>

            {!this.state.extensionNavigation && (
              <Footer
                showEnvironmentOverlay={this.toggleEnvironmentOverlay}
                isOverlayVisible={this.state.hideUserContent}
              />
            )}
          </div>
        </Fragment>
      );
    }
  }
}

DSRouter.propTypes = {
  createNewResponse: PropTypes.func.isRequired,
  workflows: PropTypes.object.isRequired,
  creatingNewWorkflowPending: PropTypes.bool.isRequired,
  creatingNewWorkflowFulfilled: PropTypes.bool.isRequired,
  creatingNewWorkflowFailed: PropTypes.bool.isRequired,
  formLoader: PropTypes.object.isRequired,
  userName: PropTypes.string,
  userHPINumber: PropTypes.string,
  userEmail: PropTypes.string,
  logout: PropTypes.func.isRequired,
};

// CONFIGURE REACT REDUX
const mapStateToProps = (state) => {
  const {
    workflows,
    creatingNewWorkflowPending,
    creatingNewWorkflowFulfilled,
    creatingNewWorkflowFailed,
  } = state.workflowReducer;
  return {
    workflows,
    creatingNewWorkflowPending,
    creatingNewWorkflowFulfilled,
    creatingNewWorkflowFailed,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ createNewResponse }, dispatch);

const hoc = connect(mapStateToProps, mapDispatchToProps)(DSRouter);

// EXPORT COMPONENT
export { hoc as DSRouter };
