// IMPORT PACKAGE REFERENCES
import React from 'react';
import PropTypes from 'prop-types';
import 'react-day-picker/dist/style.css';
import { FormTextField } from './FormTextField';
import { FormsStyles } from '../../FormsUI';
import moment from 'moment';
import '../../Styles/datePicker.scss';

// const formattedDate = 'DD-MMM-YYYY';
// const formattedTime = 'HH:mm';

// const dateFormats = [formattedDate, 'DD/MM/YYYY', 'DD-MM-YYYY'];
// const timeFormats = [formattedTime, 'hh:mm a'];

// COMPONENT
export class FormDate extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            dateTextFieldFocused: false,
            timeTextFieldFocused: true,
            formattedDate: props.formattedDate != null ? props.formattedDate : 'DD-MMM-YYYY',
            formattedTime: props.formattedTime != null ? props.formattedTime : 'HH:mm',
        };
        
        this.dateStringChanged = this.dateStringChanged.bind(this);
        this.dateTextFieldFocus = this.dateTextFieldFocus.bind(this);
        this.onDateFieldKeyPress = this.onDateFieldKeyPress.bind(this);
        this.dateTextFieldBlur = this.dateTextFieldBlur.bind(this);
        this.timeTextFieldFocus = this.timeTextFieldFocus.bind(this);
        this.timeStringChanged = this.timeStringChanged.bind(this);
        this.formatValue = this.formatValue.bind(this);
        this.timeTextFieldBlur = this.timeTextFieldBlur.bind(this);
    }

    componentDidMount() {
        this.formatValue();
        document.addEventListener('click', this.handleClick);
    }

    dateFormats() {
        return [this.state.formattedDate, 'DD/MM/YYYY', 'DD-MM-YYYY'];
    }

    timeFormats(){
        return [this.state.formattedTime, 'hh:mm a'];
    }

    formatValue(){
        let dateString = this.props.value == null || this.props.value == undefined ? '' : moment(this.props.value).format(this.state.formattedDate);
        let timeString = this.props.value == null || this.props.value == undefined ? '' : moment(this.props.value).format(this.state.formattedTime);

        this.setState({
            date: this.props.value == null ? this.props.value : moment(this.props.value).toDate(),
            dateString: dateString,
            timeString: timeString
        });
    }

    componentDidUpdate(prevProps){
        if(this.props.value != prevProps.value){
            this.formatValue();
        }

        if(this.props.formattedDate != prevProps.formattedDate){
            this.setState({
                formattedDate: this.props.formattedDate != null ? this.props.formattedDate : 'DD-MMM-YYYY',
            });
        }

        if(this.props.formattedTime != prevProps.formattedTime){
            this.setState({
                formattedTime: this.props.formattedTime != null ? this.props.formattedTime : 'HH:mm',
            });
        }

        if(this.props.adoptedTodayString != prevProps.adoptedTodayString && this.props.adoptedTodayString){
            this.setState({dateString: moment().format(this.state.formattedDate)});
        }
    }

    dateStringChanged(value) {
        if(value == null || value == ''){
            this.setState({
                dateString: null,
            });
        } else {
            this.setState({dateString: value});
        }
    }

    timeStringChanged(value){
        if(value == null || value == ''){
            this.setState({
                timeString: null,
            });
        } else {
            this.setState({timeString: value});
        }
    }

    timeTextFieldFocus() {
        this.setState({
            pickerVisible: false,
            timeTextFieldFocused: true
        });
        this.props.toggleNowButton(true);
    }

    timeTextFieldBlur(value){
        this.setState({
            timeTextFieldFocused: false,
            timeString: value
        },this.updateTimeString);

        this.props.toggleNowButton(false);
    }

    updateTimeString(){
        let momentDate;
        for(let timeFormat of this.timeFormats()){
            let testDate = moment(this.state.timeString, timeFormat);
            if(testDate.toDate() != 'Invalid Date' && testDate.isValid){
                momentDate = moment(this.state.timeString, timeFormat);
            }
        }
        let timeString = momentDate == null || !momentDate.isValid || momentDate.format(this.state.formattedTime) == 'Invalid date' ? this.state.timeString : momentDate.format(this.state.formattedTime);
        this.setState({
            timeString: timeString
        },this.updateDate);
    }

    updateDateString(){
        let momentDate;
        for(let dateFormat of this.dateFormats()){
            let testDate = moment(this.state.dateString, dateFormat);
            if(testDate.toDate() != 'Invalid Date' && testDate.isValid){
                momentDate = moment(this.state.dateString, dateFormat);
            }
        }
        let dateString = momentDate == null || !momentDate.isValid || momentDate.format(this.state.formattedDate) == 'Invalid date'? this.state.dateString : momentDate.format(this.state.formattedDate);
        this.setState({
            dateString: dateString
        },this.updateDate);
    }

    dateTextFieldFocus() {
        this.setState({
            dateTextFieldFocused: true
        });

        if(this.props.toggleTodayButton != null){
            this.props.toggleTodayButton(true);
        }
    }

    dateTextFieldBlur(value) {
        this.setState({
            dateTextFieldFocused: false,
            dateString: value
        }, this.updateDateString);

        if(this.props.toggleTodayButton != null){
            this.props.toggleTodayButton(false);
        }
    }

    onDateFieldKeyPress(event) {
        if (event != undefined && event.key === 'Enter') {
            event.target.blur();
        }
    }

    updateDate(){
        if(this.state.dateString != null && (this.state.timeString != null || !this.props.withTime) && this.state.dateString != '' && (this.state.timeString != '' || !this.props.withTime)){
            let combinedDate = moment(this.state.dateString + ' ' + this.state.timeString, this.state.formattedDate + ' ' + this.state.formattedTime);
            if(combinedDate.toDate() != 'Invalid Date'){
                this.props.dateSelected(combinedDate.toDate());
            }
        } else if((this.state.dateString == null || this.state.dateString == '') && (!this.props.withTime || (this.state.timeString == null || this.state.timeString == ''))){
            this.props.dateSelected(null);
        }
    }

    render() {
        let textFieldsContainer = {
            display: 'flex',
            flexDirection: 'row',
        };


        return (
            <div className='form-date-picker'>

                <div className='form-date-textfields-container' style={textFieldsContainer}>
                    <div ref={dateTextFieldNode => this.dateTextFieldNode = dateTextFieldNode} className="form-date-textfield">
                        <FormTextField onDateFieldKeyPress={this.onDateFieldKeyPress} onChange={this.dateStringChanged} placeholder={this.props.isMandatory ? 'Required' : 'Optional'} value={this.state.dateString} unitText={this.props.unitText} onFocus={this.dateTextFieldFocus} shouldDelayResponse={false} onBlur={this.dateTextFieldBlur} color={this.props.hasError ? FormsStyles.red : null}/>
                    </div>
                </div>

            </div>
        );
    }
}

FormDate.propTypes = {
    value: PropTypes.string,
    withTime: PropTypes.bool.isRequired,
    dateSelected: PropTypes.func.isRequired,
    unitText: PropTypes.string,
    isMandatory: PropTypes.bool.isRequired,
    hasError: PropTypes.bool.isRequired,
    toggleNowButton: PropTypes.func,
    toggleTodayButton: PropTypes.func,
    adoptedTodayString: PropTypes.bool,
    formattedTime: PropTypes.string,
    formattedDate: PropTypes.string
};