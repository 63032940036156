import { FormWithRedux } from '../../FormWithRedux';
import { IsDate, IsDateInPastError } from '../../../waferJS/Form To FormsUI/Subclasses/Validation Overrides/DateTimeValidation';
import { IsNumber, IsPositive } from '../../../waferJS/Form To FormsUI/Subclasses/Validation Overrides/DecimalNumberValidaton';
import { FormsUIGlossary } from '../../../waferJS/FormsUI/FormsUI';
import { EDChestPainKeyHistoricalInformationForm } from './EDChestPain-KeyHistoricalInformationForm';
import { EDChestPainKeyClinicalFeaturesForm } from './EDChestPain-KeyClinicalFeaturesForm';


export class EDChestPainClinicalAssessmentForm extends FormWithRedux {

    constructor(formLoader, parent, actionUpdater, dependentValuesUpdater, calculationsUpdater) {
        super('initialAssessment', formLoader, parent, actionUpdater, dependentValuesUpdater, calculationsUpdater);
    }

    generateSubform(identifier) {
        switch (identifier) {
            case 'keyHistoricalInformation':
                return new EDChestPainKeyHistoricalInformationForm(identifier, this.formLoader, this.parent, this.actionUpdater, this.dependentValuesUpdater, this.calculationsUpdater);
            case 'keyClinicalFeatures':
                return new EDChestPainKeyClinicalFeaturesForm(identifier, this.formLoader, this.parent, this.actionUpdater, this.dependentValuesUpdater, this.calculationsUpdater);
        }
    }

    shouldDisplayFormDetailForIdentifier(identifier) {
        super.shouldDisplayFormDetailForIdentifier(identifier);
        return true;
    }

    isEnabled(identifier) {
        if (identifier != null) {
            return true;
        }
        return false;
    }

    isMandatory(identifier) {
        if(identifier == 'keyHistoricalInformation' || identifier == 'keyClinicalFeatures'){
            return false;
        }
        return true;
    }

    getValueType(identifier) {
        if (identifier == 'firstDateTime') {
            return 'date';
        } else if (identifier == 'firstDuration') {
            return 'double';
        }
        return null;
    }

    submissionValidation(identifier) {
        if (identifier == 'firstDateTime') {
            return [IsDate, IsDateInPastError];
        } else if (identifier == 'firstDuration') {
            return [IsNumber, IsPositive];
        }
    }

    editingValidation(identifier) {
        if (identifier == 'firstDateTime') {
            return [];
        } else if (identifier == 'firstDuration') {
            return [IsNumber];
        }
    }

    detailString() {
        let formDetails = this.getFormDetails();

        var firstEpisodeValueDescriptions = [];
        var otherEpisodeValueDescriptions = [];
        var valueDescriptions = [];

        for (let formDetailIndex in formDetails) {
            let formDetail = formDetails[formDetailIndex];
            let description = formDetail.valueDescription();

            switch (formDetail.identifier) {
                case 'firstDateTime':
                    if (description != null && description != false) {
                        firstEpisodeValueDescriptions.push(description);
                    }
                    break;
                case 'firstDuration':
                    if (description != null && description != false) {
                        firstEpisodeValueDescriptions.push(description);
                    }
                    break;
                case 'keyHistoricalInformation':{
                    let subform = this.subform(formDetail.identifier);
                    let detail = subform.detailString(true);
                    if (detail != null && detail != ''){
                        valueDescriptions.push(FormsUIGlossary.titlePrefix + subform.title + '\n' + detail);
                    }
                    break;
                }
                case 'keyClinicalFeatures': {
                    let subform = this.subform(formDetail.identifier);
                    let detail = subform.detailString(true);
                    if (detail != null && detail != ''){
                        valueDescriptions.push(FormsUIGlossary.titlePrefix + subform.title + '\n' + detail);
                    }
                    break;
                }
                default:
                    if (description != null) {
                        valueDescriptions.push(description);
                    }
            }
        }

        if (!otherEpisodeValueDescriptions.length == 0) {
            let values = otherEpisodeValueDescriptions.join(', ');
            let finalOtherEpisodeDescription = ['Worst episode', values].join(': ');
            valueDescriptions.splice(0, 0, finalOtherEpisodeDescription);
        }

        if (!firstEpisodeValueDescriptions.length == 0) {
            let values = firstEpisodeValueDescriptions.join(', ');
            let finalFirstEpisodeDescription = ['First episode', values].join(': ');
            valueDescriptions.splice(0, 0, finalFirstEpisodeDescription);
        }

        valueDescriptions = valueDescriptions.filter((value) => { return value != '';});
        
        if (valueDescriptions.length == 0) {
            return this.detail;
        }

        else {
            let finalDetailString = valueDescriptions.join('\n');
            return finalDetailString;
        }
    }

}