import { FormWithRedux } from '../../FormWithRedux';

export class GPChestPainProbabilityOfMACEGraphForm extends FormWithRedux {

    constructor(formLoader) {
        super('probabilityOfMACEGraphForm', formLoader);
    }

    getGraphValue(){
        return this.getValue('probabilityOfMACE');
    }

    shouldDisplayFormDetailForIdentifier() {
        return true;
    }

    isEnabled() {
        return true;
    }

    isMandatory() {
        return false;
    }

    getValueType() {
        return null;
    }

    submissionValidation() {
        return [];
    }

    editingValidation() {
        return [];
    }

    detailString() {
        return '';
    }

}