import { FormValidation, EditingValidation } from '../../../waferJS/Form/Validation/FormValidation';
import { FormValidationIdentifier } from '../../../waferJS/Form/Validation/FormValidationIdentifier';
import { FormValidationError } from '../../../waferJS/Form/Validation/FormValidationError';
import { TroponinResult } from '../Custom Types/Troponin';

FormValidationIdentifier.IsTroponinResult = 'IsTroponinResult';
FormValidationIdentifier.IsTroponinString = 'IsTroponinString';

let IsTroponinResult = new FormValidation(FormValidationIdentifier.IsTroponinResult, function (value) {
    if (isNaN(+parseFloat(value))) {
        return [FormValidationError.RequiredError];
    }

    let troponin = TroponinResult.newTroponinResult(value.toString());
    if (troponin == null) {
        return [new FormValidationError(FormValidationError.FormErrorType.error, 'Invalid troponin value')];
    }

    return [];
});

let IsTroponinString = new EditingValidation(FormValidationIdentifier.IsTroponinString, function (value) {
    if (value == null || value == undefined || value.toString() == null) {
        return [FormValidationError.RequiredError];
    }

    let troponin = TroponinResult.newTroponinResult(value.toString());
    if (troponin == null) {
        return [new FormValidationError(FormValidationError.FormErrorType.error, 'Invalid troponin value')];
    }

    return [];
});

export { IsTroponinResult, IsTroponinString }; 