import { FormWithRedux } from '../../FormWithRedux';

export class GPChestPainNotesForm extends FormWithRedux {

    constructor(formLoader) {
        super('notesForm', formLoader);
    }

    shouldDisplayFormDetailForIdentifier(identifier) {
        super.shouldDisplayFormDetailForIdentifier(identifier);
        return true;
    }

    isEnabled(identifier) {
        if (identifier != null) {
            return true;
        }
        return false;
    }

    isMandatory() {
        return false;
    }

    getValueType() {
        return 'string';
    }

    submissionValidation() {
        return [];
    }

    detailString() {
        return 'summary';
    }

}