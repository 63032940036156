import { FormWithRedux } from '../../FormWithRedux';
import { FormsStyles } from '../../../waferJS/FormsUI/FormsUI';
import { TroponinResult } from '../Custom Types/Troponin';
import { FormValidation } from '../../../waferJS/Form/Validation/FormValidation';
import { FormValidationError } from '../../../waferJS/Form/Validation/FormValidationError';
import { redux } from '../../../App';
import { ActionModel } from '../../Shared Custom Types/ActionModel';
import { EDChestPainNotesForm } from './EDChestPain-NotesForm';
import { EDChestPainResultsForm } from './EDChestPain-ResultsForm';
import { EDChestPainHighRiskDifferentialDiagnosisForm } from './EDChestPain-HighRiskDifferentialDiagnosisForm';
import { EDChestPainVitalSignsForm } from './EDChestPain-VitalSignsForm';
import { EDChestPainECGForm } from './EDChestPain-ECGForm';
import { EDChestPainClinicalAssessmentForm } from './EDChestPain-ClinicalAssessmentForm';
import { EDChestPainPatientDetailsForm } from './EDChestPain-PatientDetailsForm';
import { EDChestPainBloodsForm } from './EDChestPain-BloodsForm';
import { EDChestPainIntroForm } from './EDChestPain-IntroForm';
import { EDChestPainActions } from '../Calculations/EDChestPain-Actions';
import { EDChestPainDependantValuesUpdater } from '../Calculations/EDChestPain-DependantValuesUpdater';
import { EDChestPainCalculations } from '../Calculations/EDChestPain-Calculations';

export class EDChestPainMainForm extends FormWithRedux {

    constructor(formLoader) {
        super('mainForm', formLoader, null, new EDChestPainActions(formLoader), new EDChestPainDependantValuesUpdater(formLoader), new EDChestPainCalculations());
    }

    generateSubform(identifier) {
        switch (identifier) {
            case 'introForm':
                return new EDChestPainIntroForm(this.formLoader);
            case 'notesForm':
                return new EDChestPainNotesForm(this.formLoader, this);
            case 'resultsForm':
                return new EDChestPainResultsForm(this.formLoader, this, this.actionUpdater, this.dependentValuesUpdater, this.calculationsUpdater);
            case 'highRiskDifferentialDiagnosis':
                return new EDChestPainHighRiskDifferentialDiagnosisForm(this.formLoader, this, this.actionUpdater, this.dependentValuesUpdater, this.calculationsUpdater);
            case 'vitalSigns':
                return new EDChestPainVitalSignsForm(this.formLoader, this, this.actionUpdater, this.dependentValuesUpdater, this.calculationsUpdater);
            case 'ecgTest':
                return new EDChestPainECGForm(this.formLoader, this, this.actionUpdater, this.dependentValuesUpdater, this.calculationsUpdater);
            case 'initialBloods':
                return new EDChestPainBloodsForm(this.formLoader, this, this.actionUpdater, this.dependentValuesUpdater, this.calculationsUpdater);
            case 'initialAssessment':
                return new EDChestPainClinicalAssessmentForm(this.formLoader, this, this.actionUpdater, this.dependentValuesUpdater, this.calculationsUpdater);
            case 'patientDetails':
                return new EDChestPainPatientDetailsForm(this.formLoader, this, this.actionUpdater, this.dependentValuesUpdater, this.calculationsUpdater);
        }
    }

    shouldDisplayFormDetailForIdentifier(identifier) {
        super.shouldDisplayFormDetailForIdentifier(identifier);
        return true;
    }

    isEnabled() {
        return false;
    }

    isMandatory() {
        return false;
    }

    getValueType() {
        return 'boolean';
    }

    editingValidation(identifier){
        if (identifier == 'initialBloods'){
            let self = this;
            let scheduledActionPassed = new FormValidation('FollowUpBloodsAction', function () {
                let pathwayActions = redux.store.getState().actionsReducer.actions[self.pathwayId];
                if(pathwayActions != null){
                    let action = pathwayActions.filter((action) => { return action.identifier == 'repeatTroponinAction';})[0];
                    if(action != null && action.scheduledAction != null){
                        let actionModel = new ActionModel(action.pathwayUuid, action.versionUuid, action.identifier, action.title, action.detail, action.priority, action.observed, action.removeAfterObservation, action.lastModifiedDate, action.uuid, action.scheduledAction);

                        let string = actionModel.scheduledActionDetailStringSimple(false);
                        if(string != null){
                            return [new FormValidationError(FormValidationError.FormErrorType.warning, string)];
                        }
                        return [];
                    }
                }
                return [];
            });
                
            return [scheduledActionPassed];
        }

        return [];
    }

    submissionValidation() {
        return [];
    }

    detailString() {
        return 'summary';
    }

    getBadgeValue(identifier) {
        let subform = this.subform(identifier);
        let completionProgress = subform.completionProgress();
        let subformCompletion = (completionProgress.current / completionProgress.total) == 1;

        switch (identifier) {
            case 'vitalSigns': {
                let vitalsProgress = subform.completionProgress();
                if(vitalsProgress.current / vitalsProgress.total == 1){
                    let totalEWS = subform.totalEWS();
                    if (totalEWS != null) {
                        return [{
                            color: totalEWS.color,
                            value: totalEWS.title,
                            solid: totalEWS.solid
                        }];
                    }
                }
                
                return [];
            }
            case 'ecgTest': {
                let ecgBadges = [];
                let stemiPresentValue = subform.formDetail('stemiPresent').currentValue;
                if (stemiPresentValue != null && stemiPresentValue && subformCompletion) {
                    ecgBadges.push({
                        color: FormsStyles.red,
                        value: 'STEMI',
                        solid: true
                    });
                }

                let abnormalitiesPresentValue = subform.formDetail('abnormalitiesPresent').currentValue;
                if (abnormalitiesPresentValue != null && abnormalitiesPresentValue && subformCompletion) {
                    ecgBadges.push({
                        color: FormsStyles.orange, //FormsStyles.red,
                        value: 'ABNORMAL',
                        solid: true
                    });
                }

                if (ecgBadges.length == 0 && subform.areAllCurrentValuesValid() && subformCompletion) {
                    ecgBadges.push({
                        color: FormsStyles.grey,
                        value: 'NORMAL',
                        solid: false
                    });
                }
                return ecgBadges;
            }
            case 'initialBloods': {
                let initialTroponinDateAndTime = this.getValue('initialBloodsDateAndTime');
                let initialTroponinResult = this.getValue('initialBloodsTroponin');
                let repeatTroponinResult = this.getValue('followUpBloodsTroponin');
                let repeatTroponinDateAndTime = this.getValue('followUpBloodsDateAndTime');

                let initialTroponinComplete = initialTroponinResult != null && initialTroponinResult != undefined && initialTroponinDateAndTime != null && initialTroponinDateAndTime != undefined;
                let repeatTroponinComplete = repeatTroponinResult != null && repeatTroponinResult != undefined && repeatTroponinDateAndTime != null && repeatTroponinDateAndTime != undefined;
                
                let initialTroponinStatus;
                let repeatTroponinStatus;
                if (initialTroponinComplete == true) {
                    let troponin = TroponinResult.newTroponinResult(initialTroponinResult);
                    initialTroponinStatus = troponin.getStatus();
                }
                if (repeatTroponinComplete == true) {
                    let troponin = TroponinResult.newTroponinResult(repeatTroponinResult);
                    repeatTroponinStatus = troponin.getStatus();
                }

                let badges = [];
                if (initialTroponinStatus == TroponinResult.Status.aboveCutoff || repeatTroponinStatus == TroponinResult.Status.aboveCutoff) {
                    //Display above cutoff
                    badges.push({
                        color: FormsStyles.red,
                        value: 'ABOVE CUT-OFF',
                        solid: true
                    });
                }
                if (initialTroponinComplete == true && repeatTroponinComplete == true && (Math.abs(initialTroponinResult - repeatTroponinResult) >= 4)) {
                    //Display delta high
                    badges.push({
                        color: FormsStyles.red,
                        value: 'DELTA HIGH',
                        solid: true
                    });
                } 
                if (badges.length > 0) {
                    return badges;
                } else {
                    if (initialTroponinStatus == TroponinResult.Status.belowCutoff || repeatTroponinStatus == TroponinResult.Status.belowCutoff) {
                        //Display below cutoff
                        return [{
                            color: FormsStyles.grey,
                            value: 'BELOW CUT-OFF',
                            solid: false
                        }];
                    } else if (initialTroponinComplete == true || repeatTroponinComplete == true) {
                        //Display normal
                        return [{
                            color: FormsStyles.grey,
                            value: 'NORMAL',
                            solid: false
                        }];
                    }
                }
                
                return [];
            }
        }
    }
}