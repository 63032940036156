export class GPChestPainPathwayReference {
    static data = {
        'title': 'GP Chest Pain: Suspected ACS',
        'itemType': 'template',
        'template.version': 1.0,
        'template.versionUuid': 'cardiacChestPain',
        'template.createText': 'Use This Pathway',
        'template.introID': 'introForm',
        'template.contentTabIDs': ['mainForm', 'resultsForm'],
        'template.defaultContentTabID': 'mainForm',
        'template.content': {
            'introForm': {
                'title': 'GP Chest Pain: Suspected ACS',
                'detail': 'For patients presenting to general practice where cardiac ischaemia is a concern and immediate inpatient investigation for another diagnosis is not required. The pathway will assist decisions and documentation for patients who may require acute transfer to hospital, may need a community troponin test, or are able to be sent home.\n\nWayfind will calculate EDACS. It is consistent with Community HealthPathways which contains further detail if needed.',
                'itemType': 'subform',
                'subform.sectionIDs': [
                ]
            },
            'additionalInformation': {
                'title': 'Aditional Information',
                'itemType': 'section',
                'section.childIDs': [
                    'pathwayInformationForm'
                ]
            },
            'pathwayInformationForm': {
                'title': 'Additional Information',
                'itemType': 'subform',
                'subform.sectionIDs': [
                    'pathwayInformationSection'
                ]
            },
            'pathwayInformationSection': {
                'title': '',
                'itemType': 'section',
                'section.childIDs': [
                    'pathwayObjectivesInfo',
                    'requiredInvestigationsInfo',
                    'calculatedInsightsInfo'
                ]
            },
            'pathwayObjectivesInfo': {
                'title': 'Pathway Objectives',
                'detail': 'This pathway is designed to simultaneously\n- Support your clinical decisions\n- Create a clinical record\n- Collect high quality research data',
                'itemType': 'control',
                'control.type': 'plain'
            },
            'requiredInvestigationsInfo': {
                'title': 'Required Investigations',
                'detail': 'Vital signs, ECG, Initial and Follow-up troponin',
                'itemType': 'control',
                'control.type': 'plain'
            },
            'calculatedInsightsInfo': {
                'title': 'Calculated Insights',
                'detail': 'EDACS and Risk Category', //, and Probability of MACE',
                'itemType': 'control',
                'control.type': 'plain'
            },
            'eligibilityForm': {
                'title': 'Eligibility criteria',
                'itemType': 'subform',
                'subform.sectionIDs': [
                    'eligibilitySection'
                ]
            },
            'eligibilitySection': {
                'title': 'Eligibility section',
                'itemType': 'section',
                'section.childIDs': [
                    'eligibilityReason'
                ]
            },
            'eligibilityReason': {
                'title': 'Eligibility reason',
                'itemType': 'control',
                'control.type': 'textEntry',
                'textEntry.type': 'wrappingPlainText'
            },
            'mainForm': {
                'title': 'Pathway',
                'itemType': 'subform',
                'subform.sectionIDs': [
                    'patientDetailsSection',
                    'highRiskDifferentialDiagnosisMainSection',
                    'clinicalAssessment',
                    'investigations',
                ]
            },
            'patientDetailsSection': {
                'title': 'Patient details',
                'itemType': 'section',
                'section.childIDs': [
                    'patientDetails'
                ]
            },
            'patientDetails': {
                'title': 'Patient details',
                'itemType': 'subform',
                'subform.sectionIDs': [
                    'detailsSection'
                ]
            },
            'detailsSection': {
                'section.childIDs': [
                    'patientAge',
                    'patientSex'
                ]
            },
            'patientAge': {
                'title': 'Age',
                'itemType': 'control',
                'control.type': 'textEntry',
                'textEntry.type': 'decimalNumber',
                'textEntry.unitText': 'years',
                'subcellIDs':[
                    'patientDateOfBirth'
                ]
            },
            'patientDateOfBirth':{
                'title': 'Date of birth',
                'detail': 'Date of birth can be used to calculate age.',
                'itemType': 'control',
                'control.type': 'picker',
                'picker.type': 'dateOfBirth',
            },
            'patientSex': {
                'title': 'Sex',
                'itemType': 'control',
                'control.type': 'selection',
                'selection.style': 'simple',
                'selection.options': [
                    'genderMaleOption',
                    'genderFemaleOption',
                    'genderOtherOption'
                ],
                'subcellIDs': [
                    'patientSexIssue'
                ]
            },
            'genderMaleOption': {
                'title': 'Male',
                'itemType': 'option'
            },
            'genderFemaleOption': {
                'title': 'Female',
                'itemType': 'option'
            },
            'genderOtherOption': {
                'title': 'Other',
                'itemType': 'option'
            },
            'patientSexIssue': {
                'title': 'Cardiac Risk is determined by several factors including biological sex. Please seek specialist advice to ensure this pathway is appropriate',
                'itemType': 'control',
                'control.type': 'plain'
            },
            'highRiskDifferentialDiagnosisMainSection': {
                'title': 'Clinical context',
                'itemType': 'section',
                'section.childIDs': [
                    'highRiskDifferentialDiagnosis'
                ]
            },
            'highRiskDifferentialDiagnosis': {
                'title': 'Clinical question',
                'itemType': 'subform',
                'subform.sectionIDs': [
                    'clinicalSuspicionSection',
                    'highRiskDifferentialDiagnosisSection',
                    'adviceSection'
                ]
            },
            'clinicalSuspicionSection':{
                'itemType': 'section',
                'section.childIDs': [
                    'clinicalSuspicionOfAcuteCoronorySyndrome'
                ]
            },
            'highRiskDifferentialDiagnosisSection': {
                'title': 'High-risk differential diagnoses',
                'detail': 'Are you actively considering any of the following:',
                'section.childIDs': [
                    'aorticDissectionPresent',
                    'pulmonaryEmbolism',
                    'pancreatitis',
                    'pericarditis',
                    'pneumothorax',
                    'noToAllHighRiskDifferentialDiagnosis',
                   
                ]
            },
            'noToAllHighRiskDifferentialDiagnosis': {
                'itemType': 'control',
                'control.type': 'noToAll'
            },
            'suspicionHighHelpMessage': {
                'title': 'Review the management, treatment and investigation recommendations in the Outcome section before continuing the pathway.',
                'itemType': 'control',
                'control.type': 'plain'
            },
            'suspicionUnsureHelpMessage': {
                'title': 'Please continue and complete all sections of the pathway to generate investigation, treatment and management recommendations, and to write a comprehensive clinical note.',
                'itemType': 'control',
                'control.type': 'plain'
            },
            'adviceSection': {
                'title': 'Advice',
                'itemType': 'section',
                'section.childIDs': [
                    'suspicionHighHelpMessage',
                    'suspicionUnsureHelpMessage',
                    'suspicionNoneHelpMessage'
                ]
            },
            'suspicionNoneHelpMessage': {
                'title': 'If there is no initial suspicion of ACS, recommendations will not be provided unless clinical alerts, red flags or high risk differential diagnoses are present. Please continue the pathway to write a comprehensive clinical note.',
                'itemType': 'control',
                'control.type': 'plain'
            },
            'clinicalSuspicionOfAcuteCoronorySyndrome':{
                'title': 'What is your initial suspicion of Acute coronary syndrome (ACS)?',
                'itemType': 'control',
                'control.type': 'selection',
                'selection.style': 'simple',
                'selection.options': [
                    'highOption',
                    'lowOption',
                    'noneOption'
                ]
            },
            'highOption': {
                'title': 'High',
                'itemType': 'option'
            },
            'lowOption': {
                'title': 'Unsure',
                'itemType': 'option'
            },
            'noneOption': {
                'title': 'None',
                'itemType': 'option'
            },
            'clinicalAssessment': {
                'title': 'Clinical assessment',
                'itemType': 'section',
                'section.childIDs': [
                    'initialAssessment'
                ]
            },
            'initialAssessment': {
                'title': 'Initial assessment',
                'detail': 'Timing of pain, Key clinical features & Key historical information',
                'itemType': 'subform',
                'subform.sectionIDs': [
                    'firstEpisodeSection',
                    'worstEpisodeSection',
                    'keyAssessmentInformation'
                ]
            },
            'firstEpisodeSection': {
                'title': 'First episode of chest pain',
                'itemType': 'section',
                'section.childIDs': [
                    'firstDateTime',
                    'firstDuration'
                ]
            },
            'firstDateTime': {
                'title': 'Start date and time',
                'itemType': 'control',
                'control.type': 'picker',
                'picker.type': 'dateTime',
                'validity.function': 'firstDateValidity'
            },
            'firstDuration': {
                'title': 'Duration',
                'itemType': 'control',
                'control.type': 'textEntry',
                'textEntry.type': 'decimalNumber',
                'textEntry.unitText': 'mins'
            },
            'worstEpisodeSection': {
                'title': 'Worst episode of chest pain',
                'itemType': 'section',
                'section.childIDs': [
                    'painWorstAt',
                    'worstDateTime',
                    'worstDuration'
                ]
            },
            'painWorstAt': {
                'title': 'When was the worst episode of chest pain?',
                'itemType': 'control',
                'control.type': 'selection',
                'selection.style': 'simple',
                'selection.options': [
                    'firstEpisodeOption',
                    'otherEpisodeOption'
                ]
            },
            'firstEpisodeOption': {
                'title': 'First episode',
                'itemType': 'option'
            },
            'otherEpisodeOption': {
                'title': 'Other episode',
                'itemType': 'option'
            },
            'worstDateTime': {
                'title': 'Start date and time',
                'itemType': 'control',
                'control.type': 'picker',
                'picker.type': 'dateTime',
                'visibility.function': 'otherEpisodeVisibility',
                'validity.function': 'afterFirstDateValidity'
            },
            'worstDuration': {
                'title': 'Duration',
                'itemType': 'control',
                'control.type': 'textEntry',
                'textEntry.type': 'decimalNumber',
                'textEntry.unitText': 'mins',
                'visibility.function': 'otherEpisodeVisibility'
            },
            'keyAssessmentInformation': {
                'title': 'Key assessment information',
                'itemType': 'section',
                'section.childIDs': [
                    'keyClinicalFeatures',
                    'keyHistoricalInformation'
                ]
            },
            'keyClinicalFeatures': {
                'title': 'Key clinical features',
                'itemType': 'subform',
                'subform.sectionIDs': [
                    'symptomsAndSigns'
                ]
            },
            'symptomsAndSigns': {
                'title': 'Symptoms and signs',
                'itemType': 'section',
                'section.childIDs': [
                    'diaphoresis',
                    'painRadiates',
                    'pleuriticPain',
                    'painByPalpation',
                    'crescendoPain',
                    'ongoingChestPain',
                    'noToAllSymptomsAndSigns'
                ]
            },
            'noToAllSymptomsAndSigns': {
                'itemType': 'control',
                'control.type': 'noToAll'
            },
            'diaphoresis': {
                'title': 'Diaphoresis/sweating',
                'detail': 'In association with this pain episode',
                'itemType': 'control',
                'control.type': 'selection',
                'selection.style': 'explicit'
            },
            'painRadiates': {
                'title': 'Pain radiates to arm, shoulder, neck or jaw',
                'itemType': 'control',
                'control.type': 'selection',
                'selection.style': 'explicit'
            },
            'pleuriticPain': {
                'title': 'Pleuritic pain',
                'detail': 'Sudden sharp increase in pain while breathing in localised to a particular area of the chest wall',
                'itemType': 'control',
                'control.type': 'selection',
                'selection.style': 'explicit'
            },
            'painByPalpation': {
                'title': 'Pain reproduced by palpation',
                'itemType': 'control',
                'control.type': 'selection',
                'selection.style': 'explicit'
            },
            'crescendoPain': {
                'title': 'Crescendo pain',
                'detail': '>2 episodes in 24h or any escalating frequency of pain',
                'itemType': 'control',
                'control.type': 'selection',
                'selection.style': 'explicit'
            },
            'ongoingChestPain': {
                'title': 'Ongoing chest pain',
                'detail': 'Pain continues at rest or minimal exertion',
                'itemType': 'control',
                'control.type': 'selection',
                'selection.style': 'explicit'
            },
            'keyHistoricalInformation': {
                'title': 'Key historical information',
                'detail': 'Problems, past medical history, drug history, family history and social history',
                'itemType': 'subform',
                'subform.sectionIDs': [
                    'problemsAndPast',
                    'familyHistory',
                    'medications',
                ]
            },
            'problemsAndPast': {
                'title': 'Problems and past medical history',
                'itemType': 'section',
                'section.childIDs': [
                    'hypertension',
                    'dyslipidaemia',
                    'diabetes',
                    'currentSmoker',
                    'obesity',
                    'coronaryHeartDisease',
                    'coronaryArteryStenosis',
                    'noToAllProblemsAndPast'
                ]
            },
            'noToAllProblemsAndPast': {
                'itemType': 'control',
                'control.type': 'noToAll'
            },
            'hypertension': {
                'title': 'Hypertension',
                'itemType': 'control',
                'control.type': 'selection',
                'selection.style': 'explicit'
            },
            'dyslipidaemia': {
                'title': 'Dyslipidaemia',
                'itemType': 'control',
                'control.type': 'selection',
                'selection.style': 'explicit'
            },
            'diabetes': {
                'title': 'Diabetes',
                'itemType': 'control',
                'control.type': 'selection',
                'selection.style': 'explicit'
            },
            'currentSmoker': {
                'title': 'Current smoker',
                'itemType': 'control',
                'control.type': 'selection',
                'selection.style': 'explicit'
            },
            'obesity': {
                'title': 'Obesity',
                'itemType': 'control',
                'control.type': 'selection',
                'selection.style': 'explicit'
            },
            'coronaryHeartDisease': {
                'title': 'Coronary heart disease',
                'detail': 'Previous myocardial infarction, CABG or PCI',
                'itemType': 'control',
                'control.type': 'selection',
                'selection.style': 'explicit'
            },
            'coronaryArteryStenosis': {
                'title': 'Coronary artery stenosis',
                'detail': 'Documented >50% stenosis',
                'itemType': 'control',
                'control.type': 'selection',
                'selection.style': 'explicit'
            },
            'familyHistory': {
                'title': 'Family history',
                'itemType': 'section',
                'section.childIDs': [
                    'prematureCoronaryHeartDisease'
                ]
            },
            'prematureCoronaryHeartDisease': {
                'title': 'FH: Premature coronary heart disease',
                'detail': 'First degree relatives with disease in men <55yrs or women <65yrs',
                'itemType': 'control',
                'control.type': 'selection',
                'selection.style': 'explicit'
            },
            'medications': {
                'title': 'Medications',
                'itemType': 'section',
                'section.childIDs': [
                    'aspirin',
                    'chemotherapy',
                    'noToAllMedications'
                ]
            },
            'noToAllMedications': {
                'title': 'medications',
                'itemType': 'control',
                'control.type': 'noToAll'
            },
            'aspirin': {
                'title': 'Aspirin',
                'detail': 'Regular aspirin use within last 7 days',
                'itemType': 'control',
                'control.type': 'selection',
                'selection.style': 'explicit'
            },
            'chemotherapy': {
                'title': 'Chemotherapy',
                'detail': 'Fluorouracil 5FU or capecitabine',
                'itemType': 'control',
                'control.type': 'selection',
                'selection.style': 'explicit'
            },
            'investigations': {
                'title': 'Investigations',
                'itemType': 'section',
                'section.childIDs': [
                    'vitalSigns',
                    'ecgTest',
                    'initialBloods'
                ]
            },
            'vitalSigns': {
                'title': 'Vital signs',
                'itemType': 'subform',
                'subform.sectionIDs': [
                    'recordVitalsSection'
                ]
            },
            'recordVitalsSection': {
                'title': 'Record vital signs',
                'itemType': 'section',
                'section.childIDs': [
                    'heartRate',
                    'bloodPressure',
                    'bodyTemperature',
                    'oxygenSaturation',
                    'respiratoryRate',
                    'supplementalOxygen',
                    'levelOfConsciousness'
                ]
            },
            'heartRate': {
                'title': 'Heart rate',
                'itemType': 'control',
                'control.type': 'textEntry',
                'textEntry.type': 'decimalNumber',
                'textEntry.unitText': 'bpm',
                'subcellIDs': [
                    'heartRateEWS'
                ]
            },
            'heartRateEWS': {
                'title': 'Early Warning Score',
                'itemType': 'control',
                'control.type': 'badge'
            },
            'bloodPressure': {
                'title': 'Blood pressure',
                'itemType': 'control',
                'control.type': 'picker',
                'picker.type': 'bloodPressure',
                'textEntry.unitText': 'mmHg',
                'subcellIDs': [
                    'bloodPressureEWS'
                ]
            },
            'bloodPressureEWS': {
                'title': 'Early Warning Score',
                'itemType': 'control',
                'control.type': 'badge'
            },
            'bodyTemperature': {
                'title': 'Body temperature',
                'itemType': 'control',
                'control.type': 'textEntry',
                'textEntry.type': 'decimalNumber',
                'textEntry.unitText': '℃',
                'subcellIDs': [
                    'bodyTemperatureEWS'
                ]
            },
            'bodyTemperatureEWS': {
                'title': 'Early Warning Score',
                'itemType': 'control',
                'control.type': 'badge'
            },
            'oxygenSaturation': {
                'title': 'Oxygen saturation (SpO₂)',
                'itemType': 'control',
                'control.type': 'textEntry',
                'textEntry.type': 'decimalNumber',
                'textEntry.unitText': '%',
                'subcellIDs': [
                    'oxygenSaturationEWS'
                ]
            },
            'oxygenSaturationEWS': {
                'title': 'Early Warning Score',
                'itemType': 'control',
                'control.type': 'badge'
            },
            'respiratoryRate': {
                'title': 'Respiratory rate',
                'itemType': 'control',
                'control.type': 'textEntry',
                'textEntry.type': 'decimalNumber',
                'textEntry.unitText': 'bpm',
                'subcellIDs': [
                    'respiratoryRateEWS'
                ]
            },
            'respiratoryRateEWS': {
                'title': 'Early Warning Score',
                'itemType': 'control',
                'control.type': 'badge'
            },
            'supplementalOxygen': {
                'title': 'Supplemental oxygen',
                'itemType': 'control',
                'control.type': 'selection',
                'selection.style': 'explicit',
                'subcellIDs': [
                    'supplementalOxygenEWS'
                ]
            },
            'supplementalOxygenEWS': {
                'title': 'Early Warning Score',
                'itemType': 'control',
                'control.type': 'badge'
            },
            'levelOfConsciousness': {
                'title': 'Level of consciousness',
                'itemType': 'control',
                'control.type': 'selection',
                'selection.style': 'simple',
                'selection.options': [
                    'responsivenessAlert',
                    'responsivenessVoiceOrPain',
                    'responsivenessUnresponsive'
                ],
                'subcellIDs': [
                    'levelOfConsciousnessEWS'
                ]
            },
            'levelOfConsciousnessEWS': {
                'title': 'Early Warning Score',
                'itemType': 'control',
                'control.type': 'badge'
            },
            'responsivenessAlert': {
                'title': 'Alert',
                'itemType': 'option'
            },
            'responsivenessVoiceOrPain': {
                'title': 'Voice or pain',
                'itemType': 'option'
            },
            'responsivenessUnresponsive': {
                'title': 'Unresponsive',
                'itemType': 'option'
            },
            'ecgTest': {
                'title': 'ECG',
                'itemType': 'subform',
                'subform.sectionIDs': [
                    'ecgRequirementSection',
                    'stemiSection',
                    'abnormalitiesSection'
                ]
            },
            'ecgRequirementSection': {
                'itemType': 'section',
                'section.childIDs': [
                    'ecgRequired',
                    'ecgOptional'
                ]
            },
            'ecgRequired': {
                'title': 'ECG is indicated',
                'detail': 'No repeat ECG is needed unless the patient status changes',
                'itemType': 'control',
                'control.type': 'plain'
            },
            'ecgOptional': {
                'title': 'ECG is optional',
                'itemType': 'control',
                'control.type': 'plain'
            },
            'stemiSection': {
                'title': 'STEMI',
                'itemType': 'section',
                'section.childIDs': [
                    'stemiPresent'
                ]
            },
            'stemiPresent': {
                'title': 'Is this ST elevation myocardial infarction (STEMI)?',
                'itemType': 'control',
                'control.type': 'selection',
                'selection.style': 'explicit',
                'subcellIDs': [
                    'stemiCriteria'
                ]
            },
            'stemiCriteria': {
                'title': 'STEMI criteria',
                'itemType': 'subform',
                'subform.sectionIDs': [
                    'stemiCriteriaSection'
                ],
                'visibility.function': 'stemiCriteriaVisibility',
                'mandatory.function': 'stemiCriteriaMandatory'
            },
            'stemiCriteriaSection': {
                'itemType': 'section',
                'section.childIDs': [
                    'stemiCriteriaV4toV6',
                    'stemiCriteriaV1toV3',
                    'stemiCriteriaV7toV9',
                    'stemiCriteriaNewLBBB',
                    'stemiCriteriaLBBBWithPositiveQRS',
                    'stemiCriteriaLBBBWithNegativeQRS',
                    'noToAllStemiCriteria'
                ]
            },
            'noToAllStemiCriteria': {
                'itemType': 'control',
                'control.type': 'noToAll'
            },
            'stemiCriteriaV4toV6': {
                'title': 'Contiguous ST elevation >1mm in leads V4 to V6 or limb leads',
                'itemType': 'control',
                'control.type': 'selection',
                'selection.style': 'explicit'
            },
            'stemiCriteriaV1toV3': {
                'title': 'Contiguous ST elevation >2mm in leads V1 to V3',
                'itemType': 'control',
                'control.type': 'selection',
                'selection.style': 'explicit'
            },
            'stemiCriteriaV7toV9': {
                'title': 'Contiguous ST elevation >0.5mm in posterior leads V7 to V9',
                'itemType': 'control',
                'control.type': 'selection',
                'selection.style': 'explicit'
            },
            'stemiCriteriaNewLBBB': {
                'title': 'Left bundle branch block (LBBB) that is known to be new, with typical ischaemic symptoms',
                'itemType': 'control',
                'control.type': 'selection',
                'selection.style': 'explicit'
            },
            'stemiCriteriaLBBBWithPositiveQRS': {
                'title': 'LBBB or paced rhythm with ST elevation >1 mm in leads with a QRS complex that is mainly positive',
                'itemType': 'control',
                'control.type': 'selection',
                'selection.style': 'explicit'
            },
            'stemiCriteriaLBBBWithNegativeQRS': {
                'title': 'LBBB or paced rhythm with ST elevation >5 mm in leads with a QRS complex that is mainly negative',
                'itemType': 'control',
                'control.type': 'selection',
                'selection.style': 'explicit'
            },
            'abnormalitiesSection': {
                'title': 'Other abnormalities',
                'itemType': 'section',
                'section.childIDs': [
                    'abnormalitiesPresent'
                ]
            },
            'abnormalitiesPresent': {
                'title': 'Are there any other abnormalities present?',
                'itemType': 'control',
                'control.type': 'selection',
                'selection.style': 'explicit',
                'subcellIDs': [
                    'abnormalitiesCriteria'
                ]
            },
            'abnormalitiesCriteria': {
                'title': 'Other abnormalities',
                'itemType': 'subform',
                'subform.sectionIDs': [
                    'abnormalitiesCriteriaSection'
                ],
                'visibility.function': 'abnormalitiesCriteriaVisibility',
                'mandatory.function': 'abnormalitiesCriteriaMandatory'
            },
            'abnormalitiesCriteriaSection': {
                'itemType': 'section',
                'section.childIDs': [
                    'newIschaemia',
                    'oldIschaemia',
                    'oldLBBB',
                    'nonSpecificSTorTChanges',
                    'alternativeFinding',
                    'noToAllOtherAbnormalities'
                ]
            },
            'noToAllOtherAbnormalities': {
                'itemType': 'control',
                'control.type': 'noToAll'
            },
            'newIschaemia': {
                'title': 'New ischaemia',
                'detail': 'ST depression >0.5mm in contiguous leads, T wave inversion >1mm, or Q-waves >30ms. Highest risk is widespread ST depression, deep T wave inversion or dynamic changes over time',
                'itemType': 'control',
                'control.type': 'selection',
                'selection.style': 'explicit'
            },
            'oldIschaemia': {
                'title': 'Old ischaemia',
                'detail': 'As above',
                'itemType': 'control',
                'control.type': 'selection',
                'selection.style': 'explicit'
            },
            'oldLBBB': {
                'title': 'Old Left bundle branch block (LBBB)',
                'detail': 'LBBB that does not fulfil STEMI criteria',
                'itemType': 'control',
                'control.type': 'selection',
                'selection.style': 'explicit'
            },
            'nonSpecificSTorTChanges': {
                'title': 'Non-specific ST or T wave changes',
                'detail': 'Deviation from normal with very low likelihood of ischaemia (eg. Inverted T wave in III or V1)',
                'itemType': 'control',
                'control.type': 'selection',
                'selection.style': 'explicit'
            },
            'alternativeFinding': {
                'title': 'An alternative finding',
                'detail': 'Abnormalities not diagnostic of ischaemia (eg. conduction abnormality, AF, LVH)',
                'itemType': 'control',
                'control.type': 'selection',
                'selection.style': 'explicit',
                'subcellIDs': [
                    'alternativeFindingSpecify'
                ]
            },
            'alternativeFindingSpecify': {
                'title': 'Specify',
                'itemType': 'control',
                'control.type': 'textEntry',
                'textEntry.type': 'wrappingPlainText',
                'visibility.function': 'alternativeFindingSpecifyVisibility'
            },
            'initialBloods': {
                'title': 'Bloods',
                'itemType': 'subform',
                'subform.sectionIDs': [
                    'bloodsDetailsInformationSection',
                    'initialBloodsDetailsSection',
                    'followUpBloodsDetailsSection'
                ]
            },
            'bloodsDetailsInformationSection':{
                'itemType': 'section',
                'section.childIDs': [
                    'acuteCardiologyIndicatedBloods',
                    'acsManagementNotIndicatedBloods',
                    'initialTimingNotValidBloods',
                    'initialBloodsCompleteFollowTimingNotValid'
                ]
            },
            'acuteCardiologyIndicatedBloods':{
                'title': 'No additional troponin testing is needed for patients who already require acute cardiology assessment.',
                'itemType': 'control',
                'control.type': 'plain'
            },
            'acsManagementNotIndicatedBloods':{
                'title': 'Only arrange troponin testing in the community for patients in whom cardiac ischaemia is a concern, and inpatient management of another diagnosis is not required.',
                'itemType': 'control',
                'control.type': 'plain'
            },
            'initialTimingNotValidBloods':{
                'title': 'Only arrange troponin testing in the community if the patient presents within 72 hours after a suspicious episode of chest pain.',
                'itemType': 'control',
                'control.type': 'plain'
            },
            'initialBloodsCompleteFollowTimingNotValid':{
                'title': 'Follow up Troponin is not needed if patient presented >10 hours after worst pain',
                'itemType': 'control',
                'control.type': 'plain'
            },
            'initialBloodsDetailsSection': {
                'title': 'Troponin I - Initial',
                'itemType': 'section',
                'section.childIDs': [
                    'initialBloodsDateAndTime',
                    'initialBloodsTroponin'
                ]
            },
            'initialBloodsDateAndTime': {
                'title': 'Date and time',
                'itemType': 'control',
                'control.type': 'picker',
                'picker.type': 'dateTime'
            },
            'initialBloodsTroponin': {
                'title': 'Troponin result',
                'itemType': 'control',
                'control.type': 'textEntry',
                'textEntry.type': 'troponin',
                'textEntry.unitText': 'ng/L'
            },
            'followUpBloodsDetailsSection': {
                'title': 'Troponin I - Follow-up',
                'itemType': 'section',
                'section.childIDs': [
                    'followUpBloodsDateAndTime',
                    'followUpBloodsTroponin',
                ]
            },
            'followUpBloodsDateAndTime': {
                'title': 'Date and time',
                'itemType': 'control',
                'control.type': 'picker',
                'picker.type': 'dateTime'
            },
            'followUpBloodsTroponin': {
                'title': 'Troponin result',
                'itemType': 'control',
                'control.type': 'textEntry',
                'textEntry.type': 'troponin',
                'textEntry.unitText': 'ng/L'
            },
            'redFlagsSection': {
                'title': 'Alerts',
                'itemType': 'section',
                'section.childIDs': [
                    'redFlags',
                    'highRiskDifferentialDiagnosisResults',
                    'stemiAction',
                    'abnormalVitalSigns', 
                    'clinicalEmergency', 
                    'clinicalInstability', 
                    'clinicalEmergencyDeterioration'
                ]
            },
            'redFlags': {
                'title': 'Red flags',
                'detail': 'Findings requiring urgent assessment and management',
                'itemType': 'subform',
                'subform.sectionIDs': [
                    'highRiskInvestigationRedFlags',
                    'highRiskClinicalFeaturesRedFlags'
                ]
            },
            'aorticDissectionPresent': {
                'title': 'Aortic dissection',
                'itemType': 'control',
                'control.type': 'selection',
                'selection.style': 'explicit'
            },
            'aorticDissectionPresentRedFlag': {
                'title': 'Aortic dissection',
                'itemType': 'control',
                'control.type': 'redflag',
                'subcellIDs': [
                    'aorticDissectionPresentRedFlagOverride',
                    'aorticDissectionPresentRedFlagOverrideReason'
                ]
            },
            'aorticDissectionPresentRedFlagOverride': {
                'title': 'Resolve red flag',
                'detail': 'This will affect how this red flag is considered for calculations and actions',
                'itemType': 'control',
                'control.type': 'radio',
            },
            'aorticDissectionPresentRedFlagOverrideValue': {
                'title': 'New value',
                'itemType': 'control',
                'control.type': 'badge'
            },
            'aorticDissectionPresentRedFlagOverrideReason': {
                'title': 'Override reason',
                'itemType': 'control',
                'control.type': 'textEntry',
                'textEntry.type': 'wrappingPlainText'
            },
            'pulmonaryEmbolism': {
                'title': 'Pulmonary embolism',
                'itemType': 'control',
                'control.type': 'selection',
                'selection.style': 'explicit'
            },
            'pulmonaryEmbolismRedFlag': {
                'title': 'Pulmonary embolism',
                'itemType': 'control',
                'control.type': 'redflag',
                'subcellIDs': [
                    'pulmonaryEmbolismRedFlagOverride',
                    'pulmonaryEmbolismRedFlagOverrideReason'
                ]
            },
            'pulmonaryEmbolismRedFlagOverride': {
                'title': 'Resolve red flag',
                'detail': 'This will affect how this red flag is considered for calculations and actions',
                'itemType': 'control',
                'control.type': 'radio',
            },
            'pulmonaryEmbolismRedFlagOverrideReason': {
                'title': 'Override reason',
                'itemType': 'control',
                'control.type': 'textEntry',
                'textEntry.type': 'wrappingPlainText'
            },
            'pancreatitis': {
                'title': 'Pancreatitis',
                'itemType': 'control',
                'control.type': 'selection',
                'selection.style': 'explicit'
            },
            'pancreatitisRedFlag': {
                'title': 'Pancreatitis',
                'itemType': 'control',
                'control.type': 'redflag',
                'subcellIDs': [
                    'pancreatitisRedFlagOverride',
                    'pancreatitisRedFlagOverrideReason'
                ]
            },
            'pancreatitisRedFlagOverride': {
                'title': 'Resolve red flag',
                'detail': 'This will affect how this red flag is considered for calculations and actions',
                'itemType': 'control',
                'control.type': 'radio',
            },
            'pancreatitisRedFlagOverrideReason': {
                'title': 'Override reason',
                'itemType': 'control',
                'control.type': 'textEntry',
                'textEntry.type': 'wrappingPlainText'
            },
            'pericarditis': {
                'title': 'Pericarditis',
                'itemType': 'control',
                'control.type': 'selection',
                'selection.style': 'explicit'
            },
            'pericarditisRedFlag': {
                'title': 'Pericarditis',
                'itemType': 'control',
                'control.type': 'redflag',
                'subcellIDs': [
                    'pericarditisRedFlagOverride',
                    'pericarditisRedFlagOverrideReason'
                ]
            },
            'pericarditisRedFlagOverride': {
                'title': 'Resolve red flag',
                'detail': 'This will affect how this red flag is considered for calculations and actions',
                'itemType': 'control',
                'control.type': 'radio',
            },
            'pericarditisRedFlagOverrideReason': {
                'title': 'Override reason',
                'itemType': 'control',
                'control.type': 'textEntry',
                'textEntry.type': 'wrappingPlainText'
            },
            'pneumothorax': {
                'title': 'Pneumothorax',
                'itemType': 'control',
                'control.type': 'selection',
                'selection.style': 'explicit'
            },
            'pneumothoraxRedFlag': {
                'title': 'Pneumothorax',
                'itemType': 'control',
                'control.type': 'redflag',
                'subcellIDs': [
                    'pneumothoraxRedFlagOverride',
                    'pneumothoraxRedFlagOverrideReason'
                ]
            },
            'pneumothoraxRedFlagOverride': {
                'title': 'Resolve red flag',
                'detail': 'This will affect how this red flag is considered for calculations and actions',
                'itemType': 'control',
                'control.type': 'radio',
            },
            'pneumothoraxRedFlagOverrideReason': {
                'title': 'Override reason',
                'itemType': 'control',
                'control.type': 'textEntry',
                'textEntry.type': 'wrappingPlainText'
            },
            'cardiotoxicityRedFlag': {
                'title': 'Cardiotoxicity',
                'detail': 'Chemotherapy with Fluorouracil (5FU) or Capecitabine may cause cardiotoxicity',
                'itemType': 'control',
                'control.type': 'redflag',
                'subcellIDs': [
                    'cardiotoxicityRedFlagOverride',
                    'cardiotoxicityRedFlagOverrideReason'
                ]
            },
            'cardiotoxicityRedFlagOverride': {
                'title': 'Resolve red flag',
                'detail': 'This will affect how this red flag is considered for calculations and actions',
                'itemType': 'control',
                'control.type': 'radio',
            },
            'cardiotoxicityRedFlagOverrideReason': {
                'title': 'Override reason',
                'itemType': 'control',
                'control.type': 'textEntry',
                'textEntry.type': 'wrappingPlainText'
            },
            'highRiskInvestigationRedFlags': {
                'title': 'High-risk investigation',
                'itemType': 'section',
                'section.childIDs': [
                    'troponinAboveCutOff',
                    'newIschaemicChanges'
                ]
            },
            'troponinAboveCutOff': {
                'title': 'Troponin above cut-off',
                'detail': 'Men: ≥34 ng/L\nWomen: ≥16 ng/L',
                'itemType': 'control',
                'control.type': 'redflag',
                'subcellIDs': [
                    'troponinAboveCutOffOverride',
                    'troponinAboveCutOffOverrideReason'
                ]
            },
            'troponinAboveCutOffOverride': {
                'title': 'Resolve red flag',
                'detail': 'This will affect how this red flag is considered for calculations and actions',
                'itemType': 'control',
                'control.type': 'radio',
            },
            'troponinAboveCutOffOverrideReason': {
                'title': 'Override reason',
                'itemType': 'control',
                'control.type': 'textEntry',
                'textEntry.type': 'wrappingPlainText'
            },
            'newIschaemicChanges': {
                'title': 'New ischaemic changes',
                'itemType': 'control',
                'control.type': 'redflag',
                'subcellIDs': [
                    'newIschaemicChangesOverride',
                    'newIschaemicChangesOverrideReason'
                ]
            },
            'newIschaemicChangesOverride': {
                'title': 'Resolve red flag',
                'detail': 'This will affect how this red flag is considered for calculations and actions',
                'itemType': 'control',
                'control.type': 'radio',
            },
            'newIschaemicChangesOverrideReason': {
                'title': 'Override reason',
                'itemType': 'control',
                'control.type': 'textEntry',
                'textEntry.type': 'wrappingPlainText'
            },
            'highRiskClinicalFeaturesRedFlags': {
                'title': 'High-risk clinical features',
                'itemType': 'section',
                'section.childIDs': [
                    'redFlagsOngoingChestPain',
                    'crescendoAngina',
                    'haemodynamicInstability'
                ]
            },
            'redFlagsOngoingChestPain': {
                'title': 'Ongoing chest pain',
                'itemType': 'control',
                'control.type': 'redflag',
                'subcellIDs': [
                    'redFlagsOngoingChestPainOverride',
                    'redFlagsOngoingChestPainOverrideReason'
                ]
            },
            'redFlagsOngoingChestPainOverride': {
                'title': 'Resolve red flag',
                'detail': 'This will affect how this red flag is considered for calculations and actions',
                'itemType': 'control',
                'control.type': 'radio',
            },
            'redFlagsOngoingChestPainOverrideReason': {
                'title': 'Override reason',
                'itemType': 'control',
                'control.type': 'textEntry',
                'textEntry.type': 'wrappingPlainText'
            },
            'crescendoAngina': {
                'title': 'Crescendo angina',
                'itemType': 'control',
                'control.type': 'redflag',
                'subcellIDs': [
                    'crescendoAnginaOverride',
                    'crescendoAnginaOverrideReason'
                ]
            },
            'crescendoAnginaOverride': {
                'title': 'Resolve red flag',
                'detail': 'This will affect how this red flag is considered for calculations and actions',
                'itemType': 'control',
                'control.type': 'radio',
            },
            'crescendoAnginaOverrideReason': {
                'title': 'Override reason',
                'itemType': 'control',
                'control.type': 'textEntry',
                'textEntry.type': 'wrappingPlainText'
            },
            'haemodynamicInstability': {
                'title': 'Haemodynamic instability',
                'detail': 'EWS>=2 may signify instability. Check patient status',
                'itemType': 'control',
                'control.type': 'redflag',
                'subcellIDs': [
                    'haemodynamicInstabilityOverride',
                    'haemodynamicInstabilityOverrideReason'
                ]
            },
            'haemodynamicInstabilityOverride': {
                'title': 'Resolve red flag',
                'detail': 'This will affect how this red flag is considered for calculations and actions',
                'itemType': 'control',
                'control.type': 'radio',
            },
            'haemodynamicInstabilityOverrideReason': {
                'title': 'Override reason',
                'itemType': 'control',
                'control.type': 'textEntry',
                'textEntry.type': 'wrappingPlainText'
            },
            'resultsForm': {
                'title': 'Outcome',
                'itemType': 'subform',
                'subform.sectionIDs': [
                    'redFlagsSection',
                    'insights',
                    'alerts',
                    'recommendedActions',
                    'acsTreatmentSection',
                    'investigationsResults',
                    'additionalInformationSection',
                    'exportRequestOutcomeSection'
                ]
            },
            'highRiskDifferentialDiagnosisResults': {
                'title': 'High-risk differential diagnoses',
                'itemType': 'subform',
                'subform.sectionIDs': [
                    'highRiskDifferentialDiagnosisResultsFormSection',
                ]
            },
            'highRiskDifferentialDiagnosisResultsFormSection': {
                'itemType': 'section',
                'section.childIDs': [
                    'aorticDissectionResults',
                    'pulmonaryEmbolismResults',
                    'pancreatitisResults',
                    'pericarditisResults',
                    'pneumothoraxResults'
                ]
            },
            'aorticDissectionResults':{
                'title': 'Aortic dissection',
                'itemType': 'control',
                'control.type': 'badge'
            },
            'pulmonaryEmbolismResults':{
                'title': 'Pulmonary embolism',
                'itemType': 'control',
                'control.type': 'badge'
            },
            'pancreatitisResults':{
                'title': 'Pancreatitis',
                'itemType': 'control',
                'control.type': 'badge'
            },
            'pericarditisResults':{
                'title': 'Pericarditis',
                'itemType': 'control',
                'control.type': 'badge'
            },
            'pneumothoraxResults':{
                'title': 'Pneumothorax',
                'itemType': 'control',
                'control.type': 'badge'
            },
            'alerts': {
                'title': 'Alerts',
                'itemType': 'section',
                'section.childIDs': [
                    // 'reviewWithSMO', 
                    // 'highRiskDifferentialDiagnosisAction',
                    // 'clinicalSuspicion',
                    // 'invalidPatientAction'
                ]
            },
            'nextTroponinAction': {
                'title': 'Troponin I - Follow-up',
                'itemType': 'control',
                'control.type': 'action'
            },
            'followUpECGAction': {
                'title': 'ECG',
                'detail': 'No repeat ECG is needed unless the patient status changes.',
                'itemType': 'control',
                'control.type': 'action'
            },
            // 'exerciseStressTestPossibleAction': {
            //     'title': 'Exercise Test',
            //     'detail': 'Patient is eligible for ETT within 72 hours',
            //     'itemType': 'control',
            //     'control.type': 'action'
            // },
            // 'exerciseStressTestNotPossibleAction': {
            //     'title': 'Other',
            //     'detail': 'Patient is not eligible for ETT. Seek cardiology advice regarding further testing.',
            //     'itemType': 'control',
            //     'control.type': 'action'
            // },
            'stemiAction': {
                'title': 'Activate STEMI Pathway',
                'detail': 'STEMI requires time critical management. Active local STEMI procedures.',
                'itemType': 'control',
                'control.type': 'action'
            },
            'abnormalVitalSigns':{
                'title': 'Abnormal vital signs',
                'detail': 'EWS 1-5.',
                'itemType': 'control',
                'control.type': 'action',
            },
            'clinicalInstability': {
                'title': 'Clinical instability',
                'detail': 'EWS 6-7 may indicate acute illness or unstable chronic disease. Urgent referral recommended.',
                'itemType': 'control',
                'control.type': 'action'
            },
            'clinicalEmergencyDeterioration': {
                'title': 'Clinical emergency',
                'detail': 'EWS 8-9 is a clinical emergency. Likely to deteriorate rapidly.',
                'itemType': 'control',
                'control.type': 'action',
            },
            'clinicalEmergency': {
                'title': 'Clinical emergency',
                'detail': 'EWS 10+ is a clinical emergency. Immediately life threatening critical illness.',
                'itemType': 'control',
                'control.type': 'action'
            },
            'highRiskDifferentialDiagnosisAction':{
                'title': 'High-risk differential diagnoses',
                'detail': 'This pathway is designed specifically to help exclude acute myocardial infarction due to ACS and the patient may be discharged if results are low-risk. If you are actively investigating other high-risk conditions consider exiting the pathway and seeking appropriate advice.',
                'itemType': 'control',
                'control.type': 'action',
            },
            'clinicalSuspicion':{
                'title': 'No clinical suspicion',
                'detail': 'This pathway is designed for presentations where there is a suspicion of ACS. There are numerous causes for chest pain or an elevated troponin test.',
                'itemType': 'control',
                'control.type': 'action',
            },
            'reviewWithSMO': {
                'title': 'Address red flags',
                'detail': 'Urgent management is needed, ensure red flags are resolved before continuing pathway.',
                'itemType': 'control',
                'control.type': 'action'
            },
            'recommendedActions':{
                'title': 'Management',
                'itemType': 'section',
                'section.childIDs': [
                    'acsManagementExclusionMessage',
                    'accuteCardiologyAssessmentRequest',
                    'arrangeAmbulanceTransfer',
                    'noFurtherManagementNeededForACS'
                ]
            },
            'recomendationBasis':{
                'title': 'Recommendations are based on your indicated [High / Low] clinical suspicion of ACS',
                'detail': 'If your clinical suspicion of ACS changes, update it at any time in the ‘Clinical question’ section and these recommendations will adjust accordingly.',
                'itemType': 'control',
                'control.type': 'action'
            },
            'requestAccuteCardiologyAssessment':{
                'title': 'Request Acute Cardiology Assessment',
                'detail': 'Phone the cardiology registrar, #@^0800-472-424^tel:0800-472-424#@, or if unsure, phone the on-call cardiologist, #@^0800-472-424^tel:0800-472-424#@.\nFollow up your phone call by sending a request for acute cardiology assessment via:\n\t- ERMS: Acute > Acute Cardiology Referral, or\n\t- Fax to (03) 364-0286.\nInclude results of resting ECG. If abnormal, send via ERMS as an image attachment, or fax cover note.',
                'itemType': 'control',
                'control.type': 'action'
            },
            'provideInitialTreatmentForACS':{
                'title': 'Provide initial treatment for ACS',
                'detail': 'Aspirin 300mg\nPain relief\nOxygen if in cardiogenic shock or Sats <93%',
                'itemType': 'control',
                'control.type': 'action'
            },
            'observeInGeneralPractiseSurgery':{
                'title': 'Observe in General Practice Surgery or Observation Unit',
                'detail': 'Until initial troponin result is available.',
                'itemType': 'control',
                'control.type': 'action'
            },
            'waitInGeneralPractiseSurgery':{
                'title': 'Wait in General Practice Surgery or Observation Unit',
                'detail': 'Until follow-up troponin result is available.\nPatients may leave the practice with HealthInfo (Information for People Awaiting Blood Test Results for Acute Chest Pain). However, advise them not to drive and to have a responsible adult with them until the results are known.',
                'itemType': 'control',
                'control.type': 'action'
            },
            'noFurtherManagementNeededForACS':{
                'title': 'No further management needed for suspected ACS',
                'itemType': 'control',
                'control.type': 'action'
            },
            'investigationsResults':{
                'title': 'Investigations',
                'itemType': 'section',
                'section.childIDs': [
                    'troponinTestRequired',
                    'noFurtherTroponinTestRequired',
                    'followUpTroponinTestRequired',
                    'noTroponinTestRequired',
                    'ecgInvestigation'
                ]
            },
            'troponinTestRequired':{
                'title': 'Initial troponin is indicated',
                'detail': 'If a troponin test is done, further recommendations will become available.',
                'itemType': 'control',
                'control.type': 'action'
            },
            'noFurtherTroponinTestRequired':{
                'title': 'No further troponin test indicated',
                'itemType': 'control',
                'control.type': 'action'
            },
            'followUpTroponinTestRequired':{
                'title': 'Follow-up troponin test indicated',
                'detail': 'At least two hours after previous test, and at least 6 hours after the worst symptoms',
                'itemType': 'control',
                'control.type': 'action'
            },
            'noTroponinTestRequired':{
                'title': 'No troponin test indicated',
                'itemType': 'control',
                'control.type': 'action'
            },
            'ecgInvestigation':{
                'title': 'ECG is indicated',
                'detail': 'No repeat ECG is needed unless the patient status changes',
                'itemType': 'control',
                'control.type': 'action'
            },
            'insights': {
                'title': 'Insights',
                'itemType': 'section',
                'section.childIDs': [
                    'calculatedEDACS',
                    // 'probabilityOfMACE'
                ]
            },
            'calculatedEDACS': {
                'title': 'Calculated EDACS',
                'itemType': 'control',
                'control.type': 'calculation',
            },
            'invalidPatientAction': {
                'title': 'Patient is not eligible for pathway',
                'detail': 'Due to indicated patient sex this patient is not eligible for continuing this pathway. No further recommendations will be provided.',
                'itemType': 'control',
                'control.type': 'action'
            },
            'exportRequestOutcomeSection':{
                'title': 'Export request outcome',
                'section.childIDs': [
                    'exportRequestOutcome'
                ]
            },
            'exportRequestOutcome':{
                'title': 'Please use the button below to copy the summary of this outcome into your clinical record or the clinical notes section of an ERMS request.',
                'itemType': 'control',
                'control.type': 'export'
            },
            'additionalInformationSection': {
                'title': 'Additional information',
                'section.childIDs': [
                    'additionalInformationIncompleteForm',
                    'additionalRequestInformation'
                ]
            },
            'additionalInformationIncompleteForm': {
                'title': 'Please continue to complete all sections of the pathway to generate investigation, treatment and management recommendations and write a comprehensive clinical note.',
                'itemType': 'control',
                'control.type': 'plain'
            },
            'additionalRequestInformation':{
                'title': 'Please include any other relevant information',
                'itemType': 'control',
                'control.type': 'textEntry',
                'textEntry.type': 'wrappingPlainText'
            },
            'acsManagementExclusionMessage': {
                'title': 'Management and treatment recommendations will not be provided.',
                'detail': 'You may continue to complete the pathway to write a comprehensive clinical note.',
                'itemType': 'control',
                'control.type': 'action'
            },
            'accuteCardiologyAssessmentRequest': {
                'title': 'Request #@^Acute Cardiology Assessment^https://canterbury.communityhealthpathways.org/99994.htm#@',
                'itemType': 'control',
                'control.type': 'action'
            },
            'arrangeAmbulanceTransfer': {
                'title': 'Arrange Ambulance transfer',
                'itemType': 'control',
                'control.type': 'action'
            },
            'acsTreatmentSection': {
                'title': 'ACS Treatment',
                'itemType': 'section',
                'section.childIDs': [
                    'acsTreatmentAsprin'
                ]
            },
            'acsTreatmentAsprin': {
                'title': 'Aspirin 300mg\nPain relief\nOxygen, if in cardiogenic shock or STEMI and Sats <93%',
                'itemType': 'control',
                'control.type': 'action'
            },
        },
        'template.functions': {
            'otherEpisodeVisibility': {
                'functionType': 'visibility',
                'dependencies': [
                    'painWorstAt'
                ],
                'updateInterfaceOnChange': true
            },
            'stemiCriteriaVisibility': {
                'functionType': 'visibility',
                'dependencies': [
                    'stemiPresent'
                ],
                'updateInterfaceOnChange': true
            },
            'stemiCriteriaMandatory': {
                'functionType': 'mandatory',
                'dependencies': [
                    'stemiPresent'
                ],
                'updateInterfaceOnChange': true
            },

            'abnormalitiesCriteriaVisibility': {
                'functionType': 'visibility',
                'dependencies': [
                    'abnormalitiesPresent'
                ],
                'updateInterfaceOnChange': true
            },
            'abnormalitiesCriteriaMandatory': {
                'functionType': 'mandatory',
                'dependencies': [
                    'abnormalitiesPresent'
                ],
                'updateInterfaceOnChange': true
            },
            'alternativeFindingSpecifyVisibility': {
                'functionType': 'visibility',
                'dependencies': [
                    'alternativeFinding'
                ],
                'updateInterfaceOnChange': true
            },
            'aorticDissectionCriteriaVisibility': {
                'functionType': 'visibility',
                'dependencies': [
                    'aorticDissectionPresent'
                ],
                'updateInterfaceOnChange': true
            },
            'afterFirstDateValidity': {
                'functionType': 'validity',
                'dependencies': [
                    'firstDateTime',
                    'worstDateTime'
                ]
            },
            'firstDateValidity': {
                'functionType': 'validity',
                'dependencies': [
                    'firstDateTime'
                ]
            }
        }
    }
}