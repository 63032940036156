import { FormContentView } from '../../FormsUI/FormsUI Components/Containers/FormContentView';
import { FormsStyles } from '../../FormsUI/FormsUI';
import { FormScaleKey } from '../../FormsUI/FormsUI Components/Controls/FormScaleKey';
import React from 'react';
import PropTypes from 'prop-types';
import '../../FormsUI/Styles/scaleKey.scss';

export class ScaleKeyFormDetail extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        let title = '';

        let scaleKey = 
        <div className='scale-key-container'>
            <FormScaleKey color='green' smileyType='happy' text='Not at all' />
            <FormScaleKey color='brown' smileyType='mild' text='Mild (a little)' />
            <FormScaleKey color='orange' smileyType='moderate' text='Moderate (quite bad)' />
            <FormScaleKey color='red' smileyType='severe' text='Severe (very bad)' />
        </div>;

        return (
            <FormContentView identifier={this.props.formDetail.identifier} isSubcell={this.props.isSubcell} titleString={title} detailString={this.props.formDetail.detail} subcell={[]} rightControl={scaleKey} subCellbackgroundColor={FormsStyles.backgroundColor} showHover={true}/>
        );
    }
}

ScaleKeyFormDetail.propTypes = {
    formDetail: PropTypes.object.isRequired,
    isSubcell: PropTypes.bool.isRequired,
    renderErrors: PropTypes.func.isRequired,
    renderSubcells: PropTypes.func.isRequired,
    errorLabels: PropTypes.array.isRequired,
    hasError: PropTypes.bool.isRequired
};