import React, { useEffect, useState } from "react";
import { Box, IconButton, InputAdornment, TextField } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";
import { LoadingButton } from "@mui/lab";
import { useSnackbar } from "notistack";
import moment from "moment";
import { FormContentView } from "../../FormsUI/FormsUI Components/Containers/FormContentView";
import { FormsStyles } from "../../FormsUI/FormsUI";
import { Patient } from "../../../api/getPatientByNHINumber";
import getPatientByNHINumber from "../../../api/getPatientByNHINumber";

interface NHINumberFormDetailProp {
  formDetail: any;
  errorLabels: string[];
  hasError: boolean;
}

const NHINumberFormDetail: React.FC<NHINumberFormDetailProp> = (props) => {
  const { enqueueSnackbar } = useSnackbar();

  const [loading, setLoading] = useState<boolean>(false);
  const [textNHINumber, setTextNHINumber] = useState<string>(props.formDetail.editingValue ?? "");
  const [showClearIcon, setShowClearIcon] = useState<string>("none");

  useEffect(() => {
    setShowClearIcon(textNHINumber === "" ? "none" : "flex");
  }, [textNHINumber]);

  const textFieldUpdated = (value: string) => {
    props.formDetail.form.valueUpdated(props.formDetail.identifier, value);
    setTextNHINumber(value);
  };

  const clearNHINumber = () => {
    textFieldUpdated("");
    updateNHIRelatedFields(undefined);
    enqueueSnackbar("The patient information has been cleared. Please re-enter the NHI number to populate the fields again.", {
      variant: "success",
    });
  };

  const lookupNHINumber = () => {
    if (loading) return;
    if (!textNHINumber || textNHINumber === "") {
      enqueueSnackbar("Please enter an NHI Number", {
        variant: "warning",
      });
      return;
    }

    setLoading(true);
    // Call API to get the Patient info using NHI Number
    getPatientByNHINumber(textNHINumber, (data, error) => {
      if (error) {
        updateNHIRelatedFields(undefined);
        enqueueSnackbar(error, {
          variant: "error",
        });
      } else {
        updateNHIRelatedFields(data);
        enqueueSnackbar("The Patient information has been populated based on the NHI number provided. Please validate this information with the patient.", {
          variant: "success",
        });
      }
      setLoading(false);
    });
  };

  const updateNHIRelatedFields = (data: Patient | undefined) => {
    // Update field values with NHI Lookup response
    props.formDetail.form.valueUpdated("patientName", data?.Name ?? "");

    if (data?.Gender === "male") {
      props.formDetail.form.valueUpdated("patientSex", "genderMaleOption");
      props.formDetail.form.valueUpdated("patientGender", "");
    } else if (data?.Gender === "female") {
      props.formDetail.form.valueUpdated("patientSex", "genderFemaleOption");
      props.formDetail.form.valueUpdated("patientGender", "");
    } else {
      props.formDetail.form.valueUpdated("patientSex", undefined);
      props.formDetail.form.valueUpdated("patientGender", data?.Gender ?? "");
    }

    if (data?.BirthDate) {
      let dateValue = moment(data?.BirthDate, "DD/MM/YYYY");
      props.formDetail.form.valueUpdated("patientDOB", dateValue.toDate());
      props.formDetail.form.valueUpdated("patientAge", moment().diff(dateValue, "years"));
    } else {
      props.formDetail.form.valueUpdated("patientDOB", undefined);
      props.formDetail.form.valueUpdated("patientAge", undefined);
    }
    props.formDetail.form.valueUpdated("patientAddress", data?.Address ?? "");
    props.formDetail.form.valueUpdated("patientEthnicity", data?.Ethnicity ?? "");
  };

  const createTextField = () => {
    const textField = (
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
        }}
      >
        <TextField
          fullWidth
          variant="outlined"
          placeholder="Search"
          sx={{
            color: FormsStyles.primaryTint,
            "& .MuiOutlinedInput-root": {
              borderRadius: "10px",
              border: "2px solid " + FormsStyles.backgroundColor,
              "& :focus": { color: "black" },
              color: textNHINumber.length > 0 ? FormsStyles.primaryTint : FormsStyles.grey,
            },
            "& .MuiInputBase-input": {
              py: 1,
            },
            "& fieldset": { border: "none" },
          }}
          inputProps={{ style: { textAlign: "right" } }}
          value={textNHINumber}
          onChange={(event) => {
            textFieldUpdated(event.target.value);
          }}
          onKeyUp={(event) => {
            if (event.key === "Enter") {
              lookupNHINumber();
            }
          }}
          InputProps={{
            endAdornment: (
              <>
                <InputAdornment position="end" style={{ display: showClearIcon }}>
                  <IconButton size="small" disabled={loading} sx={{ p: 0, color: "silver" }} onClick={() => clearNHINumber()}>
                    <ClearIcon sx={{ width: "18px" }} />
                  </IconButton>
                </InputAdornment>
                <InputAdornment position="end">
                  <LoadingButton size="small" sx={{ p: 0, minWidth: 0, color: FormsStyles.primaryTint }} loading={loading} onClick={() => lookupNHINumber()}>
                    <SearchIcon />
                  </LoadingButton>
                </InputAdornment>
              </>
            ),
          }}
        />
      </Box>
    );

    return textField;
  };

  return (
    <FormContentView
      identifier={props.formDetail.identifier}
      isSubcell={false}
      titleString={props.formDetail.title}
      validation={props.errorLabels}
      isMandatory={props.formDetail.form.isMandatory(props.formDetail.identifier)}
      detailString={props.formDetail.detail}
      rightControl={createTextField()}
      subcell={undefined}
      subCellbackgroundColor={FormsStyles.backgroundColor}
      showHover={true}
    />
  );
};

export default NHINumberFormDetail;
