export var ACCMRISidebar = {
    root: {
        sections: [
            {
                name: 'Request',
                path: 'mainForm',
                icon: 'pathway',
                showPie: false,
                sections: [
                    {
                        name: 'Request criteria',
                        path: 'requestCriteria',
                        showPie: true,
                    },
                    {
                        name: 'Assessment',
                        path: 'assessment',
                        showPie: true,
                    }
                ]
            },
            {
                name: 'Outcome',
                icon: 'results',
                path: 'outcome',
                showPie: false
            }
        ]
    }
};