import { createTheme, ThemeOptions, responsiveFontSizes, alpha } from "@mui/material";
import { orange, blueGrey } from "@mui/material/colors";


declare module '@mui/material/styles' {
  interface Theme {
    page: {
      bgColor: string
    },
    card: {
      bgColor: string
      headerBgColor: string
      headerTextColor: string
      headerSubTextColor: string
    },
    table: {
      headerBgColor: string
      subHeaderBgColor: string
      hoverBgColor: string
      completedMarkColor: string
      pieChartCompletedColor: string
      pieChartUncompletedColor: string
      subTextColor: string
    },
    component: {
      alertUnresolvedColor: string
      alertResolvedColor: string
      appSideBarBgColor: string
    },
  }

  interface ThemeOptions {
    page: {
      bgColor: string
    },
    card: {
      bgColor: string
      headerBgColor: string
      headerTextColor: string
      headerSubTextColor: string
    },
    table: {
      headerBgColor: string
      subHeaderBgColor: string
      hoverBgColor: string
      completedMarkColor: string
      pieChartCompletedColor: string
      pieChartUncompletedColor: string
      subTextColor: string
    },
    component: {
      alertUnresolvedColor: string
      alertResolvedColor: string
      appSideBarBgColor: string
    },
  }
}

const themeOptions: ThemeOptions = {
  palette: {
    primary: {
      main: orange[700],
      // light, dark and contrastText are generated from main by default
    },
    secondary: {
      main: blueGrey[900],
      // light, dark and contrastText are generated from main by default
    },
    text: {
      primary: blueGrey[900],
      secondary: blueGrey[700],
    }
  },
  page: {
    bgColor: "#f8f8f8"
  },
  card: {
    bgColor: "#fff",
    headerBgColor: alpha("#ff7e21", 0.3),
    headerTextColor: blueGrey[900],
    headerSubTextColor: "gray",
  },
  table: {
    headerBgColor: alpha("#ff7e21", 0.3),
    subHeaderBgColor: "#e8e8e8",
    hoverBgColor: alpha("#ff7e21", 0.1),
    completedMarkColor: "#00d900",
    pieChartCompletedColor: "#00c200",
    pieChartUncompletedColor: "#ebebeb",
    subTextColor: "gray",
  },
  typography: {
    fontFamily: 'Inter, BlinkMacSystemFont, "Segoe UI", "Helvetica Neue", Arial, sans-serif',
    h1: { fontSize: 42, fontWeight: '600' },
    h2: { fontSize: 34, fontWeight: '600' },
    h3: { fontSize: 27, fontWeight: '600' },
    h4: { fontSize: 22, fontWeight: '600' },
    h5: { fontSize: 18, fontWeight: '600' },
    h6: { fontSize: 14, fontWeight: '600' },
  },
  component: {
    alertUnresolvedColor: "#dc2626",
    alertResolvedColor: "#22c55e",
    appSideBarBgColor: "#25272c"
  },
}

export const theme = responsiveFontSizes(createTheme(themeOptions));
