// IMPORT PACKAGE REFERENCES
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FormButton } from '../../../waferJS/FormsUI/FormsUI Components/Controls/FormButton';
import { FormTextField } from '../../../waferJS/FormsUI/FormsUI Components/Controls/FormTextField';
import { FormTextView } from '../../../waferJS/FormsUI/FormsUI Components/Controls/FormTextView';
import { exportToACC, resetState, previewToACC } from '../../state/actions/ExportToACCActions';
import { Loading } from '../../shared/Loading';
import { FormLabel } from '../../../waferJS/FormsUI/FormsUI Components/Controls/FormLabel';
import { FormsStyles } from '../../../waferJS/FormsUI/FormsUI';
import { incrementWorkFlow, markWorkFlowAsExported } from '../../state/actions/WorkflowResponseActions';
import { addExpandedCell, removeExpandedCell } from '../../../components/state/actions/SubformStateActions';
import providerList from '../../../config/accProviderList.json';
import { ENVIRONMENT } from '../../../config/config';
import { DateHelper } from '../../../waferJS/Helpers/DateHelper';
import moment from 'moment';

// COMPONENT
class ReferralOverlay extends React.Component {

    constructor(props) {
        super(props);

        this.shuffleProviders = this.shuffleProviders.bind(this);

        this.state = {
            regions: providerList['regions'],
            locations: providerList['locations'],
            providers: ['--'],
            selectedRegion: providerList['regions'][0],
            selectedLocation: '--',
            selectedProvider: '--',
            email: null,
            name: null,
            notes: '',
            claimNumber: props.data['patientACCClaimNumber'],
            isReadyForSubmission: false
        };

        this.cancelAction = this.cancelAction.bind(this);
        this.performExport = this.performExport.bind(this);
        this.dismiss = this.dismiss.bind(this);
        this.regionChanged = this.regionChanged.bind(this);
        this.locationChanged = this.locationChanged.bind(this);
        this.providerChanged = this.providerChanged.bind(this);
        this.emailChange = this.emailChange.bind(this);
        this.nameChange = this.nameChange.bind(this);
        this.notesChange = this.notesChange.bind(this);
        this.claimChange = this.claimChange.bind(this);
        this.setSubmissionStatus = this.setSubmissionStatus.bind(this);
        this.displayPreview = this.displayPreview.bind(this);
        this.openPDFInNewWindow = this.openPDFInNewWindow.bind(this);
        this.submit = this.submit.bind(this);
        this.preview = this.preview.bind(this);
    }

    componentDidUpdate(prevProps) {
        this.setSubmissionStatus();
        if (this.props.exportToACCFulfilled && !prevProps.exportToACCFulfilled) {
            this.props.markWorkFlowAsExported(this.props.pathwayWorkflow.pathwayUuid, this.props.pathwayWorkflow);
        }

        if (this.props.pdfPreviewData != null && prevProps.pdfPreviewData == null) {
            this.displayPreview();
        }
    }

    componentWillUnmount() {

    }

    setSubmissionStatus() {
        let isReady = false;
        if(this.state.selectedRegion != '--' && this.state.selectedLocation != '--' && this.state.selectedProvider != '--' && this.state.email != null && this.state.name != null) {
            isReady = true;
        }
        
        if (this.state.isReadyForSubmission != isReady) {
            this.setState({isReadyForSubmission: isReady});
        }
        
    }

    cancelAction() {
        this.props.resetState();
        this.props.removeExpandedCell(this.props.pathwayId, 'referralOverlay', this.props.expandedCells);
    }

    submit() {
        this.performExport(false);
    }

    preview() {
        this.performExport(true);
    }

    checkIfPathwayIsAcc() {
        return (this.props.pathwayId === 'accbist01');
    }
    
    getReferrerEmailDomain() {
        if (this.checkIfPathwayIsAcc()) {
            return '@cdhb.health.nz';
        } else {
            return '@wayfind.health';
        }
    }

    performExport(preview) {
        let chosenProvider;

        providerList['providers'].forEach(provider => {
            if (provider.name === this.state.selectedProvider) {
                chosenProvider = provider;
            }
        });

        let referrerEmail = this.state.email.split('@')[0] + this.getReferrerEmailDomain();

        let params = this.props.data;
        
        params['providerName'] = chosenProvider.name;
        params['wardName'] = 'Christchurch Hospital ED';
        params['referralDate'] = moment().toDate();
        params['dischargeDate'] = moment().toDate();
        params['hospitalReferralDate'] = moment().toDate();
        params['referrerName'] = this.state.name;
        params['referrerNotes'] = this.state.notes;
        params['referrerEmail'] = referrerEmail;
        params['patientACCClaimNumber'] = this.state.claimNumber;

        let dateValue = moment(params['patientDOB']);
        params['patientDOB'] = DateHelper.getDateAsFormat(dateValue.toDate(), 'MM-DD-YYYY');

        let to; 
        let cc; 
        let bcc;

        switch (ENVIRONMENT) {
            case 'DEV':
                to = [this.state.email];
                cc = [this.state.email];
                bcc = [];
                break;
            case 'PROD':
                to = [chosenProvider.email];
                cc = [referrerEmail, 'claims@acc.co.nz'];
                bcc = this.checkIfPathwayIsAcc() ? ['wayfind@cdhb.health.nz'] : [];
                break;
            case 'DEMO':
                to = [this.state.email];
                cc = [this.state.email];
                bcc = [];
                break;
            case 'TEST':
                to = [this.state.email];
                cc = [this.state.email];
                bcc = [];   
                break;
            case 'STAGING':
                to = [referrerEmail];
                cc = [referrerEmail];
                bcc = this.checkIfPathwayIsAcc() ? ['wayfind@cdhb.health.nz'] : [];
                break;
            default:
                to = [referrerEmail];
                cc = [];
                bcc = [];
                break;
        }

        var data = {
            to: to,
            cc: cc,
            bcc: bcc,
            subject: 'New ACC 7988 Referral',
            template: 'acc-7988.pdf',
            params: params
        };

        if (preview) {
            this.props.previewToACC(data);
        }
        else {
            this.props.exportToACC(data);
        }
    }

    dismiss() {
        this.props.resetState();
        this.props.removeExpandedCell(this.props.pathwayId, 'referralOverlay', this.props.expandedCells);
    }

    shuffleProviders(array) {
        return array
            .map(value => ({ value, sort: Math.random() }))
            .sort((a, b) => a.sort - b.sort)
            .map(({ value }) => value);
    }

    regionChanged(value) {
        this.setState({
            selectedRegion: value.target.value
        });
    }

    emailChange(value) {
        this.setState({
            email: value
        });
    }

    nameChange(value) {
        this.setState({
            name: value
        });
    }

    claimChange(value) {
        this.setState({
            claimNumber: value
        });

        this.props.subform.valueUpdated('patientACCClaimNumber', value);
    }

    notesChange(value) {
        this.setState({
            notes: value
        });
    }

    locationChanged(value) {
        let providers = providerList['providers'];
        let fiteredProviders = [];

        if (value.target.value == '--') {
            this.setState({
                selectedLocation: '--',
                selectedProvider: '--',
                providers: ['--']
            });

            return;
        }

        providers.forEach(provider => {
            if (provider.locations.includes(value.target.value) && provider.region == this.state.selectedRegion) {
                fiteredProviders.push(provider.name);
            }
        });

        let shuffledProviders = this.shuffleProviders(fiteredProviders);
        let newProvider = shuffledProviders[0];
        this.setState({
            selectedLocation: value.target.value,
            selectedProvider: newProvider,
            providers: shuffledProviders
        });

        console.log(newProvider);
    }

    providerChanged(value) {
        this.setState({
            selectedProvider: value.target.value
        });
    }

    openPDFInNewWindow(blob) {
        var win = window.open();
        if(win != null && win != undefined) {
            let url = window.URL.createObjectURL(blob);
            const link = win.document.createElement('a');
            link.href = url;
            link.setAttribute(
                'download',
                'ACC 7988 Referral' + '.pdf'
            );
            win.document.body.appendChild(link);
            link.click();
            // Clean up and remove the link
            link.parentNode.removeChild(link);
        }
    }
    
    displayPreview() {
        // decode base64 string, remove space for IE compatibility
        var binary = atob(this.props.pdfPreviewData.replace(/\s/g, ''));
        var len = binary.length;
        var buffer = new ArrayBuffer(len);
        var view = new Uint8Array(buffer);
        for (var i = 0; i < len; i++) {
            view[i] = binary.charCodeAt(i);
        }            
        var blob = new Blob( [view], { type: 'application/pdf' });

        this.openPDFInNewWindow(blob);
    }

    render() {
        var hasAC45 = this.state.claimNumber != null && this.state.claimNumber != undefined;

        return (
            <div className="session-wrapper">
                <div className="session-overlay" />

                <div className="environment-manager-container">
                    <div className="environment-card open" ref={sessionCardNode => this.sessionCardNode = sessionCardNode}>

                        <div className="referral-card-header">
                            <header className={'session-title'}>{'Submit ACC Referral'}</header>
                           

                            <div className="card-overlay-exit-button-referral-container">
                                {<FormButton title={''} image={<img className={'session-card-exit'} src={require('../../../images/cross-blue.png')} />} backgroundColor={FormsStyles.backgroundColor} enabled={true} isSelected={false} onPress={this.dismiss} />}
                            </div>
                        </div>

                        <div className={hasAC45 ? 'referral-detail' : 'referral-detail missing'}>Please ensure an ACC45 form has been completed with Concussion as a primary diagnosis. Patient details including ACC claim number, injury details and clinical assessment must be complete before submitting. For optional referrals please explain why you are making this request in the referrer notes</div>

                        {!this.props.exportToACCPending && (this.props.exportToACCFulfilled || this.props.exportToACCFailed) &&
                            <div className="export-status-container">
                                <div><FormLabel value={this.props.exportToACCFulfilled ? 'Referral Sent' : 'Error, failed to submit. Please try again.'} textColor={this.props.exportToACCFulfilled ? FormsStyles.primaryTint : 'red'} fontSize={this.props.exportToACCFulfilled ? 1 : null} /></div>
                            </div>
                        }

                        <div className="session-body">
                            {!this.props.exportToACCPending && !this.props.exportToACCFulfilled &&
                                <div>
                                    <div className="referral-details-section">

                                        <h3 className="referral-section-title">ACC</h3>

                                        <div className="referral-section-subtitle">
                                            ACC Number (ACC45) or claim number
                                            <FormTextField className="name-input" value={this.state.claimNumber} spellCheck={false} onKeyPress={this.onKeyPress} inputType={'text'} alignment={'right'} onChange={this.claimChange} shouldDelayResponse={false} placeholder={'Number'} width={'100%'} />
                                        </div>
                                    </div>
                                    <div className="referral-details-section">

                                        <h3 className="referral-section-title">Your Details</h3>
                                        
                                        <div className="referral-section-subtitle">
                                            Name
                                            <FormTextField className="name-input" value={this.state.name} spellCheck={false} onKeyPress={this.onKeyPress} inputType={'text'} alignment={'right'} onChange={this.nameChange} shouldDelayResponse={false} placeholder={'Name'} width={'100%'} />
                                        </div>

                                        <div className="referral-section-subtitle">
                                            Email
                                            <FormTextField className="email-input" value={this.state.email} spellCheck={false} onKeyPress={this.onKeyPress} inputType={'email'} unitText={this.getReferrerEmailDomain()} alignment={'right'} onChange={this.emailChange} shouldDelayResponse={false} placeholder={'Email'} width={'100%'} />
                                        </div>

                                        <div className="referral-section-subtitle">
                                            Notes
                                            <FormTextView value={this.state.notes} isLarge={false} onChange={this.notesChange} placeholder={'Referrer Notes'} subCellbackgroundColor={FormsStyles.backgroundColor}/>
                                        </div>
                                    </div>

                                    <div className="referral-details-section referral-provider-selection">
                                        <h3 className="referral-section-title">Choose Provider</h3>
                                        <div className="referral-section-subtitle">
                                            Region
                                            <select className="referral-select" name="regions" id="regions" onChange={this.regionChanged}>
                                                {
                                                    this.state.regions.map((region) => {
                                                        return (
                                                            <option key={region} value={region}>{region}</option>
                                                        );
                                                    })
                                                }
                                            </select>
                                        </div>

                                        <div className="referral-section-subtitle">
                                            Location

                                            <select className="referral-select" name="locations" id="locations" onChange={this.locationChanged}>
                                                <option key={'--'} value={'--'}>{'--'}</option>
                                                {
                                                    this.state.locations.map((location) => {
                                                        return (
                                                            <option key={location} value={location}>{location}</option>
                                                        );
                                                    })
                                                }
                                            </select>
                                        </div>

                                        <div className="referral-section-subtitle">
                                            Provider

                                            <select className="referral-select" name="providers" id="providers" onChange={this.providerChanged} value={this.state.selectedProvider}>
                                                {/* <option key={'--'} value={'--'}>{'--'}</option> */}
                                                {
                                                    this.state.providers.map((provider) => {
                                                        return (
                                                            <option key={provider} value={provider}>{provider}</option>
                                                        );
                                                    })
                                                }
                                            </select>
                                        </div>
                                    </div>
                                </div>

                            }
                            {this.props.exportToACCPending && <Loading />}
                            {!this.props.exportToACCPending &&
                                <div className="export-overlay-buttons-container">
                                    {!this.props.exportToACCFulfilled && 
                                    <div>
                                        <FormButton title={'Submit'} enabled={this.state.isReadyForSubmission} isSelected={false} onPress={this.submit} />
                                        <FormButton title={'Preview'} enabled={this.state.isReadyForSubmission} isSelected={false} onPress={this.preview} />
                                    </div>}
                                    {this.props.exportToACCFulfilled && <FormButton title={'Okay'} enabled={true} isSelected={false} onPress={this.cancelAction} />}
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

ReferralOverlay.propTypes = {
    exportToACC: PropTypes.func.isRequired,
    resetState: PropTypes.func.isRequired,
    exportToACCPending: PropTypes.bool.isRequired,
    exportToACCFulfilled: PropTypes.bool.isRequired,
    exportToACCFailed: PropTypes.bool.isRequired,
    data: PropTypes.object.isRequired,
    pathwayWorkflow: PropTypes.object.isRequired,
    incrementWorkFlow: PropTypes.func.isRequired,
    markWorkFlowAsExported: PropTypes.func.isRequired,
    addExpandedCell: PropTypes.func.isRequired,
    removeExpandedCell: PropTypes.func.isRequired,
    expandedCells: PropTypes.object.isRequired,
    pathwayId: PropTypes.string.isRequired,
    pdfPreviewData: PropTypes.string,
    previewToACC: PropTypes.func.isRequired,
    subform: PropTypes.object.isRequired
};

// CONFIGURE REACT REDUX
const mapStateToProps = state => {
    const { exportToACCPending, exportToACCFulfilled, exportToACCFailed, pdfPreviewData } = state.exportToACCReducer;
    const { expandedCells } = state.subformStateReducer;
    return { exportToACCPending, exportToACCFulfilled, exportToACCFailed, expandedCells, pdfPreviewData };
};

const mapDispatchToProps = dispatch => (
    bindActionCreators({ exportToACC, previewToACC, resetState, incrementWorkFlow, markWorkFlowAsExported, addExpandedCell, removeExpandedCell }, dispatch)
);

const hoc = connect(mapStateToProps, mapDispatchToProps)(ReferralOverlay);

// EXPORT COMPONENT

export { hoc as ReferralOverlay };