import React from "react";
import { DSRouter } from "./DSRouter";
import { FormLoader } from "../../forms/FormLoader";
import { Box, CircularProgress } from "@mui/material";
import { useUserInfo } from "../Common/useUserInfo";
import LoginPage from "../pages/LoginPage";

interface DSRouterWrapperProp {
  formLoader: FormLoader;
}

const DSRouterWrapper: React.FC<DSRouterWrapperProp> = ({ formLoader }) => {
  const { instance, loading, isLowAccessLevel, userInfo } = useUserInfo();

  const handleLogout = () => {
    instance.logoutRedirect();
  };

  const Loading = () => {
    return (
      <Box
        sx={{
          display: "flex",
          width: "100%",
          mt: 40,
          justifyContent: "center",
          alignContent: "center",
        }}
      >
        <CircularProgress size="3rem" color="info" />
      </Box>
    );
  };

  if (loading) {
    return <Loading />;
  } else if (isLowAccessLevel) {
    return <LoginPage updateOption={true} />;
  } else {
    return (
      <DSRouter
        formLoader={formLoader}
        userName={userInfo?.Name}
        userHPINumber={userInfo?.HPINumber}
        userEmail={userInfo?.Email}
        logout={() => handleLogout()}
      />
    );
  }
};

export default DSRouterWrapper;
